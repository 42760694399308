import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { ISample } from 'store/types/entityTypes/sample'
import {
  IFetchSubmissionsPayload,
  ISampleSlice,
  IUploadSamplePayload,
} from 'store/types/slicesTypes/sample'

const initialState: ISampleSlice = {
  isFetchSubmissionsLoading: false,
  uploadingInfo: {
    filename: '',
    uploadDate: '',
  },
  submissionsCount: 0,
  hasMoreSubmissions: true,
  submissions: [],
}

const sampleSlice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    fetchSubmissionsCount() {},
    fetchSubmissionsCountFulfilled(state, action: PayloadAction<number>) {
      state.submissionsCount = action.payload
    },
    fetchSubmissionsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.submissionsCount = initialState.submissionsCount
    },
    fetchSubmissionsCountCancelled() {},
    fetchSubmissions(state, _action: PayloadAction<IFetchSubmissionsPayload>) {
      state.isFetchSubmissionsLoading = true
    },
    fetchSubmissionsFulfilled(state, action: PayloadAction<ISample[]>) {
      if (action.payload.length > 0) {
        state.submissions = [...state.submissions, ...action.payload]
      } else {
        state.hasMoreSubmissions = false
      }
      state.isFetchSubmissionsLoading = false
    },
    fetchSubmissionsRejected(state, _action: PayloadAction<AjaxError>) {
      state.submissions = initialState.submissions
      state.isFetchSubmissionsLoading = false
    },
    fetchSubmissionsCancelled(state) {
      state.isFetchSubmissionsLoading = false
    },
    uploadSample(state, action: PayloadAction<IUploadSamplePayload>) {
      state.uploadingInfo.filename = action.payload.filename
      state.uploadingInfo.uploadDate = action.payload.uploadDate
    },
    uploadSampleFulfilled(state) {
      state.uploadingInfo = initialState.uploadingInfo
    },
    uploadSampleRejected(state, _action: PayloadAction<AjaxError>) {
      state.uploadingInfo = initialState.uploadingInfo
    },
    resetSubmissions(state) {
      state.submissions = initialState.submissions
      state.submissionsCount = initialState.submissionsCount
      state.hasMoreSubmissions = initialState.hasMoreSubmissions
    },
  },
})

export const {
  fetchSubmissionsCount,
  fetchSubmissionsCountFulfilled,
  fetchSubmissionsCountRejected,
  fetchSubmissionsCountCancelled,
  fetchSubmissions,
  fetchSubmissionsFulfilled,
  fetchSubmissionsRejected,
  fetchSubmissionsCancelled,
  uploadSample,
  uploadSampleFulfilled,
  uploadSampleRejected,
  resetSubmissions,
} = sampleSlice.actions

export const selectFetchSubmissionLoading = (state: TRootState) =>
  state.sample.isFetchSubmissionsLoading
export const selectUploadSampleInfo = (state: TRootState) =>
  state.sample.uploadingInfo
export const selectSubmissionsCount = (state: TRootState) =>
  state.sample.submissionsCount
export const selectHasMoreSubmissions = (state: TRootState) =>
  state.sample.hasMoreSubmissions
export const selectSubmissions = (state: TRootState) => state.sample.submissions

export default sampleSlice.reducer
