import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_DDW_CONTRACTS,
  FETCH_DDW_REPORTS,
  getCdsByIdAndKeyword,
  getClByIdAndKeyword,
  getReportMetaById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchDdwCds,
  fetchDdwCdsCancelled,
  fetchDdwCdsFulfilled,
  fetchDdwCdsRejected,
  fetchDdwCl,
  fetchDdwClCancelled,
  fetchDdwClFulfilled,
  fetchDdwClRejected,
  fetchDdwContracts,
  fetchDdwContractsCancelled,
  fetchDdwContractsFulfilled,
  fetchDdwContractsRejected,
  fetchDdwReportMeta,
  fetchDdwReportMetaCancelled,
  fetchDdwReportMetaFulfilled,
  fetchDdwReportMetaRejected,
  fetchDdwReports,
  fetchDdwReportsCancelled,
  fetchDdwReportsFulfilled,
  fetchDdwReportsRejected,
} from 'store/slices/ddw'
import {
  mapAPIDdwCdsToState,
  mapAPIDdwClToState,
  mapAPIDdwContractsToState,
  mapAPIDdwReportsMetaToState,
  mapAPIDdwReportsToState,
} from 'store/types/entityTypes/ddw'
import {
  IFetchDdwCdsResponse,
  IFetchDdwClResponse,
  IFetchDdwContractsResponse,
  IFetchDdwReportMetaResponse,
  IFetchDdwReportsResponse,
} from 'store/types/slicesTypes/ddw'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const ddwContractsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDdwContracts.match),
    switchMap(() =>
      fetchData<IFetchDdwContractsResponse>({ url: FETCH_DDW_CONTRACTS }).pipe(
        map((response: AjaxResponse<IFetchDdwContractsResponse>) =>
          fetchDdwContractsFulfilled(
            mapAPIDdwContractsToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDdwContractsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDdwContractsRejected(error))
          )
        )
      )
    )
  )

const ddwReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDdwReports.match),
    switchMap(() =>
      fetchData<IFetchDdwReportsResponse>({ url: FETCH_DDW_REPORTS }).pipe(
        map((response: AjaxResponse<IFetchDdwReportsResponse>) =>
          fetchDdwReportsFulfilled(
            mapAPIDdwReportsToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDdwReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDdwReportsRejected(error))
          )
        )
      )
    )
  )

const ddwReportMetaEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDdwReportMeta.match),
    switchMap((action) =>
      fetchData<IFetchDdwReportMetaResponse>({
        url: getReportMetaById(action.payload.id),
      }).pipe(
        map((response: AjaxResponse<IFetchDdwReportMetaResponse>) =>
          fetchDdwReportMetaFulfilled(
            mapAPIDdwReportsMetaToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDdwReportMetaCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDdwReportMetaRejected(error))
          )
        )
      )
    )
  )

const ddwClEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDdwCl.match),
    switchMap((action) =>
      fetchData<IFetchDdwClResponse>({
        url: getClByIdAndKeyword(action.payload.id, action.payload.keyword),
      }).pipe(
        map((response: AjaxResponse<IFetchDdwClResponse>) =>
          fetchDdwClFulfilled(mapAPIDdwClToState(response.response.data))
        ),
        takeUntil(action$.pipe(filter(fetchDdwClCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchDdwClRejected(error)))
        )
      )
    )
  )

const ddwCdsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDdwCds.match),
    switchMap((action) =>
      fetchData<IFetchDdwCdsResponse>({
        url: getCdsByIdAndKeyword(action.payload.id, action.payload.keyword),
      }).pipe(
        map((response: AjaxResponse<IFetchDdwCdsResponse>) =>
          fetchDdwCdsFulfilled(mapAPIDdwCdsToState(response.response.data))
        ),
        takeUntil(action$.pipe(filter(fetchDdwCdsCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchDdwCdsRejected(error)))
        )
      )
    )
  )

export const ddwEpic = combineEpics(
  ddwContractsEpic,
  ddwReportsEpic,
  ddwReportMetaEpic,
  ddwClEpic,
  ddwCdsEpic
)
