import { Box, Stack, useTheme } from '@mui/material'
import {
  getPageTitleSearchResultByText,
  PAGE_TITLE_SEARCH,
} from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import {
  checkSearchRawIntelResultType,
  checkSearchResultType,
  openSearchRawIntelWarning,
  selectSearchResultIsNetwork,
  selectSearchResultType,
} from 'store/slices/search'

import { useSearchResultCategoryTabs } from './config'
import { MatchedCard } from './MatchedCard/MatchedCard'
import { SearchReminder } from './SearchReminder'
import { CategoryTabs } from './SearchResultContent/CategoryTabs'

export const SearchResultPage = () => {
  useGoBack()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const searchResultType = useAppSelector(selectSearchResultType)
  const searchResultIsNetwork = useAppSelector(selectSearchResultIsNetwork)
  const searchResultCategoryTabs = useSearchResultCategoryTabs()
  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)
  const searchUuid = searchParams.get(SearchParamKey.SEARCH_UUID)

  const defaultCategoryTab = searchResultCategoryTabs[0]

  const categoryTabParam = searchParams.get(SearchParamKey.SEARCH_RESULT_TAB)
  const categoryTab =
    (categoryTabParam &&
      searchResultCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const SearchContent = categoryTab.component

  useEffect(() => {
    if (searchText) {
      dispatch(checkSearchResultType(searchText))
      dispatch(checkSearchRawIntelResultType(searchText))
      dispatch(openSearchRawIntelWarning())
    }
  }, [searchText])

  return (
    <>
      <Helmet>
        <title>
          {searchText
            ? getPageTitleSearchResultByText(searchText)
            : PAGE_TITLE_SEARCH}
        </title>
      </Helmet>
      <Box
        key={searchUuid}
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          gap: 1,
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack
          sx={{
            width: '47.5rem',
            maxWidth: '60rem',
            flexGrow: 1,
          }}
        >
          <Box sx={{ borderBottom: `2px solid ${theme.colors.PRIMARY}` }}>
            <CategoryTabs
              categoryTab={categoryTab}
              searchResultCategoryTabs={searchResultCategoryTabs}
              defaultCategoryTab={defaultCategoryTab}
            />
          </Box>
          {searchResultIsNetwork && searchText && (
            <SearchReminder
              searchResultType={searchResultType}
              searchText={searchText}
            />
          )}
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <SearchContent />
          </Box>
        </Stack>
        <Box
          sx={{
            width: '25.375rem',
            height: '100%',
            bgcolor: theme.colors.BLACK_85,
            py: 1,
          }}
        >
          <MatchedCard />
        </Box>
      </Box>
    </>
  )
}
