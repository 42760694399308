import {
  Autocomplete,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Button } from 'components/Button/Button'
import { Dialog } from 'components/Dialog/Dialog'
import { Drawer } from 'components/Drawer/Drawer'
import { TwoToneLabel } from 'components/Label/Label'
import { Select, SelectItem } from 'components/Select/Select'
import { TextField } from 'components/TextField/TextField'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useTlpColorMap } from 'hooks/useTlpColorMap'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchSampleCompleteShareTargets,
  fetchSampleCompleteShareTargetsCancelled,
  fetchSampleDefaultShare,
  fetchSampleDefaultShareCancelled,
  fetchSampleFindShareTarget,
  resetSampleCompleteShareTargets,
  resetSampleFindShareTarget,
  selectSampleCompleteShareTargets,
  selectSampleCompleteShareTargetsLoading,
  selectSampleDefaultShare,
  selectSampleDefaultShareLoading,
  selectSampleFindShareTarget,
  selectSampleFindShareTargetLoading,
  selectUpdateShareTargetLoading,
  updateSampleShareTarget,
} from 'store/slices/sampleDetail'
import { TShareTargetsType, TTlp } from 'store/types/entityTypes/tlpTarget'

interface IEditTLPDrawerProps {
  drawerOpen: boolean
  toggleDrawer: () => void
  sampleId: string
}

const tlpOptions: TTlp[] = ['white', 'green', 'amber', 'red']

const TEAM_T5_OFS_ID = '63344'

export const SampleEditTLPDrawer = ({
  sampleId,
  drawerOpen,
  toggleDrawer,
}: IEditTLPDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['snackbar', 'component'])
  const dispatch = useAppDispatch()
  const tlpColorMap = useTlpColorMap()
  const sampleDefaultShareLoading = useAppSelector(
    selectSampleDefaultShareLoading
  )
  const sampleDefaultShare = useAppSelector(selectSampleDefaultShare)
  const sampleCompleteShareTargetsLoading = useAppSelector(
    selectSampleCompleteShareTargetsLoading
  )
  const sampleCompleteShareTargets = useAppSelector(
    selectSampleCompleteShareTargets
  )
  const sampleFindShareTargetLoading = useAppSelector(
    selectSampleFindShareTargetLoading
  )
  const sampleFindShareTarget = useAppSelector(selectSampleFindShareTarget)

  const updateShareTargetLoading = useAppSelector(
    selectUpdateShareTargetLoading
  )

  const [selectedTlp, setSelectedTlp] = useState<TTlp>(sampleDefaultShare.tlp)
  const [shareUserInput, setShareUserInput] = useState('')
  const [shareList, setShareList] = useState(sampleDefaultShare.targets)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchSampleDefaultShare(sampleId))

    return () => {
      dispatch(fetchSampleDefaultShareCancelled())
    }
  }, [sampleId])

  useEffect(() => {
    if (shareUserInput) {
      dispatch(fetchSampleCompleteShareTargets(shareUserInput))
    }

    return () => {
      dispatch(fetchSampleCompleteShareTargetsCancelled())
    }
  }, [shareUserInput])

  useEffect(() => {
    setSelectedTlp(sampleDefaultShare.tlp)
    setShareList(sampleDefaultShare.targets)
  }, [sampleDefaultShare])

  const handleTLPChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTlp(event.target.value as TTlp)
  }

  const handleShareUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShareUserInput(event.target.value)
  }

  const handleAddUser = () => {
    dispatch(fetchSampleFindShareTarget(shareUserInput))
    dispatch(resetSampleCompleteShareTargets())
    setShareUserInput('')
  }

  const handleWarningDialogOpen = () => {
    setWarningDialogOpen(true)
  }

  const handleWarningDialogClose = () => {
    setWarningDialogOpen(false)
  }

  const handleRemove = (ofsId: string) => {
    if (ofsId === TEAM_T5_OFS_ID) {
      handleWarningDialogOpen()
    } else {
      setShareList(shareList.filter((list) => list.ofsId !== ofsId))
    }
  }

  const handleRemoveTeamT5 = () => {
    setShareList(shareList.filter((list) => list.ofsId !== TEAM_T5_OFS_ID))
    handleWarningDialogClose()
  }

  const handleDone = () => {
    dispatch(
      updateSampleShareTarget({
        id: sampleId,
        data: {
          sample: {
            sample_share: {
              tlp: selectedTlp,
              users: shareList
                .filter((list) => list.type === 'user')
                .map((list) => ({
                  ofs_id: list.ofsId,
                  can_edit: list.canEdit,
                })),
              groups: shareList
                .filter((list) => list.type === 'group')
                .map((list) => ({
                  ofs_id: list.ofsId,
                  can_edit: list.canEdit,
                })),
            },
          },
        },
        successMessage: t('tlp.updateSuccess', { ns: 'snackbar' }),
        failMessage: t('tlp.updateFail', { ns: 'snackbar' }),
        successCallback: toggleDrawer,
      })
    )
  }

  const handleAutoCompleteChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: {
      name: string
      avatar: string
      type: TShareTargetsType
      ofsId: string
      canEdit: boolean
      label: string
    }
  ) => {
    if (value) {
      dispatch(fetchSampleFindShareTarget(value.name))
      dispatch(resetSampleCompleteShareTargets())
      setShareUserInput('')
    }
  }

  useEffect(() => {
    if (sampleFindShareTarget.name) {
      setShareList(
        [...shareList, sampleFindShareTarget].filter(
          (originalShareUser, index, self) =>
            index ===
            self.findIndex(
              (newShareUser) => newShareUser.ofsId === originalShareUser.ofsId
            )
        )
      )
      dispatch(resetSampleFindShareTarget())
    }
  }, [sampleFindShareTarget])

  const mapAccountCompleteShareTargets = sampleCompleteShareTargets.map(
    (sampleCompleteShareTarget) => ({
      ...sampleCompleteShareTarget,
      label: sampleCompleteShareTarget.name,
    })
  )

  useEffect(() => {
    if (drawerOpen) {
      setSelectedTlp(sampleDefaultShare.tlp)
      setShareUserInput('')
      setShareList(sampleDefaultShare.targets)
    }
  }, [drawerOpen])

  return (
    <Drawer
      loading={sampleDefaultShareLoading}
      open={drawerOpen}
      onClose={toggleDrawer}
    >
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {t('tlpDrawer.title', { ns: 'component' })}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Box sx={{ py: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="titleSmall" sx={{ color: theme.colors.WHITE }}>
          {t('tlpDrawer.tlp', { ns: 'component' })}
        </Typography>
        <Select
          value={selectedTlp}
          onChange={handleTLPChange}
          width="13.125rem"
          sx={{
            textTransform: 'capitalize',
            '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
              color: tlpColorMap[selectedTlp],
            },
          }}
        >
          {tlpOptions.map((option: TTlp) => (
            <SelectItem
              key={option}
              value={option}
              sx={{
                textTransform: 'capitalize',
                '.MuiTypography-root': {
                  color: tlpColorMap[option],
                },
              }}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
      </Box>
      <Typography variant="titleSmall" sx={{ color: theme.colors.WHITE }}>
        {t('tlpDrawer.shareTo', { ns: 'component' })}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, pt: 2, pb: 8 }}>
        <Autocomplete
          freeSolo
          disableClearable
          options={mapAccountCompleteShareTargets}
          onChange={handleAutoCompleteChange}
          value={shareUserInput}
          loading={sampleCompleteShareTargetsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('tlpDrawer.groupOrUserLabel', { ns: 'component' })}
              type="text"
              value={shareUserInput}
              onChange={handleShareUserChange}
              sx={{ width: '32.625rem' }}
              helperText={t('tlpDrawer.groupOrUserHelperText', {
                ns: 'component',
              })}
            />
          )}
          sx={{
            '& .MuiOutlinedInput-root': {
              p: 0,
              '.MuiAutocomplete-input': {
                height: '2rem',
                p: '0 0 0 1rem',
              },
            },
          }}
        />
        <Button
          size="large"
          sx={{ width: '7.5rem' }}
          onClick={handleAddUser}
          loading={sampleFindShareTargetLoading}
          disabled={sampleFindShareTargetLoading}
        >
          {t('tlpDrawer.addCta', { ns: 'component' })}
        </Button>
      </Box>
      <Divider sx={{ bgcolor: theme.colors.WHITE_60 }} />
      <Typography
        variant="titleSmall"
        sx={{ color: theme.colors.WHITE, py: 2 }}
      >
        {t('tlpDrawer.shareList', { ns: 'component' })}
      </Typography>
      <Stack
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          px: 4,
          py: 2,
          gap: 2,
          overflowY: 'auto',
        }}
      >
        {shareList.map(({ ofsId, type, name }) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            key={ofsId}
          >
            <TwoToneLabel
              keyText={type.charAt(0).toUpperCase() + type.slice(1)}
              valueText={name}
              mainColor={theme.colors.WHITE_60}
              textColor={theme.colors.BLACK_90}
            />
            <Button size="small" onClick={() => handleRemove(ofsId)}>
              {t('tlpDrawer.remove', { ns: 'component' })}
            </Button>
          </Box>
        ))}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '10rem' }}
          onClick={handleDone}
          loading={updateShareTargetLoading}
          disabled={updateShareTargetLoading}
        >
          {t('tlpDrawer.done', { ns: 'component' })}
        </Button>
      </Box>
      <Dialog
        open={warningDialogOpen}
        handleDialogClose={handleWarningDialogClose}
        title={t('tlpDialog.title', { ns: 'component' })}
        description={t('tlpDialog.description', { ns: 'component' })}
        confirmButtonText={t('yesCta', { ns: 'component' })}
        handleConfirmButtonClick={handleRemoveTeamT5}
      />
    </Drawer>
  )
}
