export type TToolIncluded = 'yara' | 'sigma' | 'snort' | 'other'

export type TToolType = 'yara_rules' | 'sigma_rules' | 'snort_rules' | 'blobs'

export interface ITool {
  id: number
  name: string
  date: number
  included: Record<TToolIncluded, number>
  purchasedInformation: {
    priceTagId: number
    credit: number
  } | null
}

export interface IToolAPIData {
  main_value: number
  name: string
  date: number
  included: Record<TToolIncluded, number>
  purchased_information?: {
    price_tag_id: number
    credit: number
  }
}

export const mapAPIToolsToState = (fromAPITools: IToolAPIData[]): ITool[] =>
  fromAPITools.map((fromAPITool) => ({
    id: fromAPITool.main_value,
    name: fromAPITool.name,
    date: fromAPITool.date,
    included: fromAPITool.included,
    purchasedInformation: fromAPITool.purchased_information
      ? {
          priceTagId: fromAPITool.purchased_information.price_tag_id,
          credit: fromAPITool.purchased_information.credit,
        }
      : null,
  }))
