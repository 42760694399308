import {
  Box,
  Divider,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Button } from 'components/Button/Button'
import { DateRangeSelectorPicker } from 'components/DatePicker/DateRangeSelectorPicker'
import {
  Select,
  SelectCheckboxItem,
  SelectItem,
} from 'components/Select/Select'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useAppDispatch } from 'hooks/useReduxHooks'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchDownloadTools,
  fetchDownloadToolsCancelled,
  resetDownloadTools,
} from 'store/slices/download'
import { TToolType } from 'store/types/entityTypes/tool'

interface IToolTypeOption {
  text: string
  value: TToolType
}

const toolTypeOptions: IToolTypeOption[] = [
  { text: 'YARA', value: 'yara_rules' },
  { text: 'Snort', value: 'snort_rules' },
  { text: 'Sigma', value: 'sigma_rules' },
  { text: 'Others', value: 'blobs' },
]

export const toolTypes = toolTypeOptions.map(({ value }) => value)

interface IToolsFilterBarProps {
  selectedTypes: TToolType[]
  setSelectedTypes: Dispatch<SetStateAction<TToolType[]>>
}

export const ToolsFilterBar = ({
  selectedTypes,
  setSelectedTypes,
}: IToolsFilterBarProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const { dateRangeOptionToTimes } = useDateTime()
  const rangeOptions = useDateRangeOptions()
  const dispatch = useAppDispatch()

  const targetOptions = [
    { text: t('allOption', { ns: 'download' }), value: 'all' },
    { text: 'Malware', value: 'malware' },
    { text: 'Vulnerability', value: 'vulnerability_insights_report' },
  ]

  const [selectedTarget, setSelectedTarget] = useState(targetOptions[0].value)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)

  const handleReset = () => {
    setRangeOption('all')
    setSelectedTarget('all')
    setSelectedTypes(toolTypes)
  }

  const handleTargetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTarget(event.target.value as string)
  }

  const handleTypeChange = (event: SelectChangeEvent<typeof selectedTypes>) => {
    const {
      target: { value },
    } = event

    if (value[value.length - 1] === 'all') {
      setSelectedTypes(
        selectedTypes.length === toolTypes.length ? [] : toolTypes
      )
      return
    }
    setSelectedTypes(value as TToolType[])
  }

  useEffect(() => {
    dispatch(
      fetchDownloadTools({
        // 若沒有選擇 tool types，還是會過濾 tool types = all 的資料
        toolTypes: selectedTypes.length > 0 ? selectedTypes : toolTypes,
        resourceType: selectedTarget === 'all' ? null : selectedTarget,
        ...dateRangeOptionToTimes({ startDate, endDate })[
          rangeOption as TRangeOption
        ],
      })
    )

    return () => {
      dispatch(fetchDownloadToolsCancelled())
      dispatch(resetDownloadTools())
    }
  }, [selectedTarget, selectedTypes, startDate, endDate, rangeOption])

  return (
    <Stack
      sx={{
        bgcolor: theme.colors.BLACK_60,
        p: 2,
        gap: 2,
      }}
    >
      <DateRangeSelectorPicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        rangeOption={rangeOption}
        setRangeOption={setRangeOption}
        width="12rem"
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('toolFilter.huntingTargetLabel', { ns: 'download' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Select value={selectedTarget} onChange={handleTargetChange}>
                {targetOptions.map((option, index) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      borderBottom:
                        index === 0
                          ? `1px solid ${theme.colors.WHITE_20}`
                          : 'none',
                    }}
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: theme.colors.WHITE_40 }}
          />
          <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('toolFilter.toolTypeLabel', { ns: 'download' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Select
                displayEmpty
                value={selectedTypes}
                onChange={handleTypeChange}
                multiple
                placeholder={t('toolFilter.pleaseSelect', { ns: 'download' })}
                renderValue={() => {
                  if (selectedTypes.length === 0) {
                    return t('toolFilter.pleaseSelect', { ns: 'download' })
                  }

                  if (selectedTypes.length === 4) {
                    return t('allOption', { ns: 'download' })
                  }

                  return selectedTypes
                    .map(
                      (selected) =>
                        toolTypeOptions.find(({ value }) => value === selected)
                          ?.text
                    )
                    .join(', ')
                }}
              >
                <SelectCheckboxItem
                  value="all"
                  checked={
                    toolTypes.length > 0 &&
                    selectedTypes.length === toolTypes.length
                  }
                  indeterminate={
                    selectedTypes.length > 0 &&
                    selectedTypes.length < toolTypes.length
                  }
                >
                  {t('allOption', { ns: 'download' })}
                </SelectCheckboxItem>
                <Divider
                  sx={{
                    bgcolor: theme.colors.WHITE_20,
                    '&.MuiDivider-root': {
                      m: 0,
                    },
                  }}
                />
                {toolTypeOptions.map((option) => (
                  <SelectCheckboxItem
                    key={option.value}
                    value={option.value}
                    checked={selectedTypes.indexOf(option.value) > -1}
                  >
                    {option.text}
                  </SelectCheckboxItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexShrink: 0 }}>
          <Button variant="textColor" onClick={handleReset}>
            {t('resetCta', { ns: 'component' })}
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}
