import { Table,TableContainer } from '@mui/material'
import { useState } from 'react'
import { IPMReportDetail } from 'store/types/entityTypes/report'

import { PMReportDetailTableBody } from './PMReportDetailTableBody'
import { PMReportDetailTableHeader } from './PMReportDetailTableHeader'

export type TOrder = 'asc' | 'desc'
export type TPMReportDetailOrderKeys = keyof Pick<
  IPMReportDetail,
  | 'id'
  | 'vendor'
  | 'product'
  | 'cvss'
  | 'threatLevel'
  | 'publiclyDisclosed'
  | 'exploited'
  | 'updatedAt'
>
export type TPMReportDetailKeys = keyof Pick<
  IPMReportDetail,
  | 'id'
  | 'vendor'
  | 'product'
  | 'cvss'
  | 'description'
  | 'threatLevel'
  | 'publiclyDisclosed'
  | 'exploited'
  | 'patch'
  | 'updatedAt'
>

export type TPMReportDetailFilterKeys = keyof Pick<
  IPMReportDetail,
  'id' | 'vendor' | 'product'
>
export type TFilterTextMap = Record<TPMReportDetailFilterKeys, string>

const initialFilterTextMap: TFilterTextMap = {
  id: '',
  vendor: '',
  product: '',
}

export const PMReportDetailTable = () => {
  // order
  const [order, setOrder] = useState<TOrder>('desc')
  const [orderBy, setOrderBy] =
    useState<TPMReportDetailOrderKeys>('threatLevel')
  const handleOrderChange = (newOrderBy: TPMReportDetailOrderKeys) => {
    const isAsc = orderBy === newOrderBy && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(newOrderBy)
  }

  // filter
  const [filterTextMap, setFilterTextMap] =
    useState<TFilterTextMap>(initialFilterTextMap)
  const handleFilterChange =
    (newFilterBy: TPMReportDetailOrderKeys) => (newFilterText: string) => {
      const newFilterTextMap = {
        ...filterTextMap,
        [newFilterBy]: newFilterText,
      }
      setFilterTextMap(newFilterTextMap)
    }

  return (
    <TableContainer
      sx={{
        height: '100%',
        minWidth: '77.5rem',
        // 畫面可視寬 - (navbar 寬 + 左右 padding)
        maxWidth: 'calc(100vw - 2.5rem)',
      }}
    >
      <Table stickyHeader>
        <PMReportDetailTableHeader
          order={order}
          orderBy={orderBy}
          handleOrderChange={handleOrderChange}
          filterTextMap={filterTextMap}
          handleFilterChange={handleFilterChange}
        />
        <PMReportDetailTableBody
          order={order}
          orderBy={orderBy}
          filterTextMap={filterTextMap}
        />
      </Table>
    </TableContainer>
  )
}
