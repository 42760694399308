import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_SEARCH_ADVERSARIES_DATA,
  FETCH_SEARCH_DOMAINS_DATA,
  FETCH_SEARCH_IPS_DATA,
  FETCH_SEARCH_MALWARES_DATA,
  FETCH_SEARCH_RAW_INTELS_DATA,
  FETCH_SEARCH_REPORTS_DATA,
  FETCH_SEARCH_SAMPLES_DATA,
  FETCH_SEARCH_TECHNIQUES_DATA,
} from 'constants/api'
import { chineseSearchTextMap } from 'constants/search'
import { ALL_REPORT_TYPES_QUERY } from 'constants/urlQuery'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchSearchAdversariesData,
  fetchSearchAdversariesDataCancelled,
  fetchSearchAdversariesDataFulfilled,
  fetchSearchAdversariesDataRejected,
  fetchSearchDomainsData,
  fetchSearchDomainsDataCancelled,
  fetchSearchDomainsDataFulfilled,
  fetchSearchDomainsDataRejected,
  fetchSearchIPsData,
  fetchSearchIPsDataCancelled,
  fetchSearchIPsDataFulfilled,
  fetchSearchIPsDataRejected,
  fetchSearchMalwaresData,
  fetchSearchMalwaresDataCancelled,
  fetchSearchMalwaresDataFulfilled,
  fetchSearchMalwaresDataRejected,
  fetchSearchRawIntelsData,
  fetchSearchRawIntelsDataCancelled,
  fetchSearchRawIntelsDataFulfilled,
  fetchSearchRawIntelsDataRejected,
  fetchSearchReportsData,
  fetchSearchReportsDataCancelled,
  fetchSearchReportsDataFulfilled,
  fetchSearchReportsDataRejected,
  fetchSearchSamplesData,
  fetchSearchSamplesDataCancelled,
  fetchSearchSamplesDataFulfilled,
  fetchSearchSamplesDataRejected,
  fetchSearchTechniquesData,
  fetchSearchTechniquesDataCancelled,
  fetchSearchTechniquesDataFulfilled,
  fetchSearchTechniquesDataRejected,
} from 'store/slices/search'
import { mapAPIAdversariesToState } from 'store/types/entityTypes/adversary'
import { mapAPITechniquesToState } from 'store/types/entityTypes/capability'
import { mapAPIDomainsToState } from 'store/types/entityTypes/domain'
import { mapAPIIPsToState } from 'store/types/entityTypes/ip'
import { mapAPIMalwaresToState } from 'store/types/entityTypes/malware'
import { mapAPIRawIntelsToState } from 'store/types/entityTypes/rawIntel'
import {
  mapAPIReportsToState,
  mapAPIReportTypesFromReportTypes,
  TSearchReportType,
} from 'store/types/entityTypes/report'
import { mapAPISamplesToState } from 'store/types/entityTypes/sample'
import {
  IFetchSearchAdversariesResponse,
  IFetchSearchDomainsResponse,
  IFetchSearchIPsResponse,
  IFetchSearchMalwaresResponse,
  IFetchSearchRawIntelsResponse,
  IFetchSearchReportsResponse,
  IFetchSearchSamplesResponse,
  IFetchSearchTechniquesResponse,
  TSearchResultKey,
} from 'store/types/slicesTypes/search'
import { fetchData } from 'utils/fetch.utils'
import { getArrayQueryString } from 'utils/queryString'

import { checkUnauthorizedToken } from '../auth'

const getReportsFetchingFunctionByUrl = (url: string) =>
  fetchData<IFetchSearchReportsResponse>({ url })

// 利用 searchText 組合多個抓 count 的 ajax functions，要傳入 combineLatest 一起併發
const getFetchSearchDataMap = ({
  searchText,
  searchReportTypeOptions = [],
  offset = 0,
  dateFrom,
  dateTo,
}: {
  searchText: string
  searchReportTypeOptions?: TSearchReportType[]
  offset: number
  dateFrom?: number | null
  dateTo?: number | null
}): Record<
  TSearchResultKey,
  Observable<
    AjaxResponse<
      | IFetchSearchReportsResponse
      | IFetchSearchAdversariesResponse
      | IFetchSearchMalwaresResponse
      | IFetchSearchSamplesResponse
      | IFetchSearchIPsResponse
      | IFetchSearchDomainsResponse
      | IFetchSearchTechniquesResponse
      | IFetchSearchRawIntelsResponse
    >
  >
> => {
  const query = chineseSearchTextMap[searchText] || searchText

  const ALL_SEARCH_REPORT_TYPES_COUNT = 5
  let urlParams = `query=${encodeURIComponent(query)}&offset=${offset}`

  // filter all reports 需加上 types[] = on_demand
  if (searchReportTypeOptions.length === ALL_SEARCH_REPORT_TYPES_COUNT) {
    urlParams += `&${ALL_REPORT_TYPES_QUERY}`
  } else {
    urlParams += `&${getArrayQueryString({
      types: searchReportTypeOptions.map(
        (option: TSearchReportType) => mapAPIReportTypesFromReportTypes[option]
      ),
    })}`
  }

  if (dateFrom) {
    urlParams += `&date[from]=${dateFrom}`
  }
  if (dateTo) {
    urlParams += `&date[to]=${dateTo}`
  }

  return {
    reports: getReportsFetchingFunctionByUrl(
      `${FETCH_SEARCH_REPORTS_DATA}?${urlParams}`
    ),
    adversaries: fetchData<IFetchSearchAdversariesResponse>({
      url: `${FETCH_SEARCH_ADVERSARIES_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    malware: fetchData<IFetchSearchMalwaresResponse>({
      url: `${FETCH_SEARCH_MALWARES_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    samples: fetchData<IFetchSearchSamplesResponse>({
      url: `${FETCH_SEARCH_SAMPLES_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    ips: fetchData<IFetchSearchIPsResponse>({
      url: `${FETCH_SEARCH_IPS_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    domains: fetchData<IFetchSearchDomainsResponse>({
      url: `${FETCH_SEARCH_DOMAINS_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    techniques: fetchData<IFetchSearchTechniquesResponse>({
      url: `${FETCH_SEARCH_TECHNIQUES_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
    rawIntels: fetchData<IFetchSearchRawIntelsResponse>({
      url: `${FETCH_SEARCH_RAW_INTELS_DATA}?query=${encodeURIComponent(
        query
      )}&offset=${offset}`,
    }),
  }
}

const reportsDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchReportsData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).reports.pipe(
        map((response: AjaxResponse<IFetchSearchReportsResponse>) =>
          fetchSearchReportsDataFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSearchReportsDataCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchReportsDataRejected(error))
          )
        )
      )
    )
  )

const adversariesDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchAdversariesData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).adversaries.pipe(
        map((response: AjaxResponse<IFetchSearchAdversariesResponse>) =>
          fetchSearchAdversariesDataFulfilled(
            mapAPIAdversariesToState(response.response.adversaries)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchAdversariesDataCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchAdversariesDataRejected(error))
          )
        )
      )
    )
  )

const malwaresDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchMalwaresData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).malware.pipe(
        map((response: AjaxResponse<IFetchSearchMalwaresResponse>) =>
          fetchSearchMalwaresDataFulfilled(
            mapAPIMalwaresToState(response.response.malwares)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSearchMalwaresDataCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchMalwaresDataRejected(error))
          )
        )
      )
    )
  )

const samplesDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchSamplesData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).samples.pipe(
        map((response: AjaxResponse<IFetchSearchSamplesResponse>) =>
          fetchSearchSamplesDataFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSearchSamplesDataCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchSamplesDataRejected(error))
          )
        )
      )
    )
  )

const ipsDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchIPsData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).ips.pipe(
        map((response: AjaxResponse<IFetchSearchIPsResponse>) =>
          fetchSearchIPsDataFulfilled(mapAPIIPsToState(response.response.ips))
        ),
        takeUntil(action$.pipe(filter(fetchSearchIPsDataCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchIPsDataRejected(error))
          )
        )
      )
    )
  )

const domainsDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchDomainsData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).domains.pipe(
        map((response: AjaxResponse<IFetchSearchDomainsResponse>) =>
          fetchSearchDomainsDataFulfilled(
            mapAPIDomainsToState(response.response.domains)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSearchDomainsDataCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchDomainsDataRejected(error))
          )
        )
      )
    )
  )

const techniquesDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchTechniquesData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).techniques.pipe(
        map((response: AjaxResponse<IFetchSearchTechniquesResponse>) =>
          fetchSearchTechniquesDataFulfilled(
            mapAPITechniquesToState(response.response.techniques)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchTechniquesDataCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchTechniquesDataRejected(error))
          )
        )
      )
    )
  )

const rawIntelsDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchRawIntelsData.match),
    switchMap((action) =>
      getFetchSearchDataMap(action.payload).rawIntels.pipe(
        map((response: AjaxResponse<IFetchSearchRawIntelsResponse>) =>
          fetchSearchRawIntelsDataFulfilled(
            mapAPIRawIntelsToState(response.response.raw_intels)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchRawIntelsDataCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchRawIntelsDataRejected(error))
          )
        )
      )
    )
  )

export const searchDataEpic = combineEpics(
  reportsDataEpic,
  adversariesDataEpic,
  malwaresDataEpic,
  samplesDataEpic,
  ipsDataEpic,
  domainsDataEpic,
  techniquesDataEpic,
  rawIntelsDataEpic
)
