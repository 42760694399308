import { Box, Typography, useTheme } from '@mui/material'

export const InlineBlock = ({ text }: { text: string }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        px: 2,
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        bgcolor: theme.colors.BLACK_85,
        width: 'fit-content',
        display: 'inline-flex',
        height: '1.25rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="reportInlineTextSmall"
        sx={{ color: theme.colors.WHITE }}
      >
        {text}
      </Typography>
    </Box>
  )
}
