import { SomethingWrong } from 'components/Errors/SomethingWrong'
import { useTranslation } from 'react-i18next'

interface INoResultContentProps {
  text?: string
}

export const NoResultContent = ({ text = '' }: INoResultContentProps) => {
  const { t } = useTranslation(['search'])
  return (
    <SomethingWrong
      text={
        text.length !== 0
          ? text
          : t('downloadList.emptyState.noItemsMatch', { ns: 'search' })
      }
    />
  )
}
