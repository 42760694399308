import { Box, Stack } from '@mui/material'
import EulaImage from 'assets/documentImages/eula.jpg'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H3Title } from 'components/Documents/H3Title'
import { DateLabel } from 'components/Label/Label'
import {
  NumberListItem,
  OrderedList,
  SubNumberListItem,
} from 'components/List/List'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_EULA_CHINESE } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'

export const EulaZhPage = () => {
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_EULA_CHINESE}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title title="End User License Agreement ThreatVision" />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.EULA_DOCUMENT_TIMESTAMP
              )}
            />
            <Image src={EulaImage} alt="eula" height="25rem" fit="cover" />
            <H3Title title="重要：" />
            <Description>
              請先詳讀下列條款與條件。存取或使用本產品，即表示貴用戶同意本授權合約的條款與條件。若貴用戶不同意本「合約」所有的條款與條件，請勿使用本「產品」。
            </Description>
            <H3Title title="使用者授權合約" />
            <Description>
              本使用者授權合約（以下稱「本合約」）由Team T5
              Inc.，乃係依中華民國臺灣法律組織設立，主要營業地位於臺灣臺北市松山區光復北路11巷46號15樓之公司（以下稱「TeamT5」或「提供者」）與貴用戶，乃係自然人或法人（以下稱「貴用戶」或「使用者」）雙方所訂定。
            </Description>
            <Description>
              請注意，本「合約」是給使用者的授權合約，不是購買合約。「提供者」持續擁有本「產品」、本「產品」複本、含有本「產品」的實體媒體，以及「使用者」依本「合約」授權製作的任何其他複本。
            </Description>
            <Description>
              貴用戶同意使用本「產品」即表示貴用戶已閱讀本「合約」、理解本「合約」內容，並願受本「合約」條款與條件的約束。
            </Description>
            <OrderedList>
              <NumberListItem>
                定義。
                <OrderedList>
                  <SubNumberListItem>
                    附屬機構。係指一法人之控股公司、其子公司、其任何控股公司之子公司，以及其控股公司所控制之實體。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    產品。本「合約」中的「產品」一詞係指：
                    <OrderedList>
                      <>
                        <SubNumberListItem level={3}>
                          依「訂閱合約」，由TeamT5提供予「訂戶」，本「合約」所隨附之進階持續性威脅（APT）活動研究、開源威脅情資分析及其他網路威脅情資及其包含的所有相關程序、平台或工具；
                        </SubNumberListItem>
                        <SubNumberListItem level={3}>
                          由「提供者」根據本「合約」授權給「訂戶」的（若有的話）本「產品」複本、本「產品」修改版和本「產品」的更新。
                        </SubNumberListItem>
                      </>
                    </OrderedList>
                  </SubNumberListItem>
                  <SubNumberListItem>
                    訂戶。「訂戶」係指訂閱本「產品」之自然人或法人。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    訂閱合約。在本「合約」約定之前或同時，「訂戶」取得一個或數個本「產品」授權的制式表單或其他書面通訊。
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                授權。若貴用戶同意遵循在此所有的條款與條件，「提供者」授與貴用戶以下權限（以下稱「授權」）：
                <OrderedList>
                  <SubNumberListItem>
                    使用權利。除別有約定外，貴用戶擁有非專屬、不可轉讓及不可轉授權之權限，可使用及儲存本「產品」。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    授權限制。本「產品」的授權數及使用權限受「訂閱合約」的限制。貴用戶得在「提供者」已授權且僅限依「訂閱合約」獲「提供者」授權的範圍內使用本「產品」。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    終止授權。若訂閱合約有授權期限者，在授權期結束時，本「授權」會自動終止。如果貴用戶無法遵循本「合約」中的任何規定，「提供者」有權利在不危害「提供者」任何權利或法律救濟的情況下終止本「合約」。本「授權」終止時，貴用戶必須立即停止存取或使用本「產品」。授權終止時，「提供者」有權取消「使用者」對本「產品」功能之使用權。
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                資料蒐集和網際網路連線。為本「產品」之目的，有必要蒐集、處理、儲存及傳輸資料，使「提供者」能夠識別「訂戶」或貴用戶的身份並履行本「合約」。貴用戶瞭解，為本「產品」之功能及目的，「訂戶」或貴用戶的資料必須在本「產品」及／或「提供者」的電腦系統之間進行通訊時傳送，以確保使用本「產品」的授權。
              </NumberListItem>
              <NumberListItem>
                「使用者」權利。貴用戶必須由本人或員工行使「使用者」權利。貴用戶僅得依已取得使用本「產品」之授權目的使用本「產品」。
              </NumberListItem>
              <NumberListItem>
                權利限制。貴用戶不得將本「產品」複製、散佈、提取其元件或建立其衍生版本。使用本「產品」時，貴用戶必須遵循下列限制：
                <OrderedList>
                  <SubNumberListItem>
                    貴用戶只能將本「產品」用作內部參考，除有限引用外，不得向公眾披露本「產品」的任何部分。有限引用是指，貴用戶只能在為貴用戶的客戶或客戶準備的文件中引用或應用本「產品」的一部分，並且貴用戶應註明TeamT5的名稱為引用來源。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    除本「合約」另有約定外，貴用戶不得使用、修改、翻譯或重製本「產品」，或轉讓本「產品」或其複本的使用權。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    貴用戶不得轉讓、出售、轉授權、出租、出借或借用本「產品」。
                  </SubNumberListItem>
                  <SubNumberListItem>
                    貴用戶同意僅以符合貴用戶使用本「產品」所在地所適用法律之方式使用本「產品」，包括但不限於與著作權法及其他智慧財產權相關的適用限制。
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                著作權。本「產品」及其所有權利（包括但不限於專利權及智慧財產權）皆為TeamT5及/或其授權提供者所有，並受國際條約之條款及所有「產品」使用所在國家適用法律之保護。本「產品」之結構、組織及程式碼是TeamT5及/或其授權者的重要商業秘密及機密資訊。除TeamT5另有同意外，貴用戶不得複製本「產品」。任何依據本「合約」允許貴用戶產生之複本，必須包含與本「產品」相同的著作權或其他所有權聲明。若貴用戶違反本「合約」條款，對本「產品」進行反向工程、反向組譯、解譯，或嘗試發現本「產品」的來源程式碼，則貴用戶同意從這類資訊產生的時刻起，所取得的任何資訊會自動且無法撤回地轉讓給「提供者」並由其完全擁有，儘管這些權利違反本「合約」。
              </NumberListItem>
              <NumberListItem>
                保留權利。除本「合約」明確授予貴用戶身為本「產品」之「使用者」的權利外，「提供者」保留本「產品」的所有權利。
              </NumberListItem>
              <NumberListItem>
                數種語言版本、多個複本。若本「產品」支援數個平台或語言，或貴用戶取得本「產品」多個複本，則只有貴用戶所取得「授權」數目的電腦系統與版本能使用本「產品」。貴用戶不得出售、出租、轉授權、出借或移轉貴用戶未使用本「產品」之任何版本或複本。
              </NumberListItem>
              <NumberListItem>
                使用者聲明。貴用戶身為「使用者」，瞭解本「產品」係依「現狀」提供，在相關法律所允許之最大範圍內無任何類型的擔保，無論明示或默示。「提供者」、其授權提供者、附屬機構或著作權人皆不提供任何明示或默示聲明或擔保，包括但不限於適售性或特定用途之適用性，亦不擔保本「產品」不侵害第三方之專利、著作權、商標或其他權利。「提供者」及任何其他人不擔保本「產品」功能符合貴用戶之需求，亦不擔保本「產品」作業不會中斷或無錯誤。對於選擇使用本「產品」是否獲得預期結果，以及對「產品」的存取、使用與結果，皆由貴用戶承擔所有責任與風險。
              </NumberListItem>
              {/* style for tens digits */}
              <NumberListItem tensDigits>
                產品支援。
                <OrderedList>
                  <SubNumberListItem level={2}>
                    Team T5
                    為貴用戶提供有關啓動、安裝、設置、技術故障排除和貴用戶可能對「產品」提出的一般問題的電話和電子郵件支援。貴用戶的詢問將在通常營業時間內被接收和答覆。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    除另有約定外，TeamT5或TeamT5委託的第三方得酌情提供技術支援，不提供任何保證或聲明。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    TeamT5及/或TeamT5委任的第三方保留答覆詢問是否超過產品支援範圍的權利。TeamT5及/或TeamT5委任的第三方保留酌情拒絕、暫停或終止提供產品支援的權利。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    依照隱私權政策，可能需要授權資訊和其他資料，以便用於產品支援。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    TeamT5得自行決定安排不時向貴用戶提供「產品」的更新，其中可能包括次要的功能增強、錯誤修復或性能或錯誤更正。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    對於因提供技術支援所導致任何資料、財產或硬體的損壞或遺失，或利潤的損失，TeamT5及/或TeamT5委任的第三方概不負責。
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem tensDigits>
                無其他義務。除本「合約」特別列出的義務之外，本「合約」對TeamT5及/或TeamT5委託的第三方無任何其他義務要求。
              </NumberListItem>
              <NumberListItem tensDigits>
                責任限制。在相關法律所允許之最大範圍內，在任何情況下，對於因使用或無法使用本「產品」所導致的利潤收入損失、銷售額損失、資料遺失、採購備用商品或服務之額外費用、財產損失、人身傷害、業務中止、商業資訊遺失，或任何特殊、直接、間接、意外、經濟、遮掩、犯罪、特殊或衍生之損害，無論其導致方式為何以及是否因合約、過失、疏忽或其他責任理論所引起，「提供者」、其員工或授權提供者概不負責，即使已告知「提供者」、其授權提供者或附屬機構可能會發生此類損失。因為部分國家或管轄區不允許免除責任，但允許限制責任，在此情況下，「提供者」、其員工、授權提供者或附屬機構的責任以貴用戶已付「授權」費用之總額為限。
              </NumberListItem>
              <NumberListItem tensDigits>
                通知。除另有約定外，依據本「合約」所有的通知必須遞送至：Team T5
                Inc.，地址是「臺灣臺北市松山區光復北路11巷46號15樓」。
              </NumberListItem>
              <NumberListItem tensDigits>
                準據法。本「合約」依中華民國台灣的法律解釋與適用。「使用者」及「提供者」同意，本「合約」將不適用衝突法原則與《聯合國國際商品買賣公約》。貴用戶明確同意任何因本「合約」所導致之有關「提供者」或任何與使用本「產品」相關的爭議或求償，均在臺灣臺北地方法院處理，貴用戶亦明確同意該法院有管轄權。
              </NumberListItem>
              <NumberListItem tensDigits>
                一般條款。
                <OrderedList>
                  <SubNumberListItem level={2}>
                    本「合約」為貴用戶和「提供者」之間與本「產品」相關的完整「合約」，取代之前與本「產品」相關的任何聲明、討論、保證、通訊或廣告。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    如果本「合約」的任何部分經有管轄權的法院或其他主管機關認定為無效、不合法或不可執行，則該部分將從本「合約」的其餘部分中分離出來，依據本「合約」規定之條件，該其餘部分仍有效並可執行。該無效或不可執行的部分或條款，在可能的範圍內，應替換為可完成原始商業目的或者以有效且可執行的方式實現的條款。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    本「合約」只能以書面形式進行修改，該書面應由「提供者」的授權代表或依委託書條款明確授權之人簽署。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    第6、7、9、10、11與14條條款於本「合約」終止後仍繼續適用。
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    如本「合約」的各個不同語言版本之間出現差異，以英文版本為準。
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
            </OrderedList>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
