import { combineEpics } from 'redux-observable'

import { accountEpic } from './account'
import { adversaryEpic } from './adversary'
import { authEpic } from './auth'
import { capabilityEpic } from './capability'
import { commentEpic } from './comment'
import { ddwEpic } from './ddw'
import { domainEpic } from './domain'
import { downloadEpic } from './download'
import { ipEpic } from './ip'
import { landingEpic } from './landing'
import { malwareEpic } from './malware'
import { purchaseEpic } from './purchase'
import { rawIntelEpic } from './rawIntel'
import { reportEpic } from './report'
import { sampleEpic } from './sample'
import { sampleDetailEpic } from './sampleDetail'
import { searchEpic } from './search'
import { tabContentEpic } from './tabContent'
import { threatLandscapeEpic } from './threatLandscape'

export default combineEpics(
  landingEpic,
  authEpic,
  searchEpic,
  threatLandscapeEpic,
  malwareEpic,
  sampleEpic,
  sampleDetailEpic,
  tabContentEpic,
  accountEpic,
  commentEpic,
  reportEpic,
  downloadEpic,
  adversaryEpic,
  ipEpic,
  domainEpic,
  purchaseEpic,
  capabilityEpic,
  rawIntelEpic,
  ddwEpic
)
