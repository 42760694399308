import { Box, Stack, useTheme } from '@mui/material'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { getPageTitleIPDetailByAddress } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useOutOfAAPDialog } from 'hooks/useOutOfAAPDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchIPDetail,
  fetchIPDetailCancelled,
  fetchIPStatusCancelled,
  resetIPAnalysisStatus,
  resetIPDetail,
  selectIPAnalysisStatus,
  selectIPDetail,
  selectIPDetailLoading,
} from 'store/slices/ip'
import {
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  resetTabCounts,
} from 'store/slices/tabContent'
import { TCommentType } from 'store/types/entityTypes/comment'

import { useIpCategoryTabs } from './IPComponents/ipContent.config'
import { IPInfo } from './IPComponents/IPInfo'
import { AnalyzingInfo } from './IPDomainComponents/AnalyzingInfo'
import { CategoryTabs } from './IPDomainComponents/CategoryTabs'

const commentableType: TCommentType = 'Network::Ip'

export const IPPage = () => {
  useGoBack()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const ip = searchParams.get(`${SearchParamKey.IP}`)
  const navigate = useNavigate()
  const ipCategoryTabs = useIpCategoryTabs()
  const isIPAnalyzed = useAppSelector(selectIPAnalysisStatus)
  const ipDetail = useAppSelector(selectIPDetail)
  const isIPDetailLoading = useAppSelector(selectIPDetailLoading)
  const { renderOutAAPDialog, outOfAAPDialogOpen, handleOutOfAAPDialogOpen } =
    useOutOfAAPDialog({
      needGoBack: true,
    })

  const defaultCategoryTab = ipCategoryTabs.filter(
    ({ count }) => count !== 0
  )[0]
  const categoryTabParam = searchParams.get(SearchParamKey.IP_TAB)
  const categoryTab =
    (categoryTabParam &&
      ipCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const BottomContent = categoryTab.component

  useEffect(() => {
    if (!ip) navigate(`/${NOT_FOUND_ROUTE}`)
  }, [])

  // 有 ip detail 之後再去抓各種資料的 count
  useEffect(() => {
    if (ip && ipDetail) {
      dispatch(
        setupCommentableIdAndType({
          commentableType,
          commentableId: ip,
        })
      )
      dispatch(
        fetchComments({
          commentableType,
          commentableId: ip,
        })
      )
      dispatch(fetchTabReportCount(ip))
      dispatch(fetchTabAdversaryCount(ip))
      dispatch(fetchTabSampleCount(ip))
    }

    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabAdversaryCountCancelled())
      dispatch(fetchTabSampleCountCancelled())
      dispatch(resetTabCounts())
      dispatch(resetComments())
    }
  }, [ip, Boolean(ipDetail)])

  // 初次以及當 ip 改變時，要去抓 ip detail
  useEffect(() => {
    if (ip) {
      dispatch(
        fetchIPDetail({ ip, outOfAAPCallback: handleOutOfAAPDialogOpen })
      )
    }

    return () => {
      dispatch(fetchIPDetailCancelled())
      dispatch(fetchIPStatusCancelled())
      dispatch(resetIPAnalysisStatus())
      dispatch(resetIPDetail())
    }
  }, [ip])

  if (!ip) {
    return null
  }

  const isIPReady = !isIPDetailLoading && isIPAnalyzed && ipDetail

  return (
    <>
      <Helmet>
        <title>{getPageTitleIPDetailByAddress(ip)}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack sx={{ p: 1, gap: 1, height: '100%', width: '78rem' }}>
          {isIPReady ? <IPInfo /> : <AnalyzingInfo title={ip} />}
          {isIPReady && !outOfAAPDialogOpen && (
            <PageTabsContent
              tabs={
                <CategoryTabs
                  categoryTab={categoryTab}
                  tabs={ipCategoryTabs}
                  searchParamKey={SearchParamKey.IP_TAB}
                />
              }
              content={<BottomContent detailType="ip" searchText={ip} />}
              maxWidth={
                categoryTab.key === 'dns-records' || categoryTab.key === 'info'
                  ? '100%'
                  : undefined
              }
            />
          )}
          {renderOutAAPDialog}
        </Stack>
      </Box>
    </>
  )
}
