import { Box, Divider, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const LATEST_REPORT_LOADING_TEST_ID = 'latest-report-loading'

const LoadingBlock = () => (
  <Stack sx={{ px: 4, py: 2, gap: 3, width: '100%' }}>
    <Skeleton type="title" width="26.5rem" />
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="body" width="13.5rem" />
        <Skeleton type="body" width="20rem" />
        <Skeleton type="body" width="16.5rem" />
      </Stack>
      <Skeleton type="image" width="10rem" />
    </Box>
    <Stack sx={{ gap: 2, pb: 13 }}>
      <Skeleton type="body" width="13.5rem" />
      <Skeleton type="body" width="13.5rem" />
      <Skeleton type="body" width="13.5rem" />
    </Stack>
  </Stack>
)

export const LatestReportsLoading = () => {
  const theme = useTheme()

  return (
    <Stack data-testid={LATEST_REPORT_LOADING_TEST_ID}>
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20, mb: 1 }} />
      <LoadingBlock />
    </Stack>
  )
}
