import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const LoadingBlock = () => {
  const theme = useTheme()
  return (
    <Stack sx={{ gap: 2, p: 1, bgcolor: theme.colors.BLACK_90 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Skeleton type="body" width="4.4375rem" />
        <Skeleton type="body" width="5rem" />
      </Box>
      <Skeleton type="body" width="20rem" />
    </Stack>
  )
}

export const DownloadToolsLoading = () => (
  <Stack sx={{ gap: 1, p: 1 }}>{generateSkeletons(9, <LoadingBlock />)}</Stack>
)
