import { useTheme } from '@mui/material'

export const useAvatarColor = (userEmail: string | null) => {
  const theme = useTheme()
  if (!userEmail) {
    return ''
  }

  const avatarBgcolor = [
    theme.colors.Avatar01,
    theme.colors.Avatar02,
    theme.colors.Avatar03,
    theme.colors.Avatar04,
    theme.colors.Avatar05,
    theme.colors.Avatar06,
    theme.colors.Avatar07,
    theme.colors.Avatar08,
    theme.colors.Avatar09,
    theme.colors.Avatar10,
  ]

  return avatarBgcolor[userEmail.length % 10]
}
