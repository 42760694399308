import {
  Link as MuiLink,
  LinkProps,
  SxProps,
  Theme,
  TypographyProps,
  useTheme,
} from '@mui/material'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type TLinkSize = 'extraSmall' | 'small' | 'large' | 'medium'

export interface ITextLinkProps extends LinkProps {
  to: string
  children: NonNullable<ReactNode>
  size?: TLinkSize
  disabled?: boolean
}

const getSharedColorStyles = (
  theme: Theme,
  disabled: boolean,
  sx: SxProps
): SxProps => [
  {
    wordBreak: 'break-all',
    cursor: disabled ? 'default' : 'pointer',
    color: disabled ? theme.colors.DISABLE : theme.colors.TEXT_LINK_NORMAL,
    textDecorationColor: disabled
      ? theme.colors.DISABLE
      : theme.colors.TEXT_LINK_NORMAL,
    '&:hover': {
      textDecorationColor: disabled
        ? theme.colors.DISABLE
        : theme.colors.WHITE_60,
      color: disabled ? theme.colors.DISABLE : theme.colors.WHITE_60,
    },
    '&:active': {
      textDecorationColor: disabled
        ? theme.colors.DISABLE
        : theme.colors.PRIMARY,
      color: disabled ? theme.colors.DISABLE : theme.colors.PRIMARY,
    },
  },
  ...(Array.isArray(sx) ? sx : [sx]),
]

const sizeTypographyMap: Record<TLinkSize, TypographyProps['variant']> = {
  extraSmall: 'textLinkS',
  small: 'textLink',
  medium: 'textLinkL',
  large: 'reportInlineTextLink',
}

export const TextLink = ({
  to,
  size = 'small',
  children,
  disabled = false,
  sx = [],
  ...props
}: ITextLinkProps) => {
  const theme = useTheme()

  return (
    <MuiLink
      variant={sizeTypographyMap[size]}
      component={RouterLink}
      to={disabled ? '' : to}
      sx={getSharedColorStyles(theme, disabled, sx)}
      {...props}
    >
      {children}
    </MuiLink>
  )
}

export interface IExternalLinkProps extends LinkProps {
  href: string
  children: NonNullable<ReactNode>
  size?: TLinkSize
  disabled?: boolean
}

export const ExternalLink = ({
  href,
  size = 'small',
  children,
  disabled = false,
  sx = [],
  ...props
}: IExternalLinkProps) => {
  const theme = useTheme()

  return (
    <MuiLink
      variant={sizeTypographyMap[size]}
      href={disabled ? undefined : href}
      sx={getSharedColorStyles(theme, disabled, sx)}
      target="_blank"
      {...props}
    >
      {children}
    </MuiLink>
  )
}
