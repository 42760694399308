import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import UnsubscribeMaskImage from 'assets/report/unsubscribe-mask.png'
import { DateLabel, ReportTypeLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { ReportPurchaseCta } from 'components/Report/ReportPurchaseCta'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { TTargetType } from 'pages/ThreatLandscapePage/TargetBlock'
import { useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  fetchCountryRelatedReports,
  fetchIndustryRelatedReports,
  fetchRegionRelatedReports,
  fetchRelatedReportsCancelled,
  selectRelatedReports,
  selectRelatedReportsLoading,
} from 'store/slices/threatLandscape'
import { IReport } from 'store/types/entityTypes/report'

import { Loading } from './Loading'

interface IReportItem {
  report: IReport
  highlightText: string
}

const ReportItem = ({ report, highlightText }: IReportItem) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  return (
    <Stack
      sx={{
        pt: 1,
        pb: 4,
        px: 4,
        overflowWrap: 'anywhere',
        cursor: report.permission ? 'pointer' : 'default',
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
      to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${report.alias}`}
      component={report.permission ? Link : Stack}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 1 }}>
        <DateLabel text={getDateStringByTimestampsInSeconds(report.date)} />
        <ReportTypeLabel reportType={report.type} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {report.title}
        </Typography>
      </Box>
      <Box>
        {report.permission ? (
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            <Highlighter
              textToHighlight={
                report.digest.length > 600
                  ? `${report.digest.slice(0, 600)}...`
                  : report.digest
              }
              searchWords={[highlightText]}
              caseSensitive={false}
              highlightStyle={{
                color: theme.colors.HIGH_LIGHT,
                backgroundColor: 'inherit',
              }}
            />
          </Typography>
        ) : (
          <Box
            sx={{
              cursor: report.permission ? 'pointer' : 'default',
              position: 'relative',
            }}
          >
            <Image src={UnsubscribeMaskImage} alt="Unsubscribe" />
            <ReportPurchaseCta
              purchasedInformation={report.purchasedInformation}
              alias={report.alias}
            />
          </Box>
        )}
      </Box>
    </Stack>
  )
}

interface IRelatedReports {
  displayName: string
  name: string
  targetType: TTargetType
}

export const RELATED_REPORT_CONTAINER_TEST_ID = 'related-report-container'

const RelatedReports = ({ displayName, name, targetType }: IRelatedReports) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation(['threatLandscapes', 'component'])
  const relatedReportsLoading = useAppSelector(selectRelatedReportsLoading)
  const relatedReports = useAppSelector(selectRelatedReports)

  useEffect(() => {
    if (targetType === 'country') {
      dispatch(fetchCountryRelatedReports(name))
    } else if (targetType === 'industry') {
      dispatch(fetchIndustryRelatedReports(name))
    } else if (targetType === 'region') {
      dispatch(fetchRegionRelatedReports(name))
    }
    return () => {
      dispatch(fetchRelatedReportsCancelled())
    }
  }, [targetType, name])

  return (
    <PageSection title={t('relatedReports', { ns: 'threatLandscapes' })}>
      <Box data-testid={RELATED_REPORT_CONTAINER_TEST_ID} />
      {relatedReportsLoading ? (
        <Loading />
      ) : relatedReports.length > 0 ? (
        <Box sx={{ p: 2 }}>
          {relatedReports.map((report, index) => (
            <Box key={report.id}>
              {index !== 0 && (
                <Divider sx={{ borderColor: theme.colors.WHITE_20, mb: 2 }} />
              )}
              <ReportItem report={report} highlightText={displayName} />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography
          variant="titleSmall"
          sx={{ color: theme.colors.WHITE_60, p: 2 }}
        >
          {t('emptyState.noRelatedResult', { ns: 'component' })}
        </Typography>
      )}
    </PageSection>
  )
}

export default RelatedReports
