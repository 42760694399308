import { Box, CircularProgress } from '@mui/material'
import InfiniteScroll, { Props } from 'react-infinite-scroll-component'

export const InfiniteScrollWrapper = (
  props: Omit<Props, 'loader' | 'style'>
) => (
  <InfiniteScroll
    {...props}
    loader={
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    }
    style={{ overflow: 'hidden' }}
  />
)
