import { Box, useTheme } from '@mui/material'
import { Button } from 'components/Button/Button'
import { DateRangeSelectorPicker } from 'components/DatePicker/DateRangeSelectorPicker'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface IFilterBarProps {
  startDate: Date | null
  setStartDate: Dispatch<SetStateAction<Date | null>>
  endDate: Date | null
  setEndDate: Dispatch<SetStateAction<Date | null>>
  rangeOption: string
  setRangeOption: Dispatch<SetStateAction<string>>
}

export const FilterBar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  rangeOption,
  setRangeOption,
}: IFilterBarProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  const handleReset = () => setRangeOption('all')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 4,
        py: 2,
        bgcolor: theme.colors.BLACK_95,
      }}
    >
      <DateRangeSelectorPicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        rangeOption={rangeOption}
        setRangeOption={setRangeOption}
      />
      <Button variant="textColor" onClick={handleReset}>
        {t('resetCta', { ns: 'component' })}
      </Button>
    </Box>
  )
}
