import { Box, CircularProgress } from '@mui/material'

interface ICenterCircularProgressProps {
  scrollRef?: (node?: Element | null | undefined) => void
}

/**
 * @param scrollRef 讓 infinite scroll 來偵測此 loading ui 是否有到畫面內，來決定是否 fetch more
 */
export const CenterCircularProgress = ({
  scrollRef,
}: ICenterCircularProgressProps) => (
  <Box
    ref={scrollRef}
    sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}
  >
    <CircularProgress />
  </Box>
)
