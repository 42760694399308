import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { ExternalLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { useTranslation } from 'react-i18next'
import { IPMReportDetail } from 'store/types/entityTypes/report'
import { checkIsValidURL } from 'utils/checkString'

interface IDescriptionDrawerProps {
  selectedCVE: string
  pmReportDetail: IPMReportDetail[]
  descriptionDrawerOpen: boolean
  onClose: () => void
}
export const DescriptionDrawer = ({
  selectedCVE,
  pmReportDetail,
  descriptionDrawerOpen,
  onClose,
}: IDescriptionDrawerProps) => {
  const { t } = useTranslation(['report'])
  const theme = useTheme()
  const selectedPMReportDetail = pmReportDetail.find(
    ({ id }) => id === selectedCVE
  )

  return (
    <Drawer
      open={descriptionDrawerOpen}
      onClose={onClose}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: theme.colors.MASK,
        },
      }}
    >
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {selectedCVE}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Stack sx={{ py: 2, gap: 2 }}>
        <Typography
          variant="reportH2"
          sx={{ color: theme.colors.WHITE }}
          component="h2"
        >
          {selectedPMReportDetail && selectedPMReportDetail.description.title}
        </Typography>
        <Typography
          variant="reportInlineText"
          sx={{ color: theme.colors.WHITE }}
        >
          {selectedPMReportDetail && selectedPMReportDetail.description.detail}
        </Typography>
        {selectedPMReportDetail &&
          ((selectedPMReportDetail.references &&
            selectedPMReportDetail.references.length > 0) ||
            (selectedPMReportDetail.source &&
              selectedPMReportDetail.source.length > 0)) && (
            <>
              <Typography variant="title" sx={{ color: theme.colors.WHITE }}>
                {t('pmReportsDetail.reference', { ns: 'report' })}
              </Typography>
              <Box
                sx={{
                  color: theme.colors.PRIMARY,
                  wordBreak: 'break-word',
                  'ul': {
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    marginInlineStart: '1.25rem',
                    marginInlineEnd: 0,
                    paddingInlineStart: 0,
                  },
                }}
              >
                <UnorderedList>
                  {selectedPMReportDetail.source &&
                    selectedPMReportDetail.source.length > 0 && (
                      <ListItem>
                        <ExternalLink
                          href={selectedPMReportDetail.source}
                          size="medium"
                        >
                          {selectedPMReportDetail.source}
                        </ExternalLink>
                      </ListItem>
                    )}
                  {selectedPMReportDetail.references &&
                    selectedPMReportDetail.references.length > 0 &&
                    selectedPMReportDetail.references.map((reference) => (
                      <ListItem key={reference}>
                        {checkIsValidURL(reference) ? (
                          <ExternalLink href={reference} size="medium">
                            {reference}
                          </ExternalLink>
                        ) : (
                          <Typography variant="body" color={theme.colors.WHITE}>
                            {reference}
                          </Typography>
                        )}
                      </ListItem>
                    ))}
                </UnorderedList>
              </Box>
            </>
          )}
      </Stack>
    </Drawer>
  )
}
