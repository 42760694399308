import { TableCell, TableRow } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const LoadingBlock = () => (
  <TableRow>
    <TableCell sx={{ borderBottom: 'none', p: 2 }}>
      <Skeleton type="body" width="7.5rem" />
    </TableCell>
    <TableCell sx={{ borderBottom: 'none', p: 2 }}>
      <Skeleton type="body" width="4rem" />
    </TableCell>
    <TableCell sx={{ borderBottom: 'none', p: 2 }}>
      <Skeleton type="body" width="7.5rem" />
    </TableCell>
  </TableRow>
)

export const DNSRecordLoading = () => (
  <>{generateSkeletons(12, <LoadingBlock />)}</>
)
