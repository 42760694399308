import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const TechniqueLoadingBlock = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        p: 2,
        gap: 2,
        height: '4.875rem',
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <Skeleton type="body" width="3.5rem" />
      <Skeleton type="title" width="11.5rem" />
    </Stack>
  )
}

const LoadingBlock = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        width: '12.5rem',
        height: '100%',
        gap: 1,
      }}
    >
      <TechniqueLoadingBlock />
      <Stack
        sx={{
          borderRadius: 0.5,
          border: `1px solid ${theme.colors.WHITE_20}`,
        }}
      >
        {generateSkeletons(8, <TechniqueLoadingBlock />)}
        <Stack sx={{ p: 2, gap: 2, height: '4.875rem' }}>
          <Skeleton type="body" width="3.5rem" />
          <Skeleton type="title" width="11.5rem" />
        </Stack>
      </Stack>
    </Stack>
  )
}

export const CapabilityEntryLoading = () => (
  <Box sx={{ display: 'flex', gap: 2 }}>
    {generateSkeletons(9, <LoadingBlock />)}
  </Box>
)
