import { IAAP, IRAH } from 'store/types/entityTypes/account'

export const getAAP = (automatedAnalysisPoint: IAAP) => {
  if (automatedAnalysisPoint.total === null) {
    return '∞ / ∞'
  }
  if (automatedAnalysisPoint.total === 0) {
    return ''
  }
  return `${automatedAnalysisPoint.total - automatedAnalysisPoint.used} / ${
    automatedAnalysisPoint.total
  }`
}

export const getRAH = (requestForAssistanceHour: IRAH) => {
  if (requestForAssistanceHour.total === 0) {
    return ''
  }
  return `${requestForAssistanceHour.total - requestForAssistanceHour.used} / ${
    requestForAssistanceHour.total
  }`
}
