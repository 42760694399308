import { TableRow,useTheme } from '@mui/material'
import { ReactNode } from 'react'

export const TableBodyRow = ({
  children,
  index,
}: {
  children: NonNullable<ReactNode>
  index: number
}) => {
  const theme = useTheme()

  return (
    <TableRow
      key={Math.random()}
      sx={{
        bgcolor:
          index % 2 === 0 ? theme.colors.BLACK_95 : theme.colors.BLACK_85,
        '.MuiTableCell-root': {
          verticalAlign: 'top',
          color: theme.colors.WHITE,
          ...theme.typography.bodySmall,
        },
      }}
    >
      {children}
    </TableRow>
  )
}
