import { Box, useTheme } from '@mui/material'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import ReactWordcloud, { Optional, Options } from 'react-wordcloud'
import {
  fetchIndustryBuzzwords,
  fetchIndustryBuzzwordsCancelled,
  selectIndustryBuzzwords,
  selectIndustryBuzzwordsLoading,
} from 'store/slices/threatLandscape'
import { TIndustryBuzzwordType } from 'store/types/slicesTypes/threatLandscape'

const wordcloudOptions: Optional<Options> = {
  enableTooltip: false,
  deterministic: false,
  fontFamily: 'impact',
  fontSizes: [16, 60],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 3,
  rotations: 3,
  rotationAngles: [0, 0],
  scale: 'log',
  spiral: 'rectangular',
  transitionDuration: 1000,
}

interface IWordcloudData {
  type: TIndustryBuzzwordType
  value: number
  text: string
}

interface IIndustryGraphicProps {
  buzzwordKeyword: string
}

export const IndustryGraphic = ({ buzzwordKeyword }: IIndustryGraphicProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const buzzwordsLoading = useAppSelector(selectIndustryBuzzwordsLoading)
  const buzzwords = useAppSelector(selectIndustryBuzzwords)
  const wordcloudCallbacks = {
    getWordColor: (word: {
      value: number
      text: string
      type: TIndustryBuzzwordType
    }) =>
      word.type === 'Adversary' ? theme.colors.WHITE_60 : theme.colors.BLACK_20,
    onWordClick: (word: {
      value: number
      text: string
      type: TIndustryBuzzwordType
    }) =>
      window.open(
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${
          SearchParamKey.SEARCH_TEXT
        }=${encodeURIComponent(word.text)}`
      ),
  }

  const wordcloudData: IWordcloudData[] = buzzwords.map((buzzword) => ({
    value: buzzword.weight,
    text: buzzword.value,
    type: buzzword.type,
  }))

  useEffect(() => {
    dispatch(fetchIndustryBuzzwords(buzzwordKeyword))
    return () => {
      dispatch(fetchIndustryBuzzwordsCancelled())
    }
  }, [buzzwordKeyword])

  if (buzzwordsLoading) {
    return null
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        py: 2,
        bgcolor: theme.colors.BLACK_90,
        '& text:hover': {
          fill: theme.colors.PRIMARY_80,
        },
      }}
    >
      <ReactWordcloud
        words={wordcloudData}
        options={wordcloudOptions}
        callbacks={wordcloudCallbacks}
      />
    </Box>
  )
}
