import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const SampleDetailsLoading = () => (
  <Box sx={{ p: 4, display: 'flex', gap: 8 }}>
    <Skeleton type="image" width="3rem" height="3rem" />
    <Stack sx={{ gap: 2 }}>
      <Skeleton type="body" width="16.5rem" />
      <Skeleton type="body" width="20rem" />
      <Skeleton type="body" width="16.5rem" />
    </Stack>
  </Box>
)
