import { Box, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import {
  APT_ROUTE,
  CTR_REPORT_ROUTE,
  CYBER_AFFAIRS_CATEGORY_ROUTE,
  CYBER_AFFAIRS_TYPE_ROUTE,
  FLASH_REPORT_ROUTE,
  MONTHLY_REPORT_ROUTE,
  NOT_FOUND_ROUTE,
  PM_REPORT_ROUTE,
  PREFACE_INTRODUCTION_ROUTE,
  REPORT_INTRODUCTION_ROUTE,
  VIR_REPORT_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { selectIsMsspUser } from 'store/slices/account'
import {
  selectIsFetchReportReadableTypesLoading,
  selectReportReadableTypes,
  selectReportTypeCount,
} from 'store/slices/report'
import {
  reportRouteTypeMap,
  TReportCategoryRoute,
  TReportTypeRoute,
} from 'store/types/entityTypes/report'

import { CTRReportList } from './APTReports/CTRReportList'
import { FlashReportList } from './APTReports/FlashReportList'
import { MonthlyReportList } from './APTReports/MonthlyReportList'
import { CyberAffairsList } from './CyberAffairs/CyberAffairsList'
import { ReportCategoryTabs } from './ReportComponents/ReportCategoryTabs'
import { PMReportList } from './Vulnerability/PMReportList'
import { VIRReportList } from './Vulnerability/VIRReportList'

// category routes 和 type routes 的對照表
const reportCategoryTypeRouteMap: Record<
  TReportCategoryRoute,
  TReportTypeRoute[]
> = {
  'apt': [FLASH_REPORT_ROUTE, MONTHLY_REPORT_ROUTE, CTR_REPORT_ROUTE],
  'cyber-affairs': [CYBER_AFFAIRS_TYPE_ROUTE],
  'vulnerability': [VIR_REPORT_ROUTE, PM_REPORT_ROUTE],
}

export const ReportListPage = () => {
  const theme = useTheme()
  const { reportType: reportTypeParam, reportCategory: reportCategoryParam } =
    useParams()
  const navigate = useNavigate()
  const reportTypeCount = useAppSelector(selectReportTypeCount)
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isFetchReportReadableTypesLoading = useAppSelector(
    selectIsFetchReportReadableTypesLoading
  )
  const isMsspUser = useAppSelector(selectIsMsspUser)

  useFetchReportsCount()

  const hasReportReadableTypes =
    !isFetchReportReadableTypesLoading &&
    reportTypeParam &&
    reportReadableTypes[reportRouteTypeMap[reportTypeParam]]

  const noReportTypeCount =
    reportTypeParam &&
    reportTypeCount &&
    reportTypeCount[reportRouteTypeMap[reportTypeParam]] === 0

  useEffect(() => {
    // 確認 reportType 是否對應對的 reportCategory
    const isReportTypeInCategory =
      reportCategoryParam &&
      reportTypeParam &&
      reportCategoryTypeRouteMap[
        reportCategoryParam as TReportCategoryRoute
      ].includes(reportTypeParam as TReportTypeRoute)

    // type 沒有對應 category
    if (!isReportTypeInCategory) {
      navigate(`/${NOT_FOUND_ROUTE}`, { replace: true })
    }

    // End-User 沒有閱讀權限且沒有報告導到 404
    if (
      !isMsspUser &&
      !isFetchReportReadableTypesLoading &&
      noReportTypeCount &&
      !reportReadableTypes[reportRouteTypeMap[reportTypeParam]]
    ) {
      navigate(`/${NOT_FOUND_ROUTE}`, { replace: true })
    }
  }, [
    reportTypeCount,
    reportCategoryParam,
    reportTypeParam,
    noReportTypeCount,
    reportReadableTypes,
    isMsspUser,
  ])

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
      }}
    >
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `2px solid ${theme.colors.WHITE_20}`,
          }}
        >
          {reportCategoryParam && (
            <ReportCategoryTabs reportCategoryParam={reportCategoryParam} />
          )}
          <LinkButton
            to={`/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`}
            size="small"
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            Report Introduction
          </LinkButton>
          {/* TODO: hide in May,2023 TV2.0 beta version */}
          {/* {reportCategoryParam === APT_ROUTE && (
          <LinkButton
            to={/${REPORTS_ROUTE}/${APT_ROUTE}/${REPORT_METRICS_ROUTE}`}
            size="small"
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            Check Report Metrics
          </LinkButton>
        )} */}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, flexBasis: 0, px: 1, pb: 1 }}>
        {reportCategoryParam === APT_ROUTE &&
          reportTypeParam === FLASH_REPORT_ROUTE && (
            <FlashReportList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
        {reportCategoryParam === APT_ROUTE &&
          reportTypeParam === MONTHLY_REPORT_ROUTE && (
            <MonthlyReportList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
        {reportCategoryParam === APT_ROUTE &&
          reportTypeParam === CTR_REPORT_ROUTE && (
            <CTRReportList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
        {reportCategoryParam === CYBER_AFFAIRS_CATEGORY_ROUTE &&
          reportTypeParam === CYBER_AFFAIRS_TYPE_ROUTE && (
            <CyberAffairsList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
        {/* TODO: add endUserNoSubscribe props */}
        {reportCategoryParam === VULNERABILITY_ROUTE &&
          reportTypeParam === VIR_REPORT_ROUTE && (
            <VIRReportList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
        {reportCategoryParam === VULNERABILITY_ROUTE &&
          reportTypeParam === PM_REPORT_ROUTE && (
            <PMReportList
              hasReportReadableTypesNoReport={Boolean(
                noReportTypeCount && hasReportReadableTypes
              )}
            />
          )}
      </Box>
    </Stack>
  )
}
