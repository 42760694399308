import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'
import { IPMReportDetail } from 'store/types/entityTypes/report'

interface IExploitedDrawerProps {
  selectedCVE: string
  pmReportDetail: IPMReportDetail[]
  exploitedDrawerOpen: boolean
  onClose: () => void
}

export const ExploitedDrawer = ({
  selectedCVE,
  pmReportDetail,
  exploitedDrawerOpen,
  onClose,
}: IExploitedDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['report'])

  const selectedPMReportDetail = pmReportDetail.find(
    ({ id }) => id === selectedCVE
  )

  return (
    <Drawer
      open={exploitedDrawerOpen}
      onClose={onClose}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: theme.colors.MASK,
        },
      }}
    >
      <Box>
        <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
          {selectedCVE}
        </Typography>
        <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
        {selectedPMReportDetail && (
          <Stack sx={{ gap: 2, pt: 2 }}>
            {selectedPMReportDetail.maliciousFiles &&
              selectedPMReportDetail.maliciousFiles.length !== 0 && (
                <>
                  <Typography
                    variant="title"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    {t('pmReportsDetail.maliciousFiles', { ns: 'report' })}
                  </Typography>
                  <UnorderedList>
                    {selectedPMReportDetail.maliciousFiles.map((file) => (
                      <ListItem key={file}>
                        <TextLink
                          size="medium"
                          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${file}`}
                          target="_blank"
                        >
                          {file}
                        </TextLink>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </>
              )}
            {((selectedPMReportDetail.suspiciousDomains &&
              selectedPMReportDetail.suspiciousDomains.length > 0) ||
              (selectedPMReportDetail.suspiciousIps &&
                selectedPMReportDetail.suspiciousIps.length > 0)) && (
              <>
                <Typography variant="title" sx={{ color: theme.colors.WHITE }}>
                  {t('pmReportsDetail.suspiciousIpsDomains', { ns: 'report' })}
                </Typography>
                <UnorderedList>
                  {selectedPMReportDetail.suspiciousIps &&
                    selectedPMReportDetail.suspiciousIps.length > 0 &&
                    selectedPMReportDetail.suspiciousIps.map((suspiciousIp) => (
                      <ListItem key={suspiciousIp}>
                        <TextLink
                          size="medium"
                          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${suspiciousIp}`}
                          target="_blank"
                        >
                          {suspiciousIp}
                        </TextLink>
                      </ListItem>
                    ))}
                  {selectedPMReportDetail.suspiciousDomains &&
                    selectedPMReportDetail.suspiciousDomains.length > 0 &&
                    selectedPMReportDetail.suspiciousDomains.map(
                      (suspiciousDomain) => (
                        <ListItem key={suspiciousDomain}>
                          <TextLink
                            size="medium"
                            to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${suspiciousDomain}`}
                            target="_blank"
                          >
                            {suspiciousDomain}
                          </TextLink>
                        </ListItem>
                      )
                    )}
                </UnorderedList>
              </>
            )}
          </Stack>
        )}
      </Box>
    </Drawer>
  )
}
