import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Button/Button'
import { DateRangeSelectorPicker } from 'components/DatePicker/DateRangeSelectorPicker'
import { Select, SelectItem } from 'components/Select/Select'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { TIocFormatType, TIocSourceType } from 'store/types/entityTypes/ioc'

export interface IIocSourceTypeOption {
  text: string
  value: TIocSourceType | 'all'
}

export interface IIocFormatTypeOption {
  text: string
  value: TIocFormatType | 'all'
}

interface IIocsFilterBarProps {
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  rangeOption: string
  setRangeOption: (option: string) => void
  iocSourceTypeOptions: IIocSourceTypeOption[]
  iocSourceType: IIocSourceTypeOption['value']
  setIocSourceType: Dispatch<SetStateAction<IIocSourceTypeOption['value']>>
  iocFormatTypeOptions: IIocFormatTypeOption[]
  iocFormatType: IIocFormatTypeOption['value']
  setIocFormatType: Dispatch<SetStateAction<IIocFormatTypeOption['value']>>
}

export const IocsFilterBar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  rangeOption,
  setRangeOption,
  iocSourceTypeOptions,
  iocSourceType,
  setIocSourceType,
  iocFormatTypeOptions,
  iocFormatType,
  setIocFormatType,
}: IIocsFilterBarProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  const handleReset = () => {
    setRangeOption('all')
    setIocSourceType('all')
    setIocFormatType('all')
  }

  const handleIocSourceTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIocSourceType(event.target.value as IIocSourceTypeOption['value'])
  }

  const handleIocFormatTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIocFormatType(event.target.value as IIocFormatTypeOption['value'])
  }

  return (
    <Stack
      sx={{
        bgcolor: theme.colors.BLACK_60,
        gap: 2,
        p: 2,
      }}
    >
      <DateRangeSelectorPicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        rangeOption={rangeOption}
        setRangeOption={setRangeOption}
        width="12rem"
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('iocFilter.sourceLabel', { ns: 'download' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Select
                value={iocSourceType}
                onChange={handleIocSourceTypeChange}
              >
                {iocSourceTypeOptions.map((option, index) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      borderBottom:
                        index === 0
                          ? `1px solid ${theme.colors.WHITE_20}`
                          : 'none',
                    }}
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: theme.colors.WHITE_40 }}
          />
          <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('iocFilter.typeLabel', { ns: 'download' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Select
                value={iocFormatType}
                onChange={handleIocFormatTypeChange}
              >
                {iocFormatTypeOptions.map((option, index) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      borderBottom:
                        index === 0
                          ? `1px solid ${theme.colors.WHITE_20}`
                          : 'none',
                    }}
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexShrink: 0 }}>
          <Button variant="textColor" onClick={handleReset}>
            {t('resetCta', { ns: 'component' })}
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}
