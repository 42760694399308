import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const MalwareContentLoading = () => (
  <Stack sx={{ px: 5, pt: 3, pb: 4, gap: 2 }}>
    <Box sx={{ py: 1 }}>
      <Skeleton type="title" width="26.5rem" />
    </Box>
    <Stack sx={{ gap: 1 }}>
      <Skeleton type="body" width="16.5rem" />
      <Skeleton type="body" width="20rem" />
      <Skeleton type="body" width="16.5rem" />
    </Stack>
  </Stack>
)
