import { Box, Typography, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { DateLabel } from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import { ChangeEvent, ReactNode } from 'react'

interface IDownloadItemProps {
  date: number
  title: string
  downloadElement: NonNullable<ReactNode>
  checked?: boolean
  handleCheck?: (event: ChangeEvent<HTMLInputElement>, key: string) => void
  checkboxDisabled?: boolean
}

/**
 * @description 可下載的項目元件
 * @param date 檔案日期
 * @param title 檔案名稱
 * @param type 檔案類型，會依據類型顯示不同的 icon
 * @param callback 點擊會觸發的 callback function
 * @param checked 是否有被勾選，若是 undefined 就不會出現 checkbox
 * @param handleCheck 勾選時會觸發的 function，若為 undefined 就不會出現 checkbox
 */
export const DownloadItem = ({
  date,
  title,
  checked,
  downloadElement,
  handleCheck,
  checkboxDisabled = false,
}: IDownloadItemProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  const checkable = checked !== undefined && handleCheck

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: theme.colors.BLACK_90,
        padding: 1,
        height: '2rem',
        '&+&': {
          mt: 1,
        },
        '&:hover': {
          bgcolor: theme.colors.BLACK_60,
          '& .right-icon.MuiSvgIcon-root': {
            color: theme.colors.TEXT_LINK_NORMAL,
            borderColor: theme.colors.TEXT_LINK_NORMAL,
          },
        },
        '&:active': {
          bgcolor: theme.colors.BLACK_95,
          '& .right-icon.MuiSvgIcon-root': {
            color: theme.colors.WHITE,
            borderColor: theme.colors.WHITE,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {checkable && (
            <Checkbox
              checked={checked}
              onChange={(event) => {
                event.stopPropagation()
                handleCheck(event, title)
              }}
              disabled={checkboxDisabled}
            />
          )}
          <Box sx={{ flexShrink: 0 }}>
            <DateLabel text={getDateStringByTimestampsInSeconds(date)} />
          </Box>
          <Typography
            variant="textSmallImportant"
            sx={{
              ml: 2,
              color: theme.colors.WHITE,
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {downloadElement}
        </Box>
      </Box>
    </Box>
  )
}
