import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import yaml from 'js-yaml'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    ns: [
      'landingPage',
      'navbar',
      'footer',
      'report',
      'snackbar',
      'component',
      'search',
      'threatLandscapes',
      'adversary',
      'malware',
      'sample',
      'download',
      'ipDomain',
      'account',
      'error',
      'login',
      'document',
      'reportIntroduction',
      'capability',
      'rawIntel',
      'ddw',
    ],
    defaultNS: 'landingPage',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.yml',
      parse: (data: string) => yaml.load(data),
    },
  })

export default i18n
