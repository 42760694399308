import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { PAGE_TITLE_REPORT_VULNERABILITY } from 'constants/pageTitle'
import { REPORTS_ROUTE, VULNERABILITY_ROUTE } from 'constants/routeParams'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import {
  IVulnerabilityContent,
  useVulnerabilityContent,
} from 'hooks/useVulnerabilityContent'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  selectIsCheckIsMsspLoading,
  selectIsMsspUser,
} from 'store/slices/account'
import {
  fetchPMReports,
  fetchPMReportsCancelled,
  fetchVIRReports,
  fetchVIRReportsCancelled,
  resetVIRReports,
  selectIsFetchPMReportsLoading,
  selectIsFetchReportReadableTypesLoading,
  selectIsFetchVIRReportsLoading,
  selectPMReports,
  selectReportReadableTypes,
  selectReportTypeCount,
  selectVIRReports,
} from 'store/slices/report'
import { reportTypeRouteMap } from 'store/types/entityTypes/report'

import { PM_REPORT_TYPE, VIR_REPORT_TYPE } from '../constants/reportType'
import { ReportCaption } from '../ReportComponents/ReportCaption'
import {
  PMREntryLoading,
  PMRListLoading,
  ReportEntryPreviewLoading,
} from '../ReportComponents/ReportLoading'
import { ReportPreview } from '../ReportComponents/ReportPreview'
import { PMReportPreview } from './PMReportComponents/PMReportPreview'

const SCROLLABLE_CONTAINER_ID = 'vulnerability-content-container'

export const Vulnerability = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation(['report'])
  const dispatch = useAppDispatch()
  const isMsspUser = useAppSelector(selectIsMsspUser)
  const isCheckIsMsspLoading = useAppSelector(selectIsCheckIsMsspLoading)
  const reportTypeCount = useAppSelector(selectReportTypeCount)
  const VIRReports = useAppSelector(selectVIRReports)
  const PMReports = useAppSelector(selectPMReports)
  const isFetchVIRReportsLoading = useAppSelector(
    selectIsFetchVIRReportsLoading
  )
  const isFetchPMReportsLoading = useAppSelector(selectIsFetchPMReportsLoading)
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isFetchReportReadableTypesLoading = useAppSelector(
    selectIsFetchReportReadableTypesLoading
  )
  useFetchReportsCount()
  const vulnerabilityContent = useVulnerabilityContent()

  // filter 有閱讀權限的 Vulnerability report content
  const filterVulnerabilityReportContentForEndUser =
    vulnerabilityContent.filter(({ type }) => reportReadableTypes[type])

  const displayVulnerabilityReportContent = isMsspUser
    ? vulnerabilityContent
    : filterVulnerabilityReportContentForEndUser

  useEffect(() => {
    if (!isCheckIsMsspLoading && !isMsspUser) {
      if (displayVulnerabilityReportContent.length === 1) {
        navigate(
          `/${REPORTS_ROUTE}/${VULNERABILITY_ROUTE}/${
            reportTypeRouteMap[displayVulnerabilityReportContent[0].type]
          }`
        )
      }
    }
  }, [isMsspUser, isCheckIsMsspLoading])

  useEffect(() => {
    dispatch(fetchVIRReports({ offset: 0 }))
    dispatch(fetchPMReports())

    return () => {
      dispatch(fetchVIRReportsCancelled())
      dispatch(resetVIRReports())
      dispatch(fetchPMReportsCancelled())
    }
  }, [])

  const displayNoReportsEmptyState = (content: IVulnerabilityContent) => {
    let emptyState
    // 沒有 reports 時，有閱讀權限顯示 emptyState.noReports
    if (reportReadableTypes[content.type]) {
      emptyState = t('emptyState.noReports', {
        ns: 'report',
        reports: content.name,
      })
    }
    // MSSP 沒有閱讀權限
    if (isMsspUser && !reportReadableTypes[content.type]) {
      emptyState = t('emptyState.msspNoAccess', {
        ns: 'report',
        reports: content.name,
      })
    }

    return emptyState
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_VULNERABILITY}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(25rem, 1fr))',
            gap: 1,
          }}
        >
          {isCheckIsMsspLoading || isFetchReportReadableTypesLoading ? (
            <PMREntryLoading />
          ) : (
            displayVulnerabilityReportContent.map((content, index) => (
              <Box key={content.name} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    height: '100%',
                    border: `1px solid ${theme.colors.WHITE_20}`,
                    borderRadius: 0.5,
                    color: theme.colors.WHITE,
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${content.backgroundImageSrc})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <Stack sx={{ height: '100%' }}>
                      <ReportCaption
                        name={content.name}
                        description={content.description}
                        buttonText={content.buttonText}
                        reportType={content.type}
                        // button disable: End-user 和 MSSP 有閱讀權限但沒有報告
                        buttonDisable={
                          reportTypeCount !== null &&
                          reportTypeCount[content.type] === 0 &&
                          reportReadableTypes[content.type]
                        }
                        // button hide: MSSP 沒有閱讀權限也沒有報告
                        buttonHide={
                          isMsspUser &&
                          reportTypeCount !== null &&
                          reportTypeCount[content.type] === 0 &&
                          !reportReadableTypes[content.type]
                        }
                      />
                      <Stack
                        id={`${SCROLLABLE_CONTAINER_ID + index}`}
                        sx={{
                          flexGrow: 1,
                          flexBasis: 0,
                          overflowY: 'auto',
                          bgcolor: theme.colors.BLACK_90,
                          height: '100%',
                        }}
                      >
                        {content.type === VIR_REPORT_TYPE && (
                          <Stack>
                            {isFetchVIRReportsLoading ? (
                              <Stack sx={{ py: 2 }}>
                                <ReportEntryPreviewLoading />
                                <ReportEntryPreviewLoading />
                                <ReportEntryPreviewLoading />
                              </Stack>
                            ) : reportTypeCount &&
                              reportTypeCount[content.type] === 0 ? (
                              <Box
                                sx={{
                                  height: '100%',
                                  bgcolor: theme.colors.BLACK_90,
                                  p: 4,
                                  textAlign: 'center',
                                }}
                              >
                                <Typography
                                  variant="titleSmall"
                                  sx={{ color: theme.colors.WHITE_60 }}
                                >
                                  {displayNoReportsEmptyState(content)}
                                </Typography>
                              </Box>
                            ) : (
                              VIRReports.slice(0, 6).map((report) => (
                                <Box key={report.id}>
                                  <ReportPreview {...report} hasImage={false} />
                                  <Divider
                                    sx={{
                                      borderColor: theme.colors.WHITE_20,
                                    }}
                                  />
                                </Box>
                              ))
                            )}
                          </Stack>
                        )}
                        {content.type === PM_REPORT_TYPE && (
                          <Stack>
                            {isFetchPMReportsLoading ? (
                              <PMRListLoading />
                            ) : reportTypeCount &&
                              reportTypeCount[content.type] === 0 ? (
                              <Box
                                sx={{
                                  height: '100%',
                                  bgcolor: theme.colors.BLACK_90,
                                  p: 4,
                                  textAlign: 'center',
                                }}
                              >
                                <Typography
                                  variant="titleSmall"
                                  sx={{ color: theme.colors.WHITE_60 }}
                                >
                                  {displayNoReportsEmptyState(content)}
                                </Typography>
                              </Box>
                            ) : (
                              PMReports.slice(0, 10).map((report) => (
                                <PMReportPreview
                                  key={report.name}
                                  {...report}
                                />
                              ))
                            )}
                          </Stack>
                        )}
                        <ScrollToTop
                          scrollableContainerId={`${
                            SCROLLABLE_CONTAINER_ID + index
                          }`}
                          sx={{
                            position: 'sticky',
                            bottom: '2%',
                            left: '95%',
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </>
  )
}
