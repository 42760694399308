import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_PM_REPORTS_COUNT,
  FETCH_PMR_REPORTS_LIST,
  FETCH_REPORTS,
  FETCH_REPORTS_COUNT,
  FETCH_REPORTS_READABLE_TYPES,
  getPMRReportDetailById,
  getRelatedToolsById,
  getReportByAlias,
  getReportByAliasFormat,
} from 'constants/api'
import { CRASH_ROUTE, NOT_FOUND_ROUTE } from 'constants/routeParams'
import { StatusCode } from 'constants/statusCode'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchCTRReports,
  fetchCTRReportsCancelled,
  fetchCTRReportsCount,
  fetchCTRReportsCountCancelled,
  fetchCTRReportsCountFulfilled,
  fetchCTRReportsCountRejected,
  fetchCTRReportsFulfilled,
  fetchCTRReportsRejected,
  fetchCyberAffairs,
  fetchCyberAffairsCancelled,
  fetchCyberAffairsCount,
  fetchCyberAffairsCountCancelled,
  fetchCyberAffairsCountFulfilled,
  fetchCyberAffairsCountRejected,
  fetchCyberAffairsFulfilled,
  fetchCyberAffairsRejected,
  fetchFlashReports,
  fetchFlashReportsCancelled,
  fetchFlashReportsCount,
  fetchFlashReportsCountCancelled,
  fetchFlashReportsCountFulfilled,
  fetchFlashReportsCountRejected,
  fetchFlashReportsFulfilled,
  fetchFlashReportsRejected,
  fetchMonthlyReports,
  fetchMonthlyReportsCancelled,
  fetchMonthlyReportsCount,
  fetchMonthlyReportsCountCancelled,
  fetchMonthlyReportsCountFulfilled,
  fetchMonthlyReportsCountRejected,
  fetchMonthlyReportsFulfilled,
  fetchMonthlyReportsRejected,
  fetchPMReportDetail,
  fetchPMReportDetailCancelled,
  fetchPMReportDetailFulfilled,
  fetchPMReportDetailRejected,
  fetchPMReports,
  fetchPMReportsCancelled,
  fetchPMReportsCount,
  fetchPMReportsCountCancelled,
  fetchPMReportsCountFulfilled,
  fetchPMReportsCountRejected,
  fetchPMReportsFulfilled,
  fetchPMReportsRejected,
  fetchReport,
  fetchReportCancelled,
  fetchReportFulfilled,
  fetchReportHtmlContent,
  fetchReportHtmlContentCancelled,
  fetchReportHtmlContentFulfilled,
  fetchReportHtmlContentRejected,
  fetchReportIocContent,
  fetchReportIocContentCancelled,
  fetchReportIocContentFulfilled,
  fetchReportIocContentRejected,
  fetchReportPdfContent,
  fetchReportPdfContentCancelled,
  fetchReportPdfContentFulfilled,
  fetchReportPdfContentRejected,
  fetchReportReadableTypes,
  fetchReportReadableTypesCancelled,
  fetchReportReadableTypesFulfilled,
  fetchReportReadableTypesRejected,
  fetchReportRejected,
  fetchReportTools,
  fetchReportToolsCancelled,
  fetchReportToolsFulfilled,
  fetchReportToolsRejected,
  fetchVIRReports,
  fetchVIRReportsCancelled,
  fetchVIRReportsCount,
  fetchVIRReportsCountCancelled,
  fetchVIRReportsCountFulfilled,
  fetchVIRReportsCountRejected,
  fetchVIRReportsFulfilled,
  fetchVIRReportsRejected,
} from 'store/slices/report'
import {
  APIReportType,
  mapAPIPMReportDetailToState,
  mapAPIPMReportsToState,
  mapAPIReportInfoToState,
  mapAPIReportReadableTypesToState,
  mapAPIReportsToState,
} from 'store/types/entityTypes/report'
import { mapAPIToolsToState } from 'store/types/entityTypes/tool'
import { IFetchToolsResponse } from 'store/types/slicesTypes/download'
import {
  IFetchPMReportDetailResponse,
  IFetchPMReportsResponse,
  IFetchReportReadableTypesResponse,
  IFetchReportResponse,
  IFetchReportsCountResponse,
  IFetchReportsResponse,
} from 'store/types/slicesTypes/report'
import { getFilenameByContentComposition } from 'utils/download'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

interface IComposeUrlParams {
  type: APIReportType
  dateFrom?: number | null
  dateTo?: number | null
  offset?: number
}

const composeUrlParams = ({
  type,
  dateFrom,
  dateTo,
  offset,
}: IComposeUrlParams) => {
  let urlParams = `?types[]=${type}`
  if (dateFrom) {
    urlParams += `&date[from]=${dateFrom}`
  }
  if (dateTo) {
    urlParams += `&date[to]=${dateTo}`
  }
  if (offset) {
    urlParams += `&offset=${offset}`
  }
  return urlParams
}

const flashReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchFlashReports.match),
    switchMap((action) => {
      const urlParams = composeUrlParams({
        type: 'flash',
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        offset: action.payload?.offset,
      })
      return fetchData<IFetchReportsResponse>({
        url: `${FETCH_REPORTS}/${urlParams}`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchFlashReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchFlashReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchFlashReportsRejected(error))
          )
        )
      )
    })
  )

const monthlyReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMonthlyReports.match),
    switchMap((action) => {
      const urlParams = composeUrlParams({
        type: 'monthly',
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        offset: action.payload?.offset,
      })
      return fetchData<IFetchReportsResponse>({
        url: `${FETCH_REPORTS}/${urlParams}`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchMonthlyReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchMonthlyReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMonthlyReportsRejected(error))
          )
        )
      )
    })
  )

const CTRReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCTRReports.match),
    switchMap((action) => {
      const urlParams = composeUrlParams({
        type: 'advanced',
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        offset: action.payload?.offset,
      })
      return fetchData<IFetchReportsResponse>({
        url: `${FETCH_REPORTS}/${urlParams}`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchCTRReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchCTRReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchCTRReportsRejected(error))
          )
        )
      )
    })
  )

const cyberAffairsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCyberAffairs.match),
    switchMap((action) => {
      const urlParams = composeUrlParams({
        type: 'bi_weekly',
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        offset: action.payload?.offset,
      })
      return fetchData<IFetchReportsResponse>({
        url: `${FETCH_REPORTS}/${urlParams}`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchCyberAffairsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchCyberAffairsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchCyberAffairsRejected(error))
          )
        )
      )
    })
  )

const VIRReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchVIRReports.match),
    switchMap((action) => {
      const urlParams = composeUrlParams({
        type: 'vulnerability_insights',
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        offset: action.payload?.offset,
      })
      return fetchData<IFetchReportsResponse>({
        url: `${FETCH_REPORTS}/${urlParams}`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchVIRReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchVIRReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchVIRReportsRejected(error))
          )
        )
      )
    })
  )

const PMReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchPMReports.match),
    switchMap(() =>
      fetchData<IFetchPMReportsResponse>({
        url: FETCH_PMR_REPORTS_LIST,
      }).pipe(
        map((response: AjaxResponse<IFetchPMReportsResponse>) =>
          fetchPMReportsFulfilled(
            mapAPIPMReportsToState(response.response.advisory_lists)
          )
        ),
        takeUntil(action$.pipe(filter(fetchPMReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchPMReportsRejected(error))
          )
        )
      )
    )
  )

const PMReportDetailEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchPMReportDetail.match),
    switchMap((action) =>
      fetchData<IFetchPMReportDetailResponse>({
        url: getPMRReportDetailById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchPMReportDetailResponse>) =>
          fetchPMReportDetailFulfilled(
            mapAPIPMReportDetailToState(response.response.advisories)
          )
        ),
        takeUntil(action$.pipe(filter(fetchPMReportDetailCancelled.match))),
        catchError((error) => {
          if (error.response.status_code === StatusCode.RECORD_NOT_FOUND) {
            window.location.replace(`/${NOT_FOUND_ROUTE}`)
          } else {
            window.location.replace(`/${CRASH_ROUTE}`)
          }
          return concat(
            checkUnauthorizedToken(error),
            of(fetchPMReportDetailRejected(error))
          )
        })
      )
    )
  )

const flashReportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchFlashReportsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: `${FETCH_REPORTS_COUNT}/?types[]=flash`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchFlashReportsCountFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchFlashReportsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchFlashReportsCountRejected(error))
          )
        )
      )
    )
  )

const monthlyReportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMonthlyReportsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: `${FETCH_REPORTS_COUNT}/?types[]=monthly`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchMonthlyReportsCountFulfilled(response.response)
        ),
        takeUntil(
          action$.pipe(filter(fetchMonthlyReportsCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMonthlyReportsCountRejected(error))
          )
        )
      )
    )
  )

const CTRReportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCTRReportsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: `${FETCH_REPORTS_COUNT}/?types[]=advanced`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchCTRReportsCountFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchCTRReportsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchCTRReportsCountRejected(error))
          )
        )
      )
    )
  )

const cyberAffairsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCyberAffairsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: `${FETCH_REPORTS_COUNT}/?types[]=bi_weekly`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchCyberAffairsCountFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchCyberAffairsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchCyberAffairsCountRejected(error))
          )
        )
      )
    )
  )

const VIRReportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchVIRReportsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: `${FETCH_REPORTS_COUNT}/?types[]=vulnerability_insights`,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchVIRReportsCountFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchVIRReportsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchVIRReportsCountRejected(error))
          )
        )
      )
    )
  )

const PMReportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchPMReportsCount.match),
    switchMap(() =>
      fetchData<IFetchReportsCountResponse>({
        url: FETCH_PM_REPORTS_COUNT,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsCountResponse>) =>
          fetchPMReportsCountFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchPMReportsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchPMReportsCountRejected(error))
          )
        )
      )
    )
  )

const reportHtmlContentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReportHtmlContent.match),
    switchMap((action) =>
      fetchData<string>({
        url: getReportByAliasFormat({
          alias: action.payload.alias,
          format: 'html',
        }),
        responseType: 'text',
      }).pipe(
        map((response: AjaxResponse<string>) =>
          fetchReportHtmlContentFulfilled(response.response)
        ),
        takeUntil(action$.pipe(filter(fetchReportHtmlContentCancelled.match))),
        catchError((error) => {
          if (
            JSON.parse(error.response).status_code ===
            StatusCode.RECORD_NOT_FOUND
          ) {
            window.location.replace(`/${NOT_FOUND_ROUTE}`)
          } else if (error.status === 403) {
            action.payload.noAccessCallback()
          } else {
            window.location.replace(`/${CRASH_ROUTE}`)
          }
          return concat(
            checkUnauthorizedToken(error),
            of(fetchReportHtmlContentRejected(error))
          )
        })
      )
    )
  )

const reportIocContentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReportIocContent.match),
    mergeMap((action) =>
      fetchData<string>({
        url: getReportByAliasFormat({
          alias: action.payload.alias,
          format: 'stix',
        }),
        responseType: 'json',
      }).pipe(
        mergeMap((response: AjaxResponse<string>) =>
          merge(
            of(fetchReportIocContentFulfilled(response.response)).pipe(
              tap(() => {
                if (action.payload.successCallback) {
                  action.payload.successCallback(response.response)
                }
              })
            )
          )
        ),
        takeUntil(action$.pipe(filter(fetchReportIocContentCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchReportIocContentRejected(error))
          )
        )
      )
    )
  )

const reportPdfContentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReportPdfContent.match),
    mergeMap((action) =>
      fetchData<ArrayBuffer>({
        url: getReportByAliasFormat({
          alias: action.payload.alias,
          format: 'pdf',
        }),
        responseType: 'arraybuffer',
      }).pipe(
        mergeMap((response: AjaxResponse<ArrayBuffer>) =>
          merge(
            of(fetchReportPdfContentFulfilled(response.response)).pipe(
              tap(() => {
                if (action.payload.successCallback) {
                  const contentDisposition =
                    response.responseHeaders['content-disposition'] || ''
                  action.payload.successCallback({
                    response: response.response,
                    filename:
                      getFilenameByContentComposition(contentDisposition) ||
                      action.payload.alias,
                  })
                }
              })
            )
          )
        ),
        takeUntil(action$.pipe(filter(fetchReportPdfContentCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchReportPdfContentRejected(error))
          )
        )
      )
    )
  )

const reportInfoEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReport.match),
    switchMap((action) =>
      fetchData<IFetchReportResponse>({
        url: getReportByAlias(action.payload.alias),
      }).pipe(
        map((response: AjaxResponse<IFetchReportResponse>) =>
          fetchReportFulfilled(mapAPIReportInfoToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchReportCancelled.match))),
        catchError((error) => {
          if (error.response.status_code === StatusCode.RECORD_NOT_FOUND) {
            window.location.replace(`/${NOT_FOUND_ROUTE}`)
          } else if (error.status === 403) {
            action.payload.noAccessCallback()
          } else {
            window.location.replace(`/${CRASH_ROUTE}`)
          }
          return concat(
            checkUnauthorizedToken(error),
            of(fetchReportRejected(error))
          )
        })
      )
    )
  )

const reportReadableTypesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReportReadableTypes.match),
    switchMap(() =>
      fetchData<IFetchReportReadableTypesResponse>({
        url: FETCH_REPORTS_READABLE_TYPES,
      }).pipe(
        map((response: AjaxResponse<IFetchReportReadableTypesResponse>) =>
          fetchReportReadableTypesFulfilled(
            mapAPIReportReadableTypesToState(response.response)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchReportReadableTypesCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchReportReadableTypesRejected(error))
          )
        )
      )
    )
  )

const reportToolsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchReportTools.match),
    switchMap((action) =>
      fetchData<IFetchToolsResponse>({
        url: getRelatedToolsById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchToolsResponse>) =>
          fetchReportToolsFulfilled(mapAPIToolsToState(response.response.tools))
        ),
        takeUntil(action$.pipe(filter(fetchReportToolsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchReportToolsRejected(error))
          )
        )
      )
    )
  )

export const reportEpic = combineEpics(
  flashReportsEpic,
  monthlyReportsEpic,
  CTRReportsEpic,
  cyberAffairsEpic,
  VIRReportsEpic,
  PMReportsEpic,
  PMReportDetailEpic,
  flashReportsCountEpic,
  monthlyReportsCountEpic,
  CTRReportsCountEpic,
  cyberAffairsCountEpic,
  VIRReportsCountEpic,
  PMReportsCountEpic,
  reportHtmlContentEpic,
  reportIocContentEpic,
  reportPdfContentEpic,
  reportInfoEpic,
  reportReadableTypesEpic,
  reportToolsEpic
)
