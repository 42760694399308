import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_IP_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/network/ips/167.179.85.233/reports" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"167.179.85.233",
  "analysis_status":true,
  "reports":[
    {
      "title":"Flash Report 20230728",
      "date":1690473600,
      "type":"flash",
      "adversaries":["menuPass"],
      "malwares":["BigPooh"],
      "targeted_countries":["Japan"],
      "targeted_industries":["Think tank"],
      "capability":["T1113","T1573.001","T1053","T1005","T1140","T1083","T1041","T1486","T1485","T1071.001","T1001.001","T1587.001","T1574.002","T1056.001","T1055.009","T1036.005","T1204.002","T1132.001"],
      "digest":"In late July, TeamT5 detected that menuPass attacked think tanks in Japan. In the attack, we identified menuPass’s proprietary RAT, BigPooh (aka LODEINFO). The BigPooh version in this campaign is v0.7.1, which is the latest version in our database.",
      "pdf_url":"https://api.threatvision.org/api/v2/reports/flash_report-20230727021435.pdf",
      "stix_url":"https://api.threatvision.org/api/v2/reports/flash_report-20230727021435.stix"
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Reports'

export const APIDocumentVersionTwoIPReports = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_IP_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_IP_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Related reports written by TeamT5 analyst’s team would be retrieved,
          so users can learn more about the campaigns that it’s relevant to.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/network/ips/:address/reports" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            analysis_status
            <UnorderedList>
              <ListItem>The IP had been analyzed</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The IP address</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            reports
            <br />
            Array of json objects, with following keys:
            <UnorderedList>
              <ListItem>title</ListItem>
              <ListItem>date</ListItem>
              <ListItem>type</ListItem>
              <ListItem>adversaries</ListItem>
              <ListItem>malwares</ListItem>
              <ListItem>targeted_countries</ListItem>
              <ListItem>targeted_industries</ListItem>
              <ListItem>
                capability: Array of METRE serial for capability.
              </ListItem>
              <ListItem>digest</ListItem>
              <ListItem>pdf_url</ListItem>
              <ListItem>stix_url</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
