import { Box, Stack } from '@mui/material'
import TermsOfServiceImage from 'assets/documentImages/termsOfService.jpg'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H3Title } from 'components/Documents/H3Title'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_TERMS_OF_SERVICE } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'
import { openMail } from 'utils/mail'

export const TermsOfServicePage = () => {
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_TERMS_OF_SERVICE}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title title="Terms of Service" />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.TERMS_OF_SERVICE_DOCUMENT_TIMESTAMP
              )}
            />
            <Image
              src={TermsOfServiceImage}
              alt="termsOfService"
              height="25rem"
              fit="cover"
            />
            <Description>
              Thank you for using the ThreatVision portal (the
              &quot;Portal&quot;) that we, Team T5 Inc., a limited liability
              company, organized and duly existing under the laws of Taiwan
              (R.O.C.), having the principal office at 15F., No. 46, Ln. 11,
              Guangfu N. Rd., Songshan Dist., 105 Taipei City, Taiwan
              (&quot;TeamT5&quot;), make available to you.
            </Description>
            <Description>
              Your use of the Portal is subject to this Terms of Use, the End
              User License Agreement, and the Privacy Policy (together, the
              &quot;Agreement&quot;). By using or accessing the Portal, you
              acknowledge that you agree to be bound by the Agreement. Please
              read the Agreement carefully and make sure you understand it. If
              you do not understand the Agreement, or do not accept any part of
              it, then you should not use the Portal.
            </Description>
            <Description>
              If you are using the Portal on behalf of a company or
              organization, you confirm to us that you have authority to act on
              behalf of that entity, and that entity accepts the Agreement.
            </Description>
            <H3Title title="Permissions and Restrictions" />
            <Description>
              You may access and use the Portal as made available to you, as
              long as you comply with the Agreement and the law. TeamT5 will
              create the ThreatVision accounts for the designated users
              according to the Subscription Contract between TeamT5 and the
              subscriber of the Portal. You need a ThreatVision account to use
              the Portal. To protect your ThreatVision account, you have to keep
              your account name and password confidential and do not share any
              of them with others.
            </Description>
            <Description>
              TeamT5 will grant a non-exclusive, non-transferable, limited right
              to access this Portal and the materials thereon. The following
              restrictions apply to your use of the Portal. You agree not to:
              <UnorderedList>
                <ListItem>
                  interrupt or attempt to interfere with the Portal in any way,
                  including security-related features or features that limit,
                  restrict or prevent the use of the Portal;
                </ListItem>
                <ListItem>
                  intrude or attempt to intrude into the Portal in any way;
                </ListItem>
                <ListItem>
                  attempt to decompile or reverse engineer any software
                  contained on the Portal.
                </ListItem>
              </UnorderedList>
            </Description>
            <Description>
              Permission granted to download the copy of the materials on the
              Portal such as reports and samples of malware is the grant of a
              license, not a transfer of title, and under this license you may
              not:
              <UnorderedList>
                <ListItem>
                  use the materials for any public display (commercial or
                  non-commercial);
                </ListItem>
                <ListItem>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </ListItem>
                <ListItem>
                  transfer the materials to another person or &quot;mirror&quot;
                  the materials on any other server.
                </ListItem>
              </UnorderedList>
            </Description>
            <Description>
              Unless otherwise agreed, this license shall automatically
              terminate if you violate any of these restrictions and may be
              terminated by TeamT5 at any time.
            </Description>
            <H3Title title="Uploading Content" />
            <Description>
              You may upload the sample of malware to the Portal for analysis
              and choose to disclose or not to disclose the sample you have
              uploaded to the users of the Portal appointed by you.
            </Description>
            <Description>
              If you choose to upload the sample, you must not submit to the
              Portal any sample that does not comply with the Agreement or the
              law. You are legally responsible for the sample you submit to the
              Portal. We may use automated systems to analyze the sample you
              uploaded.
            </Description>
            <Description>
              By providing the sample of malware to the Portal, you agree and
              grant to TeamT5 a worldwide, non-exclusive, royalty-free,
              transferable, sublicensable license to use that sample (including
              to reproduce, distribute, modify, display and perform it) and
              include that sample as a part of the database for the Portal. Once
              you have uploaded the sample of malware on to the Portal, you
              should not withdraw your agreement or license to TeamT5, remove or
              ask TeamT5 to remove the uploaded sample.
            </Description>
            <H3Title title="Reservation" />
            <Description>
              All rights, title, and interest in and to the Portal are and will
              remain the exclusive property of TeamT5. TeamT5 shall retain all
              worldwide rights in the intellectual property of the Portal and
              any content on the Portal.
            </Description>
            <Description>
              TeamT5 reserves the right to initiate appropriate and necessary
              legal proceedings against you for any violation of the Agreement
              and hold you liable for all the claims, losses, damages and
              consequences thereof. TeamT5s’ remedies are cumulative and not
              exclusive.
            </Description>
            <Description>
              TeamT5 authorizes you to view and download only the permitted
              materials at this Portal and only for personal and internal
              reference only and you should not disclose any part of the
              materials to the public except for a limited reference. A limited
              reference refers that you may quote from or apply only a part of
              the materials and you shall indicate TeamT5 as the source of the
              reference.
            </Description>
            <Description>
              Please note that any information, documents, files, or other
              materials sent to TeamT5 will be deemed NOT to be confidential.
            </Description>
            <H3Title title="Changes to the Portal" />
            <Description>
              The materials appearing on the Portal could include technical,
              typographical, or photographic errors. We are constantly changing
              and improving the Portal and may make changes to the materials
              contained on the Portal at any time without notice. We do not,
              however, make any commitment to update the materials. We may also
              need to alter or discontinue the Portal, or any part of it, in
              order to make performance or security improvements, change
              functionality and features, make changes to comply with law, or
              prevent illegal activities on or abuse of our systems.
            </Description>
            <Description>
              We may at any time, without notice to you, revise the Agreement
              and any other information contained in this Portal.
            </Description>
            <H3Title title="Terminations and Suspensions by TeamT5 for Cause" />
            <Description>
              We may suspend or terminate your access, your ThreatVision
              account, or your ThreatVision account&apos;s access to all or part
              of the Portal if:
              <UnorderedList>
                <ListItem>you violate the Agreement;</ListItem>
                <ListItem>
                  we are required to do so to comply with a legal requirement or
                  a court order; or
                </ListItem>
                <ListItem>
                  we reasonably believe there has been conduct that creates
                  liability or harm to any user, other third party, or TeamT5.
                </ListItem>
              </UnorderedList>
            </Description>
            <H3Title title="Disclaimer" />
            <Description>
              All materials, information, products, software, programs, and
              services are provided as &quot;as is,&quot; with no warranties or
              guarantees whatsoever. TeamT5 expressly disclaims to the fullest
              extent permitted by law all express, statutory, implied, and other
              warranties, guarantees, or representations, including, without
              limitation, the warranties of merchantability, and fitness for a
              particular purpose. Without limitation, TeamT5 makes no warranty
              of guarantee that this Portal will be uninterrupted, timely,
              error-free or secure.
            </Description>
            <Description>
              You will be solely responsible for any damages that may result,
              including loss of data or damage to your computer system due to
              use of this Portal or materials downloaded from the Portal.
            </Description>
            <H3Title title="Limitation of Liability" />
            <Description>
              In no event shall we be liable for any direct, indirect, punitive,
              incidental, special, consequential damages or any damages
              whatsoever including, without limitation, damages for loss of use,
              data or profits, arising out of or in any way connected with the
              use or performance of this Portal, with the delay or inability to
              use this Portal or related services, the provision of or failure
              to provide the Portal, or for any information, products, services
              and material obtained through this Portal, or otherwise arising
              out of the use of this Portal, whether based on contract, tort,
              negligence, strict liability or otherwise, even if we have been
              advised of the possibility of damages.
            </Description>
            <H3Title title="Indemnity" />
            <Description>
              You will indemnify and hold harmless TeamT5 and the subsidiaries,
              vendors, customers, officers and employees of TeamT5 from any
              liability, damage, or cost (including reasonable attorney fees)
              from:
              <UnorderedList>
                <ListItem>
                  any damages, claims or demand made by any third party due to
                  or arising out of your access to or use of the Portal or any
                  content on the Portal; or
                </ListItem>
                <ListItem>
                  violation of the Agreement by you, or the infringement by you
                  of any intellectual property or other right of any person or
                  entity.
                </ListItem>
              </UnorderedList>
            </Description>
            <H3Title title="Severance" />
            <Description>
              If it turns out that a particular term of the Agreement is not
              enforceable for any reason, this will not affect any other terms.
            </Description>
            <H3Title title="No Waiver" />
            <Description>
              If you do not comply with the Agreement and we do not take action
              immediately, this does not mean that we have given up any rights
              that we may have (such as the right to take action in the future).
            </Description>
            <H3Title title="Governing Law and Jurisdiction." />
            <Description>
              The terms of the Agreement shall be governed and construed
              exclusively by the laws of Taiwan (R.O.C.), not including its
              conflicts of law provisions. If there is any dispute relating to
              the Agreement which cannot be resolved amicably, then it should be
              resolved in the courts of Taiwan. You agree that if there is any
              legal action or proceeding relating to the Agreement, it should be
              brought exclusively in the Taiwan Taipei District Court for the
              first instance, and you consent to the jurisdiction thereof.
            </Description>
            <H3Title title="Contacting Us" />
            <Description>
              If you have general questions about your account or need the
              customer service for assistance, please contact us by email at{' '}
              <TextLink size="large" onClick={openMail} to="">
                support@teamt5.org
              </TextLink>
            </Description>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
