import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { Button } from 'components/Button/Button'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export interface IDialogProps extends DialogProps {
  handleDialogClose: () => void
  title?: string
  description: string
  content?: NonNullable<ReactNode>
  confirmButtonText: string
  handleConfirmButtonClick: () => void
  confirmButtonLoading?: boolean
  confirmButtonDisabled?: boolean
}
export const Dialog = ({
  open,
  handleDialogClose,
  title,
  description,
  content,
  confirmButtonText,
  handleConfirmButtonClick,
  confirmButtonLoading = false,
  confirmButtonDisabled = false,
  sx = [],
  ...props
}: IDialogProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  return (
    <MuiDialog
      open={open}
      onClose={handleDialogClose}
      sx={Array.isArray(sx) ? sx : [sx]}
      PaperProps={{
        sx: {
          width: '37.5rem',
          p: 8,
          overflowY: 'visible',
          bgcolor: theme.colors.BLACK_60,
          color: theme.colors.WHITE,
          borderRadius: 0.5,
        },
      }}
      {...props}
    >
      <SvgIcon
        sx={{
          fontSize: '2rem',
          position: 'absolute',
          right: 0,
          top: 0,
          transform: 'translate(50%, -50%)',
          cursor: 'pointer',
          color: theme.colors.WHITE_60,
          bgcolor: theme.colors.BLACK_85,
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: '50%',
          p: 1,
        }}
        component={CloseIcon}
        inheritViewBox
        onClick={handleDialogClose}
      />
      <Stack sx={{ gap: 6 }}>
        {title && (
          <DialogTitle sx={{ textAlign: 'center', p: 0 }}>
            <Typography variant="title">{title}</Typography>
          </DialogTitle>
        )}
        <DialogContent
          sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 6 }}
        >
          <Typography variant="body">{description}</Typography>
          {content}
        </DialogContent>
        <DialogActions sx={{ p: 0, gap: 4 }} disableSpacing>
          <Button size="large" variant="text" onClick={handleDialogClose}>
            {t('cancelCta', { ns: 'component' })}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleConfirmButtonClick}
            loading={confirmButtonLoading}
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Stack>
    </MuiDialog>
  )
}
