import { Box, Divider, SvgIcon } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { NoDataWithContactCta } from 'components/NoData/NoDataWithContactCta'
import { PageSection } from 'components/PageSection/PageSection'
import { ReportPreview } from 'components/Report/ReportPreview'
import { REPORTS_ROUTE } from 'constants/routeParams'
import { useAppDispatch,useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchLatestReports,
  fetchLatestReportsCancelled,
  selectLatestReports,
  selectLatestReportsLoading,
} from 'store/slices/landing'

import { LatestReportsLoading } from './LatestReportsLoading'

export const LATEST_REPORT_CONTAINER_TEST_ID = 'latest-report-container'

export const LatestReports = () => {
  const dispatch = useAppDispatch()
  const latestReportsLoading = useAppSelector(selectLatestReportsLoading)
  const latestReports = useAppSelector(selectLatestReports)
  const { t } = useTranslation(['landingPage'])

  useEffect(() => {
    dispatch(fetchLatestReports())

    return () => {
      dispatch(fetchLatestReportsCancelled())
    }
  }, [])

  return (
    <PageSection title={t('latestReports.title', { ns: 'landingPage' })}>
      <Box data-testid={LATEST_REPORT_CONTAINER_TEST_ID} />
      {latestReportsLoading ? (
        <LatestReportsLoading />
      ) : (
        <Box sx={{ p: 2, bgcolor: (theme) => theme.colors.BLACK_90 }}>
          {latestReports.length > 0 ? (
            <Box>
              <Box>
                {latestReports.map((report, index) => (
                  <Box key={report.id}>
                    {index > 0 && (
                      <Divider
                        sx={{
                          borderColor: (theme) => theme.colors.WHITE_20,
                          mb: 1,
                        }}
                      />
                    )}
                    <ReportPreview {...report} />
                  </Box>
                ))}
              </Box>
              <Divider
                sx={{ borderColor: (theme) => theme.colors.WHITE_20, mb: 1 }}
              />
              <LinkButton
                to={`/${REPORTS_ROUTE}`}
                size="large"
                sx={{ my: 5, width: '100%' }}
                endIcon={
                  <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                }
              >
                {t('latestReports.moreCta', { ns: 'landingPage' })}
              </LinkButton>
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NoDataWithContactCta />
            </Box>
          )}
        </Box>
      )}
    </PageSection>
  )
}
