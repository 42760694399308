import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { NormalLoading } from 'components/Loading/NormalLoading'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchTabMalwares,
  fetchTabMalwaresCancelled,
  resetTabMalwareContent,
  selectHasMoreTabMalwares,
  selectTabMalwareDataLoading,
  selectTabMalwares,
} from 'store/slices/tabContent'

import { MalwarePreview } from './MalwarePreview'

const SCROLLABLE_CONTAINER_ID = 'tab-malwares-scrollable-container'

interface IMalwareTabContentProps {
  searchText: string
}

export const MalwareTabContent = ({ searchText }: IMalwareTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const tabMalwares = useAppSelector(selectTabMalwares)
  const isTabMalwareLoading = useAppSelector(selectTabMalwareDataLoading)
  const hasMoreTabMalwares = useAppSelector(selectHasMoreTabMalwares)

  const [isScrollable, ref, node] = useIsScrollable([tabMalwares])

  const handleFetchTabMalwares = (refetch?: boolean) => {
    dispatch(
      fetchTabMalwares({ searchText, offset: refetch ? 0 : tabMalwares.length })
    )
  }

  // fetch initial tab samples
  useEffect(() => {
    handleFetchTabMalwares(true)
    return () => {
      dispatch(fetchTabMalwaresCancelled())
      dispatch(resetTabMalwareContent())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreTabMalwares) {
      handleFetchTabMalwares()
    }
  }, [isScrollable, hasMoreTabMalwares, node, tabMalwares.length])

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
    >
      {tabMalwares.length === 0 ? (
        isTabMalwareLoading ? (
          <NormalLoading />
        ) : (
          <NoData />
        )
      ) : (
        <InfiniteScrollWrapper
          dataLength={tabMalwares.length}
          next={handleFetchTabMalwares}
          hasMore={hasMoreTabMalwares}
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
        >
          {tabMalwares.map((malware) => (
            <Box
              key={malware.id}
              sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
            >
              <MalwarePreview
                {...malware}
                showTools
                showCheckDetailButton
                showHoverStyle
              />
            </Box>
          ))}
        </InfiniteScrollWrapper>
      )}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
