import { Divider, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

const LoadingBlock = () => (
  <Stack sx={{ px: 2, py: 4, gap: 2, width: '100%' }}>
    <Skeleton type="body" width="6.5rem" />
    <Skeleton type="body" width="100%" />
  </Stack>
)

export const ReportDetailLabelsLoading = () => {
  const theme = useTheme()

  return (
    <Stack>
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
    </Stack>
  )
}
