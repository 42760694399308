import { Box, useTheme } from '@mui/material'
import { DomainPreview } from 'components/Domain/DomainPreivew'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { OneLineLoading } from 'components/Loading/OneLineLoading'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchSampleRelationDomains,
  fetchSampleRelationDomainsCancelled,
  resetSampleRelationDomains,
  selectHasMoreSampleRelationDomains,
  selectSampleRelationDomains,
  selectSampleRelationDomainsLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../../type'

const SCROLLABLE_CONTAINER_ID = 'sample-detail-relation-domains-container'

export const RelatedDomain = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sampleRelationDomainsLoading = useAppSelector(
    selectSampleRelationDomainsLoading
  )
  const sampleRelationDomains = useAppSelector(selectSampleRelationDomains)
  const hasMoreSampleRelationDomains = useAppSelector(
    selectHasMoreSampleRelationDomains
  )
  const [isScrollable, ref, node] = useIsScrollable([sampleRelationDomains])

  const handleFetchRelationDomains = (refetch?: boolean) => {
    dispatch(
      fetchSampleRelationDomains({
        offset: refetch ? 0 : sampleRelationDomains.length,
        sampleId: searchText,
      })
    )
  }

  useEffect(() => {
    handleFetchRelationDomains(true)

    return () => {
      dispatch(fetchSampleRelationDomainsCancelled())
      dispatch(resetSampleRelationDomains())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreSampleRelationDomains) {
      handleFetchRelationDomains()
    }
  }, [
    isScrollable,
    hasMoreSampleRelationDomains,
    node,
    sampleRelationDomains.length,
  ])

  if (sampleRelationDomainsLoading && sampleRelationDomains.length === 0) {
    return <OneLineLoading />
  }

  if (sampleRelationDomains.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={sampleRelationDomains.length}
        next={handleFetchRelationDomains}
        hasMore={hasMoreSampleRelationDomains}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
        scrollThreshold="0px"
      >
        {sampleRelationDomains.map((domain) => (
          <Box
            key={domain.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <DomainPreview {...domain} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
