import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IRawIntel } from 'store/types/entityTypes/rawIntel'
import {
  IFetchRawIntelDetailPayload,
  IRawIntelDetailSlice,
} from 'store/types/slicesTypes/rawIntelDetail'

const initialState: IRawIntelDetailSlice = {
  isFetchingRawIntelDetail: false,
  rawIntelDetail: null,
}

const rawIntelDetailSlice = createSlice({
  name: 'rawIntelDetail',
  initialState,
  reducers: {
    fetchRawIntelDetail(
      state,
      _action: PayloadAction<IFetchRawIntelDetailPayload>
    ) {
      state.isFetchingRawIntelDetail = true
    },
    fetchRawIntelDetailFulfilled(state, action: PayloadAction<IRawIntel>) {
      state.isFetchingRawIntelDetail = false
      state.rawIntelDetail = action.payload
    },
    fetchRawIntelDetailRejected(state, _action: PayloadAction<AjaxError>) {
      state.isFetchingRawIntelDetail = false
      state.rawIntelDetail = initialState.rawIntelDetail
    },
    fetchRawIntelDetailCancelled(state) {
      state.isFetchingRawIntelDetail = false
    },
    resetRawIntelDetail(state) {
      state.rawIntelDetail = initialState.rawIntelDetail
    },
  },
})

export const {
  fetchRawIntelDetail,
  fetchRawIntelDetailFulfilled,
  fetchRawIntelDetailRejected,
  fetchRawIntelDetailCancelled,
  resetRawIntelDetail,
} = rawIntelDetailSlice.actions

export const selectRawIntelDetailLoading = (state: TRootState) =>
  state.rawIntel.isFetchingRawIntelDetail
export const selectRawIntelDetail = (state: TRootState) =>
  state.rawIntel.rawIntelDetail

export default rawIntelDetailSlice.reducer
