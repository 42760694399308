import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  FirstSeenLabel,
  HighlightLabel,
  RiskLevelLabel,
  TLPLabel,
} from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISampleDetail } from 'store/types/entityTypes/sample'

import { SampleEditTLPDrawer } from './SampleEditTLPDrawer'
import { SampleFilename } from './SampleElements'

export const SampleDetails = ({
  filename,
  riskLevel,
  tlp,
  sha256,
  size,
  firstSeen,
  iconUrl,
  date,
  canEditTlp,
}: ISampleDetail) => {
  const theme = useTheme()
  const { t } = useTranslation(['sample'])
  const {
    getDateStringByTimestampsInSeconds,
    getDateTimeWithSecondsStringByTimestampsInSeconds,
  } = useDateTime()

  const [tlpDrawerOpen, setTlpDrawerOpen] = useState<boolean>(false)

  const toggleTlpDrawerOpen = () => {
    setTlpDrawerOpen(!tlpDrawerOpen)
  }

  const imageUrl = useImageUrl({ fetchImageUrl: iconUrl })

  return (
    <Box sx={{ display: 'flex', px: 2, py: 4, gap: 10 }}>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box
          sx={{
            width: '5rem',
            height: '4rem',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
          }}
        >
          {imageUrl && <Image width={48} height={48} src={imageUrl} />}
        </Box>
        <Stack sx={{ gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <RiskLevelLabel level={riskLevel} />
            <Typography
              variant="titleSmall"
              sx={{ color: theme.colors.PRIMARY, lineHeight: '1.25rem' }}
            >
              {sha256}
            </Typography>
          </Box>
          <SampleFilename filename={filename} />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <TLPLabel level={tlp} />
            {canEditTlp && (
              <Typography
                onClick={toggleTlpDrawerOpen}
                variant="textLink"
                sx={{ color: theme.colors.TEXT_LINK_NORMAL, cursor: 'pointer' }}
              >
                {t('sampleInfo.editTlpCta', { ns: 'sample' })}
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack sx={{ gap: 2, ml: 10 }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', height: '1.25rem' }}
          >
            <FirstSeenLabel
              date={
                firstSeen ? getDateStringByTimestampsInSeconds(firstSeen) : '--'
              }
            />
          </Box>
          {size !== null && (
            <Box sx={{ display: 'flex', gap: 2, color: theme.colors.WHITE }}>
              <HighlightLabel text={t('sampleInfo.size', { ns: 'sample' })} />
              <Typography variant="textSmallImportant">
                {size.toLocaleString('en-us')}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 2, color: theme.colors.WHITE }}>
            <HighlightLabel
              text={t('sampleInfo.createdDate', { ns: 'sample' })}
            />
            <Typography variant="textSmallImportant">
              {getDateTimeWithSecondsStringByTimestampsInSeconds(date)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      {canEditTlp && (
        <SampleEditTLPDrawer
          drawerOpen={tlpDrawerOpen}
          toggleDrawer={toggleTlpDrawerOpen}
          sampleId={sha256}
        />
      )}
    </Box>
  )
}
