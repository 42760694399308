import { Box } from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NoData } from 'components/NoData/NoData'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { UIEvent, useEffect } from 'react'
import {
  fetchSamplePreviewAsciiStrings,
  fetchSamplePreviewAsciiStringsCancelled,
  fetchSamplePreviewStackStrings,
  fetchSamplePreviewStackStringsCancelled,
  fetchSamplePreviewUtf16leStrings,
  fetchSamplePreviewUtf16leStringsCancelled,
  resetSamplePreviewAsciiStrings,
  resetSamplePreviewStackStrings,
  resetSamplePreviewUtf16leStrings,
  selectSamplePreviewAsciiStrings,
  selectSamplePreviewAsciiStringsLoading,
  selectSamplePreviewStackStrings,
  selectSamplePreviewStackStringsLoading,
  selectSamplePreviewUtf16leStrings,
  selectSamplePreviewUtf16leStringsLoading,
} from 'store/slices/sampleDetail'
import { ISamplePreviewStrings } from 'store/types/entityTypes/sample'

import { ISampleTabContentProps } from '../type'
import type { TStringType } from './PreviewContent'
import { PreviewContentLoading } from './PreviewContentLoading'

interface IStringData {
  isLoading: boolean
  samplePreviewStringData: ISamplePreviewStrings
}

const useStringPreviewDataByStringType = (type: TStringType): IStringData => {
  const asciiStringLoading = useAppSelector(
    selectSamplePreviewAsciiStringsLoading
  )
  const asciiString = useAppSelector(selectSamplePreviewAsciiStrings)
  const utfStringLoading = useAppSelector(
    selectSamplePreviewUtf16leStringsLoading
  )
  const utfString = useAppSelector(selectSamplePreviewUtf16leStrings)
  const stackStringLoading = useAppSelector(
    selectSamplePreviewStackStringsLoading
  )
  const stackString = useAppSelector(selectSamplePreviewStackStrings)

  if (type === 'ascii') {
    return {
      isLoading: asciiStringLoading,
      samplePreviewStringData: asciiString,
    }
  }

  if (type === 'utf') {
    return {
      isLoading: utfStringLoading,
      samplePreviewStringData: utfString,
    }
  }

  if (type === 'stack') {
    return {
      isLoading: stackStringLoading,
      samplePreviewStringData: stackString,
    }
  }

  return {
    isLoading: false,
    samplePreviewStringData: {
      nextPage: null,
      contentStrings: '',
    },
  }
}

interface IPreviewStringsProps extends ISampleTabContentProps {
  stringType: TStringType
}

export const PreviewStrings = ({
  searchText,
  stringType,
}: IPreviewStringsProps) => {
  const dispatch = useAppDispatch()

  const { isLoading, samplePreviewStringData } =
    useStringPreviewDataByStringType(stringType)

  const handleFetchStrings = (refetch?: boolean) => {
    if (samplePreviewStringData.nextPage !== null) {
      if (stringType === 'ascii') {
        dispatch(
          fetchSamplePreviewAsciiStrings({
            page: refetch ? 0 : samplePreviewStringData.nextPage,
            sampleId: searchText,
          })
        )
      }

      if (stringType === 'utf') {
        dispatch(
          fetchSamplePreviewUtf16leStrings({
            page: refetch ? 0 : samplePreviewStringData.nextPage,
            sampleId: searchText,
          })
        )
      }

      if (stringType === 'stack') {
        dispatch(
          fetchSamplePreviewStackStrings({
            page: refetch ? 0 : samplePreviewStringData.nextPage,
            sampleId: searchText,
          })
        )
      }
    }
  }

  const handleFetchStringsCancelled = () => {
    if (stringType === 'ascii') {
      dispatch(fetchSamplePreviewAsciiStringsCancelled())
    }

    if (stringType === 'utf') {
      dispatch(fetchSamplePreviewUtf16leStringsCancelled())
    }

    if (stringType === 'stack') {
      dispatch(fetchSamplePreviewStackStringsCancelled())
    }
  }

  const handleResetStrings = () => {
    if (stringType === 'ascii') {
      dispatch(resetSamplePreviewAsciiStrings())
    }

    if (stringType === 'utf') {
      dispatch(resetSamplePreviewUtf16leStrings())
    }

    if (stringType === 'stack') {
      dispatch(resetSamplePreviewStackStrings())
    }
  }

  useEffect(() => {
    handleFetchStrings(true)

    return () => {
      handleFetchStringsCancelled()
      handleResetStrings()
    }
  }, [searchText])

  const [isScrollable, ref, node] = useIsScrollable([
    samplePreviewStringData.contentStrings,
  ])

  const hasMoreStrings = samplePreviewStringData.nextPage !== null

  useEffect(() => {
    if (node && !isScrollable && hasMoreStrings) {
      handleFetchStrings()
    }
  }, [
    isScrollable,
    hasMoreStrings,
    node,
    samplePreviewStringData.contentStrings,
  ])

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const targetElement = event.target as HTMLDivElement
    const isReachBottom =
      targetElement.scrollTop + targetElement.clientHeight >
      targetElement.scrollHeight - 60
    if (isReachBottom) {
      handleFetchStrings()
    }
  }

  if (isLoading && !samplePreviewStringData.contentStrings) {
    return <PreviewContentLoading />
  }
  if (samplePreviewStringData.contentStrings === 'null') {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      onScroll={handleScroll}
      sx={{ p: 4, height: '100%', overflowY: 'auto', whiteSpace: 'pre-line' }}
    >
      {samplePreviewStringData.contentStrings}
      {isLoading && <CenterCircularProgress />}
    </Box>
  )
}
