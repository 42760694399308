import { Box, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { pushAlertSnackbar } from 'store/slices/snackbar'
import { requestResetPasswordByEmail } from 'utils/auth'
import * as Yup from 'yup'

import { LoginStatus, STATUS_KEY } from '../config'

const requestFormInitialValues = { email: '' }

export const RequestForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['login', 'snackbar', 'component'])

  const requestFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('fieldErrorMessage.invalidEmail', { ns: 'component' }))
      .required(t('fieldErrorMessage.required', { ns: 'component' })),
  })

  const { isSubmitting, values, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: requestFormInitialValues,
      validationSchema: requestFormSchema,
      onSubmit: async () => {
        try {
          await requestResetPasswordByEmail(values.email)
          navigate(`?${STATUS_KEY}=${LoginStatus.RESET_PASSWORD_LINK_SEND}`)
        } catch (error) {
          dispatch(
            pushAlertSnackbar({
              text: t('resetPassword.resetSubmitEmailFail', { ns: 'snackbar' }),
            })
          )
        }
      },
    })

  return (
    <form
      onSubmit={handleSubmit}
      data-testid={LoginStatus.RESET_PASSWORD_REQUEST}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20rem',
        }}
      >
        <Typography
          variant="titleBig"
          sx={{ color: (theme) => theme.colors.TEXT_LINK_NORMAL, mb: 6 }}
        >
          {t('RequestForm.title', { ns: 'login' })}
        </Typography>
        <Typography
          variant="body"
          sx={{ color: (theme) => theme.colors.WHITE, mb: 6 }}
        >
          {t('RequestForm.description', { ns: 'login' })}
        </Typography>
        <Box sx={{ mb: 8, width: '100%' }}>
          <TextField
            name="email"
            label={t('loginForm.mailInputLabel', { ns: 'login' })}
            InputLabelProps={{ color: 'primary' }}
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
        <Button
          sx={{
            mb: 6,
            width: '100%',
          }}
          size="large"
          type="submit"
          disabled={isSubmitting}
        >
          {t('RequestForm.submitCta', { ns: 'login' })}
        </Button>
      </Box>
    </form>
  )
}
