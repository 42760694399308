import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_SEARCH_ADVERSARIES_COUNT,
  FETCH_SEARCH_DOMAINS_COUNT,
  FETCH_SEARCH_IPS_COUNT,
  FETCH_SEARCH_MALWARES_COUNT,
  FETCH_SEARCH_RAW_INTELS_COUNT,
  FETCH_SEARCH_REPORTS_COUNT,
  FETCH_SEARCH_SAMPLES_COUNT,
  FETCH_SEARCH_TECHNIQUES_COUNT,
} from 'constants/api'
import { chineseSearchTextMap } from 'constants/search'
import { ALL_REPORT_TYPES_QUERY } from 'constants/urlQuery'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchSearchAdversariesCount,
  fetchSearchAdversariesCountCancelled,
  fetchSearchAdversariesCountFulfilled,
  fetchSearchAdversariesCountRejected,
  fetchSearchDomainsCount,
  fetchSearchDomainsCountCancelled,
  fetchSearchDomainsCountFulfilled,
  fetchSearchDomainsCountRejected,
  fetchSearchIPsCount,
  fetchSearchIPsCountCancelled,
  fetchSearchIPsCountFulfilled,
  fetchSearchIPsCountRejected,
  fetchSearchMalwaresCount,
  fetchSearchMalwaresCountCancelled,
  fetchSearchMalwaresCountFulfilled,
  fetchSearchMalwaresCountRejected,
  fetchSearchRawIntelsCount,
  fetchSearchRawIntelsCountCancelled,
  fetchSearchRawIntelsCountFulfilled,
  fetchSearchRawIntelsCountRejected,
  fetchSearchReportsCount,
  fetchSearchReportsCountCancelled,
  fetchSearchReportsCountFulfilled,
  fetchSearchReportsCountRejected,
  fetchSearchSamplesCount,
  fetchSearchSamplesCountCancelled,
  fetchSearchSamplesCountFulfilled,
  fetchSearchSamplesCountRejected,
  fetchSearchTechniquesCount,
  fetchSearchTechniquesCountCancelled,
  fetchSearchTechniquesCountFulfilled,
  fetchSearchTechniquesCountRejected,
} from 'store/slices/search'
import {
  mapAPIReportTypesFromReportTypes,
  TSearchReportType,
} from 'store/types/entityTypes/report'
import {
  IFetchSearchCountResponse,
  TSearchResultKey,
} from 'store/types/slicesTypes/search'
import { fetchData } from 'utils/fetch.utils'
import { getArrayQueryString } from 'utils/queryString'

import { checkUnauthorizedToken } from '../auth'

const getCountFetchingFunctionByUrl = (url: string) =>
  fetchData<IFetchSearchCountResponse>({ url })

// 利用 searchText 組合多個抓 count 的 ajax functions，要傳入 combineLatest 一起併發
const getFetchSearchCountMap = ({
  searchText,
  searchReportTypeOptions = [],
  dateFrom,
  dateTo,
}: {
  searchText: string
  searchReportTypeOptions?: TSearchReportType[]
  dateFrom?: number | null
  dateTo?: number | null
}): Record<
  TSearchResultKey,
  Observable<AjaxResponse<IFetchSearchCountResponse>>
> => {
  const query = chineseSearchTextMap[searchText] || searchText

  const ALL_SEARCH_REPORT_TYPES_COUNT = 5
  let urlParams = `query=${encodeURIComponent(query)}`

  // filter all reports 需加上 types[] = on_demand
  if (searchReportTypeOptions.length === ALL_SEARCH_REPORT_TYPES_COUNT) {
    urlParams += `&${ALL_REPORT_TYPES_QUERY}`
  } else {
    urlParams += `&${getArrayQueryString({
      types: searchReportTypeOptions.map(
        (option: TSearchReportType) => mapAPIReportTypesFromReportTypes[option]
      ),
    })}`
  }

  if (dateFrom) {
    urlParams += `&date[from]=${dateFrom}`
  }
  if (dateTo) {
    urlParams += `&date[to]=${dateTo}`
  }

  return {
    reports: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_REPORTS_COUNT}?${urlParams}`
    ),
    adversaries: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_ADVERSARIES_COUNT}?query=${encodeURIComponent(query)}`
    ),
    malware: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_MALWARES_COUNT}?query=${encodeURIComponent(query)}`
    ),
    samples: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_SAMPLES_COUNT}?query=${encodeURIComponent(query)}`
    ),
    ips: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_IPS_COUNT}?query=${encodeURIComponent(query)}`
    ),
    domains: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_DOMAINS_COUNT}?query=${encodeURIComponent(query)}`
    ),
    techniques: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_TECHNIQUES_COUNT}?query=${encodeURIComponent(query)}`
    ),
    rawIntels: getCountFetchingFunctionByUrl(
      `${FETCH_SEARCH_RAW_INTELS_COUNT}?query=${encodeURIComponent(query)}`
    ),
  }
}

const reportsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchReportsCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).reports.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchReportsCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchSearchReportsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchReportsCountRejected(error))
          )
        )
      )
    )
  )

const adversariesCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchAdversariesCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).adversaries.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchAdversariesCountFulfilled(response.response.count)
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchAdversariesCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchAdversariesCountRejected(error))
          )
        )
      )
    )
  )

const malwaresCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchMalwaresCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).malware.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchMalwaresCountFulfilled(response.response.count)
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchMalwaresCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchMalwaresCountRejected(error))
          )
        )
      )
    )
  )

const samplesCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchSamplesCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).samples.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchSamplesCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchSearchSamplesCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchSamplesCountRejected(error))
          )
        )
      )
    )
  )

const ipsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchIPsCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).ips.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchIPsCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchSearchIPsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchIPsCountRejected(error))
          )
        )
      )
    )
  )

const domainsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchDomainsCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).domains.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchDomainsCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchSearchDomainsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchDomainsCountRejected(error))
          )
        )
      )
    )
  )

const techniquesCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchTechniquesCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).techniques.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchTechniquesCountFulfilled(response.response.count)
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchTechniquesCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchTechniquesCountRejected(error))
          )
        )
      )
    )
  )

const rawIntelsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSearchRawIntelsCount.match),
    switchMap((action) =>
      getFetchSearchCountMap(action.payload).rawIntels.pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchSearchRawIntelsCountFulfilled(response.response.count)
        ),
        takeUntil(
          action$.pipe(filter(fetchSearchRawIntelsCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSearchRawIntelsCountRejected(error))
          )
        )
      )
    )
  )

export const searchCountsEpic = combineEpics(
  reportsCountEpic,
  adversariesCountEpic,
  malwaresCountEpic,
  samplesCountEpic,
  ipsCountEpic,
  domainsCountEpic,
  techniquesCountEpic,
  rawIntelsCountEpic
)
