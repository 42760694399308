import { StatusCode } from 'constants/statusCode'

import { getAuthToken } from './auth'

interface IDownloadTextFileProps {
  value: string
  format: string
  filename: string
}

export const downloadTextFile = ({
  value,
  format,
  filename,
}: IDownloadTextFileProps) => {
  if (!filename) filename = 'untitled' // 沒有檔名的預設值
  const element = document.createElement('a')
  const file = new Blob([value], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = `${filename}.${format}`
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

interface IDownloadZipFileProps {
  value: string
  filename: string
}

export const downloadZipFile = ({ value, filename }: IDownloadZipFileProps) => {
  if (!filename) filename = 'untitled' // 沒有檔名的預設值
  const element = document.createElement('a')
  const file = new Blob([value], { type: 'text/html; charset=utf-8' })
  element.href = URL.createObjectURL(file)
  element.download = `${filename}.zip`
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

interface IDownloadPdfFileProps {
  value: ArrayBuffer
  filename: string
}

export const downloadPdfFile = ({ value, filename }: IDownloadPdfFileProps) => {
  if (!filename) filename = 'untitled' // 沒有檔名的預設值
  const element = document.createElement('a')
  const file = new Blob([value], { type: 'application/pdf;charset=utf-8' })
  element.href = URL.createObjectURL(file)

  // 後端帶來的 filename 通常會有 .pdf 的後綴，但保險起見還是檢查一下
  const downloadFilename = filename.includes('.pdf')
    ? filename
    : `${filename}.pdf`
  element.download = downloadFilename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

/**
 * @ref https://stackoverflow.com/a/67994693
 * @param disposition a header attribute
 * @returns filename string
 */
export const getFilenameByContentComposition = (disposition: string) => {
  if (!disposition) {
    return ''
  }

  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?: ?|$)/i
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  if (utf8FilenameRegex.test(disposition)) {
    const resultArr = utf8FilenameRegex.exec(disposition) || ['']
    return decodeURIComponent(resultArr[1])
  }
  // prevent ReDos attacks by anchoring the ascii regex to string start and
  // slicing off everything before 'filename='
  const filenameStart = disposition.toLowerCase().indexOf('filename=')
  if (filenameStart >= 0) {
    const partialDisposition = disposition.slice(filenameStart)
    const matches = asciiFilenameRegex.exec(partialDisposition)
    if (matches != null && matches[2]) {
      return matches[2]
    }
  }

  return ''
}

interface IDownloadItemProps {
  itemUrl: string
  format: 'text' | 'json' | 'zip'
  method?: 'GET' | 'POST'
}

export const downloadItem = async ({
  itemUrl,
  format,
  method = 'GET',
}: IDownloadItemProps) => {
  const { accessToken, tokenType } = getAuthToken()
  const requestUrl = itemUrl.substring(itemUrl.indexOf('/api/v1'))
  const downloadResponse = await fetch(requestUrl, {
    headers: { Authorization: `${tokenType} ${accessToken}` },
    method,
  })

  if (downloadResponse.ok) {
    if (format === 'json') {
      return downloadResponse.json()
    }

    if (format === 'zip') {
      return downloadResponse.arrayBuffer()
    }

    return downloadResponse.text()
  }

  // 判斷錯誤情形
  const error = await downloadResponse.json()
  if (error.status_code === StatusCode.UNAUTHORIZED_TOKEN) {
    throw new Error(StatusCode.UNAUTHORIZED_TOKEN)
  }

  return null
}
