import {
  Box,
  FormControlLabel,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { SelectedCountLabel } from 'components/Label/Label'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, Dispatch,SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchReportIocContent,
  fetchReportPdfContent,
  selectIsFetchReportIocContentLoadingCount,
  selectIsFetchReportPdfContentLoadingCount,
} from 'store/slices/report'
import { IReport } from 'store/types/entityTypes/report'
import { downloadPdfFile, downloadTextFile } from 'utils/download'

interface IReportToolbarProps {
  reports: IReport[]
  selectedReports: IReport[]
  setSelectedReports: Dispatch<SetStateAction<IReport[]>>
}

export const ReportToolbar = ({
  reports,
  selectedReports,
  setSelectedReports,
}: IReportToolbarProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['search'])
  const isFetchReportPdfContentLoadingCount = useAppSelector(
    selectIsFetchReportPdfContentLoadingCount
  )
  const isFetchReportIocContentLoadingCount = useAppSelector(
    selectIsFetchReportIocContentLoadingCount
  )

  const checkableReports = reports.filter((report) => report.permission)

  const selectedCount = selectedReports.length

  const isSelectEmpty = selectedCount === 0
  const indeterminate =
    !isSelectEmpty && selectedCount < checkableReports.length

  const handleSelectAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || indeterminate) {
      setSelectedReports(checkableReports)
    } else {
      setSelectedReports([])
    }
  }

  const handleDownloadPdf = () => {
    selectedReports.forEach(({ alias }) => {
      dispatch(
        fetchReportPdfContent({
          alias,
          successCallback: ({ response, filename }) =>
            downloadPdfFile({ value: response, filename }),
        })
      )
    })
  }

  const handleDownloadIoC = () => {
    selectedReports
      .filter((report) => report.stixUrl)
      .forEach(({ alias }) => {
        dispatch(
          fetchReportIocContent({
            alias,
            successCallback: (ioc) =>
              downloadTextFile({
                value: JSON.stringify(ioc),
                format: 'stix',
                filename: alias,
              }),
          })
        )
      })
  }

  const isDownloadIocDisabled = selectedReports.every(
    (report) => !report.stixUrl
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        borderBottom: `1px solid ${theme.colors.BLACK_50}`,
      }}
    >
      <FormControlLabel
        sx={{
          '& .MuiFormControlLabel-label': {
            color: theme.colors.WHITE,
            ...theme.typography.body,
          },
        }}
        control={
          <Checkbox
            checked={!isSelectEmpty}
            indeterminate={indeterminate}
            onChange={handleSelectAllCheckChange}
            disabled={checkableReports.length === 0}
          />
        }
        // add Typography style for disabled
        label={
          <Typography sx={{ color: theme.colors.WHITE }} variant="body">
            {t('downloadList.selectAll', { ns: 'search' })}
          </Typography>
        }
      />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <SelectedCountLabel count={selectedCount} />
        <Button
          endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
          onClick={handleDownloadPdf}
          disabled={isSelectEmpty || isFetchReportPdfContentLoadingCount !== 0}
          loading={isFetchReportPdfContentLoadingCount !== 0}
        >
          {t('downloadList.downloadPdfCta', { ns: 'search' })}
        </Button>
        <Button
          endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
          onClick={handleDownloadIoC}
          disabled={
            isSelectEmpty ||
            isFetchReportIocContentLoadingCount !== 0 ||
            isDownloadIocDisabled
          }
          loading={isFetchReportIocContentLoadingCount !== 0}
        >
          {t('downloadList.downloadIocCta', { ns: 'search' })}
        </Button>
      </Box>
    </Box>
  )
}
