import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { HighlightLabel } from 'components/Label/Label'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppSelector } from 'hooks/useReduxHooks'
import { threatLvMap, useThreatLvColor } from 'hooks/useThreatLevel'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { selectDomainDetail } from 'store/slices/domain'

export const DomainInfo = () => {
  const theme = useTheme()
  const { t } = useTranslation(['ipDomain'])
  const [searchParams] = useSearchParams()
  const threatLvColor = useThreatLvColor()
  const domainDetail = useAppSelector(selectDomainDetail)

  const domain = searchParams.get(`${SearchParamKey.DOMAIN}`)

  return (
    <Box
      data-testid="domain-info"
      sx={{ display: 'flex', alignItems: 'center', gap: 4, p: 3 }}
    >
      {domainDetail && (
        <>
          <Stack sx={{ alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                width: '6.875rem',
                height: '6.875rem',
                borderRadius: '50%',
                bgcolor: theme.colors.PRIMARY_20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '6.25rem',
                  height: '6.25rem',
                  borderRadius: '50%',
                  bgcolor: theme.colors.BLACK_85,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '6rem',
                    height: '6rem',
                    borderRadius: '50%',
                    bgcolor: threatLvColor[domainDetail.riskLevel],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="ITMScore"
                    sx={{ color: theme.colors.WHITE, display: 'flex' }}
                  >
                    {domainDetail.riskScore}
                  </Typography>
                  <Typography
                    variant="textSmallImportant"
                    sx={{
                      color: theme.colors.WHITE,
                      display: 'flex',
                      mt: -2,
                      // for `Medium Threat` word break line
                      width: '5.75rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      lineHeight: '110%',
                    }}
                  >
                    {threatLvMap[domainDetail.riskLevel]}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="textSmallImportant">
                        {t('scoreTooltip', { ns: 'ipDomain' })}
                      </Typography>
                    }
                  >
                    <SvgIcon
                      component={InfoIcon}
                      inheritViewBox
                      sx={{ width: '1rem', height: '1rem' }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {domainDetail.riskTypes &&
              domainDetail.riskTypes.map((type) => (
                <Typography
                  key={type}
                  variant="textSmallImportant"
                  sx={{
                    px: 2,
                    bgcolor: theme.colors.PRIMARY_20,
                    color: theme.colors.PRIMARY,
                    borderRadius: 0.5,
                  }}
                >
                  {type}
                </Typography>
              ))}
          </Stack>
          <Stack sx={{ gap: 2, flexGrow: 1 }}>
            <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
              {domain}
            </Typography>
            {/* <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <HighlightLabel text={t('domainLabels.currentIp', { ns: 'ipDomain' })} />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography
                  variant="textSmallImportant"
                  component="p"
                  sx={{ color: theme.colors.WHITE }}
                >
                  8.8.8.8
                </Typography>
              </Box>
            </Box> */}
            <Divider sx={{ width: '100%', bgcolor: theme.colors.WHITE_20 }} />
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <HighlightLabel
                text={t('domainLabels.attribution', { ns: 'ipDomain' })}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {domainDetail.attributes.length > 0 ? (
                  domainDetail.attributes.map((attribute, index) => (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      key={attribute.name}
                    >
                      <Typography
                        variant="textSmallImportant"
                        component="p"
                        sx={{ color: theme.colors.WHITE }}
                      >
                        {attribute.name}
                      </Typography>
                      <Tooltip
                        title={
                          <Stack>
                            <Typography variant="textSmallImportant">
                              {t('tooltip.firstSeen', { ns: 'ipDomain' })}:{' '}
                              {attribute.firstSeen}
                            </Typography>
                            <Typography variant="textSmallImportant">
                              {t('tooltip.lastSeen', { ns: 'ipDomain' })}:{' '}
                              {attribute.lastSeen}
                            </Typography>
                          </Stack>
                        }
                      >
                        <SvgIcon
                          component={InfoIcon}
                          inheritViewBox
                          sx={{ width: '1rem', height: '1rem' }}
                        />
                      </Tooltip>
                      {index < domainDetail.attributes.length - 1 && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          ,
                        </Typography>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="textSmallImportant"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    --
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <HighlightLabel
                text={t('domainLabels.latestUpdate', { ns: 'ipDomain' })}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography
                  variant="textSmallImportant"
                  component="p"
                  sx={{ color: theme.colors.WHITE }}
                >
                  {domainDetail.lastUpdateAt || '--'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <HighlightLabel
                text={t('domainLabels.registrar', { ns: 'ipDomain' })}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography
                  variant="textSmallImportant"
                  component="p"
                  sx={{ color: theme.colors.WHITE }}
                >
                  {domainDetail.registrar || '--'}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </>
      )}
    </Box>
  )
}
