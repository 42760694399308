import { AnyAction, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { createEpicMiddleware, Epic } from 'redux-observable'

import rootEpic from './epics'
import reducer from './slices'

export type TRootState = ReturnType<typeof reducer>

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, TRootState>()

export const setupStore = (
  preloadedState?: PreloadedState<TRootState>,
  forTest = false
) => {
  const additionalMiddleware = forTest ? [] : [epicMiddleware]
  return configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        additionalMiddleware
      ),
  })
}

const store = setupStore()

export type TAppDispatch = typeof store.dispatch

export type TAppEpic = Epic<AnyAction, AnyAction, TRootState>

epicMiddleware.run(rootEpic)

export default store
