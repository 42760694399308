import { Box, Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'

interface IVictimInformationProps {
  countries: string[]
  industries: string[]
}

export const VictimInformation = ({
  countries,
  industries,
}: IVictimInformationProps) => {
  const { t } = useTranslation(['rawIntel'])
  const theme = useTheme()

  return (
    <Stack sx={{ gap: 1 }}>
      <HighlightLabel text={t('label.victimInformation', { ns: 'rawIntel' })} />
      <Box sx={{ pl: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('label.county', { ns: 'rawIntel' })}:
          </Typography>
          {countries.length > 0 ? (
            countries.map((country, index) => (
              <Typography
                key={country}
                variant="bodySmall"
                sx={{ color: theme.colors.WHITE, lineHeight: 'inherit' }}
              >
                {country}
                {index + 1 < countries.length && ','}
              </Typography>
            ))
          ) : (
            <Typography
              variant="bodySmall"
              sx={{ color: theme.colors.WHITE, lineHeight: 'inherit' }}
            >
              --
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('label.industry', { ns: 'rawIntel' })}:
          </Typography>
          {industries.length > 0
            ? industries.map((industry, index) => (
                <Typography
                  key={industry}
                  variant="bodySmall"
                  sx={{ color: theme.colors.WHITE, lineHeight: 'inherit' }}
                >
                  {industry}
                  {index + 1 < industries.length && ','}
                </Typography>
              ))
            : '--'}
        </Box>
      </Box>
    </Stack>
  )
}

export const RawIntelMalwareLabel = ({ malwares }: { malwares: string[] }) => {
  const { t } = useTranslation(['rawIntel'])

  return (
    <LabelList
      labels={malwares}
      keyText={t('label.malware', { ns: 'rawIntel' })}
      getLabelLink={(label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`
      }
    />
  )
}

export const RawIntelAdversaryLabel = ({
  adversaries,
}: {
  adversaries: string[]
}) => {
  const { t } = useTranslation(['rawIntel'])

  return (
    <LabelList
      labels={adversaries}
      keyText={t('label.adversary', { ns: 'rawIntel' })}
      getLabelLink={(label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`
      }
    />
  )
}

export const RawIntelExploitLabel = ({ exploits }: { exploits: string[] }) => {
  const { t } = useTranslation(['rawIntel'])

  return (
    <LabelList
      labels={exploits}
      keyText={t('label.exploit', { ns: 'rawIntel' })}
    />
  )
}
