import { Box, Stack,Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { useTranslation } from 'react-i18next'

import { ILabelListProps } from './LabelList'

/**
 * @description 各項卡片中常見的屬性 label 列表
 * @param keyText label 列表左方的標頭
 * @param labels 列表 label 內容 array of string
 * @param labelClickCallback 點擊 label 觸發的事件，預設傳入 label string
 * @param maxCount label 最大數量，超過會以 ...More 顯示
 * @returns 屬性 labels 列表
 */
export const LabelListBreakLine = ({
  keyText,
  labels,
  getLabelLink, // affect label styles
  maxCount,
}: ILabelListProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const isLabelsCountOverFlow = maxCount && labels.length > maxCount
  const displayLabels = isLabelsCountOverFlow
    ? labels.slice(0, maxCount)
    : labels
  return (
    <Stack sx={{ gap: 1 }}>
      <Box sx={{ alignSelf: 'baseline', flexShrink: 0 }}>
        <HighlightLabel text={keyText} />
      </Box>
      {labels.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            flexWrap: 'wrap',
            pl: 2,
          }}
        >
          {displayLabels.map((label, index) => (
            <Box key={label} sx={{ display: 'flex', alignItems: 'center' }}>
              {getLabelLink && getLabelLink(label) ? (
                <TextLink to={getLabelLink(label) || ''}>{label}</TextLink>
              ) : (
                <Typography
                  variant="textSmallImportant"
                  sx={{
                    color: theme.colors.WHITE,
                  }}
                >
                  {label}
                </Typography>
              )}
              {index < displayLabels.length - 1 && (
                <Typography
                  variant={
                    getLabelLink && getLabelLink(label)
                      ? 'textLink'
                      : 'textSmallImportant'
                  }
                  sx={{
                    color: theme.colors.WHITE,
                    mr: 1,
                    textDecoration: 'none',
                  }}
                >
                  ,
                </Typography>
              )}
            </Box>
          ))}
          {isLabelsCountOverFlow && (
            <Typography
              component="div"
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE, ml: 1 }}
            >
              {t('labelMore', { ns: 'component' })}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography
          variant="textSmallImportant"
          sx={{ color: theme.colors.WHITE, paddingLeft: '0.5rem' }}
        >
          --
        </Typography>
      )}
    </Stack>
  )
}
