import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_DOMAIN_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/network/domains/lomeptos.com" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"lomeptos.com",
  "analysis_status":true,
  "risk_level":"high",
  "risk_score":75,
  "adversaries":[],
  "attributes":[],
  "services":[],
  "registrar":"OwnRegistrar, Inc.",
  "last_update_at":"2023-12-27T11:19:27.727Z",
  "summary":{
    "whois":true,
    "related_adversaries":0,
    "related_reports":0,
    "related_samples":0,
    "dns_records":41,
    "osint":1
  }
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Get Domain Information'

export const APIDocumentVersionTwoDomainGetDomainInformation = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_DOMAIN_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_DOMAIN_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          The endpoint provides meta data of the domain, such as risk level,
          registrar, services.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/network/domains/:fqdn" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            analysis_status
            <UnorderedList>
              <ListItem>The domain had been analyzed</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The FQDN</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>risk_level</ListItem>
          <ListItem>risk_score</ListItem>
          <ListItem>risk_types</ListItem>
          <ListItem>adversaries</ListItem>
          <ListItem>
            attributes
            <UnorderedList>
              <ListItem>name</ListItem>
              <ListItem>first_seen</ListItem>
              <ListItem>last_seen</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            services
            <UnorderedList>
              <ListItem>name</ListItem>
              <ListItem>first_seen</ListItem>
              <ListItem>last_seen</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>registrar</ListItem>
          <ListItem>last_update_at</ListItem>
          <ListItem>
            summary
            <UnorderedList>
              <ListItem>whois: boolean</ListItem>
              <ListItem>
                related_adversaries: Number of related adversaries
              </ListItem>
              <ListItem>related_reports: Number of related reports</ListItem>
              <ListItem>related_samples: Number of related samples</ListItem>
              <ListItem>dns_records: Number of dns records</ListItem>
              <ListItem>osint: Number of retrieved OSINT records</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
