import { Switch as MuiSwitch, SwitchProps, useTheme } from '@mui/material'

export const Switch = ({
  sx = [],
  checked,
  disabled,
  ...otherProps
}: SwitchProps) => {
  const theme = useTheme()
  return (
    <MuiSwitch
      checked={checked}
      disabled={disabled}
      sx={[
        {
          '& .MuiSwitch-switchBase': {
            '&:hover': {
              bgcolor: theme.colors.WHITE_10,
            },
          },
          '.MuiSwitch-thumb': {
            color: checked
              ? disabled
                ? '#867A5E'
                : theme.colors.PRIMARY
              : disabled
              ? theme.colors.BLACK_60
              : theme.colors.BLACK_20,
          },
          '.MuiSwitch-track': {
            backgroundColor: disabled
              ? theme.colors.BLACK_95
              : theme.colors.BLACK_60,
          },
          '.PrivateSwitchBase-input.MuiSwitch-input': {
            left: '-50%',
            width: '150%',
          },
          '.Mui-disabled+.MuiSwitch-track': {
            opacity: 1,
          },
          '.Mui-checked+.MuiSwitch-track': {
            backgroundColor: disabled
              ? theme.colors.PRIMARY_20
              : theme.colors.PRIMARY_40,
            opacity: 1,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
    />
  )
}
