import { Box, Typography, useTheme } from '@mui/material'

import type { TPreviewFormat, TStringType } from './PreviewContent'

const formats: TPreviewFormat[] = ['Strings', 'HEX']

interface IPreviewContentTabs {
  selectedFormat: TPreviewFormat
  handleFormatChange: (newFormat: TPreviewFormat) => void
  stringType: TStringType
  handleStringTypeChange: (newType: TStringType) => void
}

const stringTabs: { text: string; value: TStringType }[] = [
  {
    text: 'ASCII',
    value: 'ascii',
  },
  {
    text: 'UTF',
    value: 'utf',
  },
  {
    text: 'STACK',
    value: 'stack',
  },
]

export const PreviewContentTabs = ({
  selectedFormat,
  handleFormatChange,
  stringType,
  handleStringTypeChange,
}: IPreviewContentTabs) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '2.5rem',
        px: 4,
        py: 2.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: theme.colors.BLACK_95,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        {formats.map((format) => {
          const isFormatSelected = selectedFormat === format
          return (
            <Box
              key={format}
              sx={{
                px: 2,
                py: 0.5,
                border: `1px solid ${
                  isFormatSelected
                    ? theme.colors.PRIMARY
                    : theme.colors.WHITE_20
                }`,
                borderRadius: 0.5,
                cursor: 'pointer',
              }}
              onClick={() => handleFormatChange(format)}
            >
              <Typography
                variant="textSmallImportant"
                sx={{
                  color: isFormatSelected
                    ? theme.colors.PRIMARY
                    : theme.colors.WHITE,
                }}
              >
                {format}
              </Typography>
            </Box>
          )
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
        }}
      >
        {selectedFormat === 'Strings' &&
          stringTabs.map((stringTab, index) => {
            const isStringTypeSelected = stringType === stringTab.value
            return (
              <Box
                key={stringTab.value}
                sx={{
                  px: 2,
                  py: 0.5,
                  cursor: 'pointer',
                  bgcolor: isStringTypeSelected
                    ? theme.colors.BLACK_85
                    : 'inherit',
                  borderLeft:
                    index > 0 ? `1px solid ${theme.colors.WHITE_20}` : 'none',
                }}
                onClick={() => handleStringTypeChange(stringTab.value)}
              >
                <Typography
                  variant="textSmallImportant"
                  sx={{ color: theme.colors.WHITE }}
                >
                  {stringTab.text}
                </Typography>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
