import { Avatar, Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { Button } from 'components/Button/Button'
import { PageSection } from 'components/PageSection/PageSection'
import { MAX_FILE_SIZE } from 'constants/fileSize'
import { useAvatarColor } from 'hooks/useAvatarColor'
import { useImageUrl } from 'hooks/useImageSrc'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  removeAccountAvatar,
  selectIsFetchAccountSettingsLoading,
  selectIsUpdateAccountAvatarLoading,
  updateAccountAvatar,
  updateAccountSettings,
} from 'store/slices/account'
import { pushAlertSnackbar } from 'store/slices/snackbar'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { resizeAvatarImageFile } from 'utils/imageResizer'

import { AccountDrawer } from './AccountDrawer'
import { AccountLoading } from './AccountLoading'

export const Account = ({ email, avatar }: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account', 'snackbar'])
  const dispatch = useAppDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const avatarUrl = useImageUrl({ fetchImageUrl: avatar || '' })
  const isUpdateAccountAvatarLoading = useAppSelector(
    selectIsUpdateAccountAvatarLoading
  )
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const handleUploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const convertedFile = await resizeAvatarImageFile(file)
      if (file.size > MAX_FILE_SIZE) {
        dispatch(
          pushAlertSnackbar({
            text: t('upload.uploadSizeFail', { ns: 'snackbar' }),
          })
        )
      } else {
        const formData = new FormData()
        formData.append(
          'tip_user[avatar_upload][original]',
          convertedFile,
          convertedFile.name
        )
        formData.append(
          'tip_user[avatar_upload][resized]',
          convertedFile,
          convertedFile.name
        )
        dispatch(updateAccountAvatar(formData))
      }
      // https://stackoverflow.com/a/56258902
      // reset the input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const toggleDrawer = () => {
    setDrawerOpen((open) => !open)
  }

  const handleRemoveAvatar = () => {
    dispatch(
      updateAccountSettings({
        tip_user: {
          remove_avatar: true,
        },
        failMessage: t('avatar.removeFail', { ns: 'account' }),
      })
    )
    dispatch(removeAccountAvatar())
  }

  const avatarBgcolor = useAvatarColor(email)

  return (
    <PageSection
      title={t('account.title', { ns: 'account' })}
      titleAction={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button size="small" onClick={handleRemoveAvatar}>
            {t('account.removeAvatarCta', { ns: 'account' })}
          </Button>
          <Button
            size="small"
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
            component="label"
            loading={isUpdateAccountAvatarLoading}
          >
            {t('account.changeAvatarCta', { ns: 'account' })}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleUploadAvatar}
            />
          </Button>
        </Box>
      }
    >
      <Box>
        {accountSettingsLoading ? (
          <AccountLoading />
        ) : (
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 4, px: 4, py: 2 }}
          >
            {avatarUrl ? (
              <Avatar src={avatarUrl} />
            ) : (
              <Avatar sx={{ bgcolor: avatarBgcolor }}>
                <Typography variant="reportH2">
                  {email && email.slice(0, 1).toLocaleUpperCase()}
                </Typography>
              </Avatar>
            )}
            <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
              {email}
            </Typography>
            <Button
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
              onClick={toggleDrawer}
            >
              {t('account.changePasswordCta', { ns: 'account' })}
            </Button>
          </Box>
        )}
        <AccountDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      </Box>
    </PageSection>
  )
}
