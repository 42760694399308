import { Box, Stack, Typography } from '@mui/material'
import { Alert } from 'components/Alert/Alert'
import { TextLink } from 'components/Link/Link'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NormalLoading } from 'components/Loading/NormalLoading'
import { RawIntelPreview } from 'components/RawIntel/RawIntelPreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  closeSearchRawIntelWarning,
  fetchSearchRawIntelsData,
  fetchSearchRawIntelsDataCancelled,
  resetSearchRawIntelsData,
  selectRawIntelWarningOpen,
  selectSearchRawIntelResultType,
  selectSearchRawIntels,
} from 'store/slices/search'
import { IRawIntel } from 'store/types/entityTypes/rawIntel'
import { openMail } from 'utils/mail'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'scrollable-container'

export interface ISearchRawIntel {
  data: IRawIntel[]
  isDataLoading: boolean
  hasMoreData: boolean
}

export const RawIntelContent = () => {
  const { t } = useTranslation(['rawIntel'])

  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const searchResultType = useAppSelector(selectSearchRawIntelResultType)
  const isWarningOpen = useAppSelector(selectRawIntelWarningOpen)
  const rawIntels = useAppSelector(selectSearchRawIntels)

  const rawIntelCurrentCount = rawIntels.data.length
  const isRawIntelLoading = rawIntels.isDataLoading
  const hasMoreRawIntel = rawIntels.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchRawIntel = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchRawIntelsData({
          searchText,
          offset: refetch ? 0 : rawIntelCurrentCount,
        })
      )
    }
  }

  const handleClose = () => {
    dispatch(closeSearchRawIntelWarning())
  }

  // fetch initial data
  useEffect(() => {
    fetchRawIntel(true)
    return () => {
      dispatch(fetchSearchRawIntelsDataCancelled())
      dispatch(resetSearchRawIntelsData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreRawIntel && rawIntelCurrentCount > 0) {
      fetchRawIntel()
    }
  }, [inView, hasMoreRawIntel, rawIntelCurrentCount])

  if (isRawIntelLoading && rawIntelCurrentCount === 0) {
    return <NormalLoading />
  }

  if (rawIntelCurrentCount === 0 && !isRawIntelLoading) {
    return <NoResultContent />
  }

  return (
    <Stack sx={{ height: '100%' }}>
      {isWarningOpen && (
        <Box sx={{ px: 6, py: 2 }}>
          <Alert
            showAlertIcon
            title={
              <Typography variant="titleSmall">
                {t('alert.title', { ns: 'rawIntel' })}
              </Typography>
            }
            content={
              <Typography variant="body">
                <Trans t={t} i18nKey="alert.descriptionWithLink">
                  “TeamT5 Raw Intel” is TeamT5’s preliminary findings about the
                  malicious campaign. “TeamT5 Raw Intel” can help the user to
                  have a bigger picture of the malicious campaign based on the
                  searched Indicators of Compromise (IOCs). However, the
                  information of “TeamT5 Raw Intel” requires further
                  investigation. We recommend that our user who needs further
                  analysis and investigation can
                  <TextLink
                    size="medium"
                    onClick={openMail}
                    to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${searchText}&${SearchParamKey.SEARCH_RESULT_TAB}=rawIntels`}
                  >
                    contact us
                  </TextLink>
                </Trans>
              </Typography>
            }
            onClose={handleClose}
          />
        </Box>
      )}
      <Box
        id={SCROLLABLE_CONTAINER_ID}
        sx={{ height: '100%', overflowY: 'auto' }}
      >
        {rawIntels.data.map((rawIntel) => (
          <RawIntelPreview
            key={rawIntel.id}
            searchResultType={searchResultType}
            {...rawIntel}
          />
        ))}
        {hasMoreRawIntel && <CenterCircularProgress scrollRef={ref} />}
        <ScrollToTop
          scrollableContainerId={SCROLLABLE_CONTAINER_ID}
          sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
        />
      </Box>
    </Stack>
  )
}
