import { Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

export const Description = ({
  children,
}: {
  children: NonNullable<ReactNode>
}) => {
  const theme = useTheme()

  return (
    <Typography variant="reportInlineText" sx={{ color: theme.colors.WHITE }}>
      {children}
    </Typography>
  )
}
