import { Box, Stack } from '@mui/material'
import { OutlineTabWithCount } from 'components/Tab/OutlineTabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSampleRelationsCount,
  fetchSampleRelationsCountCancelled,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../type'
import { useRelationTabs } from './config'

export const RelationContent = ({ searchText }: ISampleTabContentProps) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const relationTabs = useRelationTabs()
  const relationTabKey =
    searchParams.get(SearchParamKey.SAMPLE_RELATION_TAB) || relationTabs[0].key

  const handleRelationTabClick = (key: string) => {
    searchParams.set(SearchParamKey.SAMPLE_RELATION_TAB, key)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (searchText) {
      dispatch(fetchSampleRelationsCount(searchText))
    }
    return () => {
      dispatch(fetchSampleRelationsCountCancelled())
    }
  }, [searchText])

  const BottomComponent = relationTabs.find(
    (tab) => tab.key === relationTabKey
  )?.component

  return (
    <Stack sx={{ height: '100%', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
        {relationTabs.map((tab) => (
          <OutlineTabWithCount
            key={tab.key}
            name={tab.name}
            count={tab.count}
            showCount={tab.showCount}
            handleSelect={() => handleRelationTabClick(tab.key)}
            selected={tab.key === relationTabKey}
            isLoading={tab.isLoading}
          />
        ))}
      </Box>
      {BottomComponent && (
        <Box
          sx={{
            maxWidth: '60.5rem',
            flexGrow: 1,
            flexBasis: 0,
            overflow: 'hidden',
          }}
        >
          <BottomComponent searchText={searchText} />
        </Box>
      )}
    </Stack>
  )
}
