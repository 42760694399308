import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import { TRootState } from 'store'
import {
  IPushSnackbarPayload,
  ISnackbarSlice,
} from 'store/types/slicesTypes/snackbar'

const initialSnackbarState: ISnackbarSlice = {
  snackbar: [],
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialSnackbarState,
  reducers: {
    pushAlertSnackbar(state, action: PayloadAction<IPushSnackbarPayload>) {
      state.snackbar = [
        ...state.snackbar,
        { ...action.payload, type: 'error', id: Date.now() },
      ]
    },
    pushWarningSnackbar(state, action: PayloadAction<IPushSnackbarPayload>) {
      state.snackbar = [
        ...state.snackbar,
        { ...action.payload, type: 'warning', id: Date.now() },
      ]
    },
    pushSuccessSnackbar(state, action: PayloadAction<IPushSnackbarPayload>) {
      state.snackbar = [
        ...state.snackbar,
        { ...action.payload, type: 'success', id: Date.now() },
      ]
    },
    pushInfoSnackbar(state, action: PayloadAction<IPushSnackbarPayload>) {
      state.snackbar = [
        ...state.snackbar,
        { ...action.payload, type: 'info', id: Date.now() },
      ]
    },
    removeSnackbar(state, action: PayloadAction<number>) {
      state.snackbar = state.snackbar.filter(({ id }) => id !== action.payload)
    },
  },
})

export const {
  pushAlertSnackbar,
  pushWarningSnackbar,
  pushSuccessSnackbar,
  pushInfoSnackbar,
  removeSnackbar,
} = snackbarSlice.actions

export const selectSnackbar = (state: TRootState) => state.snackbar

export default snackbarSlice.reducer
