import { Box } from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchSamplesData,
  fetchSearchSamplesDataCancelled,
  resetSearchSamplesData,
  selectSearchSamples,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'sample-scrollable-container'

export const SampleContent = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const samples = useAppSelector(selectSearchSamples)
  const sampleCurrentCount = samples.data.length
  const isSamplesLoading = samples.isDataLoading || samples.isCountLoading
  const hasMoreSamples = samples.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchSamples = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchSamplesData({
          searchText,
          offset: refetch ? 0 : sampleCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchSamples(true)
    return () => {
      dispatch(fetchSearchSamplesDataCancelled())
      dispatch(resetSearchSamplesData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreSamples && sampleCurrentCount > 0) {
      fetchSamples()
    }
  }, [inView, hasMoreSamples, sampleCurrentCount])

  if (isSamplesLoading && sampleCurrentCount === 0) {
    return <SampleLoading />
  }

  if (sampleCurrentCount === 0 && !isSamplesLoading) {
    return <NoResultContent />
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ overflowY: 'auto', height: '100%' }}
    >
      {samples.data.map((sample) => (
        <SamplePreview key={sample.sha256} {...sample} />
      ))}
      {hasMoreSamples && <CenterCircularProgress scrollRef={ref} />}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
