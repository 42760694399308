import { Box, useTheme } from '@mui/material'
import { PAGE_TITLE_REPORT_INTRODUCTION } from 'constants/pageTitle'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation } from 'react-router-dom'

import { ReportIntroductionNavbar } from './ReportIntroductionNavbar'

export const ReportIntroductionPage = () => {
  const theme = useTheme()
  const { hash } = useLocation()

  useEffect(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_INTRODUCTION}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          display: 'flex',
          bgcolor: theme.colors.BLACK_95,
        }}
      >
        <ReportIntroductionNavbar />
        <Outlet />
      </Box>
    </>
  )
}
