import { App } from 'App'
import { WEBSITE_MAINTENANCE_ROUTE } from 'constants/routeParams'
import useShowSnackbar from 'hooks/useShowSnackbar'
import { WebsiteMaintenancePage } from 'pages/ErrorPage/WebsiteMaintenancePage'
import { LoginPage } from 'pages/LoginPage/LoginPage'
import { Route, Routes } from 'react-router-dom'

export const RootRoutes = () => {
  useShowSnackbar()

  // const navigate = useNavigate()

  // useEffect(() => {
  //   if (!debugMode) {
  //     navigate(`/${WEBSITE_MAINTENANCE_ROUTE}`, { replace: true })
  //   }
  // })

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/*" element={<App />} />
      <Route
        path={`/${WEBSITE_MAINTENANCE_ROUTE}`}
        element={<WebsiteMaintenancePage />}
      />
    </Routes>
  )
}
