import { Box, Typography, useTheme } from '@mui/material'
import {
  NavAccordion,
  NavAccordionContent,
} from 'components/Navbar/NavAccordion'
import {
  APT_INTRODUCTION_ROUTE,
  CTR_INTRODUCTION_ROUTE,
  FLASH_INTRODUCTION_ROUTE,
  MONTHLY_INTRODUCTION_ROUTE,
  PREFACE_INTRODUCTION_ROUTE,
  REPORT_INTRODUCTION_ROUTE,
} from 'constants/routeParams'
import { Link, matchPath, useLocation } from 'react-router-dom'

export const ReportIntroductionNavbar = () => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const levelOneBorderBottom = `1px solid ${theme.colors.WHITE_20}`

  return (
    <Box
      sx={{
        minWidth: '17.5rem',
        maxWidth: '17.5rem',
        height: '100%',
        overflow: 'auto',
        borderRight: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <Link to={`/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`}>
        <Box
          sx={{
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
            pl: 4,
            pr: 2,
            cursor: 'pointer',
            '&:hover': {
              bgcolor: matchPath(
                {
                  path: `/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`,
                  end: false,
                },
                pathname
              )
                ? theme.colors.PRIMARY_40
                : theme.colors.BLACK_20,
            },
            bgcolor: matchPath(
              {
                path: `/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`,
                end: false,
              },
              pathname
            )
              ? theme.colors.PRIMARY_60
              : theme.colors.BLACK_95,
            borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          }}
        >
          <Typography variant="titleSmall" sx={{ color: theme.colors.WHITE }}>
            Preface
          </Typography>
        </Box>
      </Link>
      <NavAccordion
        text="APT"
        defaultExpanded={pathname.includes(APT_INTRODUCTION_ROUTE)}
        borderBottom={levelOneBorderBottom}
      >
        <>
          <NavAccordionContent
            text="APT in Asia Flash"
            activeKey={`/${REPORT_INTRODUCTION_ROUTE}/${APT_INTRODUCTION_ROUTE}/${FLASH_INTRODUCTION_ROUTE}`}
          />
          <NavAccordionContent
            text="APT in Asia Monthly"
            activeKey={`/${REPORT_INTRODUCTION_ROUTE}/${APT_INTRODUCTION_ROUTE}/${MONTHLY_INTRODUCTION_ROUTE}`}
          />
          <NavAccordionContent
            text="APT Campaign Tracking Report"
            activeKey={`/${REPORT_INTRODUCTION_ROUTE}/${APT_INTRODUCTION_ROUTE}/${CTR_INTRODUCTION_ROUTE}`}
          />
        </>
      </NavAccordion>
    </Box>
  )
}
