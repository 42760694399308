import { Box } from '@mui/material'

import { AdversaryMatchedCard } from './AdversaryMatchedCard'
import { useMatchedInfo } from './config'
import { MalwareMatchedCard } from './MalwareMatchedCard'
import {
  TargetCountryMatchedCard,
  TargetIndustryMatchedCard,
  TargetRegionMatchedCard,
} from './TargetMatchedCard'

export const MatchedCard = () => {
  const matchedInfo = useMatchedInfo()

  if (!matchedInfo) {
    return null
  }

  return (
    <Box>
      {matchedInfo.type === 'Adversary' && (
        <AdversaryMatchedCard adversaryId={matchedInfo.name} />
      )}
      {matchedInfo.type === 'Malware' && (
        <MalwareMatchedCard malwareId={matchedInfo.name} />
      )}
      {matchedInfo.type === 'Region' && (
        <TargetRegionMatchedCard name={matchedInfo.name} />
      )}
      {matchedInfo.type === 'Country' && (
        <TargetCountryMatchedCard name={matchedInfo.name} />
      )}
      {matchedInfo.type === 'Industry' && (
        <TargetIndustryMatchedCard name={matchedInfo.name} />
      )}
    </Box>
  )
}
