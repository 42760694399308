import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { HighlightLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import {
  SAMPLE_DETAILS_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'

interface ISampleTitle {
  title: string
}

export const SampleTitle = ({ title }: ISampleTitle) => {
  const theme = useTheme()
  return (
    <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
      {title}
    </Typography>
  )
}

interface ISampleFilename {
  filename: string
}

export const SampleFilename = ({ filename }: ISampleFilename) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Box sx={{ display: 'flex', gap: 2, color: theme.colors.WHITE }}>
      <HighlightLabel
        text={t('samplePreview.labels.fileName', { ns: 'component' })}
      />
      <Typography variant="textSmallImportant">{filename || '--'}</Typography>
    </Box>
  )
}

interface IRelatedAdversariesProps {
  adversaries: string[]
}

export const RelatedAdversaries = ({
  adversaries,
}: IRelatedAdversariesProps) => {
  const { t } = useTranslation(['component'])

  return (
    <LabelList
      labels={adversaries}
      keyText={t('samplePreview.labels.adversary', { ns: 'component' })}
      maxCount={5}
      getLabelLink={(adversaryName: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${adversaryName}`
      }
    />
  )
}

interface IRelatedMalwaresProps {
  malwares: string[]
}

export const RelatedMalwares = ({ malwares }: IRelatedMalwaresProps) => {
  const { t } = useTranslation(['search', 'component'])

  return (
    <LabelList
      labels={malwares}
      keyText={t('samplePreview.labels.malware', { ns: 'component' })}
      maxCount={5}
      getLabelLink={(target: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
      }
    />
  )
}

interface IMoreSampleDetailButton {
  sampleId: string
}

export const MoreSampleDetailButton = ({
  sampleId,
}: IMoreSampleDetailButton) => {
  const { t } = useTranslation(['component'])

  return (
    <LinkButton
      to={`/${SAMPLE_DETAILS_ROUTE}?${SearchParamKey.SAMPLE_ID}=${sampleId}`}
      variant="contained"
      endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
    >
      {t('checkDetailCta', { ns: 'component' })}
    </LinkButton>
  )
}
