import { Box, Divider, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { Flag } from 'components/Flag/Flag'
import { HighlightLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const AdversaryAliasList = ({ aliases }: { aliases: string[] }) => {
  const { t } = useTranslation(['component'])
  return (
    <LabelList
      keyText={t('adversaryPreview.labels.alias', { ns: 'component' })}
      labels={aliases}
    />
  )
}

export const AdversaryOrigin = ({ country }: { country: string }) => {
  const { t } = useTranslation(['component'])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <HighlightLabel
        text={t('adversaryPreview.labels.origin', { ns: 'component' })}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          height: '1rem',
        }}
      >
        <Typography
          sx={{ color: (theme) => theme.colors.WHITE }}
          variant="textSmallImportant"
        >
          {country}
        </Typography>
        <Flag width={20} country={country} />
      </Box>
    </Box>
  )
}

interface ITargetListProps {
  keyText: string
  labels: string[]
}

export const AdversaryTargetList = ({ keyText, labels }: ITargetListProps) => (
  <LabelList
    keyText={keyText}
    labels={labels}
    getLabelLink={(target: string) =>
      `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
    }
  />
)

export const AdversaryIntroduction = ({
  overview,
  showMoreLink = false,
}: {
  overview: string
  showMoreLink?: boolean
}) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const toggleDrawer = () => {
    setDrawerOpen((open) => !open)
  }

  return (
    <Box>
      <HighlightLabel
        text={t('adversaryPreview.labels.introduction', { ns: 'component' })}
      />
      <Typography
        variant="body"
        sx={[
          { color: theme.colors.WHITE, mt: 2, ml: 2 },
          showMoreLink && {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            lineHeight: '1.05rem',
            WebkitLineClamp: 1,
          },
        ]}
      >
        {overview}
      </Typography>
      {showMoreLink && (
        <Typography
          variant="textLink"
          sx={{ width: 'fit-content', cursor: 'pointer', pl: 2 }}
          onClick={toggleDrawer}
        >
          {t('techniquePreview.label.descriptionMoreCta', {
            ns: 'component',
          })}
        </Typography>
      )}
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
          {t('adversaryPreview.labels.introduction', { ns: 'component' })}
        </Typography>
        <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
        <Box sx={{ py: 2 }}>
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            {overview}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  )
}
