import { TCommentType } from './comment'

export interface IAdversary {
  id: string
  image: string
  imageThemeColor: string
  name: string
  aliases: string[]
  originCountries: string[]
  targetedCountries: string[]
  targetedIndustries: string[]
  overview: string
  commentableType: TCommentType
}

export interface IAdversaryAPIData {
  main_value: string
  image: string
  image_theme_color: string
  name: string
  aliases: string[]
  origin_countries: string[]
  targeted_countries: string[]
  targeted_industries: string[]
  overview: string
  commentable_type: TCommentType
}

export const mapAPIAdversaryToState = (
  fromAPIAdversary: IAdversaryAPIData
): IAdversary => ({
  id: fromAPIAdversary.main_value,
  image: fromAPIAdversary.image,
  name: fromAPIAdversary.name,
  aliases: fromAPIAdversary.aliases,
  overview: fromAPIAdversary.overview,
  imageThemeColor: fromAPIAdversary.image_theme_color,
  originCountries: fromAPIAdversary.origin_countries,
  targetedCountries: fromAPIAdversary.targeted_countries,
  targetedIndustries: fromAPIAdversary.targeted_industries,
  commentableType: fromAPIAdversary.commentable_type,
})

export const mapAPIAdversariesToState = (
  fromAPIAdversaries: IAdversaryAPIData[]
): IAdversary[] => fromAPIAdversaries.map(mapAPIAdversaryToState)
