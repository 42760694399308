import { Box, Stack, Typography, useTheme } from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NoData } from 'components/NoData/NoData'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { UIEvent, useEffect, useMemo } from 'react'
import {
  fetchSamplePreviewHex,
  fetchSamplePreviewHexCancelled,
  resetSamplePreviewHex,
  selectSamplePreviewHex,
  selectSamplePreviewHexLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../type'
import { PreviewContentLoading } from './PreviewContentLoading'

const HEX_COLUMN_HEADER_STRING =
  '00 01 02 03 04 05 06 07 08 09 0A 0B 0C 0D 0E 0F'

export const hexNumber = (num: number) => {
  const str = num.toString(16)

  return '0'.repeat(8 - str.length) + str
}

export const translateBytes = (data: string): string[] => {
  const bytesList: string[] = []
  const needReplaceRegex = /[^\x20-\x7f]/g
  const newString = data.replace(needReplaceRegex, '·')

  let tempString = ''
  for (let i = 0; i < newString.length; i += 1) {
    tempString += newString[i]

    if (tempString.length % 16 === 0 && tempString.length > 0) {
      bytesList.push(tempString)
      tempString = ''
    }
  }

  return bytesList
}

export const PreviewHex = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const samplePreviewHexLoading = useAppSelector(selectSamplePreviewHexLoading)
  const samplePreviewHex = useAppSelector(selectSamplePreviewHex)

  const handleFetchPreviewHex = (refetch?: boolean) => {
    if (samplePreviewHex.nextPage !== null) {
      dispatch(
        fetchSamplePreviewHex({
          page: refetch ? 0 : samplePreviewHex.nextPage,
          sampleId: searchText,
        })
      )
    }
  }

  useEffect(() => {
    handleFetchPreviewHex(true)

    return () => {
      dispatch(fetchSamplePreviewHexCancelled())
      dispatch(resetSamplePreviewHex())
    }
  }, [searchText])

  const { contentHex, contentBytes, nextPage } = samplePreviewHex

  const hexStringStackList = useMemo(
    () => contentHex && contentHex.split('\n'),
    [contentHex]
  )

  const hexBytes = useMemo(
    () => contentBytes && translateBytes(contentBytes),
    [contentBytes]
  )

  const [isScrollable, ref, node] = useIsScrollable([contentHex])

  const hasMoreHex = nextPage !== null

  useEffect(() => {
    if (node && !isScrollable && hasMoreHex) {
      handleFetchPreviewHex()
    }
  }, [isScrollable, hasMoreHex, node, contentHex])

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const targetElement = event.target as HTMLDivElement
    const isReachBottom =
      targetElement.scrollTop + targetElement.clientHeight >
      targetElement.scrollHeight - 60
    if (isReachBottom) {
      handleFetchPreviewHex()
    }
  }

  if (samplePreviewHexLoading && hexStringStackList.length === 0) {
    return <PreviewContentLoading />
  }

  if (!hexStringStackList) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      onScroll={handleScroll}
      sx={{ p: 4, height: '100%', overflowY: 'auto' }}
    >
      <Stack>
        <Box sx={{ pl: 18.75 }}>
          <Typography variant="reportCodeBlock">
            {HEX_COLUMN_HEADER_STRING}
          </Typography>
        </Box>
        {hexStringStackList.map((hexString, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index + hexString}
            sx={{
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              color: theme.colors.WHITE,
            }}
          >
            <Typography variant="reportCodeBlock">
              {hexNumber(index * 16).toUpperCase()}
            </Typography>
            <Typography variant="reportCodeBlock">{hexString}</Typography>
            <Typography variant="reportCodeBlock">
              {hexBytes[index] || ''}
            </Typography>
          </Box>
        ))}
        {samplePreviewHexLoading && <CenterCircularProgress />}
      </Stack>
    </Box>
  )
}
