import { Box, useTheme } from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NormalLoading } from 'components/Loading/NormalLoading'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { TechniquePreview } from 'components/Technique/TechniquePreview'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchTechniquesData,
  fetchSearchTechniquesDataCancelled,
  resetSearchTechniquesData,
  selectSearchTechniques,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'technique-scrollable-container'

export const CapabilityContent = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const techniques = useAppSelector(selectSearchTechniques)
  const techniquesCurrentCount = techniques.data.length
  const isTechniquesLoading = techniques.isDataLoading
  const hasMoreTechniques = techniques.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchTechniques = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchTechniquesData({
          searchText,
          offset: refetch ? 0 : techniquesCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchTechniques(true)
    return () => {
      dispatch(fetchSearchTechniquesDataCancelled())
      dispatch(resetSearchTechniquesData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreTechniques && techniquesCurrentCount > 0) {
      fetchTechniques()
    }
  }, [inView, hasMoreTechniques, techniquesCurrentCount])

  if (isTechniquesLoading && techniquesCurrentCount === 0) {
    return <NormalLoading />
  }

  if (techniquesCurrentCount === 0) {
    return <NoResultContent />
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ overflowY: 'auto', height: '100%' }}
    >
      {techniques.data.map((technique) => (
        <Box
          key={technique.id}
          sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
        >
          <TechniquePreview
            showHoverStyle
            descriptionOverflowEllipsis
            techniqueName={
              technique.parent?.name
                ? `${technique.parent.name}: ${technique.name}`
                : `${technique.name}`
            }
            showTechniqueName
            showReadMoreButton
            {...technique}
          />
        </Box>
      ))}
      {hasMoreTechniques && <CenterCircularProgress scrollRef={ref} />}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
