import {
  TextField as MuiTextField,
  TextFieldProps,
  useTheme,
} from '@mui/material'

const fieldHeightMap = {
  large: '2rem',
  medium: '1.5rem',
  small: '1rem',
}

const inputPaddingMap = {
  small: '0 0 0 0.5rem',
  medium: '0 0 0 1rem',
  large: '0 0 0 1rem',
}

const labelTopMap = {
  small: '-0.875rem',
  medium: '-0.6875rem',
  large: '-0.625rem',
}

const labelWithValueTopMap = {
  small: '0.25rem',
  medium: '0.1875rem',
  large: '0.125rem',
}

export interface ITextFieldProps
  extends Omit<TextFieldProps, 'size' | 'variant'> {
  size?: 'large' | 'medium' | 'small'
}

export const TextField = ({
  size = 'large',
  sx = [],
  ...props
}: ITextFieldProps) => {
  const theme = useTheme()
  const { value, error, disabled } = props
  const fieldHeight = fieldHeightMap[size]

  const labelColor = () => {
    if (error) {
      return theme.colors.ERROR
    }
    if (disabled) {
      return theme.colors.DISABLE
    }
    return theme.colors.WHITE_60
  }

  const hoverColor = () => {
    if (error) {
      return theme.colors.ERROR
    }
    if (disabled) {
      return theme.colors.DISABLE
    }
    return theme.colors.WHITE
  }

  const typographyMap = {
    small: theme.typography.assistiveText,
    medium: theme.typography.bodySmall,
    large: theme.typography.body,
  }

  return (
    <MuiTextField
      sx={[
        {
          width: '100%',
          '& input': {
            height: fieldHeight,
            p: inputPaddingMap[size],
            '&::placeholder': {
              color: theme.colors.WHITE_60,
              opacity: 1,
            },
          },
          '& .MuiOutlinedInput-root': {
            pr: 2,
            color: theme.colors.WHITE_60,
            borderRadius: 0.5,
            ...typographyMap[size],
            '& fieldset': {
              borderWidth: 1,
              borderColor: error ? theme.colors.ERROR : theme.colors.WHITE_60,
              ...typographyMap[size],
            },
            '&:hover fieldset': {
              borderWidth: 1,
              borderColor: hoverColor,
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1,
              borderColor: error ? theme.colors.ERROR : theme.colors.PRIMARY_60,
            },
            '&.Mui-disabled fieldset': {
              borderWidth: 1,
              borderColor: theme.colors.DISABLE,
            },
          },
          '& .MuiFormLabel-root': {
            color: labelColor,
            top: value !== '' ? labelWithValueTopMap[size] : labelTopMap[size],
            ...typographyMap[size],
            '&.Mui-focused': {
              color: error ? theme.colors.ERROR : theme.colors.PRIMARY_60,
              top: labelWithValueTopMap[size],
            },
          },
          '& .Mui-focused': {
            '&.MuiOutlinedInput-root': {
              color: theme.colors.WHITE,
            },
          },
          '& .MuiFormHelperText-root': {
            color: disabled ? theme.colors.DISABLE : theme.colors.WHITE_60,
            mt: 0.5,
            mx: 4,
            ...theme.typography.assistiveText,
            '&.Mui-error': {
              color: theme.colors.ERROR,
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
}

export const TextArea = ({ sx, ...props }: TextFieldProps) => {
  const { error } = props

  return (
    <MuiTextField
      multiline
      rows={5}
      sx={[
        (theme) => ({
          color: theme.colors.WHITE_60,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: error ? 2 : 1,
              borderColor: error ? theme.colors.ERROR : theme.colors.WHITE_60,
            },
            '&:hover fieldset': {
              borderWidth: 2,
              borderColor: error ? theme.colors.ERROR : theme.colors.WHITE,
            },
            '&.Mui-focused fieldset': {
              borderWidth: 2,
              borderColor: error ? theme.colors.ERROR : theme.colors.PRIMARY_60,
            },
          },
          '& .MuiInputBase-root': {
            px: 4,
            py: 1.5,
            '& .MuiInputBase-input': {
              color: error ? theme.colors.ERROR : theme.colors.WHITE,
            },
          },
          '& .MuiFormLabel-root': {
            ...theme.typography.body,
            color: error ? theme.colors.ERROR : theme.colors.WHITE_60,
            '&.Mui-focused': {
              color: error ? theme.colors.ERROR : theme.colors.PRIMARY_60,
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
}
