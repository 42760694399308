import { Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const Loading = () => (
  <Stack
    sx={{
      py: 6,
      gap: 4,
      width: '40.625rem',
    }}
  >
    <Skeleton type="h1" width="24rem" />
    <Stack sx={{ gap: 2 }}>
      <Skeleton type="body" width="13.5rem" />
      <Skeleton type="body" width="20rem" />
      <Skeleton type="body" width="16.5rem" />
    </Stack>
  </Stack>
)
