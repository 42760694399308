import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_ROUTE,
  API_DOC_V2_PMR_LIST_PMR_ROUTE,
  API_DOC_V2_PMR_ROUTE,
  API_DOC_V2_ROUTE,
} from 'constants/routeParams'
import {
  API_DOC_V2_PMR_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/vulnerability/advisory_lists/2023-Sep-2" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success": true,
  "advisories":[
    {
      "identification": "CVE-2023-29357",
      "report_urls":null,
      "vendor": "Microsoft",
      "product": "SharePoint Server ",
      "product_list": [],
      "cvss": 9.8,
      "cvss_vector": "CVSS:3.1/AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H/E:U/RL:O/RC:C",
      "description": {"title":"Microsoft SharePoint Server Elevation of Privilege Vulnerability","detail":""},
      "threat_level": "HIGH",
      "poc":null,
      "publicly_disclosed": true,
      "updated_at": 1695891144,
      "patch": "Affected Versions:\\r\\n16.0.10399.20005 16.0.10399.20005\\r\\nMore details can be found at: https://msrc.microsoft.com/update-guide/vulnerability/CVE-2023-29357\\r\\n",
      "references": [],
      "malicious_files":null,
      "suspicious_domains":null,
      "suspicious_ips":null,
      "exploited": false
    },...
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Get PMR'

export const APIDocumentVersionTwoPatchManagementReportGetPMR = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_PMR_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_PMR_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Get details of vulnerabilities included in the report.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/vulnerability/advisory_lists/:name" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            name
            <UnorderedList>
              <ListItem>
                get from{' '}
                <TextLink
                  to={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_PMR_ROUTE}/${API_DOC_V2_PMR_LIST_PMR_ROUTE}`}
                  size="large"
                >
                  List PMR
                </TextLink>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>
          JSON object, with following keys:
          <UnorderedList>
            <ListItem>
              success
              <UnorderedList>
                <ListItem>The response status</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              advisories
              <UnorderedList>
                <ListItem>
                  An array of vulnerabilities, each vulnerability contains
                  following keys:
                  <UnorderedList>
                    <ListItem>
                      identification: identification of the vulnerability
                    </ListItem>
                    <ListItem>
                      report_urls: urls for Vulnerability Insights Reports (VIR)
                      related to the vulnerability
                    </ListItem>
                    <ListItem>
                      vendor: the vendor who disclosed the vulnerability
                    </ListItem>
                    <ListItem>
                      product: main affected product / one of the affected
                      product
                    </ListItem>
                    <ListItem>product_list: all affected products</ListItem>
                    <ListItem>cvss: cvss of the vulnerability</ListItem>
                    <ListItem>
                      cvss_vector: cvss vector of the vulnerability
                    </ListItem>
                    <ListItem>
                      description: description of the vulnerability
                      <UnorderedList>
                        <ListItem>title</ListItem>
                        <ListItem>detail</ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      threat_level: threat level of the vulnerability
                    </ListItem>
                    <ListItem>
                      poc: list of Github projects (e.g. exploited code,
                      detection toolkit, etc.) related to the vulnerability
                    </ListItem>
                    <ListItem>
                      publicly_disclosed: to show if the technique details of
                      the vulnerability was disclosed publicly
                    </ListItem>
                    <ListItem>
                      updated_at: the updated time in unix timestamp of the
                      vulnerability
                    </ListItem>
                    <ListItem>
                      patch: external links or description about the patches
                    </ListItem>
                    <ListItem>
                      references: other websites that also mentioned the
                      vulnerability
                    </ListItem>
                    <ListItem>
                      malicious_files: array of sha256 hashes of samples related
                      to the vulnerability
                    </ListItem>
                    <ListItem>
                      suspicious_domains: array of domains related to the
                      vulnerability
                    </ListItem>
                    <ListItem>
                      suspicious_ips: array of ips related to the vulnerability
                    </ListItem>
                    <ListItem>
                      exploited: to show if the vulnerability was exploited
                    </ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
