import { Box, useTheme } from '@mui/material'
import { AdversaryPreview } from 'components/Adversary/AdversaryPreview'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { AdversaryLoading } from 'components/Loading/AdversaryLoading'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchTabAdversaries,
  fetchTabAdversariesCancelled,
  resetTabAdversaryContent,
  selectHasMoreTabAdversaries,
  selectTabAdversaries,
  selectTabAdversaryDataLoading,
} from 'store/slices/tabContent'

const SCROLLABLE_CONTAINER_ID = 'tab-adversaries-scrollable-container'

interface IAdversaryTabContentProps {
  searchText: string
}

export const AdversaryTabContent = ({
  searchText,
}: IAdversaryTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const tabAdversaries = useAppSelector(selectTabAdversaries)
  const isTabAdversaryLoading = useAppSelector(selectTabAdversaryDataLoading)
  const hasMoreTabAdversaries = useAppSelector(selectHasMoreTabAdversaries)

  const [isScrollable, ref, node] = useIsScrollable([tabAdversaries])

  const handleFetchTabAdversaries = (refetch?: boolean) =>
    dispatch(
      fetchTabAdversaries({
        searchText,
        offset: refetch ? 0 : tabAdversaries.length,
      })
    )

  // fetch initial tab adversaries
  useEffect(() => {
    handleFetchTabAdversaries(true)
    return () => {
      dispatch(fetchTabAdversariesCancelled())
      dispatch(resetTabAdversaryContent())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreTabAdversaries) {
      handleFetchTabAdversaries()
    }
  }, [isScrollable, hasMoreTabAdversaries, node, tabAdversaries.length])

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
    >
      {tabAdversaries.length === 0 ? (
        isTabAdversaryLoading ? (
          <AdversaryLoading />
        ) : (
          <NoData />
        )
      ) : (
        <InfiniteScrollWrapper
          dataLength={tabAdversaries.length}
          next={handleFetchTabAdversaries}
          hasMore={hasMoreTabAdversaries}
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
        >
          {tabAdversaries.map((adversary) => (
            <Box
              key={adversary.name}
              sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
            >
              <AdversaryPreview {...adversary} />
            </Box>
          ))}
        </InfiniteScrollWrapper>
      )}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
