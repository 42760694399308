import { useTheme } from '@mui/material'
import { TTlp } from 'store/types/entityTypes/tlpTarget'

export const useTlpColorMap = (): Record<TTlp, string> => {
  const theme = useTheme()
  return {
    amber: theme.colors.TLPAmber,
    green: theme.colors.TLPGreen,
    red: theme.colors.TLPRed,
    white: theme.colors.WHITE,
  }
}
