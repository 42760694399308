import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/364f38b48565814b576f482c1e0eb4c8d58effcd033fd45136ee00640a2b5321" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "risk_level":"high",
  "md5":"9ee6e8f633764c06142c9abeddb9f04c",
  "sha1":"f21fbe42eba84d6300e6f4cf59426d2f10a1ed09",
  "sha256":"364f38b48565814b576f482c1e0eb4c8d58effcd033fd45136ee00640a2b5321",
  "first_seen":1692345822,
  "size":942080,
  "date":1692387350,
  "meta":
    {
      "filename":
        {
          "original_filename":null,
          "pdb_strings":[]
        },
    "document":
      {
        "author":
          {
            "author":"Microsoft Corporation",
            "company":"",
            "creator":"",
            "last_modified_by":""
          },
        "code_page":"Windows Latin 1 (Western European)",
        "created_at":{"seconds":1692192844},
        "updated_at":{"seconds":1692192844}
      },
    "exiftool":
      {
        "output":
          {
            "directory":"/",
            "file_name":"sample_path_in_docker",
            "file_size":"920 kB",
            "file_type":"FPX",
            "mime_type":"image/vnd.fpx",
            "source_file":"/sample_path_in_docker",
            "file_access_date":"2023-12-05 10:45:24+0000",
            "file_modify_date":"2023-12-05 10:45:24+0000",
            "file_permissions":"rw-r--r--",
            "exif_tool_version":"11.88",
            "file_type_extension":"fpx",
            "file_inode_change_date":"2023-12-05 10:45:24+0000"
          },
        "file_timestamp":{"seconds":1692192844}
      },
    "file_hash":
      {
        "tlsh":"T1C21523DBA120E2A5C5A397B780F7CE65476BDD037AB8E272E987F71E1E304851133588",
        "crc32":"F0CB8576",
        "entropy":7.969986438751221
      }
    }
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Sample Meta'

export const APIDocumentVersionTwoSampleMeta = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Meta data of the sample, such as file name, risk level, would be shown
          here.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/:sha256" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>risk_level</ListItem>
          <ListItem>md5</ListItem>
          <ListItem>sha256</ListItem>
          <ListItem>sha1</ListItem>
          <ListItem>first_seen</ListItem>
          <ListItem>size</ListItem>
          <ListItem>date</ListItem>
          <ListItem>
            meta
            <br />
            <Description>JSON object, PERHAPS with following keys:</Description>
            <UnorderedList>
              <ListItem>
                filename
                <UnorderedList>
                  <ListItem>original_filename</ListItem>
                  <ListItem>pdb_strings: Array of pdb filepaths.</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                pe_file
                <UnorderedList>
                  <ListItem>crits</ListItem>
                  <ListItem>endgame</ListItem>
                  <ListItem>entropy</ListItem>
                  <ListItem>imphash</ListItem>
                  <ListItem>pehashng</ListItem>
                  <ListItem>anymaster</ListItem>
                  <ListItem>totalhash</ListItem>
                  <ListItem>anymaster_v1</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                document
                <UnorderedList>
                  <ListItem>
                    author
                    <UnorderedList>
                      <ListItem>author</ListItem>
                      <ListItem>company</ListItem>
                      <ListItem>creator</ListItem>
                      <ListItem>last_modified_by</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>code_page</ListItem>
                  <ListItem>
                    created_at
                    <UnorderedList>
                      <ListItem>seconds</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    updated_at
                    <UnorderedList>
                      <ListItem>seconds</ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                exiftool
                <UnorderedList>
                  <ListItem>
                    output
                    <UnorderedList>
                      <ListItem>pe_type</ListItem>
                      <ListItem>code_size</ListItem>
                      <ListItem>directory</ListItem>
                      <ListItem>file_name</ListItem>
                      <ListItem>file_size</ListItem>
                      <ListItem>file_type</ListItem>
                      <ListItem>mime_type</ListItem>
                      <ListItem>subsystem</ListItem>
                      <ListItem>os_version</ListItem>
                      <ListItem>time_stamp</ListItem>
                      <ListItem>entry_point</ListItem>
                      <ListItem>source_file</ListItem>
                      <ListItem>machine_type</ListItem>
                      <ListItem>image_version</ListItem>
                      <ListItem>linker_version</ListItem>
                      <ListItem>file_access_date</ListItem>
                      <ListItem>file_modify_date</ListItem>
                      <ListItem>file_permissions</ListItem>
                      <ListItem>exif_tool_version</ListItem>
                      <ListItem>subsystem_version</ListItem>
                      <ListItem>file_type_extension</ListItem>
                      <ListItem>initialized_data_size</ListItem>
                      <ListItem>file_inode_change_date</ListItem>
                      <ListItem>uninitialized_data_size</ListItem>
                      <ListItem>image_file_characteristics</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    file_timestamp
                    <UnorderedList>
                      <ListItem>seconds</ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                file_hash
                <UnorderedList>
                  <ListItem>tlsh</ListItem>
                  <ListItem>crc32</ListItem>
                  <ListItem>entropy</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
