import {
  Box,
  CircularProgress,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as UploadIcon } from 'assets/basicIcons/upload.svg'
import { Button } from 'components/Button/Button'
import { Dialog } from 'components/Dialog/Dialog'
import { SampleUploadLabel } from 'components/Label/Label'
import { Select, SelectItem } from 'components/Select/Select'
import { MAX_FILE_SIZE } from 'constants/fileSize'
import { AAP_PER_UPLOAD } from 'constants/price'
import { ACCOUNT_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { formatInTimeZone } from 'date-fns-tz'
import { useOutOfAAPDialog } from 'hooks/useOutOfAAPDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useTlpColorMap } from 'hooks/useTlpColorMap'
import { ChangeEvent, DragEvent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  selectAccountSettings,
} from 'store/slices/account'
import {
  fetchSubmissions,
  resetSubmissions,
  selectUploadSampleInfo,
  uploadSample,
} from 'store/slices/sample'
import { pushAlertSnackbar } from 'store/slices/snackbar'
import { TTlp } from 'store/types/entityTypes/tlpTarget'
import { appendObjectToFormData } from 'utils/formData'

const tlpOptions: TTlp[] = ['white', 'green', 'amber', 'red']

const preventHandler = (e: DragEvent<HTMLInputElement>) => {
  e.preventDefault()
  e.stopPropagation()
}

export const SampleUploader = () => {
  const theme = useTheme()
  const { t } = useTranslation(['sample', 'snackbar', 'component'])
  const dispatch = useAppDispatch()
  const accountSettings = useAppSelector(selectAccountSettings)
  const defaultTlp = accountSettings.tlp || 'amber'
  const uploadingInfo = useAppSelector(selectUploadSampleInfo)
  const isUploading = Boolean(
    uploadingInfo.filename && uploadingInfo.uploadDate
  )
  const { tzinfo } = useAppSelector(selectAccountSettings)
  // if tzinfo = null use browser timezone
  const timeZone = tzinfo || Intl.DateTimeFormat().resolvedOptions().timeZone
  const tlpColorMap = useTlpColorMap()
  const { renderOutAAPDialog, handleOutOfAAPDialogOpen } = useOutOfAAPDialog({
    needGoBack: false,
  })

  const [uploadingFile, setUploadingFile] = useState<File | null>(null)
  const [tlp, setTlp] = useState<TTlp>(defaultTlp)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const [isDragEnter, setDragEnter] = useState<boolean>(false)

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
  }

  const handleUploadSample = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file.size > MAX_FILE_SIZE) {
        dispatch(
          pushAlertSnackbar({
            text: t('upload.uploadSizeFail', { ns: 'snackbar' }),
          })
        )
      } else {
        setUploadingFile(file)
      }
      // https://stackoverflow.com/a/56258902
      // reset the input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const handleDrop = (event: DragEvent<HTMLInputElement>) => {
    preventHandler(event)
    setUploadingFile(event.dataTransfer.files[0])
    setDragEnter(false)
  }

  const turnHighlightOn = (event: DragEvent<HTMLInputElement>) => {
    preventHandler(event)
    setDragEnter(true)
  }

  const turnHighlightOff = (event: DragEvent<HTMLInputElement>) => {
    preventHandler(event)
    setDragEnter(false)
  }

  const handleResetSample = () => {
    setUploadingFile(null)
  }

  const uploadSuccessCallback = () => {
    dispatch(resetSubmissions())
    dispatch(fetchSubmissions({ offset: 0, risk_level: 'all' }))
    handleResetSample()
  }

  const handleSubmitSample = () => {
    if (uploadingFile) {
      const formData = new FormData()
      const sampleShare = {
        sample: {
          sample_share: {
            tlp,
            users: accountSettings.shareTargets
              .filter((list) => list.type === 'user')
              .map((list) => ({
                ofs_id: list.ofsId,
                can_edit: list.canEdit,
              })),
            groups: accountSettings.shareTargets
              .filter((list) => list.type === 'group')
              .map((list) => ({
                ofs_id: list.ofsId,
                can_edit: list.canEdit,
              })),
          },
        },
      }
      formData.append('sample[upload_file]', uploadingFile, uploadingFile.name)
      appendObjectToFormData(formData, sampleShare, '')

      dispatch(
        uploadSample({
          data: formData,
          successCallback: uploadSuccessCallback,
          filename: uploadingFile.name,
          uploadDate: formatInTimeZone(new Date(), timeZone, 'yyyy.MM.dd'),
          outOfAAPCallback: handleOutOfAAPDialogOpen,
        })
      )
      handleConfirmDialogClose()
    }
  }

  const handleChangeTlpClick = () => {
    window.open(
      `/${ACCOUNT_ROUTE}?${SearchParamKey.ACCOUNT_IS_EDIT_TLP_DRAWER_OPEN}=true`
    )
  }

  const handleTLPChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTlp(event.target.value as TTlp)
  }

  useEffect(() => {
    dispatch(fetchAccountSettings())
    return () => {
      dispatch(fetchAccountSettingsCancelled())
    }
  }, [])

  useEffect(() => {
    setTlp(defaultTlp)
  }, [defaultTlp])

  return (
    <>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          gap: 8,
          p: 6,
        }}
      >
        <Typography variant="title" sx={{ color: theme.colors.WHITE }}>
          {t('uploadSection.title', { ns: 'sample' })}
        </Typography>
        {uploadingFile ? (
          <Stack sx={{ gap: 8 }}>
            <SampleUploadLabel
              text={
                uploadingFile.name.length > 100
                  ? `${uploadingFile.name.slice(0, 101)}...`
                  : uploadingFile.name
              }
              handleRemove={handleResetSample}
            />
            <Button
              variant="contained"
              size="large"
              onClick={handleConfirmDialogOpen}
              endIcon={!isUploading && <UploadIcon />}
              disabled={isUploading}
            >
              {isUploading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                t('uploadSection.uploadFile', { ns: 'sample' })
              )}
            </Button>
          </Stack>
        ) : (
          <Box component="label">
            <Stack
              onDrop={handleDrop}
              onDragOver={turnHighlightOn}
              onDragLeave={turnHighlightOff}
              sx={{
                height: '6.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                border: `1px dotted ${theme.colors.WHITE_60}`,
                cursor: 'pointer',
                bgcolor: isDragEnter ? theme.colors.PRIMARY_20 : 'inherit',
                p: 4,
                gap: 1,
                borderRadius: 0.5,
                '&:hover': {
                  bgcolor: theme.colors.PRIMARY_20,
                },
                '&:active': {
                  bgcolor: theme.colors.PRIMARY_40,
                },
              }}
            >
              <SvgIcon
                sx={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
                component={UploadIcon}
                inheritViewBox
              />
              <Stack>
                <Typography color={theme.colors.WHITE_60} variant="body">
                  {t('uploadSection.chooseFile', { ns: 'sample' })}
                </Typography>
              </Stack>
            </Stack>
            <input
              hidden
              multiple={false}
              accept="**/*"
              type="file"
              onChange={handleUploadSample}
            />
          </Box>
        )}
        <Typography
          variant="assistiveText"
          sx={{ color: theme.colors.WHITE, textAlign: 'center' }}
        >
          <Trans t={t} i18nKey="uploadSection.description">
            Please note that you can only upload a file at a time and the sample
            you upload should be smaller than 100 MB.
            <br />
            The most unrestricted TLP value among all sources of this sample
            will be applied as the final sharing setting in the system.
          </Trans>
        </Typography>
        {uploadingFile && (
          <Stack sx={{ gap: 8 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('uploadSection.tlp', { ns: 'sample' })}
              </Typography>
              <Box sx={{ width: '5.75rem' }}>
                <Select
                  sx={{
                    '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                      color: tlpColorMap[tlp],
                    },
                  }}
                  value={tlp}
                  onChange={handleTLPChange}
                >
                  {tlpOptions.map((option) => (
                    <SelectItem
                      sx={{
                        '.MuiTypography-root': {
                          color: tlpColorMap[option],
                        },
                      }}
                      key={option}
                      value={option}
                    >
                      {option}
                    </SelectItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Typography
              variant="textLink"
              sx={{ color: theme.colors.TEXT_LINK_NORMAL, cursor: 'pointer' }}
              onClick={handleChangeTlpClick}
            >
              {t('uploadSection.changeDefaultTlp', { ns: 'sample' })}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Dialog
        open={confirmDialogOpen}
        handleDialogClose={handleConfirmDialogClose}
        title={t('uploadDialog.title', { ns: 'sample' })}
        description={t('uploadDialog.description', {
          count: AAP_PER_UPLOAD,
          ns: 'sample',
        })}
        confirmButtonText={t('yesCta', { ns: 'component' })}
        confirmButtonLoading={isUploading}
        confirmButtonDisabled={isUploading}
        handleConfirmButtonClick={handleSubmitSample}
      />
      {renderOutAAPDialog}
    </>
  )
}
