import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as KeyboardArrowDownIcon } from 'assets/basicIcons/angleDown.svg'
import { ReactComponent as KeyboardArrowUpIcon } from 'assets/basicIcons/angleUp.svg'
import { HighlightLabel } from 'components/Label/Label'
import { CAPABILITY_ROUTE, TECHNIQUE_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ITechnique } from 'store/types/entityTypes/capability'

interface ITechniqueAccordion extends ITechnique {
  children?: NonNullable<ReactNode>
  index: number
  count: number
  lastClickId: string
  setLastClickId: (arg0: string) => void
  showSubTechniques: string[]
  setShowSubTechniques: Dispatch<SetStateAction<string[]>>
  tacticId: string
}
export const TechniqueAccordion = ({
  children,
  index,
  count,
  lastClickId,
  setLastClickId,
  id,
  name,
  subTechniques,
  showSubTechniques,
  setShowSubTechniques,
  tacticId,
}: ITechniqueAccordion) => {
  const { t } = useTranslation(['capability'])
  const theme = useTheme()
  const expanded = showSubTechniques.includes(`${tacticId}-${id}`)

  const handleExpanded = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setLastClickId(`${tacticId}-${id}`)
    if (showSubTechniques.includes(`${tacticId}-${id}`)) {
      setShowSubTechniques(
        showSubTechniques.filter(
          (subTechnique) => subTechnique !== `${tacticId}-${id}`
        )
      )
    } else {
      setShowSubTechniques([...showSubTechniques, `${tacticId}-${id}`])
    }
  }

  const borderRadius = () => {
    if (index + 1 === count) {
      return '0 0 2px 2px'
    }
    if (index === 0) {
      return '2px 2px 0 0'
    }
    return 0
  }

  const borderTop = () => {
    if (lastClickId === `${tacticId}-${id}` && expanded) {
      return `1px solid ${theme.colors.PRIMARY}`
    }
    if (index === 0) {
      return `1px solid ${theme.colors.WHITE_20}`
    }
    return 'none'
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 4,
          bgcolor: theme.colors.BLACK_85,
          borderTop: borderTop(),
          borderLeft: `1px solid ${
            lastClickId === `${tacticId}-${id}` && expanded
              ? theme.colors.PRIMARY
              : theme.colors.WHITE_20
          }`,
          borderRight: `1px solid ${
            lastClickId === `${tacticId}-${id}` && expanded
              ? theme.colors.PRIMARY
              : theme.colors.WHITE_20
          }`,
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: borderRadius(),
          pl: 2,
          pr: 4,
          py: 2,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.colors.PRIMARY_20,
          },
          '&:active': {
            bgcolor: theme.colors.PRIMARY_40,
          },
        }}
        to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${id}`}
        component={Link}
      >
        <Stack sx={{ gap: 1 }}>
          <HighlightLabel text={id} color={theme.colors.TEXT_LINK_NORMAL} />
          <Typography
            variant="titleSmall"
            sx={{
              color: theme.colors.TEXT_LINK_NORMAL,
              wordBreak: 'break-word',
            }}
          >
            {name}
          </Typography>
          {subTechniques.length > 0 && (
            <Typography
              variant="assistiveText"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('capability.subTechniqueCount', {
                ns: 'capability',
                count: subTechniques.length,
              })}
            </Typography>
          )}
        </Stack>
        {subTechniques.length > 0 &&
          (expanded ? (
            <Box onClick={handleExpanded}>
              <SvgIcon
                sx={{ width: '1.5rem', height: '1.5rem' }}
                component={KeyboardArrowUpIcon}
                inheritViewBox
              />
            </Box>
          ) : (
            <Box onClick={handleExpanded}>
              <SvgIcon
                sx={{ width: '1.5rem', height: '1.5rem' }}
                component={KeyboardArrowDownIcon}
                inheritViewBox
              />
            </Box>
          ))}
      </Box>

      {expanded && (
        <Box
          sx={{
            borderRadius: borderRadius(),
            borderBottom:
              lastClickId === `${tacticId}-${id}`
                ? `1px solid ${theme.colors.PRIMARY}`
                : 'none',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}
