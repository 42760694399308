import {
  AccordionProps,
  Box,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion/Accordion'
import { BlockSubTitle, BlockTitle } from 'components/BlockTitle/BlockTitle'
import { ReactNode } from 'react'

type TOverflowStyle = 'auto' | 'hidden'

interface IPageSectionBaseProps {
  title?: string
  children: NonNullable<ReactNode>
}

interface IPageSectionProps extends IPageSectionBaseProps {
  titleInfo?: NonNullable<ReactNode>
  titleAction?: NonNullable<ReactNode>
  sx?: SxProps
  childrenOverflowStyle?: TOverflowStyle
}

/**
 * @description 在站內大部分內容區塊會用的容器，分為上方的標題以及下方的內容
 * @param titleAction 出現在上方標題右方的元件，通常是按鈕
 */
export const PageSection = ({
  title,
  titleInfo,
  titleAction,
  children,
  sx = [],
  childrenOverflowStyle = 'auto',
}: IPageSectionProps) => {
  const theme = useTheme()

  return (
    <Stack
      sx={[
        {
          [theme.breakpoints.down('xl')]: {
            width: '100%',
          },
          height: '100%',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title && (
        <BlockTitle
          title={title}
          titleInfo={titleInfo}
          titleAction={titleAction}
        />
      )}
      <Box
        sx={{ flexGrow: 1, overflow: childrenOverflowStyle, height: '100%' }}
      >
        {children}
      </Box>
    </Stack>
  )
}

interface IPageAccordionSection
  extends IPageSectionBaseProps,
    Omit<AccordionProps, 'children'> {}

export const PageAccordionSection = ({
  title,
  children,
  ...accordionProps
}: IPageAccordionSection) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Accordion {...accordionProps}>
        <AccordionSummary>
          <Typography
            variant="title"
            sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  )
}

export const BlockSection = ({
  title,
  titleInfo,
  titleAction,
  children,
  sx = [],
  childrenOverflowStyle = 'auto',
}: IPageSectionProps) => {
  const theme = useTheme()

  return (
    <Stack
      sx={[
        {
          [theme.breakpoints.down('xl')]: {
            width: '100%',
          },
          height: '100%',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title && (
        <BlockSubTitle
          title={title}
          titleInfo={titleInfo}
          titleAction={titleAction}
        />
      )}
      <Box sx={{ flexGrow: 1, overflow: childrenOverflowStyle }}>
        {children}
      </Box>
    </Stack>
  )
}
