import {
  APT_ROUTE,
  CTR_REPORT_ROUTE,
  CYBER_AFFAIRS_CATEGORY_ROUTE,
  CYBER_AFFAIRS_TYPE_ROUTE,
  FLASH_REPORT_ROUTE,
  MONTHLY_REPORT_ROUTE,
  PM_REPORT_ROUTE,
  VIR_REPORT_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'

import { TCommentType } from './comment'

export type TAPTReportType = 'flashReport' | 'monthlyReport' | 'CTRReport'

export type TVulnerabilityReportType = 'VIRReport' | 'PMReport'

export type TReportType =
  | TAPTReportType
  | 'cyberAffairs'
  | TVulnerabilityReportType
  | 'customReport'

export interface IReport {
  id: string
  title: string
  alias: string
  type: TReportType
  digest: string
  date: number
  coverUrl: string
  adversaries: string[]
  malwares: string[]
  targetedCountries: string[]
  targetedIndustries: string[]
  permission: boolean
  commentableType: TCommentType
  purchasedInformation?: {
    priceTagId: number
    credit: number
  }
  stixUrl: string | null
  pdfUrl: string | null
  capability: string[]
}

export type TReportCategory = 'APT' | 'cyberAffairs' | 'vulnerability'
export type TReportCategoryRoute = 'apt' | 'cyber-affairs' | 'vulnerability'
export type TAPTReportTypeRoute =
  | 'flash-report'
  | 'monthly-report'
  | 'ctr-report'
export type TVulnerabilityReportTypeRoute = 'vir-report' | 'pm-report'
export type TReportTypeRoute =
  | TAPTReportTypeRoute
  | 'cyber-affairs'
  | TVulnerabilityReportTypeRoute

export const reportCategoryRouteMap: Record<
  TReportCategory,
  TReportCategoryRoute
> = {
  APT: APT_ROUTE,
  cyberAffairs: CYBER_AFFAIRS_CATEGORY_ROUTE,
  vulnerability: VULNERABILITY_ROUTE,
}

export const reportRouteCategoryMap: Record<
  TReportCategoryRoute | string,
  TReportCategory
> = {
  'apt': 'APT',
  'cyber-affairs': 'cyberAffairs',
  'vulnerability': 'vulnerability',
}

export const reportTypeRouteMap: Record<
  Exclude<TReportType, 'customReport'>,
  TReportTypeRoute
> = {
  flashReport: FLASH_REPORT_ROUTE,
  monthlyReport: MONTHLY_REPORT_ROUTE,
  CTRReport: CTR_REPORT_ROUTE,
  cyberAffairs: CYBER_AFFAIRS_TYPE_ROUTE,
  VIRReport: VIR_REPORT_ROUTE,
  PMReport: PM_REPORT_ROUTE,
}

export const reportTypePageTitleMap: Record<TReportType, string> = {
  flashReport: 'Flash Report',
  monthlyReport: 'Monthly Report',
  CTRReport: 'Campaign Tracking Report',
  cyberAffairs: 'Cyber Affairs Report',
  VIRReport: 'Vulnerability Insights Report',
  PMReport: 'Patch Management Report',
  customReport: 'Custom Report',
}

export const reportRouteTypeMap: Record<
  TReportTypeRoute | string,
  Exclude<TReportType, 'customReport'>
> = {
  'flash-report': 'flashReport',
  'monthly-report': 'monthlyReport',
  'ctr-report': 'CTRReport',
  'cyber-affairs': 'cyberAffairs',
  'vir-report': 'VIRReport',
  'pm-report': 'PMReport',
}

export type APIReportType =
  | 'flash'
  | 'bi_weekly'
  | 'monthly'
  | 'advanced'
  | 'vulnerability_insights'
  | 'on_demand'

export interface IReportAPIData {
  main_value: string
  title: string
  alias: string
  type: APIReportType
  digest: string
  date: number
  cover_url: string
  adversaries: string[]
  malwares: string[]
  targeted_countries: string[]
  targeted_industries: string[]
  permission: boolean
  commentable_type: TCommentType
  purchased_information?: {
    price_tag_id: number
    credit: number
  }
  stix_url: string | null
  pdf_url: string | null
  capability: string[]
}

interface IPoc {
  url: string
  updated: number | null
  description: string
}

export type TThreatLevel = 'HIGH' | 'MEDIUM' | 'LOW'

export interface IPMReportsAPIData {
  name: string
  release_date: number
}

export interface IPMReports {
  name: string
  releaseDate: number
}

export interface IPMReportDetailAPIData {
  main_value: string
  is_new: boolean
  vendor: string
  report_aliases: string[] | null
  product: string
  product_list: string[]
  cvss: number
  cvss_vector: string
  description: {
    title: string | null
    detail: string | null
  }
  source: string
  threat_level: TThreatLevel
  publicly_disclosed: boolean | null
  exploited: boolean | null
  poc: IPoc[] | null
  updated_at: number
  patch: string
  references: string[] | null
  malicious_files: string[] | null
  suspicious_domains: string[] | null
  suspicious_ips: string[] | null
}

export interface IPMReportDetail {
  id: string
  isNew: boolean
  vendor: string
  reportAliases: string[] | null
  product: string | null
  productList: string[]
  cvss: number | null
  cvssVector: string
  description: {
    title: string | null
    detail: string | null
  }
  source: string
  threatLevel: TThreatLevel
  publiclyDisclosed: boolean | null
  exploited: boolean | null
  poc: IPoc[] | null
  updatedAt: number
  patch: string
  references: string[] | null
  maliciousFiles: string[] | null
  suspiciousDomains: string[] | null
  suspiciousIps: string[] | null
}

export interface IFetchReportsPayload {
  dateFrom?: number | null
  dateTo?: number | null
  offset?: number
}

export interface IFetchReportIocContentPayload {
  alias: string
  successCallback?: (response: string) => void
}

export interface IFetchReportPdfContentPayload {
  alias: string
  successCallback?: ({
    response,
    filename,
  }: {
    response: ArrayBuffer
    filename: string
  }) => void
}

export interface IFetchReportHtmlContentPayload {
  alias: string
  noAccessCallback: () => void
}

export interface IReportReadableTypesAPIData {
  flash: boolean
  bi_weekly: boolean
  monthly: boolean
  advanced: boolean
  vulnerability_insights: boolean
  advisory: boolean
}

export type TSearchReportType = Exclude<TReportType, 'PMReport'>

export const mapAPIReportTypesFromReportTypes: Record<
  TSearchReportType,
  APIReportType
> = {
  'flashReport': 'flash',
  'cyberAffairs': 'bi_weekly',
  'monthlyReport': 'monthly',
  'CTRReport': 'advanced',
  'VIRReport': 'vulnerability_insights',
  'customReport': 'on_demand',
}

const mapReportTypesFromAPI: Record<APIReportType, TSearchReportType> = {
  'flash': 'flashReport',
  'bi_weekly': 'cyberAffairs',
  'monthly': 'monthlyReport',
  'advanced': 'CTRReport',
  'vulnerability_insights': 'VIRReport',
  'on_demand': 'customReport',
}

export const mapAPIReportsToState = (
  fromAPIReports: IReportAPIData[]
): IReport[] =>
  fromAPIReports.map((fromAPIReport) => {
    const formattedReport: IReport = {
      id: fromAPIReport.main_value,
      title: fromAPIReport.title,
      digest: fromAPIReport.digest,
      alias: fromAPIReport.alias,
      date: fromAPIReport.date,
      adversaries: fromAPIReport.adversaries,
      permission: fromAPIReport.permission,
      type: mapReportTypesFromAPI[fromAPIReport.type],
      coverUrl: fromAPIReport.cover_url,
      malwares: fromAPIReport.malwares,
      targetedCountries: fromAPIReport.targeted_countries,
      targetedIndustries: fromAPIReport.targeted_industries,
      commentableType: fromAPIReport.commentable_type,
      stixUrl: fromAPIReport.stix_url,
      pdfUrl: fromAPIReport.pdf_url,
      capability: fromAPIReport.capability,
    }

    if (fromAPIReport.purchased_information) {
      formattedReport.purchasedInformation = {
        priceTagId: fromAPIReport.purchased_information.price_tag_id,
        credit: fromAPIReport.purchased_information.credit,
      }
    }

    return formattedReport
  })

export const mapAPIReportInfoToState = (
  fromAPIReportInfo: IReportAPIData
): IReport => {
  const formattedReport: IReport = {
    id: fromAPIReportInfo.main_value,
    title: fromAPIReportInfo.title,
    digest: fromAPIReportInfo.digest,
    alias: fromAPIReportInfo.alias,
    date: fromAPIReportInfo.date,
    adversaries: fromAPIReportInfo.adversaries,
    permission: fromAPIReportInfo.permission,
    type: mapReportTypesFromAPI[fromAPIReportInfo.type],
    coverUrl: fromAPIReportInfo.cover_url,
    malwares: fromAPIReportInfo.malwares,
    targetedCountries: fromAPIReportInfo.targeted_countries,
    targetedIndustries: fromAPIReportInfo.targeted_industries,
    commentableType: fromAPIReportInfo.commentable_type,
    stixUrl: fromAPIReportInfo.stix_url,
    pdfUrl: fromAPIReportInfo.pdf_url,
    capability: fromAPIReportInfo.capability,
  }

  if (fromAPIReportInfo.purchased_information) {
    formattedReport.purchasedInformation = {
      priceTagId: fromAPIReportInfo.purchased_information.price_tag_id,
      credit: fromAPIReportInfo.purchased_information.credit,
    }
  }

  return formattedReport
}

export const mapAPIPMReportsToState = (
  fromAPIPMReports: IPMReportsAPIData[]
): IPMReports[] =>
  fromAPIPMReports.map((fromAPIPMReport) => ({
    name: fromAPIPMReport.name,
    releaseDate: fromAPIPMReport.release_date,
  }))

export const mapAPIPMReportDetailToState = (
  fromAPIPMReportDetails: IPMReportDetailAPIData[]
): IPMReportDetail[] =>
  fromAPIPMReportDetails.map((fromAPIPMReportDetail) => ({
    id: fromAPIPMReportDetail.main_value,
    isNew: fromAPIPMReportDetail.is_new,
    vendor: fromAPIPMReportDetail.vendor,
    reportAliases: fromAPIPMReportDetail.report_aliases,
    product: fromAPIPMReportDetail.product,
    productList: fromAPIPMReportDetail.product_list,
    cvss: fromAPIPMReportDetail.cvss,
    cvssVector: fromAPIPMReportDetail.cvss_vector,
    description: {
      title: fromAPIPMReportDetail.description.title,
      detail: fromAPIPMReportDetail.description.detail,
    },
    source: fromAPIPMReportDetail.source,
    threatLevel: fromAPIPMReportDetail.threat_level,
    publiclyDisclosed: fromAPIPMReportDetail.publicly_disclosed,
    exploited: fromAPIPMReportDetail.exploited,
    poc: fromAPIPMReportDetail.poc,
    updatedAt: fromAPIPMReportDetail.updated_at,
    patch: fromAPIPMReportDetail.patch,
    references: fromAPIPMReportDetail.references,
    maliciousFiles: fromAPIPMReportDetail.malicious_files,
    suspiciousDomains: fromAPIPMReportDetail.suspicious_domains,
    suspiciousIps: fromAPIPMReportDetail.suspicious_ips,
  }))

export const mapAPIReportReadableTypesToState = (
  fromAPIReportReadableTypes: IReportReadableTypesAPIData
): Record<Exclude<TReportType, 'customReport'>, boolean> => ({
  flashReport: fromAPIReportReadableTypes.flash,
  cyberAffairs: fromAPIReportReadableTypes.bi_weekly,
  monthlyReport: fromAPIReportReadableTypes.monthly,
  CTRReport: fromAPIReportReadableTypes.advanced,
  VIRReport: fromAPIReportReadableTypes.vulnerability_insights,
  PMReport: fromAPIReportReadableTypes.advisory,
})
