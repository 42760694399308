import { CRASH_ROUTE } from 'constants/routeParams'
import React, { ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: NonNullable<ReactNode>
  debugMode: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { hasError } = this.state
    const { debugMode } = this.props
    if (hasError && !debugMode) {
      window.location.replace(`/${CRASH_ROUTE}`)
    }

    const { children } = this.props

    return children
  }
}

export default ErrorBoundary
