import {
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Flag } from 'components/Flag/Flag'
import { ADVERSARIES_ROUTE, ADVERSARY_INFO_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { MouseEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { IAdversary } from 'store/types/entityTypes/adversary'

import { ADVERSARY_ITEM_WIDTH } from '../config'

export const AdversaryBlock = ({
  image,
  name,
  id,
  originCountries,
  overview,
}: IAdversary) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const imageUrl = useImageUrl({ fetchImageUrl: image })

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        to={`/${ADVERSARIES_ROUTE}/${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_ID}=${id}`}
        component={Link}
      >
        {imageUrl && (
          <Image
            width={ADVERSARY_ITEM_WIDTH}
            fit="contain"
            src={imageUrl}
            alt={name}
          />
        )}
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack
          sx={{
            px: 4,
            py: 5,
            background: `linear-gradient(0deg, ${theme.colors.ADVERSARY_BG_COLOR}, ${theme.colors.ADVERSARY_BG_COLOR}), url("${imageUrl}");`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: 0.5,
            width: '28.5rem',
            color: theme.colors.WHITE,
          }}
        >
          <Box sx={{ px: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
            {originCountries[0] && (
              <Flag country={originCountries[0]} width={24} />
            )}
            <Typography variant="title">{name}</Typography>
          </Box>
          <Divider sx={{ my: 2, borderColor: theme.colors.WHITE_20 }} />
          <Box sx={{ px: 4 }}>
            <Typography variant="bodySmall">{overview}</Typography>
          </Box>
        </Stack>
      </Popover>
    </>
  )
}
