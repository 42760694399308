import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import ThreatVisionBanner from 'assets/threat-vision-banner.png'
import {
  PAGE_TITLE_LOGIN,
  PAGE_TITLE_RESET_PASSWORD,
} from 'constants/pageTitle'
import { useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { selectAccessToken } from 'store/slices/auth'
import { clearPathBeforeLogout, getPathBeforeLogout } from 'utils/auth'

import { LoginStatus, STATUS_KEY } from './config'
import { LinkSendNotice } from './Forms/LinkSendNotice'
import { LoginForm } from './Forms/LoginForm'
import { RequestForm } from './Forms/RequestForm'
import { ResetForm } from './Forms/ResetForm'
import LoginBackground from './login-background.jpg'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pathBeforeLogout = getPathBeforeLogout()
  const currentStatus = searchParams.get(STATUS_KEY) || LoginStatus.ANONYMOUS

  const accessToken = useAppSelector(selectAccessToken)

  // for navigate to landing page after login success
  useEffect(() => {
    if (accessToken) {
      // 避免 pathBeforeLogout 為 /login，未來可移除
      if (!pathBeforeLogout || pathBeforeLogout === '/login') {
        navigate('/')
      } else {
        navigate(pathBeforeLogout)
      }
      clearPathBeforeLogout()
    }
  }, [accessToken])

  const pageTitle =
    currentStatus === LoginStatus.ANONYMOUS
      ? PAGE_TITLE_LOGIN
      : PAGE_TITLE_RESET_PASSWORD

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid
        container
        wrap="nowrap"
        sx={{ overflow: 'auto' }}
        data-testid="login-page-container"
      >
        <Grid sm={12} md={7.2} sx={{ minWidth: '28.75rem' }}>
          <Box
            sx={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${LoginBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2.5rem',
              color: (theme) => theme.colors.WHITE,
            }}
          >
            <Box sx={{ width: '25rem' }}>
              <Image
                src={ThreatVisionBanner}
                showLoading
                width="21rem"
                height="5rem"
              />
            </Box>
            <Box sx={{ width: '25rem' }}>
              <Box
                sx={{
                  pl: 5,
                  borderBottom: (theme) => `1px solid ${theme.colors.WHITE_20}`,
                }}
              >
                <Typography variant="title">
                  A Portal to See Through the Chaos
                </Typography>
              </Box>
              <Box
                sx={{
                  pl: 5,
                }}
              >
                <Typography variant="bodySmall">
                  Prepare, Prevent, and Defeat your enemies by knowing their
                  playbook
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid sm={12} md={4.8} sx={{ minWidth: '28.75rem' }}>
          <Box
            sx={{
              bgcolor: (theme) => theme.colors.BLACK_95,
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {currentStatus === LoginStatus.ANONYMOUS && <LoginForm />}
            {currentStatus === LoginStatus.RESET_PASSWORD_REQUEST && (
              <RequestForm />
            )}
            {currentStatus === LoginStatus.RESET_PASSWORD_LINK_SEND && (
              <LinkSendNotice />
            )}
            {currentStatus === LoginStatus.RESET_PASSWORD_RESETABLE && (
              <ResetForm />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
