import { Box, Typography, useTheme } from '@mui/material'
import Error500Image from 'assets/errorImages/error-500.svg'
import { TextLink } from 'components/Link/Link'
import { PAGE_TITLE_ERROR_500 } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { openMail } from 'utils/mail'

import { ErrorPageLayout } from './ErrorPageLayout'

const ErrorContent = () => {
  const theme = useTheme()
  const { t } = useTranslation(['error'])

  return (
    <Box sx={{ mt: 4, width: '25rem', textAlign: 'center' }}>
      <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
        <Trans t={t} i18nKey="500.description">
          Something went wrong at our end. We apologize and are fixing the
          problem. Please try again at a later stage. If you need immediate help
          from our customer service team, please contact
          <TextLink size="medium" onClick={openMail} to="">
            support@teamt5.org
          </TextLink>
          .Thanks for your patience!
        </Trans>
      </Typography>
    </Box>
  )
}

export const ErrorCrashPage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['error'])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_ERROR_500}</title>
      </Helmet>
      <ErrorPageLayout
        errorImageUrl={Error500Image}
        errorTitle={
          <Typography variant="reportH2" sx={{ color: theme.colors.WHITE }}>
            {t('500.title', { ns: 'error' })}
          </Typography>
        }
        errorContent={<ErrorContent />}
      />
    </>
  )
}
