import { TableBody, TableCell, TableRow, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const TableRowLoading = () => {
  const theme = useTheme()

  return (
    <TableRow>
      <TableCell
        sx={{ p: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Skeleton type="body" width="7.5rem" />
      </TableCell>
      <TableCell
        sx={{ p: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Skeleton type="body" width="4rem" />
      </TableCell>
      <TableCell
        sx={{ p: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Skeleton type="body" width="7.5rem" />
      </TableCell>
      <TableCell
        sx={{ p: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Skeleton type="body" width="4rem" />
      </TableCell>
      <TableCell
        sx={{ p: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Skeleton type="body" width="7.5rem" />
      </TableCell>
    </TableRow>
  )
}

export const KeywordClTableLoading = () => (
  <TableBody>{generateSkeletons(11, <TableRowLoading />)}</TableBody>
)
