import {
  Box,
  SvgIcon,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as SearchIcon } from 'assets/basicIcons/search.svg'
import { ReactComponent as AscSortedIcon } from 'assets/tableIcons/asc-sort.svg'
import { ReactComponent as DescSortedIcon } from 'assets/tableIcons/desc-sort.svg'
import { ReactComponent as UnSortedIcon } from 'assets/tableIcons/unsort.svg'
import { TextField } from 'components/TextField/TextField'
import { useMalwareTableHeadCells } from 'hooks/useMalwareTableHeadCells'
import { FC, SVGProps,useState } from 'react'

import type {
  TFilterTextMap,
  TMalwareFilterKeys,
  TMalwareOrderKeys,
  TOrder,
} from './MalwareTable'

type TFilterActiveMap = Record<TMalwareFilterKeys, boolean>

const initialFilterActiveMap: TFilterActiveMap = {
  name: false,
  aliases: false,
  type: false,
  attribution: false,
}

interface IMalwareTableHeaderProps {
  order: TOrder
  orderBy: TMalwareOrderKeys
  handleOrderChange: (newOrderBy: TMalwareOrderKeys) => void
  filterTextMap: TFilterTextMap
  handleFilterChange: (
    newFilterBy: TMalwareFilterKeys
  ) => (newFilterText: string) => void
}

export const MalwareTableHeader = ({
  order,
  orderBy,
  handleOrderChange,
  filterTextMap,
  handleFilterChange,
}: IMalwareTableHeaderProps) => {
  const theme = useTheme()
  const { headCells, widthRatioSummary } = useMalwareTableHeadCells()

  // 判斷哪些欄位的 filter 有被啟用，決定是否出現 input element
  const [filterActiveMap, setFilterActiveMap] = useState<TFilterActiveMap>(
    initialFilterActiveMap
  )
  const handleFilterActiveChange = ({
    column,
    active,
  }: {
    column: TMalwareFilterKeys
    active: boolean
  }) => {
    const newFilterActiveMap = {
      ...filterActiveMap,
      [column]: active,
    }
    setFilterActiveMap(newFilterActiveMap)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          const cellWidthPercentage = headCell.widthRatio / widthRatioSummary

          const isSorting = orderBy === headCell.id

          let SortIcon: FC<SVGProps<SVGSVGElement>> = UnSortedIcon
          if (isSorting) {
            SortIcon = order === 'asc' ? AscSortedIcon : DescSortedIcon
          }

          const isFilterActive = Boolean(
            (headCell.id && filterTextMap[headCell.id as TMalwareFilterKeys]) ||
              filterActiveMap[headCell.id as TMalwareFilterKeys]
          )

          const handleFilterInputChange = handleFilterChange(
            headCell.id as TMalwareFilterKeys
          )
          const handleFilterInputOnBlur = () => {
            if (filterTextMap[headCell.id as TMalwareFilterKeys] === '') {
              handleFilterActiveChange({
                column: headCell.id as TMalwareFilterKeys,
                active: false,
              })
            }
          }

          return (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={isSorting ? order : false}
              sx={{
                bgcolor: theme.colors.BLACK_85,
                width: `${cellWidthPercentage * 100}%`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: theme.colors.WHITE,
                  width: '100%',
                }}
              >
                {isFilterActive ? (
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      label={headCell.label}
                      autoFocus
                      size="medium"
                      value={filterTextMap[headCell.id as TMalwareFilterKeys]}
                      onChange={(event) =>
                        handleFilterInputChange(event.target.value)
                      }
                      onBlur={handleFilterInputOnBlur}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="title">{headCell.label}</Typography>
                    {headCell.enableFilter && (
                      <SvgIcon
                        sx={{
                          width: '1.5rem',
                          height: '1.5rem',
                          cursor: 'pointer',
                        }}
                        component={SearchIcon}
                        inheritViewBox
                        onClick={() =>
                          handleFilterActiveChange({
                            column: headCell.id as TMalwareFilterKeys,
                            active: true,
                          })
                        }
                      />
                    )}
                  </Box>
                )}
                <SvgIcon
                  sx={{ cursor: 'pointer' }}
                  component={SortIcon}
                  onClick={() => handleOrderChange(headCell.id)}
                />
              </Box>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
