import {
  Box,
  Divider,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from '@mui/material'
import TargetScopeImage from 'assets/documents/CTR_Introduction_Report_Target_Scope.png'
import { Description } from 'components/Documents/Description'
import { ExampleReportBlock } from 'components/Documents/ExampleReportBlock'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H3Title } from 'components/Documents/H3Title'
import { H4Title } from 'components/Documents/H4Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import {
  ListItem,
  NumberListItem,
  OrderedList,
  UnorderedList,
} from 'components/List/List'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import { DocumentTimestamp, ScrollableContainerId } from 'constants/document'
import { getPageTitleReportIntroductionByTitle } from 'constants/pageTitle'
import {
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

const MGDriveHeadCells = ['SHA256', 'Filename', 'Timestamp', 'Description']

const MGDriveBodyRows = [
  {
    sha256: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=0c86ef5095c8528586c2f23a9b31c5a88b5904185606d1bb8536aad663402cb7`}
      >
        0c86ef5095c8528586c2f23a9b31c5a88b5904185606d1bb8536aad663402cb7
      </TextLink>
    ),
    filename: 'MGD.dll',
    timestamp: `2020-03-19\n07:35:26`,
    description: 'MGDrive payload spotted in Taiwan',
  },
]

export const CTRIntroductionPage = () => {
  const { t } = useTranslation(['reportIntroduction'])
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  const tocList = [
    {
      id: 'introduction',
      text: t('ctr.introduction.title', { ns: 'reportIntroduction' }),
    },
    {
      id: 'ctr-in-detail',
      text: t('ctr.ctrInDetail.title', { ns: 'reportIntroduction' }),
    },
    {
      id: 'example-report-2023-q1-campaign-tracking-report-camofei',
      text: t('ctr.exampleReport', {
        ns: 'reportIntroduction',
        reportName: '2023 Q1 Campaign Tracking Report: CamoFei',
      }),
    },
  ]

  return (
    <>
      <Helmet>
        <title>
          {getPageTitleReportIntroductionByTitle(
            t('ctr.title', {
              ns: 'reportIntroduction',
            })
          )}
        </title>
      </Helmet>
      <Box sx={{ display: 'flex' }}>
        <Stack
          id={ScrollableContainerId.TOP_ID}
          sx={{
            px: 10,
            py: 4,
            gap: 2,
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <H2ColorTitle title={t('ctr.title', { ns: 'reportIntroduction' })} />
          <DateLabel
            text={getDateStringByTimestampsInSeconds(
              DocumentTimestamp.REPORT_INTRODUCTION_TIMESTAMP
            )}
          />
          <TocLink
            text={t('ctr.introduction.title', { ns: 'reportIntroduction' })}
            hash="introduction"
          />
          <Description>
            <Trans t={t} i18nKey="ctr.introduction.description">
              <Typography variant="reportInlineTextBold">
                APT Campaign Tracking Report (aka CTR)
              </Typography>
              provides TeamT5&apos;s comprehensive understanding of significant
              threat groups/campaigns. Users can use CTR to enhance their
              knowledge of trends and challenges in the APAC region with a
              broader perspective. CTR is TeamT5&apos;s in-depth analysis of APT
              groups. Our database has documented over 100 adversary groups. Our
              ThreatVision have detailed over 30 active adversaries. We have
              detailed the most known APT groups such as APT41 (tracked as
              Amoeba), APT10 (tracked as menuPass), Lazarus. Moreover, we also
              spotted the rising APT groups such as ChamelGang (tracked as
              CamoFei), GamblingPuppte.
            </Trans>
          </Description>
          <TocLink
            text={t('ctr.ctrInDetail.title', { ns: 'reportIntroduction' })}
            hash="ctr-in-detail"
          />
          <Description>
            {t('ctr.ctrInDetail.description', { ns: 'reportIntroduction' })}
          </Description>
          <H4Title
            title={t('ctr.publicationFrequency.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            {t('ctr.publicationFrequency.description', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <H4Title
            title={t('ctr.reportLanguage.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            {t('ctr.reportLanguage.description', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <H4Title
            title={t('ctr.reportStructure.title', {
              ns: 'reportIntroduction',
            })}
          />
          <UnorderedList>
            <ListItem>
              {t('ctr.reportStructure.list.introduction', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.researchHighlight', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.timeline', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.tacticsTechniques', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.ccInfrastructure', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.targetScope', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.specialChapter', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.conclusion', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.appendix', {
                ns: 'reportIntroduction',
              })}
              <UnorderedList>
                <ListItem>
                  {t('ctr.reportStructure.list.technicalAnalysis', {
                    ns: 'reportIntroduction',
                  })}
                </ListItem>
                <ListItem>
                  {t('ctr.reportStructure.list.ioc', {
                    ns: 'reportIntroduction',
                  })}
                </ListItem>
                <ListItem>
                  {t('ctr.reportStructure.list.mitreTag', {
                    ns: 'reportIntroduction',
                  })}
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              {t('ctr.reportStructure.list.reference', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
          </UnorderedList>
          <TocLink
            text={t('ctr.exampleReport', {
              ns: 'reportIntroduction',
              reportName: '2023 Q1 Campaign Tracking Report: CamoFei',
            })}
            hash="example-report-2023-q1-campaign-tracking-report-camofei"
          />
          <Description>
            2023 Q1 Campaign Tracking Report: CamoFei
            <br />
            <TextLink
              size="large"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=advanced_report-20230331032100`}
            >
              https://threatvision.org/reports/detail?alias=advanced_report-20230331032100
            </TextLink>
          </Description>
          <ExampleReportBlock title="Introduction">
            <Description>
              CamoFei (aka ChamelGang) is a China-nexus Advanced Persistent
              Threat (APT) group that first drew TeamT5&apos;s attention in its
              attacks targeting major public hospitals in 2020. Prior to this
              report, we have traced the group as SLIME31. Our research team
              named this threat group after Fei (蜚), an evil known for bringing
              illness and disasters in the Chinese classical text &quot;Classic
              of Mountains and Seas&quot; (山海經), to illustrate its strong
              interests against healthcare institutions. Camo, short for
              camouflage, reflects the group&apos;s tactics to disguise its
              footprints.
            </Description>
            <Description>
              Over the past few years, CamoFei has kept low-profile, evading
              most security vendors’ radars. CamoFei makes its first public
              debut in late 2021, but it soon vanished out of the sight of the
              cybersecurity community. Meanwhile, TeamT5 discovered that CamoFei
              commenced to test its malware in China as early as 2019. Since
              then, our research team has observed CamoFei&apos;s activities in
              Taiwan, Vietnam, the Philippines, Thailand, India, Turkey, Brazil,
              and Hong Kong, targeting healthcare institutions, government
              agencies, think tanks, and telecom companies. Notably, our
              database suggested that Taiwan remains CamoFei&apos;s prime target
              over the years.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Research Highlight"
            description={t('ctr.researchHighlightDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <H3Title title="1. Sniping at Taiwan and Countries Surrounding South China Sea" />
            <Description>
              CamoFei&apos;s victimology closely aligns with China&apos;s state
              interests in Taiwan and South China Sea. According to our
              database, CamoFei&apos;s first activity can be traced back to a
              campaign targeting healthcare institutions in Taiwan, including a
              major public hospital affiliated with the military. Over the
              years, we have found CamoFei infiltrating Taiwanese healthcare
              institutions, governmental units, think tanks, and telecom firms.
              In addition to Taiwan, we also found CamoFei attacked government
              agencies and military units in Vietnam, the Philippines, and
              Thailand. With the growing tensions in South China Sea,
              CamoFei&apos;s extended targets indicate China&apos;s interest for
              government data of Southeast Asian countries.
            </Description>
            <H3Title title="2. Arsenal Mixed with Shared and Proprietary Tools" />
            <Description>
              CamoFei has developed an arsenal mixed with shared and proprietary
              tools. In addition to the commercial penetration testing tool
              CobaltStrike, CamoFei has also developed proprietary malware,
              including AukDoor, DoorMe, IISBeacon, Timinp and MGDrive. Among
              all, CamoFei heavily relies on custom CobaltStrike in the attacks.
              CamoFei&apos;s CobaltStrike features frequently changing loaders
              and watermarks. Notably, CamoFei also uses other hacking tools,
              including port scanners and the open-source penetration tool Fast
              Reverse Proxy (FRP) in its campaigns.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Tactics, Techniques, and Procedures"
            description={t('ctr.tacticsTechniquesDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <Description>
              This section will cover CamoFei&apos;s Tactics, Techniques, and
              Procedures (TTPs) based on their attacks and operations from 2019
              to 2023 Q1. The first two sections will thoroughly cover how
              CamoFei conducted initial access and post-exploitation. The
              Malware section will introduce CamoFei&apos;s varied arsenal
              consisting of shared and proprietary malware.
            </Description>
            <H3Title title="Initial Access" />
            <Description>
              From 2019 to the present, CamoFei has demonstrated its
              capabilities to launch attacks with different techniques. For the
              initial access phase, we observed that CamoFei leveraged
              spear-phishing emails with persuasive information to entice the
              victims. On the other hand, the threat actor also exploits
              vulnerabilities to infiltrate internet-facing systems.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="C&C Infrastructure"
            description={t('ctr.ccInfrastructureDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <Description>
              CamoFei used different tactics to build and disguised its C2
              infrastructure. First, CamoFei uses typosquatting domains with
              different naming themes of famous companies. Second, CamoFei
              weaponized prestigious cloud services as its C2, including Google
              Drive and GitHub. Last, some of CamoFei&apos;s RAT have even
              exploited Cloudflare CDN to conceal its real IPs.
            </Description>
            <Description>
              CamoFei repeatedly exploited IP and domains from the following
              providers as C2s:
            </Description>
            <UnorderedList>
              <ListItem>GoDaddy</ListItem>
              <ListItem>PDR Ltd</ListItem>
              <ListItem>Prager-IT e.U</ListItem>
              <ListItem>CITISCLOUD</ListItem>
              <ListItem>CloudITIDC</ListItem>
            </UnorderedList>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Target Scope"
            description={t('ctr.targetScopeDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <Description>
              Our research has found CamoFei targeting Taiwan, Vietnam, the
              Philippines, Thailand, India, Turkey, Brazil, and Hong Kong. A
              security vendor also pointed out CamoFei&apos;s malicious
              campaigns targeting entities in the Russia, United States, Japan,
              Afghanistan, Lithuania, and Nepal.
            </Description>
            <Image src={TargetScopeImage} alt="TargetScope" />
          </ExampleReportBlock>
          <ExampleReportBlock title="Special Chapter">
            <Description>
              In 2023, CamoFei deployed a new ransomware, CatB (aka CatB99,
              Baxtoy), in an attack against a telecom company in Taiwan. The
              CatB ransomware is known for its ability to evade threat detection
              and has been linked to several high-profile attacks on government
              agencies, healthcare organizations, and other critical
              infrastructure.
            </Description>
            <Description>
              Our analysis found that CatB is typically delivered via phishing
              emails or other social engineering techniques. Currently, our
              research team attribute CatB to CamoFei based on the following
              observations:
              <OrderedList>
                <NumberListItem>
                  A specific encryption mechanism previously used by CamoFei was
                  observed in this campaign.
                </NumberListItem>
                <NumberListItem>
                  The ransomware samples&apos; Word icons were used in
                  CamoFei&apos;s previous campaigns.
                </NumberListItem>
                <NumberListItem>
                  The use of the coolschool certificate matches CamoFei&apos;s
                  TTPs.
                </NumberListItem>
                <NumberListItem>
                  The combination of <InlineBlock text="msdtc.exe" /> and{' '}
                  <InlineBlock text="oci.dll" /> for DLL hijacking matches
                  CamoFei&apos;s TTPs.
                </NumberListItem>
              </OrderedList>
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock title="Conclusion">
            <Description>
              CamoFei&apos;s campaign has greatly focused on Taiwan and
              Southeast Asian countries. Only a handful of public reports have
              illustrated CamoFei&apos;s activities in Asia. As a cybersecurity
              firm in Taiwan, this report provides TeamT5&apos;s visibility on
              CamoFei and concludes our years-long research on the actor&apos;s
              malware development, TTPs and target scope.
            </Description>
            <Description>
              As the name &quot;CamoFei&quot; suggested, the threat group&apos;s
              most distinguishing feature is its efforts to conceal its
              footprints and bypass threat detection. Our research has found
              that CamoFei deployed custom CobaltStrike, used the CatB
              ransomware as wiper, and adopted IP-concealing techniques to hide
              its identity. We have also seen CamoFei exploit legitimate Windows
              services for malware execution and use Google Drive and GitHub as
              C2s to evade threat detection systems.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock title="Appendix">
            <H3Title title="Technical Analysis" />
            <H4Title title="MGDrive" />
            <TableContainer sx={{ overflow: 'initial' }}>
              <Table>
                <TableHead>
                  <TableHeaderRow>
                    {MGDriveHeadCells.map((headCell) => (
                      <TableCell key={headCell}>{headCell}</TableCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {MGDriveBodyRows.map((bodyRow, index) => (
                    <TableBodyRow key={bodyRow.filename} index={index}>
                      <TableCell>{bodyRow.sha256}</TableCell>
                      <TableCell>{bodyRow.filename}</TableCell>
                      <TableCell>{bodyRow.timestamp}</TableCell>
                      <TableCell>{bodyRow.description}</TableCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <H3Title title="Indicators of Compromise (IOCs)" />
            <Description>SHA-256</Description>
            <Box sx={{ textAlign: 'center' }}>
              <H3Title title="Cobalt Strike" />
            </Box>
            <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
            <Stack sx={{ textAlign: 'center' }}>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=2bbd6e5f9e953a63cd023047bae1ac7589e1595df884494703e4792b6c5a2b2e`}
              >
                2bbd6e5f9e953a63cd023047bae1ac7589e1595df884494703e4792b6c5a2b2e
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=c263fc87a0b28a11738a9c3851d8b4aba17fe92c0bb6198874929e5e540aea0a`}
              >
                c263fc87a0b28a11738a9c3851d8b4aba17fe92c0bb6198874929e5e540aea0a
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=9c4b6fd76fc73de43a84998bf2b986029743517154ebd8231e0400dc6abf7fb0`}
              >
                9c4b6fd76fc73de43a84998bf2b986029743517154ebd8231e0400dc6abf7fb0
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=9c6bbf6d31224f373d9f6257f6436d567751bc3dbf59d4b271560a6da0620d82`}
              >
                9c6bbf6d31224f373d9f6257f6436d567751bc3dbf59d4b271560a6da0620d82
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6da22225dec419135b8047c01cca2fba0e4212725cfbdc4cfabc1be7304f9efe`}
              >
                6da22225dec419135b8047c01cca2fba0e4212725cfbdc4cfabc1be7304f9efe
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=ba2a57b7d49ff9f299515d461a49a1e68e0ae8ae856f4926276c0e0229515151`}
              >
                ba2a57b7d49ff9f299515d461a49a1e68e0ae8ae856f4926276c0e0229515151
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=7340170ace6e867904b2ad2f829861e1a870cbac9810b3afae613dc0f36ab46e`}
              >
                7340170ace6e867904b2ad2f829861e1a870cbac9810b3afae613dc0f36ab46e
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6ac7c2a8797aed6f81b1ef4693b355640c44feb6e2fcf8f568cdb8a5b8d215b8`}
              >
                6ac7c2a8797aed6f81b1ef4693b355640c44feb6e2fcf8f568cdb8a5b8d215b8
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=92a222e258dc9d79093eb210961d41e8e6aa2e1e84f2f14e4453a6111f7af441`}
              >
                92a222e258dc9d79093eb210961d41e8e6aa2e1e84f2f14e4453a6111f7af441
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=dac9239fec3730b79d6431665bf7493e5adcd97b298ff3a06a843752a68eff84`}
              >
                dac9239fec3730b79d6431665bf7493e5adcd97b298ff3a06a843752a68eff84
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8a8fcb70f8584cc99423a010f575370b77280219b9e6fde79c15979868c91bd8`}
              >
                8a8fcb70f8584cc99423a010f575370b77280219b9e6fde79c15979868c91bd8
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8cd2f05e9437e30e465251f777730ed5aa6a803b67a3640e787850c55d7e41b1`}
              >
                8cd2f05e9437e30e465251f777730ed5aa6a803b67a3640e787850c55d7e41b1
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=b9a231496682cd6bed978fb1b2b15986211e5c38a13cbb246de3dcf1d8db41f4`}
              >
                b9a231496682cd6bed978fb1b2b15986211e5c38a13cbb246de3dcf1d8db41f4
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=d831a87c6abd1bbb5a9ac9e1aac06a3d9b81b6e474bdc0c78e1908e26a6166b3`}
              >
                d831a87c6abd1bbb5a9ac9e1aac06a3d9b81b6e474bdc0c78e1908e26a6166b3
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=3b71fc09a32fa9ec62063edb95ea509b82d000559b04654290dc53c57befa939`}
              >
                3b71fc09a32fa9ec62063edb95ea509b82d000559b04654290dc53c57befa939
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8a69c724a154318347a79380ec5f4f6212981968bd01e4afc55d05e6bd24075b`}
              >
                8a69c724a154318347a79380ec5f4f6212981968bd01e4afc55d05e6bd24075b
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=47d091afed4473a09bb40e0a7cc4fd266a478b09a1ac7d35493d849fad5c017a`}
              >
                47d091afed4473a09bb40e0a7cc4fd266a478b09a1ac7d35493d849fad5c017a
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=d1a7ee10d83f667465be00a7751ec9cdc8191244a0d6d0c0d49c4bbc5e665cd9`}
              >
                d1a7ee10d83f667465be00a7751ec9cdc8191244a0d6d0c0d49c4bbc5e665cd9
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8326ac1ad33c53260c90fc073188d8185f832fbbe7806f5d74906ecd5d9c8953`}
              >
                8326ac1ad33c53260c90fc073188d8185f832fbbe7806f5d74906ecd5d9c8953
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=ef3832e298fb6efeaab5ec1fe21dc97e52d1420f86d949588286db2d8e000426`}
              >
                ef3832e298fb6efeaab5ec1fe21dc97e52d1420f86d949588286db2d8e000426
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6fc6ea8284bba50fa6074f947c4c8eb177b5f260c5def0ef8be7ff39a77ff3ae`}
              >
                6fc6ea8284bba50fa6074f947c4c8eb177b5f260c5def0ef8be7ff39a77ff3ae
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=960a6a79b7cc0dd80e7097d586faf5e1e965fdce767040424abe59e96e04377e`}
              >
                960a6a79b7cc0dd80e7097d586faf5e1e965fdce767040424abe59e96e04377e
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=da0f768b557dc549786561270b8cf65165550c119da45f13b731372ba95aac8a`}
              >
                da0f768b557dc549786561270b8cf65165550c119da45f13b731372ba95aac8a
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=c7e275b32a0c10293a10ea6216eb7de4545d25e500deea3f515ab9e04404106f`}
              >
                c7e275b32a0c10293a10ea6216eb7de4545d25e500deea3f515ab9e04404106f
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=9b3e6a70499d3738c3d010f4089a310fb52818740e4c3ee06542c12742ce7950`}
              >
                9b3e6a70499d3738c3d010f4089a310fb52818740e4c3ee06542c12742ce7950
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=947fdef565d889d3d919d8d81014d718f2d22ef3ed0049c98960f7330f51f41f`}
              >
                947fdef565d889d3d919d8d81014d718f2d22ef3ed0049c98960f7330f51f41f
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=d774cefe5cff7894de659e9d167c4948edce5c39bca6e21272f88833b92aba3f`}
              >
                d774cefe5cff7894de659e9d167c4948edce5c39bca6e21272f88833b92aba3f
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=3bdf678c541274c2d3d036e1b6ea8921618ac612d0df6d6cf2d16d9851594dd4`}
              >
                3bdf678c541274c2d3d036e1b6ea8921618ac612d0df6d6cf2d16d9851594dd4
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6de6dc12a690ee2e997b7c67a7662b6c2a5da3ad3860fc4753e8290cd9634f22`}
              >
                6de6dc12a690ee2e997b7c67a7662b6c2a5da3ad3860fc4753e8290cd9634f22
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=25ef21c8ee240705b83c9916f2c1b861b0a7cab13a1b491b872c35be33dc6b93`}
              >
                25ef21c8ee240705b83c9916f2c1b861b0a7cab13a1b491b872c35be33dc6b93
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6a22ec077000d3f839bbf977ec96e7a47d0c8e3f4ab4d2a85edf4f40f1f01abd`}
              >
                6a22ec077000d3f839bbf977ec96e7a47d0c8e3f4ab4d2a85edf4f40f1f01abd
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=12fe473e7afdb70e63154a7cfd824f96e2431fb1049ff496bf89403b0511bebe`}
              >
                12fe473e7afdb70e63154a7cfd824f96e2431fb1049ff496bf89403b0511bebe
              </TextLink>
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=75a9c5870447ed41aae5427837bfbfc0daf7ac8038cbfb763754d6dfdf0fa270`}
              >
                75a9c5870447ed41aae5427837bfbfc0daf7ac8038cbfb763754d6dfdf0fa270
              </TextLink>
            </Stack>
          </ExampleReportBlock>
          <Box id={ScrollableContainerId.BOTTOM_ID} />
        </Stack>
        <TocList tocList={tocList} />
      </Box>
    </>
  )
}
