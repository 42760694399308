import { TCommentType } from './comment'
import {
  IRelatedRecord,
  IRelatedRecordAPIData,
  mapAPIRelatedRecordToState,
  TRiskLevel,
} from './shared'

export interface IIP {
  id: string
  address: string
  commentableType: TCommentType
}

export interface IIPAPIData {
  main_value: string
  address: string
  commentable_type: TCommentType
}

export interface IIPDetailAPIData {
  risk_level: TRiskLevel
  risk_score: number
  risk_types?: string[]
  adversaries: string[]
  attributes: IRelatedRecordAPIData[]
  ip_sharing: IRelatedRecordAPIData[]
  services: IRelatedRecordAPIData[]
  country: string
  region: string
  city: string
  last_update_at: string
  summary: {
    whois: boolean
    related_adversaries: number
    related_reports: number
    related_samples: number
    dns_records: number
    osint: number
  }
}

export interface IIPDetail {
  riskLevel: TRiskLevel
  riskScore: number
  riskTypes: string[] | null
  adversaries: string[]
  attributes: IRelatedRecord[]
  ipSharing: IRelatedRecord[]
  services: IRelatedRecord[]
  country: string
  region: string
  city: string
  lastUpdateAt: string
  summary: {
    whois: boolean
    relatedAdversaries: number
    relatedReports: number
    relatedSamples: number
    dnsRecords: number
    osint: number
  }
}

export const mapAPIIPsToState = (fromAPIIPs: IIPAPIData[]): IIP[] =>
  fromAPIIPs.map((fromAPIIP) => ({
    id: fromAPIIP.main_value,
    address: fromAPIIP.address,
    commentableType: fromAPIIP.commentable_type,
  }))

export const mapAPIIPDetailToState = (
  fromAPIIPDetail: IIPDetailAPIData
): IIPDetail => ({
  riskLevel: fromAPIIPDetail.risk_level,
  riskScore: fromAPIIPDetail.risk_score,
  riskTypes: fromAPIIPDetail.risk_types || null,
  adversaries: fromAPIIPDetail.adversaries,
  attributes: mapAPIRelatedRecordToState(fromAPIIPDetail.attributes),
  ipSharing: mapAPIRelatedRecordToState(fromAPIIPDetail.ip_sharing),
  services: mapAPIRelatedRecordToState(fromAPIIPDetail.services),
  country: fromAPIIPDetail.country,
  region: fromAPIIPDetail.region,
  city: fromAPIIPDetail.city,
  lastUpdateAt: fromAPIIPDetail.last_update_at,
  summary: {
    whois: fromAPIIPDetail.summary.whois,
    relatedAdversaries: fromAPIIPDetail.summary.related_adversaries,
    relatedReports: fromAPIIPDetail.summary.related_reports,
    relatedSamples: fromAPIIPDetail.summary.related_samples,
    dnsRecords: fromAPIIPDetail.summary.dns_records,
    osint: fromAPIIPDetail.summary.osint,
  },
})
