import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { DOMAIN_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'
import { IDomain } from 'store/types/entityTypes/domain'

export const DomainPreview = ({ domain }: IDomain) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        px: 4,
        py: 3,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      <Box>
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {domain}
        </Typography>
      </Box>
      <LinkButton
        to={`/${DOMAIN_ROUTE}?${SearchParamKey.DOMAIN}=${domain}`}
        variant="contained"
        endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
      >
        {t('checkDetailCta', { ns: 'component' })}
      </LinkButton>
    </Box>
  )
}
