import { AdversaryTabContent } from 'components/Adversary/AdversaryTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectDomainDetail,
  selectDomainDetailLoading,
} from 'store/slices/domain'
import {
  selectTabAdversaryCount,
  selectTabAdversaryCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
} from 'store/slices/tabContent'

import { TDetailType } from '../config'
import { CommunityContent } from '../IPDomainComponents/CommunityContent'
import { DNSRecordContent } from '../IPDomainComponents/DNSRecordContent'
import { RelationSamples } from '../IPDomainComponents/RelationSamples'
import { WhoisContent } from '../IPDomainComponents/WhoisContent'

export type TDomainKey =
  | 'dns-records'
  | 'reports'
  | 'adversaries'
  | 'samples'
  | 'whois'
  | 'community'

export interface IDomainTab {
  key: TDomainKey
  name: string
  component: FC<{ searchText: string; detailType: TDetailType }>
  count?: number
  showCount?: boolean
  isLoading?: boolean
  appendPlusSign?: boolean
}

export const useDomainCategoryTabs = (): IDomainTab[] => {
  const { t } = useTranslation(['ipDomain'])
  const isDomainDetailLoading = useAppSelector(selectDomainDetailLoading)
  const domainDetail = useAppSelector(selectDomainDetail)
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)

  const tabAdversaryCountLoading = useAppSelector(
    selectTabAdversaryCountLoading
  )
  const tabAdversaryCount = useAppSelector(selectTabAdversaryCount)

  return [
    {
      key: 'dns-records',
      name: t('contentTabs.dnsRecords', { ns: 'ipDomain' }),
      component: DNSRecordContent,
      count: domainDetail?.summary.dnsRecords,
      isLoading: isDomainDetailLoading,
    },
    {
      key: 'reports',
      name: t('contentTabs.reports', { ns: 'ipDomain' }),
      component: ReportTabContent,
      count: tabReportCount,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'adversaries',
      name: t('contentTabs.adversaries', { ns: 'ipDomain' }),
      component: AdversaryTabContent,
      count: tabAdversaryCount,
      isLoading: tabAdversaryCountLoading,
    },
    {
      key: 'samples',
      name: t('contentTabs.samples', { ns: 'ipDomain' }),
      component: RelationSamples,
      count: domainDetail?.summary.relatedSamples,
      isLoading: isDomainDetailLoading,
      appendPlusSign:
        !!domainDetail?.summary.relatedSamples &&
        domainDetail?.summary.relatedSamples >= 1000,
    },
    {
      key: 'whois',
      name: t('contentTabs.whois', { ns: 'ipDomain' }),
      component: WhoisContent,
      showCount: false,
    },
    {
      key: 'community',
      name: t('contentTabs.community', { ns: 'ipDomain' }),
      component: CommunityContent,
      showCount: false,
    },
  ]
}
