import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  SvgIcon,
  useTheme,
} from '@mui/material'
import { ReactComponent as ExpandMoreIcon } from 'assets/basicIcons/angleDown.svg'

export const Accordion = ({ sx = [], ...props }: AccordionProps) => {
  const theme = useTheme()
  return (
    <MuiAccordion
      sx={[
        {
          bgcolor: 'transparent',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          '&.Mui-expanded': {
            my: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
}

export const AccordionSummary = ({
  sx = [],
  ...props
}: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={[
      {
        pl: 2,
        pr: 4,
        minHeight: 0,
        '& .MuiAccordionSummary-content': {
          my: 2,
          mx: 0,
        },
        '&.Mui-expanded': {
          minHeight: 0,
          '& .MuiAccordionSummary-content': {
            my: 2,
          },
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    expandIcon={
      <SvgIcon
        sx={{ width: '1.5rem', height: '1.5rem' }}
        component={ExpandMoreIcon}
        inheritViewBox
      />
    }
    {...props}
  />
)

export const AccordionDetails = ({
  sx = [],
  ...props
}: AccordionDetailsProps) => {
  const theme = useTheme()
  return (
    <MuiAccordionDetails
      sx={[
        { borderTop: `1px solid ${theme.colors.WHITE_20}` },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
}
