import { ISearchReportTypeOption } from 'store/types/slicesTypes/search'

export const useSearchReportTypeOptions = () => {
  const searchReportType: ISearchReportTypeOption[] = [
    {
      key: 'flashReport',
      displayName: 'Flash',
    },
    {
      key: 'monthlyReport',
      displayName: 'Monthly',
    },
    {
      key: 'CTRReport',
      displayName: 'CTR',
    },
    {
      key: 'cyberAffairs',
      displayName: 'Cyber Affairs',
    },
    {
      key: 'VIRReport',
      displayName: 'VIR',
    },
    // 暫時移除 custom report 選項，目前不能單獨 filter
    // {
    //   key: 'customReport',
    //   displayName: t('reportTypeFilter.customReport', { ns: 'search' }),
    // },
  ]

  return searchReportType
}
