import { Box, Typography, useTheme } from '@mui/material'
import { NoData } from 'components/NoData/NoData'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchDomainWhois,
  fetchDomainWhoisCancelled,
  selectDomainWhois,
  selectDomainWhoisLoading,
} from 'store/slices/domain'
import {
  fetchIPWhois,
  fetchIPWhoisCancelled,
  selectIPWhois,
  selectIPWhoisLoading,
} from 'store/slices/ip'

import { IContentProps } from '../config'
import { WhoisLoading } from './WhoisLoading'

export const WhoisContent = ({ searchText, detailType }: IContentProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const isIP = detailType === 'ip'

  const isIPWhoisLoading = useAppSelector(selectIPWhoisLoading)
  const isDomainWhoisLoading = useAppSelector(selectDomainWhoisLoading)
  const ipWhois = useAppSelector(selectIPWhois)
  const domainWhois = useAppSelector(selectDomainWhois)

  const isWhoisLoading = isIP ? isIPWhoisLoading : isDomainWhoisLoading
  const whois = isIP ? ipWhois : domainWhois

  useEffect(() => {
    if (isIP) {
      dispatch(fetchIPWhois(searchText))
    } else {
      dispatch(fetchDomainWhois(searchText))
    }

    return () => {
      if (isIP) {
        dispatch(fetchIPWhoisCancelled())
      } else {
        dispatch(fetchDomainWhoisCancelled())
      }
    }
  }, [searchText])

  if (!isWhoisLoading && !whois) {
    return <NoData />
  }

  return (
    <Box sx={{ height: '100%', p: 2 }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: theme.colors.BLACK_90,
          border: `1px solid ${theme.colors.WHITE_20}`,
          p: 4,
          overflowY: 'auto',
        }}
      >
        {isWhoisLoading ? (
          <WhoisLoading />
        ) : (
          <Typography
            variant="reportInlineCode"
            sx={{ color: theme.colors.WHITE, whiteSpace: 'pre-line' }}
          >
            {whois}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
