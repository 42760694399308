import { Divider, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const RELATED_REPORT_LOADING_TEST_ID = 'related-report-loading'

const LoadingBlock = () => (
  <Stack sx={{ p: 4, gap: 4, width: '100%' }}>
    <Skeleton type="title" width="15rem" />
    <Stack sx={{ gap: 2, pb: 18 }}>
      <Skeleton type="body" width="100%" />
      <Skeleton type="body" width="100%" />
    </Stack>
  </Stack>
)

export const Loading = () => {
  const theme = useTheme()

  return (
    <Stack data-testid={RELATED_REPORT_LOADING_TEST_ID} sx={{ p: 2 }}>
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
    </Stack>
  )
}
