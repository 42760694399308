import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  SvgIcon,
  useTheme,
} from '@mui/material'
import { ReactComponent as CheckBoxIcon } from 'assets/basicIcons/checkbox.svg'
import { ReactComponent as CheckBoxBlankIcon } from 'assets/basicIcons/checkboxBlank.svg'
import { ReactComponent as IndeterminateCheckBoxIcon } from 'assets/basicIcons/indeterminateCheckBox.svg'

export const Checkbox = ({
  sx = [],
  checked,
  disabled,
  ...props
}: CheckboxProps) => {
  const theme = useTheme()

  return (
    <MuiCheckbox
      checked={checked}
      disabled={disabled}
      sx={[
        {
          '&.Mui-checked': {
            '& rect': {
              fill: disabled ? theme.colors.DISABLE : theme.colors.PRIMARY_60,
            },
            '& path': {
              fill: disabled ? theme.colors.WHITE_20 : theme.colors.WHITE,
            },
          },
          '&.MuiCheckbox-indeterminate': {
            '& svg rect:first-of-type': {
              fill: disabled ? theme.colors.DISABLE : theme.colors.PRIMARY_60,
            },
            '& svg rect:last-of-type': {
              fill: disabled ? theme.colors.WHITE_20 : theme.colors.WHITE,
            },
          },
          '&:hover': {
            bgcolor: checked ? theme.colors.PRIMARY_20 : theme.colors.WHITE_20,
            path: {
              fill: 'white',
              fillOpacity: 1,
            },
          },
          '&:active': {
            bgcolor: checked ? theme.colors.PRIMARY_60 : theme.colors.WHITE_60,
          },
          '&.MuiButtonBase-root.MuiCheckbox-root': {
            width: '1.5rem',
            height: '1.5rem',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      icon={
        <SvgIcon
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            path: {
              fill: disabled ? theme.colors.DISABLE : theme.colors.WHITE_60,
            },
          }}
          component={CheckBoxBlankIcon}
          inheritViewBox
        />
      }
      checkedIcon={
        <SvgIcon
          sx={{ width: '1.5rem', height: '1.5rem' }}
          component={CheckBoxIcon}
          inheritViewBox
        />
      }
      indeterminateIcon={
        <SvgIcon
          sx={{ width: '1.5rem', height: '1.5rem' }}
          component={IndeterminateCheckBoxIcon}
          inheritViewBox
        />
      }
      {...props}
    />
  )
}
