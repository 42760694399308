import { useEffect,useState } from 'react'

export interface IWindowSize {
  width: number | undefined
  height: number | undefined
}

/**
 * @description 取得畫面的長寬
 * @return 一個包含畫面長寬的物件
 */
export const useWindowSize: () => IWindowSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
