import {
  Box,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as TocIcon } from 'assets/report/icons/toc.svg'
import {
  ListItem,
  NumberListItem,
  OrderedList,
  UnorderedList,
} from 'components/List/List'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import { PAGE_TITLE_DDW_TAILORED_CHINESE_SAMPLE_REPORT } from 'constants/pageTitle'
import { useGoBack } from 'hooks/useGoBack'
import Image from 'mui-image'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import SampleReportImage from './DDWTailoredSampleReport.jpeg'
import KeywordImage1 from './keyword-image-1.png'
import KeywordImage2 from './keyword-image-2.png'
import KeywordImage3 from './keyword-image-3.png'
import KeywordImage4 from './keyword-image-4.png'

const BACK_TO_TOP = 'backToTop'
const GO_TO_BOTTOM = 'goToBottom'

const INFO_PADDING = '1.5rem'

interface IToc {
  text: string
  anchorId: string
}

const tocList: IToc[] = [
  {
    text: '1. Executive Summary',
    anchorId: 'executive-summary',
  },
  {
    text: '2. Scope',
    anchorId: 'scope',
  },
  {
    text: '3. List of Leaked Data',
    anchorId: 'list-of-leaked-data',
  },
  {
    text: '4. Risk Assessment and Recommendations',
    anchorId: 'risk-assessment-and-recommendations',
  },
]

export const DDWTailoredEnglishReportSample = () => {
  useGoBack()
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const { search, hash } = useLocation()
  const [selectedTitleId, setSelectedTitleId] = useState<string>(
    hash.replace('#', '')
  )

  const handleTocTitleClick = (titleItem: IToc) => {
    const { anchorId } = titleItem
    setSelectedTitleId(anchorId)
    if (anchorId === GO_TO_BOTTOM || anchorId === BACK_TO_TOP) {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DDW_TAILORED_CHINESE_SAMPLE_REPORT}</title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '54.125rem 23.875rem',
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          backgroundColor: theme.colors.BLACK_95,
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack sx={{ px: 28, pt: 4, pb: 10 }}>
          <>
            <Stack id={BACK_TO_TOP} sx={{ gap: 2 }}>
              <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
                Deep and Dark Web Tailored Report
              </Typography>
              <Typography
                variant="reportInlineTextSmall"
                sx={{ color: theme.colors.WHITE_60 }}
              >
                2023-10-XX
              </Typography>
              <Image
                src={SampleReportImage}
                alt="sample-report"
                height="26.875rem"
                fit="cover"
              />
              <Box
                sx={{
                  bgcolor: theme.colors.BLACK_90,
                  p: 4,
                  borderLeft: `4px solid ${theme.colors.PRIMARY_20}`,
                  borderRadius: 0.5,
                }}
              >
                <Typography
                  variant="reportH3"
                  sx={{ color: theme.colors.PRIMARY }}
                >
                  Report Summary
                </Typography>
                <OrderedList>
                  <NumberListItem>Executive Summary</NumberListItem>
                  <NumberListItem>Scope</NumberListItem>
                  <NumberListItem>List of Leaked Data</NumberListItem>
                  <NumberListItem>
                    Risk Assessment and Recommendations
                  </NumberListItem>
                </OrderedList>
              </Box>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="executive-summary"
              >
                1. Executive Summary
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                No ransomware related threats were found during this report
                period.
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Leaked credentials:
              </Typography>
              <UnorderedList>
                <ListItem>
                  Leaked credentials from combo lists on the deep and dark
                  web:The latest leaked information occurred in Sep. 2023.
                </ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Password</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Detection Date
                      </TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>aaaaaaa@***.com</TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>15/9/2023 12:48</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <UnorderedList>
                <ListItem>
                  Leaked info from compromised devices:The most recent
                  compromise occurred in Sep. 2023.
                </ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>Host</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        User Account
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Password
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Detection Date
                      </TableCell>
                      <TableCell>IP</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell sx={{ wordBreak: 'break-word' }}>
                        https://bbb.***.com.tw/cccc50/Logints.asp
                      </TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>23/9/2023 12:48</TableCell>
                      <TableCell>**.**.**.179</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Summary of search results
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell />
                      <TableCell>***[.]com.tw</TableCell>
                      <TableCell>***life[.]com</TableCell>
                      <TableCell>*******[.]com</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>Leaked Credentials</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>45</TableCell>
                      <TableCell>64</TableCell>
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell>Compromised Devices</TableCell>
                      <TableCell>26</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>159</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Charts/Data
              </Typography>
              <UnorderedList>
                <ListItem>Number of leaked accounts: X</ListItem>
                <ListItem>Number of leaked services (Host): Y</ListItem>
                <ListItem>Number of leaked email addresses: Z</ListItem>
              </UnorderedList>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="scope"
              >
                2. Scope
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                TeamT5 searched the domains of &quot;XX Group&quot; and its
                related subsidiaries &quot;XX Securities&quot;, &quot;XX
                Investment&quot;, &quot;XX Commercial Bank&quot;, and &quot;XX
                Life&quot;:
              </Typography>
              <UnorderedList>
                <ListItem>
                  XX Financial Holdings, XX Securities: ***[.]com.tw
                </ListItem>
                <ListItem>XX Investment: ***life[.]com</ListItem>
                <ListItem>XX Commercial Bank: *******[.]com</ListItem>
              </UnorderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Report period:
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                September 01, 2023 to September 30, 2023
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Leaked credentials for the above domains were searched and
                divided into two parts according to type:
              </Typography>
              <OrderedList>
                <NumberListItem>
                  Leaked credentials from combo lists on the deep and dark web:
                  Credentials were leaked to the dark web/underground market
                  through Combo Lists, and the information included emails and
                  passwords.
                </NumberListItem>
                <NumberListItem>
                  Leaked info from compromised devices: It is the data leaked by
                  the compromised device. The data includes connection records,
                  account names, passwords*, and IP.
                </NumberListItem>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Dark web information monitoring:
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                Real-time detection of 10 keywords on the dark web, providing
                relevant information. This type of intelligence includes
                ransomware, dark web marketplaces, and leaked files and emails
                circulating and related to the dark web.
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Keyword</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell>Keyword</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>1</TableCell>
                      <TableCell>***[.]com.tw</TableCell>
                      <TableCell>6</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell>2</TableCell>
                      <TableCell>***life[.]com</TableCell>
                      <TableCell>7</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={2}>
                      <TableCell>3</TableCell>
                      <TableCell>*******[.]com</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={3}>
                      <TableCell>4</TableCell>
                      <TableCell>TeamT5</TableCell>
                      <TableCell>9</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={4}>
                      <TableCell>5</TableCell>
                      <TableCell />
                      <TableCell>10</TableCell>
                      <TableCell />
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportCaption"
                sx={{ color: theme.colors.WHITE_60, textAlign: 'center' }}
              >
                Table 1: Keyword phrases used in this sample report
              </Typography>
              <UnorderedList>
                <ListItem>Keyword List</ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Keyword</TableCell>
                      <TableCell>Query Range</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>4</TableCell>
                      <TableCell>TeamT5</TableCell>
                      <TableCell>TeamT5 / Team T5</TableCell>
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell sx={{ height: '3.1125rem' }} />
                      <TableCell />
                      <TableCell />
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="list-of-leaked-data"
              >
                3. List of Leaked Data
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Leaked credentials from combo lists on the deep and dark web
              </Typography>
              <OrderedList>
                <NumberListItem>***[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Detection Date
                        </TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell>aaaaaaa@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>xxxxxx@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell>ppppppppp@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>***life[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Detection Date
                        </TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell>ffff@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>uuuuuu@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell>qqqq@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Leaked info from compromised devices
              </Typography>
              <OrderedList>
                <NumberListItem>***[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          User Account
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Password
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Detection Date
                        </TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.***.com.tw/cccc50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>23/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://global.***.com.tw/kkkweb/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.***.com.tw/ooweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>***life[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          User Account
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Password
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Detection Date
                        </TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://etrade.***life.com /hweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>
                          https://global.***life.com /nexuswe/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://etrade.***life.com/yyweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>*******[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          User Account
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Password
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Detection Date
                        </TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://ggggg.*******.com/iiweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://kkkk.*******.com/newebde/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.*******.com/hweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                Keywords Mentioned in Social Media Platforms or Deep and Dark
                Web Forums
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Image height={340} src={KeywordImage1} />
                <UnorderedList>
                  <ListItem>Title: Taiwan_XXXXX_XX_Shopping</ListItem>
                  <ListItem>Publication time: 2023.xx.xx</ListItem>
                  <ListItem>Channel: Telegram</ListItem>
                  <ListItem>Seller: XXXX, general member</ListItem>
                  <ListItem>
                    Content: Member information leaked. Including name/mobile
                    phone number/address/product/card number, etc.
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image width={285} height={292} src={KeywordImage2} />
                </Box>
                <UnorderedList>
                  <ListItem>Title: XX Popular Cards</ListItem>
                  <ListItem>Publication time: 2023.xx.xx</ListItem>
                  <ListItem>Channel: Telegram</ListItem>
                  <ListItem>Seller: XXXX, general member</ListItem>
                  <ListItem>
                    Content: The card number is sold as follows: 49 XXXXX / 49
                    XXXXX49 XXXXX / 49 XXXXX53 XXXXX / 53 XXXXX
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image width={421} height={350} src={KeywordImage3} />
                </Box>
                <UnorderedList>
                  <ListItem>
                    Title: XX Bank Card : Collection of Japanese Yen
                  </ListItem>
                  <ListItem>Publication time: 2023.xx.xx</ListItem>
                  <ListItem>Channel: Forum</ListItem>
                  <ListItem>Seller: XXXX, general member</ListItem>
                  <ListItem>
                    Content: XX bank account Japanese yen collection service
                    (money laundering)
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image height={347} src={KeywordImage4} />
                </Box>
                <UnorderedList>
                  <ListItem>Title: TW XXXX DATABASE LEAKED!!!!!!!</ListItem>
                  <ListItem>Publication time: 2023.xx.xx</ListItem>
                  <ListItem>Channel: Onion Station</ListItem>
                  <ListItem>Seller: XXXX, general member</ListItem>
                  <ListItem>
                    Content: 20G monthly report/conversation records, etc.
                  </ListItem>
                </UnorderedList>
              </Box>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="risk-assessment-and-recommendations"
              >
                4. Risk Assessment and Recommendations
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                The following are general mitigation and prevention
                recommendations provided by the TeamT5 intelligence team for
                possible leaks of intelligence information. For different types
                of data leakage, actors may take different measures.
              </Typography>
              <OrderedList>
                <NumberListItem>
                  Strengthen employees&apos; awareness of information security
                  and use different password combinations for different
                  websites.
                </NumberListItem>
                <NumberListItem>
                  Companies should conduct regular vulnerability scans and
                  security assessments to find possible weaknesses and risks and
                  patch them as soon as possible to protect the company&apos;s
                  data and information security.
                </NumberListItem>
              </OrderedList>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                *Note: In order to protect the personal privacy and rights of
                relevant personnel, the password fields in this file are masked.
                If you require raw data, please contact TeamT5.
              </Typography>
            </Stack>
            <Box id={GO_TO_BOTTOM} />
          </>
        </Stack>
        <Stack sx={{ height: '100%' }}>
          <Box
            sx={{
              position: 'sticky',
              top: '1.5rem',
              width: '21.875rem',
              height: `calc(100vh - ${theme.fixedConstants.FIXED_TOP_HEIGHT} - ${theme.fixedConstants.FIXED_FOOTER_HEIGHT} - ${INFO_PADDING} * 2)`,
            }}
          >
            <Stack sx={{ height: '100%' }}>
              <Stack
                sx={{
                  flexBasis: 0,
                  flexGrow: 1,
                  px: 2,
                  py: 4,
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1.5,
                    borderBottom: `2px solid ${theme.colors.PRIMARY}`,
                  }}
                >
                  <TabWithCount
                    icon={TocIcon}
                    name={t('toc', { ns: 'component' })}
                    showCount={false}
                    handleSelect={() => {}}
                    selected
                    sx={{ width: 'fit-content' }}
                  />
                </Box>
                <Stack sx={{ gap: 2, px: 4, py: 6 }}>
                  {tocList.map((title) => (
                    <Box
                      key={title.anchorId}
                      sx={{
                        cursor: 'pointer',
                        flexGrow: 1,
                      }}
                      onClick={() => handleTocTitleClick(title)}
                    >
                      <Link
                        sx={{ textDecoration: 'none' }}
                        href={`${search}#${title.anchorId}`}
                      >
                        <Typography
                          variant="bodySmall"
                          sx={{
                            color:
                              selectedTitleId === title.anchorId
                                ? theme.colors.PRIMARY_60
                                : theme.colors.WHITE_60,
                            display: 'flex',
                            '&:hover': {
                              color: theme.colors.TEXT_LINK_NORMAL,
                            },
                          }}
                        >
                          {title.text}
                        </Typography>
                      </Link>
                    </Box>
                  ))}
                </Stack>
              </Stack>
              <Stack sx={{ gap: 2, flexShrink: 0 }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleTocTitleClick({
                      text: t('scrollButton.backToTop', { ns: 'component' }),
                      anchorId: BACK_TO_TOP,
                    })
                  }
                >
                  <Typography
                    variant="bodySmall"
                    sx={{
                      pl: 5,
                      color:
                        selectedTitleId === BACK_TO_TOP
                          ? theme.colors.PRIMARY_60
                          : theme.colors.WHITE_60,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      '&:hover': {
                        color: theme.colors.TEXT_LINK_NORMAL,
                      },
                    }}
                  >
                    {t('scrollButton.backToTop', { ns: 'component' })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderLeft: `4px solid transparent`,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleTocTitleClick({
                      text: t('scrollButton.goToBottom', { ns: 'component' }),
                      anchorId: GO_TO_BOTTOM,
                    })
                  }
                >
                  <Typography
                    variant="bodySmall"
                    sx={{
                      pl: 5,
                      color:
                        selectedTitleId === GO_TO_BOTTOM
                          ? theme.colors.PRIMARY_60
                          : theme.colors.WHITE_60,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      '&:hover': {
                        color: theme.colors.TEXT_LINK_NORMAL,
                      },
                    }}
                  >
                    {t('scrollButton.goToBottom', { ns: 'component' })}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
