import { GobackContext } from 'context/GobackContext'
import { useContext, useEffect } from 'react'

/**
 * @description 使用此 custom-hook 來啟動上方 searchbar 旁邊的 back button
 */
export const useGoBack = () => {
  const { setBackTo } = useContext(GobackContext)

  useEffect(() => {
    setBackTo(true)
    return () => {
      setBackTo(false)
    }
  }, [])
}
