import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IAdversary } from 'store/types/entityTypes/adversary'
import { IReport } from 'store/types/entityTypes/report'
import {
  IIndustryBuzzword,
  IThreatLandscapeSlice,
} from 'store/types/slicesTypes/threatLandscape'

const initialState: IThreatLandscapeSlice = {
  relatedReportsLoading: true,
  relatedReports: [],
  relatedAdversariesLoading: true,
  relatedAdversaries: [],
  industryBuzzwordsLoading: true,
  industryBuzzwords: [],
}

const threatLandscapeSlice = createSlice({
  name: 'threatLandscape',
  initialState,
  reducers: {
    fetchCountryRelatedReports(state, _action: PayloadAction<string>) {
      state.relatedReportsLoading = true
    },
    fetchIndustryRelatedReports(state, _action: PayloadAction<string>) {
      state.relatedReportsLoading = true
    },
    fetchRegionRelatedReports(state, _action: PayloadAction<string>) {
      state.relatedReportsLoading = true
    },
    fetchRelatedReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      state.relatedReports = action.payload
      state.relatedReportsLoading = false
    },
    fetchRelatedReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.relatedReports = initialState.relatedReports
      state.relatedReportsLoading = false
    },
    fetchRelatedReportsCancelled(state) {
      state.relatedReportsLoading = false
    },
    fetchCountryRelatedAdversaries(state, _action: PayloadAction<string>) {
      state.relatedAdversariesLoading = true
    },
    fetchIndustryRelatedAdversaries(state, _action: PayloadAction<string>) {
      state.relatedAdversariesLoading = true
    },
    fetchRegionRelatedAdversaries(state, _action: PayloadAction<string>) {
      state.relatedAdversariesLoading = true
    },
    fetchRelatedAdversariesFulfilled(
      state,
      action: PayloadAction<IAdversary[]>
    ) {
      state.relatedAdversaries = action.payload
      state.relatedAdversariesLoading = false
    },
    fetchRelatedAdversariesRejected(state, _action: PayloadAction<AjaxError>) {
      state.relatedAdversaries = initialState.relatedAdversaries
      state.relatedAdversariesLoading = false
    },
    fetchRelatedAdversariesCancelled(state) {
      state.relatedAdversariesLoading = false
    },
    resetRelatedAdversaries(state) {
      state.relatedAdversaries = initialState.relatedAdversaries
    },
    fetchIndustryBuzzwords(state, _action: PayloadAction<string>) {
      state.industryBuzzwordsLoading = true
    },
    fetchIndustryBuzzwordsFulfilled(
      state,
      action: PayloadAction<IIndustryBuzzword[]>
    ) {
      state.industryBuzzwords = action.payload
      state.industryBuzzwordsLoading = false
    },
    fetchIndustryBuzzwordsRejected(state, _action: PayloadAction<AjaxError>) {
      state.industryBuzzwords = initialState.industryBuzzwords
      state.industryBuzzwordsLoading = false
    },
    fetchIndustryBuzzwordsCancelled(state) {
      state.industryBuzzwordsLoading = false
    },
  },
})

export const {
  fetchCountryRelatedReports,
  fetchIndustryRelatedReports,
  fetchRegionRelatedReports,
  fetchRelatedReportsFulfilled,
  fetchRelatedReportsRejected,
  fetchRelatedReportsCancelled,
  fetchCountryRelatedAdversaries,
  fetchIndustryRelatedAdversaries,
  fetchRegionRelatedAdversaries,
  fetchRelatedAdversariesFulfilled,
  fetchRelatedAdversariesRejected,
  fetchRelatedAdversariesCancelled,
  resetRelatedAdversaries,
  fetchIndustryBuzzwords,
  fetchIndustryBuzzwordsFulfilled,
  fetchIndustryBuzzwordsRejected,
  fetchIndustryBuzzwordsCancelled,
} = threatLandscapeSlice.actions

export const selectRelatedReportsLoading = (state: TRootState) =>
  state.threatLandscape.relatedReportsLoading
export const selectRelatedReports = (state: TRootState) =>
  state.threatLandscape.relatedReports
export const selectRelatedAdversariesLoading = (state: TRootState) =>
  state.threatLandscape.relatedAdversariesLoading
export const selectRelatedAdversaries = (state: TRootState) =>
  state.threatLandscape.relatedAdversaries
export const selectIndustryBuzzwordsLoading = (state: TRootState) =>
  state.threatLandscape.industryBuzzwordsLoading
export const selectIndustryBuzzwords = (state: TRootState) =>
  state.threatLandscape.industryBuzzwords

export default threatLandscapeSlice.reducer
