import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { Button } from 'components/Button/Button'
import { DateLabel, NewLabel } from 'components/Label/Label'
import { sub } from 'date-fns'
import { useDateTime } from 'hooks/useDatetime'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface IDocumentListItemProps {
  to?: string
  lastUpdateDate: number
  text: string
  downloadFile?: string
  downloadFilename?: string
}

export const DocumentListItem = ({
  to,
  lastUpdateDate,
  text,
  downloadFile,
  downloadFilename,
}: IDocumentListItemProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const { t } = useTranslation(['component'])

  const isNew =
    sub(Date.now(), { months: 1 }) <
    new Date(getDateStringByTimestampsInSeconds(lastUpdateDate))

  return (
    <Box
      sx={[
        {
          px: 4,
          py: 2,
          height: '2.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: to ? 'pointer' : 'default',
          '&:hover': {
            bgcolor: theme.colors.BLACK_60,
          },
          '&:active': {
            bgcolor: to ? theme.colors.BLACK_95 : theme.colors.BLACK_60,
          },
          '&+&': {
            borderTop: `1px solid ${theme.colors.WHITE_20}`,
          },
        },
      ]}
      to={to || ''}
      component={to ? Link : Box}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <DateLabel text={getDateStringByTimestampsInSeconds(lastUpdateDate)} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant="titleSmall"
            className="displayName"
            sx={{ color: theme.colors.WHITE }}
          >
            {text}
          </Typography>
          {isNew && <NewLabel text="New" />}
        </Box>
      </Box>
      {downloadFile ? (
        <Link to={downloadFile} target="_blank" download={downloadFilename}>
          <Button endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}>
            {t('downloadCta', { ns: 'component' })}
          </Button>
        </Link>
      ) : (
        <SvgIcon component={ArrowForwardIcon} inheritViewBox />
      )}
    </Box>
  )
}
