import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { PageSection } from 'components/PageSection/PageSection'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectIsFetchAccountSettingsOptionsLoading,
  updateAccountSettings,
  updateReportLanguage,
} from 'store/slices/account'
import {
  IAccountSettings,
  IAccountSettingsOptions,
  TLanguages,
} from 'store/types/entityTypes/account'

import { ReportLanguageLoading } from './ReportLanguageLoading'

export const ReportLanguage = ({
  accountSettings,
  accountSettingsOptions,
}: {
  accountSettings: IAccountSettings
  accountSettingsOptions: IAccountSettingsOptions
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['account', 'snackbar'])
  const dispatch = useAppDispatch()
  const isAccountSettingsOptionsLoading = useAppSelector(
    selectIsFetchAccountSettingsOptionsLoading
  )

  const reportLanguages = [...accountSettings.reportLanguages]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const index = reportLanguages.indexOf(event.target.value as TLanguages)
    if (index > -1) {
      reportLanguages.splice(index, 1)
    } else {
      reportLanguages.push(event.target.value as TLanguages)
    }

    if (reportLanguages.length === 0) {
      reportLanguages.push('en')
      reportLanguages.push('ja')
      reportLanguages.push('zh-TW')
    }

    dispatch(
      updateAccountSettings({
        tip_user: {
          merge_preferences: {
            report_languages: reportLanguages,
          },
        },
        successMessage: t('reportLanguage.updateSuccess', { ns: 'snackbar' }),
        failMessage: t('reportLanguage.updateFail', { ns: 'snackbar' }),
      })
    )

    dispatch(updateReportLanguage(reportLanguages))
  }

  return (
    <PageSection
      title={t('reportLanguage.title', { ns: 'account' })}
      titleInfo={
        <Tooltip
          title={
            <Stack>
              <Typography
                variant="textSmallImportant"
                sx={{
                  'ul': {
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    marginInlineStart: '1.25rem',
                    marginInlineEnd: 0,
                    paddingInlineStart: 0,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: t('reportLanguage.tooltip', { ns: 'account' }),
                }}
              />
            </Stack>
          }
        >
          <SvgIcon
            component={InfoIcon}
            inheritViewBox
            sx={{ width: '1rem', height: '1rem' }}
          />
        </Tooltip>
      }
    >
      <Box sx={{ px: 4, py: 2 }}>
        {isAccountSettingsOptionsLoading ? (
          <ReportLanguageLoading />
        ) : (
          <FormControl>
            <FormGroup sx={{ flexDirection: 'row' }}>
              {accountSettingsOptions.reportLanguages.map(
                ({ value, displayValue }) => (
                  <FormControlLabel
                    key={value}
                    control={
                      <Checkbox
                        checked={
                          value
                            ? accountSettings.reportLanguages.includes(value)
                            : false
                        }
                        value={value}
                        onChange={handleChange}
                        name={displayValue}
                        disabled={value === 'en'}
                      />
                    }
                    label={
                      value === 'en'
                        ? `${displayValue} (Always Shown)`
                        : displayValue
                    }
                    sx={{
                      color: theme.colors.WHITE,
                      '.MuiTypography-root': { ...theme.typography.body },
                      '.MuiCheckbox-root': { color: theme.colors.WHITE_60 },
                      '.Mui-checked': { color: theme.colors.PRIMARY },
                      '.MuiFormControlLabel-label.Mui-disabled': {
                        color: theme.colors.WHITE,
                      },
                    }}
                  />
                )
              )}
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </PageSection>
  )
}
