import { Box, useTheme } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { PAGE_TITLE_MALWARE } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { MalwareTable } from './TableComponents/MalwareTable'

export const MalwarePage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['malware'])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_MALWARE}</title>
      </Helmet>
      <Box
        sx={{
          p: 1,
          height: '100%',
          width: '100%',
          overflow: 'auto',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <PageSection title={t('tableTitle', { ns: 'malware' })}>
          <MalwareTable />
        </PageSection>
      </Box>
    </>
  )
}
