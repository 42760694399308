import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { NoData } from 'components/NoData/NoData'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchSampleRelationChildSamples,
  fetchSampleRelationChildSamplesCancelled,
  resetSampleRelationChildSamples,
  selectHasMoreSampleRelationChildSamples,
  selectSampleRelationChildSamples,
  selectSampleRelationChildSamplesLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../../type'

const SCROLLABLE_CONTAINER_ID = 'sample-detail-relation-child-samples-container'

export const ChildSamples = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sampleRelationChildSamplesLoading = useAppSelector(
    selectSampleRelationChildSamplesLoading
  )
  const sampleRelationChildSamples = useAppSelector(
    selectSampleRelationChildSamples
  )
  const hasMoreSampleRelationChildSamples = useAppSelector(
    selectHasMoreSampleRelationChildSamples
  )
  const [isScrollable, ref, node] = useIsScrollable([
    sampleRelationChildSamples,
  ])

  const handleFetchRelationChildSamples = (refetch?: boolean) => {
    dispatch(
      fetchSampleRelationChildSamples({
        offset: refetch ? 0 : sampleRelationChildSamples.length,
        sampleId: searchText,
      })
    )
  }

  useEffect(() => {
    handleFetchRelationChildSamples(true)

    return () => {
      dispatch(fetchSampleRelationChildSamplesCancelled())
      dispatch(resetSampleRelationChildSamples())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreSampleRelationChildSamples) {
      handleFetchRelationChildSamples()
    }
  }, [
    isScrollable,
    hasMoreSampleRelationChildSamples,
    node,
    sampleRelationChildSamples.length,
  ])

  if (
    sampleRelationChildSamplesLoading &&
    sampleRelationChildSamples.length === 0
  ) {
    return <SampleLoading />
  }

  if (sampleRelationChildSamples.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={sampleRelationChildSamples.length}
        next={handleFetchRelationChildSamples}
        hasMore={hasMoreSampleRelationChildSamples}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
        scrollThreshold="0px"
      >
        {sampleRelationChildSamples.map((sample) => (
          <Box
            key={sample.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <SamplePreview {...sample} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
