export interface IOSINTAPIData {
  source: string
  content: string
  created_at: number
  external_link: string
}

export interface IOSINT {
  source: string
  content: string
  createdAt: number
  externalLink: string
}

export const mapAPIOSINTsToState = (fromAPIOSINTs: IOSINTAPIData[]): IOSINT[] =>
  fromAPIOSINTs.map((fromAPIOSINT) => ({
    source: fromAPIOSINT.source,
    content: fromAPIOSINT.content,
    createdAt: fromAPIOSINT.created_at,
    externalLink: fromAPIOSINT.external_link,
  }))
