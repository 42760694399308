import { Box, Stack, useTheme } from '@mui/material'
import { Select, SelectItem } from 'components/Select/Select'
import { getPageTitlePMRReportDetailByName } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE, VULNERABILITY_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchPMReportDetail,
  fetchPMReportDetailCancelled,
  fetchPMReports,
  fetchPMReportsCancelled,
  selectIsFetchPMReportDetailLoading,
  selectPMReports,
} from 'store/slices/report'

import { ReportCategoryTabs } from '../ReportComponents/ReportCategoryTabs'
import { PMReportDetailTable } from './PMReportComponents/PMReportDetailTable'
import { PMReportDetailTableLoading } from './PMReportComponents/PMReportDetailTableLoading'

export const PMReportDetail = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const PMReports = useAppSelector(selectPMReports)
  const isFetchPMReportDetailLoading = useAppSelector(
    selectIsFetchPMReportDetailLoading
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedPMReport = searchParams.get(SearchParamKey.PM_REPORT)

  useFetchReportsCount()

  const handlePMRChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams(`${SearchParamKey.PM_REPORT}=${event.target.value}`)
  }

  useEffect(() => {
    dispatch(fetchPMReports())

    return () => {
      dispatch(fetchPMReportsCancelled())
    }
  }, [])

  useEffect(() => {
    if (selectedPMReport) {
      dispatch(fetchPMReportDetail(selectedPMReport))
    } else {
      navigate(`/${NOT_FOUND_ROUTE}`)
    }

    return () => {
      dispatch(fetchPMReportDetailCancelled())
    }
  }, [selectedPMReport])

  if (!selectedPMReport) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitlePMRReportDetailByName(selectedPMReport)}</title>
      </Helmet>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box sx={{ p: 1 }}>
          <ReportCategoryTabs reportCategoryParam={VULNERABILITY_ROUTE} />
        </Box>
        <Stack
          sx={{
            flexGrow: 1,
            flexBasis: 1,
            mx: 1,
            mb: 1,
            border: `1px solid ${theme.colors.WHITE_20}`,
            borderRadius: 0.5,
            bgcolor: theme.colors.BLACK_90,
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              borderBottom: `1px solid ${theme.colors.WHITE_20}`,
              flexShrink: 0,
              bgcolor: theme.colors.BLACK_95,
            }}
          >
            <Select
              value={selectedPMReport}
              onChange={handlePMRChange}
              width="15rem"
            >
              {PMReports.map((report) => (
                <SelectItem key={report.name} value={report.name}>
                  {report.name}
                </SelectItem>
              ))}
            </Select>
            {/* TODO: hide in May,2023 TV2.0 beta version */}
            {/* <Button
            endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
          >
            <Typography variant="buttonMText">Export CSV</Typography>
          </Button> */}
          </Box>
          {isFetchPMReportDetailLoading ? (
            <PMReportDetailTableLoading />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                flexGrow: 1,
                flexBasis: 1,
              }}
            >
              <PMReportDetailTable />
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  )
}
