import {
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  useTheme,
} from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

const RFITableCell = ({ children }: TableCellProps) => {
  const theme = useTheme()
  return (
    <TableCell
      sx={{
        height: '2.25rem',
        px: 4,
        py: 2,
        borderColor: theme.colors.WHITE_20,
        borderRight: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      {children}
    </TableCell>
  )
}

export const RFIListTableLoading = () => (
  <TableBody>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="12.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="22.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="7.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="12.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="22.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
    <TableRow>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="7.5rem" />
      </RFITableCell>
      <RFITableCell>
        <Skeleton type="body" width="4rem" />
      </RFITableCell>
    </TableRow>
  </TableBody>
)
