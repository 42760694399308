import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_IP_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/network/ips/167.179.85.233/whois" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"167.179.85.233",
  "analysis_status":true,
  "whois":"{\\n  \\"ip\\": \\"167.179.85.233\\",\\n  \\"asn\\": {\\n    \\"asn\\": \\"AS20473\\",\\n    \\"name\\": \\"The Constant Company, LLC\\",\\n    \\"type\\": \\"hosting\\",\\n    \\"route\\": \\"167.179.64.0/18\\",\\n    \\"domain\\": \\"constant.com\\"\\n  },\\n  \\"loc\\": \\"35.6090,139.7302\\",\\n  \\"city\\": \\"Ōi\\",\\n  \\"abuse\\": {\\n    \\"name\\": \\"ABUSE CHOOPALLCAP\\",\\n    \\"email\\": \\"abuse@choopa.com\\",\\n    \\"phone\\": \\"+000000000\\",\\n    \\"address\\": \\"100 Matawan Rd, Matawan NJ 07747\\",\\n    \\"country\\": \\"US\\",\\n    \\"network\\": \\"167.179.64.0/19\\"\\n  },\\n  \\"postal\\": \\"140-8508\\",\\n  \\"region\\": \\"Saitama\\",\\n  \\"company\\": {\\n    \\"name\\": \\"The Constant Company, LLC\\",\\n    \\"type\\": \\"hosting\\",\\n    \\"domain\\": \\"constant.com\\"\\n  },\\n  \\"country\\": \\"JP\\",\\n  \\"domains\\": {\\n    \\"ip\\": \\"167.179.85.233\\",\\n    \\"total\\": 1,\\n    \\"domains\\": [\\n      \\"blogitte.com\\"\\n    ]\\n  },\\n  \\"privacy\\": {\\n    \\"tor\\": false,\\n    \\"vpn\\": false,\\n    \\"proxy\\": false,\\n    \\"relay\\": false,\\n    \\"hosting\\": true,\\n    \\"service\\": \\"\\"\\n  },\\n  \\"hostname\\": \\"167.179.85.233.vultrusercontent.com\\",\\n  \\"latitude\\": \\"35.6090\\",\\n  \\"timezone\\": \\"Asia/Tokyo\\",\\n  \\"longitude\\": \\"139.7302\\",\\n  \\"ip_address\\": \\"167.179.85.233\\",\\n  \\"country_name\\": \\"Japan\\"\\n}"
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Whois'

export const APIDocumentVersionTwoIPWhois = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_IP_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title="API Version 2" />
        <H2ColorTitle title="IP" />
        <H2Title title="Whois" />
        <Description>
          With this endpoint, you can get IP registry information.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/network/ips/:address/whois" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            analysis_status
            <UnorderedList>
              <ListItem>The IP had been analyzed</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The IP address</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            whois
            <br />
            <Description>
              JSON <b>String</b>, with following keys:
              <UnorderedList>
                <ListItem>ip</ListItem>
                <ListItem>
                  asn
                  <UnorderedList>
                    <ListItem>asn</ListItem>
                    <ListItem>name</ListItem>
                    <ListItem>type</ListItem>
                    <ListItem>route</ListItem>
                    <ListItem>domain</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>Ioc</ListItem>
                <ListItem>city</ListItem>
                <ListItem>
                  abuse
                  <UnorderedList>
                    <ListItem>name</ListItem>
                    <ListItem>email</ListItem>
                    <ListItem>phone</ListItem>
                    <ListItem>address</ListItem>
                    <ListItem>country</ListItem>
                    <ListItem>network</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>postal</ListItem>
                <ListItem>region</ListItem>
                <ListItem>
                  company
                  <UnorderedList>
                    <ListItem>name</ListItem>
                    <ListItem>type</ListItem>
                    <ListItem>domain</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>country</ListItem>
                <ListItem>
                  domains
                  <UnorderedList>
                    <ListItem>page</ListItem>
                    <ListItem>total</ListItem>
                    <ListItem>domains: Array of resolved domains</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  privacy
                  <UnorderedList>
                    <ListItem>tor</ListItem>
                    <ListItem>vpn</ListItem>
                    <ListItem>proxy</ListItem>
                    <ListItem>relay</ListItem>
                    <ListItem>hosting</ListItem>
                    <ListItem>service</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>latitude</ListItem>
                <ListItem>timezone</ListItem>
                <ListItem>longitude</ListItem>
                <ListItem>ip_address</ListItem>
                <ListItem>country_name</ListItem>
              </UnorderedList>
            </Description>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
