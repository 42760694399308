import * as Sentry from '@sentry/react'
import { StatusCode } from 'constants/statusCode'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { logout } from 'store/slices/auth'
import {
  downloadIocItem,
  downloadIocItemFulfilled,
  downloadIocItemRejected,
} from 'store/slices/download'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { IIoc } from 'store/types/entityTypes/ioc'
import { downloadItem, downloadTextFile } from 'utils/download'

interface IDownloadStixParam extends IIoc {
  isSelectedDownload?: boolean
}
export const useDownloadIoc = (setCsvSource?: (arg0: string) => void) => {
  const { t } = useTranslation(['snackbar'])
  const dispatch = useAppDispatch()
  const location = useLocation()

  const handleStixDownloadClick = async ({
    stixUrl,
    id,
    name,
    isSelectedDownload,
  }: IDownloadStixParam) => {
    if (stixUrl) {
      dispatch(downloadIocItem(id))
      try {
        const data = await downloadItem({ itemUrl: stixUrl, format: 'json' })

        Sentry.captureMessage('Download IoC', 'log')

        if (data) {
          downloadTextFile({
            value: JSON.stringify(data),
            format: 'stix',
            filename: name,
          })
          dispatch(downloadIocItemFulfilled(id))
          if (!isSelectedDownload) {
            dispatch(
              pushSuccessSnackbar({
                text: t('download.downloadSuccess', { ns: 'snackbar' }),
              })
            )
          }
        }
      } catch (error) {
        if ((error as Error).message === StatusCode.UNAUTHORIZED_TOKEN) {
          dispatch(
            logout({
              reason: 'expired',
              pathBeforeLogout: location.pathname + location.search,
            })
          )
        }
        dispatch(downloadIocItemRejected())
        dispatch(
          pushAlertSnackbar({
            text: t('download.downloadFail', { ns: 'snackbar' }),
          })
        )
      }
    }
  }

  const handleCSVDownloadClick = async ({ csvUrl, id }: IIoc) => {
    if (csvUrl) {
      dispatch(downloadIocItem(id))
      try {
        const data = await downloadItem({ itemUrl: csvUrl, format: 'text' })
        if (data) {
          if (setCsvSource) setCsvSource(data)
        }
        dispatch(downloadIocItemFulfilled(id))
        dispatch(
          pushSuccessSnackbar({
            text: t('download.downloadSuccess', { ns: 'snackbar' }),
          })
        )
      } catch (error) {
        if ((error as Error).message === StatusCode.UNAUTHORIZED_TOKEN) {
          dispatch(
            logout({
              reason: 'expired',
              pathBeforeLogout: location.pathname + location.search,
            })
          )
        }
        dispatch(downloadIocItemRejected())
        dispatch(
          pushAlertSnackbar({
            text: t('download.downloadFail', { ns: 'snackbar' }),
          })
        )
      }
    }
  }

  return { handleStixDownloadClick, handleCSVDownloadClick }
}
