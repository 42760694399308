import { AdversaryTabContent } from 'components/Adversary/AdversaryTabContent'
import { MalwareTabContent } from 'components/Malware/MalwareTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { TechniqueTabContent } from 'components/Technique/TechniqueTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectTabAdversaryCount,
  selectTabAdversaryCountLoading,
  selectTabMalwareCount,
  selectTabMalwareCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
  selectTabTechniqueCount,
  selectTabTechniqueCountLoading,
} from 'store/slices/tabContent'

import { CommunityContent } from './CommunityContent/CommunityContent'
import { PreviewContent } from './PreviewContent/PreviewContent'
import { RelationContent } from './RelationContent/RelationContent'
import { SampleInfoContent } from './SampleInfoContent/SampleInfoContent'
import { SampleSandboxContent } from './SandboxContent/SandboxContent'
import { ISampleTabContentProps } from './type'

export interface ISampleCategoryTab {
  key: string
  name: string
  component: FC<ISampleTabContentProps>
  count?: number
  isLoading?: boolean
  hiddenEmpty?: boolean
  showCount: boolean
}

export const useSampleCategoryTabs = (): ISampleCategoryTab[] => {
  const { t } = useTranslation(['sample'])
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)
  const tabAdversaryCountLoading = useAppSelector(
    selectTabAdversaryCountLoading
  )
  const tabAdversaryCount = useAppSelector(selectTabAdversaryCount)
  const tabMalwareCountLoading = useAppSelector(selectTabMalwareCountLoading)
  const tabMalwareCount = useAppSelector(selectTabMalwareCount)

  const tabTechniqueCountLoading = useAppSelector(
    selectTabTechniqueCountLoading
  )
  const tabTechniqueCount = useAppSelector(selectTabTechniqueCount)

  const tabs: ISampleCategoryTab[] = [
    {
      key: 'sample-info',
      name: t('categoryTabs.sampleInfo', { ns: 'sample' }),
      component: SampleInfoContent,
      showCount: false,
    },
    {
      key: 'reports',
      name: t('categoryTabs.reports', { ns: 'sample' }),
      component: ReportTabContent,
      count: tabReportCount,
      hiddenEmpty: true,
      showCount: true,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'adversaries',
      name: t('categoryTabs.adversaries', { ns: 'sample' }),
      component: AdversaryTabContent,
      count: tabAdversaryCount,
      hiddenEmpty: true,
      showCount: true,
      isLoading: tabAdversaryCountLoading,
    },
    {
      key: 'malware',
      name: t('categoryTabs.malware', { ns: 'sample' }),
      component: MalwareTabContent,
      count: tabMalwareCount,
      hiddenEmpty: true,
      showCount: true,
      isLoading: tabMalwareCountLoading,
    },
    {
      key: 'techniques',
      name: t('categoryTabs.capabilities', { ns: 'sample' }),
      component: TechniqueTabContent,
      count: tabTechniqueCount,
      hiddenEmpty: false,
      showCount: true,
      isLoading: tabTechniqueCountLoading,
    },
    {
      key: 'relation',
      name: t('categoryTabs.relation.label', { ns: 'sample' }),
      component: RelationContent,
      showCount: false,
    },
    {
      key: 'sandbox',
      name: t('categoryTabs.sandbox', { ns: 'sample' }),
      component: SampleSandboxContent,
      showCount: false,
    },
    {
      key: 'preview',
      name: t('categoryTabs.preview', { ns: 'sample' }),
      component: PreviewContent,
      showCount: false,
    },
    {
      key: 'community',
      name: t('categoryTabs.community.label', { ns: 'sample' }),
      component: CommunityContent,
      showCount: false,
    },
  ]

  return tabs
}
