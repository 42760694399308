import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { NoData } from 'components/NoData/NoData'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchDomainRelationSamples,
  fetchDomainRelationSamplesCancelled,
  resetDomainRelationSamples,
  selectDomainRelationSamples,
  selectDomainRelationSamplesLoaidng,
  selectHasMoreDomainRelationSamples,
} from 'store/slices/domain'
import {
  fetchIPRelationSamples,
  fetchIPRelationSamplesCancelled,
  resetIPRelationSamples,
  selectHasMoreIPRelationSamples,
  selectIPRelationSamples,
  selectIPRelationSamplesLoaidng,
} from 'store/slices/ip'
import { ISample } from 'store/types/entityTypes/sample'

import { IContentProps } from '../config'

const SCROLLABLE_CONTAINER_ID = 'relation-samples'

export const RelationSamples = ({ searchText, detailType }: IContentProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isIP = detailType === 'ip'
  const ipRelationSamplesLoading = useAppSelector(
    selectIPRelationSamplesLoaidng
  )
  const domainRelationSamplesLoading = useAppSelector(
    selectDomainRelationSamplesLoaidng
  )
  const ipRelationSamples = useAppSelector(selectIPRelationSamples)
  const domainRelationSamples = useAppSelector(selectDomainRelationSamples)
  const hasMoreIPRelationSamples = useAppSelector(
    selectHasMoreIPRelationSamples
  )
  const hasMoreDomainRelationSamples = useAppSelector(
    selectHasMoreDomainRelationSamples
  )

  const relationSamplesLoading: boolean = isIP
    ? ipRelationSamplesLoading
    : domainRelationSamplesLoading
  const relationSamples: ISample[] = isIP
    ? ipRelationSamples
    : domainRelationSamples
  const hasMoreRelationSamples: boolean = isIP
    ? hasMoreIPRelationSamples
    : hasMoreDomainRelationSamples

  const [isScrollable, ref, node] = useIsScrollable([relationSamples])

  const handleFetchRelationSamples = (refetch?: boolean) => {
    if (isIP) {
      dispatch(
        fetchIPRelationSamples({
          offset: refetch ? 0 : relationSamples.length,
          ip: searchText,
        })
      )
    } else {
      dispatch(
        fetchDomainRelationSamples({
          offset: refetch ? 0 : relationSamples.length,
          domain: searchText,
        })
      )
    }
  }

  const handleFetchRelationSamplesCleanup = () => {
    if (isIP) {
      dispatch(fetchIPRelationSamplesCancelled())
      dispatch(resetIPRelationSamples())
    } else {
      dispatch(fetchDomainRelationSamplesCancelled())
      dispatch(resetDomainRelationSamples())
    }
  }

  useEffect(() => {
    if (searchText) {
      handleFetchRelationSamples(true)
    }

    return () => {
      handleFetchRelationSamplesCleanup()
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreRelationSamples) {
      handleFetchRelationSamples()
    }
  }, [isScrollable, hasMoreRelationSamples, node, relationSamples.length])

  if (relationSamplesLoading && relationSamples.length === 0) {
    return <SampleLoading />
  }

  if (relationSamples.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={relationSamples.length}
        next={handleFetchRelationSamples}
        hasMore={hasMoreRelationSamples}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
      >
        {relationSamples.map((sample) => (
          <Box
            key={sample.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <SamplePreview {...sample} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
