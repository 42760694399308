import Resizer from 'react-image-file-resizer'

export const resizeAvatarImageFile = (file: File) =>
  new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'PNG',
      100,
      0,
      (uri: File) => {
        resolve(uri)
      },
      'file'
    )
  })
