import { Box, useTheme } from '@mui/material'
import { NewLabel } from 'components/Label/Label'
import {
  NavAccordion,
  NavAccordionContent,
} from 'components/Navbar/NavAccordion'
import {
  API_DOC_AUTHENTICATION_ROUTE,
  API_DOC_INTRODUCTION_ROUTE,
  API_DOC_RETRIEVE_ACCESS_TOKEN_ROUTE,
  API_DOC_ROUTE,
  API_DOC_V2_ADVERSARY_CAPABILITIES_ROUTE,
  API_DOC_V2_ADVERSARY_LIST_ROUTE,
  API_DOC_V2_ADVERSARY_MALWARE_ROUTE,
  API_DOC_V2_ADVERSARY_REPORTS_ROUTE,
  API_DOC_V2_ADVERSARY_ROUTE,
  API_DOC_V2_ADVERSARY_SAMPLES_ROUTE,
  API_DOC_V2_ADVERSARY_SEARCH_ROUTE,
  API_DOC_V2_CAPABILITY_ADVERSARIES_ROUTE,
  API_DOC_V2_CAPABILITY_MALWARE_ROUTE,
  API_DOC_V2_CAPABILITY_MITRE_TATICS_ROUTE,
  API_DOC_V2_CAPABILITY_MITRE_TECHNIQUES_ROUTE,
  API_DOC_V2_CAPABILITY_REPORTS_ROUTE,
  API_DOC_V2_CAPABILITY_ROUTE,
  API_DOC_V2_CAPABILITY_SAMPLES_ROUTE,
  API_DOC_V2_CAPABILITY_SEARCH_ROUTE,
  API_DOC_V2_DOMAIN_ADVERSARIES_ROUTE,
  API_DOC_V2_DOMAIN_ANALYSIS_STATUS_ROUTE,
  API_DOC_V2_DOMAIN_DNS_RECORDS_ROUTE,
  API_DOC_V2_DOMAIN_GET_DOMAIN_INFORMATION_ROUTE,
  API_DOC_V2_DOMAIN_OSINT_POSTS_ROUTE,
  API_DOC_V2_DOMAIN_REPORTS_ROUTE,
  API_DOC_V2_DOMAIN_ROUTE,
  API_DOC_V2_DOMAIN_SAMPLES_ROUTE,
  API_DOC_V2_DOMAIN_SEARCH_DOMAINS_ROUTE,
  API_DOC_V2_DOMAIN_WHOIS_ROUTE,
  API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE,
  API_DOC_V2_IOC_DOWNLOAD_ROUTE,
  API_DOC_V2_IOC_LIST_ROUTE,
  API_DOC_V2_IOC_ROUTE,
  API_DOC_V2_IP_ADVERSARIES_ROUTE,
  API_DOC_V2_IP_ANALYSIS_STATUS_ROUTE,
  API_DOC_V2_IP_DNS_RECORDS_ROUTE,
  API_DOC_V2_IP_GET_IP_INFORMATION_ROUTE,
  API_DOC_V2_IP_OSINT_POSTS_ROUTE,
  API_DOC_V2_IP_REPORTS_ROUTE,
  API_DOC_V2_IP_ROUTE,
  API_DOC_V2_IP_SAMPLES_ROUTE,
  API_DOC_V2_IP_SEARCH_IPS_ROUTE,
  API_DOC_V2_IP_WHOIS_ROUTE,
  API_DOC_V2_LIST_REPORTS_ROUTE,
  API_DOC_V2_MALWARE_ADVERSARIES_ROUTE,
  API_DOC_V2_MALWARE_CAPABILITIES_ROUTE,
  API_DOC_V2_MALWARE_LIST_ROUTE,
  API_DOC_V2_MALWARE_REPORTS_ROUTE,
  API_DOC_V2_MALWARE_ROUTE,
  API_DOC_V2_MALWARE_SAMPLES_ROUTE,
  API_DOC_V2_MALWARE_SEARCH_ROUTE,
  API_DOC_V2_PMR_GET_PMR_ROUTE,
  API_DOC_V2_PMR_LIST_PMR_ROUTE,
  API_DOC_V2_PMR_ROUTE,
  API_DOC_V2_ROUTE,
  API_DOC_V2_SAMPLE_ADVERSARIES_ROUTE,
  API_DOC_V2_SAMPLE_CAPABILITIES_ROUTE,
  API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE,
  API_DOC_V2_SAMPLE_MALWARE_ROUTE,
  API_DOC_V2_SAMPLE_META_ROUTE,
  API_DOC_V2_SAMPLE_PREVIEW_ROUTE,
  API_DOC_V2_SAMPLE_RELATIONSHIP_ROUTE,
  API_DOC_V2_SAMPLE_REPORTS_ROUTE,
  API_DOC_V2_SAMPLE_ROUTE,
  API_DOC_V2_SAMPLE_SANDBOX_ROUTE,
  API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE,
  API_DOC_V2_SAMPLE_UPLOAD_SAMPLE_ROUTE,
} from 'constants/routeParams'
import { useLocation } from 'react-router-dom'

// const ComingSoonTag = () => {
//   const theme = useTheme()
//   return <OutlineLabel text="Coming soon" color={theme.colors.DISABLE} />
// }

export const AccordionNavbar = () => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const levelOneBorderBottom = `1px solid ${theme.colors.WHITE_20}`

  return (
    <Box
      sx={{
        minWidth: '17.5rem',
        maxWidth: '17.5rem',
        height: '100%',
        overflow: 'auto',
        borderRight: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <NavAccordion
        text="Authentication"
        defaultExpanded={pathname.includes(API_DOC_AUTHENTICATION_ROUTE)}
        borderBottom={levelOneBorderBottom}
      >
        <>
          <NavAccordionContent
            text="Introduction"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_INTRODUCTION_ROUTE}`}
          />
          <NavAccordionContent
            text="Retrieve access_token"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_RETRIEVE_ACCESS_TOKEN_ROUTE}`}
          />
        </>
      </NavAccordion>
      <NavAccordion
        text="API Version 2"
        defaultExpanded={pathname.includes(API_DOC_V2_ROUTE)}
        borderBottom={levelOneBorderBottom}
      >
        {/* <NavAccordionContent
            text="Introduction"
            activeKey={`/${API_DOC_ROUTE}/${API_VERSION_TWO_ROUTE}/${INTRODUCTION_ROUTE}`}
          /> */}
        <NavAccordion
          text="Sample"
          defaultExpanded={pathname.includes(API_DOC_V2_SAMPLE_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="Upload Sample"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_UPLOAD_SAMPLE_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="List Uploaded Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Search Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Sample Meta"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_META_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_REPORTS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Adversaries"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_ADVERSARIES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Malware"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_MALWARE_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Capabilities"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_CAPABILITIES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Relationship"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_RELATIONSHIP_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Sandbox"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_SANDBOX_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Preview"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_PREVIEW_ROUTE}`}
            indentLevel={2}
          />
        </NavAccordion>
        <NavAccordion
          text="IP"
          defaultExpanded={pathname.includes(API_DOC_V2_IP_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="Analysis Status"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_ANALYSIS_STATUS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Search IPs"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_SEARCH_IPS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Get IP Information"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_GET_IP_INFORMATION_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Whois"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_WHOIS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_REPORTS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Adversaries"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_ADVERSARIES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="DNS Records"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_DNS_RECORDS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="OSINT Posts"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IP_ROUTE}/${API_DOC_V2_IP_OSINT_POSTS_ROUTE}`}
            indentLevel={2}
          />
        </NavAccordion>
        <NavAccordion
          text="Domain"
          defaultExpanded={pathname.includes(API_DOC_V2_DOMAIN_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="Analysis Status"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_ANALYSIS_STATUS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Search Domains"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_SEARCH_DOMAINS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Get Domain Information"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_GET_DOMAIN_INFORMATION_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Whois"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_WHOIS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_REPORTS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Adversaries"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_ADVERSARIES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="DNS Records"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_DNS_RECORDS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="OSINT Posts"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_DOMAIN_ROUTE}/${API_DOC_V2_DOMAIN_OSINT_POSTS_ROUTE}`}
            indentLevel={2}
          />
        </NavAccordion>
        <NavAccordion
          text="Intelligence Reports"
          defaultExpanded={pathname.includes(
            API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE
          )}
          indentLevel={2}
        >
          <NavAccordionContent
            text="List Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE}/${API_DOC_V2_LIST_REPORTS_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
        </NavAccordion>
        <NavAccordion
          text="Patch Management Report (PMR)"
          defaultExpanded={pathname.includes(API_DOC_V2_IOC_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="List PMR"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_PMR_ROUTE}/${API_DOC_V2_PMR_LIST_PMR_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Get PMR"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_PMR_ROUTE}/${API_DOC_V2_PMR_GET_PMR_ROUTE}`}
            indentLevel={2}
          />
        </NavAccordion>
        <NavAccordion
          text="IoC Bundles"
          defaultExpanded={pathname.includes(API_DOC_V2_IOC_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="List IoC Bundles"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IOC_ROUTE}/${API_DOC_V2_IOC_LIST_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Download IoC Bundles"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_IOC_ROUTE}/${API_DOC_V2_IOC_DOWNLOAD_ROUTE}`}
            indentLevel={2}
          />
        </NavAccordion>
        <NavAccordion
          text="Adversary"
          defaultExpanded={pathname.includes(API_DOC_V2_ADVERSARY_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="List"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_LIST_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Search"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_SEARCH_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_REPORTS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Malware"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_MALWARE_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Capabilities"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_ADVERSARY_ROUTE}/${API_DOC_V2_ADVERSARY_CAPABILITIES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
        </NavAccordion>
        <NavAccordion
          text="Malware"
          defaultExpanded={pathname.includes(API_DOC_V2_MALWARE_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="List"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_LIST_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Search"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_SEARCH_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_REPORTS_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Adversaries"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_ADVERSARIES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_SAMPLES_ROUTE}`}
            indentLevel={2}
          />
          <NavAccordionContent
            text="Capabilities"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_MALWARE_ROUTE}/${API_DOC_V2_MALWARE_CAPABILITIES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
        </NavAccordion>
        <NavAccordion
          text="Capability"
          defaultExpanded={pathname.includes(API_DOC_V2_CAPABILITY_ROUTE)}
          indentLevel={2}
        >
          <NavAccordionContent
            text="MITRE Tactics"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_MITRE_TATICS_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="MITRE Techniques"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_MITRE_TECHNIQUES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Search"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_SEARCH_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Reports"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_REPORTS_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Adversaries"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_ADVERSARIES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Samples"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_SAMPLES_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
          <NavAccordionContent
            text="Malware"
            activeKey={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_CAPABILITY_ROUTE}/${API_DOC_V2_CAPABILITY_MALWARE_ROUTE}`}
            indentLevel={2}
            endTag={<NewLabel text="New" />}
          />
        </NavAccordion>
      </NavAccordion>
    </Box>
  )
}
