import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import ThreatLandscapeBackgroundImage from 'assets/threatLandscape/threat-landscape-background.jpg'
import { PAGE_TITLE_THREAT_LANDSCAPE } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { TargetBlock } from './TargetBlock'

export const ThreatLandscapePage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['threatLandscapes'])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_THREAT_LANDSCAPE}</title>
      </Helmet>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          p: 1,
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            border: `1px solid ${theme.colors.WHITE_20}`,
            borderRadius: 0.5,
          }}
        >
          <Stack
            data-testid="threat-landscape-page"
            sx={{
              px: 4,
              py: 6,
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(${ThreatLandscapeBackgroundImage})`,
              backgroundSize: 'cover',
              color: theme.colors.WHITE,
            }}
          >
            <Typography variant="title" sx={{ px: 4 }}>
              {t('entryTitle', { ns: 'threatLandscapes' })}
            </Typography>
            <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
            <Box sx={{ px: 4, mt: 2, width: '45rem' }}>
              <Typography variant="bodySmall">
                {t('entryDescription', { ns: 'threatLandscapes' })}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              height: '100%',
              bgcolor: theme.colors.BLACK_90,
              p: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexGrow: 1,
                flexBasis: 0,
                overflowY: 'auto',
              }}
            >
              <TargetBlock
                title={t('targetRegions.sectionTitle', {
                  ns: 'threatLandscapes',
                })}
                targetType="region"
              />
              <TargetBlock
                title={t('targetCountries.sectionTitle', {
                  ns: 'threatLandscapes',
                })}
                targetType="country"
              />
              <TargetBlock
                title={t('targetIndustries.sectionTitle', {
                  ns: 'threatLandscapes',
                })}
                targetType="industry"
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
