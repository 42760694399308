import { SnackbarOrigin, useSnackbar,VariantType } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { removeSnackbar, selectSnackbar } from 'store/slices/snackbar'

import { useAppSelector } from './useReduxHooks'

const useShowSnackbar = () => {
  const dispatch = useDispatch()

  const snackbars = useAppSelector(selectSnackbar)
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const [displayList, setDisplayList] = useState<number[]>([])

  const storeDisplayed = (id: number) => {
    setDisplayList((prev) => prev.concat(id))
  }

  const removeDisplayed = (id: number) => {
    setDisplayList((prev) => prev.filter((key) => id !== key))
  }

  const anchorMap: Record<VariantType, SnackbarOrigin> = {
    default: { vertical: 'top', horizontal: 'center' },
    success: { vertical: 'bottom', horizontal: 'center' },
    info: { vertical: 'top', horizontal: 'center' },
    warning: { vertical: 'top', horizontal: 'center' },
    error: { vertical: 'top', horizontal: 'center' },
  }

  useEffect(() => {
    snackbars.snackbar.forEach(({ text, type, id }) => {
      if (displayList.includes(id)) return
      if (!text) {
        dispatch(removeSnackbar(id))
        return
      }

      enqueueSnackbar(text, {
        style: { width: type === 'success' ? '15rem' : '45rem' },
        key: id,
        variant: type,
        anchorOrigin: anchorMap[type],
        persist: type !== 'success',
        onExited: () => {
          dispatch(removeSnackbar(id))
          removeDisplayed(id)
        },
      })

      storeDisplayed(id)
    })
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch])
}

export default useShowSnackbar
