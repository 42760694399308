import { UserCommentTabContent } from 'components/UserComments/UserCommentTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectCommentLoading, selectCommentsCount } from 'store/slices/comment'
import {
  selectSampleOSINTs,
  selectSampleOSINTsLoading,
} from 'store/slices/sampleDetail'

import { OSINT } from './SubContents/OSINT'

interface ICommunityTab {
  key: string
  name: string
  component: FC<{ searchText: string }>
  count: number
  showCount: boolean
  isLoading: boolean
}

export const useCommunityTabs = (): ICommunityTab[] => {
  const { t } = useTranslation(['sample'])
  const sampleOSINTsLoading = useAppSelector(selectSampleOSINTsLoading)
  const sampleOSINTs = useAppSelector(selectSampleOSINTs)

  const isCommentLoading = useAppSelector(selectCommentLoading)
  const tabCommentCount = useAppSelector(selectCommentsCount)

  return [
    {
      key: 'osint',
      name: t('categoryTabs.community.osint', { ns: 'sample' }),
      component: OSINT,
      isLoading: sampleOSINTsLoading,
      showCount: true,
      count: sampleOSINTs.length,
    },
    {
      key: 'comments',
      name: t('categoryTabs.community.comments', { ns: 'sample' }),
      component: UserCommentTabContent,
      isLoading: isCommentLoading,
      showCount: true,
      count: tabCommentCount,
    },
  ]
}
