import { TPMReportDetailKeys } from 'pages/ReportsPage/Vulnerability/PMReportComponents/PMReportDetailTable'
import { useTranslation } from 'react-i18next'

interface IHeadCell {
  enableFilter: boolean
  enableOrder: boolean
  id: TPMReportDetailKeys
  label: string
  widthRatio: number
  minWidth: number
}

export const usePMReportDetailTableHeadCells = () => {
  const { t } = useTranslation(['report'])
  const headCells: readonly IHeadCell[] = [
    {
      id: 'id',
      enableFilter: true,
      enableOrder: true,
      label: t('pmReportsDetail.vulnId', { ns: 'report' }),
      widthRatio: 12,
      minWidth: 148,
    },
    {
      id: 'vendor',
      enableFilter: true,
      enableOrder: true,
      label: t('pmReportsDetail.vendor', { ns: 'report' }),
      widthRatio: 11,
      minWidth: 144,
    },
    {
      id: 'product',
      enableFilter: true,
      enableOrder: true,
      label: t('pmReportsDetail.product', { ns: 'report' }),
      widthRatio: 10,
      minWidth: 142,
    },
    {
      id: 'cvss',
      enableFilter: false,
      enableOrder: true,
      label: t('pmReportsDetail.cvss', { ns: 'report' }),
      widthRatio: 7,
      minWidth: 90,
    },
    {
      id: 'description',
      enableFilter: false,
      enableOrder: false,
      label: t('pmReportsDetail.description', { ns: 'report' }),
      widthRatio: 15,
      minWidth: 196,
    },
    {
      id: 'threatLevel',
      enableFilter: false,
      enableOrder: true,
      label: t('pmReportsDetail.threatLv', { ns: 'report' }),
      widthRatio: 8,
      minWidth: 110,
    },
    {
      id: 'publiclyDisclosed',
      enableFilter: false,
      enableOrder: true,
      label: t('pmReportsDetail.publiclyDisclosed', { ns: 'report' }),
      widthRatio: 9,
      minWidth: 126,
    },
    {
      id: 'exploited',
      enableFilter: false,
      enableOrder: true,
      label: t('pmReportsDetail.exploited', { ns: 'report' }),
      widthRatio: 9,
      minWidth: 126,
    },
    {
      id: 'patch',
      enableFilter: false,
      enableOrder: false,
      label: t('pmReportsDetail.patch', { ns: 'report' }),
      widthRatio: 9,
      minWidth: 126,
    },
    {
      id: 'updatedAt',
      enableFilter: false,
      enableOrder: true,
      label: t('pmReportsDetail.updateDate', { ns: 'report' }),
      widthRatio: 8,
      minWidth: 110,
    },
  ]

  const widthRatioSummary = headCells.reduce(
    (accumulatedWidth, headCell) => accumulatedWidth + headCell.widthRatio,
    0
  )

  return { headCells, widthRatioSummary }
}
