import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { IDdwReportMeta } from 'store/types/entityTypes/ddw'

const KEYWORD_MAX_LENGTH = 20
interface IKeywordCardProps {
  meta: IDdwReportMeta
  handleKeywordChange: (keyword: string) => void
  selected: boolean
}
export const KeywordCard = ({
  meta,
  handleKeywordChange,
  selected,
}: IKeywordCardProps) => {
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  return (
    <Stack
      onClick={() => handleKeywordChange(meta.keyword)}
      sx={[
        {
          height: '100%',
          border: selected
            ? `1.5px solid ${theme.colors.PRIMARY_80}`
            : `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          cursor: 'pointer',
          bgcolor: selected ? theme.colors.PRIMARY_20 : 'transparent',
          position: 'relative',
          '&:hover': {
            bgcolor: theme.colors.BLACK_50,
          },
          '&:active': {
            bgcolor: theme.colors.BLACK_95,
          },
        },
      ]}
    >
      {selected && (
        <Box
          sx={{
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: `8px solid ${theme.colors.PRIMARY_80}`,
            position: 'absolute',
            bottom: '-8px',
            left: 'calc(50% - 4px)',
          }}
        />
      )}
      <Box
        sx={{
          minHeight: '2rem',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          px: 4,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {meta.keyword.length > KEYWORD_MAX_LENGTH ? (
            <Tooltip title={meta.keyword}>
              <Typography
                variant="title"
                sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
              >
                {meta.keyword.slice(0, KEYWORD_MAX_LENGTH)}...
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              variant="title"
              sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
            >
              {meta.keyword}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          display: 'flex',
          gap: 1,
        }}
      >
        <Stack sx={{ gap: 1, flex: '1 1 0%' }}>
          <Typography
            variant="assistiveText"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('ddwAlert.keyword.credentialsLeak', { ns: 'ddw' })}
          </Typography>
          <Typography
            variant="titleBig"
            sx={{
              color:
                meta.clCount > 0 ? theme.colors.TLPRed : theme.colors.TLPGreen,
            }}
          >
            {meta.clCount.toLocaleString('en-us')}
          </Typography>
        </Stack>
        <Stack sx={{ gap: 1, flex: '1 1 0%' }}>
          <Typography
            variant="assistiveText"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('ddwAlert.keyword.compromisedData', { ns: 'ddw' })}
          </Typography>
          <Typography
            variant="titleBig"
            sx={{
              color:
                meta.cdsCount > 0 ? theme.colors.TLPRed : theme.colors.TLPGreen,
            }}
          >
            {meta.cdsCount.toLocaleString('en-us')}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}
