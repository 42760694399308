import {
  Box,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as TocIcon } from 'assets/report/icons/toc.svg'
import {
  ListItem,
  NumberListItem,
  OrderedList,
  UnorderedList,
} from 'components/List/List'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import { PAGE_TITLE_DDW_TAILORED_CHINESE_SAMPLE_REPORT } from 'constants/pageTitle'
import { useGoBack } from 'hooks/useGoBack'
import Image from 'mui-image'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import SampleReportImage from './DDWTailoredSampleReport.jpeg'
import KeywordImage1 from './keyword-image-1.png'
import KeywordImage2 from './keyword-image-2.png'
import KeywordImage3 from './keyword-image-3.png'
import KeywordImage4 from './keyword-image-4.png'

const BACK_TO_TOP = 'backToTop'
const GO_TO_BOTTOM = 'goToBottom'

const INFO_PADDING = '1.5rem'

interface IToc {
  text: string
  anchorId: string
}

const tocList: IToc[] = [
  {
    text: '壹、綜覽',
    anchorId: 'executive-summary',
  },
  {
    text: '貳、執行範圍',
    anchorId: 'scope',
  },
  {
    text: '參、外洩資料清單',
    anchorId: 'list-of-leaked-data',
  },
  {
    text: '肆、風險評估及建議',
    anchorId: 'risk-assessment-and-recommendations',
  },
]

export const DDWTailoredChineseReportSample = () => {
  useGoBack()
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const { search, hash } = useLocation()
  const [selectedTitleId, setSelectedTitleId] = useState<string>(
    hash.replace('#', '')
  )

  const handleTocTitleClick = (titleItem: IToc) => {
    const { anchorId } = titleItem
    setSelectedTitleId(anchorId)
    if (anchorId === GO_TO_BOTTOM || anchorId === BACK_TO_TOP) {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DDW_TAILORED_CHINESE_SAMPLE_REPORT}</title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '54.125rem 23.875rem',
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          backgroundColor: theme.colors.BLACK_95,
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack sx={{ px: 28, pt: 4, pb: 10 }}>
          <>
            <Stack id={BACK_TO_TOP} sx={{ gap: 2 }}>
              <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
                深暗網監控分析報告
              </Typography>
              <Typography
                variant="reportInlineTextSmall"
                sx={{ color: theme.colors.WHITE_60 }}
              >
                2023-10-XX
              </Typography>
              <Image
                src={SampleReportImage}
                alt="sample-report"
                height="26.875rem"
                fit="cover"
              />
              <Box
                sx={{
                  bgcolor: theme.colors.BLACK_90,
                  p: 4,
                  borderLeft: `4px solid ${theme.colors.PRIMARY_20}`,
                  borderRadius: 0.5,
                }}
              >
                <Typography
                  variant="reportH3"
                  sx={{ color: theme.colors.PRIMARY }}
                >
                  報告目錄
                </Typography>

                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.PRIMARY }}
                  >
                    壹
                  </Typography>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    綜覽
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.PRIMARY }}
                  >
                    貳
                  </Typography>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    執行範圍
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.PRIMARY }}
                  >
                    參
                  </Typography>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    外洩資料清單
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.PRIMARY }}
                  >
                    肆
                  </Typography>
                  <Typography
                    variant="reportInlineText"
                    sx={{ color: theme.colors.WHITE }}
                  >
                    風險評估及建議
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="executive-summary"
              >
                壹、綜覽
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                本次沒有勒索軟體的相關發現。
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                外洩列表：
              </Typography>
              <UnorderedList>
                <ListItem>
                  外洩憑證：最近一批洩漏的資料發生在2023.09.15。
                </ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>電子信箱</TableCell>
                      <TableCell>密碼</TableCell>
                      <TableCell>流出時間</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>aaaaaaa@***.com</TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>15/9/2023 12:48</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <UnorderedList>
                <ListItem>
                  遭竊資料：最近的一筆連線紀錄發生在2023.09.23。
                </ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>Host</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        使用者帳號
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>密碼</TableCell>
                      <TableCell>流出時間</TableCell>
                      <TableCell>IP</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell sx={{ wordBreak: 'break-word' }}>
                        https://bbb.***.com.tw/cccc50/Logints.asp
                      </TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>###</TableCell>
                      <TableCell>23/9/2023 12:48</TableCell>
                      <TableCell>**.**.**.179</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                搜尋結果綜整
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell />
                      <TableCell>***[.]com.tw</TableCell>
                      <TableCell>***life[.]com</TableCell>
                      <TableCell>*******[.]com</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>外洩憑證</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>45</TableCell>
                      <TableCell>64</TableCell>
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell>遭竊資料</TableCell>
                      <TableCell>26</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>159</TableCell>
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                圖表/數據
              </Typography>
              <UnorderedList>
                <ListItem>外洩帳號次數: X</ListItem>
                <ListItem>外洩服務數量 (Host): Y</ListItem>
                <ListItem>外洩郵件地址數量: Z</ListItem>
              </UnorderedList>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="scope"
              >
                貳、執行範圍
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                TeamT5 搜尋「XX集團」與其相關子公司「XX 證券」、「XX
                投信」、「XX 商業銀行」、「XX 人壽」的網域：
              </Typography>
              <UnorderedList>
                <ListItem>XX 金控、XX 證券: ***[.]com.tw</ListItem>
                <ListItem>XX 投信: ***life[.]com</ListItem>
                <ListItem>XX 商業銀行: *******[.]com</ListItem>
              </UnorderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                含括時間：
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                2023年09月01日至2023年09月30日
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                搜尋到上述網域的外洩列表，根據類型分成兩部分：
              </Typography>
              <OrderedList>
                <NumberListItem>
                  外洩憑證：是透過 Combo Lists 被外洩到暗網/地下市場的帳號密碼
                  (Credentials)，資料包含電子郵件和密碼。
                </NumberListItem>
                <NumberListItem>
                  遭竊資料：是被入侵的裝置(Compromised
                  Device)所外洩的資料，資料包含連線紀錄、帳戶名稱、密碼*、和
                  IP。
                </NumberListItem>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                暗網資訊監控：
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                針對X個關鍵字在暗網上進行即時偵測，提供與之相關情資。此類情資包含勒索軟體、暗網市集、與暗網上流傳與相關之外洩文件與電子郵件。
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>項次</TableCell>
                      <TableCell>關鍵字</TableCell>
                      <TableCell>項次</TableCell>
                      <TableCell>關鍵字</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>1</TableCell>
                      <TableCell>***[.]com.tw</TableCell>
                      <TableCell>6</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell>2</TableCell>
                      <TableCell>***life[.]com</TableCell>
                      <TableCell>7</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={2}>
                      <TableCell>3</TableCell>
                      <TableCell>*******[.]com</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={3}>
                      <TableCell>4</TableCell>
                      <TableCell>杜浦數位安全</TableCell>
                      <TableCell>9</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={4}>
                      <TableCell>5</TableCell>
                      <TableCell />
                      <TableCell>10</TableCell>
                      <TableCell />
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportCaption"
                sx={{ color: theme.colors.WHITE_60, textAlign: 'center' }}
              >
                表一：本篇示範報告使用之關鍵字詞組
              </Typography>
              <UnorderedList>
                <ListItem>關鍵字</ListItem>
              </UnorderedList>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCell>項次</TableCell>
                      <TableCell>關鍵字</TableCell>
                      <TableCell>查詢範圍</TableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyRow index={0}>
                      <TableCell>4</TableCell>
                      <TableCell>杜浦數位安全</TableCell>
                      <TableCell />
                    </TableBodyRow>
                    <TableBodyRow index={1}>
                      <TableCell sx={{ height: '3.1125rem' }} />
                      <TableCell />
                      <TableCell />
                    </TableBodyRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="list-of-leaked-data"
              >
                參、外洩資料清單
              </Typography>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                外洩憑證
              </Typography>
              <OrderedList>
                <NumberListItem>***[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>電子信箱</TableCell>
                        <TableCell>密碼</TableCell>
                        <TableCell>流出時間</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell>aaaaaaa@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>xxxxxx@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell>ppppppppp@***.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>***life[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>電子信箱</TableCell>
                        <TableCell>密碼</TableCell>
                        <TableCell>流出時間</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell>ffff@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>uuuuuu@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell>qqqq@***life.com</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                遭竊資料
              </Typography>
              <OrderedList>
                <NumberListItem>***[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          使用者帳號
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          密碼
                        </TableCell>
                        <TableCell>流出時間</TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.***.com.tw/cccc50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>23/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://global.***.com.tw/kkkweb/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.***.com.tw/ooweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>***life[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          使用者帳號
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          密碼
                        </TableCell>
                        <TableCell>流出時間</TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://etrade.***life.com /hweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell>
                          https://global.***life.com /nexuswe/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://etrade.***life.com/yyweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <NumberListItem>*******[.]com</NumberListItem>
                <TableContainer sx={{ py: 2 }}>
                  <Table>
                    <TableHead>
                      <TableHeaderRow>
                        <TableCell>Host</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          使用者帳號
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          密碼
                        </TableCell>
                        <TableCell>流出時間</TableCell>
                        <TableCell>IP</TableCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyRow index={0}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://ggggg.*******.com/iiweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>15/9/2023 12:48</TableCell>
                        <TableCell>**.**.**.179</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={1}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://kkkk.*******.com/newebde/login/loginpanel
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 17:00</TableCell>
                        <TableCell>**.**.**.75</TableCell>
                      </TableBodyRow>
                      <TableBodyRow index={2}>
                        <TableCell sx={{ wordBreak: 'break-word' }}>
                          https://bbb.*******.com/hweb50/Logints.asp
                        </TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>###</TableCell>
                        <TableCell>10/9/2023 9:29</TableCell>
                        <TableCell>**.**.**.38</TableCell>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderedList>
              <Typography
                variant="reportH3"
                sx={{ color: theme.colors.PRIMARY }}
              >
                暗網及社交平台上提及之關鍵字
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Image height={340} src={KeywordImage1} />
                <UnorderedList>
                  <ListItem>標題：台湾_XXXXX_XX_购物</ListItem>
                  <ListItem>刊登時間：2023.xx.xx</ListItem>
                  <ListItem>渠道： Telegram</ListItem>
                  <ListItem>賣方：XXXX , 一般成員</ListItem>
                  <ListItem>
                    內容：會員資料流出。包含姓名/手機號碼/住址/商品/卡號等等
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image width={285} height={292} src={KeywordImage2} />
                </Box>
                <UnorderedList>
                  <ListItem>標題：XX 热门 卡头</ListItem>
                  <ListItem>刊登時間：2023.xx.xx</ListItem>
                  <ListItem>渠道： Telegram</ListItem>
                  <ListItem>賣方：XXXX , 一般成員</ListItem>
                  <ListItem>
                    內容：卡號販賣如下
                    <br />
                    49 XXXXX / 49 XXXXX
                    <br />
                    49 XXXXX / 49 XXXXX
                    <br />
                    53 XXXXX / 53 XXXXX
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image width={421} height={350} src={KeywordImage3} />
                </Box>
                <UnorderedList>
                  <ListItem>標題：XX銀行卡代收日元</ListItem>
                  <ListItem>刊登時間：2023.xx.xx</ListItem>
                  <ListItem>渠道：論壇</ListItem>
                  <ListItem>賣方：XXXX , 一般成員</ListItem>
                  <ListItem>內容：XX銀行帳戶代收日圓服務（洗錢）</ListItem>
                </UnorderedList>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.colors.WHITE_60}`,
                  borderRadius: 0.5,
                  bgcolor: theme.colors.BLACK_90,
                  pt: 1,
                  pb: 4,
                  px: 4,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image height={347} src={KeywordImage4} />
                </Box>
                <UnorderedList>
                  <ListItem>標題：TW XXXX DATABASE LEAKED!!!!!!!</ListItem>
                  <ListItem>刊登時間：2023.xx.xx</ListItem>
                  <ListItem>渠道：洋蔥站</ListItem>
                  <ListItem>賣方：XXXX , 一般成員</ListItem>
                  <ListItem>內容：20G月報/對話紀錄等</ListItem>
                </UnorderedList>
              </Box>
              <Typography
                variant="reportH2"
                sx={{ color: theme.colors.WHITE }}
                id="risk-assessment-and-recommendations"
              >
                肆、風險評估及建議
              </Typography>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                下述為TeamT5情資團隊針對可能外洩情資所提供的一般性緩解與預防建議。針對不同類型之資料外洩，駭客團隊可能採取不同之措施。
              </Typography>
              <OrderedList>
                <NumberListItem>
                  加強員工之資安意識，不同網站需使用不同密碼組合
                </NumberListItem>
                <NumberListItem>
                  公司應該定期進行弱點掃描和安全評估，尋找可能存在的弱點和風險，並儘快修補它們，以保護公司的資料和資訊安全。
                </NumberListItem>
              </OrderedList>
              <Typography
                variant="reportInlineText"
                sx={{ color: theme.colors.WHITE }}
              >
                *備註：為了保護相關人員的個人隱私及權益，本檔案的密碼欄位均做遮罩處理。若需要原始資料，請聯絡
                TeamT5。
              </Typography>
            </Stack>
            <Box id={GO_TO_BOTTOM} />
          </>
        </Stack>
        <Stack sx={{ height: '100%' }}>
          <Box
            sx={{
              position: 'sticky',
              top: '1.5rem',
              width: '21.875rem',
              height: `calc(100vh - ${theme.fixedConstants.FIXED_TOP_HEIGHT} - ${theme.fixedConstants.FIXED_FOOTER_HEIGHT} - ${INFO_PADDING} * 2)`,
            }}
          >
            <Stack sx={{ height: '100%' }}>
              <Stack
                sx={{
                  flexBasis: 0,
                  flexGrow: 1,
                  px: 2,
                  py: 4,
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1.5,
                    borderBottom: `2px solid ${theme.colors.PRIMARY}`,
                  }}
                >
                  <TabWithCount
                    icon={TocIcon}
                    name={t('toc', { ns: 'component' })}
                    showCount={false}
                    handleSelect={() => {}}
                    selected
                    sx={{ width: 'fit-content' }}
                  />
                </Box>
                <Stack sx={{ gap: 2, px: 4, py: 6 }}>
                  {tocList.map((title) => (
                    <Box
                      key={title.anchorId}
                      sx={{
                        cursor: 'pointer',
                        flexGrow: 1,
                      }}
                      onClick={() => handleTocTitleClick(title)}
                    >
                      <Link
                        sx={{ textDecoration: 'none' }}
                        href={`${search}#${title.anchorId}`}
                      >
                        <Typography
                          variant="bodySmall"
                          sx={{
                            color:
                              selectedTitleId === title.anchorId
                                ? theme.colors.PRIMARY_60
                                : theme.colors.WHITE_60,
                            display: 'flex',
                            '&:hover': {
                              color: theme.colors.TEXT_LINK_NORMAL,
                            },
                          }}
                        >
                          {title.text}
                        </Typography>
                      </Link>
                    </Box>
                  ))}
                </Stack>
              </Stack>
              <Stack sx={{ gap: 2, flexShrink: 0 }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleTocTitleClick({
                      text: t('scrollButton.backToTop', { ns: 'component' }),
                      anchorId: BACK_TO_TOP,
                    })
                  }
                >
                  <Typography
                    variant="bodySmall"
                    sx={{
                      pl: 5,
                      color:
                        selectedTitleId === BACK_TO_TOP
                          ? theme.colors.PRIMARY_60
                          : theme.colors.WHITE_60,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      '&:hover': {
                        color: theme.colors.TEXT_LINK_NORMAL,
                      },
                    }}
                  >
                    {t('scrollButton.backToTop', { ns: 'component' })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderLeft: `4px solid transparent`,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleTocTitleClick({
                      text: t('scrollButton.goToBottom', { ns: 'component' }),
                      anchorId: GO_TO_BOTTOM,
                    })
                  }
                >
                  <Typography
                    variant="bodySmall"
                    sx={{
                      pl: 5,
                      color:
                        selectedTitleId === GO_TO_BOTTOM
                          ? theme.colors.PRIMARY_60
                          : theme.colors.WHITE_60,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      '&:hover': {
                        color: theme.colors.TEXT_LINK_NORMAL,
                      },
                    }}
                  >
                    {t('scrollButton.goToBottom', { ns: 'component' })}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
