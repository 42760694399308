import { Box, Stack, useTheme } from '@mui/material'
import { Button } from 'components/Button/Button'
import { DateRangeSelectorPicker } from 'components/DatePicker/DateRangeSelectorPicker'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { ReportLoading } from 'components/Loading/ReportLoading'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useSearchReportTypeOptions } from 'hooks/useSearchReportTypeOptions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchReportsData,
  fetchSearchReportsDataCancelled,
  resetSearchReportsData,
  selectSearchReports,
  selectSelectedSearchReportTime,
  selectSelectedSearchReportTypes,
  setSelectedSearchReportTime,
  setSelectedSearchReportTypes,
} from 'store/slices/search'
import { TSearchReportType } from 'store/types/entityTypes/report'

import { NoResultContent } from '../NoResultContent'
import { ReportBlock } from './ContentComponents/ReportBlock'
import { ReportToolbar } from './ContentComponents/ReportToolbar'
import { ReportTypeSelector } from './ContentComponents/ReportTypeSelector'

const SCROLLABLE_CONTAINER_ID = 'reports-scrollable-container'

export const ReportContent = () => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const { dateRangeOptionToTimes } = useDateTime()
  const searchReportTypeOptions = useSearchReportTypeOptions()
  const dispatch = useAppDispatch()
  const reports = useAppSelector(selectSearchReports)
  const selectedSearchReportTypes = useAppSelector(
    selectSelectedSearchReportTypes
  )
  const selectedSearchReportDateTime = useAppSelector(
    selectSelectedSearchReportTime
  )
  const [searchParams] = useSearchParams()
  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)
  const tabKey = searchParams.get(SearchParamKey.SEARCH_RESULT_TAB)

  const { ref, inView } = useInView()

  const rangeOptions = useDateRangeOptions()

  // for download checkbox state
  const [selectedReports, setSelectedReports] = useState([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)

  const hasMoreReports = reports.hasMoreData
  const currentReportCounts = reports.data.length
  const noResultReports = currentReportCounts === 0 && !hasMoreReports
  const isFetchReportLoading = reports.isDataLoading

  const handleSelectReportTypesChange = (
    newReportTypeOptions: TSearchReportType[]
  ) => {
    dispatch(setSelectedSearchReportTypes(newReportTypeOptions))
  }

  const fetchReports = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchReportsData({
          searchText,
          searchReportTypeOptions: selectedSearchReportTypes,
          offset: refetch ? 0 : currentReportCounts,
          ...dateRangeOptionToTimes({
            startDate: selectedSearchReportDateTime.startDate,
            endDate: selectedSearchReportDateTime.endDate,
          })[selectedSearchReportDateTime.rangeOption as TRangeOption],
        })
      )
    }
  }

  useEffect(() => {
    dispatch(
      setSelectedSearchReportTime({
        startDate,
        endDate,
        rangeOption,
      })
    )
  }, [startDate, endDate, rangeOption])

  const handleReset = () => {
    setRangeOption('all')
    handleSelectReportTypesChange(
      searchReportTypeOptions.map((option) => option.key)
    )
  }

  useEffect(() => {
    fetchReports(true)

    return () => {
      dispatch(fetchSearchReportsDataCancelled())
      dispatch(resetSearchReportsData())
    }
  }, [searchText, selectedSearchReportTypes, selectedSearchReportDateTime])

  useEffect(() => {
    if (inView && hasMoreReports && currentReportCounts > 0) {
      fetchReports()
    }
  }, [inView, hasMoreReports, currentReportCounts])

  useEffect(() => {
    setSelectedReports([])
  }, [selectedSearchReportTypes, tabKey])

  const noResult =
    selectedSearchReportTypes.length === 0 ||
    (noResultReports && !isFetchReportLoading)

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          pl: 4,
          pr: 1,
          py: 2,
          gap: 1,
          bgcolor: theme.colors.BLACK_90,
        }}
      >
        <DateRangeSelectorPicker
          startDate={selectedSearchReportDateTime.startDate}
          setStartDate={setStartDate}
          endDate={selectedSearchReportDateTime.endDate}
          setEndDate={setEndDate}
          rangeOption={selectedSearchReportDateTime.rangeOption}
          setRangeOption={setRangeOption}
          width="14rem"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ReportTypeSelector
            selectedReportTypes={selectedSearchReportTypes}
            handleSelectReportTypesChange={handleSelectReportTypesChange}
          />
          <Button variant="textColor" onClick={handleReset}>
            {t('resetCta', { ns: 'component' })}
          </Button>
        </Box>
      </Stack>
      <Box>
        <ReportToolbar
          reports={reports.data}
          selectedReports={selectedReports}
          setSelectedReports={setSelectedReports}
        />
      </Box>
      {isFetchReportLoading && currentReportCounts === 0 ? (
        <ReportLoading withCheckbox />
      ) : noResult ? (
        <NoResultContent />
      ) : (
        <Box
          id={SCROLLABLE_CONTAINER_ID}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
          {reports.data.map((report) => (
            <ReportBlock
              key={report.alias}
              report={report}
              selectedReports={selectedReports}
              setSelectedReports={setSelectedReports}
            />
          ))}
          {hasMoreReports && <CenterCircularProgress scrollRef={ref} />}

          <ScrollToTop
            scrollableContainerId={SCROLLABLE_CONTAINER_ID}
            sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
          />
        </Box>
      )}
    </Stack>
  )
}
