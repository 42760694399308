import { Box, Divider, Stack, useTheme } from '@mui/material'
import ThreatVisionUserManualEN from 'assets/documents/ThreatVision_UserManual_v1.1_20230814_enUS.pdf'
import ThreatVisionUserManualTW from 'assets/documents/ThreatVision_UserManual_v1.1_20230814_zhTW.pdf'
import { PageSection } from 'components/PageSection/PageSection'
import { DocumentName, DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_DOCUMENTS } from 'constants/pageTitle'
import {
  API_DOC_AUTHENTICATION_ROUTE,
  API_DOC_INTRODUCTION_ROUTE,
  API_DOC_ROUTE,
  EULA_ENGLISH_VERSION_ROUTE,
  EULA_ROUTE,
  EULA_TRADITIONAL_CHINESE_VERSION_ROUTE,
  PREFACE_INTRODUCTION_ROUTE,
  PRIVACY_POLICY_ROUTE,
  REPORT_INTRODUCTION_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from 'constants/routeParams'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { DocumentListItem } from './DocumentComponents/DocumentListItem'

export const DocumentPage = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(['document', 'footer'])

  const isZh = i18n.language === 'zh'

  const UserManualDocument = isZh
    ? ThreatVisionUserManualTW
    : ThreatVisionUserManualEN

  const userManualDocumentFilename = isZh
    ? DocumentName.USER_MANUAL_DOCUMENT_FILENAME_ZH
    : DocumentName.USER_MANUAL_DOCUMENT_FILENAME_EN

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DOCUMENTS}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Stack sx={{ p: 1, gap: 1 }}>
          <PageSection title={t('userGuide.title', { ns: 'document' })}>
            <DocumentListItem
              lastUpdateDate={DocumentTimestamp.USER_MANUAL_DOCUMENT_TIMESTAMP}
              text={t('userGuide.userManual', { ns: 'document' })}
              downloadFile={UserManualDocument}
              downloadFilename={userManualDocumentFilename}
            />
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
            <DocumentListItem
              to={`/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`}
              lastUpdateDate={DocumentTimestamp.REPORT_INTRODUCTION_TIMESTAMP}
              text={t('userGuide.reportIntroduction', { ns: 'document' })}
            />
          </PageSection>
          <PageSection
            title={t('technicalDocuments.title', { ns: 'document' })}
          >
            <DocumentListItem
              to={`/${API_DOC_ROUTE}/${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_INTRODUCTION_ROUTE}`}
              lastUpdateDate={DocumentTimestamp.API_DOCUMENT_TIMESTAMP}
              text={t('technicalDocuments.apiDocument', { ns: 'document' })}
            />
          </PageSection>
          <PageSection title={t('legalDocuments.title', { ns: 'document' })}>
            <DocumentListItem
              to={`/${EULA_ROUTE}/${EULA_ENGLISH_VERSION_ROUTE}`}
              lastUpdateDate={DocumentTimestamp.EULA_DOCUMENT_TIMESTAMP}
              text={t('legalDocuments.eulaEn', { ns: 'document' })}
            />
            <DocumentListItem
              to={`/${EULA_ROUTE}/${EULA_TRADITIONAL_CHINESE_VERSION_ROUTE}`}
              lastUpdateDate={DocumentTimestamp.EULA_DOCUMENT_TIMESTAMP}
              text={t('legalDocuments.eulaZh', { ns: 'document' })}
            />
            <DocumentListItem
              to={`/${TERMS_OF_SERVICE_ROUTE}`}
              lastUpdateDate={
                DocumentTimestamp.TERMS_OF_SERVICE_DOCUMENT_TIMESTAMP
              }
              text={t('legalDocuments.termsOfService', { ns: 'document' })}
            />
            <DocumentListItem
              to={`/${PRIVACY_POLICY_ROUTE}`}
              lastUpdateDate={
                DocumentTimestamp.PRIVACY_POLICY_DOCUMENT_TIMESTAMP
              }
              text={t('legalDocuments.privacyPolicy', { ns: 'document' })}
            />
          </PageSection>
        </Stack>
      </Box>
    </>
  )
}
