import { Box, Stack, useTheme } from '@mui/material'
import {
  NetworkActivityLabel,
  RiskLevelLabel,
  TLPLabel,
  TwoToneLabel,
} from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import { useTranslation } from 'react-i18next'
import { ISample } from 'store/types/entityTypes/sample'

import {
  MoreSampleDetailButton,
  RelatedAdversaries,
  RelatedMalwares,
  SampleTitle,
} from './SampleElements'

export const UploadedSample = ({
  id,
  filename,
  adversaries,
  malwares,
  uploadAt,
  riskLevel,
  tlp,
  hasNetworkActivity,
}: ISample) => {
  const theme = useTheme()
  const { t } = useTranslation(['sample'])
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 4,
        bgcolor: theme.colors.BLACK_90,
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      <Stack sx={{ gap: 2 }}>
        <TwoToneLabel
          keyText={t('sampleInfo.uploadDate', { ns: 'sample' })}
          valueText={getDateStringByTimestampsInSeconds(uploadAt)}
          mainColor={theme.colors.WHITE_60}
          textColor={theme.colors.BLACK_90}
        />
        <SampleTitle title={filename} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <TLPLabel level={tlp} />
          <RiskLevelLabel level={riskLevel} />
          <NetworkActivityLabel isActive={hasNetworkActivity} />
        </Box>
        <RelatedAdversaries adversaries={adversaries} />
        <RelatedMalwares malwares={malwares} />
      </Stack>
      <Box sx={{ alignSelf: 'flex-end', flexShrink: 0 }}>
        <MoreSampleDetailButton sampleId={id} />
      </Box>
    </Box>
  )
}
