import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { ReactComponent as WarningIcon } from 'assets/basicIcons/warning.svg'
import { ReactNode } from 'react'

interface IAlertProps {
  showAlertIcon?: boolean
  title?: NonNullable<ReactNode>
  content: NonNullable<ReactNode>
  onClose?: () => void
}

export const Alert = ({
  showAlertIcon = false,
  title,
  content,
  onClose,
}: IAlertProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        px: 2,
        py: 2,
        bgcolor: theme.colors.WHITE_20,
        borderLeft: `2px solid ${theme.colors.WHITE}`,
        borderRadius: 0.5,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {showAlertIcon && (
          <SvgIcon
            sx={{
              alignSelf: 'flex-start',
              width: '1.5rem',
              height: '1.5rem',
            }}
            component={WarningIcon}
            inheritViewBox
          />
        )}
        <Stack sx={{ pl: showAlertIcon ? 0 : 4, color: theme.colors.WHITE }}>
          <Typography variant="titleSmall">{title}</Typography>
          <Typography variant="body">{content}</Typography>
        </Stack>
      </Box>
      {onClose && (
        <SvgIcon
          onClick={onClose}
          sx={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
          component={CloseIcon}
          inheritViewBox
        />
      )}
    </Box>
  )
}
