import type { Action } from '@reduxjs/toolkit'
import {
  getDomainAnalysisStatusById,
  getDomainDetailById,
  getDomainDNSRecordsById,
  getDomainOSINTById,
  getDomainRelationSamplesById,
  getDomainWhoisById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  delay,
  filter,
  iif,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchDomainDetail,
  fetchDomainDetailCancelled,
  fetchDomainDetailFulfilled,
  fetchDomainDetailRejected,
  fetchDomainDNSRecords,
  fetchDomainDNSRecordsCancelled,
  fetchDomainDNSRecordsFulfilled,
  fetchDomainDNSRecordsRejected,
  fetchDomainOSINT,
  fetchDomainOSINTCancelled,
  fetchDomainOSINTFulfilled,
  fetchDomainOSINTRejected,
  fetchDomainRelationSamples,
  fetchDomainRelationSamplesCancelled,
  fetchDomainRelationSamplesFulfilled,
  fetchDomainRelationSamplesRejected,
  fetchDomainStatus,
  fetchDomainStatusCancelled,
  fetchDomainStatusFulfilled,
  fetchDomainStatusRejected,
  fetchDomainWhois,
  fetchDomainWhoisCancelled,
  fetchDomainWhoisFulfilled,
  fetchDomainWhoisRejected,
} from 'store/slices/domain'
import { mapAPIDomainDetailToState } from 'store/types/entityTypes/domain'
import { mapAPIOSINTsToState } from 'store/types/entityTypes/osint'
import { mapAPISamplesToState } from 'store/types/entityTypes/sample'
import { IFetchDNSRecordsResponse } from 'store/types/slicesTypes/dns'
import {
  IFetchDomainDetailResponse,
  IFetchDomainRelationSamplesResponse,
  IFetchDomainStatusResponse,
} from 'store/types/slicesTypes/domainDetail'
import { IFetchOSINTResponse } from 'store/types/slicesTypes/osint'
import { IFetchWhoisResponse } from 'store/types/slicesTypes/whois'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const domainStatusEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainStatus.match),
    switchMap((action) =>
      fetchData<IFetchDomainStatusResponse>({
        url: getDomainAnalysisStatusById(action.payload.domain),
      }).pipe(
        delay(1000),
        switchMap((response: AjaxResponse<IFetchDomainStatusResponse>) =>
          iif(
            () => response.response.analysis_status,
            concat(
              of(fetchDomainStatusFulfilled()),
              of(fetchDomainDetail(action.payload))
            ),
            of(fetchDomainStatus(action.payload))
          )
        ),
        takeUntil(action$.pipe(filter(fetchDomainStatusCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainStatusRejected(error))
          )
        )
      )
    )
  )

const domainDetailEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainDetail.match),
    switchMap((action) =>
      fetchData<IFetchDomainDetailResponse>({
        url: getDomainDetailById(action.payload.domain),
      }).pipe(
        map((response: AjaxResponse<IFetchDomainDetailResponse>) => {
          if (response.response.analysis_status) {
            return fetchDomainDetailFulfilled(
              mapAPIDomainDetailToState(response.response)
            )
          }
          return fetchDomainStatus(action.payload)
        }),
        takeUntil(action$.pipe(filter(fetchDomainDetailCancelled.match))),
        catchError((error: AjaxError) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainDetailRejected(error)).pipe(
              tap(() => {
                if (error.status === 402) {
                  action.payload.outOfAAPCallback()
                }
              })
            )
          )
        )
      )
    )
  )

const domainOSINTEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainOSINT.match),
    switchMap((action) =>
      fetchData<IFetchOSINTResponse>({
        url: getDomainOSINTById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchOSINTResponse>) =>
          fetchDomainOSINTFulfilled(
            mapAPIOSINTsToState(response.response.osint)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDomainOSINTCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainOSINTRejected(error))
          )
        )
      )
    )
  )

const domainDNSRecordEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainDNSRecords.match),
    switchMap((action) =>
      fetchData<IFetchDNSRecordsResponse>({
        url: `${getDomainDNSRecordsById(action.payload.searchText)}?offset=${
          action.payload.offset
        }`,
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchDNSRecordsResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchDNSRecordsResponse>) =>
          fetchDomainDNSRecordsFulfilled(response.response.records || [])
        ),
        takeUntil(action$.pipe(filter(fetchDomainDNSRecordsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainDNSRecordsRejected(error))
          )
        )
      )
    )
  )

const domainWhoisEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainWhois.match),
    switchMap((action) =>
      fetchData<IFetchWhoisResponse>({
        url: getDomainWhoisById(action.payload),
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchWhoisResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchWhoisResponse>) =>
          fetchDomainWhoisFulfilled(response.response.whois || '')
        ),
        takeUntil(action$.pipe(filter(fetchDomainWhoisCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainWhoisRejected(error))
          )
        )
      )
    )
  )

const domainRelationSampleEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDomainRelationSamples.match),
    switchMap((action) =>
      fetchData<IFetchDomainRelationSamplesResponse>({
        url: `${getDomainRelationSamplesById(
          action.payload.domain
        )}?relatable_type=samples&offset=${action.payload.offset}`,
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchDomainRelationSamplesResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchDomainRelationSamplesResponse>) =>
          fetchDomainRelationSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchDomainRelationSamplesCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDomainRelationSamplesRejected(error))
          )
        )
      )
    )
  )

export const domainEpic = combineEpics(
  domainStatusEpic,
  domainDetailEpic,
  domainOSINTEpic,
  domainDNSRecordEpic,
  domainWhoisEpic,
  domainRelationSampleEpic
)
