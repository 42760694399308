import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { TextLink } from 'components/Link/Link'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import {
  selectAccountOnDemandReports,
  selectAccountOnDemandReportsLoading,
} from 'store/slices/account'

import type { TFilterTextMap, TOrder, TRFIListOrderKeys } from './RFIListTable'
import { filterRFIList, getRFIListComparatorMap } from './RFIListTable.config'
import { RFIListTableLoading } from './RFIListTableLoading'

interface IRFIListTableBodyProps {
  order: TOrder
  orderBy: TRFIListOrderKeys
  filterTextMap: TFilterTextMap
}

export const RFIListTableBody = ({
  order,
  orderBy,
  filterTextMap,
}: IRFIListTableBodyProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const accountOnDemandReports = useAppSelector(selectAccountOnDemandReports)
  const isAccountOnDemandReportsLoading = useAppSelector(
    selectAccountOnDemandReportsLoading
  )

  const filteredRFILists = filterRFIList({
    RFIList: accountOnDemandReports,
    filterTextMap,
  })

  const sortedRFILists = filteredRFILists.sort(
    getRFIListComparatorMap(order)[orderBy]
  )

  if (isAccountOnDemandReportsLoading && accountOnDemandReports.length === 0) {
    return <RFIListTableLoading />
  }

  return (
    <TableBody>
      {sortedRFILists.map((sortedRFIList) => (
        <TableRow key={sortedRFIList.id} hover>
          <TableCell
            sx={{
              height: '2.25rem',
              px: 4,
              py: 2,
              borderColor: theme.colors.WHITE_20,
              borderRight: `1px solid ${theme.colors.WHITE_20}`,
            }}
          >
            <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
              {getDateStringByTimestampsInSeconds(sortedRFIList.date)}
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              height: '2.25rem',
              px: 4,
              py: 2,
              borderColor: theme.colors.WHITE_20,
              borderRight: `1px solid ${theme.colors.WHITE_20}`,
            }}
          >
            <TextLink
              size="medium"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${sortedRFIList.alias}`}
            >
              {sortedRFIList.title}
            </TextLink>
          </TableCell>
          <TableCell
            sx={{
              height: '2.25rem',
              px: 4,
              py: 2,
              borderColor: theme.colors.WHITE_20,
              borderRight: `1px solid ${theme.colors.WHITE_20}`,
            }}
          >
            <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
              {sortedRFIList.requestForAssistanceHour}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
