import { SvgIcon, SxProps, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { ReactComponent as ScrollToTopIcon } from './scroll-to-top.svg'

interface IScrollToTopProps {
  scrollableContainerId: string
  sx?: SxProps
}

/**
 * @desc 通常會被賦予 position: sticky 並搭配 scrollable container 的相對位置來定義 left, bottom
 * @param scrollableContainerId 可以被捲動的 container id
 * @returns 具備 scroll to top 的 icon 按鈕
 */
export const ScrollToTop = ({
  scrollableContainerId,
  sx,
}: IScrollToTopProps) => {
  const theme = useTheme()

  const [showIcon, setShowIcon] = useState(false)

  const handleClick = () => {
    const scrollableContainer = document.getElementById(scrollableContainerId)
    if (scrollableContainer) {
      scrollableContainer.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  // 初次綁上捲動事件，當捲到最上方時需要隱藏 scroll to top 按鈕
  useEffect(() => {
    const scrollableContainer = document.getElementById(scrollableContainerId)
    const onScroll = () => {
      if (scrollableContainer) {
        setShowIcon(scrollableContainer.scrollTop > 0)
      }
    }
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', onScroll)
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    <SvgIcon
      sx={[
        {
          p: 0.5,
          width: '1.75rem',
          height: '1.75rem',
          cursor: 'pointer',
          color: theme.colors.WHITE_60,
          bgcolor: theme.colors.BLACK_85,
          borderRadius: 0.5,
          border: `1px solid ${theme.colors.WHITE_60}`,
          '&:hover': {
            borderWidth: 2,
            bgcolor: theme.colors.BLACK_95,
          },
          '&:active': {
            bgcolor: theme.colors.BLACK_95,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
        !showIcon && { display: 'none' },
      ]}
      component={ScrollToTopIcon}
      onClick={handleClick}
    />
  )
}
