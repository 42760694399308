import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

const LoadingBlock = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        p: 2,
        border: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <Skeleton type="image" width="4rem" height="4rem" />
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="body" width="100%" />
        <Skeleton type="body" width="19.625rem" />
      </Stack>
    </Box>
  )
}

export const OSINTLoading = () => (
  <Box sx={{ display: 'flex', gap: 2 }}>
    <LoadingBlock />
    <LoadingBlock />
  </Box>
)
