import { Box, Divider, Typography, useTheme } from '@mui/material'
import ThreatVisionUserManualEN from 'assets/documents/ThreatVision_UserManual_v1.1_20230814_enUS.pdf'
import ThreatVisionUserManualTW from 'assets/documents/ThreatVision_UserManual_v1.1_20230814_zhTW.pdf'
import { Button } from 'components/Button/Button'
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import { DocumentName } from 'constants/document'
import {
  EULA_ENGLISH_VERSION_ROUTE,
  EULA_ROUTE,
  EULA_TRADITIONAL_CHINESE_VERSION_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from 'constants/routeParams'
import { getYear } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { hideScrollBarStyles } from 'theme/scrollbar'
import { openMail } from 'utils/mail'

import ThirdPartyLicenseFile from './website-documents/3rd-license.zip'

interface IFooterLink {
  text: string
  downloadFilename?: string | boolean
  fileSrc?: string
  handleClick?: () => void
}

const FooterLink = ({
  fileSrc,
  downloadFilename = false,
  handleClick = () => {},
  text,
}: IFooterLink) => (
  <Link
    to={fileSrc || ''}
    target="_blank"
    rel="noopener noreferrer"
    download={downloadFilename}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
      <Button
        variant="footer"
        size="small"
        onClick={handleClick}
        sx={{
          '&.MuiButton-sizeSmall': {
            height: '0.875rem',
            lineHeight: '0.875rem',
          },
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Button>
    </Box>
  </Link>
)

const FooterLinkDivider = () => {
  const theme = useTheme()

  return (
    <Divider
      sx={{ borderColor: theme.colors.BLACK_50 }}
      orientation="vertical"
      variant="middle"
      flexItem
    />
  )
}

export const Footer = () => {
  const { t, i18n } = useTranslation(['footer'])
  const theme = useTheme()

  const isZh = i18n.language === 'zh'

  const UserManualDocument = isZh
    ? ThreatVisionUserManualTW
    : ThreatVisionUserManualEN

  const userManualDocumentFilename = isZh
    ? DocumentName.USER_MANUAL_DOCUMENT_FILENAME_ZH
    : DocumentName.USER_MANUAL_DOCUMENT_FILENAME_EN

  const eulaRoute =
    i18n.language === 'zh'
      ? `/${EULA_ROUTE}/${EULA_TRADITIONAL_CHINESE_VERSION_ROUTE}`
      : `/${EULA_ROUTE}/${EULA_ENGLISH_VERSION_ROUTE}`

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        bgcolor: theme.colors.BLACK_100,
        px: 4,
        py: 1,
        gap: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexShrink: 0 }}>
        <Typography
          variant="assistiveText"
          sx={{
            color: theme.colors.BLACK_50,
          }}
        >
          {t('copyright', { ns: 'footer', year: getYear(new Date()) })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflowX: 'auto',
          ...hideScrollBarStyles,
        }}
      >
        <FooterLink
          downloadFilename={userManualDocumentFilename}
          fileSrc={UserManualDocument}
          text={t('userManual', { ns: 'footer' })}
        />
        <FooterLinkDivider />
        <FooterLink fileSrc={eulaRoute} text={t('eula', { ns: 'footer' })} />
        <FooterLinkDivider />
        <FooterLink
          fileSrc={`/${TERMS_OF_SERVICE_ROUTE}`}
          text={t('terms', { ns: 'footer' })}
        />
        <FooterLinkDivider />
        <FooterLink
          fileSrc={`/${PRIVACY_POLICY_ROUTE}`}
          text={t('privacyPolicy', { ns: 'footer' })}
        />
        <FooterLinkDivider />
        <FooterLink
          fileSrc={ThirdPartyLicenseFile}
          text={t('thirdPartyLicenses', { ns: 'footer' })}
        />
        <FooterLinkDivider />
        <FooterLink
          handleClick={openMail}
          text={t('needHelp', { ns: 'footer' })}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <LanguageSwitcher />
        </Box>
      </Box>
    </Box>
  )
}
