import { Box, useTheme } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { PAGE_TITLE_DOWNLOADS } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { DownloadIocs } from './DownloadComponents/DownloadIocs'
import { DownloadTools } from './DownloadComponents/DownloadTools'

export const DownloadPage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['download'])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DOWNLOADS}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          p: 1,
        }}
      >
        <PageSection title={t('title', { ns: 'download' })}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(28.75rem, 1fr))',
              p: 2,
              columnGap: 2,
              height: '100%',
            }}
          >
            <DownloadIocs />
            <DownloadTools />
          </Box>
        </PageSection>
      </Box>
    </>
  )
}
