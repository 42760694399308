import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  DownloadToolButton,
  PurchaseToolButton,
} from 'components/Download/DownloadCta'
import { DateLabel, TwoToneLabel } from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import { useAppDispatch } from 'hooks/useReduxHooks'
import {
  useToolIncludedColor,
  useToolIncludedLabel,
} from 'hooks/useToolIncludedMap'
import { updateRelatedTools } from 'store/slices/malware'
import { ITool, TToolIncluded } from 'store/types/entityTypes/tool'

export const ThreatHuntingToolsPreview = (tool: ITool) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const toolIncludedColorMap = useToolIncludedColor()
  const toolIncludedLabelMap = useToolIncludedLabel()
  const { id, date, name, purchasedInformation, included } = tool
  const isPurchasable = Boolean(purchasedInformation)

  const successCallback = () => {
    dispatch(updateRelatedTools(id))
  }

  return (
    <Stack sx={{ p: 4, gap: 2 }}>
      <Stack sx={{ gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <DateLabel text={getDateStringByTimestampsInSeconds(date)} />
          <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
            {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {Object.keys(included)
            .filter((label: TToolIncluded) => included[label] > 0)
            .map((label: TToolIncluded) => (
              <TwoToneLabel
                key={label}
                keyText={toolIncludedLabelMap[label]}
                valueText={String(included[label])}
                mainColor={toolIncludedColorMap[label]}
                textColor={theme.colors.BLACK_90}
              />
            ))}
        </Box>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isPurchasable ? (
          <PurchaseToolButton
            extraSuccessCallback={successCallback}
            {...tool}
          />
        ) : (
          <DownloadToolButton {...tool} />
        )}
      </Box>
    </Stack>
  )
}
