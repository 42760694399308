import { SvgIcon } from '@mui/material'
// industry background images
import FinancialInstitutionBackgroundImage from 'assets/threatLandscape/item-background/industry-background-financial-institution.jpg'
import GamblingBackgroundImage from 'assets/threatLandscape/item-background/industry-background-gambling.jpg'
import HealthcareBackgroundImage from 'assets/threatLandscape/item-background/industry-background-healthcare.jpg'
import ITBackgroundImage from 'assets/threatLandscape/item-background/industry-background-it.jpg'
import ManufacturingBackgroundImage from 'assets/threatLandscape/item-background/industry-background-manufacturing.jpg'
import MediaBackgroundImage from 'assets/threatLandscape/item-background/industry-background-media.jpg'
import MilitaryBackgroundImage from 'assets/threatLandscape/item-background/industry-background-military.jpg'
import TelecomBackgroundImage from 'assets/threatLandscape/item-background/industry-background-telecom.jpg'
// industry icons
import { ReactComponent as FinancialInstitutionIcon } from 'assets/threatLandscape/item-icons/industry-icon-financial-institution.svg'
import { ReactComponent as GamblingIcon } from 'assets/threatLandscape/item-icons/industry-icon-gambling.svg'
import { ReactComponent as HealthcareIcon } from 'assets/threatLandscape/item-icons/industry-icon-healthcare.svg'
import { ReactComponent as ITIcon } from 'assets/threatLandscape/item-icons/industry-icon-it.svg'
import { ReactComponent as ManufacturingIcon } from 'assets/threatLandscape/item-icons/industry-icon-manufacturing.svg'
import { ReactComponent as MediaIcon } from 'assets/threatLandscape/item-icons/industry-icon-media.svg'
import { ReactComponent as MilitaryIcon } from 'assets/threatLandscape/item-icons/industry-icon-military.svg'
import { ReactComponent as TelecomIcon } from 'assets/threatLandscape/item-icons/industry-icon-telecom.svg'
import { useTranslation } from 'react-i18next'
import { ITargetIndustry } from 'store/types/entityTypes/target'

const sharedtargetedIndustriesIconProps = {
  sx: { width: '2.5rem', height: '2.5rem' },
  viewBox: '0 0 40 40',
}

/**
 * @description 寫死在前端的 target industries，主要用於 threat landscape page
 */

export const useTargetedIndustries = () => {
  const { t } = useTranslation(['threatLandscapes'])
  const targetedIndustries: ITargetIndustry[] = [
    {
      backgroundImageSrc: TelecomBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={TelecomIcon}
        />
      ),
      name: 'Telecommunication',
      displayName: t('targetIndustries.telecommunication.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'telecommunication',
      desc: t('targetIndustries.telecommunication.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: FinancialInstitutionBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={FinancialInstitutionIcon}
        />
      ),
      name: 'Financial Institution',
      displayName: t('targetIndustries.financialInstitution.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'financial-institution',
      desc: t('targetIndustries.financialInstitution.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: ManufacturingBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={ManufacturingIcon}
        />
      ),
      name: 'Manufacturing',
      displayName: t('targetIndustries.manufacturing.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'manufacturing',
      desc: t('targetIndustries.manufacturing.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: ITBackgroundImage,
      logo: (
        <SvgIcon {...sharedtargetedIndustriesIconProps} component={ITIcon} />
      ),
      name: 'Information Technology',
      displayName: t('targetIndustries.informationTechnology.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'information-technology',
      desc: t('targetIndustries.informationTechnology.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: MediaBackgroundImage,
      logo: (
        <SvgIcon {...sharedtargetedIndustriesIconProps} component={MediaIcon} />
      ),
      name: 'Media',
      displayName: t('targetIndustries.media.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'media',
      desc: t('targetIndustries.media.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: MilitaryBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={MilitaryIcon}
        />
      ),
      name: 'Military',
      displayName: t('targetIndustries.military.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'military',
      desc: t('targetIndustries.military.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: HealthcareBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={HealthcareIcon}
        />
      ),
      name: 'Healthcare',
      displayName: t('targetIndustries.healthcare.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'healthcare',
      desc: t('targetIndustries.healthcare.description', {
        ns: 'threatLandscapes',
      }),
    },
    {
      backgroundImageSrc: GamblingBackgroundImage,
      logo: (
        <SvgIcon
          {...sharedtargetedIndustriesIconProps}
          component={GamblingIcon}
        />
      ),
      name: 'Gambling',
      displayName: t('targetIndustries.gambling.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'gambling',
      desc: t('targetIndustries.gambling.description', {
        ns: 'threatLandscapes',
      }),
    },
  ]

  return targetedIndustries
}
