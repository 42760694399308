import {
  Box,
  CircularProgress,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { ElementType } from 'react'

export interface ITabWithCountProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: ElementType<any>
  name: string
  count?: number
  handleSelect: () => void
  selected: boolean
  showCount?: boolean
  isLoading?: boolean
  appendPlusSign?: boolean
  sx?: SxProps
}

export const TabWithCount = ({
  icon,
  name,
  count,
  handleSelect,
  selected,
  showCount = true,
  isLoading = false,
  appendPlusSign = false,
  sx = [],
}: ITabWithCountProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0.5,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.colors.PRIMARY_40,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleSelect}
    >
      <Box
        sx={{
          height: '1.25rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pl: 2,
          pr: showCount ? 1 : 2,
          gap: 1,
        }}
      >
        {icon && (
          <SvgIcon
            sx={{
              width: '1rem',
              height: '1rem',
              path: {
                fill: selected ? theme.colors.PRIMARY : theme.colors.WHITE,
                fillOpacity: 1,
              },
            }}
            component={icon}
            inheritViewBox
          />
        )}
        <Typography
          variant="titleSmall"
          sx={{
            color: selected ? theme.colors.PRIMARY : theme.colors.WHITE,
          }}
        >
          {name}
        </Typography>
      </Box>
      {showCount && (
        <Box
          sx={{
            px: '0.375rem',
            height: '1.25rem',
            bgcolor: selected ? theme.colors.PRIMARY : theme.colors.WHITE_20,
            borderRadius: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size="0.5rem"
              sx={{
                color: selected ? theme.colors.BLACK_90 : theme.colors.WHITE,
              }}
            />
          ) : (
            <Typography
              variant="textSmallImportant"
              sx={{
                color: selected ? theme.colors.BLACK_90 : theme.colors.WHITE,
                lineHeight: '0.75rem',
              }}
            >
              {count}
              {appendPlusSign && '+'}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
