import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IDNSRecord } from 'store/types/entityTypes/dns'
import { IIPDetail } from 'store/types/entityTypes/ip'
import { IOSINT } from 'store/types/entityTypes/osint'
import { ISample } from 'store/types/entityTypes/sample'
import { IFetchDNSRecordsPayload } from 'store/types/slicesTypes/dns'
import {
  IFetchIPDetailPayload,
  IFetchIPRelationSamplesPayload,
  IIPDetailSlice,
} from 'store/types/slicesTypes/ipDetail'

const initialState: IIPDetailSlice = {
  isFetchingIPDetail: false,
  isIPAnalyzed: false,
  ipDetail: null,
  isFetchingIPOSINT: true,
  ipOSINT: [],
  isFetchingDNSRecords: true,
  ipDNSRecords: [],
  hasMoreIPDNSRecords: true,
  isFetchingWhois: true,
  ipWhois: '',
  ipRelationSamples: [],
  isFetchingRelationSamples: true,
  hasMoreIPRelationSamples: true,
}

const ipDetailSlice = createSlice({
  name: 'ipDetail',
  initialState,
  reducers: {
    fetchIPStatus(_state, _action: PayloadAction<IFetchIPDetailPayload>) {},
    fetchIPStatusFulfilled(state) {
      state.isIPAnalyzed = true
    },
    fetchIPStatusRejected(state, _action: PayloadAction<AjaxError>) {
      state.isIPAnalyzed = false
    },
    fetchIPStatusCancelled() {},
    fetchIPDetail(state, _action: PayloadAction<IFetchIPDetailPayload>) {
      state.isFetchingIPDetail = true
    },
    fetchIPDetailFulfilled(state, action: PayloadAction<IIPDetail>) {
      state.ipDetail = action.payload
      state.isIPAnalyzed = true
      state.isFetchingIPDetail = false
    },
    fetchIPDetailRejected(state, _action: PayloadAction<AjaxError>) {
      state.ipDetail = initialState.ipDetail
      state.isFetchingIPDetail = false
    },
    fetchIPDetailCancelled(state) {
      state.isFetchingIPDetail = false
    },
    resetIPDetail(state) {
      state.ipDetail = initialState.ipDetail
    },
    resetIPAnalysisStatus(state) {
      state.isIPAnalyzed = initialState.isIPAnalyzed
    },
    fetchIPOSINT(state, _action: PayloadAction<string>) {
      state.isFetchingIPOSINT = true
    },
    fetchIPOSINTFulfilled(state, action: PayloadAction<IOSINT[]>) {
      state.ipOSINT = action.payload
      state.isFetchingIPOSINT = false
    },
    fetchIPOSINTRejected(state, _action: PayloadAction<AjaxError>) {
      state.ipOSINT = initialState.ipOSINT
      state.isFetchingIPOSINT = false
    },
    fetchIPOSINTCancelled(state) {
      state.isFetchingIPOSINT = false
    },
    fetchIPDNSRecords(state, _action: PayloadAction<IFetchDNSRecordsPayload>) {
      state.isFetchingDNSRecords = true
    },
    fetchIPDNSRecordsFulfilled(state, action: PayloadAction<IDNSRecord[]>) {
      if (action.payload.length === 0) {
        state.hasMoreIPDNSRecords = false
      } else {
        state.ipDNSRecords = [...state.ipDNSRecords, ...action.payload]
      }
      state.isFetchingDNSRecords = false
    },
    fetchIPDNSRecordsRejected(state, _action: PayloadAction<AjaxError>) {
      state.ipDNSRecords = initialState.ipDNSRecords
      state.isFetchingDNSRecords = false
    },
    fetchIPDNSRecordsCancelled(state) {
      state.isFetchingDNSRecords = false
    },
    resetIPDNSRecords(state) {
      state.ipDNSRecords = initialState.ipDNSRecords
      state.hasMoreIPDNSRecords = initialState.hasMoreIPDNSRecords
    },
    fetchIPWhois(state, _action: PayloadAction<string>) {
      state.isFetchingWhois = true
    },
    fetchIPWhoisFulfilled(state, action: PayloadAction<string>) {
      state.ipWhois = action.payload
      state.isFetchingWhois = false
    },
    fetchIPWhoisRejected(state, _action: PayloadAction<AjaxError>) {
      state.ipWhois = initialState.ipWhois
      state.isFetchingWhois = false
    },
    fetchIPWhoisCancelled(state) {
      state.isFetchingWhois = false
    },
    fetchIPRelationSamples(
      state,
      _action: PayloadAction<IFetchIPRelationSamplesPayload>
    ) {
      state.isFetchingRelationSamples = true
    },
    fetchIPRelationSamplesFulfilled(state, action: PayloadAction<ISample[]>) {
      if (action.payload.length === 0) {
        state.hasMoreIPRelationSamples = false
      } else {
        state.ipRelationSamples = [
          ...state.ipRelationSamples,
          ...action.payload,
        ]
      }
      state.isFetchingRelationSamples = false
    },
    fetchIPRelationSamplesRejected(state, _action: PayloadAction<AjaxError>) {
      state.ipRelationSamples = initialState.ipRelationSamples
      state.isFetchingRelationSamples = false
    },
    fetchIPRelationSamplesCancelled(state) {
      state.isFetchingRelationSamples = false
    },
    resetIPRelationSamples(state) {
      state.ipRelationSamples = initialState.ipRelationSamples
      state.hasMoreIPRelationSamples = initialState.hasMoreIPRelationSamples
    },
  },
})

export const {
  fetchIPStatus,
  fetchIPStatusFulfilled,
  fetchIPStatusRejected,
  fetchIPStatusCancelled,
  fetchIPDetail,
  fetchIPDetailFulfilled,
  fetchIPDetailRejected,
  fetchIPDetailCancelled,
  resetIPDetail,
  resetIPAnalysisStatus,
  fetchIPOSINT,
  fetchIPOSINTFulfilled,
  fetchIPOSINTRejected,
  fetchIPOSINTCancelled,
  fetchIPDNSRecords,
  fetchIPDNSRecordsFulfilled,
  fetchIPDNSRecordsRejected,
  fetchIPDNSRecordsCancelled,
  resetIPDNSRecords,
  fetchIPWhois,
  fetchIPWhoisFulfilled,
  fetchIPWhoisRejected,
  fetchIPWhoisCancelled,
  fetchIPRelationSamples,
  fetchIPRelationSamplesFulfilled,
  fetchIPRelationSamplesRejected,
  fetchIPRelationSamplesCancelled,
  resetIPRelationSamples,
} = ipDetailSlice.actions

export const selectIPAnalysisStatus = (state: TRootState) =>
  state.ipDetail.isIPAnalyzed
export const selectIPDetailLoading = (state: TRootState) =>
  state.ipDetail.isFetchingIPDetail
export const selectIPDetail = (state: TRootState) => state.ipDetail.ipDetail
export const selectIPOSINTLoading = (state: TRootState) =>
  state.ipDetail.isFetchingIPOSINT
export const selectIPOSINT = (state: TRootState) => state.ipDetail.ipOSINT
export const selectIPDNSRecordsLoading = (state: TRootState) =>
  state.ipDetail.isFetchingDNSRecords
export const selectIPDNSRecords = (state: TRootState) =>
  state.ipDetail.ipDNSRecords
export const selectHasMoreIPDNSRecords = (state: TRootState) =>
  state.ipDetail.hasMoreIPDNSRecords
export const selectIPWhoisLoading = (state: TRootState) =>
  state.ipDetail.isFetchingWhois
export const selectIPWhois = (state: TRootState) => state.ipDetail.ipWhois
export const selectIPRelationSamplesLoaidng = (state: TRootState) =>
  state.ipDetail.isFetchingRelationSamples
export const selectIPRelationSamples = (state: TRootState) =>
  state.ipDetail.ipRelationSamples
export const selectHasMoreIPRelationSamples = (state: TRootState) =>
  state.ipDetail.hasMoreIPRelationSamples

export default ipDetailSlice.reducer
