import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { ScrollableContainerId } from 'constants/document'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const BACK_TO_TOP = 'backToTop'
const GO_TO_BOTTOM = 'goToBottom'

interface ITocListProps {
  tocList?: {
    id: string
    text: string
  }[]
}

export const TocList = ({ tocList = [] }: ITocListProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const [selected, setSelected] = useState('')

  const goToBottom = () => {
    const scrollableContainerBottom = document.getElementById(
      ScrollableContainerId.BOTTOM_ID
    )
    if (scrollableContainerBottom) {
      scrollableContainerBottom.scrollIntoView({ behavior: 'smooth' })
    }
    setSelected(GO_TO_BOTTOM)
  }

  const goToTop = () => {
    const scrollableContainerTop = document.getElementById(
      ScrollableContainerId.TOP_ID
    )
    if (scrollableContainerTop) {
      scrollableContainerTop.scrollTo({ top: 0, behavior: 'smooth' })
    }
    setSelected(BACK_TO_TOP)
  }

  const handleTocTextClick = (id: string) => {
    setSelected(id)
  }

  return (
    <Box
      sx={{
        width: '15rem',
        minWidth: '15rem',
        maxWidth: '15rem',
        height: '100%',
        bgcolor: theme.colors.BLACK_85,
        px: 4,
        py: 6,
      }}
    >
      <Stack sx={{ height: '100%', gap: 8, justifyContent: 'space-between' }}>
        <Stack sx={{ gap: 2 }}>
          {tocList.map(({ id, text }) => (
            <Link
              key={id}
              href={`#${id}`}
              sx={{
                textDecoration: 'none',
                color: 'transparent',
              }}
            >
              <Box
                sx={{
                  borderLeft:
                    selected === id
                      ? `4px solid ${theme.colors.PRIMARY_60}`
                      : `4px solid ${theme.colors.BLACK_85}`,
                  cursor: 'pointer',
                }}
                onClick={() => handleTocTextClick(id)}
              >
                <Typography
                  variant="bodySmall"
                  sx={{
                    pl: 5,
                    color:
                      selected === id
                        ? theme.colors.PRIMARY_60
                        : theme.colors.WHITE_60,
                    display: 'flex',
                    '&:hover': {
                      color: theme.colors.TEXT_LINK_NORMAL,
                    },
                  }}
                >
                  {text}
                </Typography>
              </Box>
            </Link>
          ))}
        </Stack>
        <Box>
          <Box
            sx={{
              borderLeft: `4px solid ${theme.colors.BLACK_85}`,
              cursor: 'pointer',
              mt: 6,
            }}
            onClick={goToTop}
          >
            <Typography
              variant="bodySmall"
              sx={{
                pl: 5,
                color:
                  selected === BACK_TO_TOP
                    ? theme.colors.PRIMARY_60
                    : theme.colors.WHITE_60,
                display: 'flex',
                justifyContent: 'flex-end',
                '&:hover': {
                  color: theme.colors.TEXT_LINK_NORMAL,
                },
              }}
            >
              {t('scrollButton.backToTop', { ns: 'component' })}
            </Typography>
          </Box>
          <Box
            sx={{
              borderLeft: `4px solid ${theme.colors.BLACK_85}`,
              cursor: 'pointer',
            }}
            onClick={goToBottom}
          >
            <Typography
              variant="bodySmall"
              sx={{
                pl: 5,
                color:
                  selected === GO_TO_BOTTOM
                    ? theme.colors.PRIMARY_60
                    : theme.colors.WHITE_60,
                display: 'flex',
                justifyContent: 'flex-end',
                '&:hover': {
                  color: theme.colors.TEXT_LINK_NORMAL,
                },
              }}
            >
              {t('scrollButton.goToBottom', { ns: 'component' })}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
