import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuProps,
  SxProps,
  useTheme,
} from '@mui/material'
import { CSSProperties, MouseEvent, ReactNode } from 'react'

interface IMenuProps extends MenuProps {
  children: NonNullable<ReactNode>
  handleClose: () => void
  PaperPropsSx?: SxProps
  sx?: SxProps
}

export const Menu = ({
  children,
  anchorEl,
  open,
  handleClose,
  PaperPropsSx = [],
  sx = [],
  ...otherProps
}: IMenuProps) => {
  const theme = useTheme()

  return (
    <MuiMenu
      id="menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: [
          {
            bgcolor: theme.colors.BLACK_85,
            border: `1px solid ${theme.colors.WHITE_20}`,
          },
          ...(Array.isArray(PaperPropsSx) ? PaperPropsSx : [PaperPropsSx]),
        ],
      }}
      sx={[{ '.MuiMenu-list': { py: 1 } }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...otherProps}
    >
      {children}
    </MuiMenu>
  )
}

type TMenuSizes = 'small' | 'medium' | 'large'

export interface IMenuItemProps extends MenuItemProps {
  handleClick?: (event: MouseEvent<HTMLElement>) => void
  children: NonNullable<ReactNode>
  size: TMenuSizes
}

interface ISizeInfo {
  height: string
  fontStyles: CSSProperties
  padding: string
}

export const MenuItem = ({
  selected,
  handleClick,
  children,
  size,
  sx,
  ...otherProps
}: IMenuItemProps) => {
  const theme = useTheme()

  const sizeHeightMap: Record<TMenuSizes, ISizeInfo> = {
    small: {
      height: '1rem',
      fontStyles: theme.typography.assistiveText,
      padding: '0.5rem',
    },
    medium: {
      height: '1.5rem',
      fontStyles: theme.typography.bodySmall,
      padding: '1rem',
    },
    large: {
      height: '2rem',
      fontStyles: theme.typography.body,
      padding: '1rem',
    },
  }

  return (
    <MuiMenuItem
      sx={[
        {
          color: theme.colors.WHITE,
          display: 'flex',
          alignItems: 'center',
          height: sizeHeightMap[size],
          gap: 1,
          py: 0,
          px: sizeHeightMap[size].padding,
          '&.MuiMenuItem-root': {
            bgcolor: theme.colors.BLACK_85,
          },
          '&.MuiMenuItem-root.Mui-selected': {
            bgcolor: theme.colors.BLACK_85,
            '&:hover': {
              bgcolor: theme.colors.WHITE_40,
            },
          },
          '&:hover': {
            bgcolor: theme.colors.WHITE_40,
          },
          '&:active': {
            bgcolor: theme.colors.WHITE_60,
          },
          ...sizeHeightMap[size].fontStyles,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      selected={selected}
      onClick={handleClick}
      disableGutters
      disableRipple
      {...otherProps}
    >
      {children}
    </MuiMenuItem>
  )
}
