import { Box, Typography, useTheme } from '@mui/material'
import { Alert } from 'components/Alert/Alert'
import { TextLink } from 'components/Link/Link'
import { DOMAIN_ROUTE, IP_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { Trans, useTranslation } from 'react-i18next'
import { resetSearchResultType } from 'store/slices/search'
import { TSearchResultType } from 'store/types/slicesTypes/search'

interface ISearchReminderProps {
  searchResultType: TSearchResultType
  searchText: string
}

export const SearchReminder = ({
  searchResultType,
  searchText,
}: ISearchReminderProps) => {
  const { t } = useTranslation(['search'])
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(resetSearchResultType())
  }

  const reminderContent =
    searchResultType === 'ip' ? (
      <Trans t={t} i18nKey="searchIpReminder" values={{ searchText }}>
        The keyword
        <Typography variant="body" color={theme.colors.HIGH_LIGHT}>
          {searchText}
        </Typography>
        is identified as an IP, please click
        <TextLink
          size="medium"
          to={`/${IP_ROUTE}?${SearchParamKey.IP}=${searchText}`}
        >
          Check Detail
        </TextLink>
        to get more information about it.
      </Trans>
    ) : (
      <Trans t={t} i18nKey="searchDomainReminder" values={{ searchText }}>
        The keyword
        <Typography variant="body" color={theme.colors.HIGH_LIGHT}>
          {searchText}
        </Typography>
        is identified as a Domain, please click
        <TextLink
          size="medium"
          to={`/${DOMAIN_ROUTE}?${SearchParamKey.DOMAIN}=${searchText}`}
        >
          Check Detail
        </TextLink>
        to get more information about it.
      </Trans>
    )

  return (
    <Box
      sx={{
        px: 6,
        py: 2,
      }}
    >
      <Alert content={reminderContent} onClose={handleClose} />
    </Box>
  )
}
