import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IAdversary } from 'store/types/entityTypes/adversary'
import { IAdversarySlice } from 'store/types/slicesTypes/adversary'

const initialState: IAdversarySlice = {
  isFetchAdversariesLoading: true,
  isFetchAdversaryByIdLoading: true,
  adversaries: [],
  adversaryInfo: null,
}

const adversarySlice = createSlice({
  name: 'adversary',
  initialState,
  reducers: {
    fetchAdversaries(state) {
      state.isFetchAdversariesLoading = true
    },
    fetchAdversariesFulfilled(state, action: PayloadAction<IAdversary[]>) {
      state.adversaries = action.payload
      state.isFetchAdversariesLoading = false
    },
    fetchAdversariesRejected(state, _action: PayloadAction<AjaxError>) {
      state.adversaries = initialState.adversaries
      state.isFetchAdversariesLoading = false
    },
    fetchAdversariesCancelled(state) {
      state.isFetchAdversariesLoading = false
    },
    fetchAdversaryById(state, _action: PayloadAction<string>) {
      state.isFetchAdversaryByIdLoading = true
    },
    fetchAdversaryByIdFulfilled(state, action: PayloadAction<IAdversary>) {
      state.adversaryInfo = action.payload
      state.isFetchAdversaryByIdLoading = false
    },
    fetchAdversaryByIdRejected(state, _action: PayloadAction<AjaxError>) {
      state.adversaryInfo = initialState.adversaryInfo
      state.isFetchAdversaryByIdLoading = false
    },
    fetchAdversaryByIdCancelled(state) {
      state.isFetchAdversaryByIdLoading = false
    },
  },
})

export const {
  fetchAdversaries,
  fetchAdversariesFulfilled,
  fetchAdversariesRejected,
  fetchAdversariesCancelled,
  fetchAdversaryById,
  fetchAdversaryByIdFulfilled,
  fetchAdversaryByIdRejected,
  fetchAdversaryByIdCancelled,
} = adversarySlice.actions

export const selectFetchAdversariesLoading = (state: TRootState) =>
  state.adversary.isFetchAdversariesLoading
export const selectFetchAdversaryByIdLoading = (state: TRootState) =>
  state.adversary.isFetchAdversaryByIdLoading
export const selectAdversaries = (state: TRootState) =>
  state.adversary.adversaries
export const selectAdversaryInfo = (state: TRootState) =>
  state.adversary.adversaryInfo

export default adversarySlice.reducer
