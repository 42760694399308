import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reportTypeRouteMap, TReportType } from 'store/types/entityTypes/report'

export interface IReportCaptionProps {
  name: string
  description: NonNullable<ReactNode>
  buttonText: string
  reportType: Exclude<TReportType, 'customReport'>
  buttonDisable: boolean
  buttonHide: boolean
}

export const ReportCaption = ({
  name,
  description,
  buttonText,
  reportType,
  buttonDisable,
  buttonHide,
}: IReportCaptionProps) => {
  const { t } = useTranslation(['report'])
  const theme = useTheme()
  const [descExpanded, setDescExpanded] = useState<boolean>(false)

  const handleDescExpand = () => {
    setDescExpanded(true)
  }

  const handleDescFold = () => {
    setDescExpanded(false)
  }

  return (
    <Box>
      <Box sx={{ px: 4, py: 1 }}>
        <Typography variant="title">{name}</Typography>
      </Box>
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <Stack
        sx={{
          justifyContent: 'space-between',
          px: 4,
          py: 3,
        }}
      >
        <Box>
          <Typography
            variant="bodySmall"
            sx={[
              {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: '1.05rem',
              },
              !descExpanded && {
                WebkitLineClamp: '3',
              },
            ]}
          >
            {description}
          </Typography>
          <Typography
            variant="textLink"
            sx={{ cursor: 'pointer' }}
            onClick={descExpanded ? handleDescFold : handleDescExpand}
          >
            {descExpanded
              ? t('lessCta', { ns: 'report' })
              : t('moreCta', { ns: 'report' })}
          </Typography>
        </Box>
        {!buttonHide ? (
          <Box sx={{ pt: 3 }}>
            <LinkButton
              to={reportTypeRouteMap[reportType]}
              sx={{ width: '100%' }}
              disabled={buttonDisable}
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
            >
              {buttonText}
            </LinkButton>
          </Box>
        ) : (
          <Box sx={{ height: '1.5rem', mt: 3 }} />
        )}
      </Stack>
    </Box>
  )
}
