import { Box, SxProps, useTheme } from '@mui/material'

interface IMarkDownHtml {
  markdown: string
  sx?: SxProps
}
export const MarkDownHtml = ({ markdown, sx = [] }: IMarkDownHtml) => {
  const theme = useTheme()
  // https://stackoverflow.com/a/70524047
  const html = markdown
    .replace(/\[([^[]+)\](\(([^)]*))\)/gim, `<a href="$3">$1</a>`) // link
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>') // bold text
    .replace(/\*(.*)\*/gim, '<i>$1</i>') // italic text

  return (
    <Box
      sx={[
        {
          color: theme.colors.WHITE,
          whiteSpace: 'pre-line',
          ...theme.typography.body,
          'a': {
            color: theme.colors.TEXT_LINK_NORMAL,
            '&:hover': {
              color: theme.colors.WHITE_60,
            },
            '&:active': {
              color: theme.colors.PRIMARY,
            },
            ...theme.typography.textLink,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}
