import { Table, TableContainer } from '@mui/material'
import { useState } from 'react'
import { IAccountOnDemandReports } from 'store/types/entityTypes/account'

import { RFIListTableBody } from './RFIListTableBody'
import { RFIListTableHeader } from './RFIListTableHeader'

export type TOrder = 'asc' | 'desc'
export type TRFIListOrderKeys = keyof Omit<
  IAccountOnDemandReports,
  'id' | 'alias'
>
export type TRFIListFilterKeys = keyof Pick<
  IAccountOnDemandReports,
  'date' | 'title'
>
export type TFilterTextMap = Record<TRFIListFilterKeys, string>

const initialFilterTextMap: TFilterTextMap = {
  date: '',
  title: '',
}

export const RFIListTable = () => {
  // order
  const [order, setOrder] = useState<TOrder>('asc')
  const [orderBy, setOrderBy] = useState<TRFIListOrderKeys>('date')
  const handleOrderChange = (newOrderBy: TRFIListOrderKeys) => {
    const isAsc = orderBy === newOrderBy && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(newOrderBy)
  }

  // filter
  const [filterTextMap, setFilterTextMap] =
    useState<TFilterTextMap>(initialFilterTextMap)
  const handleFilterChange =
    (newFilterBy: TRFIListFilterKeys) => (newFilterText: string) => {
      const newFilterTextMap = {
        ...filterTextMap,
        [newFilterBy]: newFilterText,
      }
      setFilterTextMap(newFilterTextMap)
    }

  return (
    <TableContainer sx={{ height: '100%', minWidth: '40rem' }}>
      <Table stickyHeader>
        <RFIListTableHeader
          order={order}
          orderBy={orderBy}
          handleOrderChange={handleOrderChange}
          filterTextMap={filterTextMap}
          handleFilterChange={handleFilterChange}
        />
        <RFIListTableBody
          order={order}
          orderBy={orderBy}
          filterTextMap={filterTextMap}
        />
      </Table>
    </TableContainer>
  )
}
