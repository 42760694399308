import { Divider, Stack, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { useTranslation } from 'react-i18next'

interface IRecommendationsDrawerProps {
  open: boolean
  toggleDrawer: () => void
}

export const RecommendationsDrawer = ({
  open,
  toggleDrawer,
}: IRecommendationsDrawerProps) => {
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()

  return (
    <Drawer open={open} onClose={toggleDrawer}>
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {t('ddwAlert.remediation.title', { ns: 'ddw' })}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Stack sx={{ py: 2, gap: 4 }}>
        <Stack>
          <Typography variant="reportH3" sx={{ color: theme.colors.PRIMARY }}>
            {t('ddwAlert.remediation.credentialsLeak.title', { ns: 'ddw' })}
          </Typography>
          <Typography
            variant="reportInlineText"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('ddwAlert.remediation.credentialsLeak.description', {
              ns: 'ddw',
            })}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="reportH3" sx={{ color: theme.colors.PRIMARY }}>
            {t('ddwAlert.remediation.compromisedData.title', { ns: 'ddw' })}
          </Typography>
          <Typography
            variant="reportInlineText"
            sx={{ color: theme.colors.WHITE }}
          >
            {t('ddwAlert.remediation.compromisedData.description', {
              ns: 'ddw',
            })}
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  )
}
