import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { LinkButton } from 'components/Button/Button'
import { Drawer } from 'components/Drawer/Drawer'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { Tooltip } from 'components/Tooltip/Tooltip'
import {
  CAPABILITY_ROUTE,
  TACTIC_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { MarkDownHtml } from 'pages/CapabilityPage/CapabilityComponents/MarkDownHtml'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITechnique } from 'store/types/entityTypes/capability'

interface ITechniqueAttribute {
  title: string
  content: string | NonNullable<ReactNode>
}

const TechniqueAttribute = ({ title, content }: ITechniqueAttribute) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <HighlightLabel text={title} />
    <Typography sx={{ lineHeight: '1rem' }} variant="textSmallImportant">
      {content}
    </Typography>
  </Box>
)

interface ITechniquePreview extends ITechnique {
  techniqueName?: string
  showHoverStyle?: boolean
  showTechniqueName?: boolean
  showReadMoreButton?: boolean
  showDescriptionMoreLink?: boolean
  descriptionOverflowEllipsis?: boolean
  maxLine?: number
}

export const TechniquePreview = ({
  id,
  parent,
  subTechniques,
  description,
  techniqueName,
  lastModified,
  version,
  tactics,
  showHoverStyle = false,
  showTechniqueName = false,
  showReadMoreButton = false,
  showDescriptionMoreLink = false,
  descriptionOverflowEllipsis = false,
  maxLine = 3,
}: ITechniquePreview) => {
  const theme = useTheme()
  const { t } = useTranslation(['component', 'report'])
  const [descDrawerOpen, setDescDrawerOpen] = useState<boolean>(false)
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  const toggleDescDrawer = () => {
    setDescDrawerOpen((open) => !open)
  }

  return (
    <Stack
      sx={{
        color: theme.colors.WHITE,
        gap: 2,
        p: 4,
        pt: showTechniqueName ? 4 : 0,
        '&:hover': showHoverStyle ? { bgcolor: theme.colors.BLACK_60 } : {},
        '&:active': showHoverStyle ? { bgcolor: theme.colors.BLACK_95 } : {},
      }}
    >
      {showTechniqueName && (
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {techniqueName}
        </Typography>
      )}
      <Stack sx={{ gap: 1 }}>
        <TechniqueAttribute
          title={t('techniquePreview.label.id', { ns: 'component' })}
          content={id}
        />
        {parent.id ? (
          <TechniqueAttribute
            title={t('techniquePreview.label.subTechniquesOf', {
              ns: 'component',
            })}
            content={
              <TextLink
                to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${parent.id}`}
              >
                {parent.id}
              </TextLink>
            }
          />
        ) : (
          <TechniqueAttribute
            title={t('techniquePreview.label.subTechniques', {
              ns: 'component',
            })}
            content={subTechniques?.map((subTechnique, index) => (
              <Box key={subTechnique.id} sx={{ display: 'inline' }}>
                <TextLink
                  to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${subTechnique.id}`}
                >
                  {subTechnique.id}
                </TextLink>
                {index + 1 < subTechniques.length && ', '}
              </Box>
            ))}
          />
        )}
        <TechniqueAttribute
          title={t('techniquePreview.label.tactics', {
            ns: 'component',
          })}
          content={
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Box>
                {tactics.map((tactic, index) => (
                  <Box key={tactic.id} sx={{ display: 'inline' }}>
                    <TextLink
                      to={`/${CAPABILITY_ROUTE}/${TACTIC_ROUTE}?${SearchParamKey.CAPABILITY_TACTIC_ID}=${tactic.id}`}
                    >
                      {tactic.id}
                    </TextLink>
                    {index + 1 < tactics.length && ', '}
                  </Box>
                ))}
              </Box>
              <Tooltip
                title={
                  <Typography variant="textSmallImportant">
                    {t('techniquePreview.label.tacticsTooltip', {
                      ns: 'component',
                    })}
                  </Typography>
                }
              >
                <SvgIcon
                  component={InfoIcon}
                  inheritViewBox
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                  }}
                />
              </Tooltip>
            </Box>
          }
        />
        <TechniqueAttribute
          title={t('techniquePreview.label.version', {
            ns: 'component',
          })}
          content={
            <Typography variant="textSmallImportant">{version}</Typography>
          }
        />
        <TechniqueAttribute
          title={t('techniquePreview.label.lastModified', {
            ns: 'component',
          })}
          content={
            <Typography variant="textSmallImportant">
              {lastModified
                ? getDateStringByTimestampsInSeconds(lastModified)
                : '-'}
            </Typography>
          }
        />
        <Stack>
          <HighlightLabel
            text={t('techniquePreview.label.description', {
              ns: 'component',
            })}
          />
          <Typography
            variant="body"
            sx={[
              { pl: 2, pt: 1 },
              (showDescriptionMoreLink || descriptionOverflowEllipsis) && {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: '1.05rem',
                WebkitLineClamp: maxLine,
              },
            ]}
          >
            {description ? <MarkDownHtml markdown={description} /> : '-'}
          </Typography>
          {showDescriptionMoreLink && (
            <Typography
              variant="textLink"
              sx={{ width: 'fit-content', cursor: 'pointer', pl: 2 }}
              onClick={toggleDescDrawer}
            >
              {t('techniquePreview.label.descriptionMoreCta', {
                ns: 'component',
              })}
            </Typography>
          )}
        </Stack>
      </Stack>
      {showReadMoreButton && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LinkButton
            to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${id}`}
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            {t('readMoreCta', { ns: 'component' })}
          </LinkButton>
        </Box>
      )}
      <Drawer open={descDrawerOpen} onClose={toggleDescDrawer}>
        <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
          {t('techniquePreview.label.description', {
            ns: 'component',
          })}
        </Typography>
        <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
        <Box sx={{ py: 2 }}>
          {description ? <MarkDownHtml markdown={description} /> : '-'}
        </Box>
      </Drawer>
    </Stack>
  )
}
