import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_SAMPLE_COMPLETE_SHARE_TARGETS,
  FETCH_SAMPLE_FIND_SHARE_TARGET,
  getSampleDefaultShareById,
  getSampleDetailById,
  getSampleOSINTsById,
  getSamplePreviewsById,
  getSampleRelationsById,
  getSampleRelationsCountById,
  getSampleSandboxById,
  patchSampleShareById,
} from 'constants/api'
import i18n from 'i18next'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  EMPTY,
  filter,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchSampleCompleteShareTargets,
  fetchSampleCompleteShareTargetsCancelled,
  fetchSampleCompleteShareTargetsFulfilled,
  fetchSampleCompleteShareTargetsRejected,
  fetchSampleDefaultShare,
  fetchSampleDefaultShareCancelled,
  fetchSampleDefaultShareFulfilled,
  fetchSampleDefaultShareRejected,
  fetchSampleDetail,
  fetchSampleDetailCancelled,
  fetchSampleDetailFulfilled,
  fetchSampleDetailRejected,
  fetchSampleFindShareTarget,
  fetchSampleFindShareTargetFulfilled,
  fetchSampleFindShareTargetRejected,
  fetchSampleOSINTs,
  fetchSampleOSINTsCancelled,
  fetchSampleOSINTsFulfilled,
  fetchSampleOSINTsRejected,
  fetchSamplePreviewAsciiStrings,
  fetchSamplePreviewAsciiStringsCancelled,
  fetchSamplePreviewAsciiStringsFulfilled,
  fetchSamplePreviewAsciiStringsRejected,
  fetchSamplePreviewHex,
  fetchSamplePreviewHexCancelled,
  fetchSamplePreviewHexFulfilled,
  fetchSamplePreviewHexRejected,
  fetchSamplePreviewStackStrings,
  fetchSamplePreviewStackStringsCancelled,
  fetchSamplePreviewStackStringsFulfilled,
  fetchSamplePreviewStackStringsRejected,
  fetchSamplePreviewUtf16leStrings,
  fetchSamplePreviewUtf16leStringsCancelled,
  fetchSamplePreviewUtf16leStringsFulfilled,
  fetchSamplePreviewUtf16leStringsRejected,
  fetchSampleRelationBundleSamples,
  fetchSampleRelationBundleSamplesCancelled,
  fetchSampleRelationBundleSamplesFulfilled,
  fetchSampleRelationBundleSamplesRejected,
  fetchSampleRelationChildSamples,
  fetchSampleRelationChildSamplesCancelled,
  fetchSampleRelationChildSamplesFulfilled,
  fetchSampleRelationChildSamplesRejected,
  fetchSampleRelationDomains,
  fetchSampleRelationDomainsCancelled,
  fetchSampleRelationDomainsFulfilled,
  fetchSampleRelationDomainsRejected,
  fetchSampleRelationIPs,
  fetchSampleRelationIPsCancelled,
  fetchSampleRelationIPsFulfilled,
  fetchSampleRelationIPsRejected,
  fetchSampleRelationParentSamples,
  fetchSampleRelationParentSamplesCancelled,
  fetchSampleRelationParentSamplesFulfilled,
  fetchSampleRelationParentSamplesRejected,
  fetchSampleRelationsCount,
  fetchSampleRelationsCountCancelled,
  fetchSampleRelationsCountFulfilled,
  fetchSampleRelationsCountRejected,
  fetchSampleSandbox,
  fetchSampleSandboxCancelled,
  fetchSampleSandboxFulfilled,
  fetchSampleSandboxRejected,
  updateSampleShareTarget,
  updateSampleShareTargetFulfilled,
  updateSampleShareTargetRejected,
} from 'store/slices/sampleDetail'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { mapAPIDomainsToState } from 'store/types/entityTypes/domain'
import { mapAPIIPsToState } from 'store/types/entityTypes/ip'
import { mapAPIOSINTsToState } from 'store/types/entityTypes/osint'
import {
  mapAPISampleDetailToState,
  mapAPISamplePreviewHexToState,
  mapAPISamplePreviewStringsToState,
  mapAPISampleRelationsCountToState,
  mapAPISampleSandboxToState,
  mapAPISamplesToState,
} from 'store/types/entityTypes/sample'
import {
  mapAPIDefaultShareToState,
  mapAPIShareTargetsToState,
  mapAPIShareTargetToState,
} from 'store/types/entityTypes/tlpTarget'
import {
  IFetchSampleDetailResponse,
  IFetchSampleOSINTsResponse,
  IFetchSamplePreviewHexResponse,
  IFetchSamplePreviewStringsResponse,
  IFetchSampleRelationDomainsResponse,
  IFetchSampleRelationIPsResponse,
  IFetchSampleRelationSamplesResponse,
  IFetchSampleRelationsCountResponse,
  IFetchSampleSandboxResponse,
} from 'store/types/slicesTypes/sampleDetail'
import {
  IFetchCompleteShareTargetsResponse,
  IFetchDefaultShareResponse,
  IFetchFindShareTargetResponse,
} from 'store/types/slicesTypes/tlpTarget'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const fetchSampleDefaultShareEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleDefaultShare.match),
    switchMap((action) =>
      fetchData<IFetchDefaultShareResponse>({
        url: getSampleDefaultShareById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchDefaultShareResponse>) =>
          fetchSampleDefaultShareFulfilled(
            mapAPIDefaultShareToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSampleDefaultShareCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleDefaultShareRejected(error))
          )
        )
      )
    )
  )

const fetchSampleCompleteShareTargetsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleCompleteShareTargets.match),
    switchMap((action) =>
      fetchData<IFetchCompleteShareTargetsResponse>({
        url: `${FETCH_SAMPLE_COMPLETE_SHARE_TARGETS}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchCompleteShareTargetsResponse>) =>
          fetchSampleCompleteShareTargetsFulfilled(
            mapAPIShareTargetsToState(response.response.data)
          )
        ),

        takeUntil(
          action$.pipe(filter(fetchSampleCompleteShareTargetsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleCompleteShareTargetsRejected(error))
          )
        )
      )
    )
  )

const fetchSampleFindShareTargetEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleFindShareTarget.match),
    switchMap((action) =>
      fetchData<IFetchFindShareTargetResponse>({
        url: `${FETCH_SAMPLE_FIND_SHARE_TARGET}?name=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchFindShareTargetResponse>) =>
          fetchSampleFindShareTargetFulfilled(
            mapAPIShareTargetToState(response.response.data)
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleFindShareTargetRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('tlp.shareFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const updateSampleShareTargetsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(updateSampleShareTarget.match),
    switchMap((action) =>
      fetchData({
        method: 'PATCH',
        url: patchSampleShareById(action.payload.id),
        body: action.payload.data,
      }).pipe(
        mergeMap(() =>
          concat(
            of(updateSampleShareTargetFulfilled()),
            of(
              fetchSampleDetail({
                sampleId: action.payload.id,
                outOfAAPCallback: () => {},
              })
            ),
            iif(
              () => Boolean(action.payload.successMessage),
              of(
                pushSuccessSnackbar({
                  text: action.payload.successMessage || '',
                })
              ).pipe(
                tap(() => {
                  if (action.payload.successCallback) {
                    action.payload.successCallback()
                  }
                })
              ),
              EMPTY
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(updateSampleShareTargetRejected(error)),
            iif(
              () => Boolean(action.payload.failMessage),
              of(
                pushAlertSnackbar({
                  text: action.payload.failMessage || '',
                })
              ),
              EMPTY
            )
          )
        )
      )
    )
  )

const fetchSampleDetailEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleDetail.match),
    switchMap((action) =>
      fetchData<IFetchSampleDetailResponse>({
        url: getSampleDetailById(action.payload.sampleId),
      }).pipe(
        map((response: AjaxResponse<IFetchSampleDetailResponse>) =>
          fetchSampleDetailFulfilled(
            mapAPISampleDetailToState(response.response)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSampleDetailCancelled.match))),
        catchError((error: AjaxError) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleDetailRejected(error)).pipe(
              tap(() => {
                if (error.status === 402) {
                  action.payload.outOfAAPCallback()
                }
              })
            )
          )
        )
      )
    )
  )

const fetchSampleRelationsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationsCount.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationsCountResponse>({
        url: getSampleRelationsCountById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationsCountResponse>) =>
          fetchSampleRelationsCountFulfilled(
            mapAPISampleRelationsCountToState(response.response)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSampleRelationsCountCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationsCountRejected(error))
          )
        )
      )
    )
  )

const fetchSampleRelationIPsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationIPs.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationIPsResponse>({
        url: `${getSampleRelationsById(
          action.payload.sampleId
        )}?target=ips&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationIPsResponse>) =>
          fetchSampleRelationIPsFulfilled(
            mapAPIIPsToState(response.response.ips)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSampleRelationIPsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationIPsRejected(error))
          )
        )
      )
    )
  )

const fetchSampleRelationDomainsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationDomains.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationDomainsResponse>({
        url: `${getSampleRelationsById(
          action.payload.sampleId
        )}?target=domains&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationDomainsResponse>) =>
          fetchSampleRelationDomainsFulfilled(
            mapAPIDomainsToState(response.response.domains)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSampleRelationDomainsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationDomainsRejected(error))
          )
        )
      )
    )
  )

const fetchSampleRelationChildSamplesEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationChildSamples.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationSamplesResponse>({
        url: `${getSampleRelationsById(
          action.payload.sampleId
        )}?target=child_samples&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationSamplesResponse>) =>
          fetchSampleRelationChildSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSampleRelationChildSamplesCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationChildSamplesRejected(error))
          )
        )
      )
    )
  )

const fetchSampleRelationParentSamplesEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationParentSamples.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationSamplesResponse>({
        url: `${getSampleRelationsById(
          action.payload.sampleId
        )}?target=parent_samples&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationSamplesResponse>) =>
          fetchSampleRelationParentSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSampleRelationParentSamplesCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationParentSamplesRejected(error))
          )
        )
      )
    )
  )

const fetchSampleRelationBundleSamplesEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleRelationBundleSamples.match),
    switchMap((action) =>
      fetchData<IFetchSampleRelationSamplesResponse>({
        url: `${getSampleRelationsById(
          action.payload.sampleId
        )}?target=bundle_samples&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSampleRelationSamplesResponse>) =>
          fetchSampleRelationBundleSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSampleRelationBundleSamplesCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleRelationBundleSamplesRejected(error))
          )
        )
      )
    )
  )

const fetchSamplePreviewAsciiStringsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSamplePreviewAsciiStrings.match),
    switchMap((action) =>
      fetchData<IFetchSamplePreviewStringsResponse>({
        url: `${getSamplePreviewsById(
          action.payload.sampleId
        )}?content=strings&type=ascii&page=${action.payload.page}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSamplePreviewStringsResponse>) =>
          fetchSamplePreviewAsciiStringsFulfilled(
            mapAPISamplePreviewStringsToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSamplePreviewAsciiStringsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSamplePreviewAsciiStringsRejected(error))
          )
        )
      )
    )
  )

const fetchSamplePreviewUtf16leStringsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSamplePreviewUtf16leStrings.match),
    switchMap((action) =>
      fetchData<IFetchSamplePreviewStringsResponse>({
        url: `${getSamplePreviewsById(
          action.payload.sampleId
        )}?content=strings&type=utf16le&page=${action.payload.page}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSamplePreviewStringsResponse>) =>
          fetchSamplePreviewUtf16leStringsFulfilled(
            mapAPISamplePreviewStringsToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSamplePreviewUtf16leStringsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSamplePreviewUtf16leStringsRejected(error))
          )
        )
      )
    )
  )

const fetchSamplePreviewStackStringsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchSamplePreviewStackStrings.match),
    switchMap((action) =>
      fetchData<IFetchSamplePreviewStringsResponse>({
        url: `${getSamplePreviewsById(
          action.payload.sampleId
        )}?content=strings&type=stack&page=${action.payload.page}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSamplePreviewStringsResponse>) =>
          fetchSamplePreviewStackStringsFulfilled(
            mapAPISamplePreviewStringsToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchSamplePreviewStackStringsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSamplePreviewStackStringsRejected(error))
          )
        )
      )
    )
  )

const fetchSamplePreviewHexEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSamplePreviewHex.match),
    switchMap((action) =>
      fetchData<IFetchSamplePreviewHexResponse>({
        url: `${getSamplePreviewsById(
          action.payload.sampleId
        )}?content=hex&page=${action.payload.page}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSamplePreviewHexResponse>) =>
          fetchSamplePreviewHexFulfilled(
            mapAPISamplePreviewHexToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSamplePreviewHexCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSamplePreviewHexRejected(error))
          )
        )
      )
    )
  )

const fetchSampleSandboxEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleSandbox.match),
    switchMap((action) =>
      fetchData<IFetchSampleSandboxResponse>({
        url: getSampleSandboxById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchSampleSandboxResponse>) =>
          fetchSampleSandboxFulfilled(
            mapAPISampleSandboxToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSampleSandboxCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleSandboxRejected(error))
          )
        )
      )
    )
  )

const fetchSampleOSINTsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSampleOSINTs.match),
    switchMap((action) =>
      fetchData<IFetchSampleOSINTsResponse>({
        url: getSampleOSINTsById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchSampleOSINTsResponse>) =>
          fetchSampleOSINTsFulfilled(
            mapAPIOSINTsToState(response.response.osint)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSampleOSINTsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSampleOSINTsRejected(error))
          )
        )
      )
    )
  )

export const sampleDetailEpic = combineEpics(
  fetchSampleDefaultShareEpic,
  fetchSampleCompleteShareTargetsEpic,
  fetchSampleFindShareTargetEpic,
  updateSampleShareTargetsEpic,
  fetchSampleDetailEpic,
  fetchSampleRelationsCountEpic,
  fetchSampleRelationIPsEpic,
  fetchSampleRelationDomainsEpic,
  fetchSampleRelationChildSamplesEpic,
  fetchSampleRelationParentSamplesEpic,
  fetchSampleRelationBundleSamplesEpic,
  fetchSamplePreviewAsciiStringsEpic,
  fetchSamplePreviewUtf16leStringsEpic,
  fetchSamplePreviewStackStringsEpic,
  fetchSamplePreviewHexEpic,
  fetchSampleSandboxEpic,
  fetchSampleOSINTsEpic
)
