/* auth start */
export const LOGIN = '/api/token'
export const ACCOUNTS = '/api/v1/accounts'
export const REQUEST_RESET_PASSWORD = '/api/passwords'
export const getResetPasswordById = (id: string) => `/api/passwords/${id}`
/* auth end */

/* landing page start */
export const FETCH_LANDING_LATEST_REPORTS = '/api/v1/reports'
export const FETCH_LANDING_IOCS = '/api/v1/ioc_bundles'
export const FETCH_LANDING_COUNTS = '/api/v1/dashboard/counters'
/* landing page end */

/* search page start */
// search count
export const FETCH_SEARCH_REPORTS_COUNT = '/api/v1/search/reports/count'
export const FETCH_SEARCH_ADVERSARIES_COUNT = '/api/v1/search/adversaries/count'
export const FETCH_SEARCH_MALWARES_COUNT = '/api/v1/search/malwares/count'
export const FETCH_SEARCH_SAMPLES_COUNT = '/api/v1/search/samples/count'
export const FETCH_SEARCH_IPS_COUNT = '/api/v1/search/ips/count'
export const FETCH_SEARCH_DOMAINS_COUNT = '/api/v1/search/domains/count'
export const FETCH_SEARCH_TECHNIQUES_COUNT = '/api/v1/search/techniques/count'
export const FETCH_SEARCH_RAW_INTELS_COUNT = '/api/v1/search/raw_intels/count'

// search data
export const FETCH_SEARCH_REPORTS_DATA = '/api/v1/search/reports/entries'
export const FETCH_SEARCH_ADVERSARIES_DATA =
  '/api/v1/search/adversaries/entries'
export const FETCH_SEARCH_MALWARES_DATA = '/api/v1/search/malwares/entries'
export const FETCH_SEARCH_SAMPLES_DATA = '/api/v1/search/samples/entries'
export const FETCH_SEARCH_IPS_DATA = '/api/v1/search/ips/entries'
export const FETCH_SEARCH_DOMAINS_DATA = '/api/v1/search/domains/entries'
export const FETCH_SEARCH_TECHNIQUES_DATA = '/api/v1/search/techniques/entries'
export const FETCH_SEARCH_RAW_INTELS_DATA = '/api/v1/search/raw_intels/entries'

// suggest words
export const FETCH_SEARCH_SUGGEST_WORDS = '/api/v1/search/suggested_keywords'
/* search page end */

/* threat landscape page start */
export const getThreatLandscapeCountryRelatedAdversariesByTarget = (
  target: string
) => `/api/v1/countries/${target}/related_adversaries`
export const getThreatLandscapeCountryRelatedReportsByTarget = (
  target: string
) => `/api/v1/countries/${target}/related_reports`
export const getThreatLandscapeIndustryRelatedAdversariesByTarget = (
  target: string
) => `/api/v1/industries/${target}/related_adversaries`
export const getThreatLandscapeIndustryRelatedReportsByTarget = (
  target: string
) => `/api/v1/industries/${target}/related_reports`
export const getThreatLandscapeIndustryBuzzwordsByTarget = (target: string) =>
  `/api/v1/industries/${target}/buzzwords`
export const getThreatLandscapeRegionRelatedAdversariesByTarget = (
  target: string
) => `/api/v1/regions/${target}/related_adversaries`
export const getThreatLandscapeRegionRelatedReportsByTarget = (
  target: string
) => `/api/v1/regions/${target}/related_reports`
/* threat landscape page end */

/* malware page start */
export const FETCH_MALWARES = '/api/v1/malwares'
export const getMalwareById = (id: string) => `/api/v1/malwares/${id}`
export const getMalwareRelatedToolsById = (id: string) =>
  `/api/v1/detection_tools?resource_type=Program::Family&resource_main_value=${id}`
/* malware page end */

/* sample page start */
// submissions index page
export const UPLOAD_SAMPLE = '/api/v1/samples'
export const FETCH_SUBMISSIONS_COUNT = '/api/v1/samples/count'
export const FETCH_SUBMISSIONS = '/api/v1/samples'
// sample detail page
export const getSampleDefaultShareById = (id: string) =>
  `/api/v1/samples/${id}/share`
export const patchSampleShareById = (id: string) =>
  `/api/v1/samples/${id}/share`
export const FETCH_SAMPLE_COMPLETE_SHARE_TARGETS =
  '/api/v1/account/share_targets/complete_share_targets'
export const FETCH_SAMPLE_FIND_SHARE_TARGET =
  '/api/v1/account/share_targets/find_share_target'
export const getSampleDetailById = (id: string) => `/api/v1/samples/${id}`
export const getSampleRelationsCountById = (id: string) =>
  `/api/v1/samples/${id}/relations/count`
export const getSampleRelationsById = (id: string) =>
  `/api/v1/samples/${id}/relations`
export const getSamplePreviewsById = (id: string) =>
  `/api/v1/samples/${id}/previews`
export const getSampleSandboxById = (id: string) =>
  `/api/v1/samples/${id}/sandbox`
export const getSampleOSINTsById = (id: string) =>
  `/api/v1/samples/${id}/community`
/* sample page end */

/* account page start */
export const FETCH_ACCOUNT_SETTINGS = '/api/v1/account/settings'
export const UPDATE_ACCOUNT_SETTINGS = '/api/v1/account/settings'
export const FETCH_ACCOUNT_SETTINGS_OPTIONS = '/api/v1/account/settings/options'
export const FETCH_ACCOUNT_SETTINGS_DEFAULT_SHARE =
  '/api/v1/account/settings/default_share'
export const FETCH_ACCOUNT_COMPLETE_SHARE_TARGETS =
  '/api/v1/account/share_targets/complete_share_targets'
export const FETCH_ACCOUNT_FIND_SHARE_TARGET =
  '/api/v1/account/share_targets/find_share_target'
export const FETCH_ACCOUNT_API_KEYS = '/api/v1/account/api_keys'
export const CREATE_ACCOUNT_API_KEYS = '/api/v1/account/api_keys'
export const REPLACE_ACCOUNT_API_KEYS = '/api/v1/account/api_keys/replace'
export const DELETE_ACCOUNT_API_KEYS = '/api/v1/account/api_keys'
export const getAccountGroupById = (id: number) =>
  `/api/v1/account/groups/${id}`
export const UPDATE_ACCOUNT_PASSWORD = '/api/v1/account/password'
export const FETCH_ACCOUNT_ON_DEMAND_REPORTS =
  '/api/v1/account/settings/on_demand_reports'
export const GET_MSSP_CHECK = '/api/v1/account/groups/check_mssp'
/* account page end */

/* comment section start */
export const FETCH_COMMENTS = '/api/v1/comments'
export const CREATE_COMMENT = '/api/v1/comments'
export const getUpdateCommentsAPI = (id: string) => `/api/v1/comments/${id}`
export const getDeleteCommentsAPI = (id: string) => `/api/v1/comments/${id}`
/* comment section end */

/* report page start */
export const FETCH_REPORTS = '/api/v1/reports'
export const FETCH_REPORTS_COUNT = '/api/v1/reports/count'
export const getReportByAliasFormat = ({
  alias,
  format,
}: {
  alias: string
  format: string
}) => `/api/v1/reports/${alias}.${format}`
export const getReportByAlias = (alias: string) => `/api/v1/reports/${alias}`
export const FETCH_PMR_REPORTS_LIST = '/api/v1/vulnerability/advisory_lists'
export const getPMRReportDetailById = (id: string) =>
  `/api/v1/vulnerability/advisory_lists/${id}`
export const FETCH_PM_REPORTS_COUNT =
  '/api/v1/vulnerability/advisory_lists/count'
export const FETCH_REPORTS_READABLE_TYPES = '/api/v1/reports/readable_types'
export const getRelatedToolsById = (id: string) =>
  `/api/v1/detection_tools?resource_type=CaseFile&resource_main_value=${id}`
/* report page end */

/* download page start */
export const FETCH_DOWNLOAD_IOCS = '/api/v1/ioc_bundles'
export const FETCH_DOWNLOAD_TOOLS = '/api/v1/detection_tools'
export const downloadToolById = (id: number) =>
  `/api/v1/detection_tools/${id}/download`
/* download page end */

/* adversary page start */
export const FETCH_ADVERSARIES = '/api/v1/adversaries'
export const getAdversaryById = (id: string) => `${FETCH_ADVERSARIES}/${id}`
/* adversary page end */

/* ip page start */
export const getIPAnalysisStatusById = (id: string) =>
  `/api/v1/network/ips/${id}/analysis_status`
export const getIPDetailById = (id: string) =>
  `/api/v1/network/ips/${id}/summary`
export const getIPOSINTById = (id: string) =>
  `/api/v1/network/ips/${id}/community`
export const getIPDNSRecordsById = (id: string) =>
  `/api/v1/network/ips/${id}/dns_records`
export const getIPWhoisById = (id: string) => `/api/v1/network/ips/${id}/whois`
export const getIPRelationSamplesById = (id: string) =>
  `/api/v1/network/ips/${id}/relations`
/* ip page end */

/* domain page start */
export const getDomainAnalysisStatusById = (id: string) =>
  `/api/v1/network/domains/${id}/analysis_status`
export const getDomainDetailById = (id: string) =>
  `/api/v1/network/domains/${id}/summary`
export const getDomainOSINTById = (id: string) =>
  `/api/v1/network/domains/${id}/community`
export const getDomainDNSRecordsById = (id: string) =>
  `/api/v1/network/domains/${id}/dns_records`
export const getDomainWhoisById = (id: string) =>
  `/api/v1/network/domains/${id}/whois`
export const getDomainRelationSamplesById = (id: string) =>
  `/api/v1/network/domains/${id}/relations`
export const CHECK_IS_DOMAIN = `/api/v1/network/domains/check`
/* domain page end */

/* purchase start */
export const purchaseItemByPriceTagId = (priceTagId: number) =>
  `/api/v1/purchase_resource?price_tag_id=${priceTagId}`
/* purchase end */

/* capability start */
export const FETCH_MITRE_TACTICS = '/api/v1/mitre/tactics'
export const getMitreTacticById = (id: string) => `/api/v1/mitre/tactics/${id}`
export const getMitreTechniqueById = (id: string) =>
  `/api/v1/mitre/techniques/${id}`
/* capability end */

/* raw intel start */
export const getRawIntelDetailById = (id: string) => `/api/v1/raw_intels/${id}`
/* raw intel end */

/* ddw start */
export const FETCH_DDW_CONTRACTS = '/api/easm/contracts'
export const FETCH_DDW_REPORTS = '/api/easm/reports'
export const getReportMetaById = (id: string) => `/api/easm/reports/${id}/meta`
export const LARGE_LIMIT_COUNT = 1000000000000000
export const getClByIdAndKeyword = (id: string, keyword: string) =>
  `/api/easm/reports/${id}?type=cl&keyword=${keyword}&limit=${LARGE_LIMIT_COUNT}`
export const getCdsByIdAndKeyword = (id: string, keyword: string) =>
  `/api/easm/reports/${id}?type=cds&keyword=${keyword}&limit=${LARGE_LIMIT_COUNT}`
export const DOWNLOAD_DDW_REPORTS = '/api/easm/reports/download'
/* ddw end */
