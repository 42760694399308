import { Box, useTheme } from '@mui/material'
import { TextField } from 'components/TextField/TextField'
import { forwardRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { useTranslation } from 'react-i18next'

interface IDateRangePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  onChange: (input: [start: Date | null, end: Date | null]) => void
}

const CustomDateInput = forwardRef((props, ref) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <TextField
      inputRef={ref}
      label={t('timeSelector.range', { ns: 'component' })}
      size="medium"
      sx={{
        '& .MuiFormLabel-root': {
          ...theme.typography.bodySmall,
          top: '-0.75rem',
          '&.MuiInputLabel-shrink': {
            top: 0,
          },
        },
        '& .MuiInputBase-input': {
          width: '9rem',
          color: theme.colors.WHITE_60,
          ...theme.typography.bodySmall,
        },
      }}
      {...props}
    />
  )
})

export const DateRangePicker = (props: IDateRangePickerProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        '& .react-datepicker-popper': {
          zIndex: theme.zIndex.modal,
        },
        '& .react-datepicker': {
          border: 'none',
          bgcolor: theme.colors.BLACK_85,
          px: 6,
          pt: 4,
          pb: 6,
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          '& .react-datepicker__triangle': {
            '&::after, &::before': {
              borderBottomColor: theme.colors.BLACK_85,
            },
          },
        },
        '& .react-datepicker__month-container': {
          mx: 2,
        },
        '& .react-datepicker__navigation': {
          top: '1rem',
        },
        '& .react-datepicker__header': {
          bgcolor: theme.colors.BLACK_85,
          pt: 0,
        },
        '& .react-datepicker__current-month': {
          color: theme.colors.WHITE,
          mb: 1,
        },
        '& .react-datepicker__day-names': {
          '& .react-datepicker__day-name': {
            width: '2.25rem',
            height: '2.25rem',
            color: theme.colors.WHITE_60,
          },
        },
        '& .react-datepicker__day': {
          color: theme.colors.WHITE,
          width: '2.25rem',
          height: '2.25rem',
          pt: 1.25,
          ...theme.typography.body,
          borderRadius: 0.5,
          '&:hover': {
            bgcolor: theme.colors.PRIMARY_60,
          },
          '&.react-datepicker__day--outside-month': {
            height: 0,
            pt: 0,
          },
        },
        '& .react-datepicker__day--in-selecting-range, & .react-datepicker__day--in-range':
          {
            bgcolor: theme.colors.PRIMARY_20,
            '&.react-datepicker__day--selecting-range-start, &.react-datepicker__day--selecting-range-end, &.react-datepicker__day--range-start, &.react-datepicker__day--range-end':
              {
                bgcolor: theme.colors.PRIMARY_60,
              },
          },
        '& .react-datepicker__day--today': {
          pt: 1,
          border: `1px solid ${theme.colors.WHITE_60}`,
          '&.react-datepicker__day--outside-month': {
            border: 'none',
          },
        },
      }}
    >
      <DatePicker
        monthsShown={2}
        selectsRange
        disabledKeyboardNavigation
        customInput={<CustomDateInput />}
        {...props}
      />
    </Box>
  )
}
