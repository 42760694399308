import { Box, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { resetPassword } from 'utils/auth'
import * as Yup from 'yup'

import { LoginStatus } from '../config'

const resetFormInitialValues = { password: '', confirmPassword: '' }

export const ResetForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const identity = searchParams.get('identity')
  const token = searchParams.get('token')
  const { t } = useTranslation(['login', 'snackbar', 'component'])

  const resetFormSchema = Yup.object().shape({
    password: Yup.string().required(
      t('fieldErrorMessage.required', { ns: 'component' })
    ),
    confirmPassword: Yup.string()
      .required(t('fieldErrorMessage.required', { ns: 'component' }))
      .when('password', (password, schema) =>
        password && password.length > 0
          ? schema.oneOf(
              [password],
              t('fieldErrorMessage.confirmPassword', { ns: 'component' })
            )
          : schema
      ),
  })

  const { isSubmitting, values, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: resetFormInitialValues,
      validationSchema: resetFormSchema,
      onSubmit: async () => {
        if (identity && token) {
          try {
            await resetPassword(values.password, identity, token)
            dispatch(
              pushSuccessSnackbar({
                text: t('resetPassword.resetSuccess', { ns: 'snackbar' }),
              })
            )
            navigate('/login')
          } catch (error) {
            dispatch(
              pushAlertSnackbar({
                text: t('resetPassword.resetFail', { ns: 'snackbar' }),
              })
            )
          }
        }
      },
    })

  return (
    <form
      onSubmit={handleSubmit}
      data-testid={LoginStatus.RESET_PASSWORD_RESETABLE}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20rem',
        }}
      >
        <Typography
          variant="titleBig"
          sx={{ color: (theme) => theme.colors.TEXT_LINK_NORMAL, mb: 6 }}
        >
          {t('resetForm.title', { ns: 'login' })}
        </Typography>
        <Box sx={{ mb: 8, width: '100%' }}>
          <TextField
            name="password"
            label={t('resetForm.newPasswordInputLabel', { ns: 'login' })}
            type="password"
            InputLabelProps={{ color: 'primary' }}
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
        <Box sx={{ mb: 8, width: '100%' }}>
          <TextField
            name="confirmPassword"
            label={t('resetForm.confirmPasswordInputLabel', { ns: 'login' })}
            type="password"
            InputLabelProps={{ color: 'primary' }}
            value={values.confirmPassword}
            onChange={handleChange}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Box>
        <Button
          sx={{
            mb: 6,
            width: '100%',
          }}
          size="large"
          type="submit"
          disabled={isSubmitting}
        >
          {t('resetForm.title', { ns: 'login' })}
        </Button>
        <Typography
          variant="bodySmall"
          sx={{ color: (theme) => theme.colors.WHITE_60 }}
        >
          * {t('resetForm.reminderText', { ns: 'login' })}
        </Typography>
      </Box>
    </form>
  )
}
