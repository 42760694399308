import type { Action } from '@reduxjs/toolkit'
import { FETCH_ADVERSARIES, getAdversaryById } from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchAdversaries,
  fetchAdversariesCancelled,
  fetchAdversariesFulfilled,
  fetchAdversariesRejected,
  fetchAdversaryById,
  fetchAdversaryByIdCancelled,
  fetchAdversaryByIdFulfilled,
  fetchAdversaryByIdRejected,
} from 'store/slices/adversary'
import {
  IAdversaryAPIData,
  mapAPIAdversariesToState,
  mapAPIAdversaryToState,
} from 'store/types/entityTypes/adversary'
import { IFetchAdversariesResponse } from 'store/types/slicesTypes/adversary'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const adversariesDataEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAdversaries.match),
    switchMap(() =>
      fetchData<IFetchAdversariesResponse>({ url: FETCH_ADVERSARIES }).pipe(
        map((response: AjaxResponse<IFetchAdversariesResponse>) =>
          fetchAdversariesFulfilled(
            mapAPIAdversariesToState(response.response.adversaries)
          )
        ),
        takeUntil(action$.pipe(filter(fetchAdversariesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAdversariesRejected(error))
          )
        )
      )
    )
  )

const adversaryInfoEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAdversaryById.match),
    switchMap((action) =>
      fetchData<IAdversaryAPIData>({
        url: getAdversaryById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IAdversaryAPIData>) =>
          fetchAdversaryByIdFulfilled(mapAPIAdversaryToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchAdversaryByIdCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAdversaryByIdRejected(error))
          )
        )
      )
    )
  )

export const adversaryEpic = combineEpics(
  adversariesDataEpic,
  adversaryInfoEpic
)
