import { Box, Stack, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Skeleton } from 'components/Skeleton/Skeleton'

interface IReportLoadingProps {
  withCheckbox?: boolean
}

export const ReportLoading = ({
  withCheckbox = false,
}: IReportLoadingProps) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex' }}>
      {withCheckbox && (
        <Box sx={{ pt: 2, px: 2 }}>
          <Checkbox />
        </Box>
      )}
      <Box
        sx={{ flexGrow: 1, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <Stack sx={{ px: 4, py: 2, gap: 3, width: '100%' }}>
          <Skeleton type="title" width="26.5rem" />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack sx={{ gap: 2 }}>
              <Skeleton type="body" width="13.5rem" />
              <Skeleton type="body" width="20rem" />
              <Skeleton type="body" width="16.5rem" />
            </Stack>
            <Skeleton type="image" width="10rem" />
          </Box>
          <Stack sx={{ gap: 2, pb: 13 }}>
            <Skeleton type="body" width="13.5rem" />
            <Skeleton type="body" width="13.5rem" />
            <Skeleton type="body" width="13.5rem" />
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
