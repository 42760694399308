import type { Action } from '@reduxjs/toolkit'
import { FETCH_DOWNLOAD_IOCS, FETCH_DOWNLOAD_TOOLS } from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchDownloadIocs,
  fetchDownloadIocsCancelled,
  fetchDownloadIocsFulfilled,
  fetchDownloadIocsRejected,
  fetchDownloadTools,
  fetchDownloadToolsCancelled,
  fetchDownloadToolsFulfilled,
  fetchDownloadToolsRejected,
} from 'store/slices/download'
import { mapAPIIoCsToState } from 'store/types/entityTypes/ioc'
import { mapAPIToolsToState } from 'store/types/entityTypes/tool'
import {
  IFetchIocsPayload,
  IFetchIocsResponse,
  IFetchToolsPayload,
  IFetchToolsResponse,
} from 'store/types/slicesTypes/download'
import { fetchData } from 'utils/fetch.utils'
import { getArrayQueryString } from 'utils/queryString'

import { checkUnauthorizedToken } from './auth'

const getFetchIocsUrl = ({
  offset,
  dateFrom,
  dateTo,
  type,
  format,
}: IFetchIocsPayload) => {
  let url = `${FETCH_DOWNLOAD_IOCS}?offset=${offset}`

  if (dateFrom) {
    url = `${url}&date[from]=${dateFrom}`
  }

  if (dateTo) {
    url = `${url}&date[to]=${dateTo}`
  }

  if (type) {
    url = `${url}&type=${type}`
  }

  if (format) {
    url = `${url}&format_type=${format}`
  }

  return url
}

const fetchDownloadIocsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDownloadIocs.match),
    switchMap((action) =>
      fetchData<IFetchIocsResponse>({
        url: getFetchIocsUrl(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchIocsResponse>) =>
          fetchDownloadIocsFulfilled(
            mapAPIIoCsToState(response.response.ioc_bundles)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDownloadIocsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDownloadIocsRejected(error))
          )
        )
      )
    )
  )

const getFetchToolsUrl = ({
  dateFrom,
  dateTo,
  toolTypes,
  resourceType,
}: IFetchToolsPayload) => {
  let url = `${FETCH_DOWNLOAD_TOOLS}`

  if (dateFrom) {
    url = `${url}${url.includes('?') ? '&' : '?'}date[from]=${dateFrom}`
  }

  if (dateTo) {
    url = `${url}${url.includes('?') ? '&' : '?'}date[to]=${dateTo}`
  }

  if (toolTypes) {
    url = `${url}${url.includes('?') ? '&' : '?'}${getArrayQueryString({
      tool_types: toolTypes,
    })}`
  }

  if (resourceType) {
    url = `${url}${
      url.includes('?') ? '&' : '?'
    }filter_resource_type=${resourceType}`
  }

  return url
}

const fetchDownloadToolsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchDownloadTools.match),
    switchMap((action) =>
      fetchData<IFetchToolsResponse>({
        url: getFetchToolsUrl(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchToolsResponse>) =>
          fetchDownloadToolsFulfilled(
            mapAPIToolsToState(response.response.tools)
          )
        ),
        takeUntil(action$.pipe(filter(fetchDownloadToolsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchDownloadToolsRejected(error))
          )
        )
      )
    )
  )

// get download gzip data

export const downloadEpic = combineEpics(
  fetchDownloadIocsEpic,
  fetchDownloadToolsEpic
)
