import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const NormalLoading = () => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        p: 4,
        gap: 4,
        width: '100%',
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
      }}
    >
      <Skeleton type="title" width="26.5rem" />
      <Stack sx={{ gap: 2, pb: '8.1875rem' }}>
        <Skeleton type="body" width="13.5rem" />
        <Skeleton type="body" width="20rem" />
        <Skeleton type="body" width="16.5rem" />
      </Stack>
      <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <Skeleton type="title" width="6.25rem" />
      </Box>
    </Stack>
  )
}
