import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_SEARCH_ADVERSARIES_COUNT,
  FETCH_SEARCH_ADVERSARIES_DATA,
  FETCH_SEARCH_MALWARES_COUNT,
  FETCH_SEARCH_MALWARES_DATA,
  FETCH_SEARCH_REPORTS_COUNT,
  FETCH_SEARCH_REPORTS_DATA,
  FETCH_SEARCH_SAMPLES_COUNT,
  FETCH_SEARCH_SAMPLES_DATA,
  FETCH_SEARCH_TECHNIQUES_COUNT,
  FETCH_SEARCH_TECHNIQUES_DATA,
} from 'constants/api'
import { ALL_REPORT_TYPES_QUERY } from 'constants/urlQuery'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchTabAdversaries,
  fetchTabAdversariesCancelled,
  fetchTabAdversariesFulfilled,
  fetchTabAdversariesRejected,
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabAdversaryCountFulfilled,
  fetchTabAdversaryCountRejected,
  fetchTabMalwareCount,
  fetchTabMalwareCountCancelled,
  fetchTabMalwareCountFulfilled,
  fetchTabMalwareCountRejected,
  fetchTabMalwares,
  fetchTabMalwaresCancelled,
  fetchTabMalwaresFulfilled,
  fetchTabMalwaresRejected,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabReportCountFulfilled,
  fetchTabReports,
  fetchTabReportsCancelled,
  fetchTabReportsFulfilled,
  fetchTabReportsRejected,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  fetchTabSampleCountFulfilled,
  fetchTabSampleCountRejected,
  fetchTabSamples,
  fetchTabSamplesCancelled,
  fetchTabSamplesFulfilled,
  fetchTabSamplesRejected,
  fetchTabTechniqueCount,
  fetchTabTechniqueCountCancelled,
  fetchTabTechniqueCountFulfilled,
  fetchTabTechniqueCountRejected,
  fetchTabTechniques,
  fetchTabTechniquesCancelled,
  fetchTabTechniquesFulfilled,
  fetchTabTechniquesRejected,
} from 'store/slices/tabContent'
import { mapAPIAdversariesToState } from 'store/types/entityTypes/adversary'
import { mapAPITechniquesToState } from 'store/types/entityTypes/capability'
import { mapAPIMalwaresToState } from 'store/types/entityTypes/malware'
import { mapAPIReportsToState } from 'store/types/entityTypes/report'
import { mapAPISamplesToState } from 'store/types/entityTypes/sample'
import {
  IFetchSearchAdversariesResponse,
  IFetchSearchCountResponse,
  IFetchSearchMalwaresResponse,
  IFetchSearchReportsResponse,
  IFetchSearchSamplesResponse,
  IFetchSearchTechniquesResponse,
} from 'store/types/slicesTypes/search'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const tabReportCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabReportCount.match),
    switchMap((action) =>
      fetchData<IFetchSearchCountResponse>({
        url: `${FETCH_SEARCH_REPORTS_COUNT}?${ALL_REPORT_TYPES_QUERY}&query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchTabReportCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchTabReportCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabReportsRejected(error))
          )
        )
      )
    )
  )

const tabReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabReports.match),
    switchMap((action) =>
      fetchData<IFetchSearchReportsResponse>({
        url: `${FETCH_SEARCH_REPORTS_DATA}?${ALL_REPORT_TYPES_QUERY}&query=${action.payload.searchText}&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchReportsResponse>) =>
          fetchTabReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchTabReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabReportsRejected(error))
          )
        )
      )
    )
  )

const tabAdversaryCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabAdversaryCount.match),
    switchMap((action) =>
      fetchData<IFetchSearchCountResponse>({
        url: `${FETCH_SEARCH_ADVERSARIES_COUNT}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchTabAdversaryCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchTabAdversaryCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabAdversaryCountRejected(error))
          )
        )
      )
    )
  )

const tabAdversariesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabAdversaries.match),
    switchMap((action) =>
      fetchData<IFetchSearchAdversariesResponse>({
        url: `${FETCH_SEARCH_ADVERSARIES_DATA}?query=${action.payload.searchText}&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchAdversariesResponse>) =>
          fetchTabAdversariesFulfilled(
            mapAPIAdversariesToState(response.response.adversaries)
          )
        ),
        takeUntil(action$.pipe(filter(fetchTabAdversariesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabAdversariesRejected(error))
          )
        )
      )
    )
  )

const tabMalwareCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabMalwareCount.match),
    switchMap((action) =>
      fetchData<IFetchSearchCountResponse>({
        url: `${FETCH_SEARCH_MALWARES_COUNT}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchTabMalwareCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchTabMalwareCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabMalwareCountRejected(error))
          )
        )
      )
    )
  )

const tabMalwaresEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabMalwares.match),
    switchMap((action) =>
      fetchData<IFetchSearchMalwaresResponse>({
        url: `${FETCH_SEARCH_MALWARES_DATA}?query=${action.payload.searchText}&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchMalwaresResponse>) =>
          fetchTabMalwaresFulfilled(
            mapAPIMalwaresToState(response.response.malwares)
          )
        ),
        takeUntil(action$.pipe(filter(fetchTabMalwaresCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabMalwaresRejected(error))
          )
        )
      )
    )
  )

const tabSampleCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabSampleCount.match),
    switchMap((action) =>
      fetchData<IFetchSearchCountResponse>({
        url: `${FETCH_SEARCH_SAMPLES_COUNT}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchTabSampleCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchTabSampleCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabSampleCountRejected(error))
          )
        )
      )
    )
  )

const tabSamplesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabSamples.match),
    switchMap((action) =>
      fetchData<IFetchSearchSamplesResponse>({
        url: `${FETCH_SEARCH_SAMPLES_DATA}?query=${action.payload.searchText}&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchSamplesResponse>) =>
          fetchTabSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(action$.pipe(filter(fetchTabSamplesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabSamplesRejected(error))
          )
        )
      )
    )
  )

const tabTechniquesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabTechniques.match),
    switchMap((action) =>
      fetchData<IFetchSearchTechniquesResponse>({
        url: `${FETCH_SEARCH_TECHNIQUES_DATA}?query=${action.payload.searchText}&offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchTechniquesResponse>) =>
          fetchTabTechniquesFulfilled(
            mapAPITechniquesToState(response.response.techniques)
          )
        ),
        takeUntil(action$.pipe(filter(fetchTabTechniquesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabTechniquesRejected(error))
          )
        )
      )
    )
  )

const tabTechniqueCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTabTechniqueCount.match),
    switchMap((action) =>
      fetchData<IFetchSearchCountResponse>({
        url: `${FETCH_SEARCH_TECHNIQUES_COUNT}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchSearchCountResponse>) =>
          fetchTabTechniqueCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchTabTechniqueCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTabTechniqueCountRejected(error))
          )
        )
      )
    )
  )

export const tabContentEpic = combineEpics(
  tabReportCountEpic,
  tabReportsEpic,
  tabAdversaryCountEpic,
  tabAdversariesEpic,
  tabMalwareCountEpic,
  tabMalwaresEpic,
  tabSampleCountEpic,
  tabSamplesEpic,
  tabTechniquesEpic,
  tabTechniqueCountEpic
)
