import { Box } from '@mui/material'
import { NoData } from 'components/NoData/NoData'
import { IOSINT } from 'store/types/entityTypes/osint'

import { OSINTLoading } from './OSINTLoading'
import { OSINTPreview } from './OSINTPreview'

interface IOSINTTabContentProps {
  osints: IOSINT[]
  isLoading: boolean
}

export const OSINTTabContent = ({
  osints,
  isLoading,
}: IOSINTTabContentProps) => {
  if (!isLoading && osints.length === 0) {
    return <NoData />
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 2,
        p: 2,
      }}
    >
      {isLoading ? (
        <OSINTLoading />
      ) : (
        osints.map((osint) => (
          <Box key={osint.createdAt}>
            <OSINTPreview {...osint} />
          </Box>
        ))
      )}
    </Box>
  )
}
