import { chineseSearchTextMap } from 'constants/search'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useSearchParams } from 'react-router-dom'
import { selectSuggestWords } from 'store/slices/search'
import { ISuggestWord } from 'store/types/slicesTypes/search'

export const useMatchedInfo = (): ISuggestWord | null => {
  const [searchParams] = useSearchParams()

  const searchParamText = searchParams.get(SearchParamKey.SEARCH_TEXT)
  const suggestWords = useAppSelector(selectSuggestWords)

  const searchText =
    (searchParamText && chineseSearchTextMap[searchParamText]) ||
    searchParamText

  if (!searchText) {
    return null
  }

  const matchedSuggestWord = suggestWords.find(
    (word) =>
      word.name.toLowerCase() === searchText.toLowerCase() ||
      (word.aliases &&
        word.aliases.some(
          (alias) => alias.toLowerCase() === searchText.toLowerCase()
        ))
  )

  if (!matchedSuggestWord) {
    return null
  }

  return matchedSuggestWord
}
