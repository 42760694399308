import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/364f38b48565814b576f482c1e0eb4c8d58effcd033fd45136ee00640a2b5321/relationship?relationship=RELATIONSHIP_NAME" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `
# IP
{
  "success":true,
  "ips":[
    {
      "address":"108.177.126.99"
    }
  ]
}

# Domain
{
  "success":true,
  "domains":[
    {
      "fqdn":"geo2.adobe.com"
    }
  ]
}

# Sample

{
  "success":true,
  "child_samples":
    [
      {
        "main_value":"6290cc607c61418e2b2d0386387c5371307b4c4fdbf26ff9383525385d701fb6",
        "sha256":"6290cc607c61418e2b2d0386387c5371307b4c4fdbf26ff9383525385d701fb6",
        "md5":"9f538c8e6c76f447f937263e228ad55d",
        "size":155648,
        "first_seen":1697676691,
        "adversaries":["Polaris"],
        "malwares":["MiniPlug"],
        "filename":null,
        "risk_level":"high",
        "has_network_activity":false,
        "url":"https://api.threatvision.org/api/v2/samples/6290cc607c61418e2b2d0386387c5371307b4c4fdbf26ff9383525385d701fb6"
      }
    ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Relationship'

export const APIDocumentVersionTwoSampleRelationship = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Other samples, IPs, and domains that are marked “relatable” in our
          database would be listed here, e.g. dropped file, connection.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/:sha256/relationship" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            relationship
            <UnorderedList>
              <ListItem>
                Possible relationship
                <UnorderedList>
                  <ListItem>ips</ListItem>
                  <ListItem>domains</ListItem>
                  <ListItem>child_samples</ListItem>
                  <ListItem>parent_samples</ListItem>
                  <ListItem>bundle_samples</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The sha256 for sample</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Description>
          The relationship values of the following keys depend on query
          parameters.
        </Description>
        <UnorderedList>
          <ListItem>
            ips
            <UnorderedList>
              <ListItem>address</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            domains
            <UnorderedList>
              <ListItem>fqdn</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            child_samples
            <UnorderedList>
              <ListItem>
                Array of matching uploaded samples, each sample contains
                following keys:
                <UnorderedList>
                  <ListItem>sha256</ListItem>
                  <ListItem>filename</ListItem>
                  <ListItem>uploaded_date</ListItem>
                  <ListItem>
                    adversaries
                    <UnorderedList>
                      <ListItem>Related adversaries</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    malwares
                    <UnorderedList>
                      <ListItem>Related malwares</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    url
                    <UnorderedList>
                      <ListItem>
                        Link to sample detail API endpoint in ThreatVision
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            parent_samples
            <UnorderedList>
              <ListItem>
                Array of matching uploaded samples, each sample contains
                following keys:
                <UnorderedList>
                  <ListItem>sha256</ListItem>
                  <ListItem>filename</ListItem>
                  <ListItem>uploaded_date</ListItem>
                  <ListItem>
                    adversaries
                    <UnorderedList>
                      <ListItem>Related adversaries</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    malwares
                    <UnorderedList>
                      <ListItem>Related malwares</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    url
                    <UnorderedList>
                      <ListItem>
                        Link to sample detail API endpoint in ThreatVision
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            bundle_samples
            <UnorderedList>
              <ListItem>
                Array of matching uploaded samples, each sample contains
                following keys:
                <UnorderedList>
                  <ListItem>sha256</ListItem>
                  <ListItem>filename</ListItem>
                  <ListItem>uploaded_date</ListItem>
                  <ListItem>
                    adversaries
                    <UnorderedList>
                      <ListItem>Related adversaries</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    malwares
                    <UnorderedList>
                      <ListItem>Related malwares</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    url
                    <UnorderedList>
                      <ListItem>
                        Link to sample detail API endpoint in ThreatVision
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
