import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IDomain } from 'store/types/entityTypes/domain'
import { IIP } from 'store/types/entityTypes/ip'
import { IOSINT } from 'store/types/entityTypes/osint'
import {
  ISample,
  ISampleDetail,
  ISamplePreviewHex,
  ISamplePreviewStrings,
  ISampleRelationsCount,
  ISampleSandbox,
} from 'store/types/entityTypes/sample'
import { IDefaultShare, IShareTarget } from 'store/types/entityTypes/tlpTarget'
import {
  IFetchSampleDetailPayload,
  IFetchSamplePreviewPayload,
  IFetchSampleRelationDataPayload,
  IPatchSampleShareTargetsPayload,
  ISampleDetailSlice,
} from 'store/types/slicesTypes/sampleDetail'

const initialState: ISampleDetailSlice = {
  sampleDefaultShareLoading: false,
  sampleDefaultShare: {
    tlp: 'amber',
    targets: [],
  },
  sampleCompleteShareTargetsLoading: false,
  sampleCompleteShareTargets: [],
  sampleFindShareTargetLoading: false,
  sampleFindShareTarget: {
    name: '',
    type: 'user',
    ofsId: '',
    canEdit: false,
  },
  sampleShareTargetUpdating: false,
  sampleDetailLoading: false,
  sampleDetail: null,
  sampleRelationsCountLoading: false,
  sampleRelationsCount: {
    ipCount: 0,
    domainCount: 0,
    childSampleCount: 0,
    parentSampleCount: 0,
    bundleSampleCount: 0,
  },
  sampleRelationIPsLoading: false,
  hasMoreSampleRelationIPs: true,
  sampleRelationIPs: [],
  sampleRelationDomainsLoading: false,
  hasMoreSampleRelationDomains: true,
  sampleRelationDomains: [],
  sampleRelationChildSamplesLoading: false,
  hasMoreSampleRelationChildSamples: true,
  sampleRelationChildSamples: [],
  sampleRelationParentSamplesLoading: false,
  hasMoreSampleRelationParentSamples: true,
  sampleRelationParentSamples: [],
  sampleRelationBundleSamplesLoading: false,
  hasMoreSampleRelationBundleSamples: true,
  sampleRelationBundleSamples: [],
  samplePreviewAsciiStringsLoading: false,
  samplePreviewAsciiStrings: {
    contentStrings: '',
    nextPage: 0,
  },
  samplePreviewUtf16leStringsLoading: false,
  samplePreviewUtf16leStrings: {
    contentStrings: '',
    nextPage: 0,
  },
  samplePreviewStackStringsLoading: false,
  samplePreviewStackStrings: {
    contentStrings: '',
    nextPage: 0,
  },
  samplePreviewHexLoading: false,
  samplePreviewHex: {
    contentBytes: '',
    contentHex: '',
    nextPage: 0,
  },
  sampleSandboxLoading: false,
  sampleSandbox: {
    screenshotUrls: [],
    registry: [],
    mutex: [],
  },
  sampleOSINTsLoading: false,
  sampleOSINTs: [],
}

const sampleDetailSlice = createSlice({
  name: 'sampleDetail',
  initialState,
  reducers: {
    fetchSampleDefaultShare(state, _action: PayloadAction<string>) {
      state.sampleDefaultShareLoading = true
    },
    fetchSampleDefaultShareFulfilled(
      state,
      action: PayloadAction<IDefaultShare>
    ) {
      state.sampleDefaultShare = action.payload
      state.sampleDefaultShareLoading = false
    },
    fetchSampleDefaultShareRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleDefaultShare = initialState.sampleDefaultShare
      state.sampleDefaultShareLoading = false
    },
    fetchSampleDefaultShareCancelled(state) {
      state.sampleDefaultShareLoading = false
    },
    fetchSampleCompleteShareTargets(state, _action: PayloadAction<string>) {
      state.sampleCompleteShareTargetsLoading = true
    },
    fetchSampleCompleteShareTargetsFulfilled(
      state,
      action: PayloadAction<IShareTarget[]>
    ) {
      state.sampleCompleteShareTargets = action.payload
      state.sampleCompleteShareTargetsLoading = false
    },
    fetchSampleCompleteShareTargetsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleCompleteShareTargets = initialState.sampleCompleteShareTargets
      state.sampleCompleteShareTargetsLoading = false
    },
    fetchSampleCompleteShareTargetsCancelled(state) {
      state.sampleCompleteShareTargetsLoading = false
    },
    resetSampleCompleteShareTargets(state) {
      state.sampleCompleteShareTargets = initialState.sampleCompleteShareTargets
    },
    fetchSampleFindShareTarget(state, _action: PayloadAction<string>) {
      state.sampleFindShareTargetLoading = true
    },
    fetchSampleFindShareTargetFulfilled(
      state,
      action: PayloadAction<IShareTarget>
    ) {
      state.sampleFindShareTarget = action.payload
      state.sampleFindShareTargetLoading = false
    },
    fetchSampleFindShareTargetRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleFindShareTarget = initialState.sampleFindShareTarget
      state.sampleFindShareTargetLoading = false
    },
    fetchSampleFindShareTargetCancelled(state) {
      state.sampleFindShareTargetLoading = false
    },
    resetSampleFindShareTarget(state) {
      state.sampleFindShareTarget = initialState.sampleFindShareTarget
      state.sampleFindShareTargetLoading =
        initialState.sampleFindShareTargetLoading
    },
    updateSampleShareTarget(
      state,
      _action: PayloadAction<IPatchSampleShareTargetsPayload>
    ) {
      state.sampleShareTargetUpdating = true
    },
    updateSampleShareTargetFulfilled(state) {
      state.sampleShareTargetUpdating = false
    },
    updateSampleShareTargetRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleShareTargetUpdating = false
    },
    fetchSampleDetail(
      state,
      _action: PayloadAction<IFetchSampleDetailPayload>
    ) {
      state.sampleDetailLoading = true
    },
    fetchSampleDetailFulfilled(state, action: PayloadAction<ISampleDetail>) {
      state.sampleDetail = action.payload
      state.sampleDetailLoading = false
    },
    fetchSampleDetailRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleDetail = initialState.sampleDetail
      state.sampleDetailLoading = false
    },
    fetchSampleDetailCancelled(state) {
      state.sampleDetailLoading = false
    },
    fetchSampleRelationsCount(state, _action: PayloadAction<string>) {
      state.sampleRelationsCountLoading = false
    },
    fetchSampleRelationsCountFulfilled(
      state,
      action: PayloadAction<ISampleRelationsCount>
    ) {
      state.sampleRelationsCount = action.payload
      state.sampleRelationsCountLoading = false
    },
    fetchSampleRelationsCountRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleRelationsCount = initialState.sampleRelationsCount
      state.sampleRelationsCountLoading = false
    },
    fetchSampleRelationsCountCancelled(state) {
      state.sampleRelationsCountLoading = false
    },
    fetchSampleRelationIPs(
      state,
      _action: PayloadAction<IFetchSampleRelationDataPayload>
    ) {
      state.sampleRelationIPsLoading = true
    },
    fetchSampleRelationIPsFulfilled(state, action: PayloadAction<IIP[]>) {
      if (action.payload.length > 0) {
        state.sampleRelationIPs = [
          ...state.sampleRelationIPs,
          ...action.payload,
        ]
      } else {
        state.hasMoreSampleRelationIPs = false
      }
      state.sampleRelationIPsLoading = false
    },
    fetchSampleRelationIPsRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleRelationIPs = initialState.sampleRelationIPs
      state.sampleRelationIPsLoading = false
    },
    fetchSampleRelationIPsCancelled(state) {
      state.sampleRelationIPsLoading = false
    },
    resetSampleRelationIPs(state) {
      state.sampleRelationIPs = initialState.sampleRelationIPs
      state.hasMoreSampleRelationIPs = true
    },
    fetchSampleRelationDomains(
      state,
      _action: PayloadAction<IFetchSampleRelationDataPayload>
    ) {
      state.sampleRelationDomainsLoading = true
    },
    fetchSampleRelationDomainsFulfilled(
      state,
      action: PayloadAction<IDomain[]>
    ) {
      if (action.payload.length > 0) {
        state.sampleRelationDomains = [
          ...state.sampleRelationDomains,
          ...action.payload,
        ]
      } else {
        state.hasMoreSampleRelationDomains = false
      }
      state.sampleRelationDomainsLoading = false
    },
    fetchSampleRelationDomainsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleRelationDomains = initialState.sampleRelationDomains
      state.sampleRelationDomainsLoading = false
    },
    fetchSampleRelationDomainsCancelled(state) {
      state.sampleRelationDomainsLoading = false
    },
    resetSampleRelationDomains(state) {
      state.sampleRelationDomains = initialState.sampleRelationDomains
      state.hasMoreSampleRelationDomains = true
    },
    fetchSampleRelationChildSamples(
      state,
      _action: PayloadAction<IFetchSampleRelationDataPayload>
    ) {
      state.sampleRelationChildSamplesLoading = true
    },
    fetchSampleRelationChildSamplesFulfilled(
      state,
      action: PayloadAction<ISample[]>
    ) {
      if (action.payload.length > 0) {
        state.sampleRelationChildSamples = [
          ...state.sampleRelationChildSamples,
          ...action.payload,
        ]
      } else {
        state.hasMoreSampleRelationChildSamples = false
      }
      state.sampleRelationChildSamplesLoading = false
    },
    fetchSampleRelationChildSamplesRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleRelationChildSamples = initialState.sampleRelationChildSamples
      state.sampleRelationChildSamplesLoading = false
    },
    fetchSampleRelationChildSamplesCancelled(state) {
      state.sampleRelationChildSamplesLoading = false
    },
    resetSampleRelationChildSamples(state) {
      state.sampleRelationChildSamples = initialState.sampleRelationChildSamples
      state.hasMoreSampleRelationChildSamples = true
    },
    fetchSampleRelationParentSamples(
      state,
      _action: PayloadAction<IFetchSampleRelationDataPayload>
    ) {
      state.sampleRelationParentSamplesLoading = true
    },
    fetchSampleRelationParentSamplesFulfilled(
      state,
      action: PayloadAction<ISample[]>
    ) {
      if (action.payload.length > 0) {
        state.sampleRelationParentSamples = [
          ...state.sampleRelationParentSamples,
          ...action.payload,
        ]
      } else {
        state.hasMoreSampleRelationParentSamples = false
      }
      state.sampleRelationParentSamplesLoading = false
    },
    fetchSampleRelationParentSamplesRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleRelationParentSamples =
        initialState.sampleRelationParentSamples
      state.sampleRelationParentSamplesLoading = false
    },
    fetchSampleRelationParentSamplesCancelled(state) {
      state.sampleRelationParentSamplesLoading = false
    },
    resetSampleRelationParentSamples(state) {
      state.sampleRelationParentSamples =
        initialState.sampleRelationParentSamples
      state.hasMoreSampleRelationParentSamples = true
    },
    fetchSampleRelationBundleSamples(
      state,
      _action: PayloadAction<IFetchSampleRelationDataPayload>
    ) {
      state.sampleRelationBundleSamplesLoading = true
    },
    fetchSampleRelationBundleSamplesFulfilled(
      state,
      action: PayloadAction<ISample[]>
    ) {
      if (action.payload.length > 0) {
        state.sampleRelationBundleSamples = [
          ...state.sampleRelationBundleSamples,
          ...action.payload,
        ]
      } else {
        state.hasMoreSampleRelationBundleSamples = false
      }
      state.sampleRelationBundleSamplesLoading = false
    },
    fetchSampleRelationBundleSamplesRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.sampleRelationBundleSamples =
        initialState.sampleRelationBundleSamples
      state.sampleRelationBundleSamplesLoading = false
    },
    fetchSampleRelationBundleSamplesCancelled(state) {
      state.sampleRelationBundleSamplesLoading = false
    },
    resetSampleRelationBundleSamples(state) {
      state.sampleRelationBundleSamples =
        initialState.sampleRelationBundleSamples
      state.hasMoreSampleRelationBundleSamples = true
    },
    fetchSamplePreviewAsciiStrings(
      state,
      _action: PayloadAction<IFetchSamplePreviewPayload>
    ) {
      state.samplePreviewAsciiStringsLoading = true
    },
    fetchSamplePreviewAsciiStringsFulfilled(
      state,
      action: PayloadAction<ISamplePreviewStrings>
    ) {
      const newPreviewAsciiStrings = action.payload
      state.samplePreviewAsciiStrings = {
        nextPage: newPreviewAsciiStrings.nextPage,
        contentStrings:
          state.samplePreviewAsciiStrings.contentStrings +
          newPreviewAsciiStrings.contentStrings,
      }
      state.samplePreviewAsciiStringsLoading = false
    },
    fetchSamplePreviewAsciiStringsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.samplePreviewAsciiStrings = initialState.samplePreviewAsciiStrings
      state.samplePreviewAsciiStringsLoading = false
    },
    fetchSamplePreviewAsciiStringsCancelled(state) {
      state.samplePreviewAsciiStringsLoading = false
    },
    resetSamplePreviewAsciiStrings(state) {
      state.samplePreviewAsciiStrings = initialState.samplePreviewAsciiStrings
    },
    fetchSamplePreviewUtf16leStrings(
      state,
      _action: PayloadAction<IFetchSamplePreviewPayload>
    ) {
      state.samplePreviewUtf16leStringsLoading = true
    },
    fetchSamplePreviewUtf16leStringsFulfilled(
      state,
      action: PayloadAction<ISamplePreviewStrings>
    ) {
      const newPreviewUtf16leStrings = action.payload
      state.samplePreviewUtf16leStrings = {
        nextPage: newPreviewUtf16leStrings.nextPage,
        contentStrings:
          state.samplePreviewUtf16leStrings.contentStrings +
          newPreviewUtf16leStrings.contentStrings,
      }
      state.samplePreviewUtf16leStringsLoading = false
    },
    fetchSamplePreviewUtf16leStringsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.samplePreviewUtf16leStrings =
        initialState.samplePreviewUtf16leStrings
      state.samplePreviewUtf16leStringsLoading = false
    },
    fetchSamplePreviewUtf16leStringsCancelled(state) {
      state.samplePreviewUtf16leStringsLoading = false
    },
    resetSamplePreviewUtf16leStrings(state) {
      state.samplePreviewUtf16leStrings =
        initialState.samplePreviewUtf16leStrings
    },
    fetchSamplePreviewStackStrings(
      state,
      _action: PayloadAction<IFetchSamplePreviewPayload>
    ) {
      state.samplePreviewStackStringsLoading = true
    },
    fetchSamplePreviewStackStringsFulfilled(
      state,
      action: PayloadAction<ISamplePreviewStrings>
    ) {
      const newPreviewStackStrings = action.payload
      state.samplePreviewStackStrings = {
        nextPage: newPreviewStackStrings.nextPage,
        contentStrings:
          state.samplePreviewStackStrings.contentStrings +
          newPreviewStackStrings.contentStrings,
      }
      state.samplePreviewStackStringsLoading = false
    },
    fetchSamplePreviewStackStringsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.samplePreviewStackStrings = initialState.samplePreviewStackStrings
      state.samplePreviewStackStringsLoading = false
    },
    fetchSamplePreviewStackStringsCancelled(state) {
      state.samplePreviewStackStringsLoading = false
    },
    resetSamplePreviewStackStrings(state) {
      state.samplePreviewStackStrings = initialState.samplePreviewStackStrings
    },
    fetchSamplePreviewHex(
      state,
      _action: PayloadAction<IFetchSamplePreviewPayload>
    ) {
      state.samplePreviewHexLoading = true
    },
    fetchSamplePreviewHexFulfilled(
      state,
      action: PayloadAction<ISamplePreviewHex>
    ) {
      const newPreviewHex = action.payload
      state.samplePreviewHex = {
        nextPage: newPreviewHex.nextPage,
        contentHex:
          state.samplePreviewHex.contentHex === ''
            ? newPreviewHex.contentHex
            : `${state.samplePreviewHex.contentHex}\n${newPreviewHex.contentHex}`,
        contentBytes:
          state.samplePreviewHex.contentBytes +
          window.atob(newPreviewHex.contentBytes),
      }
      state.samplePreviewHexLoading = false
    },
    fetchSamplePreviewHexRejected(state, _action: PayloadAction<AjaxError>) {
      state.samplePreviewHex = initialState.samplePreviewHex
      state.samplePreviewHexLoading = false
    },
    fetchSamplePreviewHexCancelled(state) {
      state.samplePreviewHexLoading = false
    },
    resetSamplePreviewHex(state) {
      state.samplePreviewHex = initialState.samplePreviewHex
    },
    fetchSampleSandbox(state, _action: PayloadAction<string>) {
      state.sampleSandboxLoading = true
    },
    fetchSampleSandboxFulfilled(state, action: PayloadAction<ISampleSandbox>) {
      state.sampleSandbox = action.payload
      state.sampleSandboxLoading = false
    },
    fetchSampleSandboxRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleSandbox = initialState.sampleSandbox
      state.sampleSandboxLoading = false
    },
    fetchSampleSandboxCancelled(state) {
      state.sampleSandboxLoading = false
    },
    fetchSampleOSINTs(state, _action: PayloadAction<string>) {
      state.sampleOSINTsLoading = true
    },
    fetchSampleOSINTsFulfilled(state, action: PayloadAction<IOSINT[]>) {
      state.sampleOSINTs = action.payload
      state.sampleOSINTsLoading = false
    },
    fetchSampleOSINTsRejected(state, _action: PayloadAction<AjaxError>) {
      state.sampleOSINTs = initialState.sampleOSINTs
      state.sampleOSINTsLoading = false
    },
    fetchSampleOSINTsCancelled(state) {
      state.sampleOSINTsLoading = false
    },
  },
})

export const {
  fetchSampleDefaultShare,
  fetchSampleDefaultShareFulfilled,
  fetchSampleDefaultShareRejected,
  fetchSampleDefaultShareCancelled,
  fetchSampleCompleteShareTargets,
  fetchSampleCompleteShareTargetsFulfilled,
  fetchSampleCompleteShareTargetsRejected,
  fetchSampleCompleteShareTargetsCancelled,
  resetSampleCompleteShareTargets,
  fetchSampleFindShareTarget,
  fetchSampleFindShareTargetFulfilled,
  fetchSampleFindShareTargetRejected,
  fetchSampleFindShareTargetCancelled,
  resetSampleFindShareTarget,
  updateSampleShareTarget,
  updateSampleShareTargetFulfilled,
  updateSampleShareTargetRejected,
  fetchSampleDetail,
  fetchSampleDetailFulfilled,
  fetchSampleDetailRejected,
  fetchSampleDetailCancelled,
  fetchSampleRelationsCount,
  fetchSampleRelationsCountFulfilled,
  fetchSampleRelationsCountRejected,
  fetchSampleRelationsCountCancelled,
  fetchSampleRelationIPs,
  fetchSampleRelationIPsFulfilled,
  fetchSampleRelationIPsRejected,
  fetchSampleRelationIPsCancelled,
  resetSampleRelationIPs,
  fetchSampleRelationDomains,
  fetchSampleRelationDomainsFulfilled,
  fetchSampleRelationDomainsRejected,
  fetchSampleRelationDomainsCancelled,
  resetSampleRelationDomains,
  fetchSampleRelationChildSamples,
  fetchSampleRelationChildSamplesFulfilled,
  fetchSampleRelationChildSamplesRejected,
  fetchSampleRelationChildSamplesCancelled,
  resetSampleRelationChildSamples,
  fetchSampleRelationParentSamples,
  fetchSampleRelationParentSamplesFulfilled,
  fetchSampleRelationParentSamplesRejected,
  fetchSampleRelationParentSamplesCancelled,
  resetSampleRelationParentSamples,
  fetchSampleRelationBundleSamples,
  fetchSampleRelationBundleSamplesFulfilled,
  fetchSampleRelationBundleSamplesRejected,
  fetchSampleRelationBundleSamplesCancelled,
  resetSampleRelationBundleSamples,
  fetchSamplePreviewAsciiStrings,
  fetchSamplePreviewAsciiStringsFulfilled,
  fetchSamplePreviewAsciiStringsRejected,
  fetchSamplePreviewAsciiStringsCancelled,
  resetSamplePreviewAsciiStrings,
  fetchSamplePreviewUtf16leStrings,
  fetchSamplePreviewUtf16leStringsFulfilled,
  fetchSamplePreviewUtf16leStringsRejected,
  fetchSamplePreviewUtf16leStringsCancelled,
  resetSamplePreviewUtf16leStrings,
  fetchSamplePreviewStackStrings,
  fetchSamplePreviewStackStringsFulfilled,
  fetchSamplePreviewStackStringsRejected,
  fetchSamplePreviewStackStringsCancelled,
  resetSamplePreviewStackStrings,
  fetchSamplePreviewHex,
  fetchSamplePreviewHexFulfilled,
  fetchSamplePreviewHexRejected,
  fetchSamplePreviewHexCancelled,
  resetSamplePreviewHex,
  fetchSampleSandbox,
  fetchSampleSandboxFulfilled,
  fetchSampleSandboxRejected,
  fetchSampleSandboxCancelled,
  fetchSampleOSINTs,
  fetchSampleOSINTsFulfilled,
  fetchSampleOSINTsRejected,
  fetchSampleOSINTsCancelled,
} = sampleDetailSlice.actions

export const selectSampleDefaultShareLoading = (state: TRootState) =>
  state.sampleDetail.sampleDefaultShareLoading
export const selectSampleDefaultShare = (state: TRootState) =>
  state.sampleDetail.sampleDefaultShare
export const selectSampleCompleteShareTargetsLoading = (state: TRootState) =>
  state.sampleDetail.sampleCompleteShareTargetsLoading
export const selectSampleCompleteShareTargets = (state: TRootState) =>
  state.sampleDetail.sampleCompleteShareTargets
export const selectSampleFindShareTargetLoading = (state: TRootState) =>
  state.sampleDetail.sampleFindShareTargetLoading
export const selectSampleFindShareTarget = (state: TRootState) =>
  state.sampleDetail.sampleFindShareTarget
export const selectUpdateShareTargetLoading = (state: TRootState) =>
  state.sampleDetail.sampleShareTargetUpdating
export const selectSampleDetailLoading = (state: TRootState) =>
  state.sampleDetail.sampleDetailLoading
export const selectSampleDetail = (state: TRootState) =>
  state.sampleDetail.sampleDetail
export const selectSampleRelationsCountLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationsCountLoading
export const selectSampleRelationsCount = (state: TRootState) =>
  state.sampleDetail.sampleRelationsCount
export const selectHasMoreSampleRelationIPs = (state: TRootState) =>
  state.sampleDetail.hasMoreSampleRelationIPs
export const selectSampleRelationIPsLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationIPsLoading
export const selectSampleRelationIPs = (state: TRootState) =>
  state.sampleDetail.sampleRelationIPs
export const selectHasMoreSampleRelationDomains = (state: TRootState) =>
  state.sampleDetail.hasMoreSampleRelationDomains
export const selectSampleRelationDomainsLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationDomainsLoading
export const selectSampleRelationDomains = (state: TRootState) =>
  state.sampleDetail.sampleRelationDomains
export const selectHasMoreSampleRelationChildSamples = (state: TRootState) =>
  state.sampleDetail.hasMoreSampleRelationChildSamples
export const selectSampleRelationChildSamplesLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationChildSamplesLoading
export const selectSampleRelationChildSamples = (state: TRootState) =>
  state.sampleDetail.sampleRelationChildSamples
export const selectHasMoreSampleRelationParentSamples = (state: TRootState) =>
  state.sampleDetail.hasMoreSampleRelationParentSamples
export const selectSampleRelationParentSamplesLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationParentSamplesLoading
export const selectSampleRelationParentSamples = (state: TRootState) =>
  state.sampleDetail.sampleRelationParentSamples
export const selectHasMoreSampleRelationBundleSamples = (state: TRootState) =>
  state.sampleDetail.hasMoreSampleRelationBundleSamples
export const selectSampleRelationBundleSamplesLoading = (state: TRootState) =>
  state.sampleDetail.sampleRelationBundleSamplesLoading
export const selectSampleRelationBundleSamples = (state: TRootState) =>
  state.sampleDetail.sampleRelationBundleSamples
export const selectSamplePreviewAsciiStringsLoading = (state: TRootState) =>
  state.sampleDetail.samplePreviewAsciiStringsLoading
export const selectSamplePreviewAsciiStrings = (state: TRootState) =>
  state.sampleDetail.samplePreviewAsciiStrings
export const selectSamplePreviewUtf16leStringsLoading = (state: TRootState) =>
  state.sampleDetail.samplePreviewUtf16leStringsLoading
export const selectSamplePreviewUtf16leStrings = (state: TRootState) =>
  state.sampleDetail.samplePreviewUtf16leStrings
export const selectSamplePreviewStackStringsLoading = (state: TRootState) =>
  state.sampleDetail.samplePreviewStackStringsLoading
export const selectSamplePreviewStackStrings = (state: TRootState) =>
  state.sampleDetail.samplePreviewStackStrings
export const selectSamplePreviewHexLoading = (state: TRootState) =>
  state.sampleDetail.samplePreviewHexLoading
export const selectSamplePreviewHex = (state: TRootState) =>
  state.sampleDetail.samplePreviewHex
export const selectSampleSandboxLoading = (state: TRootState) =>
  state.sampleDetail.sampleSandboxLoading
export const selectSampleSandbox = (state: TRootState) =>
  state.sampleDetail.sampleSandbox
export const selectSampleOSINTsLoading = (state: TRootState) =>
  state.sampleDetail.sampleOSINTsLoading
export const selectSampleOSINTs = (state: TRootState) =>
  state.sampleDetail.sampleOSINTs

export default sampleDetailSlice.reducer
