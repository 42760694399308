import type { Action } from '@reduxjs/toolkit'
import {
  getThreatLandscapeCountryRelatedAdversariesByTarget,
  getThreatLandscapeCountryRelatedReportsByTarget,
  getThreatLandscapeIndustryBuzzwordsByTarget,
  getThreatLandscapeIndustryRelatedAdversariesByTarget,
  getThreatLandscapeIndustryRelatedReportsByTarget,
  getThreatLandscapeRegionRelatedAdversariesByTarget,
  getThreatLandscapeRegionRelatedReportsByTarget,
} from 'constants/api'
import { ALL_REPORT_TYPES_QUERY } from 'constants/urlQuery'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchCountryRelatedAdversaries,
  fetchCountryRelatedReports,
  fetchIndustryBuzzwords,
  fetchIndustryBuzzwordsCancelled,
  fetchIndustryBuzzwordsFulfilled,
  fetchIndustryBuzzwordsRejected,
  fetchIndustryRelatedAdversaries,
  fetchIndustryRelatedReports,
  fetchRegionRelatedAdversaries,
  fetchRegionRelatedReports,
  fetchRelatedAdversariesCancelled,
  fetchRelatedAdversariesFulfilled,
  fetchRelatedAdversariesRejected,
  fetchRelatedReportsCancelled,
  fetchRelatedReportsFulfilled,
  fetchRelatedReportsRejected,
} from 'store/slices/threatLandscape'
import { mapAPIAdversariesToState } from 'store/types/entityTypes/adversary'
import { mapAPIReportsToState } from 'store/types/entityTypes/report'
import {
  IFetchIndustryBuzzwordsResponse,
  IFetchRelatedAdversariesResponse,
  IFetchRelatedReportsResponse,
} from 'store/types/slicesTypes/threatLandscape'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const countryRelatedAdversariesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCountryRelatedAdversaries.match),
    switchMap((action) =>
      fetchData<IFetchRelatedAdversariesResponse>({
        url: getThreatLandscapeCountryRelatedAdversariesByTarget(
          action.payload
        ),
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedAdversariesResponse>) =>
          fetchRelatedAdversariesFulfilled(
            mapAPIAdversariesToState(response.response.related_adversaries)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedAdversariesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedAdversariesRejected(error))
          )
        )
      )
    )
  )

const industryRelatedAdversariesEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchIndustryRelatedAdversaries.match),
    switchMap((action) =>
      fetchData<IFetchRelatedAdversariesResponse>({
        url: getThreatLandscapeIndustryRelatedAdversariesByTarget(
          action.payload
        ),
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedAdversariesResponse>) =>
          fetchRelatedAdversariesFulfilled(
            mapAPIAdversariesToState(response.response.related_adversaries)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedAdversariesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedAdversariesRejected(error))
          )
        )
      )
    )
  )

const regionRelatedAdversariesEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchRegionRelatedAdversaries.match),
    switchMap((action) =>
      fetchData<IFetchRelatedAdversariesResponse>({
        url: getThreatLandscapeRegionRelatedAdversariesByTarget(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedAdversariesResponse>) =>
          fetchRelatedAdversariesFulfilled(
            mapAPIAdversariesToState(response.response.related_adversaries)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedAdversariesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedAdversariesRejected(error))
          )
        )
      )
    )
  )

const countryRelatedReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCountryRelatedReports.match),
    switchMap((action) =>
      fetchData<IFetchRelatedReportsResponse>({
        url: `${getThreatLandscapeCountryRelatedReportsByTarget(
          action.payload
        )}?${ALL_REPORT_TYPES_QUERY}`,
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedReportsResponse>) =>
          fetchRelatedReportsFulfilled(
            mapAPIReportsToState(response.response.related_reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedReportsRejected(error))
          )
        )
      )
    )
  )

const industryRelatedReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIndustryRelatedReports.match),
    switchMap((action) =>
      fetchData<IFetchRelatedReportsResponse>({
        url: getThreatLandscapeIndustryRelatedReportsByTarget(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedReportsResponse>) =>
          fetchRelatedReportsFulfilled(
            mapAPIReportsToState(response.response.related_reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedReportsRejected(error))
          )
        )
      )
    )
  )

const regionRelatedReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchRegionRelatedReports.match),
    switchMap((action) =>
      fetchData<IFetchRelatedReportsResponse>({
        url: `${getThreatLandscapeRegionRelatedReportsByTarget(
          action.payload
        )}?${ALL_REPORT_TYPES_QUERY}`,
      }).pipe(
        map((response: AjaxResponse<IFetchRelatedReportsResponse>) =>
          fetchRelatedReportsFulfilled(
            mapAPIReportsToState(response.response.related_reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchRelatedReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRelatedReportsRejected(error))
          )
        )
      )
    )
  )

const relatedIndustryBuzzwordsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIndustryBuzzwords.match),
    switchMap((action) =>
      fetchData<IFetchIndustryBuzzwordsResponse>({
        url: getThreatLandscapeIndustryBuzzwordsByTarget(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchIndustryBuzzwordsResponse>) =>
          fetchIndustryBuzzwordsFulfilled(response.response.buzzwords)
        ),
        takeUntil(action$.pipe(filter(fetchIndustryBuzzwordsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchIndustryBuzzwordsRejected(error))
          )
        )
      )
    )
  )

export const threatLandscapeEpic = combineEpics(
  countryRelatedAdversariesEpic,
  industryRelatedAdversariesEpic,
  regionRelatedAdversariesEpic,
  countryRelatedReportsEpic,
  industryRelatedReportsEpic,
  regionRelatedReportsEpic,
  relatedIndustryBuzzwordsEpic
)
