import { useCallback, useLayoutEffect, useState } from 'react'

// 主要用在偵測 infinite scroll 的區塊，偵測內容是否高於 container 導致捲動與否
export const useIsScrollable = (dependencies: unknown[]) => {
  const [node, setNode] = useState<HTMLDivElement>()
  const ref = useCallback((refNode: HTMLDivElement) => {
    setNode(refNode)
  }, [])

  const [isScrollable, setIsScrollable] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (!node) return

    setIsScrollable(node.scrollHeight > node.clientHeight)
  }, [...dependencies, node])

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      if (node) {
        setIsScrollable(node.scrollHeight > node.clientHeight)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [node])

  return [isScrollable, ref, node] as const
}
