import { Box, FormControlLabel, Typography, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { useSearchReportTypeOptions } from 'hooks/useSearchReportTypeOptions'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TSearchReportType } from 'store/types/entityTypes/report'
import { ISearchReportTypeOption } from 'store/types/slicesTypes/search'

interface IReportTypeSelectorProps {
  selectedReportTypes: TSearchReportType[]
  handleSelectReportTypesChange: (
    newReportTypeOptions: TSearchReportType[]
  ) => void
}

export const ReportTypeSelector = ({
  selectedReportTypes,
  handleSelectReportTypesChange,
}: IReportTypeSelectorProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['download', 'search'])
  const searchReportTypeOptions = useSearchReportTypeOptions()

  const handleReportTypeCheck = (
    event: ChangeEvent<HTMLInputElement>,
    reportTypeOption: ISearchReportTypeOption
  ) => {
    if (event.target.checked) {
      const newReportTypeOptions: TSearchReportType[] = [
        reportTypeOption.key,
        ...selectedReportTypes,
      ]
      handleSelectReportTypesChange(newReportTypeOptions)
    } else {
      const newReportTypeOptions: TSearchReportType[] =
        selectedReportTypes.filter(
          (selectedReportType) => selectedReportType !== reportTypeOption.key
        )
      handleSelectReportTypesChange(newReportTypeOptions)
    }
  }

  const isSelectedAll = selectedReportTypes.length > 0
  const isSelectedIndeterminate =
    selectedReportTypes.length > 0 &&
    selectedReportTypes.length < searchReportTypeOptions.length

  const handleCheckAllTypes = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || isSelectedIndeterminate) {
      handleSelectReportTypesChange(
        searchReportTypeOptions.map((option) => option.key)
      )
    } else {
      handleSelectReportTypesChange([])
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
      <Typography color={theme.colors.WHITE} variant="textSmallImportant">
        {t('reportTypeFilter.label', { ns: 'search' })}
      </Typography>
      <Box>
        <FormControlLabel
          sx={{
            mr: 2,
            '& .MuiFormControlLabel-label': {
              color: theme.colors.WHITE,
              ...theme.typography.body,
            },
          }}
          control={
            <Checkbox
              checked={isSelectedAll}
              indeterminate={isSelectedIndeterminate}
              onChange={handleCheckAllTypes}
            />
          }
          label={
            <Typography sx={{ color: theme.colors.WHITE }} variant="body">
              {t('reportTypeFilter.all', { ns: 'search' })}
            </Typography>
          }
        />
        {searchReportTypeOptions.map((option) => (
          <FormControlLabel
            key={option.key}
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.colors.WHITE,
                ...theme.typography.body,
              },
            }}
            control={
              <Checkbox
                checked={selectedReportTypes.some(
                  (selectedReportType: TSearchReportType) =>
                    selectedReportType === option.key
                )}
                onChange={(event) => handleReportTypeCheck(event, option)}
              />
            }
            label={option.displayName}
          />
        ))}
      </Box>
    </Box>
  )
}
