import { Box } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const ReportLanguageLoading = () => (
  <Box sx={{ display: 'flex', gap: 2 }}>
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <Skeleton type="body" width="1rem" />
      <Skeleton type="body" width="4.5rem" />
    </Box>
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <Skeleton type="body" width="1rem" />
      <Skeleton type="body" width="4.5rem" />
    </Box>
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <Skeleton type="body" width="1rem" />
      <Skeleton type="body" width="4.5rem" />
    </Box>
  </Box>
)
