import { Box, Divider, Stack, useTheme } from '@mui/material'
import { ReactNode } from 'react'

import { Description } from './Description'
import { H2ColorTitle } from './H2ColorTitle'

export const ExampleReportBlock = ({
  title,
  description,
  children,
}: {
  title: string
  description?: string
  children: NonNullable<ReactNode>
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `1px solid ${theme.colors.PRIMARY}`,
        borderRadius: 0.5,
        pt: 1,
        pb: 4,
        bgcolor: theme.colors.BLACK_90,
      }}
    >
      <Stack sx={{ mx: 4, gap: 2 }}>
        <Stack>
          <H2ColorTitle title={title} />
          {description && <Description>{description}</Description>}
        </Stack>
        <Divider sx={{ bgcolor: theme.colors.PRIMARY }} />
        {children}
      </Stack>
    </Box>
  )
}
