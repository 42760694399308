import { Box, SxProps, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface IBlockTitleProps {
  title?: string
  titleInfo?: NonNullable<ReactNode>
  titleAction?: NonNullable<ReactNode>
  sx?: SxProps
}
export const BlockTitle = ({
  title,
  titleInfo,
  titleAction,
  sx = [],
}: IBlockTitleProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          minHeight: '2rem',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          px: 4,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography
          variant="title"
          sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
        >
          {title}
        </Typography>
        {titleInfo}
      </Box>
      <Box sx={{ display: 'flex' }}>{titleAction}</Box>
    </Box>
  )
}

export const BlockSubTitle = ({
  title,
  titleInfo,
  titleAction,
  sx = [],
}: IBlockTitleProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          minHeight: '2rem',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          px: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography
          variant="textSmallImportant"
          sx={{ color: theme.colors.WHITE }}
        >
          {title}
        </Typography>
        {titleInfo}
      </Box>
      {titleAction}
    </Box>
  )
}
