import { Box, Stack, Typography } from '@mui/material'
import ThreatVisionBanner from 'assets/threat-vision-banner.png'
import { TextLink } from 'components/Link/Link'
import { SearchBar } from 'components/SearchBar/SearchBar'
import { LocalStorageKey } from 'constants/localStorageKeys'
import { PAGE_TITLE_SEARCH } from 'constants/pageTitle'
import { SEARCH_RESULT_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const SearchPage = () => {
  const { t } = useTranslation(['search'])

  const getSearchLink = (searchText: string) =>
    `${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${searchText}`

  const searchHistoryJSON = localStorage.getItem(LocalStorageKey.SEARCH_HISTORY)

  const searchHistory =
    searchHistoryJSON && Array.isArray(JSON.parse(searchHistoryJSON))
      ? (JSON.parse(searchHistoryJSON) as string[])
      : []

  const displaySearchHistory = Array.from(new Set(searchHistory))

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_SEARCH}</title>
      </Helmet>
      <Stack sx={{ alignItems: 'center', height: '100%', overflow: 'auto' }}>
        <Box sx={{ mt: 30 }}>
          <Image
            width="22.5rem"
            height="5.625rem"
            src={ThreatVisionBanner}
            alt="ThreatVision"
          />
        </Box>
        <Box sx={{ width: '53.75rem', height: '3rem', mt: 16 }}>
          <SearchBar onSearchEntry />
        </Box>
        {displaySearchHistory.length > 0 && (
          <Box sx={{ mt: 14, textAlign: 'center' }}>
            <Box>
              <Typography variant="title" sx={{ color: 'white' }}>
                {t('searchHistory', { ns: 'search' })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                p: 4,
              }}
            >
              <Box sx={{ display: 'flex', gap: 4 }}>
                {displaySearchHistory.map((keyword) => (
                  <TextLink key={keyword} to={getSearchLink(keyword)}>
                    {keyword}
                  </TextLink>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Stack>
    </>
  )
}
