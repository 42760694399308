import {
  Box,
  Drawer as MuiDrawer,
  DrawerProps,
  Stack,
  SvgIcon,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'

export interface IDrawerProps extends DrawerProps {
  onClose: () => void
  loading?: boolean
}

export const Drawer = ({
  onClose,
  children,
  open,
  loading,
  sx = [],
  ...otherProps
}: IDrawerProps) => {
  const theme = useTheme()
  return (
    <MuiDrawer
      sx={Array.isArray(sx) ? sx : [sx]}
      PaperProps={{
        sx: {
          bgcolor: theme.colors.BLACK_95,
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
      {...otherProps}
    >
      <Stack
        sx={{
          height: '100%',
          width: '60.625rem',
          px: 40,
          py: 12,
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            width: '2.5rem',
            height: '2.5rem',
            border: `1px solid ${theme.colors.WHITE_60}`,
            p: 2,
            borderRadius: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors.PRIMARY_20,
            },
          }}
          onClick={onClose}
        >
          <SvgIcon
            sx={{ width: '1.5rem', height: '1.5rem' }}
            component={CloseIcon}
            inheritViewBox
          />
        </Box>
        {loading ? <CenterCircularProgress /> : children}
      </Stack>
    </MuiDrawer>
  )
}
