import { getPageTitleThreatLandscapeRegionByName } from 'constants/pageTitle'
import { THREAT_LANDSCAPE_ROUTE } from 'constants/routeParams'
import { useTargetedRegions } from 'hooks/useTargetedRegions'
import Image from 'mui-image'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate,useParams } from 'react-router-dom'

import { LandscapePageContainer } from './LandscapePageContainer/LandscapePageContainer'

export const RegionSubPage = () => {
  const navigate = useNavigate()
  const { target } = useParams()
  const targetedRegions = useTargetedRegions()
  const targetRegion = targetedRegions.find(
    (region) => region.targetName === target
  )

  // redirect to landscape index page if country is not exists
  useEffect(() => {
    if (!targetRegion) {
      navigate(`/${THREAT_LANDSCAPE_ROUTE}`)
    }
  }, [targetRegion])

  return targetRegion && targetRegion.graphicImageSrc ? (
    <>
      <Helmet>
        <title>
          {getPageTitleThreatLandscapeRegionByName(targetRegion.displayName)}
        </title>
      </Helmet>
      <LandscapePageContainer
        target={targetRegion}
        targetType="region"
        TargetGraphic={<Image src={targetRegion.graphicImageSrc} />}
      />
    </>
  ) : null
}
