type TOrder = 'asc' | 'desc'

export const stringComparator = (
  stringA: string,
  stringB: string,
  order: TOrder
) => {
  const isDesc = order === 'desc'
  // ignore upper and lowercase
  const nameA = stringA.toUpperCase()
  const nameB = stringB.toUpperCase()

  if (nameA < nameB) {
    return isDesc ? 1 : -1
  }
  if (nameA > nameB) {
    return isDesc ? -1 : 1
  }

  return 0
}

export const numberComparator = (
  numberA: number,
  numberB: number,
  order: TOrder
) => {
  const isDesc = order === 'desc'

  if (numberA < numberB) {
    return isDesc ? 1 : -1
  }
  if (numberA > numberB) {
    return isDesc ? -1 : 1
  }

  return 0
}
