import { useEffect,useState } from 'react'
import { getImageUrl } from 'utils/image'

// 後端圖片會放在不同的 end point 下，需要去除 domain 取得相對路徑來做 proxy
export const useImageUrl = ({ fetchImageUrl }: { fetchImageUrl: string }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const handleFetchImageUrl = async () => {
    if (fetchImageUrl.includes('/report_assets')) {
      setImageUrl(
        fetchImageUrl.substring(fetchImageUrl.indexOf('/report_assets'))
      )
    } else if (fetchImageUrl.includes('/assets/karen')) {
      setImageUrl(
        fetchImageUrl.substring(fetchImageUrl.indexOf('/assets/karen'))
      )
    } else if (fetchImageUrl.includes('/avatar')) {
      setImageUrl(fetchImageUrl.substring(fetchImageUrl.indexOf('/avatar')))
    } else {
      // 此處都會是在 /api/v1 下的圖片，需要呼叫 getImageUrl 去做 fetch request
      const url = await getImageUrl(fetchImageUrl)

      if (url) {
        setImageUrl(url)
      }
    }
  }

  useEffect(() => {
    handleFetchImageUrl()
  }, [fetchImageUrl])

  if (!fetchImageUrl) {
    return ''
  }

  return imageUrl
}
