import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { NoData } from 'components/NoData/NoData'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchTabSamples,
  fetchTabSamplesCancelled,
  resetTabSampleContent,
  selectHasMoreTabSamples,
  selectTabSampleDataLoading,
  selectTabSamples,
} from 'store/slices/tabContent'

const SCROLLABLE_CONTAINER_ID = 'tab-samples-scrollable-container'

interface ISampleTabContentProps {
  searchText: string
}

export const SampleTabContent = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const tabSamples = useAppSelector(selectTabSamples)
  const isTabSampleLoading = useAppSelector(selectTabSampleDataLoading)
  const hasMoreTabSamples = useAppSelector(selectHasMoreTabSamples)

  const [isScrollable, ref, node] = useIsScrollable([tabSamples])

  const handleFetchTabSamples = (refetch?: boolean) => {
    dispatch(
      fetchTabSamples({ searchText, offset: refetch ? 0 : tabSamples.length })
    )
  }

  // fetch initial tab samples
  useEffect(() => {
    handleFetchTabSamples(true)
    return () => {
      dispatch(fetchTabSamplesCancelled())
      dispatch(resetTabSampleContent())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreTabSamples) {
      handleFetchTabSamples()
    }
  }, [isScrollable, hasMoreTabSamples, node, tabSamples.length])

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
    >
      {tabSamples.length === 0 ? (
        isTabSampleLoading ? (
          <SampleLoading />
        ) : (
          <NoData />
        )
      ) : (
        <InfiniteScrollWrapper
          dataLength={tabSamples.length}
          next={handleFetchTabSamples}
          hasMore={hasMoreTabSamples}
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
        >
          {tabSamples.map((sample) => (
            <Box
              key={sample.id}
              sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
            >
              <SamplePreview {...sample} />
            </Box>
          ))}
        </InfiniteScrollWrapper>
      )}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
