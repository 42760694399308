import { Box, Stack, useTheme } from '@mui/material'
import { MalwarePreview } from 'components/Malware/MalwarePreview'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { getPageTitleMalwareDetailByName } from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import {
  fetchMalware,
  fetchMalwareCancelled,
  selectMalware,
  selectMalwareLoading,
} from 'store/slices/malware'

import { useMalwareCategoryTabs } from './config'
import { MalwareCategoryTabs } from './MalwareCategoryTabs'
import { MalwareContentLoading } from './MalwareContentLoading'

export const MalwareContentPage = () => {
  useGoBack()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMalwareLoading = useAppSelector(selectMalwareLoading)
  const malware = useAppSelector(selectMalware)
  const [searchParams] = useSearchParams()
  const malwareId = searchParams.get(SearchParamKey.MALWARE_ID)

  const malwareCategoryTabs = useMalwareCategoryTabs()
  const defaultCategoryTab = malwareCategoryTabs[0]

  const categoryTabParam = searchParams.get(SearchParamKey.MALWARE_TAB)
  const categoryTab =
    (categoryTabParam &&
      malwareCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const BottomContent = categoryTab.component

  useEffect(() => {
    if (malwareId) {
      dispatch(fetchMalware({ id: malwareId }))
    }

    return () => {
      dispatch(fetchMalwareCancelled())
    }
  }, [])

  return !malware || isMalwareLoading ? (
    <MalwareContentLoading />
  ) : (
    <>
      <Helmet>
        <title>{getPageTitleMalwareDetailByName(malware.name)}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack
          sx={{
            p: 1,
            gap: 1,
            height: '100%',
            width: '78rem',
          }}
        >
          <Box sx={{ flexShrink: 0 }}>
            <MalwarePreview showDescription {...malware} />
          </Box>
          <PageTabsContent
            tabs={
              <MalwareCategoryTabs
                malwareId={malware.id}
                categoryTab={categoryTab}
              />
            }
            content={<BottomContent searchText={malware.id} />}
          />
        </Stack>
      </Box>
    </>
  )
}
