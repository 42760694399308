import { Box } from '@mui/material'
import { IPPreview } from 'components/IP/IPPreview'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { OneLineLoading } from 'components/Loading/OneLineLoading'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchIPsData,
  fetchSearchIPsDataCancelled,
  resetSearchIPsData,
  selectSearchIPs,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'domain-scrollable-container'

export const IPContent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['search'])
  const [searchParams] = useSearchParams()
  const ips = useAppSelector(selectSearchIPs)
  const ipsCurrentCount = ips.data.length
  const isIPsLoading = ips.isDataLoading
  const hasMoreIps = ips.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchIPs = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchIPsData({
          searchText,
          offset: refetch ? 0 : ipsCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchIPs(true)
    return () => {
      dispatch(fetchSearchIPsDataCancelled())
      dispatch(resetSearchIPsData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreIps && ipsCurrentCount > 0) {
      fetchIPs()
    }
  }, [inView, hasMoreIps, ipsCurrentCount])

  if (isIPsLoading && ipsCurrentCount === 0) {
    return <OneLineLoading />
  }

  if (ipsCurrentCount === 0 && !isIPsLoading) {
    return (
      <NoResultContent
        text={t('downloadList.emptyState.invalidIp', { ns: 'search' })}
      />
    )
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      {ips.data.map((ip) => (
        <IPPreview key={ip.address} {...ip} />
      ))}
      {hasMoreIps && <CenterCircularProgress scrollRef={ref} />}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
