import {
  Box,
  CircularProgress,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowCubeIcon } from 'assets/basicIcons/arrowCube.svg'
import { ReactComponent as CheckIcon } from 'assets/basicIcons/check.svg'
import { Menu, MenuItem } from 'components/Menu/Menu'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectIsFetchAccountSettingsLoading,
  selectLocale,
  updateAccountSettings,
  updateSystemLocale,
} from 'store/slices/account'
import { TLocale } from 'store/types/entityTypes/account'

const LanguageSwitcher = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isAccountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )
  const locale = useAppSelector(selectLocale)
  const { t } = useTranslation(['footer'])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  type TLocaleKey = Exclude<TLocale, null> | 'null'

  const localeNativeNameMap: Record<TLocaleKey, string> = {
    null: t('languageSwitcher.auto', { ns: 'footer' }),
    en: t('languageSwitcher.english', { ns: 'footer' }),
    'zh-TW': t('languageSwitcher.chinese', { ns: 'footer' }),
    ja: t('languageSwitcher.japanese', { ns: 'footer' }),
  }

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLanguageButtonClick = (language: TLocaleKey) => {
    const newLocale = language === 'null' ? null : language
    dispatch(
      updateAccountSettings({
        tip_user: {
          merge_preferences: {
            locale: newLocale,
          },
        },
      })
    )
    dispatch(updateSystemLocale(newLocale))
    handleClose()
  }

  if (isAccountSettingsLoading) {
    return <CircularProgress size={12} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        pl: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="buttonSText"
            sx={{ color: theme.colors.WHITE_60 }}
          >
            {localeNativeNameMap[locale as TLocaleKey]}
          </Typography>
        </Box>
        <SvgIcon
          sx={{
            width: '0.75rem',
            height: '0.75rem',
            ml: 0.5,
          }}
          component={ArrowCubeIcon}
          inheritViewBox
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        PaperPropsSx={{ width: '5rem' }}
      >
        {Object.keys(localeNativeNameMap).map((language: TLocaleKey) => {
          const isSelected = String(locale) === language

          return (
            <MenuItem
              key={language}
              selected={isSelected}
              handleClick={() => handleLanguageButtonClick(language)}
              size="small"
            >
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {isSelected && (
                  <SvgIcon
                    sx={{ width: '1rem', height: '1rem' }}
                    component={CheckIcon}
                    inheritViewBox
                  />
                )}
                <Box sx={{ pl: isSelected ? 0 : 5 }}>
                  {localeNativeNameMap[language]}
                </Box>
              </Box>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

export default LanguageSwitcher
