import { Box, SxProps, Typography, useTheme } from '@mui/material'

export interface IPageTabProps {
  selected: boolean
  name: string
  handleTabClick: () => void
  sx?: SxProps
}

export const PageTab = ({
  selected,
  name,
  handleTabClick,
  sx = [],
}: IPageTabProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          px: 4,
          py: 1.5,
          mb: -0.5,
          borderBottom: selected ? `2px solid ${theme.colors.PRIMARY}` : 'none',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.colors.PRIMARY_40,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleTabClick}
    >
      <Typography
        variant="title"
        sx={{
          color: selected ? theme.colors.PRIMARY : theme.colors.WHITE,
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}
