import { Box, Typography } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchCounts,
  fetchCountsCancelled,
  selectCounts,
  selectCountsLoading,
} from 'store/slices/landing'
import { ICounts } from 'store/types/entityTypes/counts'

import { CountsBoardLoading } from './CountsBoardLoading'

export const COUNTS_BOARD_CONTAINER_TEST_ID = 'counts-board-container'

export const CountsBoard = () => {
  const dispatch = useAppDispatch()
  const countsLoading = useAppSelector(selectCountsLoading)
  const counts = useAppSelector(selectCounts)

  const countsNotReady = Object.values(counts).some((count) => count === null)

  const { t } = useTranslation(['landingPage'])

  const countsDisplayNameMap: Record<keyof ICounts, string> = {
    reportCount: t('countsBoard.reports', { ns: 'landingPage' }),
    indicatorCount: t('countsBoard.indicators', { ns: 'landingPage' }),
    sampleCount: t('countsBoard.samples', { ns: 'landingPage' }),
    malwareCount: t('countsBoard.malware', { ns: 'landingPage' }),
    adversaryCount: t('countsBoard.adversaries', { ns: 'landingPage' }),
  }

  useEffect(() => {
    dispatch(fetchCounts())
    return () => {
      dispatch(fetchCountsCancelled())
    }
  }, [])

  const countList = Object.keys(counts).map((key: keyof ICounts) => ({
    name: key,
    count: counts[key],
  }))

  return (
    <Box data-testid={COUNTS_BOARD_CONTAINER_TEST_ID}>
      <PageSection>
        <Box
          sx={{
            bgcolor: (theme) => theme.colors.BLACK_90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {countsLoading || countsNotReady ? (
            <CountsBoardLoading />
          ) : (
            countList.map((countItem) => (
              <Box key={countItem.name} sx={{ textAlign: 'center', mb: 1 }}>
                <Box>
                  <Typography
                    variant="textSmallImportant"
                    sx={{ color: (theme) => theme.colors.WHITE_60 }}
                  >
                    {countsDisplayNameMap[countItem.name]}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="title"
                    sx={{ color: (theme) => theme.colors.PRIMARY }}
                  >
                    {countItem.count?.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </PageSection>
    </Box>
  )
}
