import { Box } from '@mui/material'
import { OSINTTabContent } from 'components/OSINT/OSINTTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import {
  selectSampleOSINTs,
  selectSampleOSINTsLoading,
} from 'store/slices/sampleDetail'

export const OSINT = () => {
  const osintsLoading = useAppSelector(selectSampleOSINTsLoading)
  const osints = useAppSelector(selectSampleOSINTs)

  return (
    <Box>
      <OSINTTabContent osints={osints} isLoading={osintsLoading} />
    </Box>
  )
}
