import { Box, Typography, useTheme } from '@mui/material'
import { DateLabel } from 'components/Label/Label'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { Link } from 'react-router-dom'
import { IReport } from 'store/types/entityTypes/report'

interface IReportPreviewProps extends IReport {
  hasImage: boolean
}

export const ReportPreview = ({
  alias,
  date,
  title,
  digest,
  coverUrl,
  hasImage,
}: IReportPreviewProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const imageUrl = useImageUrl({ fetchImageUrl: coverUrl })

  return (
    <Box
      sx={[
        {
          bgcolor: theme.colors.BLACK_90,
          cursor: 'pointer',
          p: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          '&:hover': {
            bgcolor: theme.colors.BLACK_60,
            '& .reportTitle': {
              boxShadow: `${theme.colors.PRIMARY_20} 0px -8px 0px 0px inset`,
            },
          },
          '&:active': { bgcolor: theme.colors.BLACK_95 },
        },
      ]}
      to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${alias}`}
      component={Link}
    >
      <Box>
        <Box sx={{ width: 'fit-content' }}>
          <DateLabel text={getDateStringByTimestampsInSeconds(date)} />
        </Box>
        <Box sx={{ mt: 1, overflowWrap: 'anywhere' }}>
          <Typography
            variant="titleSmall"
            className="reportTitle"
            sx={{ color: theme.colors.PRIMARY }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ mt: 1, overflowWrap: 'anywhere' }}>
          <Typography variant="bodySmall" sx={{ color: theme.colors.WHITE }}>
            {digest.length > 600 ? `${digest.slice(0, 600)}...` : digest}
          </Typography>
        </Box>
      </Box>
      {hasImage && imageUrl && (
        <Box>
          <Image
            src={imageUrl}
            alt={title}
            width="10rem"
            height="10rem"
            fit="cover"
          />
        </Box>
      )}
    </Box>
  )
}
