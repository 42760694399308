import { useTranslation } from 'react-i18next'

// Map 的 key 會是後端傳來的字串，若後端有調整 adversary 的國家，此 Map 也需要同步更新
export const useAdversaryCountryNameMap = () => {
  const { t } = useTranslation(['adversary'])
  const adversaryCountryNameMap: Record<string, string> = {
    China: t('countries.china', { ns: 'adversary' }),
    India: t('countries.india', { ns: 'adversary' }),
    'North Korea': t('countries.northKorea', { ns: 'adversary' }),
    Pakistan: t('countries.pakistan', { ns: 'adversary' }),
    'South Korea': t('countries.southKorea', { ns: 'adversary' }),
    Vietnam: t('countries.vietnam', { ns: 'adversary' }),
  }
  return adversaryCountryNameMap
}
