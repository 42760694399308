import './i18n'
// fonts css
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/noto-sans/300.css'
import '@fontsource/noto-sans/400.css'
import '@fontsource/noto-sans/400-italic.css'
import '@fontsource/noto-sans/500.css'
import '@fontsource/noto-sans/600.css'
import '@fontsource/noto-sans/700.css'
import '@fontsource/pt-mono/400.css'
// for react-wordcloud
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
// for react-datepicker
import 'react-datepicker/dist/react-datepicker.css'
// for react-slick
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Box, SvgIcon } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
// import * as Sentry from '@sentry/react'
import { ReactComponent as SnackbarErrorIcon } from 'assets/snackbarIcons/error.svg'
import { ReactComponent as SnackbarSuccessIcon } from 'assets/snackbarIcons/success.svg'
import { SnackbarAction } from 'components/Snackbar/SnackbarAction'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import { RootRoutes } from 'RootRoutes'
import store from 'store'
import theme from 'theme'

// Sentry.init({
//   dsn: 'https://16201cd310529ed83841299d546c8310@telemetry.teamt5.org/15',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       // tracePropagationTargets: [
//       //   'localhost',
//       //   /^https:\/\/threatvision.org\.io\/api/,
//       // ],
//     }),
//     new Sentry.Replay(),
//   ],
//   sampleRate: 1.0,
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 1.0,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
// })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <Box
            sx={{
              '.SnackbarItem-message': {
                fontSize: '0.875rem',
                fontWeight: 400,
                fontFamily: 'Noto Sans',
                gap: 2,
              },
              '.SnackbarItem-contentRoot.SnackbarItem-variantSuccess': {
                backgroundColor: `${theme.colors.SECONDARY_80}`,
                borderRadius: 0.5,
              },
              '.SnackbarItem-contentRoot.SnackbarItem-variantError': {
                backgroundColor: `${theme.colors.ERROR_80}`,
                borderRadius: 0.5,
              },
              '.SnackbarItem-contentRoot.SnackbarItem-variantInfo': {
                backgroundColor: `${theme.colors.GOOD_80}`,
                borderRadius: 0.5,
              },
            }}
          >
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              iconVariant={{
                info: <SvgIcon component={SnackbarSuccessIcon} />,
                error: <SvgIcon component={SnackbarErrorIcon} />,
                success: '',
              }}
              action={(snackbarKey) => (
                <SnackbarAction snackbarKey={snackbarKey} />
              )}
            >
              <RootRoutes />
            </SnackbarProvider>
          </Box>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
