import { byCountry } from 'country-code-lookup'
import ReactWorldFlag from 'react-world-flags'

interface IFlagProps {
  width?: number
  country?: string
  code?: string
}

export const Flag = ({ width = 20, country, code }: IFlagProps) => {
  // 國家名是後端從第三方平台爬到的資料，美國顯示為 United States of America
  // 需 mapping 成 country code 使用的名稱 United States
  // https://www.iban.com/country-codes
  if (country === 'United States of America') {
    country = 'United States'
  }
  if (country) {
    code = byCountry(country)?.iso2
  }

  return <ReactWorldFlag width={width} code={code} />
}
