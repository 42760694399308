import { Box, useTheme } from '@mui/material'
import { useAppSelector } from 'hooks/useReduxHooks'
import {
  selectMalwareTools,
  selectMalwareToolsLoading,
} from 'store/slices/malware'

import { ThreatHuntingToolsPreview } from './ThreatHuntingToolsPreview'
import { ThreatHuntingToolsPreviewLoading } from './ThreatHuntingToolsPreviewLoading'

export const ThreatHuntingToolTabContent = () => {
  const theme = useTheme()

  const toolsLoading = useAppSelector(selectMalwareToolsLoading)
  const tools = useAppSelector(selectMalwareTools)

  return (
    <Box sx={{ p: 2 }}>
      {toolsLoading ? (
        <ThreatHuntingToolsPreviewLoading />
      ) : (
        tools.map((tool) => (
          <Box
            key={tool.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <ThreatHuntingToolsPreview {...tool} />
          </Box>
        ))
      )}
    </Box>
  )
}
