import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IDNSRecord } from 'store/types/entityTypes/dns'
import { IDomainDetail } from 'store/types/entityTypes/domain'
import { IOSINT } from 'store/types/entityTypes/osint'
import { ISample } from 'store/types/entityTypes/sample'
import { IFetchDNSRecordsPayload } from 'store/types/slicesTypes/dns'
import {
  IDomainDetailSlice,
  IFetchDomainDetailPayload,
  IFetchDomainRelationSamplesPayload,
} from 'store/types/slicesTypes/domainDetail'

const initialState: IDomainDetailSlice = {
  isFetchingDomainDetail: false,
  isDomainAnalyzed: false,
  domainDetail: null,
  isFetchingDomainOSINT: true,
  domainOSINT: [],
  isFetchingDNSRecords: true,
  domainDNSRecords: [],
  hasMoreDomainDNSRecords: true,
  isFetchingWhois: true,
  domainWhois: '',
  domainRelationSamples: [],
  isFetchingRelationSamples: true,
  hasMoreDomainRelationSamples: true,
}

const domainDetailSlice = createSlice({
  name: 'domainDetail',
  initialState,
  reducers: {
    fetchDomainStatus(
      _state,
      _action: PayloadAction<IFetchDomainDetailPayload>
    ) {},
    fetchDomainStatusFulfilled(state) {
      state.isDomainAnalyzed = true
    },
    fetchDomainStatusRejected(state, _action: PayloadAction<AjaxError>) {
      state.isDomainAnalyzed = false
    },
    fetchDomainStatusCancelled() {},
    fetchDomainDetail(
      state,
      _action: PayloadAction<IFetchDomainDetailPayload>
    ) {
      state.isFetchingDomainDetail = true
    },
    fetchDomainDetailFulfilled(state, action: PayloadAction<IDomainDetail>) {
      state.domainDetail = action.payload
      state.isDomainAnalyzed = true
      state.isFetchingDomainDetail = false
    },
    fetchDomainDetailRejected(state, _action: PayloadAction<AjaxError>) {
      state.domainDetail = initialState.domainDetail
      state.isFetchingDomainDetail = false
    },
    fetchDomainDetailCancelled(state) {
      state.isFetchingDomainDetail = false
    },
    resetDomainDetail(state) {
      state.domainDetail = initialState.domainDetail
    },
    resetDomainAnalysisStatus(state) {
      state.isDomainAnalyzed = initialState.isDomainAnalyzed
    },
    fetchDomainOSINT(state, _action: PayloadAction<string>) {
      state.isFetchingDomainOSINT = true
    },
    fetchDomainOSINTFulfilled(state, action: PayloadAction<IOSINT[]>) {
      state.domainOSINT = action.payload
      state.isFetchingDomainOSINT = false
    },
    fetchDomainOSINTRejected(state, _action: PayloadAction<AjaxError>) {
      state.domainOSINT = initialState.domainOSINT
      state.isFetchingDomainOSINT = false
    },
    fetchDomainOSINTCancelled(state) {
      state.isFetchingDomainOSINT = false
    },
    fetchDomainDNSRecords(
      state,
      _action: PayloadAction<IFetchDNSRecordsPayload>
    ) {
      state.isFetchingDNSRecords = true
    },
    fetchDomainDNSRecordsFulfilled(state, action: PayloadAction<IDNSRecord[]>) {
      if (action.payload.length === 0) {
        state.hasMoreDomainDNSRecords = false
      } else {
        state.domainDNSRecords = [...state.domainDNSRecords, ...action.payload]
      }
      state.isFetchingDNSRecords = false
    },
    fetchDomainDNSRecordsRejected(state, _action: PayloadAction<AjaxError>) {
      state.domainDNSRecords = initialState.domainDNSRecords
      state.isFetchingDNSRecords = false
    },
    fetchDomainDNSRecordsCancelled(state) {
      state.isFetchingDNSRecords = false
    },
    resetDomainDNSRecords(state) {
      state.domainDNSRecords = initialState.domainDNSRecords
      state.hasMoreDomainDNSRecords = initialState.hasMoreDomainDNSRecords
    },
    fetchDomainWhois(state, _action: PayloadAction<string>) {
      state.isFetchingWhois = true
    },
    fetchDomainWhoisFulfilled(state, action: PayloadAction<string>) {
      state.domainWhois = action.payload
      state.isFetchingWhois = false
    },
    fetchDomainWhoisRejected(state, _action: PayloadAction<AjaxError>) {
      state.domainWhois = initialState.domainWhois
      state.isFetchingWhois = false
    },
    fetchDomainWhoisCancelled(state) {
      state.isFetchingWhois = false
    },
    fetchDomainRelationSamples(
      state,
      _action: PayloadAction<IFetchDomainRelationSamplesPayload>
    ) {
      state.isFetchingRelationSamples = true
    },
    fetchDomainRelationSamplesFulfilled(
      state,
      action: PayloadAction<ISample[]>
    ) {
      if (action.payload.length === 0) {
        state.hasMoreDomainRelationSamples = false
      } else {
        state.domainRelationSamples = [
          ...state.domainRelationSamples,
          ...action.payload,
        ]
      }
      state.isFetchingRelationSamples = false
    },
    fetchDomainRelationSamplesRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.domainRelationSamples = initialState.domainRelationSamples
      state.isFetchingRelationSamples = false
    },
    fetchDomainRelationSamplesCancelled(state) {
      state.isFetchingRelationSamples = false
    },
    resetDomainRelationSamples(state) {
      state.domainRelationSamples = initialState.domainRelationSamples
      state.hasMoreDomainRelationSamples =
        initialState.hasMoreDomainRelationSamples
    },
  },
})

export const {
  fetchDomainStatus,
  fetchDomainStatusFulfilled,
  fetchDomainStatusRejected,
  fetchDomainStatusCancelled,
  fetchDomainDetail,
  fetchDomainDetailFulfilled,
  fetchDomainDetailRejected,
  fetchDomainDetailCancelled,
  resetDomainDetail,
  resetDomainAnalysisStatus,
  fetchDomainOSINT,
  fetchDomainOSINTFulfilled,
  fetchDomainOSINTRejected,
  fetchDomainOSINTCancelled,
  fetchDomainDNSRecords,
  fetchDomainDNSRecordsFulfilled,
  fetchDomainDNSRecordsRejected,
  fetchDomainDNSRecordsCancelled,
  resetDomainDNSRecords,
  fetchDomainWhois,
  fetchDomainWhoisFulfilled,
  fetchDomainWhoisRejected,
  fetchDomainWhoisCancelled,
  fetchDomainRelationSamples,
  fetchDomainRelationSamplesFulfilled,
  fetchDomainRelationSamplesRejected,
  fetchDomainRelationSamplesCancelled,
  resetDomainRelationSamples,
} = domainDetailSlice.actions

export const selectDomainAnalysisStatus = (state: TRootState) =>
  state.domainDetail.isDomainAnalyzed
export const selectDomainDetailLoading = (state: TRootState) =>
  state.domainDetail.isFetchingDomainDetail
export const selectDomainDetail = (state: TRootState) =>
  state.domainDetail.domainDetail
export const selectDomainOSINTLoading = (state: TRootState) =>
  state.domainDetail.isFetchingDomainOSINT
export const selectDomainOSINT = (state: TRootState) =>
  state.domainDetail.domainOSINT
export const selectDomainDNSRecordsLoading = (state: TRootState) =>
  state.domainDetail.isFetchingDNSRecords
export const selectDomainDNSRecords = (state: TRootState) =>
  state.domainDetail.domainDNSRecords
export const selectHasMoreDomainDNSRecords = (state: TRootState) =>
  state.domainDetail.hasMoreDomainDNSRecords
export const selectDomainWhoisLoading = (state: TRootState) =>
  state.domainDetail.isFetchingWhois
export const selectDomainWhois = (state: TRootState) =>
  state.domainDetail.domainWhois
export const selectDomainRelationSamplesLoaidng = (state: TRootState) =>
  state.domainDetail.isFetchingRelationSamples
export const selectDomainRelationSamples = (state: TRootState) =>
  state.domainDetail.domainRelationSamples
export const selectHasMoreDomainRelationSamples = (state: TRootState) =>
  state.domainDetail.hasMoreDomainRelationSamples

export default domainDetailSlice.reducer
