import {
  Box,
  FormControlLabel,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import {
  DownloadToolButton,
  PurchaseToolButton,
} from 'components/Download/DownloadCta'
import { ThreatHuntingTool } from 'components/Download/ThreatHuntingTool'
import { SelectedCountLabel } from 'components/Label/Label'
import { BlockSection } from 'components/PageSection/PageSection'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useDownloadTool } from 'hooks/useDownloadTool'
import { useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectDownloadTools,
  selectDownloadToolsLoading,
  selectToolDownloadingIdList,
} from 'store/slices/download'
import { ITool, TToolType } from 'store/types/entityTypes/tool'

import { DownloadToolsLoading } from './DownloadToolsLoading'
import { ToolsFilterBar, toolTypes } from './ToolsFilterBar'

const SCROLLABLE_CONTAINER_ID = 'download-tools-container'

export const DownloadTools = () => {
  const theme = useTheme()
  const { t } = useTranslation(['download', 'component'])
  const [selectTools, setSelectTools] = useState<ITool[]>([])
  const [isDownloadSelect, setIsDownloadSelect] = useState<boolean>(false)
  const [selectedTypes, setSelectedTypes] = useState<TToolType[]>(toolTypes)

  const selectedCount = selectTools.length

  const toolDownloadingIdList = useAppSelector(selectToolDownloadingIdList)
  const toolsLoading = useAppSelector(selectDownloadToolsLoading)
  const tools = useAppSelector(selectDownloadTools)
  const availableTools = tools.filter((tool) => !tool.purchasedInformation)

  const isSelectedAll =
    selectTools.length > 0 && selectTools.length === availableTools.length
  const indeterminate = !isSelectedAll && selectTools.length > 0

  const { handleDownloadTool, isDownloading } = useDownloadTool()

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || indeterminate) {
      setSelectTools(availableTools)
    } else {
      setSelectTools([])
    }
  }

  const handleDownloadSelectedTools = () => {
    setIsDownloadSelect(true)
    selectTools.forEach((tool) => {
      handleDownloadTool({ ...tool, isSelectedDownload: true })
    })
  }

  useEffect(() => {
    if (toolDownloadingIdList.length === 0) setIsDownloadSelect(false)
  }, [toolDownloadingIdList])

  const handleToolSelect = (
    event: ChangeEvent<HTMLInputElement>,
    item: ITool
  ) => {
    const currentItem = tools.find((tool) => tool.id === item.id)

    if (currentItem) {
      if (event.target.checked) {
        setSelectTools([currentItem, ...selectTools])
      } else {
        setSelectTools(
          selectTools.filter((selectItem) => selectItem.id !== item.id)
        )
      }
    }
  }

  return (
    <BlockSection title={t('threatHuntingTools', { ns: 'download' })}>
      <Stack sx={{ height: '100%' }}>
        <ToolsFilterBar
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
        />
        {!toolsLoading && tools.length === 0 ? (
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE_60, px: 4, py: 2 }}
          >
            {selectedTypes.length === 0
              ? t('toolTypeSelectReminder', { ns: 'download' })
              : t('emptyState.noMatchFilter', { ns: 'component' })}
          </Typography>
        ) : (
          <Stack sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                flexShrink: 0,
                borderBottom: `1px solid ${theme.colors.WHITE_20}`,
              }}
            >
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.colors.WHITE,
                    ...theme.typography.body,
                  },
                }}
                control={
                  <Checkbox
                    checked={isSelectedAll}
                    indeterminate={indeterminate}
                    onChange={handleSelectAllChange}
                    disabled={availableTools.length === 0}
                  />
                }
                // add Typography style for disabled
                label={
                  <Typography sx={{ color: theme.colors.WHITE }} variant="body">
                    {t('selectAll', { ns: 'download' })}
                  </Typography>
                }
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <SelectedCountLabel count={selectedCount} />
                <Button
                  disabled={
                    selectTools.length === 0 ||
                    (selectTools.length !== 0 &&
                      isDownloading &&
                      isDownloadSelect)
                  }
                  loading={
                    selectTools.length !== 0 &&
                    isDownloading &&
                    isDownloadSelect
                  }
                  onClick={handleDownloadSelectedTools}
                  endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                >
                  {t('downloadCta', { ns: 'download' })}
                </Button>
              </Box>
            </Box>
            {toolsLoading && tools.length === 0 ? (
              <DownloadToolsLoading />
            ) : (
              <Stack
                id={SCROLLABLE_CONTAINER_ID}
                sx={{
                  flexBasis: 1,
                  flexGrow: 1,
                  overflow: 'auto',
                  p: 1,
                  gap: 1,
                }}
              >
                {tools.map((tool) => {
                  const isChecked = selectTools.some(
                    (selectTool) => selectTool.id === tool.id
                  )
                  const downloadElement = tool.purchasedInformation ? (
                    <PurchaseToolButton size="small" {...tool} />
                  ) : (
                    <DownloadToolButton size="small" {...tool} />
                  )
                  return (
                    <ThreatHuntingTool
                      key={tool.id}
                      checked={isChecked}
                      downloadElement={downloadElement}
                      handleCheck={(e) => handleToolSelect(e, tool)}
                      checkboxDisabled={Boolean(tool.purchasedInformation)}
                      {...tool}
                    />
                  )
                })}
                <ScrollToTop
                  scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                  sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                />
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </BlockSection>
  )
}
