export const chineseSearchTextMap: Record<string, string> = {
  日本: 'Japan',
  南韓: 'South Korea',
  台灣: 'Taiwan',
  臺灣: 'Taiwan',
  越南: 'Vietnam',
  泰國: 'Thailand',
  新加坡: 'Singapore',
  澳洲: 'Australia',
  印度: 'India',
  蒙古: 'Mongolia',
  電信業: 'Telecommunication',
  金融業: 'Financial Institution',
  製造業: 'Manufacturing',
  資訊科技業: 'Information Technology',
  媒體業: 'Media',
  軍事: 'Military',
  醫療照護: 'Healthcare',
  博弈業: 'Gambling',
  南亞: 'South Asia',
  東南亞: 'South East Asia',
  東亞: 'East Asia',
  西亞: 'West Asia',
  中亞: 'Central Asia',
  歐洲: 'Europe',
  非洲: 'Africa',
  北美洲: 'North America',
  拉丁美洲和加勒比海: 'Latin America and the Caribbean',
  大洋洲: 'Oceania',
}
