import { Box } from '@mui/material'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabMalwareCount,
  fetchTabMalwareCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  resetTabCounts,
} from 'store/slices/tabContent'

import { ITechniqueCategoryTab, useTechniqueCategoryTabs } from './config'

interface ITechniqueCategoryTabsProps {
  categoryTab: ITechniqueCategoryTab
  techniqueId: string
}

export const TechniqueCategoryTabs = ({
  techniqueId,
  categoryTab,
}: ITechniqueCategoryTabsProps) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const techniqueCategoryTabs = useTechniqueCategoryTabs()

  const handleSelect = (key: string) => {
    searchParams.set(SearchParamKey.CAPABILITY_TECHNIQUE_TAB, key)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    dispatch(fetchTabReportCount(techniqueId))
    dispatch(fetchTabAdversaryCount(techniqueId))
    dispatch(fetchTabMalwareCount(techniqueId))
    dispatch(fetchTabSampleCount(techniqueId))

    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabAdversaryCountCancelled())
      dispatch(fetchTabMalwareCountCancelled())
      dispatch(fetchTabSampleCountCancelled())
      dispatch(resetTabCounts())
    }
  }, [techniqueId])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, py: 1.5 }}>
      {techniqueCategoryTabs.map((tab) => (
        <TabWithCount
          key={tab.key}
          name={tab.name}
          handleSelect={() => handleSelect(tab.key)}
          count={tab.count}
          selected={categoryTab.key === tab.key}
          isLoading={tab.isLoading}
          appendPlusSign={tab.appendPlusSign}
        />
      ))}
    </Box>
  )
}
