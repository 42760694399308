import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { NoData } from 'components/NoData/NoData'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchSampleRelationBundleSamples,
  fetchSampleRelationBundleSamplesCancelled,
  resetSampleRelationBundleSamples,
  selectHasMoreSampleRelationBundleSamples,
  selectSampleRelationBundleSamples,
  selectSampleRelationBundleSamplesLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../../type'

const SCROLLABLE_CONTAINER_ID = 'sample-detail-relation-child-samples-container'

export const BundleSamples = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sampleRelationBundleSamplesLoading = useAppSelector(
    selectSampleRelationBundleSamplesLoading
  )
  const sampleRelationBundleSamples = useAppSelector(
    selectSampleRelationBundleSamples
  )
  const hasMoreSampleRelationBundleSamples = useAppSelector(
    selectHasMoreSampleRelationBundleSamples
  )
  const [isScrollable, ref, node] = useIsScrollable([
    sampleRelationBundleSamples,
  ])

  const handleFetchRelationBundleSamples = (refetch?: boolean) => {
    dispatch(
      fetchSampleRelationBundleSamples({
        offset: refetch ? 0 : sampleRelationBundleSamples.length,
        sampleId: searchText,
      })
    )
  }

  useEffect(() => {
    handleFetchRelationBundleSamples(true)

    return () => {
      dispatch(fetchSampleRelationBundleSamplesCancelled())
      dispatch(resetSampleRelationBundleSamples())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreSampleRelationBundleSamples) {
      handleFetchRelationBundleSamples()
    }
  }, [
    isScrollable,
    hasMoreSampleRelationBundleSamples,
    node,
    sampleRelationBundleSamples.length,
  ])

  if (
    sampleRelationBundleSamplesLoading &&
    sampleRelationBundleSamples.length === 0
  ) {
    return <SampleLoading />
  }

  if (sampleRelationBundleSamples.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={sampleRelationBundleSamples.length}
        next={handleFetchRelationBundleSamples}
        hasMore={hasMoreSampleRelationBundleSamples}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
        scrollThreshold="0px"
      >
        {sampleRelationBundleSamples.map((sample) => (
          <Box
            key={sample.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <SamplePreview {...sample} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
