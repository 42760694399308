import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { selectIsFetchReportHtmlContentLoading } from 'store/slices/report'

import { ReportDetailTocLoading } from './ReportDetailTocLoading'

const BACK_TO_TOP = 'backToTop'
const GO_TO_BOTTOM = 'goToBottom'

interface IH2h3ListItem {
  tagName: string
  text: string
  anchorId: string
}

interface IReportDetailTocProps {
  tocList: IH2h3ListItem[]
}

export const ReportDetailToc = ({ tocList }: IReportDetailTocProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const { search, hash } = useLocation()
  const [selectedTitleId, setSelectedTitleId] = useState<string>(
    hash.replace('#', '')
  )

  const isFetchReportHtmlContentLoading = useAppSelector(
    selectIsFetchReportHtmlContentLoading
  )

  const handleTocTitleClick = (titleItem: IH2h3ListItem) => {
    const { anchorId } = titleItem
    setSelectedTitleId(anchorId)
    if (anchorId === GO_TO_BOTTOM || anchorId === BACK_TO_TOP) {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (isFetchReportHtmlContentLoading || tocList.length === 0) {
    return <ReportDetailTocLoading />
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          px: 2,
          py: 4,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Stack sx={{ gap: 2 }}>
          {tocList.map((title) => (
            <Box
              key={title.anchorId}
              sx={{
                borderLeft:
                  selectedTitleId === title.anchorId && title.tagName === 'H3'
                    ? `4px solid ${theme.colors.PRIMARY_60}`
                    : `4px solid transparent`,
                cursor: 'pointer',
                pl: title.tagName === 'H3' ? 5 : 1,
                flexGrow: 1,
              }}
              onClick={() => handleTocTitleClick(title)}
            >
              <Link
                sx={{ textDecoration: 'none' }}
                href={`${search}#${title.anchorId}`}
              >
                <Typography
                  variant="bodySmall"
                  sx={{
                    color:
                      selectedTitleId === title.anchorId
                        ? theme.colors.PRIMARY_60
                        : theme.colors.WHITE_60,
                    display: 'flex',
                    '&:hover': {
                      color: theme.colors.TEXT_LINK_NORMAL,
                    },
                  }}
                >
                  {title.text}
                </Typography>
              </Link>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack sx={{ gap: 2, flexShrink: 0, mr: 6 }}>
        <Box
          sx={{
            borderLeft: `4px solid transparent`,
            cursor: 'pointer',
            mt: 6,
          }}
          onClick={() =>
            handleTocTitleClick({
              tagName: 'none',
              text: t('scrollButton.backToTop', { ns: 'component' }),
              anchorId: BACK_TO_TOP,
            })
          }
        >
          <Typography
            variant="bodySmall"
            sx={{
              pl: 5,
              color:
                selectedTitleId === BACK_TO_TOP
                  ? theme.colors.PRIMARY_60
                  : theme.colors.WHITE_60,
              display: 'flex',
              justifyContent: 'flex-end',
              '&:hover': {
                color: theme.colors.TEXT_LINK_NORMAL,
              },
            }}
          >
            {t('scrollButton.backToTop', { ns: 'component' })}
          </Typography>
        </Box>
        <Box
          sx={{
            borderLeft: `4px solid transparent`,
            cursor: 'pointer',
          }}
          onClick={() =>
            handleTocTitleClick({
              tagName: 'none',
              text: t('scrollButton.goToBottom', { ns: 'component' }),
              anchorId: GO_TO_BOTTOM,
            })
          }
        >
          <Typography
            variant="bodySmall"
            sx={{
              pl: 5,
              color:
                selectedTitleId === GO_TO_BOTTOM
                  ? theme.colors.PRIMARY_60
                  : theme.colors.WHITE_60,
              display: 'flex',
              justifyContent: 'flex-end',
              '&:hover': {
                color: theme.colors.TEXT_LINK_NORMAL,
              },
            }}
          >
            {t('scrollButton.goToBottom', { ns: 'component' })}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}
