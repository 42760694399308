import { Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const SampleLoading = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{ p: 4, gap: 4, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
    >
      <Skeleton type="title" width="26.5rem" />
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="body" width="19rem" />
        <Skeleton type="body" width="29.5rem" />
        <Skeleton type="body" width="40.75rem" />
      </Stack>
    </Stack>
  )
}
