import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_AUTH } from '../title'

const EXAMPLE_REQUEST = `curl -X POST https://api.threatvision.org/oauth/token \\
  -d "grant_type=client_credentials" \\
  -d "client_id=YOUR_CLIENT_ID" \\
  -d "client_secret=YOUR_CLIENT_SECRET"
`

const EXAMPLE_RESPONSE_BODY = `{
  "token_type": "Bearer",
  "access_token": "YOUR_ACCESS_TOKEN",
  "expires_in": 1569477726,
  "created_at": 1569477726
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'request-body', text: 'Request Body (x-www-form-urlencoded)' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Retrieve access_token'

export const RetrieveAccessToken = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(`${TITLE} - ${API_DOC_AUTH}`)}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title="Authentication" />
        <H2ColorTitle title={TITLE} />
        <Description>
          In your account page, you can obtain your API{' '}
          <InlineBlock text="client_id" /> and{' '}
          <InlineBlock text="client_secret" />, which are required for OAuth 2.0
          client credentials authentication flow.
        </Description>
        <Description>
          <InlineBlock text="access_token" /> are required on all API request.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          POST <InlineBlock text="https://api.threatvision.org/oauth/token" />
        </Description>
        <TocLink
          text="Request Body (x-www-form-urlencoded)"
          hash="request-body"
        />
        <UnorderedList>
          <ListItem>
            grant_type
            <UnorderedList>
              <ListItem>Per OAuth spec, must be “client_credentials”</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            client_id
            <UnorderedList>
              <ListItem>Available in your account page.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            client_secret
            <UnorderedList>
              <ListItem>Available in your account page.</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>
          JSON object, with following keys:
          <UnorderedList>
            <ListItem>
              access_token
              <UnorderedList>
                <ListItem>
                  The access token to provide in each API request
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              expires_in
              <UnorderedList>
                <ListItem>
                  The access token expiration date in unix timestamp.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
