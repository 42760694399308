import { Box, Stack } from '@mui/material'
import { OSINTTabContent } from 'components/OSINT/OSINTTabContent'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { OutlineTabWithCount } from 'components/Tab/OutlineTabWithCount'
import { UserCommentTabContent } from 'components/UserComments/UserCommentTabContent'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { selectCommentLoading, selectCommentsCount } from 'store/slices/comment'
import {
  fetchDomainOSINT,
  fetchDomainOSINTCancelled,
  selectDomainDetail,
  selectDomainOSINT,
  selectDomainOSINTLoading,
} from 'store/slices/domain'
import {
  fetchIPOSINT,
  fetchIPOSINTCancelled,
  selectIPDetail,
  selectIPOSINT,
  selectIPOSINTLoading,
} from 'store/slices/ip'

import { IContentProps } from '../config'

const SCROLLABLE_CONTAINER_ID = 'community-content-container'

export const CommunityContent = ({ searchText, detailType }: IContentProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['ipDomain'])
  const [searchParams, setSearchParams] = useSearchParams()
  const isIP = detailType === 'ip'

  const tabCommentCount = useAppSelector(selectCommentsCount)
  const isCommentLoading = useAppSelector(selectCommentLoading)

  const ipDetail = useAppSelector(selectIPDetail)
  const domainDetail = useAppSelector(selectDomainDetail)

  const isIPOSINTLoading = useAppSelector(selectIPOSINTLoading)
  const isDomainOSINTLoading = useAppSelector(selectDomainOSINTLoading)

  const ipOSINTs = useAppSelector(selectIPOSINT)
  const domainOSINTs = useAppSelector(selectDomainOSINT)

  const detail = isIP ? ipDetail : domainDetail
  const isOSINTLoading = isIP ? isIPOSINTLoading : isDomainOSINTLoading
  const osints = isIP ? ipOSINTs : domainOSINTs

  useEffect(() => {
    if (isIP) {
      dispatch(fetchIPOSINT(searchText))
    } else {
      dispatch(fetchDomainOSINT(searchText))
    }

    return () => {
      if (isIP) {
        dispatch(fetchIPOSINTCancelled())
      } else {
        dispatch(fetchDomainOSINTCancelled())
      }
    }
  }, [])

  const communityTabs = [
    {
      key: 'osint',
      name: t('communityTabs.osint', { ns: 'ipDomain' }),
      component: <OSINTTabContent osints={osints} isLoading={isOSINTLoading} />,
      count: detail?.summary.osint,
      isLoading: isOSINTLoading,
    },
    {
      key: 'comments',
      name: t('communityTabs.comments', { ns: 'ipDomain' }),
      component: <UserCommentTabContent />,
      count: tabCommentCount,
      isLoading: isCommentLoading,
    },
  ]

  const defaultCommunityTab = communityTabs[0]
  const communityTabParam = searchParams.get(
    SearchParamKey.IP_DOMAIN_COMMUNITY_TAB
  )
  const communityTab =
    (communityTabParam &&
      communityTabs.find((tab) => tab.key === communityTabParam)) ||
    defaultCommunityTab

  const handleSelect = (key: string) => {
    searchParams.set(SearchParamKey.IP_DOMAIN_COMMUNITY_TAB, key)
    setSearchParams(searchParams)
  }

  const bottomContent = communityTab.component

  return (
    <Stack sx={{ pt: 0.5, height: '100%' }}>
      <Box sx={{ display: 'flex', gap: 1, px: 4, py: 1 }}>
        {communityTabs.map((tab) => (
          <OutlineTabWithCount
            key={tab.key}
            name={tab.name}
            count={tab.count}
            handleSelect={() => handleSelect(tab.key)}
            showCount
            selected={communityTab.key === tab.key}
          />
        ))}
      </Box>
      <Box
        id={SCROLLABLE_CONTAINER_ID}
        sx={{
          maxWidth: '60.5rem',
          flexGrow: 1,
          flexBasis: 0,
          overflowY: 'auto',
        }}
      >
        {bottomContent}
        <ScrollToTop
          scrollableContainerId={SCROLLABLE_CONTAINER_ID}
          sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
        />
      </Box>
    </Stack>
  )
}
