import { Stack, Typography,useTheme } from '@mui/material'
import NoSearchResultImage from 'assets/errorImages/qq-tv.svg'
import Image from 'mui-image'

interface ISomethingWrongProps {
  text?: string
}

export const SomethingWrong = ({
  text = 'Something going wrong...',
}: ISomethingWrongProps) => {
  const theme = useTheme()
  return (
    <Stack sx={{ pt: 12, gap: 2, alignItems: 'center' }}>
      <Typography variant="titleBig" sx={{ color: theme.colors.WHITE_60 }}>
        {text}
      </Typography>
      <Image
        src={NoSearchResultImage}
        alt="Search no match"
        width={328}
        height={130}
      />
    </Stack>
  )
}
