import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_LANDING_COUNTS,
  FETCH_LANDING_IOCS,
  FETCH_LANDING_LATEST_REPORTS,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchCounts,
  fetchCountsCancelled,
  fetchCountsFulfilled,
  fetchCountsRejected,
  fetchIocs,
  fetchIocsCancelled,
  fetchIocsFulfilled,
  fetchIocsRejected,
  fetchLatestReports,
  fetchLatestReportsCancelled,
  fetchLatestReportsFulfilled,
  fetchLatestReportsRejected,
} from 'store/slices/landing'
import {
  IFetchCountsResponse,
  mapAPICountsToState,
} from 'store/types/entityTypes/counts'
import { mapAPIIoCsToState } from 'store/types/entityTypes/ioc'
import { mapAPIReportsToState } from 'store/types/entityTypes/report'
import { IFetchIocsResponse } from 'store/types/slicesTypes/download'
import { IFetchReportsResponse } from 'store/types/slicesTypes/report'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const latestReportsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchLatestReports.match),
    switchMap(() =>
      fetchData<IFetchReportsResponse>({
        url: FETCH_LANDING_LATEST_REPORTS,
      }).pipe(
        map((response: AjaxResponse<IFetchReportsResponse>) =>
          fetchLatestReportsFulfilled(
            mapAPIReportsToState(response.response.reports)
          )
        ),
        takeUntil(action$.pipe(filter(fetchLatestReportsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchLatestReportsRejected(error))
          )
        )
      )
    )
  )

const iocsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIocs.match),
    switchMap(() =>
      fetchData<IFetchIocsResponse>({ url: FETCH_LANDING_IOCS }).pipe(
        map((response: AjaxResponse<IFetchIocsResponse>) =>
          fetchIocsFulfilled(mapAPIIoCsToState(response.response.ioc_bundles))
        ),
        takeUntil(action$.pipe(filter(fetchIocsCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchIocsRejected(error)))
        )
      )
    )
  )

const countsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchCounts.match),
    switchMap(() =>
      fetchData<IFetchCountsResponse>({
        url: FETCH_LANDING_COUNTS,
      }).pipe(
        map((response: AjaxResponse<IFetchCountsResponse>) =>
          fetchCountsFulfilled(mapAPICountsToState(response.response.data))
        ),
        takeUntil(action$.pipe(filter(fetchCountsCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchCountsRejected(error)))
        )
      )
    )
  )

export const landingEpic = combineEpics(latestReportsEpic, iocsEpic, countsEpic)
