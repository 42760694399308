import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  IPurchasePayload,
  IPurchaseSlice,
} from 'store/types/slicesTypes/purchase'

const initialState: IPurchaseSlice = {
  purchaseLoading: false,
}

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    purchaseItem(state, _action: PayloadAction<IPurchasePayload>) {
      state.purchaseLoading = true
    },
    purchaseItemFulfilled(state) {
      state.purchaseLoading = false
    },
    purchaseItemRejected(state, _action: PayloadAction<AjaxError>) {
      state.purchaseLoading = initialState.purchaseLoading
    },
  },
})

export const { purchaseItem, purchaseItemFulfilled, purchaseItemRejected } =
  purchaseSlice.actions

export const selectPurchaseLoading = (state: TRootState) =>
  state.purchase.purchaseLoading

export default purchaseSlice.reducer
