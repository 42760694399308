import { Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const PreviewContentLoading = () => (
  <Stack sx={{ p: 4, gap: 2 }}>
    <Skeleton type="body" width="52.5rem" />
    <Skeleton type="body" width="29.25rem" />
    <Skeleton type="body" width="40.75rem" />
  </Stack>
)
