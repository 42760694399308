import { Box } from '@mui/material'
import {
  ADVERSARIES_ROUTE,
  ADVERSARY_INFO_ROUTE,
  DOMAIN_ROUTE,
  IP_ROUTE,
  MALWARE_CONTENT_ROUTE,
  MALWARES_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  SAMPLE_DETAILS_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { entityMap, TAPIEntity, TEntity } from 'store/types/entityTypes/shared'

const getCommentRouteByEntity = (
  entityType: TEntity,
  entityId: string,
  commentId: string
): string => {
  let route = ''
  switch (entityType) {
    case 'report':
      route = `/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${entityId}`
      break
    case 'adversary':
      route = `/${ADVERSARIES_ROUTE}/${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_TAB}=comments&${SearchParamKey.ADVERSARY_ID}=${entityId}`
      break
    case 'malware':
      route = `/${MALWARES_ROUTE}/${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_TAB}=comments&${SearchParamKey.MALWARE_ID}=${entityId}`
      break
    case 'sample':
      route = `/${SAMPLE_DETAILS_ROUTE}?${SearchParamKey.SAMPLE_TAB}=community&${SearchParamKey.SAMPLE_COMMUNITY_TAB}=comments&${SearchParamKey.SAMPLE_ID}=${entityId}`
      break
    case 'ip':
      route = `/${IP_ROUTE}?${SearchParamKey.IP_TAB}=community&${SearchParamKey.IP_DOMAIN_COMMUNITY_TAB}=comments&${SearchParamKey.IP}=${entityId}`
      break
    case 'domain':
      route = `/${DOMAIN_ROUTE}?${SearchParamKey.DOMAIN_TAB}=community&${SearchParamKey.IP_DOMAIN_COMMUNITY_TAB}=comments&${SearchParamKey.DOMAIN}=${entityId}`
      break
    default:
      break
  }
  return `${route}&comment_id=${commentId}`
}

export const CommentRedirectNotification = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const entityAPIType = searchParams.get(
    SearchParamKey.COMMENT_RESOURCE_TYPE
  ) as TAPIEntity
  const entityId = searchParams.get(SearchParamKey.COMMENT_RESOURCE_ID)
  const commentId = searchParams.get(SearchParamKey.COMMENT_ID)
  const entityType = entityMap[entityAPIType]

  useEffect(() => {
    if (entityId && entityType && commentId) {
      const redirectRoute = getCommentRouteByEntity(
        entityType,
        entityId,
        commentId
      )
      navigate(redirectRoute)
    }
  }, [])

  return <Box />
}
