import { Box, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ArrowBackwardIcon } from 'assets/basicIcons/arrowBackward.svg'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { WindowSizeContext } from 'context/WindowSizeContext'
import { useContext,useEffect, useState } from 'react'

import {
  ADVERSARY_ITEM_WIDTH,
  ADVERSARY_ITEMS_GAP_WIDTH,
  CALCULATE_SCROLL_WIDTH_DELAY,
} from '../config'

interface IScrollSwitcherProps {
  reachedLeftEdge: boolean
  reachedRightEdge: boolean
  handleEdgeDetection: () => void
  scrollContainerId: string
}

export const ScrollSwitcher = ({
  reachedLeftEdge,
  reachedRightEdge,
  handleEdgeDetection,
  scrollContainerId,
}: IScrollSwitcherProps) => {
  const theme = useTheme()
  const { width } = useContext(WindowSizeContext)

  const detectIsScrollable = (): boolean => {
    const container = document.getElementById(scrollContainerId)
    return Boolean(container && container.scrollWidth > container.clientWidth)
  }

  const [shouldShowSwitcher, setShowSwitcher] = useState<boolean>(false)

  // detect should show switcher after window width change
  useEffect(() => {
    const interval = setInterval(() => {
      if (!shouldShowSwitcher) {
        // work-around for not showing scroll switch
        setShowSwitcher(detectIsScrollable())
      }
    }, CALCULATE_SCROLL_WIDTH_DELAY)
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [width, shouldShowSwitcher, reachedRightEdge])

  if (!shouldShowSwitcher) {
    return null
  }

  const handleScrollForward = () => {
    const container = document.getElementById(scrollContainerId)
    if (!reachedRightEdge && container) {
      container.scrollBy({
        left: ADVERSARY_ITEM_WIDTH + ADVERSARY_ITEMS_GAP_WIDTH,
        behavior: 'smooth',
      })
      handleEdgeDetection()
    }
  }

  const handleScrollBack = () => {
    const container = document.getElementById(scrollContainerId)
    if (!reachedLeftEdge && container) {
      container.scrollBy({
        left: (ADVERSARY_ITEM_WIDTH + ADVERSARY_ITEMS_GAP_WIDTH) * -1,
        behavior: 'smooth',
      })
      handleEdgeDetection()
    }
  }

  const sharedIconStyle = {
    width: '1rem',
    height: '1rem',
    border: `1px solid ${theme.colors.WHITE_60}`,
    borderRadius: 0.5,
    cursor: 'pointer',
  }

  const disabledIconStyle = {
    path: {
      fill: theme.colors.DISABLE,
    },
    borderColor: theme.colors.DISABLE,
    cursor: 'default',
  }

  return (
    <Box sx={{ display: 'flex', width: '2.25rem', height: '1rem', gap: 1 }}>
      <SvgIcon
        sx={[sharedIconStyle, reachedLeftEdge && disabledIconStyle]}
        component={ArrowBackwardIcon}
        inheritViewBox
        onClick={handleScrollBack}
      />
      <SvgIcon
        sx={[sharedIconStyle, reachedRightEdge && disabledIconStyle]}
        component={ArrowForwardIcon}
        inheritViewBox
        onClick={handleScrollForward}
      />
    </Box>
  )
}
