import { TCommentType } from './comment'
import { TRiskLevel } from './shared'
import { TTlp } from './tlpTarget'

export interface ISample {
  id: string
  sha256: string
  md5: string
  iconUrl: string
  firstSeen: number
  riskLevel: TRiskLevel
  hasNetworkActivity: boolean
  adversaries: string[]
  malwares: string[]
  filename: string
  uploadAt: number
  tlp: TTlp
  size: number
  commentableType: TCommentType
}

export interface ISampleAPIData {
  main_value: string
  sha256: string
  md5: string
  icon_url: string
  first_seen: number
  risk_level: TRiskLevel
  has_network_activity: boolean
  adversaries: string[]
  malwares: string[]
  filename: string
  date: number
  tlp: TTlp
  size: number
  commentable_type: TCommentType
}

export interface ISampleDetail {
  iconUrl: string
  riskLevel: TRiskLevel
  sha256: string
  filename: string
  tlp: TTlp
  canEditTlp: boolean
  firstSeen: number | null
  size: number | null
  date: number
  sampleInfo: {
    md5: string
    sha1: string
    sha256: string
    filename: {
      originalFilename: string
      pdbStrings: string[]
    }
    peFile?: Record<string, string>
    document?: Record<string, string>
    exiftool?: {
      output: Record<string, string>
      fileTimestamp?: {
        seconds: number
      }
    }
    fileHash: Record<string, string>
  }
}

export interface ISampleDetailAPIData {
  icon_url: string
  risk_level: TRiskLevel
  sha256: string
  file_name: string
  tlp: TTlp
  can_edit_tlp: boolean
  first_seen: number | null
  size: number | null
  date: number
  sample_info: {
    md5: string
    sha1: string
    sha256: string
    filename: {
      original_filename: string
      pdb_strings: string[]
    }
    pe_file?: Record<string, string>
    document?: Record<string, string>
    exiftool?: {
      output: Record<string, string>
      file_timestamp?: {
        seconds: number
      }
    }
    file_hash: Record<string, string>
  }
}

export interface ISampleRelationsCount {
  ipCount: number
  domainCount: number
  childSampleCount: number
  parentSampleCount: number
  bundleSampleCount: number
}

export interface ISampleRelationsCountAPIData {
  ip_count: number
  domain_count: number
  child_sample_count: number
  parent_sample_count: number
  bundle_sample_count: number
}

export interface ISamplePreviewStrings {
  contentStrings: string
  nextPage: number | null
}

export interface ISamplePreviewStringsAPIData {
  content_strings: string
  next_page: number | null
}

export interface ISamplePreviewHex {
  contentBytes: string
  contentHex: string
  nextPage: number | null
}

export interface ISamplePreviewHexAPIData {
  content_bytes: string
  content_hex: string
  next_page: number | null
}

export interface ISampleSandboxUnit {
  value: string
  broker: string
}

export interface ISampleSandbox {
  screenshotUrls: string[]
  registry: ISampleSandboxUnit[]
  mutex: ISampleSandboxUnit[]
}

export interface ISampleSandboxAPIData {
  screenshot_urls: string[]
  registry: ISampleSandboxUnit[]
  mutex: ISampleSandboxUnit[]
}

export const mapAPISamplesToState = (
  fromAPISamples: ISampleAPIData[]
): ISample[] =>
  fromAPISamples.map((fromAPISample) => ({
    id: fromAPISample.main_value,
    sha256: fromAPISample.sha256,
    md5: fromAPISample.md5,
    tlp: fromAPISample.tlp,
    adversaries: fromAPISample.adversaries,
    malwares: fromAPISample.malwares,
    size: fromAPISample.size,
    iconUrl: fromAPISample.icon_url,
    firstSeen: fromAPISample.first_seen,
    riskLevel: fromAPISample.risk_level,
    hasNetworkActivity: fromAPISample.has_network_activity,
    filename: fromAPISample.filename,
    uploadAt: fromAPISample.date,
    commentableType: fromAPISample.commentable_type,
  }))

export const mapAPISampleDetailToState = (
  fromAPISampleDetail: ISampleDetailAPIData
): ISampleDetail => ({
  iconUrl: fromAPISampleDetail.icon_url,
  riskLevel: fromAPISampleDetail.risk_level,
  sha256: fromAPISampleDetail.sha256,
  filename: fromAPISampleDetail.file_name,
  tlp: fromAPISampleDetail.tlp,
  canEditTlp: fromAPISampleDetail.can_edit_tlp,
  firstSeen: fromAPISampleDetail.first_seen,
  size: fromAPISampleDetail.size,
  date: fromAPISampleDetail.date,
  sampleInfo: {
    md5: fromAPISampleDetail.sample_info?.md5,
    sha1: fromAPISampleDetail.sample_info?.sha1,
    sha256: fromAPISampleDetail.sample_info?.sha256,
    filename: {
      originalFilename:
        fromAPISampleDetail.sample_info?.filename?.original_filename,
      pdbStrings: fromAPISampleDetail.sample_info?.filename?.pdb_strings,
    },
    peFile: fromAPISampleDetail.sample_info?.pe_file,
    document: undefined,
    exiftool: fromAPISampleDetail.sample_info?.exiftool
      ? {
          output: fromAPISampleDetail.sample_info?.exiftool?.output,
          fileTimestamp:
            fromAPISampleDetail.sample_info?.exiftool?.file_timestamp,
        }
      : undefined,
    fileHash: fromAPISampleDetail.sample_info?.file_hash,
  },
})

export const mapAPISampleRelationsCountToState = (
  fromAPISampleRelationsCount: ISampleRelationsCountAPIData
): ISampleRelationsCount => ({
  ipCount: fromAPISampleRelationsCount.ip_count,
  domainCount: fromAPISampleRelationsCount.domain_count,
  childSampleCount: fromAPISampleRelationsCount.child_sample_count,
  parentSampleCount: fromAPISampleRelationsCount.parent_sample_count,
  bundleSampleCount: fromAPISampleRelationsCount.bundle_sample_count,
})

export const mapAPISamplePreviewStringsToState = (
  fromAPISamplePreviewStrings: ISamplePreviewStringsAPIData
): ISamplePreviewStrings => ({
  contentStrings: fromAPISamplePreviewStrings.content_strings,
  nextPage: fromAPISamplePreviewStrings.next_page,
})

export const mapAPISamplePreviewHexToState = (
  fromAPISamplePreviewHex: ISamplePreviewHexAPIData
): ISamplePreviewHex => ({
  contentBytes: fromAPISamplePreviewHex.content_bytes,
  contentHex: fromAPISamplePreviewHex.content_hex,
  nextPage: fromAPISamplePreviewHex.next_page,
})

export const mapAPISampleSandboxToState = (
  fromAPISampleSandbox: ISampleSandboxAPIData
): ISampleSandbox => ({
  screenshotUrls: fromAPISampleSandbox.screenshot_urls,
  registry: fromAPISampleSandbox.registry,
  mutex: fromAPISampleSandbox.mutex,
})
