import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { DateLabel } from 'components/Label/Label'
import {
  PM_REPORT_DETAIL_ROUTE,
  PM_REPORT_ROUTE,
  REPORTS_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { Link } from 'react-router-dom'
import { IPMReports } from 'store/types/entityTypes/report'

export const PMReportPreview = ({ name, releaseDate }: IPMReports) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
      to={`/${REPORTS_ROUTE}/${VULNERABILITY_ROUTE}/${PM_REPORT_ROUTE}/${PM_REPORT_DETAIL_ROUTE}?${SearchParamKey.PM_REPORT}=${name}`}
      component={Link}
    >
      <Stack sx={{ gap: 2 }}>
        <Box sx={{ width: 'fit-content' }}>
          <DateLabel text={getDateStringByTimestampsInSeconds(releaseDate)} />
        </Box>
        <Typography variant="textLinkL" sx={{ color: theme.colors.PRIMARY }}>
          {name}
        </Typography>
      </Stack>
      <SvgIcon component={ArrowForwardIcon} inheritViewBox />
    </Box>
  )
}
