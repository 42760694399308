import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import RawIntelBlurImage from 'assets/rawIntel/rawIntelBlur.png'
import { LinkButton } from 'components/Button/Button'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import {
  RAW_INTEL_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { IRawIntel } from 'store/types/entityTypes/rawIntel'
import { TSearchResultType } from 'store/types/slicesTypes/search'

import {
  RawIntelAdversaryLabel,
  RawIntelExploitLabel,
  RawIntelMalwareLabel,
  VictimInformation,
} from './RawIntelLabels'

const TAGS_MAX_COUNT = 4

interface IRawIntelPreview extends IRawIntel {
  searchResultType: TSearchResultType
}

export const RawIntelPreview = ({
  id,
  title,
  targetCountries,
  targetIndustries,
  malwares,
  adversaries,
  exploits,
  maliciousSamples,
  seen,
  searchResultType,
  ips,
  domains,
}: IRawIntelPreview) => {
  const theme = useTheme()
  const { t } = useTranslation(['component', 'rawIntel'])
  const [searchParams] = useSearchParams()
  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const searchResultTypeLabel: Record<TSearchResultType, string> = {
    ip: 'Suspicious IP',
    domain: 'Suspicious Domain',
    other: 'Malicious Sample',
  }

  return (
    <Stack
      sx={{
        p: 4,
        gap: 2,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
        {title}
      </Typography>
      {seen ? (
        <Stack sx={{ gap: 1 }}>
          <VictimInformation
            countries={targetCountries}
            industries={targetIndustries}
          />
          <RawIntelMalwareLabel malwares={malwares} />
          <RawIntelAdversaryLabel adversaries={adversaries} />
          <RawIntelExploitLabel exploits={exploits} />
          {maliciousSamples.length > 0 && (
            <>
              <HighlightLabel
                text={t('label.maliciousSample', { ns: 'rawIntel' })}
              />
              <UnorderedList>
                {maliciousSamples
                  .slice(0, TAGS_MAX_COUNT)
                  .map((sample, index) => (
                    <ListItem key={sample}>
                      <TextLink
                        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${sample}`}
                        target="_blank"
                      >
                        {sample}
                      </TextLink>
                      {index === TAGS_MAX_COUNT - 1 &&
                        maliciousSamples.length > TAGS_MAX_COUNT && (
                          <Typography
                            variant="textSmallImportant"
                            sx={{ color: theme.colors.WHITE }}
                          >
                            {t('labelMore', { ns: 'component' })}
                          </Typography>
                        )}
                    </ListItem>
                  ))}
              </UnorderedList>
            </>
          )}
          {ips.length > 0 && (
            <>
              <HighlightLabel
                text={t('label.suspiciousIp', { ns: 'rawIntel' })}
              />
              <UnorderedList>
                {ips.slice(0, TAGS_MAX_COUNT).map((ip, index) => (
                  <ListItem key={ip}>
                    <TextLink
                      to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${ip}`}
                      target="_blank"
                    >
                      {ip}
                    </TextLink>
                    {index === TAGS_MAX_COUNT - 1 &&
                      ips.length > TAGS_MAX_COUNT && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          {t('labelMore', { ns: 'component' })}
                        </Typography>
                      )}
                  </ListItem>
                ))}
              </UnorderedList>
            </>
          )}
          {domains.length > 0 && (
            <>
              <HighlightLabel
                text={t('label.suspiciousDomain', { ns: 'rawIntel' })}
              />
              <UnorderedList>
                {domains.slice(0, TAGS_MAX_COUNT).map((domain, index) => (
                  <ListItem key={domain}>
                    <TextLink
                      to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${domain}`}
                      target="_blank"
                    >
                      {domain}
                    </TextLink>
                    {index === TAGS_MAX_COUNT - 1 &&
                      domains.length > TAGS_MAX_COUNT && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          {t('labelMore', { ns: 'component' })}
                        </Typography>
                      )}
                  </ListItem>
                ))}
              </UnorderedList>
            </>
          )}
        </Stack>
      ) : (
        <>
          <Box>
            <HighlightLabel text={searchResultTypeLabel[searchResultType]} />
            <UnorderedList>
              <ListItem>
                <Typography
                  variant="textSmallImportant"
                  sx={{ color: theme.colors.WHITE }}
                >
                  {searchText || '--'}
                </Typography>
              </ListItem>
            </UnorderedList>
          </Box>
          <Image
            src={RawIntelBlurImage}
            alt="blur"
            width="100%"
            height="100%"
            fit="cover"
          />
        </>
      )}
      <Box sx={{ alignSelf: 'flex-end', flexShrink: 0 }}>
        <LinkButton
          to={`/${RAW_INTEL_ROUTE}?${SearchParamKey.RAW_INTEL_ID}=${id}`}
          variant={seen ? 'outlined' : 'contained'}
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
        >
          {t('checkDetailCta', { ns: 'component' })}
        </LinkButton>
      </Box>
    </Stack>
  )
}
