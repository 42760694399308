export type TCommentType =
  | 'Blob'
  | 'CaseFile'
  | 'Adversary'
  | 'Program::Family'
  | 'Network::Ip'
  | 'Network::Domain'
  | 'Karen::Comment'
  | 'Karen::RawIntel'

export interface IComment {
  author: {
    email: string
    avatar: string
  }
  id: number
  content: string
  date: number
  editable: boolean
  isPrivate: boolean
  commentableType: TCommentType
  comments: IComment[]
  replyTo?: string
}

export interface ICommentAPIData {
  author: {
    email: string
    avatar: string
  }
  id: number
  content: string
  date: number
  editable: boolean
  private: boolean
  commentable_type: TCommentType
  comments: ICommentAPIData[]
}

export const mapAPICommentToState = (
  fromAPIComment: ICommentAPIData
): IComment => ({
  author: fromAPIComment.author,
  id: fromAPIComment.id,
  content: fromAPIComment.content,
  date: fromAPIComment.date,
  editable: fromAPIComment.editable,
  isPrivate: fromAPIComment.private,
  commentableType: fromAPIComment.commentable_type,
  comments: fromAPIComment.comments.map(mapAPICommentToState),
})

export const mapAPICommentsToState = (
  fromAPIComments: ICommentAPIData[]
): IComment[] => fromAPIComments.map(mapAPICommentToState)

const recursiveFlatComments = (
  flattenSubComments: IComment[],
  targetComment: IComment,
  replyTo: string
) => {
  const { comments } = targetComment
  flattenSubComments.push({ replyTo, ...targetComment })
  if (comments.length > 0) {
    const { author } = targetComment
    comments.forEach((subComment) => {
      recursiveFlatComments(flattenSubComments, subComment, author.email)
    })
  }
}

export const getFlattenSubComments = (
  originalComments: IComment[]
): IComment[] => {
  const flattenComments = originalComments.map((originalComment) => {
    const { comments } = originalComment
    if (comments.length > 0) {
      const { author } = originalComment
      const newSubComments: IComment[] = []
      comments.forEach((subComment) => {
        recursiveFlatComments(newSubComments, subComment, author.email)
      })

      return {
        ...originalComment,
        comments: newSubComments,
      }
    }
    return originalComment
  })

  return flattenComments
}
