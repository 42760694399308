import { Box, Divider, Stack, useTheme } from '@mui/material'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchCTRReports,
  fetchCTRReportsCancelled,
  fetchFlashReports,
  fetchFlashReportsCancelled,
  fetchMonthlyReports,
  fetchMonthlyReportsCancelled,
  resetCTRReports,
  resetFlashReports,
  resetMonthlyReports,
  selectCTRReports,
  selectFlashReports,
  selectHasMoreCTRReports,
  selectHasMoreFlashReports,
  selectHasMoreMonthlyReports,
  selectIsFetchCTRReportsLoading,
  selectIsFetchFlashReportsLoading,
  selectIsFetchMonthlyReportsLoading,
  selectMonthlyReports,
} from 'store/slices/report'
import {
  IFetchReportsPayload,
  IReport,
  TAPTReportType,
} from 'store/types/entityTypes/report'

import { ReportEntryPreviewLoading } from './ReportLoading'
import { ReportPreview } from './ReportPreview'

interface IReportInfo {
  reportPreviewList: IReport[]
  ref: (refNode: HTMLDivElement) => void
  isScrollable: boolean
  node: HTMLDivElement | undefined
  hasMore: boolean
  fetchReports: ActionCreatorWithOptionalPayload<
    IFetchReportsPayload | undefined,
    string
  >
  fetchReportsCancel: ActionCreatorWithOptionalPayload<
    IFetchReportsPayload | undefined,
    string
  >
  scrollableContainerId: string
  showLoading: boolean
}

export const ReportPreviewList = ({ type }: { type: TAPTReportType }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const flashReports = useAppSelector(selectFlashReports)
  const monthlyReports = useAppSelector(selectMonthlyReports)
  const CTRReports = useAppSelector(selectCTRReports)
  const [isFlashReportScrollable, flashReportRef, flashReportNode] =
    useIsScrollable([flashReports])
  const [isMonthlyReportScrollable, monthlyReportRef, monthlyReportNode] =
    useIsScrollable([monthlyReports])
  const [isCTRReportScrollable, CTRReportRef, CTRReportNode] = useIsScrollable([
    CTRReports,
  ])
  const hasMoreFlashReports = useAppSelector(selectHasMoreFlashReports)
  const hasMoreMonthlyReports = useAppSelector(selectHasMoreMonthlyReports)
  const hasMoreCTRReports = useAppSelector(selectHasMoreCTRReports)
  const isFetchFlashReportsLoading = useAppSelector(
    selectIsFetchFlashReportsLoading
  )
  const isFetchMonthlyReportsLoading = useAppSelector(
    selectIsFetchMonthlyReportsLoading
  )
  const isFetchCTRReportsLoading = useAppSelector(
    selectIsFetchCTRReportsLoading
  )

  const reportMap: Record<TAPTReportType, IReportInfo> = {
    flashReport: {
      reportPreviewList: flashReports,
      ref: flashReportRef,
      isScrollable: isFlashReportScrollable,
      node: flashReportNode,
      hasMore: hasMoreFlashReports,
      fetchReports: fetchFlashReports,
      fetchReportsCancel: fetchFlashReportsCancelled,
      scrollableContainerId: 'flash-report-content-container',
      showLoading: isFetchFlashReportsLoading && flashReports.length === 0,
    },
    monthlyReport: {
      reportPreviewList: monthlyReports,
      ref: monthlyReportRef,
      isScrollable: isMonthlyReportScrollable,
      node: monthlyReportNode,
      hasMore: hasMoreMonthlyReports,
      fetchReports: fetchMonthlyReports,
      fetchReportsCancel: fetchMonthlyReportsCancelled,
      scrollableContainerId: 'monthly-report-content-container',
      showLoading: isFetchMonthlyReportsLoading && monthlyReports.length === 0,
    },
    CTRReport: {
      reportPreviewList: CTRReports,
      ref: CTRReportRef,
      isScrollable: isCTRReportScrollable,
      node: CTRReportNode,
      hasMore: hasMoreCTRReports,
      fetchReports: fetchCTRReports,
      fetchReportsCancel: fetchCTRReportsCancelled,
      scrollableContainerId: 'CTR-report-content-container',
      showLoading: isFetchCTRReportsLoading && CTRReports.length === 0,
    },
  }

  const fetchReportsWithOffset = () => {
    dispatch(
      reportMap[type].fetchReports({
        offset: reportMap[type].reportPreviewList.length,
      })
    )
  }

  // initial fetch
  useEffect(() => {
    dispatch(reportMap[type].fetchReports({ offset: 0 }))

    return () => {
      dispatch(reportMap[type].fetchReportsCancel())
      dispatch(resetFlashReports())
      dispatch(resetMonthlyReports())
      dispatch(resetCTRReports())
    }
  }, [])

  // infinite scroll fetch
  useEffect(() => {
    if (
      reportMap[type].node &&
      !reportMap[type].isScrollable &&
      reportMap[type].hasMore
    ) {
      fetchReportsWithOffset()
    }
  }, [
    reportMap[type].isScrollable,
    reportMap[type].hasMore,
    reportMap[type].node,
    reportMap[type].reportPreviewList.length,
  ])

  if (reportMap[type].showLoading) {
    return (
      <Stack sx={{ py: 2 }}>
        <ReportEntryPreviewLoading />
        <ReportEntryPreviewLoading />
        <ReportEntryPreviewLoading />
      </Stack>
    )
  }

  return (
    <Stack
      id={reportMap[type].scrollableContainerId}
      ref={reportMap[type].ref}
      sx={{
        flexGrow: 1,
        flexBasis: 0,
        overflowY: 'auto',
      }}
    >
      <InfiniteScrollWrapper
        dataLength={reportMap[type].reportPreviewList.length}
        next={fetchReportsWithOffset}
        hasMore={reportMap[type].hasMore}
        scrollableTarget={reportMap[type].scrollableContainerId}
      >
        {reportMap[type].reportPreviewList.map((report) => (
          <Box key={report.id}>
            <ReportPreview {...report} hasImage={false} />
            <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={reportMap[type].scrollableContainerId}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Stack>
  )
}
