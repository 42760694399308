import { Table as MuiTable,useTheme } from '@mui/material'
import { ReactNode } from 'react'

export const Table = ({ children }: { children: NonNullable<ReactNode> }) => {
  const theme = useTheme()

  return (
    <MuiTable
      sx={{
        '& .MuiTableCell-root': {
          border: `1px solid ${theme.colors.WHITE_60}`,
          fontFamily: 'inherit',
        },
      }}
    >
      {children}
    </MuiTable>
  )
}
