import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_ROUTE,
  API_DOC_V2_ROUTE,
  API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE,
  API_DOC_V2_SAMPLE_ROUTE,
} from 'constants/routeParams'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl -X POST "https://api.threatvision.org/api/v2/samples" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN" \\
  -F "file=@./sample.exe"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success": true,
  "url": "https://api.threatvision.org/api/v2/samples/UPLOADED_SAMPLE_HASH"
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'request-body', text: 'Request Body (multipart/form-data)' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Upload Sample'

export const APIDocumentVersionTwoUploadSample = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          You can upload your files to ThreatVision for auto analysis.
          <br />
          Auto analysis for each file would take 1 AAP (Automated Analysis
          Point).
          <br />
          The results of the analysis can be retrieved from the{' '}
          <TextLink
            to={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE}`}
            size="large"
          >
            List Uploaded Samples
          </TextLink>{' '}
          endpoint.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          POST{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples" />
        </Description>
        <TocLink
          text="Request Body (multipart/form-data)"
          hash="request-body"
        />
        <UnorderedList>
          <ListItem>
            file
            <UnorderedList>
              <ListItem>The file to upload</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            url
            <UnorderedList>
              <ListItem>Url for sample’s detail page</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
