import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_MALWARES,
  getMalwareById,
  getMalwareRelatedToolsById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchMalware,
  fetchMalwareCancelled,
  fetchMalwareFulfilled,
  fetchMalwareRejected,
  fetchMalwareRelatedTools,
  fetchMalwareRelatedToolsCancelled,
  fetchMalwareRelatedToolsFulfilled,
  fetchMalwareRelatedToolsRejected,
  fetchMalwares,
  fetchMalwaresCancelled,
  fetchMalwaresFulfilled,
  fetchMalwaresRejected,
} from 'store/slices/malware'
import {
  mapAPIMalwaresToState,
  mapAPIMalwareToState,
} from 'store/types/entityTypes/malware'
import { mapAPIToolsToState } from 'store/types/entityTypes/tool'
import { IFetchToolsResponse } from 'store/types/slicesTypes/download'
import {
  IFetchMalwareResponse,
  IFetchMalwaresResponse,
} from 'store/types/slicesTypes/malware'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const fetchMalwaresEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMalwares.match),
    switchMap((action) =>
      fetchData<IFetchMalwaresResponse>({
        url: `${FETCH_MALWARES}?offset=${action.payload.offset}`,
      }).pipe(
        map((response: AjaxResponse<IFetchMalwaresResponse>) =>
          fetchMalwaresFulfilled(
            mapAPIMalwaresToState(response.response.malwares)
          )
        ),
        takeUntil(action$.pipe(filter(fetchMalwaresCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMalwaresRejected(error))
          )
        )
      )
    )
  )

const fetchMalwareEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMalware.match),
    switchMap((action) =>
      fetchData<IFetchMalwareResponse>({
        url: getMalwareById(action.payload.id),
      }).pipe(
        map((response: AjaxResponse<IFetchMalwareResponse>) =>
          fetchMalwareFulfilled(mapAPIMalwareToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchMalwareCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchMalwareRejected(error)))
        )
      )
    )
  )

const fetchMalwareTools: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMalwareRelatedTools.match),
    switchMap((action) =>
      fetchData<IFetchToolsResponse>({
        url: getMalwareRelatedToolsById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchToolsResponse>) =>
          fetchMalwareRelatedToolsFulfilled(
            mapAPIToolsToState(response.response.tools)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchMalwareRelatedToolsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMalwareRelatedToolsRejected(error))
          )
        )
      )
    )
  )

export const malwareEpic = combineEpics(
  fetchMalwaresEpic,
  fetchMalwareEpic,
  fetchMalwareTools
)
