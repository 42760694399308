import { Box } from '@mui/material'
import { AdversaryPreview } from 'components/Adversary/AdversaryPreview'
import { AdversaryLoading } from 'components/Loading/AdversaryLoading'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchAdversariesData,
  fetchSearchAdversariesDataCancelled,
  resetSearchAdversariesData,
  selectSearchAdversaries,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'adversary-scrollable-container'

export const AdversaryContent = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const adversaries = useAppSelector(selectSearchAdversaries)
  const adversariesCurrentCount = adversaries.data.length
  const isAdversariesLoading = adversaries.isDataLoading
  const hasMoreAdversaries = adversaries.hasMoreData

  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchAdversaries = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchAdversariesData({
          searchText,
          offset: refetch ? 0 : adversariesCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchAdversaries(true)
    return () => {
      dispatch(fetchSearchAdversariesDataCancelled())
      dispatch(resetSearchAdversariesData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreAdversaries && adversariesCurrentCount > 0) {
      fetchAdversaries()
    }
  }, [inView, hasMoreAdversaries, adversariesCurrentCount])

  if (isAdversariesLoading && adversariesCurrentCount === 0) {
    return <AdversaryLoading />
  }

  if (adversariesCurrentCount === 0 && !isAdversariesLoading) {
    return <NoResultContent />
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ overflowY: 'auto', height: '100%' }}
    >
      {adversaries.data.map((adversary) => (
        <AdversaryPreview key={adversary.name} {...adversary} />
      ))}
      {hasMoreAdversaries && <CenterCircularProgress scrollRef={ref} />}

      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
