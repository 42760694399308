import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { ADVERSARIES_ROUTE, ADVERSARY_INFO_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { IAdversary } from 'store/types/entityTypes/adversary'

import {
  AdversaryAliasList,
  AdversaryIntroduction,
  AdversaryOrigin,
  AdversaryTargetList,
} from './AdversaryLabels'

export const AdversaryPreview = ({
  id,
  name,
  aliases,
  overview,
  originCountries,
  targetedCountries,
  targetedIndustries,
  image,
}: IAdversary) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  const imageUrl = useImageUrl({ fetchImageUrl: image })

  const country = originCountries[0] || ''

  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      {imageUrl && (
        <Image
          style={{ minWidth: 120, minHeight: 120 }}
          width={120}
          height={120}
          src={imageUrl}
        />
      )}
      <Stack sx={{ p: 2, gap: 2, color: theme.colors.WHITE }}>
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {name}
        </Typography>
        <AdversaryAliasList aliases={aliases} />
        {country && <AdversaryOrigin country={country} />}
        <AdversaryTargetList
          labels={targetedCountries}
          keyText={t('adversaryPreview.labels.targetCountry', {
            ns: 'component',
          })}
        />
        <AdversaryTargetList
          labels={targetedIndustries}
          keyText={t('adversaryPreview.labels.targetIndustry', {
            ns: 'component',
          })}
        />
        <AdversaryIntroduction overview={overview} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <LinkButton
            to={`/${ADVERSARIES_ROUTE}/${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_ID}=${id}`}
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            {t('readMoreCta', { ns: 'component' })}
          </LinkButton>
        </Box>
      </Stack>
    </Box>
  )
}
