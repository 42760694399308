import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchDomainDNSRecords,
  fetchDomainDNSRecordsCancelled,
  resetDomainDNSRecords,
  selectDomainDNSRecords,
  selectDomainDNSRecordsLoading,
  selectHasMoreDomainDNSRecords,
} from 'store/slices/domain'
import {
  fetchIPDNSRecords,
  fetchIPDNSRecordsCancelled,
  resetIPDNSRecords,
  selectHasMoreIPDNSRecords,
  selectIPDNSRecords,
  selectIPDNSRecordsLoading,
} from 'store/slices/ip'

import { IContentProps } from '../config'
import { DNSRecordLoading } from './DNSRecordLoading'

const CHECH_REACH_BOTTOM_DELAY = 1000

// bind table elements for infinite scroll detection
const TABLE_CONTAINER_ID = 'dns-records-table-container'
const TABLE_ID = 'dns-records-table'

export const DNSRecordContent = ({ searchText, detailType }: IContentProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation(['ipDomain'])
  const tableBottom = useRef<HTMLDivElement | null>(null)

  const headCells = [
    t('dnsRecords.date', { ns: 'ipDomain' }),
    t('dnsRecords.type', { ns: 'ipDomain' }),
    t('dnsRecords.value', { ns: 'ipDomain' }),
  ]

  const isIP = detailType === 'ip'

  const ipDNSRecords = useAppSelector(selectIPDNSRecords)
  const domainDNSRecords = useAppSelector(selectDomainDNSRecords)
  const dnsRecords = isIP ? ipDNSRecords : domainDNSRecords

  const isIPDNSRecordsLoading = useAppSelector(selectIPDNSRecordsLoading)
  const isDomainDNSRecordsLoading = useAppSelector(
    selectDomainDNSRecordsLoading
  )
  const isDNSRecordsLoading = isIP
    ? isIPDNSRecordsLoading
    : isDomainDNSRecordsLoading

  const hasMoreIPDNSRecords = useAppSelector(selectHasMoreIPDNSRecords)
  const hasMoreDomainDNSRecords = useAppSelector(selectHasMoreDomainDNSRecords)
  const hasMoreDNSRecords = isIP ? hasMoreIPDNSRecords : hasMoreDomainDNSRecords
  const handleFetchDNSRecords = (refetch?: boolean) => {
    if (hasMoreDNSRecords) {
      if (isIP) {
        dispatch(
          fetchIPDNSRecords({
            searchText,
            offset: refetch ? 0 : dnsRecords.length,
          })
        )
      } else {
        dispatch(
          fetchDomainDNSRecords({
            searchText,
            offset: refetch ? 0 : dnsRecords.length,
          })
        )
      }
    }
  }

  const handleCleanup = () => {
    if (isIP) {
      dispatch(fetchIPDNSRecordsCancelled())
      dispatch(resetIPDNSRecords())
    } else {
      dispatch(fetchDomainDNSRecordsCancelled())
      dispatch(resetDomainDNSRecords())
    }
  }

  useEffect(() => {
    handleFetchDNSRecords(true)
    return () => {
      handleCleanup()
    }
  }, [searchText])

  useEffect(() => {
    // 定期檢查是否有捲到最後一筆，觸發抓下一筆資料
    const fetchInterval = setInterval(() => {
      if (!isDNSRecordsLoading && hasMoreDNSRecords) {
        const tableBottomElement = tableBottom.current
        if (tableBottomElement) {
          const { top } = tableBottomElement.getBoundingClientRect()
          if (top < window.innerHeight) {
            handleFetchDNSRecords()
          }
        }
      }
    }, CHECH_REACH_BOTTOM_DELAY)
    return () => {
      clearInterval(fetchInterval)
    }
  }, [isDNSRecordsLoading, hasMoreDNSRecords])

  if (!isDNSRecordsLoading && dnsRecords.length === 0) {
    return <NoData />
  }

  return (
    <Box sx={{ height: '100%', width: '100%', overflow: 'hidden', p: 2 }}>
      <TableContainer
        id={TABLE_CONTAINER_ID}
        sx={{ height: '100%', maxHeight: '100%' }}
      >
        <Table
          id={TABLE_ID}
          stickyHeader
          sx={{
            border: `1px solid ${theme.colors.WHITE_20}`,
            borderRadius: 0.5,
          }}
        >
          <TableHead
            sx={{
              height: '2.5rem',
              bgcolor: theme.colors.BLACK_90,
            }}
          >
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell}
                  sx={{
                    color: theme.colors.WHITE,
                    width: '33%',
                    p: 2,
                    borderBottom: theme.colors.WHITE_20,
                    bgcolor: theme.colors.BLACK_90,
                  }}
                >
                  <Typography variant="titleSmall">{headCell}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                height: '2.25rem',
              },
            }}
          >
            {isDNSRecordsLoading && dnsRecords.length === 0 ? (
              <DNSRecordLoading />
            ) : (
              <>
                {dnsRecords.map((record, index) => (
                  <TableRow key={record.date + record.value}>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{record.date}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{record.type}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{record.value}</Typography>
                      {index === dnsRecords.length - 1 && (
                        <Box ref={tableBottom} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {hasMoreDNSRecords && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ p: 0, borderBottom: 'none' }}>
                      <Box sx={{ height: '4rem' }}>
                        <CenterCircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
        <ScrollToTop
          scrollableContainerId={TABLE_CONTAINER_ID}
          sx={{ position: 'sticky', bottom: '7%', left: '95%' }}
        />
      </TableContainer>
    </Box>
  )
}
