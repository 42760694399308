import { AdversaryTabContent } from 'components/Adversary/AdversaryTabContent'
import { MalwareTabContent } from 'components/Malware/MalwareTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { SampleTabContent } from 'components/Sample/SampleTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectTabAdversaryCount,
  selectTabAdversaryCountLoading,
  selectTabMalwareCount,
  selectTabMalwareCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
  selectTabSampleCount,
  selectTabSampleCountLoading,
} from 'store/slices/tabContent'

export interface ITechniqueCategoryTab {
  key: string
  name: string
  component: FC<{ searchText: string }>
  count: number
  isLoading?: boolean
  appendPlusSign?: boolean
}

export const useTechniqueCategoryTabs = (): ITechniqueCategoryTab[] => {
  const { t } = useTranslation(['capability', 'component'])
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)

  const tabAdversaryCountLoading = useAppSelector(
    selectTabAdversaryCountLoading
  )
  const tabAdversaryCount = useAppSelector(selectTabAdversaryCount)

  const tabMalwareCountLoading = useAppSelector(selectTabMalwareCountLoading)
  const tabMalwareCount = useAppSelector(selectTabMalwareCount)

  const tabSampleCountLoading = useAppSelector(selectTabSampleCountLoading)
  const tabSampleCount = useAppSelector(selectTabSampleCount)

  const tabs: ITechniqueCategoryTab[] = [
    {
      key: 'reports',
      name: t('categoryTabs.reports', { ns: 'component' }),
      component: ReportTabContent,
      count: tabReportCount,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'adversaries',
      name: t('categoryTabs.adversaries', { ns: 'component' }),
      component: AdversaryTabContent,
      count: tabAdversaryCount,
      isLoading: tabAdversaryCountLoading,
    },
    {
      key: 'malware',
      name: t('categoryTabs.malware', { ns: 'component' }),
      component: MalwareTabContent,
      count: tabMalwareCount,
      isLoading: tabMalwareCountLoading,
    },
    {
      key: 'samples',
      name: t('categoryTabs.samples', { ns: 'component' }),
      component: SampleTabContent,
      count: tabSampleCount,
      isLoading: tabSampleCountLoading,
      appendPlusSign: tabSampleCount >= 1000,
    },
  ]

  return tabs
}
