import { Stack, Typography, useTheme } from '@mui/material'
import { TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { TIocSubscriberGroupsKey } from 'store/types/entityTypes/tlpTarget'

import { SubscriptionIoCLoading } from './SubscriptionIoCLoading'

export const SubscriptionIoC = ({ iocSubscriberGroups }: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const { formatDuration } = useDateTime()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const iocSubscriberGroupDisplay: Record<TIocSubscriberGroupsKey, string> = {
    'apt_ioc_list_subscribers': t('subscribedIoCs.apt', { ns: 'account' }),
    'cyber_crime_list_subscribers': t('subscribedIoCs.cyberCrime', {
      ns: 'account',
    }),
  }

  return (
    <PageSection title={t('subscribedIoCs.title', { ns: 'account' })}>
      {accountSettingsLoading ? (
        <SubscriptionIoCLoading />
      ) : (
        <Stack sx={{ gap: 1, px: 4, py: 2 }}>
          {iocSubscriberGroups?.length === 0 ? (
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('subscribedIoCs.emptyState', { ns: 'account' })}
            </Typography>
          ) : (
            iocSubscriberGroups &&
            iocSubscriberGroups.map((iocSubscriberGroup) => (
              <TwoToneLabel
                key={iocSubscriberGroup.name}
                keyText={iocSubscriberGroupDisplay[iocSubscriberGroup.key]}
                valueText={formatDuration(
                  iocSubscriberGroup.joinedAt,
                  iocSubscriberGroup.expiresAt
                )}
                mainColor={theme.colors.HACKING_TOOL}
                textColor={theme.colors.BLACK_90}
              />
            ))
          )}
        </Stack>
      )}
    </PageSection>
  )
}
