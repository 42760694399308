import { AdversaryTabContent } from 'components/Adversary/AdversaryTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectIPDetail, selectIPDetailLoading } from 'store/slices/ip'
import {
  selectTabAdversaryCount,
  selectTabAdversaryCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
} from 'store/slices/tabContent'

import { TDetailType } from '../config'
import { CommunityContent } from '../IPDomainComponents/CommunityContent'
import { DNSRecordContent } from '../IPDomainComponents/DNSRecordContent'
import { RelationSamples } from '../IPDomainComponents/RelationSamples'
import { WhoisContent } from '../IPDomainComponents/WhoisContent'

export type TIPKey =
  | 'dns-records'
  | 'reports'
  | 'adversaries'
  | 'samples'
  | 'info'
  | 'community'

export interface IIPTab {
  key: TIPKey
  name: string
  component: FC<{ searchText: string; detailType: TDetailType }>
  count?: number
  showCount?: boolean
  isLoading?: boolean
  appendPlusSign?: boolean
}

export const useIpCategoryTabs = (): IIPTab[] => {
  const { t } = useTranslation(['ipDomain'])
  const isIPDetailLoading = useAppSelector(selectIPDetailLoading)
  const ipDetail = useAppSelector(selectIPDetail)
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)

  const tabAdversaryCountLoading = useAppSelector(
    selectTabAdversaryCountLoading
  )
  const tabAdversaryCount = useAppSelector(selectTabAdversaryCount)

  return [
    {
      key: 'dns-records',
      name: t('contentTabs.dnsRecords', { ns: 'ipDomain' }),
      component: DNSRecordContent,
      count: ipDetail?.summary.dnsRecords,
      isLoading: isIPDetailLoading,
    },
    {
      key: 'reports',
      name: t('contentTabs.reports', { ns: 'ipDomain' }),
      component: ReportTabContent,
      count: tabReportCount,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'adversaries',
      name: t('contentTabs.adversaries', { ns: 'ipDomain' }),
      component: AdversaryTabContent,
      count: tabAdversaryCount,
      isLoading: tabAdversaryCountLoading,
    },
    {
      key: 'samples',
      name: t('contentTabs.samples', { ns: 'ipDomain' }),
      component: RelationSamples,
      count: ipDetail?.summary.relatedSamples,
      isLoading: isIPDetailLoading,
      appendPlusSign:
        !!ipDetail?.summary.relatedSamples &&
        ipDetail?.summary.relatedSamples >= 1000,
    },
    {
      key: 'info',
      name: t('contentTabs.info', { ns: 'ipDomain' }),
      component: WhoisContent,
      showCount: false,
    },
    {
      key: 'community',
      name: t('contentTabs.community', { ns: 'ipDomain' }),
      component: CommunityContent,
      showCount: false,
    },
  ]
}
