import { TableRow, useTheme } from '@mui/material'
import { ReactNode } from 'react'

export const TableHeaderRow = ({
  children,
}: {
  children: NonNullable<ReactNode>
}) => {
  const theme = useTheme()

  return (
    <TableRow
      sx={{
        bgcolor: theme.colors.PRIMARY_20,
        '.MuiTableCell-root': {
          verticalAlign: 'top',
          color: theme.colors.WHITE,
          ...theme.typography.reportH5,
        },
      }}
    >
      {children}
    </TableRow>
  )
}
