import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import { BlockTitle } from 'components/BlockTitle/BlockTitle'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { TwoToneLabel } from 'components/Label/Label'
import { SampleTitle } from 'components/Sample/SampleElements'
import { UploadedSample } from 'components/Sample/UploadedSample'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { PAGE_TITLE_SAMPLE_MANAGEMENT } from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSubmissions,
  fetchSubmissionsCancelled,
  resetSubmissions,
  selectFetchSubmissionLoading,
  selectHasMoreSubmissions,
  selectSubmissions,
  selectUploadSampleInfo,
} from 'store/slices/sample'
import { TRiskLevelPayload } from 'store/types/slicesTypes/sample'

import { SampleLoading } from './SampleLoading'
import { SampleToolbar } from './SampleToolbar'
import { SampleUploader } from './SampleUploader'

const SCROLLABLE_CONTAINER_ID = 'sample-content-container'

const NoSubmissionTVSmile = () => {
  const theme = useTheme()
  const { t } = useTranslation(['sample'])

  return (
    <Stack sx={{ pt: 12, gap: 2, alignItems: 'center' }}>
      <Typography variant="titleBig" sx={{ color: theme.colors.WHITE_60 }}>
        {t('emptyState.noFile', { ns: 'sample' })}
      </Typography>
      <Image
        src={SmileTVImage}
        alt={t('emptyState.noFile', { ns: 'sample' })}
        width={328}
        height={130}
      />
    </Stack>
  )
}

export const SampleManagementPage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['sample', 'component'])
  const rangeOptions = useDateRangeOptions()
  const { dateRangeOptionToTimes } = useDateTime()
  const dispatch = useAppDispatch()
  const uploadingInfo = useAppSelector(selectUploadSampleInfo)
  const samples = useAppSelector(selectSubmissions)
  const isFetchSubmissionsLoading = useAppSelector(selectFetchSubmissionLoading)
  const hasMoreSubmissions = useAppSelector(selectHasMoreSubmissions)
  const [isScrollable, ref, node] = useIsScrollable([samples])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)
  const [riskLevel, setRiskLevel] = useState<TRiskLevelPayload>('all')
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamSearchText = searchParams.get(
    SearchParamKey.SAMPLE_SEARCH_TEXT
  )
  const [searchText, setSearchText] = useState(searchParamSearchText)

  const isUploading = Boolean(
    uploadingInfo.filename && uploadingInfo.uploadDate
  )

  // 沒有過濾條件
  const noFilter =
    rangeOption === rangeOptions[0].value &&
    riskLevel === 'all' &&
    !searchParamSearchText

  const noSubmissionUI = noFilter ? (
    <NoSubmissionTVSmile />
  ) : (
    <Typography
      variant="textSmallImportant"
      sx={{ color: theme.colors.WHITE_60, px: 4, py: 2 }}
    >
      {t('emptyState.noMatchFilter', { ns: 'component' })}
    </Typography>
  )

  const handleReset = () => {
    setRangeOption('all')
    setRiskLevel('all')
    setSearchText('')
    searchParams.delete(SearchParamKey.SAMPLE_SEARCH_TEXT)
    setSearchParams(searchParams)
  }

  const handleRiskLevelChange = (level: TRiskLevelPayload) => {
    setRiskLevel(level)
  }

  const fetchSubmissionsData = (refetch?: boolean) => {
    dispatch(
      fetchSubmissions({
        offset: refetch ? 0 : samples.length,
        risk_level: riskLevel,
        query: searchParamSearchText || '',
        ...dateRangeOptionToTimes({ startDate, endDate })[
          rangeOption as TRangeOption
        ],
      })
    )
  }

  useEffect(() => {
    fetchSubmissionsData(true)

    return () => {
      dispatch(fetchSubmissionsCancelled())
      dispatch(resetSubmissions())
    }
  }, [startDate, endDate, riskLevel, rangeOption, searchParamSearchText])

  // infinite scroll fetch
  useEffect(() => {
    if (node && !isScrollable && hasMoreSubmissions) {
      fetchSubmissionsData()
    }
  }, [isScrollable, hasMoreSubmissions, node, samples.length])

  const showLoading = isFetchSubmissionsLoading && samples.length === 0

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_SAMPLE_MANAGEMENT}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          p: 1,
        }}
      >
        <Box
          sx={[
            {
              [theme.breakpoints.down('xl')]: {
                width: '100%',
              },
              height: '100%',
              border: `1px solid ${theme.colors.WHITE_20}`,
              borderRadius: 0.5,
              display: 'flex',
            },
          ]}
        >
          <Stack
            sx={{
              flexBasis: 0,
              flexGrow: 1,
              minWidth: '57rem',
              maxWidth: '85rem',
            }}
          >
            <BlockTitle
              title={t('entryTitle', { ns: 'sample' })}
              sx={{ bgcolor: theme.colors.BLACK_90 }}
            />
            <Stack sx={{ flexBasis: 0, flexGrow: 1 }}>
              <Box sx={{ flexShrink: 0 }}>
                <SampleToolbar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  rangeOption={rangeOption}
                  setRangeOption={setRangeOption}
                  riskLevel={riskLevel}
                  handleRiskLevelChange={handleRiskLevelChange}
                  handleReset={handleReset}
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Box>
              {showLoading ? (
                <SampleLoading />
              ) : samples.length > 0 ? (
                <Box
                  id={SCROLLABLE_CONTAINER_ID}
                  ref={ref}
                  sx={{
                    borderTop: `1px solid ${theme.colors.WHITE_20}`,
                    flexBasis: 0,
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  <InfiniteScrollWrapper
                    dataLength={samples.length}
                    next={fetchSubmissionsData}
                    hasMore={hasMoreSubmissions}
                    scrollableTarget={SCROLLABLE_CONTAINER_ID}
                  >
                    {isUploading && (
                      <Stack
                        sx={{
                          p: 4,
                          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                          gap: 4,
                        }}
                      >
                        <Stack sx={{ gap: 2 }}>
                          <TwoToneLabel
                            keyText={t('sampleInfo.uploadDate', {
                              ns: 'sample',
                            })}
                            valueText={uploadingInfo.uploadDate}
                            mainColor={theme.colors.WHITE_60}
                            textColor={theme.colors.BLACK_90}
                          />
                          <SampleTitle title={uploadingInfo.filename} />
                        </Stack>
                        <Stack sx={{ gap: 2 }}>
                          <Skeleton type="body" width="19rem" />
                          <Skeleton type="body" width="29.25rem" />
                          <Skeleton type="body" width="40.625rem" />
                        </Stack>
                      </Stack>
                    )}
                    {samples.map((sample) => (
                      <Box
                        key={sample.md5}
                        sx={{
                          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                        }}
                      >
                        <UploadedSample {...sample} />
                      </Box>
                    ))}
                  </InfiniteScrollWrapper>
                  <ScrollToTop
                    scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                    sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                  />
                </Box>
              ) : (
                noSubmissionUI
              )}
            </Stack>
          </Stack>
          <Divider
            orientation="vertical"
            sx={{ borderColor: theme.colors.WHITE_20 }}
          />
          <Box
            sx={{
              display: 'flex',
              flexShrink: 0,
              width: '20.5rem',
              [theme.breakpoints.up('desktop')]: {
                width: '26.5rem',
              },
              [theme.breakpoints.up('xl')]: {
                width: '32.5rem',
              },
              p: 6,
              bgcolor: theme.colors.BLACK_90,
            }}
          >
            <Box
              sx={{
                height: '100%',
                bgcolor: theme.colors.BLACK_85,
                borderRadius: 0.5,
              }}
            >
              <SampleUploader />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
