import { getResetPasswordById, REQUEST_RESET_PASSWORD } from 'constants/api'
import { LocalStorageKey } from 'constants/localStorageKeys'
import { addSeconds } from 'date-fns'
import { IAuthAPIResponse } from 'store/types/slicesTypes/auth'

export const getExpireTime = (expireIn: number) =>
  addSeconds(new Date(), expireIn).getTime()

export const getAuthToken = () => ({
  accessToken: localStorage.getItem(LocalStorageKey.AUTH_TOKEN) || '',
  tokenType: localStorage.getItem(LocalStorageKey.AUTH_TOKEN_TYPE) || '',
  expireTime: localStorage.getItem(LocalStorageKey.AUTH_EXPIRE_TIME)
    ? Number(localStorage.getItem(LocalStorageKey.AUTH_EXPIRE_TIME))
    : null,
})

export const setAuthToken = (tokenProps: IAuthAPIResponse) => {
  localStorage.setItem(LocalStorageKey.AUTH_TOKEN, tokenProps.access_token)
  localStorage.setItem(LocalStorageKey.AUTH_TOKEN_TYPE, tokenProps.token_type)
  localStorage.setItem(
    LocalStorageKey.AUTH_EXPIRE_TIME,
    String(getExpireTime(tokenProps.expires_in))
  )
}

export const clearAuthToken = () => {
  localStorage.removeItem(LocalStorageKey.AUTH_TOKEN)
  localStorage.removeItem(LocalStorageKey.AUTH_TOKEN_TYPE)
  localStorage.removeItem(LocalStorageKey.AUTH_EXPIRE_TIME)
}

export const setPathBeforeLogout = (path: string) =>
  localStorage.setItem(LocalStorageKey.PATH_BEFORE_LOGOUT, path)

export const getPathBeforeLogout = () =>
  localStorage.getItem(LocalStorageKey.PATH_BEFORE_LOGOUT)

export const clearPathBeforeLogout = () =>
  localStorage.removeItem(LocalStorageKey.PATH_BEFORE_LOGOUT)

export const requestResetPasswordByEmail = (email: string) =>
  fetch(
    `${REQUEST_RESET_PASSWORD}?password[email]=${encodeURIComponent(email)}`,
    {
      method: 'POST',
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error()
    }
  })

export const resetPassword = (
  password: string,
  userId: string,
  token: string
) =>
  fetch(
    `${getResetPasswordById(
      userId
    )}?token=${token}&password_reset[password]=${encodeURIComponent(password)}`,
    { method: 'PATCH' }
  ).then((response) => {
    if (!response.ok) {
      throw new Error()
    }
  })
