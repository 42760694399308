import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

export const ReportEntryPreviewLoading = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{ gap: 4, p: 4, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
    >
      <Skeleton type="title" width="13.125rem" />
      <Stack sx={{ gap: 2, pb: 20 }}>
        <Skeleton type="body" width="100%" />
        <Skeleton type="body" width="100%" />
      </Stack>
    </Stack>
  )
}

export const PMRPreviewLoading = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{ gap: 2, p: 4, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
    >
      <Skeleton type="body" width="4.4375rem" />
      <Skeleton type="title" width="15rem" />
    </Stack>
  )
}

const ReportColumnLoading = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        height: '100%',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        color: theme.colors.WHITE,
      }}
    >
      <Skeleton type="image" width="100%" height="9.75rem" />
      <Stack sx={{ py: 2 }}>
        <ReportEntryPreviewLoading />
        <ReportEntryPreviewLoading />
        <ReportEntryPreviewLoading />
      </Stack>
    </Box>
  )
}

export const ReportEntryLoading = () => (
  <>
    <ReportColumnLoading />
    <ReportColumnLoading />
    <ReportColumnLoading />
  </>
)

const PMRColumnLoading = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        height: '100%',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        color: theme.colors.WHITE,
      }}
    >
      <Skeleton type="image" width="100%" height="9.75rem" />
      <Stack sx={{ py: 2 }}>
        {generateSkeletons(6, <PMRPreviewLoading />)}
      </Stack>
    </Box>
  )
}

export const PMREntryLoading = () => (
  <>
    <ReportColumnLoading />
    <PMRColumnLoading />
  </>
)

const ReportPreviewLoading = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 8,
        p: 4,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        width: '100%',
      }}
    >
      <Stack sx={{ gap: 4, width: '100%' }}>
        <Skeleton type="title" width="15rem" />
        <Stack sx={{ gap: 2 }}>
          <Skeleton type="body" width="100%" />
          <Skeleton type="body" width="100%" />
        </Stack>
      </Stack>
      <Box>
        <Skeleton type="image" width="10rem" height="10rem" />
      </Box>
    </Box>
  )
}

export const ReportListLoading = () => (
  <Stack sx={{ py: 2, gap: 1, width: '100%' }}>
    <ReportPreviewLoading />
    <ReportPreviewLoading />
    <ReportPreviewLoading />
  </Stack>
)

export const PMRListLoading = () => (
  <Stack sx={{ gap: 1 }}>{generateSkeletons(7, <PMRPreviewLoading />)}</Stack>
)
