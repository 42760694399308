import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as CopyIcon } from 'assets/basicIcons/copy.svg'
import { ReactComponent as ReportIcon } from 'assets/report/icons/report.svg'
import { Button } from 'components/Button/Button'
import { PageSection } from 'components/PageSection/PageSection'
import { Switch } from 'components/Switch/Switch'
import { TextField } from 'components/TextField/TextField'
import {
  API_DOC_AUTHENTICATION_ROUTE,
  API_DOC_INTRODUCTION_ROUTE,
  API_DOC_ROUTE,
} from 'constants/routeParams'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createAccountAPIKeys,
  deleteAccountAPIKeys,
  fetchAccountAPIKeys,
  fetchAccountAPIKeysCancelled,
  replaceAccountAPIKeys,
  selectAccountAPIKeys,
} from 'store/slices/account'
import { pushSuccessSnackbar } from 'store/slices/snackbar'

export const API = () => {
  const theme = useTheme()
  const { t } = useTranslation(['account', 'snackbar'])
  const dispatch = useAppDispatch()
  const apiKeys = useAppSelector(selectAccountAPIKeys)

  const handleChange = () => {
    if (!apiKeys.enable) {
      dispatch(createAccountAPIKeys())
    } else {
      dispatch(deleteAccountAPIKeys())
    }
  }

  const copyClientId = () => {
    navigator.clipboard.writeText(apiKeys.clientId)
    dispatch(
      pushSuccessSnackbar({ text: t('copySuccess', { ns: 'snackbar' }) })
    )
  }

  const copyClientSecret = () => {
    navigator.clipboard.writeText(apiKeys.clientSecret)
    dispatch(
      pushSuccessSnackbar({ text: t('copySuccess', { ns: 'snackbar' }) })
    )
  }

  const handleRevoke = () => {
    if (apiKeys.enable) dispatch(replaceAccountAPIKeys())
  }

  useEffect(() => {
    dispatch(fetchAccountAPIKeys())

    return () => {
      dispatch(fetchAccountAPIKeysCancelled())
    }
  }, [])

  return (
    <PageSection
      title={t('api.title', { ns: 'account' })}
      titleInfo={
        <Box sx={{ ml: -3 }}>
          <Switch
            checked={apiKeys.enable}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      }
      titleAction={
        <Button
          variant="textColor"
          href={`/${API_DOC_ROUTE}/${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_INTRODUCTION_ROUTE}`}
        >
          <SvgIcon
            component={ReportIcon}
            inheritViewBox
            sx={{
              path: { fill: theme.colors.TEXT_LINK_NORMAL },
              width: '1rem',
              height: '1rem',
            }}
          />
          {t('api.apiDocumentCta', { ns: 'account' })}
        </Button>
      }
    >
      <Stack sx={{ gap: 4, px: 4, py: 2 }}>
        <TextField
          name="clientId"
          label={t('api.clientId', { ns: 'account' })}
          type="text"
          // no value needs to display focus textField style
          value={apiKeys.clientId || ' '}
          disabled={!apiKeys.enable}
          sx={{
            width: '29.5rem',
            '.MuiInputBase-root': {
              p: 0,
            },
            '.MuiInputBase-root .MuiInputBase-input': {
              color: theme.colors.WHITE_60,
            },
            '& .MuiInputBase-root.Mui-disabled': {
              '& > fieldset': {
                borderColor: theme.colors.DISABLE,
              },
            },
            '.Mui-disabled': {
              color: theme.colors.DISABLE,
              WebkitTextFillColor: theme.colors.DISABLE,
            },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Box
                sx={{
                  bgcolor: apiKeys.enable
                    ? theme.colors.PRIMARY_40
                    : 'transparent',
                  width: '3rem',
                  height: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '0 4px 4px 0',
                  borderLeft: apiKeys.enable
                    ? `1px solid ${theme.colors.WHITE_60}`
                    : `1px solid ${theme.colors.DISABLE}`,
                  cursor: apiKeys.enable ? 'pointer' : 'default',
                  '&:active': {
                    bgcolor: theme.colors.PRIMARY_60,
                  },
                }}
                onClick={apiKeys.enable ? copyClientId : () => {}}
              >
                <SvgIcon
                  component={CopyIcon}
                  inheritViewBox
                  width={24}
                  height={24}
                  sx={{
                    color: apiKeys.enable
                      ? theme.colors.WHITE
                      : theme.colors.DISABLE,
                  }}
                />
              </Box>
            ),
          }}
        />
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            name="clientSecret"
            label={t('api.clientSecret', { ns: 'account' })}
            type="text"
            // no value needs to display focus textField style
            value={apiKeys.clientSecret || ' '}
            disabled={!apiKeys.enable}
            sx={{
              width: '29.5rem',
              '.MuiInputBase-root': {
                p: 0,
              },
              '.MuiInputBase-root .MuiInputBase-input': {
                color: theme.colors.WHITE_60,
              },
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: theme.colors.DISABLE,
                },
              },
              '.Mui-disabled': {
                color: theme.colors.DISABLE,
                WebkitTextFillColor: theme.colors.DISABLE,
              },
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Box
                  sx={{
                    bgcolor: apiKeys.enable
                      ? theme.colors.PRIMARY_40
                      : 'transparent',
                    width: '3rem',
                    height: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0 4px 4px 0',
                    borderLeft: apiKeys.enable
                      ? `1px solid ${theme.colors.WHITE_60}`
                      : `1px solid ${theme.colors.DISABLE}`,
                    cursor: apiKeys.enable ? 'pointer' : 'default',
                    '&:active': {
                      bgcolor: theme.colors.PRIMARY_60,
                    },
                  }}
                  onClick={apiKeys.enable ? copyClientSecret : () => {}}
                >
                  <SvgIcon
                    component={CopyIcon}
                    inheritViewBox
                    width={24}
                    height={24}
                    sx={{
                      color: apiKeys.enable
                        ? theme.colors.WHITE
                        : theme.colors.DISABLE,
                    }}
                  />
                </Box>
              ),
            }}
          />
          <Box
            onClick={handleRevoke}
            sx={{ cursor: apiKeys.enable ? 'pointer' : 'default' }}
          >
            <Typography
              variant="textLink"
              sx={{
                color: apiKeys.enable
                  ? theme.colors.TEXT_LINK_NORMAL
                  : theme.colors.DISABLE,
              }}
            >
              {t('api.revokeCta', { ns: 'account' })}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </PageSection>
  )
}
