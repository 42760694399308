import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as VisibilityIcon } from 'assets/basicIcons/visibility.svg'
import { ReactComponent as VisibilityOffIcon } from 'assets/basicIcons/visibilityOff.svg'
import { Button } from 'components/Button/Button'
import { Drawer } from 'components/Drawer/Drawer'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectUpdateAccountPasswordLoading,
  updateAccountPassword,
} from 'store/slices/account'
import * as Yup from 'yup'

interface IDescriptionDrawerProps {
  drawerOpen: boolean
  toggleDrawer: () => void
}

export const AccountDrawer = ({
  drawerOpen,
  toggleDrawer,
}: IDescriptionDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['account', 'component'])
  const dispatch = useAppDispatch()
  const isUpdateAccountPasswordLoading = useAppSelector(
    selectUpdateAccountPasswordLoading
  )
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false)

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show)

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)

  const handleClickShowNewPasswordConfirmation = () =>
    setShowNewPasswordConfirmation((show) => !show)

  const changePasswordFormInitialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  const changePasswordFormSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      t('fieldErrorMessage.required', { ns: 'component' })
    ),
    newPassword: Yup.string().required(
      t('fieldErrorMessage.required', { ns: 'component' })
    ),
    newPasswordConfirmation: Yup.string()
      .required(t('fieldErrorMessage.required', { ns: 'component' }))
      .oneOf(
        [Yup.ref('newPassword')],
        t('fieldErrorMessage.confirmPassword', { ns: 'component' })
      ),
  })

  const toggleDrawerAction = () => {
    toggleDrawer()
  }

  const {
    touched,
    errors,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues: changePasswordFormInitialValues,
    validationSchema: changePasswordFormSchema,
    onSubmit: () => {
      dispatch(
        updateAccountPassword({
          old_password: values.currentPassword,
          new_password: values.newPassword,
          successCallback: () => {
            toggleDrawerAction()
            setValues(changePasswordFormInitialValues)
          },
          failCallback: () => {
            setFieldError(
              'currentPassword',
              t('fieldErrorMessage.wrongPassword', { ns: 'component' })
            )
          },
        })
      )
    },
  })

  const onDrawerClose = () => {
    toggleDrawerAction()
    setValues(changePasswordFormInitialValues)
  }

  return (
    <Drawer open={drawerOpen} onClose={onDrawerClose}>
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {t('account.changePasswordDrawer.title', { ns: 'account' })}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20, mb: 2 }} />
      <form onSubmit={handleSubmit}>
        <Stack sx={{ gap: 7 }}>
          <TextField
            name="currentPassword"
            label={t('account.changePasswordDrawer.currentPassword', {
              ns: 'account',
            })}
            type={showCurrentPassword ? 'text' : 'password'}
            value={values.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ width: '25rem' }}
            error={touched.currentPassword && !!errors.currentPassword}
            helperText={
              touched.currentPassword &&
              !!errors.currentPassword &&
              errors.currentPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ ml: 0 }} position="end">
                  {showCurrentPassword ? (
                    <SvgIcon
                      onClick={handleClickShowCurrentPassword}
                      sx={{
                        width: '1rem',
                        height: '1rem',
                        cursor: 'pointer',
                      }}
                      component={VisibilityIcon}
                      inheritViewBox
                    />
                  ) : (
                    <SvgIcon
                      onClick={handleClickShowCurrentPassword}
                      sx={{
                        width: '1rem',
                        height: '1rem',
                        cursor: 'pointer',
                      }}
                      component={VisibilityOffIcon}
                      inheritViewBox
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="newPassword"
            label={t('account.changePasswordDrawer.newPassword', {
              ns: 'account',
            })}
            type={showNewPassword ? 'text' : 'password'}
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.newPassword && !!errors.newPassword}
            helperText={
              touched.newPassword && !!errors.newPassword && errors.newPassword
            }
            sx={{ width: '25rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ ml: 0 }} position="end">
                  {showNewPassword ? (
                    <SvgIcon
                      onClick={handleClickShowNewPassword}
                      sx={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                      component={VisibilityIcon}
                      inheritViewBox
                    />
                  ) : (
                    <SvgIcon
                      onClick={handleClickShowNewPassword}
                      sx={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                      component={VisibilityOffIcon}
                      inheritViewBox
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="newPasswordConfirmation"
            label={t('account.changePasswordDrawer.newPasswordConfirmation', {
              ns: 'account',
            })}
            type={showNewPasswordConfirmation ? 'text' : 'password'}
            value={values.newPasswordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.newPasswordConfirmation &&
              !!errors.newPasswordConfirmation
            }
            helperText={
              touched.newPasswordConfirmation &&
              !!errors.newPasswordConfirmation &&
              errors.newPasswordConfirmation
            }
            sx={{ width: '25rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ ml: 0 }} position="end">
                  {showNewPasswordConfirmation ? (
                    <SvgIcon
                      onClick={handleClickShowNewPasswordConfirmation}
                      sx={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                      component={VisibilityIcon}
                      inheritViewBox
                    />
                  ) : (
                    <SvgIcon
                      onClick={handleClickShowNewPasswordConfirmation}
                      sx={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                      component={VisibilityOffIcon}
                      inheritViewBox
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            disabled={
              values.currentPassword === '' ||
              values.newPassword === '' ||
              values.newPasswordConfirmation === '' ||
              values.newPasswordConfirmation !== values.newPassword
            }
            loading={isUpdateAccountPasswordLoading}
            sx={{ width: '10rem', py: 1 }}
          >
            {t('account.changePasswordDrawer.doneCta', { ns: 'account' })}
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}
