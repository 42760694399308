import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { OutlineLabel, TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { DDW_MONITOR_KEY } from 'constants/ddw'
import { DDW_ALERT_ROUTE } from 'constants/routeParams'
import { useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { selectAccountSettings } from 'store/slices/account'
import {
  fetchDdwReports,
  fetchDdwReportsCancelled,
  selectLatestDdwReports,
} from 'store/slices/ddw'

export const LatestDDWReportCount = () => {
  const { t } = useTranslation(['landingPage'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { formatDuration } = useDateTime()
  const latestDdwReport = useAppSelector(selectLatestDdwReports)
  const accountSettings = useAppSelector(selectAccountSettings)
  const isSubscribeDdwMonitor =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
    )

  useEffect(() => {
    dispatch(fetchDdwReports())
    return () => {
      dispatch(fetchDdwReportsCancelled())
    }
  }, [])

  return (
    <PageSection title={t('ddwAlert.title', { ns: 'landingPage' })}>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            height: '2.25rem',
            pl: 3,
            pr: 4,
            bgcolor: theme.colors.BLACK_90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            cursor: 'pointer',
            '&:hover': {
              bgcolor: theme.colors.BLACK_60,
            },
          }}
          component={Link}
          to={`/${DDW_ALERT_ROUTE}`}
        >
          {isSubscribeDdwMonitor && !latestDdwReport ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <OutlineLabel
                text={t('ddwAlert.monitoringInterval', { ns: 'landingPage' })}
                color={theme.colors.WHITE_60}
              />
              <Typography
                variant="titleBig"
                sx={{ color: theme.colors.WHITE_60 }}
              >
                --
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <TwoToneLabel
                keyText={t('ddwAlert.monitoringInterval', {
                  ns: 'landingPage',
                })}
                valueText={formatDuration(
                  latestDdwReport.reportStartAt / 1000,
                  latestDdwReport.reportEndAt / 1000
                )}
                mainColor={theme.colors.WHITE_60}
                textColor={theme.colors.BLACK_90}
              />
              <Typography
                variant="titleBig"
                sx={{ color: theme.colors.TLPRed }}
              >
                {latestDdwReport.reportCount}
              </Typography>
            </Box>
          )}
          <SvgIcon
            component={ArrowForwardIcon}
            inheritViewBox
            sx={{ width: '1rem', height: '1rem' }}
          />
        </Box>
      </Box>
    </PageSection>
  )
}
