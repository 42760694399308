import { SvgIcon } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { Button } from 'components/Button/Button'
import { Dialog } from 'components/Dialog/Dialog'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  selectAvailableRAH,
  selectIsFetchAccountSettingsLoading,
} from 'store/slices/account'
import { purchaseItem, selectPurchaseLoading } from 'store/slices/purchase'
import { pushAlertSnackbar } from 'store/slices/snackbar'
import { openMail } from 'utils/mail'

interface IReportPurchaseCtaProps {
  purchasedInformation?: {
    priceTagId: number
    credit: number
  }
  alias: string
}
export const ReportPurchaseCta = ({
  purchasedInformation,
  alias,
}: IReportPurchaseCtaProps) => {
  const { t } = useTranslation(['component'])
  const dispatch = useAppDispatch()
  const purchaseLoading = useAppSelector(selectPurchaseLoading)
  const availableRAH = useAppSelector(selectAvailableRAH)
  const isFetchAccountSetting = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchAccountSettings())

    return () => {
      dispatch(fetchAccountSettingsCancelled())
    }
  }, [])

  const isPurchasable =
    purchasedInformation &&
    !isFetchAccountSetting &&
    availableRAH >= purchasedInformation.credit

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const purchaseSuccessCallback = () => {
    navigate(
      `/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${alias}`
    )
    handleDialogClose()
  }

  const purchaseFailCallback = () => {
    dispatch(
      pushAlertSnackbar({
        text: t('purchase.purchaseFail', { ns: 'snackbar' }),
      })
    )
    handleDialogClose()
  }

  const handlePurchaseItem = () => {
    if (purchasedInformation) {
      dispatch(
        purchaseItem({
          priceTagId: purchasedInformation.priceTagId,
          successCallback: purchaseSuccessCallback,
          failCallback: purchaseFailCallback,
        })
      )
    }
  }

  const handleContactSales = () => {
    openMail()
    handleDialogClose()
  }

  return (
    <>
      {purchasedInformation ? (
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{
            minWidth: '15rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          onClick={handleDialogOpen}
          loading={isFetchAccountSetting}
          disabled={isFetchAccountSetting}
        >
          {t('rahPurchase.rahAccessReportCta', {
            ns: 'component',
            count: purchasedInformation.credit,
          })}
        </Button>
      ) : (
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{
            width: '15rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onClick={openMail}
        >
          {t('contactCta', { ns: 'component' })}
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        title={
          isPurchasable
            ? t('rahPurchase.enoughRahDialog.title', { ns: 'component' })
            : t('rahPurchase.notEnoughRahDialog.title', { ns: 'component' })
        }
        description={
          isPurchasable
            ? t('rahPurchase.enoughRahDialog.purchaseReportDescription', {
                ns: 'component',
                count: purchasedInformation.credit,
              })
            : t('rahPurchase.notEnoughRahDialog.description', {
                ns: 'component',
              })
        }
        confirmButtonText={
          isPurchasable
            ? t('yesCta', { ns: 'component' })
            : t('sendEmailCta', { ns: 'component' })
        }
        handleConfirmButtonClick={
          isPurchasable ? handlePurchaseItem : handleContactSales
        }
        confirmButtonLoading={purchaseLoading}
        confirmButtonDisabled={purchaseLoading}
      />
    </>
  )
}
