import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { ExampleReportBlock } from 'components/Documents/ExampleReportBlock'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H3Title } from 'components/Documents/H3Title'
import { H4Title } from 'components/Documents/H4Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import {
  DocumentContentWidth,
  DocumentTimestamp,
  ScrollableContainerId,
} from 'constants/document'
import { getPageTitleReportIntroductionByTitle } from 'constants/pageTitle'
import {
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

const sampleListHeadCells = [
  'No.',
  'Adversary',
  'Sample SHA256',
  'File Name',
  'Target',
  'Trojan',
  'C2',
]

const sampleListBodyRows = [
  {
    no: '1',
    adversary: 'Naikon',
    sampleSha256: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8e9c4c3063eda198a43a4b91349eebbc7dd7890f9cfb8084ec0c979b531ed7f9`}
      >
        8e9c4c3063eda198a43a4b91349eebbc7dd7890f9cfb8084ec0c979b531ed7f9
      </TextLink>
    ),
    fileName: 'kav64.exe',
    target: 'VN-Unknown',
    trojan: 'ZqPlug',
    c2: 'N/A',
  },
  {
    no: '2',
    adversary: 'Unknown',
    sampleSha256: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=e08926f20fd62aac218a70daa99c20169e2683311b2ef7d7dc64fe281f997fb6`}
      >
        e08926f20fd62aac218a70daa99c20169e2683311b2ef7d7dc64fe281f997fb6
      </TextLink>
    ),
    fileName: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=1ba9ac0ec78d6317e1e79ddf3007978d`}
      >
        1ba9ac0ec78d6317e1e79ddf3007978d
      </TextLink>
    ),
    target: 'HK-Gambling',
    trojan: 'Winnti_Linux_RAT',
    c2: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=103.145.145.121`}
      >
        103.145.145.121
      </TextLink>
    ),
  },
  {
    no: '3',
    adversary: 'SLIME41',
    sampleSha256: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=ad8f50270f44814bbb2497f2dc1235f97eaab0d726494d11c639f830f544bd34`}
      >
        ad8f50270f44814bbb2497f2dc1235f97eaab0d726494d11c639f830f544bd34
      </TextLink>
    ),
    fileName: 'move',
    target: 'US-Unknown',
    trojan: 'WheatPlug',
    c2: (
      <>
        bucket.fieldamazonszone.comavatars.apicomponent.comhd.usacomponent.com
        <br />
        <TextLink
          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=8.218.150.48`}
        >
          8.218.150.48
        </TextLink>
        <br />
        <TextLink
          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=47.243.68.26`}
        >
          47.243.68.26
        </TextLink>
      </>
    ),
  },
  {
    no: '4',
    adversary: 'SLIME1',
    sampleSha256: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=fe49375e13431ac55b710b78841704a16618077a47e55ac3de86506412c6c0d4`}
      >
        fe49375e13431ac55b710b78841704a16618077a47e55ac3de86506412c6c0d4
      </TextLink>
    ),
    fileName: 'zergsf.tmp',
    target: 'KR-Unknown',
    trojan: 'AsyncRAT',
    c2: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=20.214.233.183`}
      >
        20.214.233.183
      </TextLink>
    ),
  },
]

const maliciousListHeadCells = [
  'No.',
  'Adversary',
  'Malicious Infra',
  'Related Backdoor',
  'Related Exploit',
  'Target',
]

const maliciousListBodyRows = [
  {
    no: '1',
    adversary: '1SocialNetworkTeam',
    maliciousInfra: (
      <TextLink
        to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=38.54.63.20`}
      >
        38.54.63.20
      </TextLink>
    ),
    relatedBackdoor: 'PkgAgent',
    relatedExploit: 'N/A',
    target: 'ZA-Gov',
  },
  {
    no: '2',
    adversary: 'SLIME13',
    maliciousInfra: (
      <>
        <TextLink
          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=154.39.150.2`}
        >
          154.39.150.2
        </TextLink>
        <br />
        <TextLink
          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=14.128.33.8`}
        >
          14.128.33.8
        </TextLink>
      </>
    ),
    relatedBackdoor: 'SoftEther VPN',
    relatedExploit: 'N/A',
    target: 'TW-Electronics',
  },
]

const malwareTableHeadCells = [
  'Name',
  'Type',
  'Description',
  'Attribution',
  'First Seen',
]

const malwareTableBodyRows = [
  {
    name: 'ZqPlug',
    type: 'RAT',
    description:
      'ZqPlug is a modular backdoor that is associated with the Chinese APT group. The malware name comes from its PDB string in its early version. ZqPlug has numerous backdoor functions such as command shell, executing VBScript or JavaScript, collecting information of the victim system, and installing or freezing plugins. The malware supports both listen port mode as well as reverse mode to communicate with the C&C server.',
    attribution: 'Naikon',
    firstSeen: '2022.12',
  },
  {
    name: 'Winnti_Linux_RAT',
    type: 'RAT',
    description:
      'Winnti_Linux_RAT is comprised of two files: a main backdoor (libxselinux) and a library (libxselinux.so) used to hide its activity on an infected system. The library is based on an open-source user-land rootkit Azazel. The embedded configuration in main backdoor is decoded from the data section using a simple XOR cipher.',
    attribution: 'Shared',
    firstSeen: '2020.12',
  },
]
const SAMPLE_ANALYSIS_VICTIM_INFORMATION = `Country: VN
Sector/Industry: Unknown`

const MALICIOUS_INFRASTRUCTURE_ANALYSIS_VICTIM_INFORMATION = `Country: ZA
Sector/Industry: Gov
Organization: Unknown`

export const MonthlyIntroductionPage = () => {
  const { t } = useTranslation(['reportIntroduction'])
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  const tocList = [
    {
      id: 'introduction',
      text: t('monthly.introduction.title', { ns: 'reportIntroduction' }),
    },
    {
      id: 'monthly-report-in-detail',
      text: t('monthly.monthlyReportInDetail.title', {
        ns: 'reportIntroduction',
      }),
    },
    {
      id: 'example-report-monthly-report-2023-september',
      text: t('monthly.exampleReport.title', {
        ns: 'reportIntroduction',
        reportName: 'Monthly Report 2023 September',
      }),
    },
  ]

  return (
    <>
      <Helmet>
        <title>
          {getPageTitleReportIntroductionByTitle(
            t('monthly.title', {
              ns: 'reportIntroduction',
            })
          )}
        </title>
      </Helmet>
      <Box sx={{ display: 'flex' }}>
        <Stack
          id={ScrollableContainerId.TOP_ID}
          sx={{
            width: DocumentContentWidth.WIDTH,
            maxWidth: DocumentContentWidth.MAX_WIDTH,
            minWidth: DocumentContentWidth.MIN_WIDTH,
            px: 10,
            py: 4,
            gap: 2,
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          <H2ColorTitle
            title={t('monthly.title', { ns: 'reportIntroduction' })}
          />
          <DateLabel
            text={getDateStringByTimestampsInSeconds(
              DocumentTimestamp.REPORT_INTRODUCTION_TIMESTAMP
            )}
          />
          <TocLink
            text={t('monthly.introduction.title', { ns: 'reportIntroduction' })}
            hash="introduction"
          />
          <Description>
            <Trans t={t} i18nKey="monthly.introduction.description">
              <Typography variant="reportInlineTextBold">
                APT in Asia Monthly (aka Monthly Report)
              </Typography>
              provides strategic and actionable intelligence in the Asia Pacific
              region. Monthly Report will connect the dots between cyberattacks
              and recent political events, policies, and foreign affairs. Users
              can use Monthly Report to understand the latest trends and the
              current threat landscape.
            </Trans>
          </Description>
          <TocLink
            text={t('monthly.monthlyReportInDetail.title', {
              ns: 'reportIntroduction',
            })}
            hash="monthly-report-in-detail"
          />
          <Description>
            {t('monthly.monthlyReportInDetail.description.paragraphOne', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <Description>
            {t('monthly.monthlyReportInDetail.description.paragraphTwo', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <UnorderedList>
            <ListItem>
              {t('monthly.monthlyReportInDetail.list.overview', {
                ns: 'reportIntroduction',
              })}
              <UnorderedList>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.researchHighlights"
                  >
                    <InlineBlock text="Research Highlight" /> provides the key
                    highlights of the APT campaigns in this month.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.outlook"
                  >
                    <InlineBlock text="Outlook" /> provides the latest trends of
                    APT activities in Asia.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.sampleList"
                  >
                    <InlineBlock text="Sample List" /> provides summaries of the
                    campaigns with malicious samples.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.maliciousInfrastructureList"
                  >
                    <InlineBlock text="Malicious Infrastructure List" />{' '}
                    provides summaries of the campaigns with malicious
                    infrastructures.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.malwareTable"
                  >
                    <InlineBlock text="Malware Table" /> introduces the malware
                    mentioned in this report.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.sampleAnalysis"
                  >
                    <InlineBlock text="Sample Analysis" /> provides a detailed
                    technical analysis of each malicious sample.
                  </Trans>
                </ListItem>
                <ListItem>
                  <Trans
                    t={t}
                    i18nKey="monthly.monthlyReportInDetail.list.maliciousInfrastructureAnalysis"
                  >
                    <InlineBlock text="Malicious Infrastructure Analysis" />
                    provides a detailed technical analysis of each malicious
                    infrastructure.
                  </Trans>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <H4Title
            title={t('monthly.publicationFrequency.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            {t('monthly.publicationFrequency.description', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <H4Title
            title={t('monthly.reportLanguage.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            <Trans t={t} i18nKey="monthly.reportLanguage.description">
              Moreover, each Monthly Report has both English version and
              Japanese version.Each Monthly Report has both English version and
              Japanese version. In the English version, user will have the
              English version of the <InlineBlock text="Research Highlight" />
              and <InlineBlock text="Outlook" />. In the Japanese version, user
              will have the Mandarin version of the Executive Summary.
            </Trans>
          </Description>
          <H4Title
            title={t('monthly.reportStructure.title', {
              ns: 'reportIntroduction',
            })}
          />
          <UnorderedList>
            <ListItem>
              {t('monthly.reportStructure.list.researchHighlight', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('monthly.reportStructure.list.outlook', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('monthly.reportStructure.list.sampleList', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('monthly.reportStructure.list.maliciousInfrastructureList', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('monthly.reportStructure.list.malwareTable', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('monthly.reportStructure.list.sampleAnalysis', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t(
                'monthly.reportStructure.list.maliciousInfrastructureAnalysis',
                {
                  ns: 'reportIntroduction',
                }
              )}
            </ListItem>
          </UnorderedList>
          <TocLink
            text={t('monthly.exampleReport.title', {
              ns: 'reportIntroduction',
              reportName: 'Monthly Report 2023 September',
            })}
            hash="example-report-monthly-report-2023-september"
          />
          <Description>
            {t('monthly.exampleReport.englishVersion', {
              ns: 'reportIntroduction',
              reportName: 'Monthly Report 2023 September',
            })}
            <br />
            <TextLink
              size="large"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=monthly_report-20230905065535`}
            >
              https://threatvision.org/reports/detail?alias=monthly_report-20230905065535
            </TextLink>
          </Description>
          <Description>
            {t('monthly.exampleReport.japaneseVersion', {
              ns: 'reportIntroduction',
              reportName: 'Monthly Report 2023 September - 日本語版',
            })}
            <br />
            <TextLink
              size="large"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=monthly_report-20230908015118`}
            >
              https://threatvision.org/reports/detail?alias=monthly_report-20230908015118
            </TextLink>
          </Description>
          <ExampleReportBlock
            title="Research Highlight"
            description={t('monthly.researchHighlightDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <H3Title title="1-1. SoftEther VPN Remains a Weapon of Choice for SLIME13’s Ongoing Campaign Against Taiwan’s High-Tech Sector" />
            <Description>
              At the end of August, TeamT5 has obtained threat indicator that
              Chinese SLIME13 (aka Flax Typhoon, Ethereal Panda) attacked
              Taiwan’s electronics and information technology industry. The
              threat actor employs two C2 IPs (Malicious Infrastructure 2), both
              of which have the same digital certificate, indicating their
              consistent modus operandi, specifically using SoftEther VPN as
              communication tunnels between the C2 and victim hosts. Our
              investigation shows that the group is possibly targeting one of
              Taiwan’s gigantic conglomerates, as the actor controlled the IP
              address owned by that conglomerate’s subsidiary, the Internet
              Service Provider (ISP) in Taiwan. Furthermore, we have uncovered
              more victims targeted by SLIME13, which now includes several
              universities in Taiwan, as a consequence of this recent attack.
              Our extended investigation suggests that these victims may have
              been compromised by the threat actor approximately a year ago.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Outlook"
            description={t('monthly.outlookDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <H3Title title="2-1. Stay Alert to Huapi's Future Supply Chain Attack Leveraging Taiwan’s System Integrator as Springboard" />
            <Description>
              Recently, TeamT5 published a security advisory regarding an
              ongoing data gathering campaign (Sample 6) targeting Taiwan&apos;s
              research institutes which we attributed to Huapi. Since our last
              update, TeamT5 has further uncovered additional findings. These
              include (1) The Huapi operator was compromising routers in Taiwan
              as proxy for Command and Control (C2); and (2) they also
              registered an interesting domain{' '}
              <InlineBlock text="azion-service.com.tw" /> in June. This domain
              masquerades as a legitimate domain belonging to a large Taiwanese
              information technology company &quot;Azion Corporation&quot;(
              <InlineBlock text="www.azion.com.tw" />
              ). Although the actors skillfully crafted to mimic the official
              company domain, employing a legitimate digital signature (SHA-1:
              e7c11abecae4d75ca36675f676fab10eab849e43) and sophisticated
              deceptive techniques, our research team pinpointed several crucial
              differences between the malicious domain and the company&apos;s
              legitimate domain. One notable distinction was the use of a
              different email address in the WHOIS contact information.
              Furthermore, while the official company domain seamlessly connects
              to its own DNS server, the fake domain prepared by the Huapi
              operator only resolves to Taiwan&apos;s HiNet DNS server.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Sample List"
            description={t('monthly.sampleListDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <TableContainer sx={{ overflow: 'initial' }}>
              <Table>
                <TableHead>
                  <TableHeaderRow>
                    {sampleListHeadCells.map((headCell) => (
                      <TableCell key={headCell}>{headCell}</TableCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {sampleListBodyRows.map((bodyRow, index) => (
                    <TableBodyRow key={bodyRow.no} index={index}>
                      <TableCell>{bodyRow.no}</TableCell>
                      <TableCell>{bodyRow.adversary}</TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.sampleSha256}
                      </TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.fileName}
                      </TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.target}
                      </TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.trojan}
                      </TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.c2}
                      </TableCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Malicious Infrastructure List"
            description={t('monthly.maliciousInfrastructureListDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <TableContainer sx={{ overflow: 'initial' }}>
              <Table>
                <TableHead>
                  <TableHeaderRow>
                    {maliciousListHeadCells.map((headCell) => (
                      <TableCell key={headCell}>{headCell}</TableCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {maliciousListBodyRows.map((bodyRow, index) => (
                    <TableBodyRow key={bodyRow.no} index={index}>
                      <TableCell>{bodyRow.no}</TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.adversary}
                      </TableCell>
                      <TableCell>{bodyRow.maliciousInfra}</TableCell>
                      <TableCell>{bodyRow.relatedBackdoor}</TableCell>
                      <TableCell>{bodyRow.relatedExploit}</TableCell>
                      <TableCell sx={{ wordBreak: 'break-all' }}>
                        {bodyRow.target}
                      </TableCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Malware Table"
            description={t('monthly.malwareTableDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <TableContainer sx={{ overflow: 'initial' }}>
              <Table>
                <TableHead>
                  <TableHeaderRow>
                    {malwareTableHeadCells.map((headCell) => (
                      <TableCell key={headCell}>{headCell}</TableCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {malwareTableBodyRows.map((bodyRow, index) => (
                    <TableBodyRow key={bodyRow.name} index={index}>
                      <TableCell>{bodyRow.name}</TableCell>
                      <TableCell>{bodyRow.type}</TableCell>
                      <TableCell>{bodyRow.description}</TableCell>
                      <TableCell>{bodyRow.attribution}</TableCell>
                      <TableCell>{bodyRow.firstSeen}</TableCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Sample Analysis"
            description={t('monthly.sampleAnalysisDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <H3Title title="Sample 1" />
            <H3Title title="Meta Info:" />
            <Description>
              <H3Title title="Backdoor" />
              <br />
              ZqPlug
            </Description>
            <Description>
              <H3Title title="Exploit" />
              <br />
              N/A
            </Description>
            <Description>
              <H3Title title="Adversary" />
              <br />
              Naikon
            </Description>
            <H3Title title="Sample Analysis Info:" />
            <H3Title title="Sample 1" />
            <H4Title title="Victim Information" />
            <CodeBlock code={SAMPLE_ANALYSIS_VICTIM_INFORMATION} />
            <H4Title title="Email Header" />
            <CodeBlock code="N/A" />
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Malicious Infrastructure Analysis"
            description={t(
              'monthly.maliciousInfrastructureAnalysisDescription',
              {
                ns: 'reportIntroduction',
              }
            )}
          >
            <H3Title title="Malicious Infrastructure 1" />
            <H3Title title="Meta Info:" />
            <Description>
              <H3Title title="Source IoC" />
              <br />
              <TextLink
                size="large"
                to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=38.54.63.20`}
              >
                38.54.63.20
              </TextLink>
            </Description>
            <Description>
              <H3Title title="Related Backdoor" />
              <br />
              PkgAgent
            </Description>
            <Description>
              <H3Title title="Related Exploit" />
              <br />
              N/A
            </Description>
            <Description>
              <H3Title title="Known Adversary" />
              <br />
              SocialNetworkTeam
            </Description>
            <H3Title title="Victim Information" />
            <CodeBlock
              code={MALICIOUS_INFRASTRUCTURE_ANALYSIS_VICTIM_INFORMATION}
            />
          </ExampleReportBlock>
          <Box id={ScrollableContainerId.BOTTOM_ID} />
        </Stack>
        <TocList tocList={tocList} />
      </Box>
    </>
  )
}
