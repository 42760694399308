import { getPageTitleThreatLandscapeIndustryByName } from 'constants/pageTitle'
import { THREAT_LANDSCAPE_ROUTE } from 'constants/routeParams'
import { useTargetedIndustries } from 'hooks/useTargetedIndustries'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { IndustryGraphic } from './IndustryBuzzWords/IndustryBuzzWords'
import { LandscapePageContainer } from './LandscapePageContainer/LandscapePageContainer'

export const IndustrySubPage = () => {
  const navigate = useNavigate()
  const { target } = useParams()
  const targetedIndustries = useTargetedIndustries()

  const targetIndustry = targetedIndustries.find(
    (industry) => industry.targetName === target
  )

  // redirect to landscape index page if country is not exists
  useEffect(() => {
    if (!targetIndustry) {
      navigate(`/${THREAT_LANDSCAPE_ROUTE}`)
    }
  }, [targetIndustry])

  return targetIndustry ? (
    <>
      <Helmet>
        <title>
          {getPageTitleThreatLandscapeIndustryByName(
            targetIndustry.displayName
          )}
        </title>
      </Helmet>
      <LandscapePageContainer
        target={targetIndustry}
        targetType="industry"
        TargetGraphic={
          <IndustryGraphic buzzwordKeyword={targetIndustry.name || ''} />
        }
      />
    </>
  ) : null
}
