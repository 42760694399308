import { useTranslation } from 'react-i18next'
import { IMalware } from 'store/types/entityTypes/malware'

interface IHeadCell {
  enableFilter: boolean
  id: keyof Pick<
    IMalware,
    'name' | 'aliases' | 'type' | 'attribution' | 'hasTools'
  >
  label: string
  widthRatio: number
}

export const useMalwareTableHeadCells = () => {
  const { t } = useTranslation(['malware'])

  const headCells: readonly IHeadCell[] = [
    {
      id: 'name',
      enableFilter: true,
      label: t('tableHeaders.name', { ns: 'malware' }),
      widthRatio: 4,
    },
    {
      id: 'aliases',
      enableFilter: true,
      label: t('tableHeaders.alias', { ns: 'malware' }),
      widthRatio: 4,
    },
    {
      id: 'type',
      enableFilter: true,
      label: t('tableHeaders.type', { ns: 'malware' }),
      widthRatio: 3,
    },
    {
      id: 'attribution',
      enableFilter: true,
      label: t('tableHeaders.attribution', { ns: 'malware' }),
      widthRatio: 4,
    },
    // 分析師需要整理 Malware Table 的 First Seen，暫時隱藏
    // {
    //   id: 'firstSeen',
    //   enableFilter: false,
    //   label: t('tableHeaders.firstSeen', { ns: 'malware' }),
    //   widthRatio: 2.5,
    // },
    {
      id: 'hasTools',
      enableFilter: false,
      label: t('tableHeaders.tools', { ns: 'malware' }),
      widthRatio: 2,
    },
  ]

  const widthRatioSummary = headCells.reduce(
    (accumulatedWidth, headCell) => accumulatedWidth + headCell.widthRatio,
    0
  )

  return { headCells, widthRatioSummary }
}
