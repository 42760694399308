import { Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const LoadingBlock = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        pt: 5.5,
        pb: 12,
        gap: 5.5,
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
      }}
    >
      <Skeleton type="image" width="6.25rem" height="6.25rem" />
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="title" width="8rem" />
        <Skeleton type="body" width="8rem" />
      </Stack>
    </Stack>
  )
}

export const Loading = () => <>{generateSkeletons(9, <LoadingBlock />)}</>
