import { useTranslation } from 'react-i18next'
import { IAccountOnDemandReports } from 'store/types/entityTypes/account'

interface IHeadCell {
  enableFilter: boolean
  enableOrder: boolean
  id: keyof IAccountOnDemandReports
  label: string
  widthRatio: number
  minWidth: number
}

export const useRFIListTableHeadCells = () => {
  const { t } = useTranslation(['account'])

  const headCells: readonly IHeadCell[] = [
    {
      id: 'date',
      enableFilter: true,
      enableOrder: true,
      label: t('rfi.rfiList.date', { ns: 'account' }),
      widthRatio: 2,
      minWidth: 120,
    },
    {
      id: 'title',
      enableFilter: true,
      enableOrder: true,
      label: t('rfi.rfiList.title', { ns: 'account' }),
      widthRatio: 8,
      minWidth: 120,
    },
    {
      id: 'requestForAssistanceHour',
      enableFilter: false,
      enableOrder: true,
      label: t('rfi.rfiList.rah', { ns: 'account' }),
      widthRatio: 2,
      minWidth: 120,
    },
  ]

  const widthRatioSummary = headCells.reduce(
    (accumulatedWidth, headCell) => accumulatedWidth + headCell.widthRatio,
    0
  )

  return { headCells, widthRatioSummary }
}
