import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as KeyboardArrowDownIcon } from 'assets/basicIcons/angleDown.svg'
import { ReactComponent as KeyboardArrowUpIcon } from 'assets/basicIcons/angleUp.svg'
import { ReactNode, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

const accordionIndentLevelMap: Record<number, string> = {
  1: '0.5rem',
  2: '2rem',
}

interface INavAccordionProps {
  indentLevel?: number
  borderBottom?: string
  text: string
  defaultExpanded?: boolean
  children: NonNullable<ReactNode>
}
export const NavAccordion = ({
  indentLevel = 1,
  borderBottom = 'none',
  text,
  defaultExpanded = false,
  children,
}: INavAccordionProps) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          pl: accordionIndentLevelMap[indentLevel],
          pr: 2,
          py: 1.5,
          cursor: 'pointer',
          borderBottom,
          '&:hover': {
            bgcolor: theme.colors.BLACK_20,
          },
        }}
        onClick={handleExpanded}
      >
        <Box
          sx={{
            width: '1rem',
            height: '1rem',
            border: `1px solid ${theme.colors.WHITE_60}`,
            borderRadius: 0.5,
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {expanded ? (
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={KeyboardArrowUpIcon}
              inheritViewBox
            />
          ) : (
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={KeyboardArrowDownIcon}
              inheritViewBox
            />
          )}
        </Box>
        <Typography variant="titleSmall" sx={{ color: theme.colors.WHITE }}>
          {text}
        </Typography>
      </Box>
      {expanded && children}
    </Box>
  )
}

const accordionContentIndentLevelMap: Record<number, string> = {
  1: '2rem',
  2: '3.5rem',
}

interface INavAccordionContentProps {
  text: string
  activeKey: string
  indentLevel?: number
  disabled?: boolean
  endTag?: NonNullable<ReactNode>
}

export const NavAccordionContent = ({
  text,
  activeKey,
  indentLevel = 1,
  disabled = false,
  endTag,
}: INavAccordionContentProps) => {
  const theme = useTheme()
  const { pathname } = useLocation()

  return (
    <Link to={disabled ? '#' : `${activeKey}`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: accordionContentIndentLevelMap[indentLevel],
          pr: 2,
          py: 1.5,
          cursor: disabled ? 'default' : 'pointer',
          '&:hover': {
            bgcolor: matchPath({ path: activeKey, end: false }, pathname)
              ? theme.colors.PRIMARY_40
              : theme.colors.BLACK_20,
          },
          bgcolor: matchPath({ path: activeKey, end: false }, pathname)
            ? theme.colors.PRIMARY_60
            : theme.colors.BLACK_95,
        }}
      >
        <Typography
          variant="body"
          sx={{ color: disabled ? theme.colors.DISABLE : theme.colors.WHITE }}
        >
          {text}
        </Typography>
        {endTag && (
          <Box component="span" sx={{ ml: 2 }}>
            {endTag}
          </Box>
        )}
      </Box>
    </Link>
  )
}
