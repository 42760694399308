import { Box, Modal, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as AngleLeftIcon } from 'assets/basicIcons/angleLeft.svg'
import { ReactComponent as AngleRightIcon } from 'assets/basicIcons/angleRight.svg'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { NoData } from 'components/NoData/NoData'
import { PageAccordionSection } from 'components/PageSection/PageSection'
import { useImageUrl } from 'hooks/useImageSrc'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { FC, useEffect, useState } from 'react'
import Slider from 'react-slick'
import {
  fetchSampleSandbox,
  fetchSampleSandboxCancelled,
  selectSampleSandbox,
  selectSampleSandboxLoading,
} from 'store/slices/sampleDetail'

import { AccordionLoading } from '../RelationContent/SubContents/AccordionLoading'
import { ISampleTabContentProps } from '../type'

const NextArrow: FC = ({
  onClick,
  className,
}: {
  onClick: () => void
  className: string
}) => {
  const theme = useTheme()

  return (
    <SvgIcon
      className={className}
      component={AngleRightIcon}
      inheritViewBox
      sx={{
        width: '1.5rem',
        height: '1.5rem',
        right: 2,
        zIndex: 1,
        path: {
          fill: className.includes('slick-disabled')
            ? theme.colors.DISABLE
            : theme.colors.WHITE_60,
        },
        cursor: className.includes('slick-disabled') ? 'default' : 'pointer',
      }}
      onClick={onClick}
    />
  )
}

const PrevArrow: FC = ({
  onClick,
  className,
}: {
  onClick: () => void
  className: string
}) => {
  const theme = useTheme()

  return (
    <SvgIcon
      className={className}
      component={AngleLeftIcon}
      inheritViewBox
      sx={{
        width: '1.5rem',
        height: '1.5rem',
        left: 2,
        zIndex: 1,
        path: {
          fill: className.includes('slick-disabled')
            ? theme.colors.DISABLE
            : theme.colors.WHITE_60,
        },
        cursor: className.includes('slick-disabled') ? 'default' : 'pointer',
      }}
      onClick={onClick}
    />
  )
}

const SandboxImageBlock = ({
  screenshotUrls,
}: {
  screenshotUrls: string[]
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const [initialSlide, setInitialSlide] = useState(0)

  const sliderSettings = {
    infinite: false,
    initialSlide,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const handleOpen = (index: number) => {
    setOpen(true)
    setInitialSlide(index)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {screenshotUrls.map((url: string, index: number) => (
        <Box
          key={url}
          sx={{ flexShrink: 0, cursor: 'pointer' }}
          onClick={() => handleOpen(index)}
        >
          <Image
            src={useImageUrl({ fetchImageUrl: url }) || ''}
            alt="sandbox image"
            width="10rem"
            height="10rem"
            fit="contain"
          />
        </Box>
      ))}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'transparent',
            boxShadow: 24,
            '&:focus-visible': {
              outline: 'none',
            },
            width: '37.5rem',
          }}
        >
          <Slider {...sliderSettings}>
            {screenshotUrls.map((url: string) => (
              <Image
                key={url}
                src={useImageUrl({ fetchImageUrl: url }) || ''}
                alt="sandbox"
                width={600}
                height={430}
                duration={1000}
                fit="fill"
              />
            ))}
          </Slider>
          <SvgIcon
            sx={{
              fontSize: '2rem',
              position: 'absolute',
              right: 0,
              top: 0,
              transform: 'translate(50%, -50%)',
              cursor: 'pointer',
              color: theme.colors.WHITE_60,
              bgcolor: theme.colors.BLACK_85,
              border: `1px solid ${theme.colors.WHITE_20}`,
              borderRadius: '50%',
            }}
            component={CloseIcon}
            inheritViewBox
            onClick={handleClose}
          />
        </Box>
      </Modal>
    </>
  )
}

export const SampleSandboxContent = ({
  searchText,
}: ISampleTabContentProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const sampleSandboxLoading = useAppSelector(selectSampleSandboxLoading)
  const sampleSandbox = useAppSelector(selectSampleSandbox)

  useEffect(() => {
    if (searchText) {
      dispatch(fetchSampleSandbox(searchText))
    }

    return () => {
      dispatch(fetchSampleSandboxCancelled())
    }
  }, [searchText])

  if (sampleSandboxLoading) {
    return <AccordionLoading />
  }

  if (
    sampleSandbox.screenshotUrls.length +
      sampleSandbox.registry.length +
      sampleSandbox.mutex.length ===
    0
  ) {
    return <NoData />
  }

  return (
    <Stack sx={{ mt: 2, gap: 1 }}>
      {sampleSandbox.screenshotUrls.length > 0 && (
        <PageAccordionSection defaultExpanded title="Preview">
          <Box
            sx={{
              px: 4,
              py: 2,
              display: 'flex',
              overflowX: 'auto',
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            <SandboxImageBlock screenshotUrls={sampleSandbox.screenshotUrls} />
          </Box>
        </PageAccordionSection>
      )}
      {sampleSandbox.registry.length > 0 && (
        <PageAccordionSection defaultExpanded title="Registry">
          <Stack
            sx={{
              px: 4,
              py: 2,
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            {sampleSandbox.registry.map((reg) => (
              <Typography key={reg.value} variant="bodySmall">
                {reg.value}
              </Typography>
            ))}
          </Stack>
        </PageAccordionSection>
      )}
      {sampleSandbox.mutex.length > 0 && (
        <PageAccordionSection defaultExpanded title="mutex">
          <Stack
            sx={{
              px: 4,
              py: 2,
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            {sampleSandbox.mutex.map((mut) => (
              <Typography key={mut.value} variant="bodySmall">
                {mut.value}
              </Typography>
            ))}
          </Stack>
        </PageAccordionSection>
      )}
    </Stack>
  )
}
