import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IMalware } from 'store/types/entityTypes/malware'
import { ITool } from 'store/types/entityTypes/tool'
import {
  IFetchMalwarePayload,
  IFetchMalwaresPayload,
  IMalwareSlice,
} from 'store/types/slicesTypes/malware'

const initialState: IMalwareSlice = {
  malwares: [],
  isFetchMalwaresLoading: false,
  hasMoreMalwares: true,
  malware: null,
  isFetchMalwareLoading: false,
  tools: [],
  isFetchToolsLoading: true,
}

const malwareSlice = createSlice({
  name: 'malware',
  initialState,
  reducers: {
    fetchMalwares(state, _action: PayloadAction<IFetchMalwaresPayload>) {
      state.isFetchMalwaresLoading = true
    },
    fetchMalwaresFulfilled(state, action: PayloadAction<IMalware[]>) {
      if (action.payload.length === 0) {
        state.hasMoreMalwares = false
      } else {
        state.malwares = [...state.malwares, ...action.payload]
      }
      state.isFetchMalwaresLoading = false
    },
    fetchMalwaresRejected(state, _action: PayloadAction<AjaxError>) {
      state.malwares = initialState.malwares
      state.isFetchMalwaresLoading = false
    },
    fetchMalwaresCancelled(state) {
      state.isFetchMalwaresLoading = false
    },
    resetMalwares(state) {
      state.malwares = initialState.malwares
      state.hasMoreMalwares = initialState.hasMoreMalwares
    },
    fetchMalware(state, _action: PayloadAction<IFetchMalwarePayload>) {
      state.isFetchMalwareLoading = true
    },
    fetchMalwareFulfilled(state, action: PayloadAction<IMalware>) {
      state.malware = action.payload
      state.isFetchMalwareLoading = false
    },
    fetchMalwareRejected(state, _action: PayloadAction<AjaxError>) {
      state.malware = initialState.malware
      state.isFetchMalwareLoading = false
    },
    fetchMalwareCancelled(state) {
      state.isFetchMalwareLoading = false
    },
    fetchMalwareRelatedTools(state, _action: PayloadAction<string>) {
      state.isFetchToolsLoading = true
    },
    fetchMalwareRelatedToolsFulfilled(state, action: PayloadAction<ITool[]>) {
      state.tools = action.payload
      state.isFetchToolsLoading = false
    },
    fetchMalwareRelatedToolsRejected(state, _action: PayloadAction<AjaxError>) {
      state.tools = initialState.tools
      state.isFetchToolsLoading = false
    },
    fetchMalwareRelatedToolsCancelled(state) {
      state.isFetchToolsLoading = false
    },
    updateRelatedTools(state, action: PayloadAction<number>) {
      state.tools = state.tools.map((tool) =>
        tool.id === action.payload
          ? {
              ...tool,
              purchasedInformation: null, // 購買成功後把 purchase info 清空
            }
          : tool
      )
    },
  },
})

export const {
  fetchMalwares,
  fetchMalwaresFulfilled,
  fetchMalwaresRejected,
  fetchMalwaresCancelled,
  fetchMalware,
  fetchMalwareFulfilled,
  fetchMalwareRejected,
  fetchMalwareCancelled,
  resetMalwares,
  fetchMalwareRelatedTools,
  fetchMalwareRelatedToolsFulfilled,
  fetchMalwareRelatedToolsRejected,
  fetchMalwareRelatedToolsCancelled,
  updateRelatedTools,
} = malwareSlice.actions

export const selectMalwaresLoading = (state: TRootState) =>
  state.malware.isFetchMalwaresLoading

export const selectMalwares = (state: TRootState) => state.malware.malwares

export const selectHasMoreMalwares = (state: TRootState) =>
  state.malware.hasMoreMalwares

export const selectMalware = (state: TRootState) => state.malware.malware

export const selectMalwareLoading = (state: TRootState) =>
  state.malware.isFetchMalwareLoading

export const selectMalwareToolsLoading = (state: TRootState) =>
  state.malware.isFetchToolsLoading

export const selectMalwareTools = (state: TRootState) => state.malware.tools

export default malwareSlice.reducer
