import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { Button } from 'components/Button/Button'
import { TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'

import { GroupDrawer } from './GroupDrawer'
import { GroupLoading } from './GroupLoading'

export const Group = ({ mainGroup }: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const { formatDuration } = useDateTime()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const toggleDrawer = () => {
    setDrawerOpen((open) => !open)
  }

  return (
    <PageSection title={t('group.title', { ns: 'account' })}>
      <Box>
        {accountSettingsLoading ? (
          <GroupLoading />
        ) : mainGroup ? (
          <Box sx={{ display: 'flex', gap: 1, px: 4, py: 2 }}>
            <TwoToneLabel
              keyText={mainGroup.name}
              valueText={formatDuration(
                mainGroup.joinedAt,
                mainGroup.expiresAt
              )}
              mainColor={theme.colors.WHITE_60}
              textColor={theme.colors.BLACK_90}
            />
            <Button
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
              onClick={toggleDrawer}
            >
              {t('group.checkGroupMembersCta', { ns: 'account' })}
            </Button>
          </Box>
        ) : (
          <Box sx={{ px: 4, py: 2 }}>
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('group.emptyState', { ns: 'account' })}
            </Typography>
          </Box>
        )}

        <GroupDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          groupId={mainGroup?.id}
          groupName={mainGroup?.name}
        />
      </Box>
    </PageSection>
  )
}
