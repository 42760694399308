import { Stack, Typography, useTheme } from '@mui/material'
import QQTVImage from 'assets/errorImages/qq-tv.svg'
import { PAGE_TITLE_WEBSITE_MAINTENANCE } from 'constants/pageTitle'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'

export const WebsiteMaintenancePage = () => {
  const theme = useTheme()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_WEBSITE_MAINTENANCE}</title>
      </Helmet>
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.colors.BLACK_85,
          gap: 2,
        }}
      >
        <Typography variant="titleBig" sx={{ color: theme.colors.WHITE }}>
          Website maintenance
        </Typography>
        <Image
          src={QQTVImage}
          alt="Website maintenance"
          width={328}
          height={130}
        />
      </Stack>
    </>
  )
}
