import { Stack, Typography, useTheme } from '@mui/material'
import { TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { TReportSubscriberGroupsKey } from 'store/types/entityTypes/tlpTarget'

import { SubscriptionReportLoading } from './SubscriptionReportLoading'

export const SubscriptionReport = ({
  reportSubscriberGroups,
}: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const { formatDuration } = useDateTime()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const reportSubscriberGroupDisplay: Record<
    TReportSubscriberGroupsKey,
    string
  > = {
    'flash_report_subscribers': t('subscribedReports.flashReports', {
      ns: 'account',
    }),
    'monthly_report_subscribers': t('subscribedReports.monthlyReports', {
      ns: 'account',
    }),
    'advanced_report_subscribers': t('subscribedReports.ctReports', {
      ns: 'account',
    }),
    'bi_weekly_report_subscribers': t('subscribedReports.caReports', {
      ns: 'account',
    }),
    'vulnerability_insights_report_subscribers': t(
      'subscribedReports.viReports',
      {
        ns: 'account',
      }
    ),
    'advisory_report_subscribers': t('subscribedReports.pmReports', {
      ns: 'account',
    }),
  }

  return (
    <PageSection title={t('subscribedReports.title', { ns: 'account' })}>
      {accountSettingsLoading ? (
        <SubscriptionReportLoading />
      ) : (
        <Stack sx={{ gap: 1, px: 4, py: 2 }}>
          {reportSubscriberGroups?.length === 0 ? (
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('subscribedReports.emptyState', { ns: 'account' })}
            </Typography>
          ) : (
            reportSubscriberGroups &&
            reportSubscriberGroups.map((reportSubscriberGroup) => (
              <TwoToneLabel
                key={reportSubscriberGroup.name}
                keyText={
                  reportSubscriberGroupDisplay[reportSubscriberGroup.key]
                }
                valueText={formatDuration(
                  reportSubscriberGroup.joinedAt,
                  reportSubscriberGroup.expiresAt
                )}
                mainColor={theme.colors.WEBSHELL}
                textColor={theme.colors.BLACK_90}
              />
            ))
          )}
        </Stack>
      )}
    </PageSection>
  )
}
