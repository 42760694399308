import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { Description } from 'components/Documents/Description'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { DateLabel } from 'components/Label/Label'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import {
  DocumentContentWidth,
  DocumentTimestamp,
  ScrollableContainerId,
} from 'constants/document'
import { useDateTime } from 'hooks/useDatetime'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

interface IBodyRow {
  researchArea: string
  reportName: string
  publicationFrequency: string
  reportNumberInAYear: string
  reportLanguage: string
  ioc: string
}

export const ReportIntroductionPrefacePage = () => {
  const { t } = useTranslation(['reportIntroduction'])
  const headCells: string[] = t('preface.tableHeadCells', {
    ns: 'reportIntroduction',
    returnObjects: true,
    defaultValue: [],
  })
  const bodyRows: IBodyRow[] = t('preface.tableBodyRows', {
    ns: 'reportIntroduction',
    returnObjects: true,
    defaultValue: [],
  })
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{`${t('preface.title', {
          ns: 'reportIntroduction',
        })} - Report Introduction - ThreatVision`}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Stack
          id={ScrollableContainerId.TOP_ID}
          sx={{
            width: DocumentContentWidth.WIDTH,
            maxWidth: DocumentContentWidth.MAX_WIDTH,
            minWidth: DocumentContentWidth.MIN_WIDTH,
            px: 10,
            py: 4,
            gap: 2,
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <H2ColorTitle
            title={t('preface.title', { ns: 'reportIntroduction' })}
          />
          <DateLabel
            text={getDateStringByTimestampsInSeconds(
              DocumentTimestamp.REPORT_INTRODUCTION_TIMESTAMP
            )}
          />
          <H2Title
            title={t('preface.subTitle', { ns: 'reportIntroduction' })}
          />
          <Description>
            {t('preface.description.paragraphOne', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <Description>
            <Trans t={t} i18nKey="preface.description.paragraphTwo">
              We name our threat intelligence portal, ThreatVision, because
              every ThreatVision user will have a clear vision of these threat
              actors. We have listed all the threat intelligence reports in this
              page. Currently, there are six standardized and regularly updated
              threat intelligence reports. We have three reports about APT (
              <InlineBlock text="Flash Report" />,
              <InlineBlock text="Monthly Report" />, and
              <InlineBlock text="Campaign Tracking Report" />
              ). Two reports are about highly dangerous vulnerabilities (
              <InlineBlock text="Patch Management Report" />
              , and
              <InlineBlock text="Vulnerability Insights Report" />
              ). Last but not least, we have
              <InlineBlock text="Cyber Affairs Report" />, which aims to update
              users on cyber policies and important security incidents that
              happened in the Chinese-speaking world, such as China, Taiwan, and
              Hong Kong.
            </Trans>
          </Description>
          <TableContainer sx={{ overflow: 'initial' }}>
            <Table>
              <TableHead>
                <TableHeaderRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell}>{headCell}</TableCell>
                  ))}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {bodyRows.map((bodyRow, index) => (
                  <TableBodyRow key={bodyRow.reportName} index={index}>
                    <TableCell>{bodyRow.researchArea}</TableCell>
                    <TableCell>{bodyRow.reportName}</TableCell>
                    <TableCell>{bodyRow.publicationFrequency}</TableCell>
                    <TableCell>{bodyRow.reportNumberInAYear}</TableCell>
                    <TableCell>{bodyRow.reportLanguage}</TableCell>
                    <TableCell>{bodyRow.ioc}</TableCell>
                  </TableBodyRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box id={ScrollableContainerId.BOTTOM_ID} />
        </Stack>
        <TocList />
      </Box>
    </>
  )
}
