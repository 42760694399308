import { Box, Stack, Typography, useTheme } from '@mui/material'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { TechniquePreview } from 'components/Technique/TechniquePreview'
import { getPageTitleTechniqueDetailByTitle } from 'constants/pageTitle'
import {
  CAPABILITY_ROUTE,
  NOT_FOUND_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchTechnique,
  fetchTechniqueCancelled,
  resetTechnique,
  selectTechnique,
  selectTechniqueLoading,
} from 'store/slices/capability'

import { useTechniqueCategoryTabs } from './config'
import { TechniqueCategoryTabs } from './TechniqueCategoryTabs'
import { TechniqueLoading } from './TechniqueLoading'

export const TechniquePage = () => {
  const { t } = useTranslation(['capability'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const technique = useAppSelector(selectTechnique)
  const techniqueLoading = useAppSelector(selectTechniqueLoading)
  const techniqueTabs = useTechniqueCategoryTabs()
  const navigate = useNavigate()
  useGoBack()

  const techniqueId = searchParams.get(SearchParamKey.CAPABILITY_TECHNIQUE_ID)

  const defaultCategoryTab = techniqueTabs[0]
  const categoryTabParam = searchParams.get(
    SearchParamKey.CAPABILITY_TECHNIQUE_TAB
  )
  const categoryTab =
    (categoryTabParam &&
      techniqueTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const BottomContent = categoryTab.component

  useEffect(() => {
    // 判斷如果 techniqueId 跟 technique.id 是不同家族，需要重新抓值
    if (techniqueId) {
      const parentTechniqueId = techniqueId.includes('.')
        ? techniqueId.substring(0, techniqueId.indexOf('.'))
        : techniqueId
      if (technique.id !== parentTechniqueId) {
        dispatch(fetchTechnique(techniqueId))
      }
    }

    return () => {
      dispatch(fetchTechniqueCancelled())
    }
  }, [technique.id, techniqueId])

  useEffect(
    () => () => {
      dispatch(resetTechnique())
    },
    []
  )

  const techniqueInfo =
    technique.subTechniques.find(({ id }) => id === techniqueId) || technique

  const techniqueName = techniqueInfo.parent.name
    ? `${techniqueInfo.parent.name}: ${techniqueInfo.name}`
    : `${techniqueInfo.name}`

  if (!techniqueId) {
    navigate(`/${NOT_FOUND_ROUTE}`, { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitleTechniqueDetailByTitle(techniqueName)}</title>
      </Helmet>
      <Box
        sx={{
          p: 1,
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            borderRadius: 0.5,
            border: `1px solid ${theme.colors.WHITE_20}`,
          }}
        >
          <Box
            sx={{
              width: '17.5rem',
              flexShrink: 0,
              borderRight: `1px solid ${theme.colors.WHITE_20}`,
              height: '100%',
              overflow: 'auto',
              bgcolor: theme.colors.BLACK_90,
            }}
          >
            {!techniqueLoading && (
              <Stack
                sx={{
                  width: '100%',
                  color: theme.colors.WHITE,
                }}
              >
                <Typography
                  sx={{
                    px: 4,
                    py: 1,
                    borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                  }}
                  variant="title"
                >
                  {t('technique.title', { ns: 'capability' })}
                </Typography>
                <Typography
                  sx={{
                    color: theme.colors.WHITE,
                    cursor: 'pointer',
                    px: 4,
                    py: 1.5,
                    '&:hover': {
                      bgcolor:
                        technique.id === techniqueId
                          ? theme.colors.PRIMARY_40
                          : theme.colors.WHITE_20,
                    },
                    bgcolor:
                      technique.id === techniqueId
                        ? theme.colors.PRIMARY_60
                        : 'inherit',
                  }}
                  variant="titleSmall"
                  to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${technique.id}`}
                  component={Link}
                >
                  {technique.name}
                </Typography>
                {technique.subTechniques.map(({ id, name }) => (
                  <Typography
                    sx={{
                      color: theme.colors.WHITE,
                      cursor: 'pointer',
                      pl: 8,
                      pr: 2,
                      py: 1.5,
                      '&:hover': {
                        bgcolor:
                          id === techniqueId
                            ? theme.colors.PRIMARY_40
                            : theme.colors.WHITE_20,
                      },
                      bgcolor:
                        id === techniqueId
                          ? theme.colors.PRIMARY_60
                          : 'inherit',
                    }}
                    key={id}
                    variant="body"
                    to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${id}`}
                    component={Link}
                  >
                    {name}
                  </Typography>
                ))}
              </Stack>
            )}
          </Box>
          <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
            <Stack sx={{ width: '100%', height: '100%' }}>
              {techniqueLoading ? (
                <TechniqueLoading />
              ) : (
                <Stack sx={{ gap: 2 }}>
                  <Typography
                    sx={{
                      mx: 4,
                      pt: 4,
                      color: theme.colors.WHITE,
                    }}
                    variant="titleBig"
                  >
                    {techniqueName}
                  </Typography>
                  <Box>
                    <TechniquePreview
                      showDescriptionMoreLink
                      maxLine={1}
                      {...techniqueInfo}
                    />
                  </Box>
                </Stack>
              )}
              <Stack sx={{ px: 1, flexGrow: 1 }}>
                <PageTabsContent
                  tabs={
                    <TechniqueCategoryTabs
                      techniqueId={techniqueInfo.id}
                      categoryTab={categoryTab}
                    />
                  }
                  content={
                    <Box sx={{ px: 1, py: 2 }}>
                      <BottomContent searchText={techniqueInfo.id} />
                    </Box>
                  }
                  maxWidth="100%"
                />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  )
}
