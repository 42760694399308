import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { useGoBack } from 'hooks/useGoBack'
import type { TTargetType } from 'pages/ThreatLandscapePage/TargetBlock'
import { ReactElement } from 'react'
import Highlighter from 'react-highlight-words'

import { RelatedAdversaries } from '../RelatedAdversary/RelatedAdversaries'
import RelatedReports from '../RelatedReports/RelatedReports'

interface ILandscapePageContainerProps {
  target: {
    desc: string
    logo?: ReactElement
    name: string
    displayName: string
    targetName: string
    backgroundImageSrc: string
  }
  TargetGraphic: ReactElement
  targetType: TTargetType
}

export const LandscapePageContainer = ({
  target,
  TargetGraphic,
  targetType,
}: ILandscapePageContainerProps) => {
  useGoBack()
  const theme = useTheme()
  const { displayName, name } = target
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        display: 'flex',
        gap: 1,
        p: 1,
      }}
    >
      <Stack
        sx={{
          width: '28.5rem',
          [theme.breakpoints.up('desktop')]: {
            width: '32rem',
          },
          [theme.breakpoints.up('xl')]: {
            width: '44.5rem',
          },
          maxHeight: '63.5rem',
          overflow: 'auto',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: '2px 2px 0 0 ',
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${target.backgroundImageSrc});`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexBasis: 1,
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              pl: 4,
              mb: 1,
              display: 'flex',
              gap: 3,
              alignItems: 'center',
            }}
          >
            {target.logo && <Box>{target.logo}</Box>}
            <Box>
              <Typography variant="title" sx={{ color: theme.colors.WHITE }}>
                {target.displayName}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
          <Box sx={{ pl: 4, mt: 2 }}>
            <Typography variant="bodySmall" sx={{ color: theme.colors.WHITE }}>
              <Highlighter
                textToHighlight={target.desc}
                searchWords={[displayName]}
                caseSensitive={false}
                highlightStyle={{
                  color: theme.colors.HIGH_LIGHT,
                  backgroundColor: 'inherit',
                }}
              />
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', minHeight: '29.875rem' }}>
          {TargetGraphic}
        </Box>
      </Stack>
      <Stack
        spacing={1}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          width: '40rem',
          minWidth: '40rem',
          maxWidth: '72.75rem',
          overflow: 'auto',
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <RelatedReports
            targetType={targetType}
            displayName={displayName}
            name={name}
          />
        </Box>
        <Box sx={{ height: '11rem', flexShrink: 0 }}>
          <RelatedAdversaries targetType={targetType} name={name} />
        </Box>
      </Stack>
    </Box>
  )
}
