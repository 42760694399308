import { Box } from '@mui/material'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useSearchParams } from 'react-router-dom'

import { ISampleCategoryTab } from './config'

interface ISampleCategoryTabsProps {
  sampleCategoryTabs: ISampleCategoryTab[]
  categoryTab: ISampleCategoryTab
}

export const SampleCategoryTabs = ({
  sampleCategoryTabs,
  categoryTab,
}: ISampleCategoryTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSelect = (key: string) => {
    searchParams.set(SearchParamKey.SAMPLE_TAB, key)
    setSearchParams(searchParams)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, py: 1.5 }}>
      {sampleCategoryTabs.map((tab) => (
        <TabWithCount
          key={tab.key}
          name={tab.name}
          handleSelect={() => handleSelect(tab.key)}
          count={tab.count}
          showCount={tab.showCount}
          selected={categoryTab.key === tab.key}
        />
      ))}
    </Box>
  )
}
