import { Box, Stack, useTheme } from '@mui/material'
import { AdversaryInfo } from 'components/Adversary/AdversaryInfo'
import { AdversaryInfoLoading } from 'components/Adversary/AdversaryInfoLoading'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { getPageTitleAdversaryDetailByName } from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchAdversaryById,
  fetchAdversaryByIdCancelled,
  selectAdversaryInfo,
  selectFetchAdversaryByIdLoading,
} from 'store/slices/adversary'

import { AdversaryCategoryTabs } from './AdversaryCategoryTabs'
import { useAdversaryCategoryTabs } from './config'

export const AdversaryInfoPage = () => {
  useGoBack()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const adversaryId = searchParams.get(SearchParamKey.ADVERSARY_ID)
  const adversary = useAppSelector(selectAdversaryInfo)
  const isAdversaryLoading = useAppSelector(selectFetchAdversaryByIdLoading)

  // 已經抓完資料但還是沒有對應的 adversary，導到 404 page
  useEffect(() => {
    if (!isAdversaryLoading && !adversary) {
      navigate('/404', { replace: true })
    }
  }, [isAdversaryLoading])

  // 依照 adversaryId 來更新 adversary info
  useEffect(() => {
    if (adversaryId) {
      dispatch(fetchAdversaryById(adversaryId))
    }
    return () => {
      dispatch(fetchAdversaryByIdCancelled())
    }
  }, [adversaryId])

  const adversaryCategoryTabs = useAdversaryCategoryTabs()
  const defaultCategoryTab = adversaryCategoryTabs[0]

  const categoryTabParam = searchParams.get(SearchParamKey.ADVERSARY_TAB)
  const categoryTab =
    (categoryTabParam &&
      adversaryCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const BottomContent = categoryTab.component

  return (
    adversary && (
      <>
        <Helmet>
          <title>{getPageTitleAdversaryDetailByName(adversary.name)}</title>
        </Helmet>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            [theme.breakpoints.up('desktop')]: {
              justifyContent: 'center',
            },
          }}
        >
          <Stack
            sx={{
              p: 1,
              gap: 1,
              height: '100%',
              width: '78rem',
            }}
          >
            <Box sx={{ flexShrink: 0 }}>
              {isAdversaryLoading ? (
                <AdversaryInfoLoading />
              ) : (
                <AdversaryInfo {...adversary} />
              )}
            </Box>
            <PageTabsContent
              tabs={
                <AdversaryCategoryTabs
                  adversaryId={adversary.id}
                  categoryTab={categoryTab}
                />
              }
              content={<BottomContent searchText={adversary.id} />}
            />
          </Stack>
        </Box>
      </>
    )
  )
}
