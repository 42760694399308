import type { Action } from '@reduxjs/toolkit'
import { purchaseItemByPriceTagId } from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs'
import type { TAppEpic } from 'store'
import {
  purchaseItem,
  purchaseItemFulfilled,
  purchaseItemRejected,
} from 'store/slices/purchase'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const purchaseItemEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(purchaseItem.match),
    switchMap((action) =>
      fetchData({
        method: 'POST',
        url: purchaseItemByPriceTagId(action.payload.priceTagId),
      }).pipe(
        map(() => purchaseItemFulfilled()),
        tap(() => action.payload.successCallback()),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(purchaseItemRejected(error)).pipe(
              tap(() => action.payload.failCallback())
            )
          )
        )
      )
    )
  )

export const purchaseEpic = combineEpics(purchaseItemEpic)
