import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { NewLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { useTargetedCountries } from 'hooks/useTargetedCountries'
import { useTargetedIndustries } from 'hooks/useTargetedIndustries'
import { useTargetedRegions } from 'hooks/useTargetedRegions'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export type TTargetType = 'country' | 'industry' | 'region'

interface ITargetItemProps {
  logo?: ReactElement
  displayName: string
  targetName: string
  targetType: TTargetType
  isNew?: boolean
}

export const TargetItem = ({
  logo,
  targetName, // target key
  displayName, // target display name
  targetType,
  isNew = false,
}: ITargetItemProps) => {
  const theme = useTheme()
  const targetTo = `${targetType}/${targetName}`

  return (
    <Box
      sx={[
        {
          px: 4,
          py: 3,
          height: '4rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.colors.BLACK_60,
          },
          '&:active': {
            bgcolor: theme.colors.BLACK_95,
          },
        },
      ]}
      to={targetTo}
      component={Link}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        {logo && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>{logo}</Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant="titleSmall"
            className="displayName"
            sx={{ color: theme.colors.WHITE }}
          >
            {displayName}
          </Typography>
          {isNew && <NewLabel text="New" />}
        </Box>
      </Box>
      <SvgIcon component={ArrowForwardIcon} inheritViewBox />
    </Box>
  )
}

interface ITargetBlockProps {
  title: string
  targetType: TTargetType
}

export const TargetBlock = ({ title, targetType }: ITargetBlockProps) => {
  const targetedRegions = useTargetedRegions()
  const targetedCountries = useTargetedCountries()
  const targetedIndustries = useTargetedIndustries()

  return (
    <PageSection title={title} sx={{ width: '100%' }}>
      <Stack
        sx={{
          p: 2,
          gap: 1,
          flexGrow: 1,
          flexBasis: 0,
          overflow: 'auto',
          height: '100%',
        }}
      >
        {targetType === 'region' &&
          targetedRegions.map((region) => (
            <Box key={region.targetName}>
              <TargetItem
                targetType="region"
                displayName={region.displayName}
                targetName={region.targetName}
              />
            </Box>
          ))}
        {targetType === 'country' &&
          targetedCountries.map((country, index) => (
            <Box key={country.targetName}>
              <TargetItem
                targetType="country"
                logo={country.logo}
                displayName={country.displayName}
                targetName={country.targetName}
                isNew={index + 1 === targetedCountries.length}
              />
            </Box>
          ))}
        {targetType === 'industry' &&
          targetedIndustries.map((industry, index) => (
            <Box key={industry.targetName}>
              <TargetItem
                targetType="industry"
                logo={industry.logo}
                displayName={industry.displayName}
                targetName={industry.targetName}
                isNew={index + 1 === targetedIndustries.length}
              />
            </Box>
          ))}
      </Stack>
    </PageSection>
  )
}
