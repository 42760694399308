import { TRangeOption } from 'hooks/useDatetime'
import { useTranslation } from 'react-i18next'

interface IRangeOptions {
  text: string
  value: TRangeOption
}

export const useDateRangeOptions = () => {
  const { t } = useTranslation(['component'])
  const rangeOptions: IRangeOptions[] = [
    { text: t('timeSelector.allOption', { ns: 'component' }), value: 'all' },
    {
      text: t('timeSelector.last24HrsOption', { ns: 'component' }),
      value: '24h',
    },
    {
      text: t('timeSelector.last7DaysOption', { ns: 'component' }),
      value: '7d',
    },
    {
      text: t('timeSelector.last30DaysOption', { ns: 'component' }),
      value: '30d',
    },
    {
      text: t('timeSelector.customDateRange', { ns: 'component' }),
      value: 'custom',
    },
  ]

  return rangeOptions
}
