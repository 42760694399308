import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IIoc } from 'store/types/entityTypes/ioc'
import { ITool } from 'store/types/entityTypes/tool'
import {
  IDownloadSlice,
  IFetchIocsPayload,
  IFetchToolsPayload,
} from 'store/types/slicesTypes/download'

const initialState: IDownloadSlice = {
  isIocsLoading: false,
  hasMoreIocs: true,
  iocs: [],
  isToolsLoading: false,
  tools: [],
  downloadingCount: 0,
  downloadingIdList: [],
  iocDownloadingCount: 0,
  iocDownloadingIdList: [],
}

const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    fetchDownloadIocs(state, _action: PayloadAction<IFetchIocsPayload>) {
      state.isIocsLoading = true
    },
    fetchDownloadIocsFulfilled(state, action: PayloadAction<IIoc[]>) {
      if (action.payload.length === 0) {
        state.hasMoreIocs = false
      } else {
        state.iocs = [...state.iocs, ...action.payload]
      }
      state.isIocsLoading = false
    },
    fetchDownloadIocsRejected(state, _action: PayloadAction<AjaxError>) {
      state.iocs = initialState.iocs
      state.isIocsLoading = false
    },
    fetchDownloadIocsCancelled(state) {
      state.isIocsLoading = false
    },
    resetDownloadIocs(state) {
      state.hasMoreIocs = true
      state.iocs = initialState.iocs
    },
    fetchDownloadTools(state, _action: PayloadAction<IFetchToolsPayload>) {
      state.isToolsLoading = true
    },
    fetchDownloadToolsFulfilled(state, action: PayloadAction<ITool[]>) {
      state.tools = action.payload
      state.isToolsLoading = false
    },
    fetchDownloadToolsRejected(state, _action: PayloadAction<AjaxError>) {
      state.tools = initialState.tools
      state.isToolsLoading = false
    },
    fetchDownloadToolsCancelled(state) {
      state.isToolsLoading = false
    },
    resetDownloadTools(state) {
      state.tools = initialState.tools
    },
    downloadToolItem(state, action: PayloadAction<number>) {
      state.downloadingCount += 1
      state.downloadingIdList = [...state.downloadingIdList, action.payload]
    },
    downloadToolItemFulfilled(state, action: PayloadAction<number>) {
      state.tools = state.tools.map((tool) =>
        tool.id === action.payload
          ? {
              ...tool,
              purchasedInformation: null, // 購買成功後把 purchase info 清空
            }
          : tool
      )
      state.downloadingCount -= 1
      state.downloadingIdList = state.downloadingIdList.filter(
        (id) => id !== action.payload
      )
    },
    downloadToolItemRejected(state) {
      state.downloadingCount -= 1
      state.downloadingIdList = initialState.downloadingIdList
    },
    downloadIocItem(state, action: PayloadAction<string>) {
      state.iocDownloadingCount += 1
      state.iocDownloadingIdList = [
        ...state.iocDownloadingIdList,
        action.payload,
      ]
    },
    downloadIocItemFulfilled(state, action: PayloadAction<string>) {
      state.iocDownloadingCount -= 1
      state.iocDownloadingIdList = state.iocDownloadingIdList.filter(
        (id) => id !== action.payload
      )
    },
    downloadIocItemRejected(state) {
      state.iocDownloadingCount -= 1
      state.iocDownloadingIdList = initialState.iocDownloadingIdList
    },
  },
})

export const {
  fetchDownloadIocs,
  fetchDownloadIocsFulfilled,
  fetchDownloadIocsRejected,
  fetchDownloadIocsCancelled,
  resetDownloadIocs,
  fetchDownloadTools,
  fetchDownloadToolsFulfilled,
  fetchDownloadToolsRejected,
  fetchDownloadToolsCancelled,
  resetDownloadTools,
  downloadToolItem,
  downloadToolItemFulfilled,
  downloadToolItemRejected,
  downloadIocItem,
  downloadIocItemFulfilled,
  downloadIocItemRejected,
} = downloadSlice.actions

export const selectDownloadIocsLoading = (state: TRootState) =>
  state.download.isIocsLoading
export const selectDownloadIocs = (state: TRootState) => state.download.iocs
export const selectHasMoreIocs = (state: TRootState) =>
  state.download.hasMoreIocs
export const selectDownloadToolsLoading = (state: TRootState) =>
  state.download.isToolsLoading
export const selectDownloadTools = (state: TRootState) => state.download.tools
export const selectToolDownloading = (state: TRootState) =>
  state.download.downloadingCount > 0
export const selectToolDownloadingIdList = (state: TRootState) =>
  state.download.downloadingIdList
export const selectIocDownloading = (state: TRootState) =>
  state.download.iocDownloadingCount > 0
export const selectIocDownloadingIdList = (state: TRootState) =>
  state.download.iocDownloadingIdList

export default downloadSlice.reducer
