import CTRBackgroundImage from 'assets/report/CTR-background.jpg'
import flashBackgroundImage from 'assets/report/flash-background.jpg'
import monthlyBackgroundImage from 'assets/report/monthly-background.jpg'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TAPTReportType } from 'store/types/entityTypes/report'

export interface IAPTReportContent {
  name: string
  description: NonNullable<ReactNode>
  buttonText: string
  backgroundImageSrc: string
  type: TAPTReportType
}

export const useAptReportContent = () => {
  const { t } = useTranslation(['report'])

  const aptContent: IAPTReportContent[] = [
    {
      name: t('flashReports.title', { ns: 'report' }),
      description: (
        <Trans t={t} i18nKey="flashReports.description">
          APT in Asia Flash (aka Flash Report) provides timely, accurate, and
          actionable intelligence. Users can use Flash Report to have
          just-in-Time alerts of the latest APT intrusions.
          <br />
          Flash Report is published twice a week. Users will have at least 100
          Flash Reports a year. Each Flash Report details one specific targeted
          attack and provides all essential Indicators of Compromise (IoCs).
        </Trans>
      ),
      buttonText: t('flashReports.moreCta', { ns: 'report' }),
      backgroundImageSrc: flashBackgroundImage,
      type: 'flashReport',
    },
    {
      name: t('monthlyReports.title', { ns: 'report' }),
      description: (
        <Trans t={t} i18nKey="monthlyReports.description">
          APT in Asia Monthly (aka Monthly Report) provides strategic and
          actionable intelligence in the Asia Pacific region. Monthly Report
          will connect the dots between cyberattacks and recent political
          events, policies, and foreign affairs. Users can use Monthly Report to
          understand the latest trends and the current threat landscape.
          <br />
          Monthly Report is published every month. Users will have 12 Monthly
          Reports a year. Each Monthly Report summarizes 13 – 16 APT attack
          cases in the Asia Pacific region over the past month.
        </Trans>
      ),
      buttonText: t('monthlyReports.moreCta', { ns: 'report' }),
      backgroundImageSrc: monthlyBackgroundImage,
      type: 'monthlyReport',
    },
    {
      name: t('ctReports.title', { ns: 'report' }),
      description: (
        <Trans t={t} i18nKey="ctReports.description">
          APT Campaign Tracking Report (aka CTR) provides TeamT5’s comprehensive
          understanding of significant threat groups/campaigns. Users can use
          CTR to enhance their knowledge of trends and challenges in the APAC
          region with a broader perspective.
          <br />
          We will publish two CTR at the end of every quarter. Users will have 8
          CTR a year. Each CTR provides an in-depth analysis on a particular
          threat group/campaign, covering its TTPs, target scope, etc., over the
          past 2-3 years. Moreover, at the end of Q2 and Q4, CTR will provide an
          APT Threat Landscape report covering major incidents, popular TTPs,
          most targeted industries, and top malwares/APTs over the past two
          quarters.
        </Trans>
      ),
      buttonText: t('ctReports.moreCta', { ns: 'report' }),
      backgroundImageSrc: CTRBackgroundImage,
      type: 'CTRReport',
    },
  ]
  return aptContent
}
