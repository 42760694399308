import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_SUBMISSIONS,
  FETCH_SUBMISSIONS_COUNT,
  UPLOAD_SAMPLE,
} from 'constants/api'
import i18n from 'i18next'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchSubmissions,
  fetchSubmissionsCancelled,
  fetchSubmissionsCount,
  fetchSubmissionsCountCancelled,
  fetchSubmissionsCountFulfilled,
  fetchSubmissionsCountRejected,
  fetchSubmissionsFulfilled,
  fetchSubmissionsRejected,
  uploadSample,
  uploadSampleFulfilled,
  uploadSampleRejected,
} from 'store/slices/sample'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { mapAPISamplesToState } from 'store/types/entityTypes/sample'
import {
  IFetchSubmissionsCountResponse,
  IFetchSubmissionsPayload,
  IFetchSubmissionsResponse,
} from 'store/types/slicesTypes/sample'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const fetchSubmissionsCountEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSubmissionsCount.match),
    switchMap(() =>
      fetchData<IFetchSubmissionsCountResponse>({
        url: FETCH_SUBMISSIONS_COUNT,
      }).pipe(
        map((response: AjaxResponse<IFetchSubmissionsCountResponse>) =>
          fetchSubmissionsCountFulfilled(response.response.count)
        ),
        takeUntil(action$.pipe(filter(fetchSubmissionsCountCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSubmissionsCountRejected(error))
          )
        )
      )
    )
  )

const getFetchSubmissionUrl = ({
  offset,
  dateFrom,
  dateTo,
  risk_level,
  query,
}: IFetchSubmissionsPayload): string => {
  let url = `${FETCH_SUBMISSIONS}?offset=${offset}`
  if (dateFrom) {
    url = `${url}&date[from]=${dateFrom}`
  }
  if (dateTo) {
    url = `${url}&date[to]=${dateTo}`
  }
  if (risk_level) {
    url = `${url}&risk_level=${risk_level}`
  }
  if (query) {
    url = `${url}&query=${encodeURIComponent(query)}`
  }

  return url
}

const fetchSubmissionsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSubmissions.match),
    switchMap((action) =>
      fetchData<IFetchSubmissionsResponse>({
        url: getFetchSubmissionUrl(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchSubmissionsResponse>) =>
          fetchSubmissionsFulfilled(
            mapAPISamplesToState(response.response.submissions)
          )
        ),
        takeUntil(action$.pipe(filter(fetchSubmissionsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSubmissionsRejected(error))
          )
        )
      )
    )
  )

const uploadSampleEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(uploadSample.match),
    switchMap((action) =>
      fetchData({
        method: 'POST',
        url: UPLOAD_SAMPLE,
        body: action.payload.data,
        isUploadFile: true,
      }).pipe(
        mergeMap(() =>
          concat(
            of(uploadSampleFulfilled()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('upload.uploadSuccess', { ns: 'snackbar' }),
              })
            )
          )
        ),
        tap(() => {
          action.payload.successCallback()
        }),
        catchError((error: AjaxError) =>
          concat(
            checkUnauthorizedToken(error),
            of(uploadSampleRejected(error)).pipe(
              tap(() => {
                if (error.status === 402) {
                  action.payload.outOfAAPCallback()
                }
              })
            ),
            of(
              pushAlertSnackbar({
                text: i18n.t('upload.uploadFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

export const sampleEpic = combineEpics(
  uploadSampleEpic,
  fetchSubmissionsCountEpic,
  fetchSubmissionsEpic
)
