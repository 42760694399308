import type { Action } from '@reduxjs/toolkit'
import {
  FETCH_MITRE_TACTICS,
  getMitreTacticById,
  getMitreTechniqueById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchTactic,
  fetchTacticCancelled,
  fetchTacticFulfilled,
  fetchTacticRejected,
  fetchTactics,
  fetchTacticsCancelled,
  fetchTacticsFulfilled,
  fetchTacticsRejected,
  fetchTechnique,
  fetchTechniqueCancelled,
  fetchTechniqueFulfilled,
  fetchTechniqueRejected,
} from 'store/slices/capability'
import {
  mapAPITacticsToState,
  mapAPITacticToState,
  mapAPITechniqueToState,
} from 'store/types/entityTypes/capability'
import {
  IFetchTacticResponse,
  IFetchTacticsResponse,
  IFetchTechniqueResponse,
} from 'store/types/slicesTypes/capability'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const tacticsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTactics.match),
    switchMap(() =>
      fetchData<IFetchTacticsResponse>({
        url: FETCH_MITRE_TACTICS,
      }).pipe(
        map((response: AjaxResponse<IFetchTacticsResponse>) =>
          fetchTacticsFulfilled(mapAPITacticsToState(response.response.tactics))
        ),
        takeUntil(action$.pipe(filter(fetchTacticsCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchTacticsRejected(error)))
        )
      )
    )
  )

const tacticEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTactic.match),
    switchMap((action) =>
      fetchData<IFetchTacticResponse>({
        url: getMitreTacticById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchTacticResponse>) =>
          fetchTacticFulfilled(mapAPITacticToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchTacticCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchTacticRejected(error)))
        )
      )
    )
  )

const techniqueEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchTechnique.match),
    switchMap((action) =>
      fetchData<IFetchTechniqueResponse>({
        url: getMitreTechniqueById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchTechniqueResponse>) => {
          // only store technique data
          if (response.response?.parent?.serial) {
            return fetchTechnique(response.response.parent.serial)
          }
          return fetchTechniqueFulfilled(
            mapAPITechniqueToState(response.response)
          )
        }),
        takeUntil(action$.pipe(filter(fetchTechniqueCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchTechniqueRejected(error))
          )
        )
      )
    )
  )

export const capabilityEpic = combineEpics(
  tacticsEpic,
  tacticEpic,
  techniqueEpic
)
