import {
  Autocomplete,
  Box,
  InputAdornment,
  Popper,
  PopperProps,
  SvgIcon,
  TextField,
  useTheme,
} from '@mui/material'
import { ReactComponent as SearchIcon } from 'assets/basicIcons/search.svg'
import { LocalStorageKey } from 'constants/localStorageKeys'
import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import {
  KeyboardEvent,
  MouseEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchSuggestWords,
  fetchSuggestWordsCancelled,
  selectSuggestWords,
} from 'store/slices/search'
import { v4 as uuidv4 } from 'uuid'

const SEARCH_HISTORY_MAX_COUNT = 5

const StyledPopper = (props: PopperProps) => (
  <Popper
    {...props}
    sx={(theme) => ({
      '& .MuiPaper-root': {
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderTop: 'none',
        borderRadius: '0 0 0.125rem 0.125rem',
        bgcolor: theme.colors.BLACK_90,
        color: theme.colors.WHITE,
        '& .MuiAutocomplete-listbox': {
          py: 0,
        },
        '& .MuiAutocomplete-option': {
          pl: 11,
          pr: 4,
          py: 1.5,
          ...theme.typography.body,
          '&.Mui-focused': {
            bgcolor: theme.colors.WHITE_40,
            '&[aria-selected=true]': {
              bgcolor: theme.colors.WHITE_40,
            },
          },
          '&:hover': {
            bgcolor: theme.colors.WHITE_40,
          },
          '&[aria-selected=true]': {
            bgcolor: theme.colors.WHITE_20,
            '&:hover': {
              bgcolor: theme.colors.WHITE_40,
            },
          },
        },
      },
    })}
  />
)

export const SearchBar = ({
  onSearchEntry = false,
}: {
  onSearchEntry?: boolean
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const searchHistoryJSON = localStorage.getItem(LocalStorageKey.SEARCH_HISTORY)
  const searchHistory =
    searchHistoryJSON && Array.isArray(JSON.parse(searchHistoryJSON))
      ? JSON.parse(searchHistoryJSON)
      : []

  const suggestWords = useAppSelector(selectSuggestWords)

  const searchParamSearchInputText =
    searchParams.get(SearchParamKey.SEARCH_TEXT) || ''

  const [inputValue, setInputValue] = useState(searchParamSearchInputText)
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    searchParamSearchInputText
  )

  // 用來判斷 Enter 時是要以 Auto-Complete 搜尋而非輸入框文字搜尋的 Flag
  const [searchByAutoComplete, setSearchByAutoComplete] =
    useState<boolean>(false)

  const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false)

  const handleAutoCompleteOpen = () => {
    setAutoCompleteOpen(true)
  }

  const handleAutoCompleteClose = () => {
    setSearchByAutoComplete(false)
    setAutoCompleteOpen(false)
  }

  const handleSearch = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    word: string
  ) => {
    const newSearchText = word.trim()

    if (newSearchText.length > 0) {
      // update search history in localStorage
      const newSearchHistory = [...searchHistory, newSearchText]
      const displaySearchHistory =
        newSearchHistory.length > 5
          ? newSearchHistory.slice(
              newSearchHistory.length - SEARCH_HISTORY_MAX_COUNT,
              SEARCH_HISTORY_MAX_COUNT + 1
            )
          : newSearchHistory

      localStorage.setItem(
        LocalStorageKey.SEARCH_HISTORY,
        JSON.stringify(displaySearchHistory)
      )

      const searchUrl = `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${
        SearchParamKey.SEARCH_TEXT
      }=${encodeURIComponent(newSearchText)}&${
        SearchParamKey.SEARCH_UUID
      }=${uuidv4()}`

      if (event.shiftKey) {
        window.open(searchUrl, '_blank')
      } else if (event.ctrlKey || event.metaKey) {
        // metaKey is command key in MacOS
        window.open(searchUrl)
      } else {
        navigate(searchUrl)
      }
    }
  }

  const handleAutoCompleteChange = (
    event: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>,
    value: string
  ) => {
    setAutoCompleteValue(value)
    if (value) {
      handleSearch(event, value)
    }
  }

  const handleInputChange = (
    _event: SyntheticEvent<Element | Event>,
    value: string
  ) => {
    setInputValue(value)
    if (value.length > 0) {
      // 只有文字輸入改變時，才會出現 auto complete
      handleAutoCompleteOpen()
    } else {
      handleAutoCompleteClose()
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !searchByAutoComplete) {
      handleSearch(event, inputValue)
    }
  }

  // 用滑鼠或鍵盤 focus 到 auto complete 選項時觸發
  const handleHighlightChange = (
    _event: SyntheticEvent<Element | Event>,
    option: string | null
  ) => {
    setSearchByAutoComplete(Boolean(option))
  }

  useEffect(() => {
    dispatch(fetchSuggestWords())
    return () => {
      dispatch(fetchSuggestWordsCancelled())
    }
  }, [])

  useEffect(() => {
    setInputValue(searchParamSearchInputText)
    setAutoCompleteValue(searchParamSearchInputText)
  }, [searchParamSearchInputText])

  const autoCompleteOptions = suggestWords.map((word) => word.name)

  const searchbarHeight = onSearchEntry ? '3rem' : '2rem'

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Autocomplete
        freeSolo
        disableClearable
        disablePortal
        open={autoCompleteOpen}
        options={autoCompleteOptions}
        onClose={handleAutoCompleteClose}
        value={autoCompleteValue}
        onChange={handleAutoCompleteChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onHighlightChange={handleHighlightChange}
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            placeholder={t('searchBarPlaceholder', { ns: 'component' })}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    sx={{ width: '1.5rem', height: '1.5rem', ml: 3 }}
                    component={SearchIcon}
                    inheritViewBox
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        sx={{
          '& .MuiOutlinedInput-root': {
            p: 0,
            '.MuiAutocomplete-input': {
              height: searchbarHeight,
              p: 0,
            },
          },
          '& .MuiTextField-root': {
            height: '100%',
            width: '100%',
            bgcolor: theme.colors.BLACK_90,
            '& .MuiInputBase-root': {
              p: 0,
              borderRadius: 0,
              height: '100%',
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `2px solid ${theme.colors.WHITE_20}`,
                },
              },
            },
            '& .MuiInputBase-input': {
              color: theme.colors.WHITE,
              fontSize: '0.875rem',
              height: searchbarHeight,
              p: 0,
              '&::placeholder': {
                opacity: 0.6,
              },
              '&:focus': {
                '&::placeholder': {
                  opacity: 1,
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderBottom: `1px solid ${theme.colors.WHITE_20}`,
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.colors.WHITE_20,
              },
              bgcolor: theme.colors.BLACK_60,
              '& .MuiSvgIcon-root': {
                path: {
                  fill: theme.colors.WHITE,
                  fillOpacity: 1,
                },
              },
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  opacity: 1,
                },
              },
            },
          },
        }}
      />
    </Box>
  )
}
