import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_ROUTE,
  API_DOC_V2_ROUTE,
  API_DOC_V2_SAMPLE_ROUTE,
  API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE,
} from 'constants/routeParams'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/search?query=huapi" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success": true,
  "samples": [
    {
      
      "sha256":"b4e11a083c5dc3b69d0866f80193d10e96dbe611961f5f108cbe78dc8c93c2be",
      "md5":"f233991c8b0da42504e1af7e859bf292",
      "size":46592,
      "first_seen":1632405027,
      "adversaries":["Huapi"],
      "malwares":["Bifrost"],
      "filename":"f233991c8b0da42504e1af7e859bf292.virus",
      "risk_level":"high",
      "has_network_activity":false,
      "url":"https://api.threatvision.org/api/v2/samples/b4e11a083c5dc3b69d0866f80193d10e96dbe611961f5f108cbe78dc8c93c2be"
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Search Samples'

export const APIDocumentVersionTwoSearchSamples = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          This endpoint enables users to search any samples they are interested
          in.
          <br />
          It fetches pertinent results by utilizing the relational data
          structure in our database.
          <br />
          This endpoint can deliver 10 results per query and ‘offset’ parameter
          is provided for filtering.
        </Description>
        <Description>
          Notably, the endpoint for sample details (the url you retrieve from{' '}
          <TextLink
            to={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE}`}
            size="large"
          >
            Search Samples
          </TextLink>{' '}
          API endpoint) would take 1 AAP (Automated Analysis Point) per query.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/search" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            query
            <UnorderedList>
              <ListItem>Keywords to filter samples.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            samples
            <UnorderedList>
              <ListItem>
                Array of matching uploaded samples, each sample contains
                following keys:
                <UnorderedList>
                  <ListItem>sha256</ListItem>
                  <ListItem>md5</ListItem>
                  <ListItem>size</ListItem>
                  <ListItem>filename</ListItem>
                  <ListItem>first_seen</ListItem>
                  <ListItem>risk_level</ListItem>
                  <ListItem>has_network_activity</ListItem>
                  <ListItem>
                    adversaries
                    <UnorderedList>
                      <ListItem>Related adversaries</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    malwares
                    <UnorderedList>
                      <ListItem>Related malwares</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    url
                    <UnorderedList>
                      <ListItem>
                        Link to sample detail API endpoint in ThreatVision
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
