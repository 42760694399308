import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { ReactNode } from 'react'

interface IMatchedCardLayoutProps {
  title: string
  children: NonNullable<ReactNode>
  linkText: string
  linkTo: string
}

export const MatchedCardLayout = ({
  title,
  children,
  linkText,
  linkTo,
}: IMatchedCardLayoutProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        maxHeight: '100%',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{ justifyContent: 'space-between', bgcolor: theme.colors.BLACK_90 }}
      >
        <Box
          sx={{
            px: 4,
            py: 1,
            borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          }}
        >
          <Typography
            variant="title"
            sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
        <Box sx={{ my: 4, px: 4 }}>
          <LinkButton
            to={linkTo}
            sx={{ width: '100%' }}
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            {linkText}
          </LinkButton>
        </Box>
      </Stack>
    </Box>
  )
}
