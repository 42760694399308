export type TTlp = 'white' | 'green' | 'amber' | 'red'

export type TShareTargetsType = 'user' | 'group'

export type TReportSubscriberGroupsName =
  | 'Flash Subscribers'
  | 'Monthly Subscribers'
  | 'Campaign Tracking Subscribers'
  | 'Cyber Affairs Subscribers'
  | 'Vulnerability Insights Subscribers'
  | 'Advisory Report Subscribers'

export type TReportSubscriberGroupsKey =
  | 'flash_report_subscribers'
  | 'monthly_report_subscribers'
  | 'advanced_report_subscribers'
  | 'bi_weekly_report_subscribers'
  | 'vulnerability_insights_report_subscribers'
  | 'advisory_report_subscribers'

export type TIocSubscriberGroupsName =
  | 'Apt IoC List Subscribers'
  | 'Cyber Crime List Subscribers'

export type TIocSubscriberGroupsKey =
  | 'apt_ioc_list_subscribers'
  | 'cyber_crime_list_subscribers'

export type TOtherServicesSubscriberGroupsName = 'Deep dark web monitor'

export type TOtherServicesSubscriberGroupsKey = 'deep_dark_web_monitor'

export interface IShareTargetAPIData {
  name: string
  type: TShareTargetsType
  ofs_id: string
  can_edit: boolean
}

export interface IShareTarget {
  name: string
  type: TShareTargetsType
  ofsId: string
  canEdit: boolean
}

export interface IDefaultShareAPIData {
  tlp: TTlp
  targets: IShareTargetAPIData[]
}

export interface IDefaultShare {
  tlp: TTlp
  targets: IShareTarget[]
}

export const mapAPIShareTargetToState = (
  fromAPIAccountFindShareTarget: IShareTargetAPIData
): IShareTarget => ({
  name: fromAPIAccountFindShareTarget.name,
  type: fromAPIAccountFindShareTarget.type,
  ofsId: fromAPIAccountFindShareTarget.ofs_id,
  canEdit: fromAPIAccountFindShareTarget.can_edit,
})

export const mapAPIShareTargetsToState = (
  fromAPIAccountShareTargets: IShareTargetAPIData[]
): IShareTarget[] => fromAPIAccountShareTargets.map(mapAPIShareTargetToState)

export const mapAPIDefaultShareToState = (
  fromAPIAccountSettingsDefaultShare: IDefaultShareAPIData
): IDefaultShare => ({
  tlp: fromAPIAccountSettingsDefaultShare.tlp,
  targets: mapAPIShareTargetsToState(
    fromAPIAccountSettingsDefaultShare.targets
  ),
})
