import { Box } from '@mui/material'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchTabMalwareCount,
  fetchTabMalwareCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  fetchTabTechniqueCount,
  fetchTabTechniqueCountCancelled,
  resetTabCounts,
} from 'store/slices/tabContent'
import { TCommentType } from 'store/types/entityTypes/comment'

import { IAdversaryCategoryTab, useAdversaryCategoryTabs } from './config'

interface IAdversaryCategoryTabsProps {
  categoryTab: IAdversaryCategoryTab
  adversaryId: string
}

export const AdversaryCategoryTabs = ({
  adversaryId,
  categoryTab,
}: IAdversaryCategoryTabsProps) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const adversaryCategoryTabs = useAdversaryCategoryTabs()

  const handleSelect = (key: string) => {
    searchParams.set(SearchParamKey.ADVERSARY_TAB, key)
    setSearchParams(searchParams)
  }

  const commentableType: TCommentType = 'Adversary'
  const commentableId = adversaryId

  useEffect(() => {
    dispatch(
      setupCommentableIdAndType({
        commentableId,
        commentableType,
      })
    )
    dispatch(
      fetchComments({
        commentableId,
        commentableType,
      })
    )
    dispatch(fetchTabReportCount(adversaryId))
    dispatch(fetchTabMalwareCount(adversaryId))
    dispatch(fetchTabSampleCount(adversaryId))
    dispatch(fetchTabTechniqueCount(adversaryId))

    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabMalwareCountCancelled())
      dispatch(fetchTabSampleCountCancelled())
      dispatch(fetchTabTechniqueCountCancelled())
      dispatch(resetTabCounts())
      dispatch(resetComments())
    }
  }, [adversaryId])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, py: 1.5 }}>
      {adversaryCategoryTabs.map((tab) => (
        <TabWithCount
          key={tab.key}
          name={tab.name}
          handleSelect={() => handleSelect(tab.key)}
          count={tab.count}
          selected={categoryTab.key === tab.key}
          isLoading={tab.isLoading}
          appendPlusSign={tab.appendPlusSign}
        />
      ))}
    </Box>
  )
}
