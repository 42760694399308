// country background images
import AfricaBackgroundImage from 'assets/threatLandscape/item-background/region-background-africa.jpg'
import CentralAsiaBackgroundImage from 'assets/threatLandscape/item-background/region-background-central-asia.jpg'
import EastAsiaBackgroundImage from 'assets/threatLandscape/item-background/region-background-east-asia.jpg'
import EuropeBackgroundImage from 'assets/threatLandscape/item-background/region-background-europe.jpg'
import LatinAmericaAndTheCaribbeanBackgroundImage from 'assets/threatLandscape/item-background/region-background-latin-america-and-the-caribbean.jpg'
import NorthAmericaBackgroundImage from 'assets/threatLandscape/item-background/region-background-north-america.jpg'
import OceaniaBackgroundImage from 'assets/threatLandscape/item-background/region-background-oceania.jpg'
import SouthAsiaBackgroundImage from 'assets/threatLandscape/item-background/region-background-south-asia.jpg'
import SouthEastAsiaBackgroundImage from 'assets/threatLandscape/item-background/region-background-south-east-asia.jpg'
import WestAsiaBackgroundImage from 'assets/threatLandscape/item-background/region-background-west-asia.jpg'
import AfricaGraphicImage from 'assets/threatLandscape/region-images/africa.png'
import CentralAsiaImage from 'assets/threatLandscape/region-images/central-asia.png'
import EastAsiaGraphicImage from 'assets/threatLandscape/region-images/east-asia.png'
import EuropeGraphicImage from 'assets/threatLandscape/region-images/europe.png'
import LatinAmericaAndTheCaribbeanGraphicImage from 'assets/threatLandscape/region-images/latin-america-and-the-caribbean.png'
import NorthAmericaGraphicImage from 'assets/threatLandscape/region-images/north-america.png'
import OceaniaGraphicImage from 'assets/threatLandscape/region-images/oceania.png'
// country graphic images
import SouthAsiaGraphicImage from 'assets/threatLandscape/region-images/south-asia.png'
import SouthEastAsiaGraphicImage from 'assets/threatLandscape/region-images/south-east-asia.png'
import WestAsiaGraphicImage from 'assets/threatLandscape/region-images/west-asia.png'
import { useTranslation } from 'react-i18next'
import { ITargetRegion } from 'store/types/entityTypes/target'

export const useTargetedRegions = () => {
  /**
   * @description 寫死在前端的 target countries，主要用於 threat landscape page
   */
  const { t } = useTranslation(['threatLandscapes'])

  const targetedRegions: ITargetRegion[] = [
    {
      backgroundImageSrc: SouthAsiaBackgroundImage,
      name: 'South Asia',
      displayName: t('targetRegions.southAsia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'south-asia',
      desc: t('targetRegions.southAsia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: SouthAsiaGraphicImage,
    },
    {
      backgroundImageSrc: SouthEastAsiaBackgroundImage,
      name: 'South East Asia',
      displayName: t('targetRegions.southEastAsia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'south-east-asia',
      desc: t('targetRegions.southEastAsia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: SouthEastAsiaGraphicImage,
    },
    {
      backgroundImageSrc: EastAsiaBackgroundImage,
      name: 'East Asia',
      displayName: t('targetRegions.eastAsia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'east-asia',
      desc: t('targetRegions.eastAsia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: EastAsiaGraphicImage,
    },
    {
      backgroundImageSrc: WestAsiaBackgroundImage,
      name: 'West Asia',
      displayName: t('targetRegions.westAsia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'west-asia',
      desc: t('targetRegions.westAsia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: WestAsiaGraphicImage,
    },
    {
      backgroundImageSrc: CentralAsiaBackgroundImage,
      name: 'Central Asia',
      displayName: t('targetRegions.centralAsia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'central-asia',
      desc: t('targetRegions.centralAsia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: CentralAsiaImage,
    },
    {
      backgroundImageSrc: EuropeBackgroundImage,
      name: 'Europe',
      displayName: t('targetRegions.europe.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'europe',
      desc: t('targetRegions.europe.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: EuropeGraphicImage,
    },
    {
      backgroundImageSrc: AfricaBackgroundImage,
      name: 'Africa',
      displayName: t('targetRegions.africa.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'africa',
      desc: t('targetRegions.africa.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: AfricaGraphicImage,
    },
    {
      backgroundImageSrc: NorthAmericaBackgroundImage,
      name: 'North America',
      displayName: t('targetRegions.northAmerica.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'north-america',
      desc: t('targetRegions.northAmerica.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: NorthAmericaGraphicImage,
    },
    {
      backgroundImageSrc: LatinAmericaAndTheCaribbeanBackgroundImage,
      name: 'Latin America and the Caribbean',
      displayName: t('targetRegions.latinAmericaAndTheCaribbean.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'latin-america-and-the-caribbean',
      desc: t('targetRegions.latinAmericaAndTheCaribbean.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: LatinAmericaAndTheCaribbeanGraphicImage,
    },
    {
      backgroundImageSrc: OceaniaBackgroundImage,
      name: 'Oceania',
      displayName: t('targetRegions.oceania.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'oceania',
      desc: t('targetRegions.oceania.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: OceaniaGraphicImage,
    },
  ]

  return targetedRegions
}
