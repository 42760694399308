import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IAdversary } from 'store/types/entityTypes/adversary'
import { ITechnique } from 'store/types/entityTypes/capability'
import { IMalware } from 'store/types/entityTypes/malware'
import { IReport } from 'store/types/entityTypes/report'
import { ISample } from 'store/types/entityTypes/sample'
import { ITabContentSlice } from 'store/types/slicesTypes/tabContent'

interface IFetchTabResultPayload {
  searchText: string
  offset: number
}

const initialState: ITabContentSlice = {
  tabReports: {
    count: 0,
    data: [],
    isCountLoading: true,
    isDataLoading: true,
    hasMoreData: true,
  },
  tabAdversaries: {
    count: 0,
    data: [],
    isCountLoading: true,
    isDataLoading: true,
    hasMoreData: true,
  },
  tabMalwares: {
    count: 0,
    data: [],
    isCountLoading: true,
    isDataLoading: true,
    hasMoreData: true,
  },
  tabSamples: {
    count: 0,
    data: [],
    isCountLoading: true,
    isDataLoading: true,
    hasMoreData: true,
  },
  tabTechniques: {
    count: 0,
    data: [],
    isCountLoading: true,
    isDataLoading: true,
    hasMoreData: true,
  },
}

const tabContentSlice = createSlice({
  name: 'tabContent',
  initialState,
  reducers: {
    fetchTabReportCount(state, _action: PayloadAction<string>) {
      state.tabReports.isCountLoading = true
    },
    fetchTabReportCountFulfilled(state, action: PayloadAction<number>) {
      state.tabReports.count = action.payload
      state.tabReports.isCountLoading = false
    },
    fetchTabReportCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabReports.count = initialState.tabReports.count
      state.tabReports.isCountLoading = false
    },
    fetchTabReportCountCancelled(state) {
      state.tabReports.isCountLoading = false
    },
    fetchTabReports(state, _action: PayloadAction<IFetchTabResultPayload>) {
      state.tabReports.isDataLoading = true
    },
    fetchTabReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      if (action.payload.length > 0) {
        state.tabReports.data = [...state.tabReports.data, ...action.payload]
      } else {
        state.tabReports.hasMoreData = false
      }
      state.tabReports.isDataLoading = false
    },
    fetchTabReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabReports = initialState.tabReports
      state.tabReports.isDataLoading = false
    },
    fetchTabReportsCancelled(state) {
      state.tabReports.isDataLoading = false
    },
    resetTabReportContent(state) {
      state.tabReports.data = initialState.tabReports.data
      state.tabReports.hasMoreData = initialState.tabReports.hasMoreData
    },
    fetchTabAdversaryCount(state, _action: PayloadAction<string>) {
      state.tabAdversaries.isCountLoading = true
    },
    fetchTabAdversaryCountFulfilled(state, action: PayloadAction<number>) {
      state.tabAdversaries.count = action.payload
      state.tabAdversaries.isCountLoading = false
    },
    fetchTabAdversaryCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabAdversaries.count = initialState.tabAdversaries.count
      state.tabAdversaries.isCountLoading = false
    },
    fetchTabAdversaryCountCancelled(state) {
      state.tabAdversaries.isCountLoading = false
    },
    fetchTabAdversaries(state, _action: PayloadAction<IFetchTabResultPayload>) {
      state.tabAdversaries.isDataLoading = true
    },
    fetchTabAdversariesFulfilled(state, action: PayloadAction<IAdversary[]>) {
      if (action.payload.length > 0) {
        state.tabAdversaries.data = [
          ...state.tabAdversaries.data,
          ...action.payload,
        ]
      } else {
        state.tabAdversaries.hasMoreData = false
      }
      state.tabAdversaries.isDataLoading = false
    },
    fetchTabAdversariesRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabAdversaries = initialState.tabAdversaries
      state.tabAdversaries.isDataLoading = false
    },
    fetchTabAdversariesCancelled(state) {
      state.tabAdversaries.isDataLoading = false
    },
    resetTabAdversaryContent(state) {
      state.tabAdversaries.data = initialState.tabAdversaries.data
      state.tabAdversaries.hasMoreData = initialState.tabAdversaries.hasMoreData
    },
    fetchTabMalwareCount(state, _action: PayloadAction<string>) {
      state.tabMalwares.isCountLoading = true
    },
    fetchTabMalwareCountFulfilled(state, action: PayloadAction<number>) {
      state.tabMalwares.count = action.payload
      state.tabMalwares.isCountLoading = false
    },
    fetchTabMalwareCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabMalwares.count = initialState.tabMalwares.count
      state.tabMalwares.isCountLoading = false
    },
    fetchTabMalwareCountCancelled(state) {
      state.tabMalwares.isCountLoading = false
    },
    fetchTabMalwares(state, _action: PayloadAction<IFetchTabResultPayload>) {
      state.tabMalwares.isDataLoading = true
    },
    fetchTabMalwaresFulfilled(state, action: PayloadAction<IMalware[]>) {
      if (action.payload.length > 0) {
        state.tabMalwares.data = [...state.tabMalwares.data, ...action.payload]
      } else {
        state.tabMalwares.hasMoreData = false
      }
      state.tabMalwares.isDataLoading = false
    },
    fetchTabMalwaresRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabMalwares = initialState.tabMalwares
      state.tabMalwares.isDataLoading = false
    },
    fetchTabMalwaresCancelled(state) {
      state.tabMalwares.isDataLoading = false
    },
    resetTabMalwareContent(state) {
      state.tabMalwares.data = initialState.tabMalwares.data
      state.tabMalwares.hasMoreData = initialState.tabMalwares.hasMoreData
    },
    fetchTabSampleCount(state, _action: PayloadAction<string>) {
      state.tabSamples.isCountLoading = true
    },
    fetchTabSampleCountFulfilled(state, action: PayloadAction<number>) {
      state.tabSamples.count = action.payload
      state.tabSamples.isCountLoading = false
    },
    fetchTabSampleCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabSamples.count = initialState.tabSamples.count
      state.tabSamples.isCountLoading = false
    },
    fetchTabSampleCountCancelled(state) {
      state.tabSamples.isCountLoading = false
    },
    fetchTabSamples(state, _action: PayloadAction<IFetchTabResultPayload>) {
      state.tabSamples.isDataLoading = true
    },
    fetchTabSamplesFulfilled(state, action: PayloadAction<ISample[]>) {
      if (action.payload.length > 0) {
        state.tabSamples.data = [...state.tabSamples.data, ...action.payload]
      } else {
        state.tabSamples.hasMoreData = false
      }
      state.tabSamples.isDataLoading = false
    },
    fetchTabSamplesRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabSamples = initialState.tabSamples
      state.tabSamples.isDataLoading = false
    },
    fetchTabSamplesCancelled(state) {
      state.tabSamples.isDataLoading = false
    },
    resetTabSampleContent(state) {
      state.tabSamples.data = initialState.tabSamples.data
      state.tabSamples.hasMoreData = initialState.tabSamples.hasMoreData
    },
    fetchTabTechniques(state, _action: PayloadAction<IFetchTabResultPayload>) {
      state.tabTechniques.isDataLoading = true
    },
    fetchTabTechniquesFulfilled(state, action: PayloadAction<ITechnique[]>) {
      if (action.payload.length > 0) {
        state.tabTechniques.data = [
          ...state.tabTechniques.data,
          ...action.payload,
        ]
      } else {
        state.tabTechniques.hasMoreData = false
      }
      state.tabTechniques.isDataLoading = false
    },
    fetchTabTechniquesRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabTechniques = initialState.tabTechniques
      state.tabTechniques.isDataLoading = false
    },
    fetchTabTechniquesCancelled(state) {
      state.tabTechniques.isDataLoading = false
    },
    resetTabTechniquesContent(state) {
      state.tabTechniques.data = initialState.tabTechniques.data
      state.tabTechniques.hasMoreData = initialState.tabTechniques.hasMoreData
    },
    fetchTabTechniqueCount(state, _action: PayloadAction<string>) {
      state.tabTechniques.isCountLoading = true
    },
    fetchTabTechniqueCountFulfilled(state, action: PayloadAction<number>) {
      state.tabTechniques.count = action.payload
      state.tabTechniques.isCountLoading = false
    },
    fetchTabTechniqueCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.tabTechniques.count = initialState.tabTechniques.count
      state.tabTechniques.isCountLoading = false
    },
    fetchTabTechniqueCountCancelled(state) {
      state.tabTechniques.isCountLoading = false
    },
    resetTabCounts(state) {
      state.tabReports.count = initialState.tabReports.count
      state.tabAdversaries.count = initialState.tabAdversaries.count
      state.tabMalwares.count = initialState.tabMalwares.count
      state.tabSamples.count = initialState.tabSamples.count
      state.tabTechniques.count = initialState.tabTechniques.count
    },
  },
})

export const {
  fetchTabReportCount,
  fetchTabReportCountFulfilled,
  fetchTabReportCountRejected,
  fetchTabReportCountCancelled,
  fetchTabReports,
  fetchTabReportsFulfilled,
  fetchTabReportsRejected,
  fetchTabReportsCancelled,
  resetTabReportContent,
  fetchTabAdversaryCount,
  fetchTabAdversaryCountFulfilled,
  fetchTabAdversaryCountRejected,
  fetchTabAdversaryCountCancelled,
  fetchTabAdversaries,
  fetchTabAdversariesFulfilled,
  fetchTabAdversariesRejected,
  fetchTabAdversariesCancelled,
  resetTabAdversaryContent,
  fetchTabMalwareCount,
  fetchTabMalwareCountFulfilled,
  fetchTabMalwareCountCancelled,
  fetchTabMalwareCountRejected,
  fetchTabMalwares,
  fetchTabMalwaresFulfilled,
  fetchTabMalwaresCancelled,
  fetchTabMalwaresRejected,
  resetTabMalwareContent,
  fetchTabSampleCount,
  fetchTabSampleCountFulfilled,
  fetchTabSampleCountRejected,
  fetchTabSampleCountCancelled,
  fetchTabSamples,
  fetchTabSamplesFulfilled,
  fetchTabSamplesRejected,
  fetchTabSamplesCancelled,
  resetTabSampleContent,
  fetchTabTechniques,
  fetchTabTechniquesFulfilled,
  fetchTabTechniquesRejected,
  fetchTabTechniquesCancelled,
  resetTabTechniquesContent,
  fetchTabTechniqueCount,
  fetchTabTechniqueCountFulfilled,
  fetchTabTechniqueCountRejected,
  fetchTabTechniqueCountCancelled,
  resetTabCounts,
} = tabContentSlice.actions

export const selectTabReportCountLoading = (state: TRootState) =>
  state.tabContent.tabReports.isCountLoading
export const selectTabReportCount = (state: TRootState) =>
  state.tabContent.tabReports.count
export const selectTabReportDataLoading = (state: TRootState) =>
  state.tabContent.tabReports.isDataLoading
export const selectTabReports = (state: TRootState) =>
  state.tabContent.tabReports.data
export const selectHasMoreTabReports = (state: TRootState) =>
  state.tabContent.tabReports.hasMoreData

export const selectTabAdversaryCountLoading = (state: TRootState) =>
  state.tabContent.tabAdversaries.isCountLoading
export const selectTabAdversaryCount = (state: TRootState) =>
  state.tabContent.tabAdversaries.count
export const selectTabAdversaryDataLoading = (state: TRootState) =>
  state.tabContent.tabAdversaries.isDataLoading
export const selectTabAdversaries = (state: TRootState) =>
  state.tabContent.tabAdversaries.data
export const selectHasMoreTabAdversaries = (state: TRootState) =>
  state.tabContent.tabAdversaries.hasMoreData

export const selectTabMalwareCountLoading = (state: TRootState) =>
  state.tabContent.tabMalwares.isCountLoading
export const selectTabMalwareCount = (state: TRootState) =>
  state.tabContent.tabMalwares.count
export const selectTabMalwareDataLoading = (state: TRootState) =>
  state.tabContent.tabMalwares.isDataLoading
export const selectTabMalwares = (state: TRootState) =>
  state.tabContent.tabMalwares.data
export const selectHasMoreTabMalwares = (state: TRootState) =>
  state.tabContent.tabMalwares.hasMoreData

export const selectTabSampleCountLoading = (state: TRootState) =>
  state.tabContent.tabSamples.isCountLoading
export const selectTabSampleCount = (state: TRootState) =>
  state.tabContent.tabSamples.count
export const selectTabSampleDataLoading = (state: TRootState) =>
  state.tabContent.tabSamples.isDataLoading
export const selectTabSamples = (state: TRootState) =>
  state.tabContent.tabSamples.data
export const selectHasMoreTabSamples = (state: TRootState) =>
  state.tabContent.tabSamples.hasMoreData

export const selectTabTechniqueCountLoading = (state: TRootState) =>
  state.tabContent.tabTechniques.isCountLoading
export const selectTabTechniqueCount = (state: TRootState) =>
  state.tabContent.tabTechniques.count
export const selectTabTechniqueDataLoading = (state: TRootState) =>
  state.tabContent.tabTechniques.isDataLoading
export const selectTabTechniques = (state: TRootState) =>
  state.tabContent.tabTechniques.data
export const selectHasMoreTabTechniques = (state: TRootState) =>
  state.tabContent.tabTechniques.hasMoreData

export default tabContentSlice.reducer
