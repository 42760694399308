import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_IOC_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/ioc_bundles" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success": true,
  "ioc_bundles": [
    {
      "id":"Q2FzZUZpbGUvMTE3MDU=",
      "name":"Flash Report 20230811 - 繁體中文版",
      "type":"Report IoC List",
      "created_at":1691683200,
      "stix_url":"https://api.threatvision.org/api/v2/ioc_bundles/Q2FzZUZpbGUvMTE3MDU=.stix"
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'List IoC Bundles'

export const APIDocumentVersionTwoIocBundleListIocBundles = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_IOC_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          flexBasis: 0,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_IOC_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          ThreatVision provides three different types of IoC bundles:
          <UnorderedList>
            <ListItem>Advanced Persistent Threat Weekly Feed IoC</ListItem>
            <ListItem>Cyber Crime Weekly Feed IoC</ListItem>
            <ListItem>Report IoC</ListItem>
          </UnorderedList>
        </Description>
        <Description>
          List IoC Bundles can be used to retrieve all the IoC bundles you are
          authorized to download. With this endpoint, you can get the name,
          type, created date, and download URL for each IoC bundle.
        </Description>
        <Description>
          Each request lists 10 IoC bundles, sorted in reverse order by creation
          time, so you are always able to access the latest IoC lists. You are
          also able to offset the results, skipping lists you don’t need.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/ioc_bundles" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip
                <br />
                Allows you to paginate over the results
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>
          JSON object, with following keys:
          <UnorderedList>
            <ListItem>
              success
              <UnorderedList>
                <ListItem>The response status</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              ioc_bundles
              <UnorderedList>
                <ListItem>
                  An array of matching reports, each report contains following
                  keys:
                  <UnorderedList>
                    <ListItem>id: the identity of IoC bundle</ListItem>
                    <ListItem>name: the name of IoC bundle</ListItem>
                    <ListItem>type: the IoC bundle type</ListItem>
                    <ListItem>
                      created_at: timestamp of IoC bundle creation
                    </ListItem>
                    <ListItem>
                      stix_url: URL to download indicators in STIX format(if
                      supported)
                    </ListItem>
                    <ListItem>
                      csv_url: URL to download indicators in CSV format(if
                      supported)
                    </ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
