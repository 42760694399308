import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const TableHeadCellLoading = ({ sx = [] }: { sx?: SxProps }) => {
  const theme = useTheme()

  return (
    <TableCell
      sx={[
        {
          pl: 4,
          pr: 2,
          py: 5,
          bgcolor: theme.colors.BLACK_90,
          borderRight: `1px solid ${theme.colors.WHITE_20}`,
          borderBottom: `2px solid ${theme.colors.WHITE}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Skeleton type="title" width="5rem" />
    </TableCell>
  )
}

const TableBodyCellLoading = ({ sx = [] }: { sx?: SxProps }) => {
  const theme = useTheme()

  return (
    <TableCell
      sx={[
        {
          height: '2.25rem',
          px: 4,
          py: 7,
          borderColor: theme.colors.WHITE_20,
          borderRight: `1px solid ${theme.colors.WHITE_20}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Skeleton type="body" width="4rem" />
    </TableCell>
  )
}

const TableBodyCellTwoLineLoading = ({ sx = [] }: { sx?: SxProps }) => {
  const theme = useTheme()

  return (
    <TableCell
      sx={[
        {
          height: '2.25rem',
          px: 4,
          py: 7,
          borderColor: theme.colors.WHITE_20,
          borderRight: `1px solid ${theme.colors.WHITE_20}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Skeleton type="body" width="10.25rem" />
      <Box sx={{ pt: 1 }}>
        <Skeleton type="body" width="10.25rem" />
      </Box>
    </TableCell>
  )
}

const TableBodyRowLoading = () => (
  <TableRow>
    {generateSkeletons(4, <TableBodyCellLoading />)}
    <TableBodyCellTwoLineLoading />
    {generateSkeletons(4, <TableBodyCellLoading />)}
    <TableBodyCellLoading sx={{ borderRight: 'none' }} />
  </TableRow>
)

export const PMReportDetailTableLoading = () => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCellLoading sx={{ width: '7.78125rem' }} />
          <TableHeadCellLoading sx={{ width: '7.78125rem' }} />
          <TableHeadCellLoading sx={{ width: '7.78125rem' }} />
          <TableHeadCellLoading sx={{ width: '5.625rem' }} />
          <TableHeadCellLoading sx={{ width: '12.25rem' }} />
          <TableHeadCellLoading sx={{ width: '6.875rem' }} />
          <TableHeadCellLoading sx={{ width: '6.875rem' }} />
          <TableHeadCellLoading sx={{ width: '6.875rem' }} />
          <TableHeadCellLoading sx={{ width: '7.875rem' }} />
          <TableHeadCellLoading
            sx={{ width: '7.78125rem', borderRight: 'none' }}
          />
        </TableRow>
      </TableHead>
      <TableBody>{generateSkeletons(6, <TableBodyRowLoading />)}</TableBody>
    </Table>
  </TableContainer>
)
