import { Box, Stack, useTheme } from '@mui/material'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { ReactNode, useEffect } from 'react'

interface IPageTabsContent {
  tabs: NonNullable<ReactNode>
  content: NonNullable<ReactNode>
  extraReminder?: NonNullable<ReactNode> | null
  maxWidth?: string
}

const SCROLLABLE_CONTAINER_ID = 'content-container'

export const PageTabsContent = ({
  tabs,
  content,
  extraReminder = null,
  maxWidth = '80rem',
}: IPageTabsContent) => {
  const theme = useTheme()

  useEffect(() => {
    const container = document.getElementById(SCROLLABLE_CONTAINER_ID)
    if (container && container.scrollTo) {
      container.scrollTo(0, 0)
    }
  }, [content])

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          borderBottom: `2px solid ${theme.colors.PRIMARY}`,
          flexShrink: 0,
        }}
      >
        {tabs}
      </Box>
      {extraReminder && <Box sx={{ flexShrink: 0 }}>{extraReminder}</Box>}
      <Box
        id={SCROLLABLE_CONTAINER_ID}
        sx={{
          maxWidth: { maxWidth },
          flexGrow: 1,
          flexBasis: 0,
          overflowY: 'auto',
        }}
      >
        {content}
        <ScrollToTop
          scrollableContainerId={SCROLLABLE_CONTAINER_ID}
          sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
        />
      </Box>
    </Stack>
  )
}
