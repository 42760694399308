import {
  Box,
  ClickAwayListener,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { ReactComponent as SearchIcon } from 'assets/basicIcons/search.svg'
import { Button } from 'components/Button/Button'
import { DateRangeSelectorPicker } from 'components/DatePicker/DateRangeSelectorPicker'
import { Select, SelectItem } from 'components/Select/Select'
import { TextField } from 'components/TextField/TextField'
import { SearchParamKey } from 'constants/searchParamKeys'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TRiskLevelPayload } from 'store/types/slicesTypes/sample'

interface IRiskLevelOptions {
  value: TRiskLevelPayload
  displayText: string
}

interface ISampleToolbarProps {
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  rangeOption: string
  setRangeOption: (option: string) => void
  riskLevel: TRiskLevelPayload
  handleRiskLevelChange: (level: TRiskLevelPayload) => void
  handleReset: () => void
  searchText: string | null
  setSearchText: (searchText: string) => void
}

export const SampleToolbar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  rangeOption,
  setRangeOption,
  riskLevel,
  handleRiskLevelChange,
  handleReset,
  searchText,
  setSearchText,
}: ISampleToolbarProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['sample', 'component'])
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSearchBarFocus, setIsSearchBarFocus] = useState(false)

  const riskLevelOptions: IRiskLevelOptions[] = [
    {
      value: 'all',
      displayText: t('riskLevelSelector.all', { ns: 'sample' }),
    },
    {
      value: 'high_risk',
      displayText: t('riskLevelSelector.high', { ns: 'sample' }),
    },
    {
      value: 'mid_risk',
      displayText: t('riskLevelSelector.medium', { ns: 'sample' }),
    },
    {
      value: 'low_risk',
      displayText: t('riskLevelSelector.low', { ns: 'sample' }),
    },
  ]

  const handleSearch = () => {
    searchParams.set(
      SearchParamKey.SAMPLE_SEARCH_TEXT,
      searchText?.trim() || ''
    )
    setSearchParams(searchParams)
    setIsSearchBarFocus(false)
  }

  const handleResetSearchBar = () => {
    searchParams.set(SearchParamKey.SAMPLE_SEARCH_TEXT, '')
    setSearchParams(searchParams)
    setSearchText('')
  }

  const handleClickAway = () => {
    handleSearch()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
      event.currentTarget.querySelector('input')?.blur()
    }
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleFocus = () => {
    setIsSearchBarFocus(true)
  }

  const showResetButton = !!searchText && !isSearchBarFocus

  return (
    <Stack
      sx={{
        px: 4,
        py: 2,
        bgcolor: theme.colors.BLACK_95,
        gap: 2,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ width: 'fit-content' }}>
          <TextField
            type="text"
            placeholder={t('search.placeholder', { ns: 'sample' })}
            value={searchText || ''}
            onChange={handleSearchTextChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            sx={{
              width: '34.875rem',
              '& .MuiOutlinedInput-root': {
                pr: 0,
                height: '1.5rem',
                // searchButton pressed 樣式
                '&:active fieldset': {
                  borderWidth: 1,
                  borderColor: theme.colors.PRIMARY_60,
                },
                // searchButton borderLeft 樣式
                '&:hover .searchButton': {
                  borderLeft: `1px solid ${theme.colors.WHITE}`,
                },
                '&:active .searchButton': {
                  borderLeft: `1px solid ${theme.colors.PRIMARY_60}`,
                },
                '&.Mui-focused .searchButton': {
                  borderLeft: `1px solid ${theme.colors.PRIMARY_60}`,
                },
                ...theme.typography.bodySmall,
              },
              'input': {
                height: '1.5rem',
              },
            }}
            InputProps={{
              endAdornment: (
                <Box
                  className="searchButton"
                  sx={{
                    bgcolor: theme.colors.PRIMARY_40,
                    width: 'fit-content',
                    height: '1.5rem',
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0 4px 4px 0',
                    borderLeft: `1px solid ${theme.colors.WHITE_60}`,
                    cursor: 'pointer',
                    '&:active': {
                      bgcolor: theme.colors.PRIMARY_60,
                    },
                    py: 0.75,
                    pl: 1.5,
                    pr: 2,
                  }}
                  onClick={
                    showResetButton ? handleResetSearchBar : handleSearch
                  }
                >
                  <SvgIcon
                    component={showResetButton ? CloseIcon : SearchIcon}
                    inheritViewBox
                    sx={{
                      color: theme.colors.WHITE_60,
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  <Typography
                    variant="buttonMText"
                    sx={{ color: theme.colors.WHITE, whiteSpace: 'nowrap' }}
                  >
                    {showResetButton
                      ? t('resetCta', { ns: 'component' })
                      : t('search.cta', { ns: 'sample' })}
                  </Typography>
                </Box>
              ),
            }}
          />
        </Box>
      </ClickAwayListener>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <DateRangeSelectorPicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            rangeOption={rangeOption}
            setRangeOption={setRangeOption}
            width="13.4375rem"
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: theme.colors.WHITE_40 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE, whiteSpace: 'nowrap' }}
            >
              {t('riskLevelSelector.label', { ns: 'sample' })}
            </Typography>
            <Select
              value={riskLevel}
              onChange={(e) =>
                handleRiskLevelChange(e.target.value as TRiskLevelPayload)
              }
              width="13.75rem"
            >
              {riskLevelOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.displayText}
                </SelectItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Button variant="textColor" onClick={handleReset}>
          {t('resetCta', { ns: 'component' })}
        </Button>
      </Box>
    </Stack>
  )
}
