import { Box, SvgIcon, SxProps } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { Button } from 'components/Button/Button'
import { Dialog } from 'components/Dialog/Dialog'
import { useDownloadIoc } from 'hooks/useDownloadIoc'
import { useDownloadTool } from 'hooks/useDownloadTool'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import {
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  selectAvailableRAH,
} from 'store/slices/account'
import {
  selectIocDownloadingIdList,
  selectToolDownloadingIdList,
} from 'store/slices/download'
import { purchaseItem, selectPurchaseLoading } from 'store/slices/purchase'
import { pushAlertSnackbar } from 'store/slices/snackbar'
import { IIoc } from 'store/types/entityTypes/ioc'
import { ITool } from 'store/types/entityTypes/tool'
import { openMail } from 'utils/mail'

export const DownloadIocButton = ({ name, stixUrl, csvUrl, id }: IIoc) => {
  const { t } = useTranslation(['download', 'snackbar'])
  const csvRef = useRef<null | HTMLElement>(null)
  const iocDownloadingIdList = useAppSelector(selectIocDownloadingIdList)
  const [csvSource, setCsvSource] = useState<string>('')
  const { handleStixDownloadClick, handleCSVDownloadClick } =
    useDownloadIoc(setCsvSource)

  const ctaText = stixUrl
    ? t('stix', { ns: 'download' })
    : t('csv', { ns: 'download' })

  if (!stixUrl && !csvUrl) {
    return null
  }

  useEffect(() => {
    if (csvSource && csvRef.current) {
      csvRef.current.click()
      setCsvSource('')
    }
  }, [csvSource])

  return (
    <>
      <Button
        size="small"
        onClick={
          stixUrl
            ? () => handleStixDownloadClick({ name, stixUrl, id } as IIoc)
            : () => handleCSVDownloadClick({ name, csvUrl, id } as IIoc)
        }
        endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
        loading={iocDownloadingIdList.includes(id)}
        disabled={iocDownloadingIdList.includes(id)}
      >
        {ctaText}
      </Button>
      <CSVLink data={csvSource} filename={name}>
        <Box sx={{ display: 'none' }} ref={csvRef} />
      </CSVLink>
    </>
  )
}

interface IDownloadToolButtonProps extends ITool {
  size?: 'medium' | 'small'
  sx?: SxProps
  text?: string
}

export const DownloadToolButton = ({
  size = 'medium',
  sx = [],
  text,
  ...tool
}: IDownloadToolButtonProps) => {
  const { handleDownloadTool } = useDownloadTool()
  const toolDownloadingIdList = useAppSelector(selectToolDownloadingIdList)
  const { t } = useTranslation(['component'])

  return (
    <Button
      size={size}
      endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
      onClick={() => handleDownloadTool(tool)}
      loading={toolDownloadingIdList.includes(tool.id)}
      disabled={toolDownloadingIdList.includes(tool.id)}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      {text || t('downloadCta', { ns: 'component' })}
    </Button>
  )
}

interface IPurchaseToolButtonProps extends ITool {
  extraSuccessCallback?: () => void
  size?: 'medium' | 'small'
  sx?: SxProps
  text?: string
}

export const PurchaseToolButton = ({
  extraSuccessCallback,
  size = 'medium',
  sx = [],
  text,
  ...tool
}: IPurchaseToolButtonProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['component'])
  const purchaseLoading = useAppSelector(selectPurchaseLoading)
  const availableRAH = useAppSelector(selectAvailableRAH)
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { handleDownloadTool, isDownloading } = useDownloadTool()
  const { purchasedInformation } = tool

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    dispatch(fetchAccountSettings())

    return () => {
      dispatch(fetchAccountSettingsCancelled())
    }
  }, [])

  if (!purchasedInformation) {
    return null
  }

  const { credit, priceTagId } = purchasedInformation
  const isPurchasable = availableRAH >= credit

  const purchaseSuccessCallback = () => {
    if (extraSuccessCallback) {
      extraSuccessCallback()
    }
    handleDownloadTool(tool)
    dispatch(fetchAccountSettings()) // 更新 Available RAH 的最新狀態，來改變 UI
    handleDialogClose()
    setIsPurchasing(false)
  }

  const purchaseFailCallback = () => {
    dispatch(fetchAccountSettings()) // 更新 Available RAH 的最新狀態，來改變 UI
    dispatch(
      pushAlertSnackbar({
        text: t('purchase.purchaseFail', { ns: 'snackbar' }),
      })
    )
    handleDialogClose()
    setIsPurchasing(false)
  }

  const handlePurchaseItem = () => {
    setIsPurchasing(true)
    dispatch(
      purchaseItem({
        priceTagId,
        successCallback: purchaseSuccessCallback,
        failCallback: purchaseFailCallback,
      })
    )
  }

  const handleContactSales = () => {
    openMail()
    handleDialogClose()
  }

  return (
    <>
      <Button
        size={size}
        variant="contained"
        onClick={handleDialogOpen}
        endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
        loading={isPurchasing}
        disabled={purchaseLoading}
        sx={Array.isArray(sx) ? sx : [sx]}
      >
        {text ||
          t('rahPurchase.purchaseRahCta', { count: credit, ns: 'component' })}
      </Button>
      <Dialog
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        title={
          isPurchasable
            ? t('rahPurchase.enoughRahDialog.title', { ns: 'component' })
            : t('rahPurchase.notEnoughRahDialog.title', { ns: 'component' })
        }
        description={
          isPurchasable
            ? t('rahPurchase.enoughRahDialog.purchaseToolsDescription', {
                count: credit,
                ns: 'component',
              })
            : t('rahPurchase.notEnoughRahDialog.description', {
                ns: 'component',
              })
        }
        confirmButtonText={
          isPurchasable
            ? t('yesCta', { ns: 'component' })
            : t('sendEmailCta', { ns: 'component' })
        }
        handleConfirmButtonClick={
          isPurchasable ? handlePurchaseItem : handleContactSales
        }
        confirmButtonLoading={purchaseLoading || isDownloading}
        confirmButtonDisabled={purchaseLoading || isDownloading}
      />
    </>
  )
}
