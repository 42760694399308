import { Box } from '@mui/material'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { useSearchParams } from 'react-router-dom'

import { IDomainTab } from '../DomainComponents/domainContent.config'
import { IIPTab } from '../IPComponents/ipContent.config'

interface ICategoryTabsProps {
  categoryTab: IIPTab | IDomainTab
  tabs: IIPTab[] | IDomainTab[]
  searchParamKey: string
}

export const CategoryTabs = ({
  categoryTab,
  tabs,
  searchParamKey,
}: ICategoryTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSelect = (key: string) => {
    searchParams.set(searchParamKey, key)
    setSearchParams(searchParams)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, py: 1.5 }}>
      {tabs.map((tab) => (
        <TabWithCount
          key={tab.key}
          name={tab.name}
          handleSelect={() => handleSelect(tab.key)}
          count={tab.count}
          showCount={tab.showCount}
          selected={categoryTab.key === tab.key}
          isLoading={tab.isLoading}
          appendPlusSign={tab.appendPlusSign}
        />
      ))}
    </Box>
  )
}
