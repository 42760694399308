import { Box, SvgIcon, SvgIconProps, Typography, useTheme } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import {
  ADVERSARIES_ROUTE,
  DOCUMENTS_ROUTE,
  DOWNLOAD_ROUTE,
  MALWARES_ROUTE,
  REPORTS_ROUTE,
  SAMPLE_MANAGEMENT_ROUTE,
  SEARCH_ROUTE,
  THREAT_LANDSCAPE_ROUTE,
} from 'constants/routeParams'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import {
  checkIsMssp,
  checkIsMsspCancelled,
  selectIsMsspUser,
} from 'store/slices/account'
import {
  fetchReportReadableTypes,
  fetchReportReadableTypesCancelled,
  selectReportReadableTypes,
} from 'store/slices/report'

import { ReactComponent as AdversariesIcon } from './assets/adversaries.svg'
import { ReactComponent as CapabilityIcon } from './assets/capability.svg'
import { ReactComponent as DocumentIcon } from './assets/document.svg'
import { ReactComponent as DownloadsIcon } from './assets/downloads.svg'
import { ReactComponent as LogoIcon } from './assets/logo.svg'
import { ReactComponent as MalwareIcon } from './assets/malware.svg'
import { ReactComponent as ReportsIcon } from './assets/reports.svg'
// import { ReactComponent as RFIIcon } from './assets/rfi.svg'
import { ReactComponent as SampleManagementIcon } from './assets/sample-management.svg'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import { ReactComponent as ThreatLandscapeIcon } from './assets/threat-landscape.svg'

interface INavbarOptionButtonProps {
  icon?: ReactElement<SvgIconProps>
  sx?: SxProps<Theme>
  expandedText?: string
  isActive?: boolean
  callback?: () => void
  linkTo?: string
}

interface INavbarOptionConfig extends INavbarOptionButtonProps {
  activeKey?: string
  hide?: boolean
}

export const NavbarOptionButton = ({
  icon,
  sx = {},
  expandedText = '',
  isActive = false,
  callback,
  linkTo,
}: INavbarOptionButtonProps) => {
  const theme = useTheme()

  const handleClick = () => {
    if (callback) {
      callback()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          bgcolor: theme.colors.WHITE_20,
        },
      }}
      onClick={handleClick}
      to={linkTo || ''}
      component={Link}
    >
      <Box
        sx={{
          width: '2rem',
          height: theme.fixedConstants.FIXED_TOP_HEIGHT,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...sx,
        }}
      >
        {icon ? (
          <Box
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: isActive ? theme.colors.PRIMARY_60 : 'transparent',
              borderRadius: 1,
              border: isActive ? `1px solid ${theme.colors.WHITE_20}` : 'none',
            }}
          >
            {icon}
          </Box>
        ) : (
          <Box sx={{ width: '2rem', height: '2rem' }} />
        )}
      </Box>
      {/* Accordion 裡面第二層的字才會有 Active 效果，用沒有 icon 當作是第二層 */}
      {expandedText && (
        <Typography
          sx={{
            color:
              isActive && !icon ? theme.colors.PRIMARY_80 : theme.colors.WHITE,
            pl: 1,
            pr: 2,
          }}
          variant="textSmallImportant"
        >
          {expandedText}
        </Typography>
      )}
    </Box>
  )
}

export const useNavbarSubOptions = (): INavbarOptionConfig[] => {
  const { t } = useTranslation(['navbar'])
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isMsspUser = useAppSelector(selectIsMsspUser)
  const dispatch = useAppDispatch()

  const hideReportButton =
    Object.values(reportReadableTypes).every((readable) => !readable) &&
    !isMsspUser

  useEffect(() => {
    dispatch(checkIsMssp())
    dispatch(fetchReportReadableTypes())

    return () => {
      dispatch(checkIsMsspCancelled())
      dispatch(fetchReportReadableTypesCancelled())
    }
  }, [])

  return [
    {
      activeKey: `/${REPORTS_ROUTE}`,
      linkTo: `/${REPORTS_ROUTE}`,
      icon: <SvgIcon component={ReportsIcon} />,
      expandedText: t('reports', { ns: 'navbar' }),
      hide: hideReportButton,
    },
    {
      activeKey: `/${SEARCH_ROUTE}`,
      linkTo: `/${SEARCH_ROUTE}`,
      icon: (
        <SvgIcon
          sx={{ width: '1.5rem', height: '1.5rem' }}
          component={SearchIcon}
          inheritViewBox
        />
      ),
      expandedText: t('search', { ns: 'navbar' }),
    },
    {
      activeKey: `/${THREAT_LANDSCAPE_ROUTE}`,
      linkTo: `/${THREAT_LANDSCAPE_ROUTE}`,
      icon: <SvgIcon component={ThreatLandscapeIcon} />,
      expandedText: t('threatLandscape', { ns: 'navbar' }),
    },
    {
      activeKey: `/${ADVERSARIES_ROUTE}`,
      linkTo: `/${ADVERSARIES_ROUTE}`,
      icon: <SvgIcon component={AdversariesIcon} />,
      expandedText: t('adversaries', { ns: 'navbar' }),
    },
    {
      activeKey: `/${MALWARES_ROUTE}`,
      linkTo: `/${MALWARES_ROUTE}`,
      icon: <SvgIcon component={MalwareIcon} />,
      expandedText: t('malware', { ns: 'navbar' }),
    },
    {
      activeKey: `/${SAMPLE_MANAGEMENT_ROUTE}`,
      linkTo: `/${SAMPLE_MANAGEMENT_ROUTE}`,
      icon: <SvgIcon component={SampleManagementIcon} />,
      expandedText: t('sampleManagement', { ns: 'navbar' }),
    },
    {
      activeKey: '/capability',
      linkTo: '/capability',
      icon: <SvgIcon component={CapabilityIcon} />,
      expandedText: t('capabilities', { ns: 'navbar' }),
    },
    // {
    //   activeKey: '/rfi',
    //   linkTo: '/rfi',
    //   icon: <SvgIcon component={RFIIcon} />,
    //   expandedText: 'RFI',
    // },
    {
      activeKey: `/${DOWNLOAD_ROUTE}`,
      linkTo: `/${DOWNLOAD_ROUTE}`,
      icon: <SvgIcon component={DownloadsIcon} />,
      expandedText: t('downloads', { ns: 'navbar' }),
    },
    {
      activeKey: `/${DOCUMENTS_ROUTE}`,
      linkTo: `/${DOCUMENTS_ROUTE}`,
      icon: <SvgIcon component={DocumentIcon} />,
      expandedText: t('documents', { ns: 'navbar' }),
    },
  ]
}

export const LogoButton = () => {
  const navigate = useNavigate()

  const handleNavigateToHome = () => {
    navigate('/')
  }

  return (
    <NavbarOptionButton
      callback={handleNavigateToHome}
      sx={{ bgcolor: (theme) => theme.colors.PRIMARY }}
      icon={
        <SvgIcon
          component={LogoIcon}
          inheritViewBox
          sx={{ width: '2rem', height: '2rem' }}
        />
      }
    />
  )
}
