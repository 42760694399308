import {
  Box,
  Divider,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { Table } from 'components/Table/Table'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import { generateSkeletons } from 'utils/generateSkeletons'

const TableBodyRowLoading = () => {
  const theme = useTheme()
  return (
    <TableRow sx={{ bgcolor: theme.colors.BLACK_95 }}>
      <TableCell>
        <Skeleton type="body" width="2.25rem" />
      </TableCell>
      <TableCell>
        <Skeleton type="body" width="5rem" />
      </TableCell>
      <TableCell>
        <Skeleton type="body" width="100%" />
      </TableCell>
    </TableRow>
  )
}

export const TacticLoading = () => {
  const theme = useTheme()
  return (
    <Stack>
      <Stack sx={{ height: '12.6875rem', p: 4, gap: 2 }}>
        <Box
          sx={{ height: '2.6875rem', display: 'flex', alignItems: 'center' }}
        >
          <Skeleton type="title" width="26.5rem" />
        </Box>
        <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
        <Stack sx={{ gap: 2 }}>
          <Skeleton type="body" width="13.5rem" />
          <Skeleton type="body" width="20rem" />
          <Skeleton type="body" width="10.25rem" />
        </Stack>
      </Stack>
      <Stack sx={{ px: 4 }}>
        <Box
          sx={{ height: '2.6875rem', display: 'flex', alignItems: 'center' }}
        >
          <Skeleton type="title" width="7.5rem" />
        </Box>
        <TableContainer sx={{ overflow: 'initial' }}>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>
                  <Skeleton type="body" width="2.25rem" />
                </TableCell>
                <TableCell>
                  <Skeleton type="body" width="5rem" />
                </TableCell>
                <TableCell>
                  <Skeleton type="body" width="5rem" />
                </TableCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {generateSkeletons(5, <TableBodyRowLoading />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  )
}
