import { Stack, Typography, useTheme } from '@mui/material'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'

interface INoDataProps {
  text?: string
}

export const NoData = ({ text = '' }: INoDataProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Stack sx={{ pt: 12, gap: 2, alignItems: 'center' }}>
      <Typography variant="titleBig" sx={{ color: theme.colors.WHITE_60 }}>
        {text || t('emptyState.noRelatedResult', { ns: 'component' })}
      </Typography>
      <Image
        src={SmileTVImage}
        alt={t('emptyState.noRelatedResult', { ns: 'component' })}
        width={328}
        height={130}
      />
    </Stack>
  )
}
