type TIocBundleableType = 'CaseFile' | 'IoC::Package' | 'IoC::Collection'
type TIocType = 'Report IoC List' | 'Cyber Crime IoC List' | 'APT IoC List'

export type TIocSourceType = 'report' | 'cyber_crime' | 'apt'
export type TIocFormatType = 'csv' | 'stix'

export const iocSourceTypeMap: Record<TIocSourceType, TIocType> = {
  apt: 'APT IoC List',
  cyber_crime: 'Cyber Crime IoC List',
  report: 'Report IoC List',
}

export interface IIoc {
  id: string
  name: string
  iocBundleableType: TIocBundleableType
  iocBundleableId: string
  type: TIocType
  createdAt: number
  stixUrl?: string
  csvUrl?: string
}

export interface IIocAPIData {
  main_value: string
  name: string
  ioc_bundleable_type: TIocBundleableType
  ioc_bundleable_id: string
  type: TIocType
  created_at: number
  stix_url?: string
  csv_url?: string
}

// 若一個 ioc 若同時有 stix + csv，需要拆成兩個不同的檔案提供下載
export const mapAPIIoCsToState = (fromAPIIoCs: IIocAPIData[]): IIoc[] => {
  const formattedIocs: IIoc[] = fromAPIIoCs
    .map((fromAPIIoC) => {
      const formattedIoc: IIoc = {
        id: fromAPIIoC.main_value,
        name: fromAPIIoC.name,
        iocBundleableId: fromAPIIoC.ioc_bundleable_id,
        iocBundleableType: fromAPIIoC.ioc_bundleable_type,
        type: fromAPIIoC.type,
        createdAt: fromAPIIoC.created_at,
      }

      if (fromAPIIoC.stix_url && fromAPIIoC.csv_url) {
        return [
          {
            ...formattedIoc,
            id: `${fromAPIIoC.main_value}_stix`,
            stixUrl: fromAPIIoC.stix_url,
          },
          {
            ...formattedIoc,
            id: `${fromAPIIoC.main_value}_csv`,
            csvUrl: fromAPIIoC.csv_url,
          },
        ]
      }

      if (fromAPIIoC.stix_url) {
        formattedIoc.stixUrl = fromAPIIoC.stix_url
      }

      if (fromAPIIoC.csv_url) {
        formattedIoc.csvUrl = fromAPIIoC.csv_url
      }

      return formattedIoc
    })
    .flat()

  return formattedIocs
}
