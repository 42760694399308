import { Box, Stack } from '@mui/material'
import { OutlineTabWithCount } from 'components/Tab/OutlineTabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchSampleOSINTs,
  fetchSampleOSINTsCancelled,
} from 'store/slices/sampleDetail'

import { useCommunityTabs } from './config'

interface ICommunityContentProps {
  searchText: string
}

export const CommunityContent = ({ searchText }: ICommunityContentProps) => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const communityTabs = useCommunityTabs()

  const communityTabKey =
    searchParams.get(SearchParamKey.SAMPLE_COMMUNITY_TAB) ||
    communityTabs[0].key

  const handleCommunityTabClick = (key: string) => {
    searchParams.set(SearchParamKey.SAMPLE_COMMUNITY_TAB, key)
    setSearchParams(searchParams)
  }

  const commentableType = 'Blob'
  const commentableId = searchText

  useEffect(() => {
    dispatch(
      setupCommentableIdAndType({
        commentableType,
        commentableId,
      })
    )
    dispatch(
      fetchComments({
        commentableType,
        commentableId,
      })
    )
    dispatch(fetchSampleOSINTs(searchText))

    return () => {
      dispatch(fetchSampleOSINTsCancelled())
      dispatch(resetComments())
    }
  }, [searchText])

  const BottomComponent = communityTabs.find(
    (tab) => tab.key === communityTabKey
  )?.component

  return (
    <Stack sx={{ pt: 0.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 4, gap: 2 }}>
        {communityTabs.map((tab) => (
          <OutlineTabWithCount
            key={tab.key}
            name={tab.name}
            count={tab.count}
            showCount={tab.showCount}
            handleSelect={() => handleCommunityTabClick(tab.key)}
            selected={tab.key === communityTabKey}
            isLoading={tab.isLoading}
          />
        ))}
      </Box>
      {BottomComponent && <BottomComponent searchText={searchText} />}
    </Stack>
  )
}
