import { Box, Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { Switch } from 'components/Switch/Switch'
import { PAGE_TITLE_CAPABILITIES } from 'constants/pageTitle'
import { CAPABILITY_ROUTE, TACTIC_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  fetchTactics,
  selectTactics,
  selectTacticsLoading,
} from 'store/slices/capability'

import { SubTechnique } from './CapabilityComponents/SubTechnique'
import { TechniqueAccordion } from './CapabilityComponents/TechniqueAccordion'
import { CapabilityEntryLoading } from './CapabilityEntryLoading'

export const CapabilityEntryPage = () => {
  const { t } = useTranslation(['capability'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [showSubTechniques, setShowSubTechniques] = useState<string[]>([])
  const tactics = useAppSelector(selectTactics)
  const tacticsLoading = useAppSelector(selectTacticsLoading)
  const [lastClickId, setLastClickId] = useState<string>('')

  const allTechniquesId = tactics
    .map((tactic) =>
      tactic.techniques.map((technique) => `${tactic.id}-${technique.id}`)
    )
    .flat()

  const handleShowSubTechniques = () => {
    if (showSubTechniques.length === allTechniquesId.length) {
      setShowSubTechniques([])
    } else {
      setShowSubTechniques(allTechniquesId)
    }
  }

  useEffect(() => {
    dispatch(fetchTactics())
  }, [])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_CAPABILITIES}</title>
      </Helmet>

      <Box
        sx={{
          p: 1,
          height: '100%',
          width: '100%',
          overflow: 'auto',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <PageSection
          title={t('capability.title', { ns: 'capability' })}
          sx={{ bgcolor: theme.colors.BLACK_90 }}
        >
          <Stack sx={{ height: '100%', bgcolor: theme.colors.BLACK_90 }}>
            <Box
              sx={{
                px: 4,
                py: 1,
                display: 'flex',
                gap: 4,
                alignItems: 'center',
                borderBottom: `1px solid ${theme.colors.WHITE_20}`,
              }}
            >
              <Typography
                variant="titleSmall"
                sx={{ color: theme.colors.WHITE }}
              >
                {t('capability.showSwitch', { ns: 'capability' })}
              </Typography>
              <Switch
                checked={showSubTechniques.length === allTechniquesId.length}
                onChange={handleShowSubTechniques}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
            <Box
              sx={{
                px: 1,
                py: 2,
                width: '100%',
                overflow: 'auto',
                flexGrow: 1,
                bgcolor: theme.colors.BLACK_90,
              }}
            >
              {tacticsLoading ? (
                <CapabilityEntryLoading />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  {tactics.map((tactic) => (
                    <Box
                      key={tactic.id}
                      sx={{ minWidth: '12.5rem', width: '12.5rem' }}
                    >
                      <Stack
                        sx={{
                          p: 2,
                          gap: 1,
                          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: theme.colors.PRIMARY_20,
                          },
                          '&:active': {
                            bgcolor: theme.colors.PRIMARY_40,
                          },
                        }}
                        to={`/${CAPABILITY_ROUTE}/${TACTIC_ROUTE}?${SearchParamKey.CAPABILITY_TACTIC_ID}=${tactic.id}`}
                        component={Link}
                      >
                        <HighlightLabel
                          text={tactic.id}
                          color={theme.colors.PRIMARY}
                        />
                        <Typography
                          variant="title"
                          sx={{ color: theme.colors.PRIMARY }}
                        >
                          {tactic.name}
                        </Typography>
                        {tactic.techniques.length > 0 && (
                          <Typography
                            variant="assistiveText"
                            sx={{ color: theme.colors.WHITE_60 }}
                          >
                            {t('capability.techniqueCount', {
                              ns: 'capability',
                              count: tactic.techniques.length,
                            })}
                          </Typography>
                        )}
                      </Stack>
                      <Stack sx={{ mt: 1 }}>
                        {tactic.techniques.map((technique, index) => (
                          <TechniqueAccordion
                            key={technique.id}
                            index={index}
                            count={tactic.techniques.length}
                            lastClickId={lastClickId}
                            setLastClickId={setLastClickId}
                            showSubTechniques={showSubTechniques}
                            setShowSubTechniques={setShowSubTechniques}
                            tacticId={tactic.id}
                            {...technique}
                          >
                            {technique.subTechniques.length > 0 &&
                              technique.subTechniques.map((subTechnique) => (
                                <SubTechnique
                                  key={subTechnique.id}
                                  lastClickId={lastClickId}
                                  tacticId={tactic.id}
                                  techniqueId={technique.id}
                                  {...subTechnique}
                                />
                              ))}
                          </TechniqueAccordion>
                        ))}
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Stack>
        </PageSection>
      </Box>
    </>
  )
}
