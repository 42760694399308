import { Box } from '@mui/material'
import { DomainPreview } from 'components/Domain/DomainPreivew'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { OneLineLoading } from 'components/Loading/OneLineLoading'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchDomainsData,
  fetchSearchDomainsDataCancelled,
  resetSearchDomainsData,
  selectSearchDomains,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'domain-scrollable-container'

export const DomainContent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['search'])
  const [searchParams] = useSearchParams()
  const domains = useAppSelector(selectSearchDomains)
  const domainsCurrentCount = domains.data.length
  const isDomainsLoading = domains.isDataLoading
  const hasMoreDomains = domains.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchDomains = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchDomainsData({
          searchText,
          offset: refetch ? 0 : domainsCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchDomains(true)
    return () => {
      dispatch(fetchSearchDomainsDataCancelled())
      dispatch(resetSearchDomainsData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreDomains && domainsCurrentCount > 0) {
      fetchDomains()
    }
  }, [inView, hasMoreDomains, domainsCurrentCount])

  if (isDomainsLoading && domainsCurrentCount === 0) {
    return <OneLineLoading />
  }

  if (domainsCurrentCount === 0 && !isDomainsLoading) {
    return (
      <NoResultContent
        text={t('downloadList.emptyState.invalidDomain', { ns: 'search' })}
      />
    )
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      {domains.data.map((domain) => (
        <DomainPreview key={domain.domain} {...domain} />
      ))}
      {hasMoreDomains && <CenterCircularProgress scrollRef={ref} />}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
