export type TRiskLevel = 'high' | 'medium' | 'low'

export interface IRelatedRecordAPIData {
  name: string
  first_seen: string
  last_seen: string
}

export interface IRelatedRecord {
  name: string
  firstSeen: string
  lastSeen: string
}

export const mapAPIRelatedRecordToState = (
  fromAPIRelatedRecords: IRelatedRecordAPIData[]
): IRelatedRecord[] =>
  fromAPIRelatedRecords.map((fromAPIRelatedRecord) => ({
    name: fromAPIRelatedRecord.name,
    firstSeen: fromAPIRelatedRecord.first_seen,
    lastSeen: fromAPIRelatedRecord.last_seen,
  }))

export type TAPIEntity =
  | 'Blobs'
  | 'CaseFile'
  | 'Network::IP'
  | 'Network::Domain'
  | 'Adversary'
  | 'Program::Family'

export type TEntity =
  | 'sample'
  | 'report'
  | 'ip'
  | 'domain'
  | 'adversary'
  | 'malware'

export const entityMap: Record<TAPIEntity, TEntity> = {
  Blobs: 'sample',
  CaseFile: 'report',
  'Network::IP': 'ip',
  'Network::Domain': 'domain',
  Adversary: 'adversary',
  'Program::Family': 'malware',
}
