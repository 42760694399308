import { Box, Divider, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { IPMReportDetail } from 'store/types/entityTypes/report'

interface IProductDrawerProps {
  selectedCVE: string
  pmReportDetail: IPMReportDetail[]
  productDrawerOpen: boolean
  onClose: () => void
}
export const ProductDrawer = ({
  selectedCVE,
  pmReportDetail,
  productDrawerOpen,
  onClose,
}: IProductDrawerProps) => {
  const theme = useTheme()
  const selectedPMReportDetail = pmReportDetail.find(
    ({ id }) => id === selectedCVE
  )

  return (
    <Drawer
      open={productDrawerOpen}
      onClose={onClose}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: theme.colors.MASK,
        },
      }}
    >
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {selectedCVE}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Box sx={{ py: 2 }}>
        {selectedPMReportDetail &&
          selectedPMReportDetail.productList &&
          selectedPMReportDetail.productList.map((product) => (
            <Typography
              key={product}
              variant="body"
              sx={{ color: theme.colors.WHITE }}
              component="p"
            >
              {product}
            </Typography>
          ))}
      </Box>
    </Drawer>
  )
}
