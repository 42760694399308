import type { Action } from '@reduxjs/toolkit'
import {
  getIPAnalysisStatusById,
  getIPDetailById,
  getIPDNSRecordsById,
  getIPOSINTById,
  getIPRelationSamplesById,
  getIPWhoisById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  delay,
  filter,
  iif,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchIPDetail,
  fetchIPDetailCancelled,
  fetchIPDetailFulfilled,
  fetchIPDetailRejected,
  fetchIPDNSRecords,
  fetchIPDNSRecordsCancelled,
  fetchIPDNSRecordsFulfilled,
  fetchIPDNSRecordsRejected,
  fetchIPOSINT,
  fetchIPOSINTCancelled,
  fetchIPOSINTFulfilled,
  fetchIPOSINTRejected,
  fetchIPRelationSamples,
  fetchIPRelationSamplesCancelled,
  fetchIPRelationSamplesFulfilled,
  fetchIPRelationSamplesRejected,
  fetchIPStatus,
  fetchIPStatusCancelled,
  fetchIPStatusFulfilled,
  fetchIPStatusRejected,
  fetchIPWhois,
  fetchIPWhoisCancelled,
  fetchIPWhoisFulfilled,
  fetchIPWhoisRejected,
} from 'store/slices/ip'
import { mapAPIIPDetailToState } from 'store/types/entityTypes/ip'
import { mapAPIOSINTsToState } from 'store/types/entityTypes/osint'
import { mapAPISamplesToState } from 'store/types/entityTypes/sample'
import { IFetchDNSRecordsResponse } from 'store/types/slicesTypes/dns'
import {
  IFetchIPDetailResponse,
  IFetchIPRelationSamplesResponse,
  IFetchIPStatusResponse,
} from 'store/types/slicesTypes/ipDetail'
import { IFetchOSINTResponse } from 'store/types/slicesTypes/osint'
import { IFetchWhoisResponse } from 'store/types/slicesTypes/whois'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const ipStatusEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPStatus.match),
    switchMap((action) =>
      fetchData<IFetchIPDetailResponse>({
        url: getIPAnalysisStatusById(action.payload.ip),
      }).pipe(
        delay(1000),
        switchMap((response: AjaxResponse<IFetchIPStatusResponse>) =>
          iif(
            () => response.response.analysis_status,
            concat(
              of(fetchIPStatusFulfilled()),
              of(fetchIPDetail(action.payload))
            ),
            of(fetchIPStatus(action.payload))
          )
        ),
        takeUntil(action$.pipe(filter(fetchIPStatusCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchIPStatusRejected(error))
          )
        )
      )
    )
  )

const ipDetailEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPDetail.match),
    switchMap((action) =>
      fetchData<IFetchIPDetailResponse>({
        url: getIPDetailById(action.payload.ip),
      }).pipe(
        map((response: AjaxResponse<IFetchIPDetailResponse>) => {
          if (response.response.analysis_status) {
            return fetchIPDetailFulfilled(
              mapAPIIPDetailToState(response.response)
            )
          }
          return fetchIPStatus(action.payload)
        }),
        takeUntil(action$.pipe(filter(fetchIPDetailCancelled.match))),
        catchError((error: AjaxError) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchIPDetailRejected(error)).pipe(
              tap(() => {
                if (error.status === 402) {
                  action.payload.outOfAAPCallback()
                }
              })
            )
          )
        )
      )
    )
  )

const ipOSINTEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPOSINT.match),
    switchMap((action) =>
      fetchData<IFetchOSINTResponse>({
        url: getIPOSINTById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchOSINTResponse>) =>
          fetchIPOSINTFulfilled(mapAPIOSINTsToState(response.response.osint))
        ),
        takeUntil(action$.pipe(filter(fetchIPOSINTCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchIPOSINTRejected(error)))
        )
      )
    )
  )

const ipDNSRecordEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPDNSRecords.match),
    switchMap((action) =>
      fetchData<IFetchDNSRecordsResponse>({
        url: `${getIPDNSRecordsById(action.payload.searchText)}?offset=${
          action.payload.offset
        }`,
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchDNSRecordsResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchDNSRecordsResponse>) =>
          fetchIPDNSRecordsFulfilled(response.response.records || [])
        ),
        takeUntil(action$.pipe(filter(fetchIPDNSRecordsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchIPDNSRecordsRejected(error))
          )
        )
      )
    )
  )

const ipWhoisEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPWhois.match),
    switchMap((action) =>
      fetchData<IFetchWhoisResponse>({
        url: getIPWhoisById(action.payload),
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchWhoisResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchWhoisResponse>) =>
          fetchIPWhoisFulfilled(response.response.whois || '')
        ),
        takeUntil(action$.pipe(filter(fetchIPWhoisCancelled.match))),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(fetchIPWhoisRejected(error)))
        )
      )
    )
  )

const ipRelationSampleEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchIPRelationSamples.match),
    switchMap((action) =>
      fetchData<IFetchIPRelationSamplesResponse>({
        url: `${getIPRelationSamplesById(
          action.payload.ip
        )}?relatable_type=samples&offset=${action.payload.offset}`,
      }).pipe(
        filter(
          (response: AjaxResponse<IFetchIPRelationSamplesResponse>) =>
            response.response.analysis_status
        ),
        map((response: AjaxResponse<IFetchIPRelationSamplesResponse>) =>
          fetchIPRelationSamplesFulfilled(
            mapAPISamplesToState(response.response.samples)
          )
        ),
        takeUntil(action$.pipe(filter(fetchIPRelationSamplesCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchIPRelationSamplesRejected(error))
          )
        )
      )
    )
  )

export const ipEpic = combineEpics(
  ipStatusEpic,
  ipDetailEpic,
  ipOSINTEpic,
  ipDNSRecordEpic,
  ipWhoisEpic,
  ipRelationSampleEpic
)
