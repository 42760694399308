import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as LinkIcon } from 'assets/basicIcons/link.svg'
import TwitterImage from 'assets/osintSourceImages/twitter.svg'
import { DateLabel } from 'components/Label/Label'
import { ExternalLink } from 'components/Link/Link'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { IOSINT } from 'store/types/entityTypes/osint'

export const OSINTPreview = ({
  source,
  createdAt,
  content,
  externalLink,
}: IOSINT) => {
  const theme = useTheme()
  const { getDateTimeStringByTimestampsInSeconds } = useDateTime()

  return (
    <ExternalLink href={externalLink} sx={{ textDecoration: 'none' }}>
      <Box
        sx={{
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          p: 2,
          cursor: 'pointer',
          '&:hover': { bgcolor: theme.colors.BLACK_60 },
          '&:active': { bgcolor: theme.colors.BLACK_95 },
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flexShrink: 0 }}>
            {source === 'Twitter' ? (
              <Image width={64} height={64} src={TwitterImage} alt="Twitter" />
            ) : (
              'Need Backup Image'
            )}
          </Box>
          <Stack sx={{ gap: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <DateLabel
                text={getDateTimeStringByTimestampsInSeconds(createdAt)}
              />
              <SvgIcon
                component={LinkIcon}
                inheritViewBox
                sx={{
                  width: '1rem',
                  height: '1rem',
                  path: {
                    fill: theme.colors.TEXT_LINK_NORMAL,
                  },
                }}
              />
            </Box>
            <Typography
              variant="bodySmall"
              sx={{
                color: theme.colors.WHITE,
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {content}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </ExternalLink>
  )
}
