import { Box } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

export const RELATED_ADVERSARIES_LOADING_TEST_ID = 'related-adversaries-loading'

const LoadingBlock = () => (
  <Box
    sx={{
      width: '8rem',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Skeleton type="image" width="6.25rem" height="6.25rem" />
  </Box>
)

export const Loading = () => (
  <Box
    data-testid={RELATED_ADVERSARIES_LOADING_TEST_ID}
    sx={{ display: 'flex', gap: 2, px: 4, py: 2 }}
  >
    {generateSkeletons(5, <LoadingBlock />)}
  </Box>
)
