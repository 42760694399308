import { Box, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { Button } from 'components/Button/Button'
import { TwoToneLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import { PageSection } from 'components/PageSection/PageSection'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTlpColorMap } from 'hooks/useTlpColorMap'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'

import { DefaultTLPSettingLoading } from './DefaultTLPSettingLoading'
import { EditTLPDrawer } from './EditTLPDrawer'

export const DefaultTLPSetting = ({ tlp, shareTargets }: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account', 'component'])
  const [searchParams, setSearchParams] = useSearchParams()
  const tlpColorMap = useTlpColorMap()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )
  const isEditTlpDrawerOpen = searchParams.get(
    SearchParamKey.ACCOUNT_IS_EDIT_TLP_DRAWER_OPEN
  )
  const [drawerOpen, setDrawerOpen] = useState<boolean>(
    Boolean(isEditTlpDrawerOpen)
  )

  const toggleDrawer = () => {
    setDrawerOpen((open) => !open)
  }

  useEffect(() => {
    if (isEditTlpDrawerOpen) {
      searchParams.delete(SearchParamKey.ACCOUNT_IS_EDIT_TLP_DRAWER_OPEN)
      setSearchParams(searchParams)
    }
  }, [])

  return (
    <PageSection
      title={t('defaultTlpSetting.title', { ns: 'account' })}
      titleAction={
        <Button
          size="small"
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          onClick={toggleDrawer}
        >
          {t('defaultTlpSetting.editCta', { ns: 'account' })}
        </Button>
      }
    >
      <Box>
        {accountSettingsLoading ? (
          <DefaultTLPSettingLoading />
        ) : (
          <Stack sx={{ gap: 1, px: 4, py: 2 }}>
            {tlp && (
              <TwoToneLabel
                keyText={t('defaultTlpSetting.tlpLabel', { ns: 'account' })}
                valueText={tlp.charAt(0).toUpperCase() + tlp.slice(1)}
                mainColor={tlpColorMap[tlp]}
                textColor={theme.colors.BLACK_90}
              />
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <LabelList
                keyText={t('tlpDrawer.shareTo', { ns: 'component' })}
                labels={shareTargets.map((target) => target.name)}
              />
            </Box>
          </Stack>
        )}
        <EditTLPDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      </Box>
    </PageSection>
  )
}
