import { Box, Typography } from '@mui/material'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'

import { LoginStatus } from '../config'
import MailSendImage from './mail-send.svg'

export const LinkSendNotice = () => {
  const { t } = useTranslation(['login'])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20rem',
      }}
      data-testid={LoginStatus.RESET_PASSWORD_LINK_SEND}
    >
      <Typography variant="body" sx={{ color: (theme) => theme.colors.WHITE }}>
        {t('linkSendNotice', { ns: 'login' })}
      </Typography>
      <Image width={150} height={150} showLoading src={MailSendImage} />
    </Box>
  )
}
