import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { BlockSubTitle, BlockTitle } from 'components/BlockTitle/BlockTitle'
import { LinkButton } from 'components/Button/Button'
import { REPORTS_ROUTE } from 'constants/routeParams'
import { useReportListInfo } from 'hooks/useReportListInfo'
import { useTranslation } from 'react-i18next'
import { reportTypeRouteMap, TReportType } from 'store/types/entityTypes/report'

interface IReportListContent<T> {
  info: string
  cta: string
  type: T
}

export interface IReportListInfoProps {
  type: Exclude<TReportType, 'customReport'>
  otherReportsInfo?: IReportListContent<Exclude<TReportType, 'customReport'>>[]
}

export const ReportListInfo = ({
  type,
  otherReportsInfo,
}: IReportListInfoProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['report'])
  const reportListInfo = useReportListInfo()

  const getReportLink = (otherInfoType: Exclude<TReportType, 'customReport'>) =>
    `/${REPORTS_ROUTE}/${reportListInfo[type]?.reportCategoryRoute}/${reportTypeRouteMap[otherInfoType]}`

  return (
    <Stack
      sx={{
        width: '20rem',
        [theme.breakpoints.up('desktop')]: {
          width: '26.25rem',
        },
        [theme.breakpoints.up('xl')]: {
          width: '32.5rem',
        },
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${reportListInfo[type]?.backgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <BlockTitle title={reportListInfo[type]?.title} />
      <Stack
        sx={{
          justifyContent: 'space-between',
          gap: 4,
          flexBasis: 0,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Box sx={{ px: 4, pt: 1 }}>
          <Typography variant="bodySmall">
            {reportListInfo[type]?.digest}
          </Typography>
        </Box>
        {otherReportsInfo && otherReportsInfo.length !== 0 && (
          <Box>
            <BlockSubTitle title={t('howAbout.title', { ns: 'report' })} />
            {otherReportsInfo.map((report, index) => (
              <Box key={report.info} sx={{ px: 4 }}>
                <Box sx={{ pt: 3 }}>
                  <Typography variant="bodySmall">{report.info}</Typography>
                </Box>
                <LinkButton
                  sx={{ my: 4, width: '100%' }}
                  endIcon={
                    <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                  }
                  to={getReportLink(report.type)}
                >
                  {report.cta}
                </LinkButton>
                {index === 0 && (
                  <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
                )}
              </Box>
            ))}
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
