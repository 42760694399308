import { Box, Stack } from '@mui/material'
import EulaImage from 'assets/documentImages/eula.jpg'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H3Title } from 'components/Documents/H3Title'
import { DateLabel } from 'components/Label/Label'
import {
  NumberListItem,
  OrderedList,
  SubNumberListItem,
} from 'components/List/List'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_EULA_ENGLISH } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'

export const EulaEnPage = () => {
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_EULA_ENGLISH}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title title="End User License Agreement ThreatVision" />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.EULA_DOCUMENT_TIMESTAMP
              )}
            />
            <Image src={EulaImage} alt="eula" height="25rem" fit="cover" />
            <H3Title title="IMPORTANT:" />
            <Description>
              Please read the terms and conditions set out below carefully.
              THROUGH ACCESSING OR USING THE PRODUCT YOU ARE EXPRESSING YOUR
              CONSENT TO THESE TERMS AND CONDITIONS OF THE AGREEMENT. If You do
              not agree to all of the terms and conditions of this Agreement,
              please do not access or use the Product.
            </Description>
            <H3Title title="End User License Agreement" />
            <Description>
              Under the terms and conditions of this End User License Agreement
              (hereinafter referred to as &quot;the Agreement&quot;) entered
              into by and between Team T5 Inc., a corporation organized and
              existing under the laws of the Taiwan (R.O.C.), with its principal
              place of business at 15F., No. 46, LN. 11, Guangfu N. Rd.,
              Songshan Dist., Taipei City 10560, Taiwan (R.O.C.) (hereinafter
              referred to as &quot;TeamT5&quot; or &quot;the Provider&quot;) and
              you, a natural person or legal entity (hereinafter referred to as
              &quot;You&quot; or &quot;the End User&quot;).
            </Description>
            <Description>
              Please note that THIS IS AN AGREEMENT ON LICENSE TO THE END USER
              AND NOT AN AGREEMENT FOR SALE. The Provider continues to own the
              Product, the copy of the Product, and the physical media contained
              the Product and any other copies that the End User is authorized
              to make pursuant to this Agreement.
            </Description>
            <Description>
              YOU AGREE THAT YOUR USE OF THE PRODUCT ACKNOWLEDGES THAT YOU HAVE
              READ THIS AGREEMENT, UNDERSTAND IT AND AGREE TO BE BOUND BY ITS
              TERMS AND CONDITIONS.
            </Description>
            <OrderedList>
              <NumberListItem>
                Definition.
                <OrderedList>
                  <SubNumberListItem>
                    Affiliates. It refers to the holding companies of a legal
                    entity, its subsidiaries, and the subsidiaries of any of its
                    holding companies, as well as entities controlled by its
                    holding companies.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    Product. As used in this Agreement the term
                    &quot;Product&quot; means:
                    <OrderedList>
                      <SubNumberListItem level={3}>
                        the research on Advanced Persistent Threat
                        (&quot;APT&quot;) activities, Open Source Intelligence
                        analysis and other cyber threat intelligence accompanied
                        by this Agreement, and all related programs, platforms,
                        or tools thereof prepared and provided by TeamT5 to the
                        Subscriber according to the Subscription Contract;
                      </SubNumberListItem>
                      <SubNumberListItem level={3}>
                        copies of the Product, modified versions of the Product
                        and updates of Product, if any, licensed to the
                        Subscriber by the Provider pursuant to this Agreement.
                      </SubNumberListItem>
                    </OrderedList>
                  </SubNumberListItem>
                  <SubNumberListItem>
                    Subscriber. The Subscriber refers to the natural person or
                    legal entity taking out the subscription to the Product.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    Subscription Contract. A printed form or other written
                    communication pursuant to which the Subscriber acquires one
                    or more licenses for the Product concurrently or prior to
                    the effectiveness of this Agreement.
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                License. Subject to the condition that You have agreed to comply
                with all the terms and conditions stipulated herein, the
                Provider shall grant You the following rights (hereinafter
                referred to as &quot;License&quot;):
                <OrderedList>
                  <SubNumberListItem>
                    Right to use. Unless otherwise stated, You will have the
                    non-exclusive, non-transferable and non-sublicensed right to
                    use and store the Product.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    Limit of License. The number of licenses and the right to
                    use the Product shall be bound by the licenses granted in
                    the Subscription Contract. You may use the Product that has
                    been authorized by and only to the extent in accordance the
                    limitation arising from the Subscription Contract granted by
                    Provider.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    Termination of the License. The License shall terminate
                    automatically at the end of the period for which granted
                    stipulated in the Subscription Contract, if any. If You fail
                    to comply with any of the provisions of this Agreement, the
                    Provider shall be entitled to terminate the Agreement,
                    without prejudice to any entitlement or legal remedy open to
                    the Provider in such eventualities. In the event of
                    termination of the License, You must immediately cease to
                    access or use the Product. Upon termination of the License,
                    the Provider shall be also entitled to cancel the End
                    User&apos;s entitlement to use the functions of the Product.
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                Data collection and internet connection. For the purpose of the
                Product and the Agreement, it is necessary to collect, process,
                store and transfer data enabling the Provider to identify
                Subscriber or You and to perform the Agreement. You hereby
                acknowledge that for the function and purpose of this Product it
                is necessary for Subscriber&apos;s or your data to be
                transferred, during communication between the Product and/or the
                Provider&apos;s computer systems to ensure authorization to use
                the Product.
              </NumberListItem>
              <NumberListItem>
                End User&apos;s rights. You must exercise End User&apos;s rights
                in person or via your employees. You are only entitled to use
                the Product for which You have obtained a License.
              </NumberListItem>
              <NumberListItem>
                Restrictions to rights. You may not copy, distribute, extract
                components or make derivative works of the Product. When using
                the Product, You are required to comply with the following
                restrictions:
                <OrderedList>
                  <SubNumberListItem>
                    You may use the Product for internal reference only and
                    shall not disclose any part of the Product to the public
                    except for a limited reference. A limited reference refers
                    that You may quote from or apply only a part of the Product
                    in the documents which are prepared for Your clients or
                    customers, and You shall indicate the name of TeamT5 as the
                    source of the reference.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    You may not use, modify, translate or reproduce the Product
                    or transfer rights to use the Product or copies of the
                    Product in any manner other than as provided for in this
                    Agreement.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    You may not transfer, sell, sub-license, lease, lend or
                    borrow the Product.
                  </SubNumberListItem>
                  <SubNumberListItem>
                    You agree that You will only use the Product in a manner
                    that complies with all applicable laws in the jurisdiction
                    in which You use the Product, including, but not limited to,
                    applicable restrictions concerning copyright and other
                    intellectual property rights.
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem>
                Copyright. The Product and all rights, without limitation
                including proprietary rights and intellectual property rights
                thereto are owned by TeamT5 and/or its licensors. They are
                protected by international treaty provisions and by all other
                applicable national laws of the country in which the Product is
                being used. The structure, organization and code of the Product
                are the valuable trade secrets and confidential information of
                TeamT5 and/or its licensors. You must not copy the Product,
                except as agreed by TeamT5. Any copies which You are permitted
                to make pursuant to this Agreement must contain the same
                copyright and other proprietary notices that appear on the
                Product. If You reverse engineer, reverse compile, disassemble
                or otherwise attempt to discover the source code of the Product,
                in breach of the provisions of this Agreement, You hereby agree
                that any information thereby obtained shall automatically and
                irrevocably be deemed to be transferred to and owned by the
                Provider in full, from the moment such information comes into
                being, notwithstanding the Provider&apos;s rights in relation to
                breach of this Agreement.
              </NumberListItem>
              <NumberListItem>
                Reservation of rights. The Provider hereby reserves all rights
                to the Product, with the exception of rights expressly granted
                under the terms of this Agreement to You as the End User of the
                Product.
              </NumberListItem>
              <NumberListItem>
                Multiple language versions, multiple copies. In the event that
                the Product supports multiple platforms or languages, or if You
                receive multiple copies of the Product, You may only use the
                Product for the number of computer systems and for the versions
                for which You obtained a License. You may not sell, rent, lease,
                sub-license, lend or transfer versions or copies of the Product
                which You do not use.
              </NumberListItem>
              <NumberListItem>
                END USER DECLARATIONS. AS THE END USER YOU ACKNOWLEDGE THAT THE
                PRODUCT IS PROVIDED &quot;AS IS&quot;, WITHOUT WARRANTY OF ANY
                KIND, EXPRESS OR IMPLIED, AND TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW. NEITHER THE PROVIDER, ITS LICENSORS OR
                AFFILIATES, NOR THE COPYRIGHT HOLDERS MAKE ANY REPRESENTATIONS
                OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                THE WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                PURPOSE OR THAT THE PRODUCT WILL NOT INFRINGE ANY THIRD-PARTY
                PATENTS, COPYRIGHTS, TRADEMARKS OR OTHER RIGHTS. THERE IS NO
                WARRANTY BY THE PROVIDER OR BY ANY OTHER PARTY THAT THE
                FUNCTIONS CONTAINED IN THE PRODUCT WILL MEET YOUR REQUIREMENTS
                OR THAT THE OPERATION OF THE PRODUCT WILL BE UNINTERRUPTED OR
                ERROR-FREE. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR THE
                SELECTION OF THE PRODUCT TO ACHIEVE YOUR INTENDED RESULTS AND
                FOR THE ACCESS, USE AND RESULTS OBTAINED FROM IT.
              </NumberListItem>
              <NumberListItem tensDigits>
                Product support.
                <OrderedList>
                  <SubNumberListItem level={2}>
                    TeamT5 provides End User with access to phone and email
                    support relating to start, installation, setup, technical
                    troubleshooting and general questions that End User may have
                    about the Product. End User&apos;s support inquiries will be
                    accepted and answered during normal business hours.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    Unless explicitly stated otherwise, TeamT5 or third parties
                    commissioned by TeamT5 may provide Product support at their
                    own discretion, without any guarantees or declarations.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    TeamT5 and/or third parties commissioned by TeamT5 reserve
                    the right to decide that answering the inquiries is beyond
                    the scope of Product support. TeamT5 and/or third parties
                    commissioned by TeamT5 reserves the right to refuse, suspend
                    or terminate the provision of Product support at its own
                    discretion.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    License information and other data in compliance with
                    Privacy Policy may be required for the purpose of Product
                    support.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    TeamT5 may provide End User, from time to time, with Updates
                    of the Product, which may include minor functionality
                    enhancements, bug fixes or performance or error corrections,
                    and maintenance releases, as scheduled by Provider in its
                    sole discretion.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    TeamT5 and/or third parties commissioned by TeamT5 will not
                    bear liability for damage or loss of data, property, or
                    hardware or loss of profits due to the provision of Product
                    support.
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
              <NumberListItem tensDigits>
                No other obligations. This Agreement creates no obligations on
                the part of TeamT5 and/or third parties commissioned by TeamT5
                other than as specifically set forth herein.
              </NumberListItem>
              <NumberListItem tensDigits>
                LIMITATION OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, IN NO EVENT SHALL THE PROVIDER, ITS EMPLOYEES OR
                LICENSORS BE LIABLE FOR ANY LOST PROFITS, REVENUE, SALES, DATA
                OR COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
                PROPERTY DAMAGE, PERSONAL INJURY, INTERRUPTION OF BUSINESS, LOSS
                OF BUSINESS INFORMATION OR FOR ANY SPECIAL, DIRECT, INDIRECT,
                INCIDENTAL, ECONOMIC, COVER, PUNITIVE, SPECIAL OR CONSEQUENTIAL
                DAMAGES, HOWEVER CAUSED AND WHETHER ARISING UNDER CONTRACT,
                TORT, NEGLIGENCE OR OTHER THEORY OF LIABILITY, ARISING OUT OF
                THE USE OF OR INABILITY TO USE THE PRODUCT, EVEN IF THE PROVIDER
                OR ITS LICENSORS OR AFFILIATES ARE ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. BECAUSE SOME COUNTRIES AND JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OF LIABILITY, BUT MAY ALLOW LIABILITY TO BE
                LIMITED, IN SUCH CASES, THE LIABILITY OF THE PROVIDER, ITS
                EMPLOYEES OR LICENSORS OR AFFILIATES SHALL BE LIMITED TO THE SUM
                THAT YOU PAID FOR THE LICENSE.
              </NumberListItem>
              <NumberListItem tensDigits>
                Notices. Except as otherwise provided herein, all notices under
                this Agreement must be delivered to: Team T5 Inc., at 15F., No.
                46, LN. 11, Guangfu N. Rd., Songshan Dist., Taipei City 10560,
                Taiwan (R.O.C.).
              </NumberListItem>
              <NumberListItem tensDigits>
                Governing law. This Agreement shall be governed by and construed
                in accordance with the laws of Taiwan, the Republic of China.
                The End User and the Provider hereby agree that the principles
                of the conflict of laws and the United Nations Convention on
                Contracts for the International Sale of Goods shall not apply.
                You expressly agree that any disputes or claims ensuing from
                this Agreement with respect to the Provider or any disputes or
                claims relating to use of the Product shall be dealt with in
                Taiwan Taipei District Court and You expressly agree to the said
                court exercising jurisdiction.
              </NumberListItem>
              <NumberListItem tensDigits>
                General provisions.
                <OrderedList>
                  <SubNumberListItem level={2}>
                    This is the entire Agreement between the Provider and You
                    relating to the Product and it supersedes any prior
                    representations, discussions, undertakings, communications
                    or advertising relating to the Product.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    Should any part of this Agreement be found by a court of
                    competent jurisdiction or other competent authority to be
                    invalid, unlawful or unenforceable, then such part will be
                    severed from the remainder of this Agreement, which shall
                    remain valid and enforceable under the conditions stipulated
                    therein. The invalid or unenforceable part or provision
                    shall be replaced with a provision which accomplishes, to
                    the extent possible, the original business purpose of such
                    part or provision in a valid and enforceable manner.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    This Agreement may only be modified in written form, signed
                    by an authorized representative of the Provider, or a person
                    expressly authorized to act in this capacity under the terms
                    of a power of attorney.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    The provisions of Articles 6, 7, 9, 10, 11, and14 shall
                    survive the termination of the Agreement.
                  </SubNumberListItem>
                  <SubNumberListItem level={2}>
                    In case of a discrepancy between different language versions
                    of this Agreement, the English version shall prevail.
                  </SubNumberListItem>
                </OrderedList>
              </NumberListItem>
            </OrderedList>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
