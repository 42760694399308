import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { Button } from 'components/Button/Button'
import { OutlineLabel, TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { openMail } from 'utils/mail'
import { getAAP, getRAH } from 'utils/point'

import { QuotaLoading } from './QuotaLoading'

export const Quota = ({
  automatedAnalysisPoint,
  requestForAssistanceHour,
}: IAccountSettings) => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const { formatExpiresDate } = useDateTime()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  return (
    <PageSection
      title={t('quotaAndCredit.title', { ns: 'account' })}
      titleAction={
        <Button
          size="small"
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          onClick={openMail}
        >
          {t('quotaAndCredit.requestCta', { ns: 'account' })}
        </Button>
      }
    >
      {accountSettingsLoading ? (
        <QuotaLoading />
      ) : (
        <Stack sx={{ gap: 1, px: 4, py: 2 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {automatedAnalysisPoint.expiresAt ? (
              <TwoToneLabel
                keyText={t('quotaAndCredit.aap', { ns: 'account' })}
                valueText={formatExpiresDate(automatedAnalysisPoint.expiresAt)}
                mainColor={theme.colors.PRIMARY}
                textColor={theme.colors.BLACK_90}
              />
            ) : (
              <OutlineLabel
                text={t('quotaAndCredit.aap', { ns: 'account' })}
                color={theme.colors.PRIMARY}
              />
            )}
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE }}
            >
              {getAAP(automatedAnalysisPoint)}
            </Typography>
            <Tooltip
              title={
                <Typography
                  variant="textSmallImportant"
                  sx={{
                    'ul': {
                      marginBlockStart: 0,
                      marginBlockEnd: 0,
                      marginInlineStart: '1.25rem',
                      marginInlineEnd: 0,
                      paddingInlineStart: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t('quotaAndCredit.aapTooltip', { ns: 'account' }),
                  }}
                />
              }
            >
              <SvgIcon
                component={InfoIcon}
                inheritViewBox
                sx={{ width: '1rem', height: '1rem' }}
              />
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {requestForAssistanceHour.expiresAt ? (
              <TwoToneLabel
                keyText={t('quotaAndCredit.rah', { ns: 'account' })}
                valueText={formatExpiresDate(
                  requestForAssistanceHour.expiresAt
                )}
                mainColor={theme.colors.TEXT_LINK_NORMAL}
                textColor={theme.colors.BLACK_90}
              />
            ) : (
              <OutlineLabel
                text={t('quotaAndCredit.rah', { ns: 'account' })}
                color={theme.colors.TEXT_LINK_NORMAL}
              />
            )}
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE }}
            >
              {getRAH(requestForAssistanceHour)}
            </Typography>

            <Tooltip
              title={
                <Typography
                  variant="textSmallImportant"
                  sx={{
                    'ul': {
                      marginBlockStart: 0,
                      marginBlockEnd: 0,
                      marginInlineStart: '1.25rem',
                      marginInlineEnd: 0,
                      paddingInlineStart: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t('quotaAndCredit.rahTooltip', { ns: 'account' }),
                  }}
                />
              }
            >
              <SvgIcon
                component={InfoIcon}
                inheritViewBox
                sx={{ width: '1rem', height: '1rem' }}
              />
            </Tooltip>
          </Box>
        </Stack>
      )}
    </PageSection>
  )
}
