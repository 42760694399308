import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import theme from 'theme'

export const ThreatHuntingToolsPreviewLoading = () => (
  <Stack
    sx={{ p: 4, gap: 2, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
  >
    <Stack sx={{ gap: 4 }}>
      <Skeleton type="title" width="26.5rem" />
      <Skeleton type="body" width="26.5rem" />
    </Stack>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Skeleton type="title" width="7.5rem" />
    </Box>
  </Stack>
)
