export enum SearchParamKey {
  // report
  REPORT_ALIAS = 'alias',
  PM_REPORT = 'pmReport',

  // adversary
  ACTIVE_ADVERSARY_COUNTRY_TAB = 'adversaryCountryTab',
  ADVERSARY_ID = 'adversaryId',
  ADVERSARY_TAB = 'adversaryTab',

  // search
  SEARCH_TEXT = 'searchText',
  SEARCH_RESULT_TAB = 'searchResultTabKey',
  ORIGIN_SEARCH_TEXT = 'query',
  SEARCH_UUID = 'uuid',

  // malware
  MALWARE_ID = 'malwareId',
  MALWARE_TAB = 'malwareTab',

  // sample
  SAMPLE_ID = 'sampleId',
  SAMPLE_TAB = 'sampleTab',
  SAMPLE_RELATION_TAB = 'sampleRelationTab',
  SAMPLE_COMMUNITY_TAB = 'sampleCommunityTab',
  SAMPLE_SEARCH_TEXT = 'sampleSearchText',

  // capability
  CAPABILITY_TACTIC_ID = 'tacticId',
  CAPABILITY_TECHNIQUE_ID = 'techniqueId',
  CAPABILITY_TECHNIQUE_TAB = 'techniqueTab',

  // ip
  IP = 'ip',
  IP_TAB = 'ipTab',
  // domain
  DOMAIN = 'domain',
  DOMAIN_TAB = 'domainTab',
  // ip, domain shared
  IP_DOMAIN_COMMUNITY_TAB = 'communityTab',

  // raw intel
  RAW_INTEL_ID = 'raw-intel-id',

  // account
  ACCOUNT_IS_EDIT_TLP_DRAWER_OPEN = 'isEditTlpDrawerOpen',

  // comment
  COMMENT_RESOURCE_TYPE = 'resource_type',
  COMMENT_RESOURCE_ID = 'resource_main_value',
  COMMENT_ID = 'comment_id',

  // debug
  DEBUG_FLAG = 'debug',
}
