import {
  TReportCategoryRoute,
  TReportTypeRoute,
} from 'store/types/entityTypes/report'

/* Report Page start */
export const REPORTS_ROUTE = 'reports'
export const REPORT_DETAIL_ROUTE = 'detail'
// report category
export const APT_ROUTE: TReportCategoryRoute = 'apt'
export const CYBER_AFFAIRS_CATEGORY_ROUTE: TReportCategoryRoute =
  'cyber-affairs'
export const VULNERABILITY_ROUTE: TReportCategoryRoute = 'vulnerability'
// report types
export const FLASH_REPORT_ROUTE: TReportTypeRoute = 'flash-report'
export const MONTHLY_REPORT_ROUTE: TReportTypeRoute = 'monthly-report'
export const CTR_REPORT_ROUTE: TReportTypeRoute = 'ctr-report'
export const CYBER_AFFAIRS_TYPE_ROUTE: TReportTypeRoute = 'cyber-affairs'
export const REPORT_METRICS_ROUTE = 'report-metrics'
export const VIR_REPORT_ROUTE: TReportTypeRoute = 'vir-report'
export const PM_REPORT_ROUTE: TReportTypeRoute = 'pm-report'
export const PM_REPORT_DETAIL_ROUTE = 'pmr-detail'
/* Report Page end */

/* Search Page start */
export const SEARCH_ROUTE = 'search'
export const SEARCH_RESULT_ROUTE = 'result'
export const ORIGIN_SEARCH_RESULT_ROUTE = '/samples/search'
/* Search Page end */

/* Threat Landscape Page start */
export const THREAT_LANDSCAPE_ROUTE = 'threat-landscape'
export const TARGET_REGION_ROUTE = 'region'
export const TARGET_COUNTRY_ROUTE = 'country'
export const TARGET_INDUSTRY_ROUTE = 'industry'
/* Threat Landscape Page end */

/* Adversary Page start */
export const ADVERSARIES_ROUTE = 'adversaries'
export const ADVERSARY_INFO_ROUTE = 'info'
/* Adversary Page end */

/* Malware Page start */
export const MALWARES_ROUTE = 'malwares'
export const MALWARE_CONTENT_ROUTE = 'content'
/* Malware Page end */

/* Sample Page start */
export const SAMPLE_MANAGEMENT_ROUTE = 'sample-management'
export const SAMPLE_DETAILS_ROUTE = 'samples/details'
/* Sample Page end */

/* Capability Page start */
export const CAPABILITY_ROUTE = 'capability'
export const TACTIC_ROUTE = 'tactic'
export const TECHNIQUE_ROUTE = 'technique'
/* Capability Page end */

/* Download Page start */
export const DOWNLOAD_ROUTE = 'downloads'
/* Download Page end */

/* Account Page start */
export const ACCOUNT_ROUTE = 'account'
export const RFI_LIST_ROUTE = 'rfi-list'
/* Account Page end */

/* IP Page start */
export const IP_ROUTE = 'ip'
/* IP Page end */

/* Domain Page start */
export const DOMAIN_ROUTE = 'domain'
/* Domain Page end */

/* Raw Intel Page start */
export const RAW_INTEL_ROUTE = 'raw-intel'
/* Raw Intel Page end */

/* Report Introduction Page start */
export const REPORT_INTRODUCTION_ROUTE = 'report-introduction'
export const PREFACE_INTRODUCTION_ROUTE = 'preface'
export const APT_INTRODUCTION_ROUTE = 'apt'
export const FLASH_INTRODUCTION_ROUTE = 'flash-report'
export const MONTHLY_INTRODUCTION_ROUTE = 'monthly-report'
export const CTR_INTRODUCTION_ROUTE = 'ctr-report'
export const CYBER_AFFAIRS_INTRODUCTION_ROUTE = 'cyber-affairs-report'
export const VIR_INTRODUCTION_ROUTE = 'vir-report'
export const PMR_INTRODUCTION_ROUTE = 'pmr-report'
/* Report Introduction Page end */

/* API document Page start */
export const API_DOC_ROUTE = 'api-document'
export const API_DOC_AUTHENTICATION_ROUTE = 'authentication'
export const API_DOC_INTRODUCTION_ROUTE = 'introduction'
export const API_DOC_RETRIEVE_ACCESS_TOKEN_ROUTE = 'retrieve-access-token'

// API Version 2
export const API_DOC_V2_ROUTE = 'api-version-2'
export const API_DOC_V2_SAMPLE_ROUTE = 'sample'
export const API_DOC_V2_SAMPLE_UPLOAD_SAMPLE_ROUTE = 'upload-sample'
export const API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE =
  'list-uploaded-samples'
export const API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE = 'search-samples'
export const API_DOC_V2_SAMPLE_META_ROUTE = 'sample-meta'
export const API_DOC_V2_SAMPLE_REPORTS_ROUTE = 'sample-reports'
export const API_DOC_V2_SAMPLE_ADVERSARIES_ROUTE = 'sample-adversaries'
export const API_DOC_V2_SAMPLE_MALWARE_ROUTE = 'sample-malware'
export const API_DOC_V2_SAMPLE_CAPABILITIES_ROUTE = 'sample-capabilities'
export const API_DOC_V2_SAMPLE_RELATIONSHIP_ROUTE = 'sample-relationship'
export const API_DOC_V2_SAMPLE_SANDBOX_ROUTE = 'sample-sandbox'
export const API_DOC_V2_SAMPLE_PREVIEW_ROUTE = 'sample-preview'
export const API_DOC_V2_IP_ROUTE = 'ip'
export const API_DOC_V2_IP_ANALYSIS_STATUS_ROUTE = 'analysis-status'
export const API_DOC_V2_IP_SEARCH_IPS_ROUTE = 'search-ips'
export const API_DOC_V2_IP_GET_IP_INFORMATION_ROUTE = 'get-ip-information'
export const API_DOC_V2_IP_WHOIS_ROUTE = 'whois'
export const API_DOC_V2_IP_SAMPLES_ROUTE = 'samples'
export const API_DOC_V2_IP_REPORTS_ROUTE = 'reports'
export const API_DOC_V2_IP_ADVERSARIES_ROUTE = 'adversaries'
export const API_DOC_V2_IP_DNS_RECORDS_ROUTE = 'dns-records'
export const API_DOC_V2_IP_OSINT_POSTS_ROUTE = 'osint-posts'
export const API_DOC_V2_DOMAIN_ROUTE = 'domain'
export const API_DOC_V2_DOMAIN_ANALYSIS_STATUS_ROUTE = 'analysis-status'
export const API_DOC_V2_DOMAIN_SEARCH_DOMAINS_ROUTE = 'search-domains'
export const API_DOC_V2_DOMAIN_GET_DOMAIN_INFORMATION_ROUTE =
  'get-domain-information'
export const API_DOC_V2_DOMAIN_WHOIS_ROUTE = 'whois'
export const API_DOC_V2_DOMAIN_SAMPLES_ROUTE = 'samples'
export const API_DOC_V2_DOMAIN_REPORTS_ROUTE = 'reports'
export const API_DOC_V2_DOMAIN_ADVERSARIES_ROUTE = 'adversaries'
export const API_DOC_V2_DOMAIN_DNS_RECORDS_ROUTE = 'dns-records'
export const API_DOC_V2_DOMAIN_OSINT_POSTS_ROUTE = 'osint-posts'
export const API_DOC_V2_PMR_ROUTE = 'patch-management-report'
export const API_DOC_V2_PMR_LIST_PMR_ROUTE = 'list-pmr'
export const API_DOC_V2_PMR_GET_PMR_ROUTE = 'get-pmr'
export const API_DOC_V2_IOC_ROUTE = 'ioc-bundles'
export const API_DOC_V2_IOC_LIST_ROUTE = 'list-ioc-bundles'
export const API_DOC_V2_IOC_DOWNLOAD_ROUTE = 'download-ioc-bundles'
export const API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE = 'intelligence-reports'
export const API_DOC_V2_LIST_REPORTS_ROUTE = 'list-reports'
export const API_DOC_V2_ADVERSARY_ROUTE = 'adversary'
export const API_DOC_V2_ADVERSARY_LIST_ROUTE = 'list'
export const API_DOC_V2_ADVERSARY_SEARCH_ROUTE = 'search'
export const API_DOC_V2_ADVERSARY_REPORTS_ROUTE = 'reports'
export const API_DOC_V2_ADVERSARY_MALWARE_ROUTE = 'malware'
export const API_DOC_V2_ADVERSARY_SAMPLES_ROUTE = 'samples'
export const API_DOC_V2_ADVERSARY_CAPABILITIES_ROUTE = 'capabilities'
export const API_DOC_V2_MALWARE_ROUTE = 'malware'
export const API_DOC_V2_MALWARE_LIST_ROUTE = 'list'
export const API_DOC_V2_MALWARE_SEARCH_ROUTE = 'search'
export const API_DOC_V2_MALWARE_REPORTS_ROUTE = 'reports'
export const API_DOC_V2_MALWARE_ADVERSARIES_ROUTE = 'adversaries'
export const API_DOC_V2_MALWARE_SAMPLES_ROUTE = 'samples'
export const API_DOC_V2_MALWARE_CAPABILITIES_ROUTE = 'capabilities'
export const API_DOC_V2_CAPABILITY_ROUTE = 'capability'
export const API_DOC_V2_CAPABILITY_MITRE_TATICS_ROUTE = 'mitre-tatics'
export const API_DOC_V2_CAPABILITY_MITRE_TECHNIQUES_ROUTE = 'mitre-techniques'
export const API_DOC_V2_CAPABILITY_SEARCH_ROUTE = 'search'
export const API_DOC_V2_CAPABILITY_REPORTS_ROUTE = 'reports'
export const API_DOC_V2_CAPABILITY_ADVERSARIES_ROUTE = 'adversaries'
export const API_DOC_V2_CAPABILITY_SAMPLES_ROUTE = 'samples'
export const API_DOC_V2_CAPABILITY_MALWARE_ROUTE = 'malware'
/* API document Page end */

/* Redirect Page start */
export const REDIRECT_COMMENT_NOTIFICATION_ROUTE = 'comment-redirect'
export const REPORT_REDIRECT_ALIAS_SUB_ROUTE = '_report'
/* Redirect Page end */

/* Document Page start */
export const DOCUMENTS_ROUTE = 'documents'
export const EULA_ROUTE = 'eula'
export const EULA_ENGLISH_VERSION_ROUTE = 'english-version'
export const EULA_TRADITIONAL_CHINESE_VERSION_ROUTE =
  'traditional-chinese-version'
export const TERMS_OF_SERVICE_ROUTE = 'terms-of-service'
export const PRIVACY_POLICY_ROUTE = 'privacy-policy'
/* Document Page end */

/* Error Page start */
export const CRASH_ROUTE = '500'
export const NOT_FOUND_ROUTE = '404'
/* Error Page end */

/* Website Maintenance Page start */
export const WEBSITE_MAINTENANCE_ROUTE = 'website-maintenance'
/* Website Maintenance Page end */

/* DDW Page start */
export const DDW_ALERT_ROUTE = 'ddw-alert'
export const DDW_ALERT_DEMO_ROUTE = 'ddw-alert-demo'
export const RISK_MONITORING_ROUTE = 'risk-monitoring'
export const DDW_TAILORED_REPORTS_ENTRY = 'ddw-tailored-reports-entry'
export const DDW_TAILORED_CHINESE_REPORT_SAMPLE =
  'ddw-tailored-chinese-report-sample'
export const DDW_TAILORED_ENGLISH_REPORT_SAMPLE =
  'ddw-tailored-english-report-sample'
/* DDW Page end */
