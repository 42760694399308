import qs from 'qs'
import { Observable } from 'rxjs'
import { ajax, AjaxConfig, AjaxResponse } from 'rxjs/ajax'

import { getAuthToken } from './auth'

interface IFetchDataArgs extends AjaxConfig {
  isUploadFile?: boolean
}

export const fetchData = <TResponse>({
  isUploadFile = false,
  ...customConfig
}: IFetchDataArgs): Observable<AjaxResponse<TResponse>> => {
  const config = {
    ...customConfig,
    headers: {
      ...customConfig.headers,
    },
  }

  const { accessToken, tokenType } = getAuthToken()

  // 除了登入之外，其餘的 API 都需要有 token 才能成功
  if (accessToken && tokenType) {
    config.headers.Authorization = `${tokenType} ${accessToken}`
  }

  if (!isUploadFile) {
    // 上傳檔案時，不能定義 content-type，需要讓系統自動產生
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // 上傳檔案時，帶的內容是 formData，不用被 stringify
    if (config.body) {
      config.body = qs.stringify(config.body, { arrayFormat: 'brackets' })
    }
  }

  return ajax(config)
}
