import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { PageSection } from 'components/PageSection/PageSection'
import {
  ACCOUNT_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  RFI_LIST_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchAccountOnDemandReports,
  fetchAccountOnDemandReportsCancelled,
  selectAccountOnDemandReports,
  selectIsFetchAccountSettingsLoading,
} from 'store/slices/account'

import { RFILoading } from './RFILoading'

export const RFI = () => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const dispatch = useAppDispatch()
  const accountOnDemandReports = useAppSelector(selectAccountOnDemandReports)
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  useEffect(() => {
    dispatch(fetchAccountOnDemandReports())

    return () => {
      dispatch(fetchAccountOnDemandReportsCancelled())
    }
  }, [])

  return (
    <PageSection
      title={t('rfi.title', { ns: 'account' })}
      titleAction={
        <LinkButton
          size="small"
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          to={`/${ACCOUNT_ROUTE}/${RFI_LIST_ROUTE}`}
        >
          {t('rfi.moreCta', { ns: 'account' })}
        </LinkButton>
      }
    >
      {accountSettingsLoading ? (
        <RFILoading />
      ) : accountOnDemandReports.length === 0 ? (
        <Box sx={{ px: 4, py: 2 }}>
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE_60 }}
          >
            {t('rfi.emptyState', { ns: 'account' })}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 2 }}>
          <UnorderedList typography={theme.typography.textLinkL}>
            {accountOnDemandReports.slice(0, 3).map((accountOnDemandReport) => (
              <ListItem key={accountOnDemandReport.id}>
                <TextLink
                  size="medium"
                  to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${accountOnDemandReport.alias}`}
                >
                  {accountOnDemandReport.title}
                </TextLink>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      )}
    </PageSection>
  )
}
