import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const AdversaryInfoLoading = () => (
  <Box sx={{ display: 'flex', gap: 4, px: 4, py: 2 }}>
    <Stack sx={{ gap: 1, alignItems: 'center' }}>
      <Skeleton type="image" width="6rem" height="6rem" />
      <Skeleton type="title" width="3rem" />
      <Skeleton type="body" width="4rem" />
    </Stack>
    <Stack sx={{ gap: 2 }}>
      <Skeleton type="body" width="16.5rem" />
      <Skeleton type="body" width="20rem" />
      <Skeleton type="body" width="16.5rem" />
    </Stack>
  </Box>
)
