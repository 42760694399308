import {
  Box,
  FormControlLabel,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { DownloadIocButton } from 'components/Download/DownloadCta'
import { DownloadItem } from 'components/Download/DownloadItem'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SelectedCountLabel } from 'components/Label/Label'
import { BlockSection } from 'components/PageSection/PageSection'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { StatusCode } from 'constants/statusCode'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useDownloadIoc } from 'hooks/useDownloadIoc'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { logout } from 'store/slices/auth'
import {
  fetchDownloadIocs,
  fetchDownloadIocsCancelled,
  resetDownloadIocs,
  selectDownloadIocs,
  selectDownloadIocsLoading,
  selectHasMoreIocs,
  selectIocDownloading,
  selectIocDownloadingIdList,
} from 'store/slices/download'
import { IIoc, iocSourceTypeMap } from 'store/types/entityTypes/ioc'
import { deduplicatedStringArray } from 'utils/array'
import { downloadItem } from 'utils/download'

import { DownloadIocsLoading } from './DownloadIocsLoading'
import {
  IIocFormatTypeOption,
  IIocSourceTypeOption,
  IocsFilterBar,
} from './IocsFilterBar'

const SCROLLABLE_CONTAINER_ID = 'download-iocs-container'

export const filterIocsBySourceAndFormatType = ({
  iocs,
  iocSourceType,
  iocFormatType,
}: {
  iocs: IIoc[]
  iocSourceType: IIocSourceTypeOption['value']
  iocFormatType: IIocFormatTypeOption['value']
}): IIoc[] =>
  iocs.filter((ioc) => {
    let validSourceType = true
    let validFormatType = true
    // API Response 的 type 跟 query 的 type 不同，因此需逐一過濾
    validSourceType =
      iocSourceType === 'all' || ioc.type === iocSourceTypeMap[iocSourceType]
    // API Response 不會自動過濾 by format，所以前端須主動過濾
    if (iocFormatType === 'csv') {
      validFormatType = Boolean(ioc.csvUrl)
    } else if (iocFormatType === 'stix') {
      validFormatType = Boolean(ioc.stixUrl)
    }

    return validSourceType && validFormatType
  })

export const DownloadIocs = () => {
  const theme = useTheme()
  const { dateRangeOptionToTimes } = useDateTime()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { t } = useTranslation(['download', 'component'])
  const iocs = useAppSelector(selectDownloadIocs)
  const isIocsLoading = useAppSelector(selectDownloadIocsLoading)
  const hasMoreIocs = useAppSelector(selectHasMoreIocs)
  const [isScrollable, ref, node] = useIsScrollable([iocs])
  const { handleStixDownloadClick } = useDownloadIoc()

  // 用來綁定 CSV Link component 的 refs
  const csvRefs = useRef<HTMLElement[]>([])
  const rangeOptions = useDateRangeOptions()

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)

  const iocSourceTypeOptions: IIocSourceTypeOption[] = [
    { text: t('allOption', { ns: 'download' }), value: 'all' },
    { text: 'APT', value: 'apt' },
    { text: 'Cyber Crime', value: 'cyber_crime' },
    { text: 'Reports', value: 'report' },
  ]
  const [iocSourceType, setIocSourceType] = useState<
    IIocSourceTypeOption['value']
  >(iocSourceTypeOptions[0].value)

  const iocFormatTypeOptions: IIocFormatTypeOption[] = [
    { text: t('allOption', { ns: 'download' }), value: 'all' },
    { text: 'CSV', value: 'csv' },
    { text: 'STIX', value: 'stix' },
  ]
  const [iocFormatType, setIocFormatType] = useState<
    IIocFormatTypeOption['value']
  >(iocFormatTypeOptions[0].value)

  /* iocs selection start */
  const [selectedIocs, setSelectedIocs] = useState<IIoc[]>([])
  const isSelectedAll =
    selectedIocs.length > 0 && selectedIocs.length === iocs.length
  const indeterminate = !isSelectedAll && selectedIocs.length > 0
  const selectedCount = selectedIocs.length

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || indeterminate) {
      setSelectedIocs(iocs)
    } else {
      setSelectedIocs([])
    }
  }

  const handleIocSelect = (
    event: ChangeEvent<HTMLInputElement>,
    item: IIoc
  ) => {
    const currentItem = iocs.find((selectItem) => selectItem.id === item.id)

    if (currentItem) {
      if (event.target.checked) {
        setSelectedIocs([currentItem, ...selectedIocs])
      } else {
        setSelectedIocs(
          selectedIocs.filter((selectedIoc) => selectedIoc.id !== item.id)
        )
      }
    }
  }

  const selectedCsvIocs = selectedIocs.filter((ioc) => ioc.csvUrl)
  const selectedStixIocs = selectedIocs.filter((ioc) => ioc.stixUrl)

  const [selectedCsvSources, setSelectedCsvSources] = useState<
    Record<string, string>
  >({})

  const getCsvResource = async (csvIoc: IIoc) => {
    if (csvIoc.csvUrl && !selectedCsvSources[csvIoc.id]) {
      try {
        const data = await downloadItem({
          itemUrl: csvIoc.csvUrl,
          format: 'text',
        })
        if (data) {
          setSelectedCsvSources((prev) => ({ ...prev, [csvIoc.id]: data }))
        }
      } catch (error) {
        if ((error as Error).message === StatusCode.UNAUTHORIZED_TOKEN) {
          dispatch(
            logout({
              reason: 'expired',
              pathBeforeLogout: location.pathname + location.search,
            })
          )
        }
      }
    }
  }

  useEffect(() => {
    selectedCsvIocs.forEach((csvIoc) => getCsvResource(csvIoc))
  }, [selectedCsvIocs.length])

  const selectedCsvDownloadLinks = selectedCsvIocs.map((csvIoc, index) => (
    <CSVLink
      key={csvIoc.id}
      data={selectedCsvSources[csvIoc.id] || ''}
      filename={csvIoc.name}
    >
      <Box
        sx={{ display: 'none' }}
        ref={(el: HTMLElement) => {
          csvRefs.current[index] = el
        }}
      />
    </CSVLink>
  ))
  /* iocs selection end */

  /* fetch iocs start */
  const fetchMoreIocs = (refetch?: boolean) => {
    const offset = refetch
      ? 0
      : deduplicatedStringArray(iocs.map((ioc) => ioc.name)).length // 因為 ioc 被前端拆分過，因此 offset 需要 group by name
    dispatch(
      fetchDownloadIocs({
        offset,
        type: iocSourceType === 'all' ? undefined : iocSourceType,
        format: iocFormatType === 'all' ? undefined : iocFormatType,
        ...dateRangeOptionToTimes({ startDate, endDate })[
          rangeOption as TRangeOption
        ],
      })
    )
  }

  // fetch initial data
  useEffect(() => {
    fetchMoreIocs(true)
    return () => {
      Sentry.captureMessage('Change download IoCs filter', 'log')
      dispatch(fetchDownloadIocsCancelled())
      dispatch(resetDownloadIocs())
      setSelectedIocs([])
    }
  }, [rangeOption, startDate, endDate, iocSourceType, iocFormatType])

  // infinite scroll fetch
  useEffect(() => {
    if (node && !isScrollable && hasMoreIocs) {
      fetchMoreIocs()
    }
  }, [isScrollable, hasMoreIocs, node, iocs.length])
  /* fetch iocs end */

  /* download iocs start */
  const iocDownloadingIdList = useAppSelector(selectIocDownloadingIdList)
  const isIocDownloading = useAppSelector(selectIocDownloading)
  const [isDownloadingSelectedIocs, setIsDownloadingSelectedIocs] =
    useState<boolean>(false)

  useEffect(() => {
    if (iocDownloadingIdList.length === 0) setIsDownloadingSelectedIocs(false)
  }, [iocDownloadingIdList])

  const handleDownloadSelectedIocs = () => {
    setIsDownloadingSelectedIocs(true)
    // trigger download selected csv iocs
    csvRefs.current.forEach((el) => el.click())
    // trigger download selected stix iocs
    selectedStixIocs.forEach((ioc) => {
      handleStixDownloadClick({ ...ioc, isSelectedDownload: true })
    })
  }

  const isDownloadButtonDisabled =
    selectedIocs.length === 0 ||
    selectedCsvIocs.some((csvIoc) => !selectedCsvSources[csvIoc.id])
  /* download iocs end */

  const displayIocs = filterIocsBySourceAndFormatType({
    iocs,
    iocSourceType,
    iocFormatType,
  })

  return (
    <BlockSection title={t('ioc', { ns: 'download' })}>
      <Stack sx={{ height: '100%' }} data-testid="download-iocs-container">
        <IocsFilterBar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          rangeOption={rangeOption}
          setRangeOption={setRangeOption}
          iocSourceTypeOptions={iocSourceTypeOptions}
          iocSourceType={iocSourceType}
          setIocSourceType={setIocSourceType}
          iocFormatTypeOptions={iocFormatTypeOptions}
          iocFormatType={iocFormatType}
          setIocFormatType={setIocFormatType}
        />
        {!isIocsLoading && iocs.length === 0 ? (
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE_60, px: 4, py: 2 }}
          >
            {t('emptyState.noMatchFilter', { ns: 'component' })}
          </Typography>
        ) : (
          <Stack sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderBottom: `1px solid ${theme.colors.WHITE_20}`,
              }}
            >
              <Box>
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: theme.colors.WHITE,
                      ...theme.typography.body,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={isSelectedAll}
                      indeterminate={indeterminate}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label={t('selectAll', { ns: 'download' })}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <SelectedCountLabel count={selectedCount} />
                <Button
                  loading={
                    isIocDownloading &&
                    selectedIocs.length !== 0 &&
                    isDownloadingSelectedIocs
                  }
                  disabled={
                    isDownloadButtonDisabled ||
                    (selectedIocs.length !== 0 &&
                      isIocDownloading &&
                      isDownloadingSelectedIocs)
                  }
                  onClick={handleDownloadSelectedIocs}
                  endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                >
                  {t('downloadCta', { ns: 'download' })}
                </Button>
              </Box>
            </Box>
            {isIocsLoading && displayIocs.length === 0 ? (
              <DownloadIocsLoading />
            ) : (
              <Stack
                id={SCROLLABLE_CONTAINER_ID}
                ref={ref}
                sx={{
                  flexBasis: 0,
                  flexGrow: 1,
                  overflowY: 'auto',
                  p: 1,
                }}
              >
                <InfiniteScrollWrapper
                  dataLength={displayIocs.length}
                  next={fetchMoreIocs}
                  hasMore={hasMoreIocs}
                  scrollableTarget={SCROLLABLE_CONTAINER_ID}
                >
                  {displayIocs.map((ioc) => (
                    <DownloadItem
                      key={ioc.id}
                      title={ioc.name}
                      date={ioc.createdAt}
                      checked={selectedIocs.some(
                        (selectedIoc) => selectedIoc.id === ioc.id
                      )}
                      downloadElement={<DownloadIocButton {...ioc} />}
                      handleCheck={(e) => handleIocSelect(e, ioc)}
                    />
                  ))}
                </InfiniteScrollWrapper>
                <ScrollToTop
                  scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                  sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                />
              </Stack>
            )}
          </Stack>
        )}
        {selectedCsvDownloadLinks}
      </Stack>
    </BlockSection>
  )
}
