import type { Action } from '@reduxjs/toolkit'
import {
  CHECK_IS_DOMAIN,
  FETCH_SEARCH_SUGGEST_WORDS,
  getAdversaryById,
  getMalwareById,
} from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  checkSearchRawIntelResultType,
  checkSearchRawIntelResultTypeFulfilled,
  checkSearchRawIntelResultTypeRejected,
  checkSearchResultType,
  checkSearchResultTypeFulfilled,
  checkSearchResultTypeRejected,
  fetchMatchedAdversary,
  fetchMatchedAdversaryCancelled,
  fetchMatchedAdversaryFulfilled,
  fetchMatchedAdversaryRejected,
  fetchMatchedMalware,
  fetchMatchedMalwareCancelled,
  fetchMatchedMalwareFulfilled,
  fetchMatchedMalwareRejected,
  fetchSuggestWords,
  fetchSuggestWordsCancelled,
  fetchSuggestWordsFulfilled,
  fetchSuggestWordsRejected,
} from 'store/slices/search'
import {
  IAdversaryAPIData,
  mapAPIAdversaryToState,
} from 'store/types/entityTypes/adversary'
import {
  IMalwareAPIData,
  mapAPIMalwareToState,
} from 'store/types/entityTypes/malware'
import {
  ICheckDomainResponse,
  IFetchSuggestWordResponse,
} from 'store/types/slicesTypes/search'
import { checkStringIsIP } from 'utils/checkString'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from '../auth'
import { searchCountsEpic } from './counts'
import { searchDataEpic } from './data'

const searchResultTypeEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(checkSearchResultType.match),
    switchMap((action) =>
      fetchData<ICheckDomainResponse>({
        url: `${CHECK_IS_DOMAIN}?fqdn=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<ICheckDomainResponse>) => {
          if (response.response.check) {
            return checkSearchResultTypeFulfilled('domain')
          }
          const isIPv4 = checkStringIsIP(action.payload)
          if (isIPv4) {
            return checkSearchResultTypeFulfilled('ip')
          }
          return checkSearchResultTypeFulfilled('other')
        }),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(checkSearchResultTypeRejected(error))
          )
        )
      )
    )
  )

const searchRawIntelResultTypeEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(checkSearchRawIntelResultType.match),
    switchMap((action) =>
      fetchData<ICheckDomainResponse>({
        url: `${CHECK_IS_DOMAIN}?fqdn=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<ICheckDomainResponse>) => {
          if (response.response.check) {
            return checkSearchRawIntelResultTypeFulfilled('domain')
          }
          const isIPv4 = checkStringIsIP(action.payload)
          if (isIPv4) {
            return checkSearchRawIntelResultTypeFulfilled('ip')
          }
          return checkSearchRawIntelResultTypeFulfilled('other')
        }),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(checkSearchRawIntelResultTypeRejected(error))
          )
        )
      )
    )
  )

const suggestWordsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchSuggestWords.match),
    switchMap(() =>
      fetchData<IFetchSuggestWordResponse>({
        url: FETCH_SEARCH_SUGGEST_WORDS,
      }).pipe(
        map((response: AjaxResponse<IFetchSuggestWordResponse>) =>
          fetchSuggestWordsFulfilled(response.response.keywords)
        ),
        takeUntil(action$.pipe(filter(fetchSuggestWordsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchSuggestWordsRejected(error))
          )
        )
      )
    )
  )

const matchedAdversaryEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMatchedAdversary.match),
    switchMap((action) =>
      fetchData<IAdversaryAPIData>({
        url: getAdversaryById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IAdversaryAPIData>) =>
          fetchMatchedAdversaryFulfilled(
            mapAPIAdversaryToState(response.response)
          )
        ),
        takeUntil(action$.pipe(filter(fetchMatchedAdversaryCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMatchedAdversaryRejected(error))
          )
        )
      )
    )
  )

const matchedMalwareEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchMatchedMalware.match),
    switchMap((action) =>
      fetchData<IMalwareAPIData>({
        url: getMalwareById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IMalwareAPIData>) =>
          fetchMatchedMalwareFulfilled(mapAPIMalwareToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchMatchedMalwareCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchMatchedMalwareRejected(error))
          )
        )
      )
    )
  )

export const searchEpic = combineEpics(
  searchResultTypeEpic,
  searchRawIntelResultTypeEpic,
  searchCountsEpic,
  searchDataEpic,
  suggestWordsEpic,
  matchedAdversaryEpic,
  matchedMalwareEpic
)
