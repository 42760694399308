import { Box, IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { SnackbarKey,useSnackbar } from 'notistack'

export const SnackbarAction = ({
  closable = true,
  snackbarKey,
}: {
  closable?: boolean
  snackbarKey: SnackbarKey
}) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      {closable && (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
          <SvgIcon
            sx={{ width: '1.5rem', height: '1.5rem' }}
            component={CloseIcon}
            inheritViewBox
          />
        </IconButton>
      )}
    </Box>
  )
}
