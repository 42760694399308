import { getAuthToken } from './auth'

/**
 * Convert image format from blob.
 * @param buffer Image data.
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength

  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i])
  }

  return window.btoa(binary)
}

export const getImageUrl = async (url: string) => {
  // setup bearer token for fetching protected image
  const headers = new Headers()
  const { accessToken, tokenType } = getAuthToken()
  headers.set('Authorization', `${tokenType} ${accessToken}`)

  // should go through proxy, cannot use specific url
  const imageEndpoint = url.substring(url.indexOf('/api/v1'))

  const response = await fetch(imageEndpoint, { headers })

  // API 成功回 200 才回傳字串，若是 permission denied 則回 null
  if (response.ok) {
    const contentType = response.headers.get('Content-type')
    if (contentType === 'image/svg+xml') {
      const responseText = await response.text()
      if (responseText.includes('<svg')) {
        return `data:image/svg+xml;utf8,${encodeURIComponent(responseText)}`
      }
    }

    if (contentType === 'image/png') {
      const blob = await response.arrayBuffer()
      return `data:image/png;base64, ${arrayBufferToBase64(blob)}`
    }

    if (contentType === 'image/jpeg') {
      const blob = await response.arrayBuffer()
      return `data:image/jpeg;base64, ${arrayBufferToBase64(blob)}`
    }
  }

  return null
}
