import { CYBER_AFFAIRS_TYPE_ROUTE } from 'constants/routeParams'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const CyberAffairs = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(CYBER_AFFAIRS_TYPE_ROUTE)
  }, [])

  return null
}
