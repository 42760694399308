import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectSampleRelationsCount,
  selectSampleRelationsCountLoading,
} from 'store/slices/sampleDetail'

import { BundleSamples } from './SubContents/BundleSamples'
import { ChildSamples } from './SubContents/ChildSamples'
import { ParentSamples } from './SubContents/ParentSamples'
import { RelatedDomain } from './SubContents/RelatedDomain'
import { RelatedIP } from './SubContents/RelatedIP'

interface IRelationTab {
  key: string
  name: string
  component: FC<{ searchText: string }>
  count: number
  showCount: boolean
  isLoading: boolean
}

export const useRelationTabs = (): IRelationTab[] => {
  const { t } = useTranslation(['sample'])
  const sampleRelationsCountLoading = useAppSelector(
    selectSampleRelationsCountLoading
  )

  const sampleRelationsCount = useAppSelector(selectSampleRelationsCount)

  return [
    {
      key: 'ips',
      name: t('categoryTabs.relation.ips', { ns: 'sample' }),
      component: RelatedIP,
      count: sampleRelationsCount.ipCount,
      showCount: true,
      isLoading: sampleRelationsCountLoading,
    },
    {
      key: 'domains',
      name: t('categoryTabs.relation.domains', { ns: 'sample' }),
      component: RelatedDomain,
      count: sampleRelationsCount.domainCount,
      showCount: true,
      isLoading: sampleRelationsCountLoading,
    },
    {
      key: 'child-samples',
      name: t('categoryTabs.relation.childSamples', { ns: 'sample' }),
      component: ChildSamples,
      count: sampleRelationsCount.childSampleCount,
      showCount: true,
      isLoading: sampleRelationsCountLoading,
    },
    {
      key: 'parent-samples',
      name: t('categoryTabs.relation.parentSamples', { ns: 'sample' }),
      component: ParentSamples,
      count: sampleRelationsCount.parentSampleCount,
      showCount: true,
      isLoading: sampleRelationsCountLoading,
    },
    {
      key: 'bundle-samples',
      name: t('categoryTabs.relation.bundleSamples', { ns: 'sample' }),
      component: BundleSamples,
      count: sampleRelationsCount.bundleSampleCount,
      showCount: true,
      isLoading: sampleRelationsCountLoading,
    },
  ]
}
