import { IMalware, TMalwareAttribution } from 'store/types/entityTypes/malware'
import { checkTextIncludingIgnoreCase } from 'utils/checkString'
import { stringComparator } from 'utils/comparator'

import type { TFilterTextMap, TMalwareOrderKeys, TOrder } from './MalwareTable'

/* Header config */

// PM 指定的 attribution 排序階層
const attributionSortingLevelMap: Record<TMalwareAttribution, number> = {
  unknown: 1,
  'opensource': 2,
  'shared': 3,
  normal: 4,
}

/**
 * @param order asc or desc
 * @returns 不同 Malware table 欄位專屬的排序方式
 */

export const getMalwareComparatorMap = (
  order: TOrder
): Record<TMalwareOrderKeys, (a: IMalware, b: IMalware) => number> => {
  const isDesc = order === 'desc'

  return {
    name: (a: IMalware, b: IMalware) => stringComparator(a.name, b.name, order),
    aliases: (a: IMalware, b: IMalware) =>
      stringComparator(a.aliases[0] || '', b.aliases[0] || '', order),
    type: (a: IMalware, b: IMalware) => stringComparator(a.type, b.type, order),
    attribution: (a: IMalware, b: IMalware) => {
      const aLevel =
        attributionSortingLevelMap[a.attribution] ||
        attributionSortingLevelMap.normal
      const bLevel =
        attributionSortingLevelMap[b.attribution] ||
        attributionSortingLevelMap.normal

      if (aLevel < bLevel) {
        return isDesc ? 1 : -1
      }

      if (aLevel > bLevel) {
        return isDesc ? -1 : 1
      }

      if (
        aLevel === attributionSortingLevelMap.normal &&
        bLevel === attributionSortingLevelMap.normal
      ) {
        return stringComparator(a.attribution, b.attribution, order)
      }

      return 0
    },
    firstSeen: (a: IMalware, b: IMalware) =>
      stringComparator(String(a.firstSeen), String(b.firstSeen), order),
    hasTools: (a: IMalware, b: IMalware) => {
      if (a.hasTools === b.hasTools) {
        return 0
      }
      if (a.hasTools) {
        return isDesc ? -1 : 1
      }

      return isDesc ? 1 : -1
    },
  }
}

/**
 * @param malware Malware 列表資料
 * @param filterTextMap 各欄位的 filterText
 * @returns 取 filterTextMap 各欄位包含文字的交集
 */
export const useFilterMalware = ({
  malwares,
  filterTextMap,
}: {
  malwares: IMalware[]
  filterTextMap: TFilterTextMap
}): IMalware[] => {
  const validIndex: number[] = []

  // 需要依照 alias 的 filterText 微調 alias 的順序，因此用 map 來處理 filter
  const newMalwares = malwares.map((malware, index) => {
    // 預設為 undefined，依序被有 filterText 的欄位檢查
    let valid
    if (valid !== false && filterTextMap.name) {
      valid = checkTextIncludingIgnoreCase(malware.name, filterTextMap.name)
    }
    if (valid !== false && filterTextMap.aliases) {
      if (malware.aliases.length > 0) {
        const matchAliasIndex = malware.aliases.findIndex(
          (alias) =>
            filterTextMap.aliases &&
            checkTextIncludingIgnoreCase(alias, filterTextMap.aliases)
        )
        if (matchAliasIndex < 0) {
          valid = false
        }
      } else {
        valid = false
      }
    }
    if (valid !== false && filterTextMap.type) {
      valid = checkTextIncludingIgnoreCase(malware.type, filterTextMap.type)
    }
    if (valid !== false && filterTextMap.attribution) {
      valid = checkTextIncludingIgnoreCase(
        malware.attribution,
        filterTextMap.attribution
      )
    }

    // 如果 valid 依然是初始的 undefined，代表都不用 filter，只有 false 會被過濾掉
    if (valid !== false) {
      validIndex.push(index)
    }

    return malware
  })

  return newMalwares.filter((_malware, index) => validIndex.includes(index))
}

export const getDisplayAlias = (
  aliases: string[],
  aliasFilterText?: string
) => {
  if (aliases.length === 0) {
    return '--'
  }
  if (!aliasFilterText) {
    return aliases[0] || '--'
  }

  const displayAlias = aliases.find((alias) =>
    checkTextIncludingIgnoreCase(alias, aliasFilterText)
  )

  return displayAlias || '--'
}
