import { Box, Stack, Typography, useTheme } from '@mui/material'
import { PAGE_TITLE_REPORT_APT } from 'constants/pageTitle'
import { APT_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import {
  IAPTReportContent,
  useAptReportContent,
} from 'hooks/useAptReportContent'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  selectIsCheckIsMsspLoading,
  selectIsMsspUser,
} from 'store/slices/account'
import {
  selectIsFetchReportReadableTypesLoading,
  selectReportReadableTypes,
  selectReportTypeCount,
} from 'store/slices/report'
import { reportTypeRouteMap } from 'store/types/entityTypes/report'

import { ReportCaption } from '../ReportComponents/ReportCaption'
import { ReportEntryLoading } from '../ReportComponents/ReportLoading'
import { ReportPreviewList } from '../ReportComponents/ReportPreviewList'

export const APTReports = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation(['report'])
  const isMsspUser = useAppSelector(selectIsMsspUser)
  const isCheckIsMsspLoading = useAppSelector(selectIsCheckIsMsspLoading)
  const reportTypeCount = useAppSelector(selectReportTypeCount)
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isFetchReportReadableTypesLoading = useAppSelector(
    selectIsFetchReportReadableTypesLoading
  )
  useFetchReportsCount()
  const APTReportContent = useAptReportContent()

  // filter 有閱讀權限的 APT report content
  const filterAPTReportContentForEndUser = APTReportContent.filter(
    ({ type }) => reportReadableTypes[type]
  )

  const gridColumnCount = isMsspUser
    ? APTReportContent.length
    : filterAPTReportContentForEndUser.length

  const displayAptReportContent = isMsspUser
    ? APTReportContent
    : filterAPTReportContentForEndUser

  useEffect(() => {
    if (!isCheckIsMsspLoading && !isMsspUser) {
      if (displayAptReportContent.length === 1) {
        navigate(
          `/${REPORTS_ROUTE}/${APT_ROUTE}/${
            reportTypeRouteMap[displayAptReportContent[0].type]
          }`
        )
      }
    }
  }, [displayAptReportContent, isCheckIsMsspLoading])

  const displayNoReportsEmptyState = (content: IAPTReportContent) => {
    let emptyState
    // 沒有 reports 時，有閱讀權限顯示 emptyState.noReports
    if (reportReadableTypes[content.type]) {
      emptyState = t('emptyState.noReports', {
        ns: 'report',
        reports: content.name,
      })
    }
    // MSSP 沒有閱讀權限
    if (isMsspUser && !reportReadableTypes[content.type]) {
      emptyState = t('emptyState.msspNoAccess', {
        ns: 'report',
        reports: content.name,
      })
    }

    return emptyState
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_APT}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateColumns: `repeat(${
              gridColumnCount || 3
            }, minmax(25rem, 1fr))`,
            gap: 1,
          }}
        >
          {isCheckIsMsspLoading || isFetchReportReadableTypesLoading ? (
            <ReportEntryLoading />
          ) : (
            displayAptReportContent.map((content) => (
              <Box key={content.name} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    height: '100%',
                    border: `1px solid ${theme.colors.WHITE_20}`,
                    borderRadius: 0.5,
                    color: theme.colors.WHITE,
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${content.backgroundImageSrc})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <Stack sx={{ height: '100%' }}>
                      <ReportCaption
                        name={content.name}
                        description={content.description}
                        buttonText={content.buttonText}
                        reportType={content.type}
                        // button disable: End-user 和 MSSP 有閱讀權限但沒有報告
                        buttonDisable={
                          reportTypeCount !== null &&
                          reportTypeCount[content.type] === 0 &&
                          reportReadableTypes[content.type]
                        }
                        // button hide: MSSP 沒有閱讀權限也沒有報告
                        buttonHide={
                          isMsspUser &&
                          reportTypeCount !== null &&
                          reportTypeCount[content.type] === 0 &&
                          !reportReadableTypes[content.type]
                        }
                      />
                      <Stack
                        sx={{ height: '100%', bgcolor: theme.colors.BLACK_90 }}
                      >
                        {reportTypeCount &&
                        reportTypeCount[content.type] === 0 ? (
                          <Box sx={{ p: 4, textAlign: 'center' }}>
                            <Typography
                              variant="titleSmall"
                              sx={{ color: theme.colors.WHITE_60 }}
                            >
                              {displayNoReportsEmptyState(content)}
                            </Typography>
                          </Box>
                        ) : (
                          <ReportPreviewList type={content.type} />
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </>
  )
}
