import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_ADVERSARY_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/adversaries/Polaris/samples" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"Polaris",
  "samples":
    [
      {
        "sha256":"acb34fe8421f5afaa4774470277efc1f3215e156ee1d9f92b0aba3f16a136e47",
        "md5":"33e19a73a6a7387438a38f35da88a304",
        "size":139776,
        "first_seen":1570218074,
        "adversaries":["Polaris"],
        "malwares":["nbplugin"],
        "filename":"log.dll",
        "risk_level":"high",
        "has_network_activity":true,
        "url":"https://api.threatvision.org/api/v2/samples/acb34fe8421f5afaa4774470277efc1f3215e156ee1d9f92b0aba3f16a136e47"
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Samples'

export const APIDocumentVersionTwoAdversarySamples = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_ADVERSARY_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_ADVERSARY_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Lists all samples connected to a particular adversary, and can
          optionally offset the results.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/adversaries/:name/samples" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>Adversary name</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            samples
            <UnorderedList>
              <ListItem>
                Array of matching uploaded samples, each sample contains
                following keys:
                <UnorderedList>
                  <ListItem>sha256</ListItem>
                  <ListItem>md5</ListItem>
                  <ListItem>size</ListItem>
                  <ListItem>filename</ListItem>
                  <ListItem>first_seen</ListItem>
                  <ListItem>risk_level</ListItem>
                  <ListItem>has_network_activity</ListItem>
                  <ListItem>
                    adversaries
                    <UnorderedList>
                      <ListItem>Related adversaries</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    malwares
                    <UnorderedList>
                      <ListItem>Related malwares</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    url
                    <UnorderedList>
                      <ListItem>
                        Link to sample detail API endpoint in ThreatVision
                      </ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
