import { Box, Stack, useTheme } from '@mui/material'
import ChinaAdversaryImage from 'assets/adversaryCountryImages/china.png'
import DefaultMapImage from 'assets/adversaryCountryImages/default-map.png'
import IndiaAdversaryImage from 'assets/adversaryCountryImages/india.png'
import NorthKoreaAdversaryImage from 'assets/adversaryCountryImages/north-korea.png'
import PakistanAdversaryImage from 'assets/adversaryCountryImages/pakistan.png'
import SouthKoreaAdversaryImage from 'assets/adversaryCountryImages/south-korea.png'
import VietnamAdversaryImage from 'assets/adversaryCountryImages/vietnam.png'
import { PageSection } from 'components/PageSection/PageSection'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { PAGE_TITLE_ADVERSARIES } from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAdversaryCountryNameMap } from 'hooks/useAdversaryCountryNameMap'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  fetchAdversaries,
  fetchAdversariesCancelled,
  selectAdversaries,
  selectFetchAdversariesLoading,
} from 'store/slices/adversary'
import { IAdversary } from 'store/types/entityTypes/adversary'

import { AdversaryBlock } from './AdversaryBlock'
import { Loading } from './Loading'

/**
 * @desc 將 adversaries 依照國家分類
 * @param adversaries 要被分類的 adversaries
 * @returns Record<string, IAdversary[]>
 */
const getAdversariesGroupByCountry = (
  adversaries: IAdversary[]
): Record<string, IAdversary[]> => {
  const resultMap: Record<string, IAdversary[]> = {}
  adversaries.forEach((adversary) => {
    const { originCountries } = adversary
    const country = originCountries[0] || ''
    if (country) {
      if (resultMap[country]) {
        resultMap[country].push(adversary)
      } else {
        resultMap[country] = [adversary]
      }
    }
  })
  return resultMap
}

const countryImageMap: Record<string, string> = {
  China: ChinaAdversaryImage,
  India: IndiaAdversaryImage,
  Pakistan: PakistanAdversaryImage,
  Vietnam: VietnamAdversaryImage,
  'North Korea': NorthKoreaAdversaryImage,
  'South Korea': SouthKoreaAdversaryImage,
}

export const AdversaryPage = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const adversaryCountryNameMap = useAdversaryCountryNameMap()
  const { t } = useTranslation(['adversary'])
  const [searchParams, setSearchParams] = useSearchParams()
  const activeCountryTab = searchParams.get(
    SearchParamKey.ACTIVE_ADVERSARY_COUNTRY_TAB
  )

  useEffect(() => {
    dispatch(fetchAdversaries())
    return () => {
      dispatch(fetchAdversariesCancelled())
    }
  }, [])

  const adversaries = useAppSelector(selectAdversaries)
  const isAdversariesLoading = useAppSelector(selectFetchAdversariesLoading)
  const adversariesGroupByCountry = getAdversariesGroupByCountry(adversaries)

  const countryList = Object.keys(adversariesGroupByCountry)
    .map((country) => ({
      name: country,
      count: adversariesGroupByCountry[country].length,
    }))
    .sort((a, b) => {
      // PD 希望讓數量最多的在最前面
      if (a.count > b.count) {
        return -1
      }
      if (a.count < b.count) {
        return 1
      }
      return 0
    })

  // 若無對應到國家，且資料已備齊，會以第一個國家為預設值
  useEffect(() => {
    if (
      (!activeCountryTab || !adversariesGroupByCountry[activeCountryTab]) &&
      countryList.length > 0
    ) {
      searchParams.set(
        SearchParamKey.ACTIVE_ADVERSARY_COUNTRY_TAB,
        countryList[0].name
      )
      setSearchParams(searchParams)
    }
  }, [adversaries])

  const handleSelectCountry = (name: string) => {
    searchParams.set(SearchParamKey.ACTIVE_ADVERSARY_COUNTRY_TAB, name)
    setSearchParams(searchParams)
  }

  const displayAdversaries =
    activeCountryTab && adversariesGroupByCountry[activeCountryTab]
      ? adversariesGroupByCountry[activeCountryTab]
      : []

  const CountryMapImage =
    activeCountryTab && countryImageMap[activeCountryTab]
      ? countryImageMap[activeCountryTab]
      : DefaultMapImage

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_ADVERSARIES}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          p: 1,
        }}
      >
        <PageSection title={t('entryTitle', { ns: 'adversary' })}>
          <Stack sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                px: 2,
                py: 1.5,
                bgcolor: theme.colors.BLACK_90,
                borderBottom: `2px solid ${theme.colors.PRIMARY}`,
              }}
            >
              {isAdversariesLoading ? (
                <Box sx={{ py: 0.5 }}>
                  <Skeleton type="body" width="12.5rem" />
                </Box>
              ) : (
                countryList.map((country) => (
                  <TabWithCount
                    key={country.name}
                    name={adversaryCountryNameMap[country.name] || country.name}
                    handleSelect={() => handleSelectCountry(country.name)}
                    count={country.count}
                    selected={activeCountryTab === country.name}
                  />
                ))
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexBasis: 0,
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  width: '29rem',
                  [theme.breakpoints.up('desktop')]: {
                    width: '37.5rem',
                  },
                  [theme.breakpoints.up('xl')]: {
                    width: '46.5rem',
                  },
                  height: '100%',
                }}
              >
                <Image src={CountryMapImage} fit="cover" height="100%" />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  minWidth: '32.75rem',
                  [theme.breakpoints.up('xl')]: {
                    minWidth: '71rem',
                    maxWidth: '71rem',
                  },
                  p: 1,
                  flexBasis: 0,
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    maxHeight: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    [theme.breakpoints.up('lg')]: {
                      gridTemplateColumns: 'repeat(3, 1fr)',
                    },
                    [theme.breakpoints.up('xl')]: {
                      gridTemplateColumns: 'repeat(4, 1fr)',
                    },
                    gap: 1,
                    overflow: 'auto',
                  }}
                >
                  {isAdversariesLoading ? (
                    <Loading />
                  ) : (
                    displayAdversaries.map((adversary) => (
                      <Box
                        key={adversary.name}
                        sx={{ minHeight: '15.1875rem' }}
                      >
                        <AdversaryBlock {...adversary} />
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          </Stack>
        </PageSection>
      </Box>
    </>
  )
}
