import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

const KeywordCardLoading = () => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        height: '100%',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
      }}
    >
      <Box
        sx={{
          minHeight: '2rem',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          px: 4,
        }}
      >
        <Skeleton type="body" width="100%" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          px: 4,
          py: 2,
          justifyContent: 'space-around',
        }}
      >
        <Stack sx={{ gap: 1 }}>
          <Skeleton type="body" width="5rem" />
          <Skeleton type="title" width="3rem" />
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Skeleton type="body" width="5rem" />
          <Skeleton type="title" width="3rem" />
        </Stack>
      </Box>
    </Stack>
  )
}

export const DDWAlertLoading = () => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, minmax(14.75rem, 1fr))',
      gap: 2,
      mb: 2,
    }}
  >
    {generateSkeletons(25, <KeywordCardLoading />)}
  </Box>
)
