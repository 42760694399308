import { Box } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { IRadioOption, RadioGroup } from 'components/Radio/Radio'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectIsFetchAccountSettingsLoading,
  updateAccountSettings,
  updateSystemLocale,
} from 'store/slices/account'
import {
  IAccountSettings,
  IAccountSettingsOptions,
  TLocale,
} from 'store/types/entityTypes/account'

import { SystemLanguageLoading } from './SystemLanguageLoading'

export const SystemLanguage = ({
  accountSettings,
  accountSettingsOptions,
}: {
  accountSettings: IAccountSettings
  accountSettingsOptions: IAccountSettingsOptions
}) => {
  const { t } = useTranslation(['account', 'snackbar'])
  const dispatch = useAppDispatch()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const locale = event.target.value || null
    dispatch(
      updateAccountSettings({
        tip_user: {
          merge_preferences: {
            locale,
          },
        },
        successMessage: t('systemLanguage.updateSuccess', { ns: 'snackbar' }),
        failMessage: t('systemLanguage.updateFail', { ns: 'snackbar' }),
      })
    )

    dispatch(updateSystemLocale(locale as TLocale))
  }

  const localeOptions: IRadioOption[] = accountSettingsOptions.locales.map(
    ({ value, displayValue }) => ({
      value,
      label: displayValue,
    })
  )

  return (
    <PageSection title={t('systemLanguage.title', { ns: 'account' })}>
      <Box sx={{ px: 4, py: 2 }}>
        {accountSettingsLoading ? (
          <SystemLanguageLoading />
        ) : (
          <RadioGroup
            sx={{ flexDirection: 'row' }}
            value={accountSettings.locale}
            onChange={handleChange}
            options={localeOptions}
          />
        )}
      </Box>
    </PageSection>
  )
}
