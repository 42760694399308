export interface IRawIntelAPIData {
  main_value: string
  title: string
  seen: boolean
  targets_countries?: string[]
  targets_industries?: string[]
  malwares?: string[]
  adversaries?: string[]
  mitre_techniques?: string[]
  vulnerabilities?: string[]
  hashes?: string[]
  ips?: string[]
  domains?: string[]
}

export interface IRawIntel {
  id: string
  title: string
  seen: boolean
  targetCountries: string[]
  targetIndustries: string[]
  malwares: string[]
  adversaries: string[]
  capabilities: string[]
  maliciousSamples: string[]
  exploits: string[]
  ips: string[]
  domains: string[]
}

export const mapAPIRawIntelToState = (
  fromAPIRawIntel: IRawIntelAPIData
): IRawIntel => ({
  id: fromAPIRawIntel.main_value,
  title: fromAPIRawIntel.title,
  seen: fromAPIRawIntel.seen,
  targetCountries: fromAPIRawIntel.targets_countries || [],
  targetIndustries: fromAPIRawIntel.targets_industries || [],
  malwares: fromAPIRawIntel.malwares || [],
  adversaries: fromAPIRawIntel.adversaries || [],
  capabilities: fromAPIRawIntel.mitre_techniques || [],
  maliciousSamples: fromAPIRawIntel.hashes || [],
  exploits: fromAPIRawIntel.vulnerabilities || [],
  ips: fromAPIRawIntel.ips || [],
  domains: fromAPIRawIntel.domains || [],
})

export const mapAPIRawIntelsToState = (
  fromAPIRawIntels: IRawIntelAPIData[]
): IRawIntel[] => fromAPIRawIntels.map(mapAPIRawIntelToState)
