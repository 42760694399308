import { Box } from '@mui/material'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchMalwareRelatedTools,
  fetchMalwareRelatedToolsCancelled,
} from 'store/slices/malware'
import {
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  fetchTabTechniqueCount,
  fetchTabTechniqueCountCancelled,
  resetTabCounts,
} from 'store/slices/tabContent'
import { TCommentType } from 'store/types/entityTypes/comment'

import { IMalwareCategoryTab, useMalwareCategoryTabs } from './config'

interface IMalwareCategoryTabsProps {
  malwareId: string
  categoryTab: IMalwareCategoryTab
}

export const MalwareCategoryTabs = ({
  malwareId,
  categoryTab,
}: IMalwareCategoryTabsProps) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const malwareCategoryTabs = useMalwareCategoryTabs()

  const handleSelect = (key: string) => {
    searchParams.set(SearchParamKey.MALWARE_TAB, key)
    setSearchParams(searchParams)
  }

  const commentableType: TCommentType = 'Program::Family'
  const commentableId = malwareId

  useEffect(() => {
    dispatch(
      setupCommentableIdAndType({
        commentableType,
        commentableId,
      })
    )
    dispatch(
      fetchComments({
        commentableType,
        commentableId,
      })
    )
    dispatch(fetchTabReportCount(malwareId))
    dispatch(fetchTabAdversaryCount(malwareId))
    dispatch(fetchTabSampleCount(malwareId))
    dispatch(fetchMalwareRelatedTools(malwareId))
    dispatch(fetchTabTechniqueCount(malwareId))

    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabAdversaryCountCancelled())
      dispatch(fetchTabSampleCountCancelled())
      dispatch(fetchTabTechniqueCountCancelled())
      dispatch(resetTabCounts())
      dispatch(resetComments())
      dispatch(fetchMalwareRelatedToolsCancelled())
    }
  }, [malwareId])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, py: 1.5 }}>
      {malwareCategoryTabs.map((tab) => (
        <TabWithCount
          key={tab.key}
          name={tab.name}
          handleSelect={() => handleSelect(tab.key)}
          count={tab.count}
          selected={categoryTab.key === tab.key}
          isLoading={tab.isLoading}
          appendPlusSign={tab.appendPlusSign}
        />
      ))}
    </Box>
  )
}
