import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { IPPreview } from 'components/IP/IPPreview'
import { OneLineLoading } from 'components/Loading/OneLineLoading'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchSampleRelationIPs,
  fetchSampleRelationIPsCancelled,
  resetSampleRelationIPs,
  selectHasMoreSampleRelationIPs,
  selectSampleRelationIPs,
  selectSampleRelationIPsLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../../type'

const SCROLLABLE_CONTAINER_ID = 'sample-detail-relation-ips-container'

export const RelatedIP = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sampleRelationIPsLoading = useAppSelector(
    selectSampleRelationIPsLoading
  )
  const sampleRelationIPs = useAppSelector(selectSampleRelationIPs)
  const hasMoreSampleRelationIPs = useAppSelector(
    selectHasMoreSampleRelationIPs
  )
  const [isScrollable, ref, node] = useIsScrollable([sampleRelationIPs])

  const handleFetchRelationIPs = (refetch?: boolean) => {
    dispatch(
      fetchSampleRelationIPs({
        offset: refetch ? 0 : sampleRelationIPs.length,
        sampleId: searchText,
      })
    )
  }

  useEffect(() => {
    handleFetchRelationIPs(true)

    return () => {
      dispatch(fetchSampleRelationIPsCancelled())
      dispatch(resetSampleRelationIPs())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreSampleRelationIPs) {
      handleFetchRelationIPs()
    }
  }, [isScrollable, hasMoreSampleRelationIPs, node, sampleRelationIPs.length])

  if (sampleRelationIPsLoading && sampleRelationIPs.length === 0) {
    return <OneLineLoading />
  }

  if (sampleRelationIPs.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={sampleRelationIPs.length}
        next={handleFetchRelationIPs}
        hasMore={hasMoreSampleRelationIPs}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
        scrollThreshold="0px"
      >
        {sampleRelationIPs.map((ip) => (
          <Box
            key={ip.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <IPPreview {...ip} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
