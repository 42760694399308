import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { NormalLoading } from 'components/Loading/NormalLoading'
import { NoData } from 'components/NoData/NoData'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchTabTechniques,
  fetchTabTechniquesCancelled,
  resetTabTechniquesContent,
  selectHasMoreTabTechniques,
  selectTabTechniqueDataLoading,
  selectTabTechniques,
} from 'store/slices/tabContent'

import { TechniquePreview } from './TechniquePreview'

const SCROLLABLE_CONTAINER_ID = 'tab-techniques-scrollable-container'

interface ITechniqueTabContentProps {
  searchText: string
}

export const TechniqueTabContent = ({
  searchText,
}: ITechniqueTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const tabTechniques = useAppSelector(selectTabTechniques)
  const isTabTechniqueLoading = useAppSelector(selectTabTechniqueDataLoading)
  const hasMoreTabTechniques = useAppSelector(selectHasMoreTabTechniques)

  const [isScrollable, ref, node] = useIsScrollable([tabTechniques])

  const handleFetchTabTechniques = (refetch?: boolean) =>
    dispatch(
      fetchTabTechniques({
        searchText,
        offset: refetch ? 0 : tabTechniques.length,
      })
    )

  // fetch initial tab techniques
  useEffect(() => {
    handleFetchTabTechniques(true)
    return () => {
      dispatch(fetchTabTechniquesCancelled())
      dispatch(resetTabTechniquesContent())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreTabTechniques) {
      handleFetchTabTechniques()
    }
  }, [isScrollable, hasMoreTabTechniques, node, tabTechniques.length])

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
    >
      {tabTechniques.length === 0 ? (
        isTabTechniqueLoading ? (
          <NormalLoading />
        ) : (
          <NoData />
        )
      ) : (
        <InfiniteScrollWrapper
          dataLength={tabTechniques.length}
          next={handleFetchTabTechniques}
          hasMore={hasMoreTabTechniques}
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          scrollThreshold="0px"
        >
          {tabTechniques.map((technique) => (
            <Box
              key={technique.id}
              sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
            >
              <TechniquePreview
                techniqueName={
                  technique.parent?.name
                    ? `${technique.parent.name}: ${technique.name}`
                    : `${technique.name}`
                }
                showHoverStyle
                showTechniqueName
                showReadMoreButton
                {...technique}
              />
            </Box>
          ))}
        </InfiniteScrollWrapper>
      )}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
