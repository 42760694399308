import { Box } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const AccountLoading = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, px: 4, py: 2 }}>
    <Skeleton type="image" width="2.5rem" height="2.5rem" />
    <Skeleton type="body" width="11.5rem" />
    <Skeleton type="body" width="8.5rem" />
  </Box>
)
