import { Box, Stack } from '@mui/material'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { TocList } from 'components/Documents/TocList'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_V2_TITLE } from '../title'

const TITLE = 'Introduction'

export const APIDocumentVersionTwoIntroduction = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(`${TITLE} - ${API_DOC_V2_TITLE}`)}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={TITLE} />
        <Description>
          TeamT5’s ThreatVision cyber threat intelligence platform provides a
          rich API, including:
          <UnorderedList>
            <ListItem>
              Retrieval of Intelligence Reports
              <UnorderedList>
                <ListItem>List Reports</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Retrieval of Patch Management Reports (PMR)
              <UnorderedList>
                <ListItem>List PMR</ListItem>
                <ListItem>Get PMR</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Retrieval of IoC Bundles
              <UnorderedList>
                <ListItem>List IoC Bundles</ListItem>
                <ListItem>Download IoC Bundles</ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList />
    </Box>
  </>
)
