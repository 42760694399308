import { Box, Stack } from '@mui/material'
import Image from 'mui-image'
import { ReactNode } from 'react'

interface IErrorPageLayoutProps {
  errorImageUrl: string
  errorTitle: NonNullable<ReactNode>
  errorContent: NonNullable<ReactNode>
}

export const ErrorPageLayout = ({
  errorImageUrl,
  errorTitle,
  errorContent,
}: IErrorPageLayoutProps) => (
  <Stack sx={{ gap: 2, width: '100%', alignItems: 'center', pt: '7.5rem' }}>
    <Image src={errorImageUrl} alt="Error" width={320} height={320} />
    <Box>{errorTitle}</Box>
    <Box>{errorContent}</Box>
  </Stack>
)
