import { IWindowSize } from 'hooks/useWindowSize'
import { createContext } from 'react'

/**
 * @description 用來記錄畫面的寬高，將 Context 存在 App.tsx scope，讓元件可以偵測畫面寬高來改變內容排列方式
 * 用法為呼叫 useWindowSize 此 custom-hook 取得 width, height
 */
export const WindowSizeContext = createContext<IWindowSize>({
  width: window.innerWidth,
  height: window.innerHeight,
})
