import { Box, Table, TableContainer } from '@mui/material'
import { SomethingWrong } from 'components/Errors/SomethingWrong'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import {
  fetchMalwares,
  fetchMalwaresCancelled,
  resetMalwares,
  selectHasMoreMalwares,
  selectMalwares,
  selectMalwaresLoading,
} from 'store/slices/malware'
import { IMalware } from 'store/types/entityTypes/malware'

import { MalwareTableBody } from './MalwareTableBody'
import { MalwareTableHeader } from './MalwareTableHeader'
import { MalwareTableLoading } from './MalwareTableLoading'

export type TOrder = 'asc' | 'desc'
export type TMalwareOrderKeys = keyof Omit<
  IMalware,
  'overview' | 'id' | 'commentableType'
>
export type TMalwareFilterKeys = keyof Pick<
  IMalware,
  'name' | 'aliases' | 'type' | 'attribution'
>
export type TFilterTextMap = Record<TMalwareFilterKeys, string>

const initialFilterTextMap: TFilterTextMap = {
  name: '',
  aliases: '',
  type: '',
  attribution: '',
}

export const MalwareTable = () => {
  const dispatch = useAppDispatch()
  const malwares = useAppSelector(selectMalwares)
  const isMalwaresLoading = useAppSelector(selectMalwaresLoading)
  const hasMoreMalwares = useAppSelector(selectHasMoreMalwares)

  // order
  const [order, setOrder] = useState<TOrder>('asc')
  const [orderBy, setOrderBy] = useState<TMalwareOrderKeys>('name')
  const handleOrderChange = (newOrderBy: TMalwareOrderKeys) => {
    const isAsc = orderBy === newOrderBy && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(newOrderBy)
  }

  // filter
  const [filterTextMap, setFilterTextMap] =
    useState<TFilterTextMap>(initialFilterTextMap)
  const handleFilterChange =
    (newFilterBy: TMalwareFilterKeys) => (newFilterText: string) => {
      const newFilterTextMap = {
        ...filterTextMap,
        [newFilterBy]: newFilterText,
      }
      setFilterTextMap(newFilterTextMap)
    }

  const handleFetchMalwares = () => {
    if (hasMoreMalwares) {
      dispatch(
        fetchMalwares({
          offset: malwares.length,
        })
      )
    }
  }

  useEffect(() => {
    handleFetchMalwares()
    return () => {
      dispatch(fetchMalwaresCancelled())
      dispatch(resetMalwares())
    }
  }, [])

  useEffect(() => {
    handleFetchMalwares()
    return () => {
      dispatch(fetchMalwaresCancelled())
    }
  }, [malwares.length])

  if (malwares.length === 0 && !isMalwaresLoading) {
    return <SomethingWrong />
  }

  return (
    <TableContainer sx={{ height: '100%', minWidth: '77.5rem' }}>
      <Table stickyHeader>
        <MalwareTableHeader
          order={order}
          orderBy={orderBy}
          handleOrderChange={handleOrderChange}
          filterTextMap={filterTextMap}
          handleFilterChange={handleFilterChange}
        />
        {isMalwaresLoading && malwares.length === 0 ? (
          <MalwareTableLoading />
        ) : (
          <MalwareTableBody
            malwares={malwares}
            order={order}
            orderBy={orderBy}
            filterTextMap={filterTextMap}
          />
        )}
      </Table>
      {hasMoreMalwares && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CenterCircularProgress />
        </Box>
      )}
    </TableContainer>
  )
}
