import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { DateLabel, TwoToneLabel } from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import {
  useToolIncludedColor,
  useToolIncludedLabel,
} from 'hooks/useToolIncludedMap'
import { ChangeEvent, ReactNode } from 'react'
import { ITool, TToolIncluded } from 'store/types/entityTypes/tool'

interface IThreatHuntingToolProps extends ITool {
  handleCheck?: (event: ChangeEvent<HTMLInputElement>, key: string) => void
  downloadElement: NonNullable<ReactNode>
  checked?: boolean
  checkboxDisabled?: boolean
}

export const ThreatHuntingTool = ({
  handleCheck,
  downloadElement,
  checked,
  checkboxDisabled,
  name,
  date,
  included,
}: IThreatHuntingToolProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const toolIncludedColorMap = useToolIncludedColor()
  const toolIncludedLabelMap = useToolIncludedLabel()
  const checkable = checked !== undefined && handleCheck

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: theme.colors.BLACK_90,
        padding: 1,
        '&:hover': {
          bgcolor: theme.colors.BLACK_60,
          '& .right-icon.MuiSvgIcon-root': {
            color: theme.colors.TEXT_LINK_NORMAL,
            borderColor: theme.colors.TEXT_LINK_NORMAL,
          },
        },
        '&:active': {
          bgcolor: theme.colors.BLACK_95,
          '& .right-icon.MuiSvgIcon-root': {
            color: theme.colors.WHITE,
            borderColor: theme.colors.WHITE,
          },
        },
      }}
    >
      <Stack
        sx={{
          width: '100%',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {checkable && (
              <Checkbox
                checked={checked}
                onChange={(event) => {
                  event.stopPropagation()
                  handleCheck(event, name)
                }}
                disabled={checkboxDisabled}
              />
            )}
            <DateLabel text={getDateStringByTimestampsInSeconds(date)} />
            {Object.keys(included)
              .filter((label: TToolIncluded) => included[label] > 0)
              .map((label: TToolIncluded) => (
                <TwoToneLabel
                  key={label}
                  keyText={toolIncludedLabelMap[label]}
                  valueText={String(included[label])}
                  mainColor={toolIncludedColorMap[label]}
                  textColor={theme.colors.BLACK_90}
                />
              ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {downloadElement}
          </Box>
        </Box>
        <Typography
          variant="textSmallImportant"
          sx={{
            pl: checkable ? 8 : 0,
            color: theme.colors.WHITE,
            flexGrow: 1,
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Box>
  )
}
