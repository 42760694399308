import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import ScoreSkeletonImage from 'assets/skeletonMaterials/network-score.svg'
import { Skeleton } from 'components/Skeleton/Skeleton'
import Image from 'mui-image'

interface IAnalyzingInfoProps {
  title: string
}

export const AnalyzingInfo = ({ title }: IAnalyzingInfoProps) => {
  const theme = useTheme()
  return (
    <Box
      data-testid="analyzing-info"
      sx={{ display: 'flex', alignitems: 'center', px: 2, py: 5, gap: 4 }}
    >
      <Box
        sx={{
          '& div': {
            animation: 'rotate-animation 1.2s infinite linear',
            '@keyframes rotate-animation': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '50%': {
                transform: 'rotate(180deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          },
        }}
      >
        <Image src={ScoreSkeletonImage} width={110} height={110} />
      </Box>
      <Stack sx={{ flexGrow: 1, gap: 2 }}>
        <Typography sx={{ color: theme.colors.PRIMARY }} variant="title">
          {title}
        </Typography>
        <Skeleton type="body" width="16.5rem" />
        <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
        <Skeleton type="body" width="16.5rem" />
        <Skeleton type="body" width="20rem" />
        <Skeleton type="body" width="16.5rem" />
      </Stack>
    </Box>
  )
}
