import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { PAGE_TITLE_REPORT_CTR } from 'constants/pageTitle'
import { useAptReportListContent } from 'hooks/useAptReportListContent'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { selectIsMsspUser } from 'store/slices/account'
import {
  fetchCTRReports,
  fetchCTRReportsCancelled,
  resetCTRReports,
  selectAPTReportTypeCount,
  selectCTRReports,
  selectHasMoreCTRReports,
  selectIsFetchCTRReportsLoading,
  selectReportReadableTypes,
} from 'store/slices/report'

import { CTR_REPORT_TYPE } from '../constants/reportType'
import { FilterBar } from '../ReportComponents/FilterBar'
import { ReportListInfo } from '../ReportComponents/ReportListInfo'
import { ReportListLoading } from '../ReportComponents/ReportLoading'
import { ReportPreview } from '../ReportComponents/ReportPreview'

const SCROLLABLE_CONTAINER_ID = 'ctr-report-list-container'

interface ICTRReportListProps {
  hasReportReadableTypesNoReport: boolean
}

export const CTRReportList = ({
  hasReportReadableTypesNoReport,
}: ICTRReportListProps) => {
  const theme = useTheme()
  const { dateRangeOptionToTimes } = useDateTime()
  const rangeOptions = useDateRangeOptions()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['report', 'component'])
  const CTRReports = useAppSelector(selectCTRReports)
  const APTReportTypeCount = useAppSelector(selectAPTReportTypeCount)
  const hasMoreReports = useAppSelector(selectHasMoreCTRReports)
  const isFetchCTRReportsLoading = useAppSelector(
    selectIsFetchCTRReportsLoading
  )
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isMsspUser = useAppSelector(selectIsMsspUser)

  const [isScrollable, ref, node] = useIsScrollable([CTRReports])
  useFetchReportsCount()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)

  const aptReportListContent = useAptReportListContent()

  // filter 有閱讀權限且有報告的 APT
  const filterOtherReports = APTReportTypeCount
    ? aptReportListContent.filter(
        ({ type }) =>
          type !== CTR_REPORT_TYPE &&
          APTReportTypeCount[type] !== 0 &&
          reportReadableTypes[type]
      )
    : []

  const fetchMoreReports = (refetch?: boolean) => {
    const offset = refetch ? 0 : CTRReports.length
    dispatch(
      fetchCTRReports({
        offset,
        ...dateRangeOptionToTimes({ startDate, endDate })[
          rangeOption as TRangeOption
        ],
      })
    )
  }

  const displayNoReportsEmptyState = () => {
    let emptyState
    // 沒有 reports 時，有閱讀權限顯示 emptyState.noReports
    if (hasReportReadableTypesNoReport) {
      emptyState = t('emptyState.noReports', {
        ns: 'report',
        reports: t('ctReports.title', { ns: 'report' }),
      })
    } else if (isMsspUser) {
      emptyState = t('emptyState.msspNoAccess', {
        ns: 'report',
        reports: t('ctReports.title', { ns: 'report' }),
      })
    } else {
      emptyState = t('emptyState.noMatchFilter', { ns: 'component' })
    }

    return emptyState
  }

  // initial fetch
  useEffect(() => {
    fetchMoreReports(true)

    return () => {
      dispatch(fetchCTRReportsCancelled())
      dispatch(resetCTRReports())
    }
  }, [rangeOption, startDate, endDate])

  // infinite scroll fetch
  useEffect(() => {
    if (node && !isScrollable && hasMoreReports) {
      fetchMoreReports()
    }
  }, [isScrollable, hasMoreReports, node, CTRReports.length])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_CTR}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          color: theme.colors.WHITE,
        }}
      >
        <ReportListInfo
          type={CTR_REPORT_TYPE}
          otherReportsInfo={filterOtherReports}
        />
        <Stack
          sx={{
            flexGrow: 1,
            flexBasis: 0,
            width: '100%',
            maxWidth: '85rem',
            minWidth: '47.5rem',
            [theme.breakpoints.up('xl')]: {
              minWidth: '85rem',
            },
          }}
        >
          <FilterBar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            rangeOption={rangeOption}
            setRangeOption={setRangeOption}
          />
          {isFetchCTRReportsLoading && CTRReports.length === 0 ? (
            <ReportListLoading />
          ) : (
            <Stack
              id={SCROLLABLE_CONTAINER_ID}
              ref={ref}
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                overflowY: 'auto',
              }}
            >
              {CTRReports.length > 0 ? (
                <>
                  <InfiniteScrollWrapper
                    dataLength={CTRReports.length}
                    next={fetchMoreReports}
                    hasMore={hasMoreReports}
                    scrollableTarget={SCROLLABLE_CONTAINER_ID}
                  >
                    {CTRReports.map((report) => (
                      <Box key={report.id}>
                        <ReportPreview {...report} hasImage />
                        <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
                      </Box>
                    ))}
                  </InfiniteScrollWrapper>
                  <ScrollToTop
                    scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                    sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                  />
                </>
              ) : (
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="titleSmall"
                    sx={{ color: theme.colors.WHITE_60 }}
                  >
                    {displayNoReportsEmptyState()}
                  </Typography>
                </Box>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  )
}
