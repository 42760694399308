import { Skeleton as MuiSkeleton, SkeletonProps,useTheme } from '@mui/material'

type TSkeletonType = 'h1' | 'title' | 'body' | 'image' | 'circular'

interface ISkeletonProps extends SkeletonProps {
  type: TSkeletonType
}

export const Skeleton = ({ type, sx = [], ...props }: ISkeletonProps) => {
  const theme = useTheme()

  const heightMap: Record<TSkeletonType, string> = {
    h1: '3.125rem',
    title: '1.5rem',
    body: '1rem',
    image: '10rem',
    circular: '3rem',
  }

  return (
    <MuiSkeleton
      sx={[
        { bgcolor: theme.colors.WHITE_20, height: heightMap[type] },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant="rectangular"
      {...props}
    />
  )
}
