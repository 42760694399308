import { Avatar, Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as AccountSettingsIcon } from 'assets/basicIcons/account-settings.svg'
import { ReactComponent as ExpandMoreIcon } from 'assets/basicIcons/angleDown.svg'
import { ReactComponent as LogoutIcon } from 'assets/basicIcons/logout.svg'
import { TwoToneLabel } from 'components/Label/Label'
import { Menu, MenuItem } from 'components/Menu/Menu'
import { ACCOUNT_ROUTE } from 'constants/routeParams'
import { useAvatarColor } from 'hooks/useAvatarColor'
import { useImageUrl } from 'hooks/useImageSrc'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  fetchAccountSettings,
  selectAccountSettings,
} from 'store/slices/account'
import { logout } from 'store/slices/auth'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { getAAP, getRAH } from 'utils/point'

interface IAccountDropdown extends IAccountSettings {
  backgroundColor?: string
}

export const AccountDropdown = ({
  backgroundColor,
  email,
  avatar,
}: IAccountDropdown) => {
  const { t } = useTranslation(['navbar'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const avatarUrl = useImageUrl({ fetchImageUrl: avatar || '' })
  const avatarBgcolor = useAvatarColor(email)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { automatedAnalysisPoint, requestForAssistanceHour } = useAppSelector(
    selectAccountSettings
  )

  backgroundColor = anchorEl
    ? theme.colors.WHITE_20
    : backgroundColor || theme.colors.BLACK_90

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logout({ reason: 'manual' }))
    handleCloseMenu()
  }

  useEffect(() => {
    if (anchorEl) {
      dispatch(fetchAccountSettings())
    }
  }, [anchorEl])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          bgcolor: backgroundColor,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': {
            bgcolor: theme.colors.WHITE_20,
            '.avatar': {
              border: `1px solid ${theme.colors.PRIMARY}`,
            },
          },
        }}
        aria-owns={anchorEl ? 'menu' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleOpenMenu}
      >
        {avatarUrl ? (
          <Avatar
            src={avatarUrl}
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              border: anchorEl ? `1px solid ${theme.colors.PRIMARY}` : 'none',
            }}
          />
        ) : (
          <Avatar
            className="avatar"
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              bgcolor: avatarBgcolor,
              border: anchorEl ? `1px solid ${theme.colors.PRIMARY}` : 'none',
            }}
          >
            <Typography variant="reportH3">
              {email && email.slice(0, 1).toLocaleUpperCase()}
            </Typography>
          </Avatar>
        )}
        <SvgIcon
          sx={{ width: '1rem', height: '1rem' }}
          component={ExpandMoreIcon}
          inheritViewBox
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleCloseMenu}
        MenuListProps={{
          onMouseLeave: handleCloseMenu,
          sx: {
            p: 0,
          },
        }}
        onMouseLeave={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ '.MuiMenu-list': { py: 0 } }}
      >
        <Box
          sx={{
            py: 2,
            px: 4,
            display: 'flex',
            gap: 1,
            borderBottom: `1px solid ${theme.colors.WHITE_20}`,
          }}
        >
          <TwoToneLabel
            key="AAP"
            keyText="AAP"
            valueText={getAAP(automatedAnalysisPoint)}
            mainColor={theme.colors.PRIMARY}
            textColor={theme.colors.BLACK_90}
          />
          <TwoToneLabel
            key="RAH"
            keyText="RAH"
            valueText={getRAH(requestForAssistanceHour)}
            mainColor={theme.colors.TEXT_LINK_NORMAL}
            textColor={theme.colors.BLACK_90}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <MenuItem handleClick={handleCloseMenu} size="large">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                color: theme.colors.WHITE,
              }}
              to={`${ACCOUNT_ROUTE}`}
              component={Link}
            >
              <SvgIcon component={AccountSettingsIcon} inheritViewBox />
              {t('accountSettings', { ns: 'navbar' })}
            </Box>
          </MenuItem>
          <MenuItem handleClick={handleLogout} size="large">
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <SvgIcon component={LogoutIcon} inheritViewBox />
              {t('logout', { ns: 'navbar' })}
            </Box>
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
}
