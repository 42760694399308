import { Box, Stack, useTheme } from '@mui/material'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { getPageTitleDomainDetailByFQDN } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useOutOfAAPDialog } from 'hooks/useOutOfAAPDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchDomainDetail,
  fetchDomainDetailCancelled,
  fetchDomainStatusCancelled,
  resetDomainAnalysisStatus,
  resetDomainDetail,
  selectDomainAnalysisStatus,
  selectDomainDetail,
  selectDomainDetailLoading,
} from 'store/slices/domain'
import {
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabSampleCount,
  fetchTabSampleCountCancelled,
  resetTabCounts,
} from 'store/slices/tabContent'
import { TCommentType } from 'store/types/entityTypes/comment'

import { useDomainCategoryTabs } from './DomainComponents/domainContent.config'
import { DomainInfo } from './DomainComponents/DomainInfo'
import { AnalyzingInfo } from './IPDomainComponents/AnalyzingInfo'
import { CategoryTabs } from './IPDomainComponents/CategoryTabs'

const commentableType: TCommentType = 'Network::Domain'

export const DomainPage = () => {
  useGoBack()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const domain = searchParams.get(`${SearchParamKey.DOMAIN}`)
  const navigate = useNavigate()
  const domainCategoryTabs = useDomainCategoryTabs()
  const isDomainAnalyzed = useAppSelector(selectDomainAnalysisStatus)
  const domainDetail = useAppSelector(selectDomainDetail)
  const isDomainDetailLoading = useAppSelector(selectDomainDetailLoading)
  const { renderOutAAPDialog, outOfAAPDialogOpen, handleOutOfAAPDialogOpen } =
    useOutOfAAPDialog({
      needGoBack: true,
    })

  const defaultCategoryTab = domainCategoryTabs[0]
  const categoryTabParam = searchParams.get(SearchParamKey.DOMAIN_TAB)
  const categoryTab =
    (categoryTabParam &&
      domainCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const BottomContent = categoryTab.component

  useEffect(() => {
    if (!domain) navigate(`/${NOT_FOUND_ROUTE}`)
  }, [])

  // 有 domain detail 之後再去抓各種資料的 count
  useEffect(() => {
    if (domain && domainDetail) {
      dispatch(
        setupCommentableIdAndType({
          commentableType,
          commentableId: domain,
        })
      )
      dispatch(
        fetchComments({
          commentableType,
          commentableId: domain,
        })
      )
      dispatch(fetchTabReportCount(domain))
      dispatch(fetchTabAdversaryCount(domain))
      dispatch(fetchTabSampleCount(domain))
    }

    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabAdversaryCountCancelled())
      dispatch(fetchTabSampleCountCancelled())
      dispatch(resetTabCounts())
      dispatch(resetComments())
    }
  }, [domain, Boolean(domainDetail)])

  // 初次以及當 domain 改變時，要去抓 domain detail
  useEffect(() => {
    if (domain) {
      dispatch(
        fetchDomainDetail({
          domain,
          outOfAAPCallback: handleOutOfAAPDialogOpen,
        })
      )
    }

    return () => {
      dispatch(fetchDomainDetailCancelled())
      dispatch(fetchDomainStatusCancelled())
      dispatch(resetDomainAnalysisStatus())
      dispatch(resetDomainDetail())
    }
  }, [domain])

  if (!domain) {
    return null
  }

  const isDomainReady =
    !isDomainDetailLoading && isDomainAnalyzed && domainDetail

  return (
    <>
      <Helmet>
        <title>{getPageTitleDomainDetailByFQDN(domain)}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Stack sx={{ p: 1, gap: 1, height: '100%', width: '78rem' }}>
          {isDomainReady ? <DomainInfo /> : <AnalyzingInfo title={domain} />}
          {isDomainReady && !outOfAAPDialogOpen && (
            <PageTabsContent
              tabs={
                <CategoryTabs
                  categoryTab={categoryTab}
                  tabs={domainCategoryTabs}
                  searchParamKey={SearchParamKey.DOMAIN_TAB}
                />
              }
              content={
                <BottomContent detailType="domain" searchText={domain} />
              }
              maxWidth={
                categoryTab.key === 'dns-records' || categoryTab.key === 'whois'
                  ? '100%'
                  : undefined
              }
            />
          )}
          {renderOutAAPDialog}
        </Stack>
      </Box>
    </>
  )
}
