import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { ITactic, ITechnique } from 'store/types/entityTypes/capability'
import { ICapabilitySlice } from 'store/types/slicesTypes/capability'

const initialState: ICapabilitySlice = {
  tactics: [],
  tacticsLoading: true,
  tactic: {
    id: '',
    name: '',
    description: '',
    lastModified: null,
    publishedAt: null,
    version: '',
    techniques: [
      {
        id: '',
        name: '',
        description: '',
        lastModified: null,
        publishedAt: null,
        version: '',
        parent: {
          id: null,
          name: null,
        },
        subTechniques: [],
        tactics: [],
      },
    ],
  },
  tacticLoading: true,
  technique: {
    id: '',
    name: '',
    description: '',
    lastModified: null,
    publishedAt: null,
    version: '',
    parent: {
      id: null,
      name: null,
    },
    subTechniques: [],
    tactics: [],
  },
  techniqueLoading: true,
}

const capabilitySlice = createSlice({
  name: 'capability',
  initialState,
  reducers: {
    fetchTactics(state) {
      state.tacticsLoading = true
    },
    fetchTacticsFulfilled(state, action: PayloadAction<ITactic[]>) {
      state.tactics = action.payload
      state.tacticsLoading = false
    },
    fetchTacticsRejected(state, _action: PayloadAction<AjaxError>) {
      state.tactics = initialState.tactics
      state.tacticsLoading = false
    },
    fetchTacticsCancelled(state) {
      state.tacticsLoading = false
    },
    fetchTactic(state, _action: PayloadAction<string>) {
      state.tacticLoading = true
    },
    fetchTacticFulfilled(state, action: PayloadAction<ITactic>) {
      state.tactic = action.payload
      state.tacticLoading = false
    },
    fetchTacticRejected(state, _action: PayloadAction<AjaxError>) {
      state.tactic = initialState.tactic
      state.tacticLoading = false
    },
    fetchTacticCancelled(state) {
      state.tacticLoading = false
    },
    resetTactic(state) {
      state.tactic = initialState.tactic
    },
    fetchTechnique(state, _action: PayloadAction<string>) {
      state.techniqueLoading = true
    },
    fetchTechniqueFulfilled(state, action: PayloadAction<ITechnique>) {
      state.technique = action.payload
      state.techniqueLoading = false
    },
    fetchTechniqueRejected(state, _action: PayloadAction<AjaxError>) {
      state.technique = initialState.technique
      state.techniqueLoading = false
    },
    fetchTechniqueCancelled(state) {
      state.techniqueLoading = false
    },
    resetTechnique(state) {
      state.technique = initialState.technique
    },
  },
})

export const {
  fetchTactics,
  fetchTacticsFulfilled,
  fetchTacticsRejected,
  fetchTacticsCancelled,
  fetchTactic,
  fetchTacticFulfilled,
  fetchTacticRejected,
  fetchTacticCancelled,
  resetTactic,
  fetchTechnique,
  fetchTechniqueFulfilled,
  fetchTechniqueRejected,
  fetchTechniqueCancelled,
  resetTechnique,
} = capabilitySlice.actions

export const selectTacticsLoading = (state: TRootState) =>
  state.capability.tacticsLoading
export const selectTactics = (state: TRootState) => state.capability.tactics
export const selectTacticLoading = (state: TRootState) =>
  state.capability.tacticLoading
export const selectTactic = (state: TRootState) => state.capability.tactic
export const selectTechniqueLoading = (state: TRootState) =>
  state.capability.techniqueLoading
export const selectTechnique = (state: TRootState) => state.capability.technique

export default capabilitySlice.reducer
