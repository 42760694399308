import { TableBody, TableRow } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

import { MalwareTableBodyCell } from './MalwareTableBody'

export const FirstRowLoading = () => (
  <TableRow>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="3rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="3rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="3rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="3rem" />
    </MalwareTableBodyCell>
    {/* TODO: hide first seen loading
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.75rem" />
    </MalwareTableBodyCell> */}
    <MalwareTableBodyCell>
      <Skeleton type="body" width="1.5rem" />
    </MalwareTableBodyCell>
  </TableRow>
)

export const SecondRowLoading = () => (
  <TableRow>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.5rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.5rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.5rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.5rem" />
    </MalwareTableBodyCell>
    {/* TODO: hide first seen loading
    <MalwareTableBodyCell>
      <Skeleton type="body" width="5rem" />
    </MalwareTableBodyCell> */}
    <MalwareTableBodyCell>
      <Skeleton type="body" width="1.5rem" />
    </MalwareTableBodyCell>
  </TableRow>
)

export const ThirdRowLoading = () => (
  <TableRow>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="9rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="9rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="9rem" />
    </MalwareTableBodyCell>
    <MalwareTableBodyCell>
      <Skeleton type="body" width="9rem" />
    </MalwareTableBodyCell>
    {/* TODO: hide first seen loading
    <MalwareTableBodyCell>
      <Skeleton type="body" width="6.75rem" />
    </MalwareTableBodyCell> */}
    <MalwareTableBodyCell>
      <Skeleton type="body" width="1.5rem" />
    </MalwareTableBodyCell>
  </TableRow>
)

export const MalwareTableLoading = () => (
  <TableBody>
    <FirstRowLoading />
    <SecondRowLoading />
    <ThirdRowLoading />
    <FirstRowLoading />
    <SecondRowLoading />
    <ThirdRowLoading />
    <FirstRowLoading />
    <SecondRowLoading />
    <ThirdRowLoading />
    <FirstRowLoading />
    <SecondRowLoading />
    <ThirdRowLoading />
    <FirstRowLoading />
    <SecondRowLoading />
    <ThirdRowLoading />
    <FirstRowLoading />
  </TableBody>
)
