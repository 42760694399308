import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { IAdversary } from 'store/types/entityTypes/adversary'
import { ITechnique } from 'store/types/entityTypes/capability'
import { IDomain } from 'store/types/entityTypes/domain'
import { IIP } from 'store/types/entityTypes/ip'
import { IMalware } from 'store/types/entityTypes/malware'
import { IRawIntel } from 'store/types/entityTypes/rawIntel'
import { IReport, TSearchReportType } from 'store/types/entityTypes/report'
import { ISample } from 'store/types/entityTypes/sample'
import { ITargetCountry, ITargetIndustry } from 'store/types/entityTypes/target'
import {
  IFetchSearchResultPayload,
  ISearchReportDateTime,
  ISearchSlice,
  ISuggestWord,
  TSearchResultType,
} from 'store/types/slicesTypes/search'

interface IFetchSearchResultDataPayload extends IFetchSearchResultPayload {
  offset: number
}

const initialContent = {
  count: 0,
  isCountLoading: false,
  isDataLoading: false,
  data: [],
  hasMoreData: true,
}

const initialState: ISearchSlice = {
  searchResultType: 'other',
  searchRawIntel: { searchResultType: 'other', warningOpen: true },
  suggestWords: [],
  searchHistory: [],
  selectedSearchReportTypes: [
    'flashReport',
    'cyberAffairs',
    'monthlyReport',
    'CTRReport',
    'VIRReport',
  ],
  selectedSearchReportDateTime: {
    startDate: null,
    endDate: null,
    rangeOption: 'all',
  },
  reports: initialContent,
  adversaries: initialContent,
  malwares: initialContent,
  samples: initialContent,
  ips: initialContent,
  domains: initialContent,
  techniques: initialContent,
  rawIntels: initialContent,
  isMatchedDataLoading: false,
  matchedAdversary: null,
  matchedMalware: null,
  matchedCountry: null,
  matchedIndustry: null,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    checkSearchResultType(_state, _action: PayloadAction<string>) {},
    checkSearchResultTypeFulfilled(
      state,
      action: PayloadAction<TSearchResultType>
    ) {
      state.searchResultType = action.payload
    },
    checkSearchResultTypeRejected(state, _action: PayloadAction<AjaxError>) {
      state.searchResultType = initialState.searchResultType
    },
    resetSearchResultType(state) {
      state.searchResultType = initialState.searchResultType
    },
    checkSearchRawIntelResultType(_state, _action: PayloadAction<string>) {},
    checkSearchRawIntelResultTypeFulfilled(
      state,
      action: PayloadAction<TSearchResultType>
    ) {
      state.searchRawIntel.searchResultType = action.payload
    },
    checkSearchRawIntelResultTypeRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.searchRawIntel.searchResultType =
        initialState.searchRawIntel.searchResultType
    },
    openSearchRawIntelWarning(state) {
      state.searchRawIntel.warningOpen = true
    },
    closeSearchRawIntelWarning(state) {
      state.searchRawIntel.warningOpen = false
    },
    fetchSuggestWords() {},
    fetchSuggestWordsFulfilled(state, action: PayloadAction<ISuggestWord[]>) {
      state.suggestWords = action.payload
    },
    fetchSuggestWordsRejected(state, _action: PayloadAction<AjaxError>) {
      state.suggestWords = initialState.suggestWords
    },
    fetchSuggestWordsCancelled() {},
    setSelectedSearchReportTypes(
      state,
      action: PayloadAction<TSearchReportType[]>
    ) {
      state.selectedSearchReportTypes = action.payload
    },
    resetSelectedSearchReportTypes(state) {
      state.selectedSearchReportTypes = initialState.selectedSearchReportTypes
    },
    setSelectedSearchReportTime(
      state,
      action: PayloadAction<ISearchReportDateTime>
    ) {
      state.selectedSearchReportDateTime = action.payload
    },
    resetSelectedSearchReportTime(state) {
      state.selectedSearchReportDateTime =
        initialState.selectedSearchReportDateTime
    },
    /* count fetch start */
    fetchSearchReportsCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.reports.isCountLoading = true
    },
    fetchSearchReportsCountFulfilled(state, action: PayloadAction<number>) {
      state.reports.count = action.payload
      state.reports.isCountLoading = false
    },
    fetchSearchReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.reports.count = initialState.reports.count
      state.reports.isCountLoading = false
    },
    fetchSearchReportsCountCancelled(state) {
      state.reports.isCountLoading = false
    },
    fetchSearchAdversariesCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.adversaries.isCountLoading = true
    },
    fetchSearchAdversariesCountFulfilled(state, action: PayloadAction<number>) {
      state.adversaries.count = action.payload
      state.adversaries.isCountLoading = false
    },
    fetchSearchAdversariesCountRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.adversaries.count = initialState.adversaries.count
      state.adversaries.isCountLoading = false
    },
    fetchSearchAdversariesCountCancelled(state) {
      state.adversaries.isCountLoading = false
    },
    fetchSearchMalwaresCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.malwares.isCountLoading = true
    },
    fetchSearchMalwaresCountFulfilled(state, action: PayloadAction<number>) {
      state.malwares.count = action.payload
      state.malwares.isCountLoading = false
    },
    fetchSearchMalwaresCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.malwares.count = initialState.malwares.count
      state.malwares.isCountLoading = false
    },
    fetchSearchMalwaresCountCancelled(state) {
      state.malwares.isCountLoading = false
    },
    fetchSearchSamplesCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.samples.isCountLoading = true
    },
    fetchSearchSamplesCountFulfilled(state, action: PayloadAction<number>) {
      state.samples.count = action.payload
      state.samples.isCountLoading = false
    },
    fetchSearchSamplesCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.samples.count = initialState.samples.count
      state.samples.isCountLoading = false
    },
    fetchSearchSamplesCountCancelled(state) {
      state.samples.isCountLoading = false
    },
    fetchSearchIPsCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.ips.isCountLoading = true
    },
    fetchSearchIPsCountFulfilled(state, action: PayloadAction<number>) {
      state.ips.count = action.payload
      state.ips.isCountLoading = false
    },
    fetchSearchIPsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.ips.count = initialState.ips.count
      state.ips.isCountLoading = false
    },
    fetchSearchIPsCountCancelled(state) {
      state.ips.isCountLoading = false
    },
    fetchSearchDomainsCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.domains.isCountLoading = true
    },
    fetchSearchDomainsCountFulfilled(state, action: PayloadAction<number>) {
      state.domains.count = action.payload
      state.domains.isCountLoading = false
    },
    fetchSearchDomainsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.domains.count = initialState.domains.count
      state.domains.isCountLoading = false
    },
    fetchSearchDomainsCountCancelled(state) {
      state.domains.isCountLoading = false
    },
    fetchSearchTechniquesCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.techniques.isCountLoading = true
    },
    fetchSearchTechniquesCountFulfilled(state, action: PayloadAction<number>) {
      state.techniques.count = action.payload
      state.techniques.isCountLoading = false
    },
    fetchSearchTechniquesCountRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.techniques.count = initialState.techniques.count
      state.techniques.isCountLoading = false
    },
    fetchSearchTechniquesCountCancelled(state) {
      state.techniques.isCountLoading = false
    },
    fetchSearchRawIntelsCount(
      state,
      _action: PayloadAction<IFetchSearchResultPayload>
    ) {
      state.rawIntels.isCountLoading = true
    },
    fetchSearchRawIntelsCountFulfilled(state, action: PayloadAction<number>) {
      state.rawIntels.count = action.payload
      state.rawIntels.isCountLoading = false
    },
    fetchSearchRawIntelsCountRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.rawIntels.count = initialState.rawIntels.count
      state.rawIntels.isCountLoading = false
    },
    fetchSearchRawIntelsCountCancelled(state) {
      state.rawIntels.isCountLoading = false
    },
    resetAllSearchCount(state) {
      state.reports.count = initialState.reports.count
      state.adversaries.count = initialState.adversaries.count
      state.malwares.count = initialState.malwares.count
      state.samples.count = initialState.samples.count
      state.ips.count = initialState.ips.count
      state.domains.count = initialState.domains.count
      state.techniques.count = initialState.techniques.count
      state.rawIntels.count = initialState.rawIntels.count
    },
    /* count fetch end */

    /* data fetch start */
    // reports
    fetchSearchReportsData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.reports.isDataLoading = true
    },
    fetchSearchReportsDataFulfilled(state, action: PayloadAction<IReport[]>) {
      if (action.payload.length > 0) {
        state.reports.data = [...state.reports.data, ...action.payload]
      } else {
        state.reports.hasMoreData = false
      }
      state.reports.isDataLoading = false
    },
    fetchSearchReportsDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.reports.data = initialState.reports.data
      state.reports.isDataLoading = false
    },
    fetchSearchReportsDataCancelled(state) {
      state.reports.isDataLoading = false
    },
    resetSearchReportsData(state) {
      state.reports.data = initialState.reports.data
      state.reports.hasMoreData = initialState.reports.hasMoreData
    },
    // adversaries
    fetchSearchAdversariesData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.adversaries.isDataLoading = true
    },
    fetchSearchAdversariesDataFulfilled(
      state,
      action: PayloadAction<IAdversary[]>
    ) {
      if (action.payload.length > 0) {
        state.adversaries.data = [...state.adversaries.data, ...action.payload]
      } else {
        state.adversaries.hasMoreData = false
      }
      state.adversaries.isDataLoading = false
    },
    fetchSearchAdversariesDataRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.adversaries.data = initialState.adversaries.data
      state.adversaries.isDataLoading = false
    },
    fetchSearchAdversariesDataCancelled(state) {
      state.adversaries.isDataLoading = false
    },
    resetSearchAdversariesData(state) {
      state.adversaries.data = initialState.adversaries.data
      state.adversaries.hasMoreData = initialState.adversaries.hasMoreData
    },
    // malwares
    fetchSearchMalwaresData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.malwares.isDataLoading = true
    },
    fetchSearchMalwaresDataFulfilled(state, action: PayloadAction<IMalware[]>) {
      if (action.payload.length > 0) {
        state.malwares.data = [...state.malwares.data, ...action.payload]
      } else {
        state.malwares.hasMoreData = false
      }
      state.malwares.isDataLoading = false
    },
    fetchSearchMalwaresDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.malwares.data = initialState.malwares.data
      state.malwares.isDataLoading = false
    },
    fetchSearchMalwaresDataCancelled(state) {
      state.malwares.isDataLoading = false
    },
    resetSearchMalwaresData(state) {
      state.malwares.data = initialState.malwares.data
      state.malwares.hasMoreData = initialState.malwares.hasMoreData
    },
    // samples
    fetchSearchSamplesData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.samples.isDataLoading = true
    },
    fetchSearchSamplesDataFulfilled(state, action: PayloadAction<ISample[]>) {
      if (action.payload.length > 0) {
        state.samples.data = [...state.samples.data, ...action.payload]
      } else {
        state.samples.hasMoreData = false
      }
      state.samples.isDataLoading = false
    },
    fetchSearchSamplesDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.samples.data = initialState.samples.data
      state.samples.isDataLoading = false
    },
    fetchSearchSamplesDataCancelled(state) {
      state.samples.isDataLoading = false
    },
    resetSearchSamplesData(state) {
      state.samples.data = initialState.samples.data
      state.samples.hasMoreData = initialState.samples.hasMoreData
    },
    // techniques
    fetchSearchTechniquesData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.techniques.isDataLoading = true
    },
    fetchSearchTechniquesDataFulfilled(
      state,
      action: PayloadAction<ITechnique[]>
    ) {
      if (action.payload.length > 0) {
        state.techniques.data = [...state.techniques.data, ...action.payload]
      } else {
        state.techniques.hasMoreData = false
      }
      state.techniques.isDataLoading = false
    },
    fetchSearchTechniquesDataRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.techniques.data = initialState.techniques.data
      state.techniques.isDataLoading = false
    },
    fetchSearchTechniquesDataCancelled(state) {
      state.techniques.isDataLoading = false
    },
    resetSearchTechniquesData(state) {
      state.techniques.data = initialState.techniques.data
      state.techniques.hasMoreData = initialState.techniques.hasMoreData
    },
    // raw intels
    fetchSearchRawIntelsData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.rawIntels.isDataLoading = true
    },
    fetchSearchRawIntelsDataFulfilled(
      state,
      action: PayloadAction<IRawIntel[]>
    ) {
      if (action.payload.length > 0) {
        state.rawIntels.data = [...state.rawIntels.data, ...action.payload]
      } else {
        state.rawIntels.hasMoreData = false
      }
      state.rawIntels.isDataLoading = false
    },
    fetchSearchRawIntelsDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.rawIntels.data = initialState.rawIntels.data
      state.rawIntels.isDataLoading = false
    },
    fetchSearchRawIntelsDataCancelled(state) {
      state.rawIntels.isDataLoading = false
    },
    resetSearchRawIntelsData(state) {
      state.rawIntels.data = initialState.rawIntels.data
      state.rawIntels.hasMoreData = initialState.rawIntels.hasMoreData
    },
    // ips
    fetchSearchIPsData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.ips.isDataLoading = true
    },
    fetchSearchIPsDataFulfilled(state, action: PayloadAction<IIP[]>) {
      if (action.payload.length > 0) {
        state.ips.data = [...state.ips.data, ...action.payload]
      } else {
        state.ips.hasMoreData = false
      }
      state.ips.isDataLoading = false
    },
    fetchSearchIPsDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.ips.data = initialState.ips.data
      state.ips.isDataLoading = false
    },
    fetchSearchIPsDataCancelled(state) {
      state.ips.isDataLoading = false
    },
    resetSearchIPsData(state) {
      state.ips.data = initialState.ips.data
      state.ips.hasMoreData = initialState.ips.hasMoreData
    },
    // domains
    fetchSearchDomainsData(
      state,
      _action: PayloadAction<IFetchSearchResultDataPayload>
    ) {
      state.domains.isDataLoading = true
    },
    fetchSearchDomainsDataFulfilled(state, action: PayloadAction<IDomain[]>) {
      if (action.payload.length > 0) {
        state.domains.data = [...state.domains.data, ...action.payload]
      } else {
        state.domains.hasMoreData = false
      }
      state.domains.isDataLoading = false
    },
    fetchSearchDomainsDataRejected(state, _action: PayloadAction<AjaxError>) {
      state.domains.data = initialState.domains.data
      state.domains.isDataLoading = false
    },
    fetchSearchDomainsDataCancelled(state) {
      state.domains.isDataLoading = false
    },
    resetSearchDomainsData(state) {
      state.domains.data = initialState.domains.data
      state.domains.hasMoreData = initialState.domains.hasMoreData
    },
    /* data fetch end */

    /* matched card start */
    fetchMatchedAdversary(state, _action: PayloadAction<string>) {
      state.isMatchedDataLoading = true
    },
    fetchMatchedAdversaryFulfilled(state, action: PayloadAction<IAdversary>) {
      state.matchedAdversary = action.payload
      state.isMatchedDataLoading = false
    },
    fetchMatchedAdversaryRejected(state, _action: PayloadAction<AjaxError>) {
      state.matchedAdversary = initialState.matchedAdversary
      state.isMatchedDataLoading = false
    },
    fetchMatchedAdversaryCancelled(state) {
      state.isMatchedDataLoading = false
    },
    fetchMatchedMalware(state, _action: PayloadAction<string>) {
      state.isMatchedDataLoading = true
    },
    fetchMatchedMalwareFulfilled(state, action: PayloadAction<IMalware>) {
      state.matchedMalware = action.payload
      state.isMatchedDataLoading = false
    },
    fetchMatchedMalwareRejected(state, _action: PayloadAction<AjaxError>) {
      state.matchedMalware = initialState.matchedMalware
      state.isMatchedDataLoading = false
    },
    fetchMatchedMalwareCancelled(state) {
      state.isMatchedDataLoading = false
    },
    setMatchedTargetCountry(state, action: PayloadAction<ITargetCountry>) {
      state.matchedCountry = action.payload
    },
    setMatchedTargetIndustry(state, action: PayloadAction<ITargetIndustry>) {
      state.matchedIndustry = action.payload
    },
    /* matched card end */
  },
})

export const {
  checkSearchResultType,
  checkSearchResultTypeFulfilled,
  checkSearchResultTypeRejected,
  resetSearchResultType,
  checkSearchRawIntelResultType,
  checkSearchRawIntelResultTypeFulfilled,
  checkSearchRawIntelResultTypeRejected,
  openSearchRawIntelWarning,
  closeSearchRawIntelWarning,
  fetchSuggestWords,
  fetchSuggestWordsFulfilled,
  fetchSuggestWordsRejected,
  fetchSuggestWordsCancelled,
  setSelectedSearchReportTypes,
  resetSelectedSearchReportTypes,
  setSelectedSearchReportTime,
  resetSelectedSearchReportTime,
  /* count actions start */
  fetchSearchReportsCount,
  fetchSearchReportsCountFulfilled,
  fetchSearchReportsCountRejected,
  fetchSearchReportsCountCancelled,
  fetchSearchAdversariesCount,
  fetchSearchAdversariesCountFulfilled,
  fetchSearchAdversariesCountRejected,
  fetchSearchAdversariesCountCancelled,
  fetchSearchMalwaresCount,
  fetchSearchMalwaresCountFulfilled,
  fetchSearchMalwaresCountRejected,
  fetchSearchMalwaresCountCancelled,
  fetchSearchSamplesCount,
  fetchSearchSamplesCountFulfilled,
  fetchSearchSamplesCountRejected,
  fetchSearchSamplesCountCancelled,
  fetchSearchIPsCount,
  fetchSearchIPsCountFulfilled,
  fetchSearchIPsCountRejected,
  fetchSearchIPsCountCancelled,
  fetchSearchDomainsCount,
  fetchSearchDomainsCountFulfilled,
  fetchSearchDomainsCountRejected,
  fetchSearchDomainsCountCancelled,
  fetchSearchTechniquesCount,
  fetchSearchTechniquesCountFulfilled,
  fetchSearchTechniquesCountRejected,
  fetchSearchTechniquesCountCancelled,
  fetchSearchRawIntelsCount,
  fetchSearchRawIntelsCountFulfilled,
  fetchSearchRawIntelsCountRejected,
  fetchSearchRawIntelsCountCancelled,
  resetAllSearchCount,
  /* count actions end */
  /* data actions start */
  fetchSearchReportsData,
  fetchSearchReportsDataFulfilled,
  fetchSearchReportsDataRejected,
  fetchSearchReportsDataCancelled,
  resetSearchReportsData,
  fetchSearchAdversariesData,
  fetchSearchAdversariesDataFulfilled,
  fetchSearchAdversariesDataRejected,
  fetchSearchAdversariesDataCancelled,
  resetSearchAdversariesData,
  fetchSearchMalwaresData,
  fetchSearchMalwaresDataFulfilled,
  fetchSearchMalwaresDataRejected,
  fetchSearchMalwaresDataCancelled,
  resetSearchMalwaresData,
  fetchSearchSamplesData,
  fetchSearchSamplesDataFulfilled,
  fetchSearchSamplesDataRejected,
  fetchSearchSamplesDataCancelled,
  resetSearchSamplesData,
  fetchSearchIPsData,
  fetchSearchIPsDataFulfilled,
  fetchSearchIPsDataRejected,
  fetchSearchIPsDataCancelled,
  resetSearchIPsData,
  fetchSearchDomainsData,
  fetchSearchDomainsDataFulfilled,
  fetchSearchDomainsDataRejected,
  fetchSearchDomainsDataCancelled,
  resetSearchDomainsData,
  fetchSearchTechniquesData,
  fetchSearchTechniquesDataFulfilled,
  fetchSearchTechniquesDataRejected,
  fetchSearchTechniquesDataCancelled,
  resetSearchTechniquesData,
  fetchSearchRawIntelsData,
  fetchSearchRawIntelsDataFulfilled,
  fetchSearchRawIntelsDataRejected,
  fetchSearchRawIntelsDataCancelled,
  resetSearchRawIntelsData,
  /* data actions end */
  /* matched card start */
  fetchMatchedAdversary,
  fetchMatchedAdversaryFulfilled,
  fetchMatchedAdversaryRejected,
  fetchMatchedAdversaryCancelled,
  fetchMatchedMalware,
  fetchMatchedMalwareFulfilled,
  fetchMatchedMalwareRejected,
  fetchMatchedMalwareCancelled,
  setMatchedTargetCountry,
  setMatchedTargetIndustry,
  /* matched card end */
} = searchSlice.actions

export const selectSearchResultType = (state: TRootState) =>
  state.search.searchResultType

export const selectSearchResultIsNetwork = (state: TRootState) =>
  state.search.searchResultType === 'ip' ||
  state.search.searchResultType === 'domain'

export const selectSearchRawIntelResultType = (state: TRootState) =>
  state.search.searchRawIntel.searchResultType

export const selectRawIntelWarningOpen = (state: TRootState) =>
  state.search.searchRawIntel.warningOpen

export const selectSuggestWords = (state: TRootState) =>
  state.search.suggestWords

export const selectSelectedSearchReportTypes = (state: TRootState) =>
  state.search.selectedSearchReportTypes

export const selectSelectedSearchReportTime = (state: TRootState) =>
  state.search.selectedSearchReportDateTime

export const selectSearchReports = (state: TRootState) => state.search.reports

export const selectSearchAdversaries = (state: TRootState) =>
  state.search.adversaries

export const selectSearchMalwares = (state: TRootState) => state.search.malwares

export const selectSearchSamples = (state: TRootState) => state.search.samples

export const selectSearchTechniques = (state: TRootState) =>
  state.search.techniques

export const selectSearchRawIntels = (state: TRootState) =>
  state.search.rawIntels

export const selectSearchIPs = (state: TRootState) => state.search.ips

export const selectSearchDomains = (state: TRootState) => state.search.domains

export const selectMatchedCardLoading = (state: TRootState) =>
  state.search.isMatchedDataLoading

export const selectMatchedAdversary = (state: TRootState) =>
  state.search.matchedAdversary

export const selectMatchedMalware = (state: TRootState) =>
  state.search.matchedMalware

export const selectMatchedCountry = (state: TRootState) =>
  state.search.matchedCountry

export const selectMatchedIndustry = (state: TRootState) =>
  state.search.matchedIndustry

export default searchSlice.reducer
