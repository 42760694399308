import type { Action } from '@reduxjs/toolkit'
import { getRawIntelDetailById } from 'constants/api'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  fetchRawIntelDetail,
  fetchRawIntelDetailCancelled,
  fetchRawIntelDetailFulfilled,
  fetchRawIntelDetailRejected,
} from 'store/slices/rawIntel'
import { mapAPIRawIntelToState } from 'store/types/entityTypes/rawIntel'
import { IFetchRawIntelDetailResponse } from 'store/types/slicesTypes/rawIntelDetail'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const rawIntelDetailEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchRawIntelDetail.match),
    switchMap((action) =>
      fetchData<IFetchRawIntelDetailResponse>({
        url: getRawIntelDetailById(action.payload.id),
      }).pipe(
        map((response: AjaxResponse<IFetchRawIntelDetailResponse>) =>
          fetchRawIntelDetailFulfilled(mapAPIRawIntelToState(response.response))
        ),
        takeUntil(action$.pipe(filter(fetchRawIntelDetailCancelled.match))),
        catchError((error: AjaxError) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchRawIntelDetailRejected(error)).pipe(
              tap(() => {
                if (error.status === 402) {
                  action.payload.outOfAAPCallback()
                }
              })
            )
          )
        )
      )
    )
  )

export const rawIntelEpic = combineEpics(rawIntelDetailEpic)
