/**
 * 額外的掛在 theme 上面的 style constants，之後可直接從 Mui theme 取得
 */
const fixedConstants = {
  FIXED_TOP_HEIGHT: '2rem',
  FIXED_FOOTER_HEIGHT: '1.5rem',
  FIXED_NAVBAR_WIDTH: '2rem',
}

type TFixedConstantKeys = keyof typeof fixedConstants

export type IFixedConstants = {
  [K in TFixedConstantKeys]: string
}

export default fixedConstants
