import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { ExampleReportBlock } from 'components/Documents/ExampleReportBlock'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H3Title } from 'components/Documents/H3Title'
import { H4Title } from 'components/Documents/H4Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import {
  DocumentContentWidth,
  DocumentTimestamp,
  ScrollableContainerId,
} from 'constants/document'
import { getPageTitleReportIntroductionByTitle } from 'constants/pageTitle'
import {
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

const headCells = ['Name', 'Type', 'Sample SHA256', 'Attribution', 'First Seen']

const bodyRows = [
  {
    name: 'DbgPrint',
    type: 'RAT',
    sampleSha256:
      'DbgPrint (aka Waterbear) is a proprietary RAT used by the Chinese APT group Huapi. We dubbed this malware "DbgPrint" because of the export function in its earlier variants. DbgPrint is composed of a loader and an encrypted payload. The encrypted payload is stored either inside the loader or as a separate file. Upon execution, the payload will run in the memory section and download the RAT module. In our long-term investigation, Huapi actors tend to use DbgPrint as their second stage backdoor. Discovery made in recent sample show that DbgPrint is now equipped with an anti-AV module.',
    attribution: 'Huapi',
    firstSeen: '2009',
  },
]

export const FlashIntroductionPage = () => {
  const { t } = useTranslation(['reportIntroduction'])
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  const tocList = [
    {
      id: 'introduction',
      text: t('flash.introduction.title', { ns: 'reportIntroduction' }),
    },
    {
      id: 'flash-report-in-detail',
      text: t('flash.flashReportInDetail.title', { ns: 'reportIntroduction' }),
    },
    {
      id: 'example-report-flash-report-20230915',
      text: t('flash.exampleReport.title', {
        ns: 'reportIntroduction',
        reportName: 'Flash Report 20230915',
      }),
    },
  ]

  return (
    <>
      <Helmet>
        <title>
          {getPageTitleReportIntroductionByTitle(
            t('flash.title', {
              ns: 'reportIntroduction',
            })
          )}
        </title>
      </Helmet>
      <Box sx={{ display: 'flex' }}>
        <Stack
          id={ScrollableContainerId.TOP_ID}
          sx={{
            width: DocumentContentWidth.WIDTH,
            maxWidth: DocumentContentWidth.MAX_WIDTH,
            minWidth: DocumentContentWidth.MIN_WIDTH,
            px: 10,
            py: 4,
            gap: 2,
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <H2ColorTitle
            title={t('flash.title', { ns: 'reportIntroduction' })}
          />
          <DateLabel
            text={getDateStringByTimestampsInSeconds(
              DocumentTimestamp.REPORT_INTRODUCTION_TIMESTAMP
            )}
          />
          <TocLink
            text={t('flash.introduction.title', { ns: 'reportIntroduction' })}
            hash="introduction"
          />
          <Description>
            <Trans t={t} i18nKey="flash.introduction.description.paragraphOne">
              <Typography variant="reportInlineTextBold">
                APT in Asia Flash (aka Flash Report)
              </Typography>
              provides timely, accurate, and actionable intelligence. Users can
              use Flash Report to have just-in-time alerts of the latest APT
              intrusions.
            </Trans>
          </Description>
          <Description>
            {t('flash.introduction.description.paragraphTwo', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <TocLink
            text={t('flash.flashReportInDetail.title', {
              ns: 'reportIntroduction',
            })}
            hash="flash-report-in-detail"
          />
          <Description>
            {t('flash.flashReportInDetail.description', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <H4Title
            title={t('flash.publicationFrequency.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            {t('flash.publicationFrequency.description', {
              ns: 'reportIntroduction',
            })}
          </Description>
          <H4Title
            title={t('flash.reportLanguage.title', {
              ns: 'reportIntroduction',
            })}
          />
          <Description>
            <Trans t={t} i18nKey="flash.reportLanguage.description">
              Flash Report is available in both Mandarin and English versions.
              The difference between the two versions lies in the language used
              to write
              <InlineBlock text="Executive Summary" />. in the report.
            </Trans>
          </Description>
          <H4Title
            title={t('flash.reportStructure.title', {
              ns: 'reportIntroduction',
            })}
          />
          <UnorderedList>
            <ListItem>
              {t('flash.reportStructure.list.executiveSummary', {
                ns: 'reportIntroduction',
              })}
              <UnorderedList>
                <ListItem>
                  {t('flash.reportStructure.list.technicalDetails', {
                    ns: 'reportIntroduction',
                  })}
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              {t('flash.reportStructure.list.malwareTable', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
            <ListItem>
              {t('flash.reportStructure.list.sampleList', {
                ns: 'reportIntroduction',
              })}
            </ListItem>
          </UnorderedList>
          <TocLink
            text={t('flash.exampleReport.title', {
              ns: 'reportIntroduction',
              reportName: 'Flash Report 20230915',
            })}
            hash="example-report-flash-report-20230915"
          />
          <Description>
            {t('flash.exampleReport.englishVersion', {
              ns: 'reportIntroduction',
              reportName: 'Flash Report 20230915',
            })}
            <br />
            <TextLink
              size="large"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=flash_report-20230913053808`}
            >
              https://threatvision.org/reports/detail?alias=flash_report-20230913053808
            </TextLink>
          </Description>
          <Description>
            {t('flash.exampleReport.mandarinVersion', {
              ns: 'reportIntroduction',
              reportName: 'Flash Report 20230915 - 繁體中文版',
            })}
            <br />
            <TextLink
              size="large"
              to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=flash_report-20230915005552`}
            >
              https://threatvision.org/reports/detail?alias=flash_report-20230915005552
            </TextLink>
          </Description>
          <ExampleReportBlock
            title="Executive Summary"
            description={t('flash.executiveSummaryDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <UnorderedList>
              <ListItem>
                According to our private source line, the attack was targeting
                entities in Taiwan.
              </ListItem>
              <ListItem>
                The C2 of this attack is{' '}
                <InlineBlock text="naroupet.randaln.com" />.
              </ListItem>
            </UnorderedList>
            <Description>
              <Typography variant="reportInlineTextBold">
                Technical Details:{' '}
              </Typography>
              In the attack, the actor prepared a launcher (Sample 1), a loader
              (Sample 2), and a payload (Sample 3) to deploy its proprietary
              RAT, DbgPrint.
            </Description>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Malware Table"
            description={t('flash.malwareTableDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <TableContainer sx={{ overflow: 'initial' }}>
              <Table>
                <TableHead>
                  <TableHeaderRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell}>{headCell}</TableCell>
                    ))}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {bodyRows.map((bodyRow, index) => (
                    <TableBodyRow key={bodyRow.name} index={index}>
                      <TableCell>{bodyRow.name}</TableCell>
                      <TableCell>{bodyRow.type}</TableCell>
                      <TableCell>{bodyRow.sampleSha256}</TableCell>
                      <TableCell>{bodyRow.attribution}</TableCell>
                      <TableCell>{bodyRow.firstSeen}</TableCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ExampleReportBlock>
          <ExampleReportBlock
            title="Sample List"
            description={t('flash.sampleListDescription', {
              ns: 'reportIntroduction',
            })}
          >
            <H3Title title="Sample 1" />
            <UnorderedList>
              <ListItem>
                Hash:
                <br />
                <TextLink
                  size="large"
                  to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=6a2274f162cd6b5ad16c76852aebb5c25df11805de87dedb848f4990f861db33`}
                >
                  6a2274f162cd6b5ad16c76852aebb5c25df11805de87dedb848f4990f861db33
                </TextLink>
              </ListItem>
              <ListItem>File Name: AvamarUpdate.exe</ListItem>
              <ListItem>Timestamp: 1989-11-27 12:53:55</ListItem>
              <ListItem>C2: N/A</ListItem>
              <ListItem>Traffic Pattern:</ListItem>
            </UnorderedList>
            <CodeBlock code="N/A" />
          </ExampleReportBlock>
          <Box id={ScrollableContainerId.BOTTOM_ID} />
        </Stack>
        <TocList tocList={tocList} />
      </Box>
    </>
  )
}
