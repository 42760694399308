import CABackgroundImage from 'assets/report/CA-background.jpg'
import CTRBackgroundImage from 'assets/report/CTR-background.jpg'
import flashBackgroundImage from 'assets/report/flash-background.jpg'
import monthlyBackgroundImage from 'assets/report/monthly-background.jpg'
import VIRRBackgroundImage from 'assets/report/VIRR-background.jpg'
import {
  APT_ROUTE,
  CYBER_AFFAIRS_CATEGORY_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TReportType } from 'store/types/entityTypes/report'

interface IReportListInfo {
  title: string
  digest: NonNullable<ReactNode>
  reportCategoryRoute: string
  backgroundImage: string
}

export const useReportListInfo = () => {
  const { t } = useTranslation(['report'])

  const reportListInfo: Record<
    Exclude<TReportType, 'customReport'>,
    IReportListInfo
  > = {
    flashReport: {
      title: t('flashReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="flashReports.description">
          APT in Asia Flash (aka Flash Report) provides timely, accurate, and
          actionable intelligence. Users can use Flash Report to have
          just-in-Time alerts of the latest APT intrusions.
          <br />
          Flash Report is published twice a week. Users will have at least 100
          Flash Reports a year. Each Flash Report details one specific targeted
          attack and provides all essential Indicators of Compromise (IoCs).
        </Trans>
      ),
      reportCategoryRoute: APT_ROUTE,
      backgroundImage: flashBackgroundImage,
    },
    monthlyReport: {
      title: t('monthlyReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="monthlyReports.description">
          APT in Asia Monthly (aka Monthly Report) provides strategic and
          actionable intelligence in the Asia Pacific region. Monthly Report
          will connect the dots between cyberattacks and recent political
          events, policies, and foreign affairs. Users can use Monthly Report to
          understand the latest trends and the current threat landscape.
          <br />
          Monthly Report is published every month. Users will have 12 Monthly
          Reports a year. Each Monthly Report summarizes 13 – 16 APT attack
          cases in the Asia Pacific region over the past month.
        </Trans>
      ),
      reportCategoryRoute: APT_ROUTE,
      backgroundImage: monthlyBackgroundImage,
    },
    CTRReport: {
      title: t('ctReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="ctReports.description">
          APT Campaign Tracking Report (aka CTR) provides TeamT5’s comprehensive
          understanding of significant threat groups/campaigns. Users can use
          CTR to enhance their knowledge of trends and challenges in the APAC
          region with a broader perspective.
          <br />
          We will publish two CTR at the end of every quarter. Users will have 8
          CTR a year. Each CTR provides an in-depth analysis on a particular
          threat group/campaign, covering its TTPs, target scope, etc., over the
          past 2-3 years. Moreover, at the end of Q2 and Q4, CTR will provide an
          APT Threat Landscape report covering major incidents, popular TTPs,
          most targeted industries, and top malwares/APTs over the past two
          quarters.
        </Trans>
      ),
      reportCategoryRoute: APT_ROUTE,
      backgroundImage: CTRBackgroundImage,
    },
    cyberAffairs: {
      title: t('cyberAffairsReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="cyberAffairsReports.description">
          Cyber Affairs Biweekly Report (aka Biweekly Report) provides strategic
          cyber intelligence about the Chinese-speaking cyber world. Users of
          the Biweekly Report can understand the cyber capabilities of the
          emerging superpower – China.
          <br />
          Biweekly Report is published every two weeks. Users will have 24
          Biweekly Reports a year. Each Biweekly Report provides a quick recap
          of cybersecurity news in the Chinese-speaking cyber world, covering
          the latest policies, regulations, to cyber incidents. Moreover, each
          report provides a strategic analysis of Chinese cyber power or a deep
          analysis of China’s Information Operation.
        </Trans>
      ),
      reportCategoryRoute: CYBER_AFFAIRS_CATEGORY_ROUTE,
      backgroundImage: CABackgroundImage,
    },
    VIRReport: {
      title: t('viReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="viReports.description">
          Vulnerability Insights Report (aka VIR) provides technical detail
          about critical and highly exploitable vulnerabilities. Users can use
          VIR to mitigate the threat posed by these critical and highly
          exploitable vulnerabilities. (Vulnerability Insights Report (VIR) is
          the new name of the old series, Vulnerability Mitigation and Response
          Report (aka VMRR).)
          <br />
          VIR is published every two weeks. Users will have 24 VIR a year. Each
          VIR focuses on one critical and highly exploitable vulnerability.
          Moreover, VIR depicts the possible attacking scenario and provides the
          detection tool.
        </Trans>
      ),
      reportCategoryRoute: VULNERABILITY_ROUTE,
      backgroundImage: VIRRBackgroundImage,
    },
    PMReport: {
      title: t('pmReports.title', { ns: 'report' }),
      digest: (
        <Trans t={t} i18nKey="pmReports.description">
          Patch Management Report (aka PMR) provides all the relevant
          information on all the critical and highly exploitable
          vulnerabilities. Users can use PMR to prioritize their patch
          management.
          <br />
          PMR will be updated every two weeks. Users will have 24 PMR a year.
          Each PMR summarizes around 100 most critical vulnerabilities during
          the period in a table and lists all the essential information about
          these vulnerabilities, such as the affected products, patching
          details, and even publicly available PoC codes.
        </Trans>
      ),
      reportCategoryRoute: VULNERABILITY_ROUTE,
      backgroundImage: VIRRBackgroundImage,
    },
  }

  return reportListInfo
}
