import { createContext, Dispatch, SetStateAction } from 'react'

interface IGoback {
  backTo: boolean
  setBackTo: Dispatch<SetStateAction<boolean>>
}

/**
 * @description 儲存當下是否需要顯示 goback，來源是 App.tsx 的一個 useState [backTo, setBackTo]
 * 使用方式為呼叫 useGoBack 這個 custom-hook
 */
export const GobackContext = createContext<IGoback>({
  backTo: false,
  setBackTo: () => {},
})
