import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { SampleLoading } from 'components/Loading/SampleLoading'
import { NoData } from 'components/NoData/NoData'
import { SamplePreview } from 'components/Sample/SamplePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchSampleRelationParentSamples,
  fetchSampleRelationParentSamplesCancelled,
  resetSampleRelationParentSamples,
  selectHasMoreSampleRelationParentSamples,
  selectSampleRelationParentSamples,
  selectSampleRelationParentSamplesLoading,
} from 'store/slices/sampleDetail'

import { ISampleTabContentProps } from '../../type'

const SCROLLABLE_CONTAINER_ID = 'sample-detail-relation-child-samples-container'

export const ParentSamples = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sampleRelationParentSamplesLoading = useAppSelector(
    selectSampleRelationParentSamplesLoading
  )
  const sampleRelationParentSamples = useAppSelector(
    selectSampleRelationParentSamples
  )
  const hasMoreSampleRelationParentSamples = useAppSelector(
    selectHasMoreSampleRelationParentSamples
  )
  const [isScrollable, ref, node] = useIsScrollable([
    sampleRelationParentSamples,
  ])

  const handleFetchRelationParentSamples = (refetch?: boolean) => {
    dispatch(
      fetchSampleRelationParentSamples({
        offset: refetch ? 0 : sampleRelationParentSamples.length,
        sampleId: searchText,
      })
    )
  }

  useEffect(() => {
    handleFetchRelationParentSamples(true)

    return () => {
      dispatch(fetchSampleRelationParentSamplesCancelled())
      dispatch(resetSampleRelationParentSamples())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreSampleRelationParentSamples) {
      handleFetchRelationParentSamples()
    }
  }, [
    isScrollable,
    hasMoreSampleRelationParentSamples,
    node,
    sampleRelationParentSamples.length,
  ])

  if (
    sampleRelationParentSamplesLoading &&
    sampleRelationParentSamples.length === 0
  ) {
    return <SampleLoading />
  }

  if (sampleRelationParentSamples.length === 0) {
    return <NoData />
  }

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto' }}
    >
      <InfiniteScrollWrapper
        dataLength={sampleRelationParentSamples.length}
        next={handleFetchRelationParentSamples}
        hasMore={hasMoreSampleRelationParentSamples}
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
        scrollThreshold="0px"
      >
        {sampleRelationParentSamples.map((sample) => (
          <Box
            key={sample.id}
            sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
          >
            <SamplePreview {...sample} />
          </Box>
        ))}
      </InfiniteScrollWrapper>
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
