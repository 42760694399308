import { Box, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation } from 'react-router-dom'

import { AccordionNavbar } from './AccordionNavbar'

export const APIDocumentPage = () => {
  const theme = useTheme()
  const { hash } = useLocation()

  useEffect(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>API Document - ThreatVision</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          display: 'flex',
          bgcolor: theme.colors.BLACK_95,
        }}
      >
        <AccordionNavbar />
        <Outlet />
      </Box>
    </>
  )
}
