/**
 * @description Check the string is IPv4 address or not
 * @ref https://melvingeorge.me/blog/check-if-string-is-valid-ip-address-javascript
 */
export const checkStringIsIP = (string: string) => {
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi

  return regexExp.test(string)
}

// https://stackoverflow.com/a/5717133
export const checkIsValidURL = (string: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%@_.~+=,:;]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(string)
}

export const checkIsNumber = (string: string) =>
  !Number.isNaN(Number(string)) && !Number.isNaN(parseFloat(string))

export const checkTextIncludingIgnoreCase = (
  checkingText: string,
  targetText: string
): boolean => checkingText.toLowerCase().includes(targetText.toLowerCase())
