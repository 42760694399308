import { checkIsNumber } from './checkString'

// 把巢狀的物件轉為 formData 格式，合併到傳入的 formData
export const appendObjectToFormData = (
  formData: FormData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  parentKey: string
) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      appendObjectToFormData(
        formData,
        data[key],
        parentKey
          ? checkIsNumber(key)
            ? `${parentKey}[]`
            : `${parentKey}[${key}]`
          : key
      )
    })
  } else {
    const value = data === null ? '' : data

    formData.append(parentKey, value)
  }
}
