import { Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { CAPABILITY_ROUTE, TECHNIQUE_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { Link } from 'react-router-dom'
import { ITechnique } from 'store/types/entityTypes/capability'

interface ISubTechnique extends ITechnique {
  lastClickId: string
  tacticId: string
  techniqueId: string
}

export const SubTechnique = ({
  id,
  name,
  lastClickId,
  tacticId,
  techniqueId,
}: ISubTechnique) => {
  const theme = useTheme()

  const isLastClick = lastClickId === `${tacticId}-${techniqueId}`

  return (
    <Stack
      sx={{
        bgcolor: theme.colors.BLACK_60,
        gap: 1,
        px: 4,
        py: 2,
        borderLeft: `1px solid ${
          isLastClick ? theme.colors.PRIMARY : theme.colors.WHITE_20
        }`,
        borderRight: `1px solid ${
          isLastClick ? theme.colors.PRIMARY : theme.colors.WHITE_20
        }`,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        '&:hover': {
          bgcolor: theme.colors.PRIMARY_20,
        },
        '&:active': {
          bgcolor: theme.colors.PRIMARY_40,
        },
      }}
      to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${techniqueId}`}
      component={Link}
    >
      <HighlightLabel text={id} />
      <Typography variant="bodySmall" sx={{ color: theme.colors.WHITE }}>
        {name}
      </Typography>
    </Stack>
  )
}
