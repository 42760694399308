import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_MALWARE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/malwares/TVLoad/capabilities" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"TVLoad",
  "techniques":[
    {
      "main_value":"T1659",
      "name":"Content Injection",
      "description":"Adversaries may gain access and continuously communicate with victims by injecting malicious content into systems through online network traffic.",
      "last_modified":1539745923,
      "published_at":1539745923,
      "version":"1.1",
      "tactics":[
        {
          "tactic_name":"Initial Access",
          "tactic_serial":"TA0001"
        }
      ],
      "sub_techniques":[]
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Capabilities'

export const APIDocumentVersionTwoMalwareCapabilities = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_MALWARE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_MALWARE_TITLE} />
        <H2Title title={TITLE} />
        <Description>Lists all associated mitre techniques.</Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/malwares/:name/capabilities" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>Malware name</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            techniques
            <UnorderedList>
              <ListItem>
                Array of MITRE techniques, each object contains following keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>description: string</ListItem>
                  <ListItem>last_modified: unix timestamp</ListItem>
                  <ListItem>published_at: unix timestamp</ListItem>
                  <ListItem>version: string</ListItem>
                  <ListItem>sub_techniques: array of MITRE techniques</ListItem>
                  <ListItem>
                    parent: parent object (while parent not existed this key
                    could be missing)
                    <UnorderedList>
                      <ListItem>name: string</ListItem>
                      <ListItem>serial: string</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    tactics: array of object with following keys:
                    <UnorderedList>
                      <ListItem>tactic_name: string</ListItem>
                      <ListItem>tactic_serial: string</ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
