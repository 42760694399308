import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps,
  Stack,
  SvgIcon,
  useTheme,
} from '@mui/material'
import { ReactComponent as ExpandMoreIcon } from 'assets/basicIcons/angleDown.svg'
import { ReactComponent as CheckIcon } from 'assets/basicIcons/check.svg'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { MenuItem } from 'components/Menu/Menu'

type TSelectorSizes = 'small' | 'medium' | 'large'

export interface ISelectProps extends Omit<SelectProps, 'size'> {
  size?: TSelectorSizes
  width?: string | number
  helperText?: string
}

const useSelectorStylesBySize = (size: TSelectorSizes) => {
  const theme = useTheme()
  const stylesMap = {
    large: {
      selectorHeight: '2rem',
      fontStyles: theme.typography.body,
      iconStyles: {
        width: '1rem',
        height: '1rem',
        top: '0.5rem',
      },
    },
    medium: {
      selectorHeight: '1.5rem',
      fontStyles: theme.typography.bodySmall,
      iconStyles: {
        width: '1rem',
        height: '1rem',
        top: '0.25rem',
      },
    },
    small: {
      selectorHeight: '1rem',
      fontStyles: theme.typography.assistiveText,
      iconStyles: {
        width: '0.5rem',
        height: '0.5rem',
        top: '0.25rem',
        right: '0.25rem',
      },
    },
  }

  return stylesMap[size]
}

export const Select = ({
  sx = [],
  size = 'medium',
  width = '100%',
  helperText,
  label,
  ...props
}: ISelectProps) => {
  const theme = useTheme()
  const { selectorHeight, fontStyles, iconStyles } =
    useSelectorStylesBySize(size)
  const normalColor = theme.colors.WHITE_60
  const hoverColor = theme.colors.WHITE
  const activeColor = theme.colors.PRIMARY_60
  const focusColor = theme.colors.TEXT_LINK_NORMAL

  const labelColor = () => {
    if (props.disabled) {
      return theme.colors.DISABLE
    }
    if (props.error) {
      return theme.colors.ERROR
    }
    return normalColor
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <FormControl
        sx={{
          '&:active': {
            '.label': {
              color: props.disabled ? 'none' : activeColor,
            },
          },
          '&:hover': {
            '.label': {
              color: props.disabled ? 'none' : hoverColor,
            },
          },
        }}
      >
        {label && (
          <InputLabel
            className="label"
            shrink
            sx={{
              color: labelColor,
              top: 4,
              '&.Mui-focused': {
                color: focusColor,
              },
              '&.Mui-disabled': {
                color: theme.colors.DISABLE,
              },
              ...theme.typography.assistiveText,
            }}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          sx={[
            {
              width,
              height: selectorHeight,
              borderRadius: 0.5,
              'legend': theme.typography.assistiveText,
              '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                pl: 4,
                pr: 6,
                py: 0,
                color: normalColor,
                ...fontStyles,
                lineHeight: '1.375rem',
                '&:hover': {
                  color: hoverColor,
                },
                '&.Mui-disabled': {
                  WebkitTextFillColor: theme.colors.DISABLE,
                },
              },
              '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: hoverColor,
                },
                '& .MuiSelect-icon': {
                  path: { fill: hoverColor },
                },
              },
              '&:active': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: activeColor,
                  },
                  '& .MuiSelect-icon': {
                    path: { fill: activeColor },
                  },
                },
              },
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: focusColor,
                  borderWidth: '0.0625rem',
                },
                '& .MuiSelect-icon': {
                  path: { fill: focusColor },
                },
              },
              '&.Mui-disabled': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.colors.DISABLE,
                },
                '& .MuiSelect-icon': {
                  path: { fill: theme.colors.DISABLE },
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: normalColor,
              },
              '& .MuiSelect-icon': {
                path: { fill: props.error ? theme.colors.ERROR : normalColor },
                ...iconStyles,
              },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                bgcolor: theme.colors.BLACK_85,
                borderRadius: 0.5,
                border: `1px solid ${theme.colors.WHITE_20}`,
                maxHeight: '15rem',
                overflow: 'auto',
                '& .MuiList-root': {
                  py: 0,
                },
              },
            },
          }}
          notched={!!label}
          label={label}
          {...props}
        />
      </FormControl>
      {props.error && (
        <FormHelperText
          sx={{
            mt: 0.5,
            mx: 4,
            color: theme.colors.ERROR,
            ...theme.typography.assistiveText,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  )
}

export const SelectItem = ({
  children,
  selected,
  sx = [],
  ...otherProps
}: MenuItemProps) => {
  const theme = useTheme()
  return (
    <MenuItem size="medium" sx={sx} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ width: '1.5rem', height: '1.5rem' }}>
          {selected ? (
            <SvgIcon
              sx={{
                width: '1.5rem',
                height: '1.5rem',
                path: {
                  fill: theme.colors.PRIMARY,
                },
              }}
              component={CheckIcon}
              inheritViewBox
            />
          ) : (
            ''
          )}
        </Box>
        {children}
      </Box>
    </MenuItem>
  )
}

interface ISelectCheckboxItemProps extends MenuItemProps {
  indeterminate?: boolean
  checked: boolean
}

export const SelectCheckboxItem = ({
  children,
  checked,
  indeterminate,
  ...otherProps
}: ISelectCheckboxItemProps) => (
  <MenuItem size="medium" {...otherProps}>
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Checkbox checked={checked} indeterminate={indeterminate} />
      {children}
    </Box>
  </MenuItem>
)
