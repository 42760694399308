import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const TechniqueLoading = () => (
  <Stack>
    <Stack sx={{ height: '15rem', p: 4, gap: 4 }}>
      <Box sx={{ height: '2.6875rem', display: 'flex', alignItems: 'center' }}>
        <Skeleton type="title" width="26.5rem" />
      </Box>
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="body" width="13.5rem" />
        <Skeleton type="body" width="20rem" />
        <Skeleton type="body" width="10.25rem" />
      </Stack>
    </Stack>
  </Stack>
)
