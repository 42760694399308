import VIRRBackgroundImage from 'assets/report/VIRR-background.jpg'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TVulnerabilityReportType } from 'store/types/entityTypes/report'

export interface IVulnerabilityContent {
  name: string
  description: NonNullable<ReactNode>
  buttonText: string
  backgroundImageSrc: string
  type: TVulnerabilityReportType
}

export const useVulnerabilityContent = () => {
  const { t } = useTranslation(['report'])

  const vulnerabilityContent: IVulnerabilityContent[] = [
    {
      name: t('viReports.title', { ns: 'report' }),
      description: (
        <Trans t={t} i18nKey="viReports.description">
          Vulnerability Insights Report (aka VIR) provides technical detail
          about critical and highly exploitable vulnerabilities. Users can use
          VIR to mitigate the threat posed by these critical and highly
          exploitable vulnerabilities. (Vulnerability Insights Report (VIR) is
          the new name of the old series, Vulnerability Mitigation and Response
          Report (aka VMRR).)
          <br />
          VIR is published every two weeks. Users will have 24 VIR a year. Each
          VIR focuses on one critical and highly exploitable vulnerability.
          Moreover, VIR depicts the possible attacking scenario and provides the
          detection tool.
        </Trans>
      ),
      buttonText: t('viReports.moreCta', { ns: 'report' }),
      backgroundImageSrc: VIRRBackgroundImage,
      type: 'VIRReport',
    },
    {
      name: t('pmReports.title', { ns: 'report' }),
      description: (
        <Trans t={t} i18nKey="pmReports.description">
          Patch Management Report (aka PMR) provides all the relevant
          information on all the critical and highly exploitable
          vulnerabilities. Users can use PMR to prioritize their patch
          management.
          <br />
          PMR will be updated every two weeks. Users will have 24 PMR a year.
          Each PMR summarizes around 100 most critical vulnerabilities during
          the period in a table and lists all the essential information about
          these vulnerabilities, such as the affected products, patching
          details, and even publicly available PoC codes.
        </Trans>
      ),
      buttonText: t('pmReports.moreCta', { ns: 'report' }),
      backgroundImageSrc: VIRRBackgroundImage,
      type: 'PMReport',
    },
  ]

  return vulnerabilityContent
}
