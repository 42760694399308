import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { LabelListBreakLine } from 'components/LabelList/LabelListBreakLine'
import { TextLink } from 'components/Link/Link'
import {
  CAPABILITY_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchReportLoading, selectReport } from 'store/slices/report'

import { ReportDetailLabelsLoading } from './ReportDetailLabelsLoading'

export const ReportDetailLabels = () => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  const isFetchReportLoading = useAppSelector(selectIsFetchReportLoading)
  const report = useAppSelector(selectReport)

  if (isFetchReportLoading) {
    return <ReportDetailLabelsLoading />
  }

  return (
    <Stack sx={{ height: '100%' }}>
      {report && (
        <Stack
          sx={{
            px: 2,
            py: 4,
            gap: 4,
            flexBasis: 0,
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box>
            <Box sx={{ pb: 4 }}>
              <LabelListBreakLine
                keyText={t('reportPreview.labels.targetCountry', {
                  ns: 'component',
                })}
                labels={report.targetedCountries}
                getLabelLink={(target: string) =>
                  `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
                }
              />
            </Box>
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
          </Box>
          <Box>
            <Box sx={{ pb: 4 }}>
              <LabelListBreakLine
                keyText={t('reportPreview.labels.targetIndustry', {
                  ns: 'component',
                })}
                labels={report.targetedIndustries}
                getLabelLink={(target: string) =>
                  `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
                }
              />
            </Box>
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
          </Box>
          <Box>
            <Box sx={{ pb: 4 }}>
              <LabelListBreakLine
                keyText={t('reportPreview.labels.adversary', {
                  ns: 'component',
                })}
                labels={report.adversaries}
                getLabelLink={(target: string) =>
                  `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
                }
              />
            </Box>
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
          </Box>
          <Box>
            <Box sx={{ pb: 4 }}>
              <LabelListBreakLine
                keyText={t('reportPreview.labels.malware', {
                  ns: 'component',
                })}
                labels={report.malwares}
                getLabelLink={(target: string) =>
                  `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`
                }
              />
            </Box>
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
          </Box>
          <Box>
            <Stack sx={{ gap: 1, pb: 4 }}>
              <HighlightLabel
                text={t('reportPreview.labels.capability', {
                  ns: 'component',
                })}
              />
              {report.capability.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    flexWrap: 'wrap',
                    pl: 2,
                  }}
                >
                  {report.capability.map((techniqueId, index) => (
                    <Box
                      key={techniqueId}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <TextLink
                        to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${techniqueId}`}
                      >
                        {techniqueId}
                      </TextLink>
                      {index < report.capability.length - 1 && (
                        <Typography
                          variant="textLink"
                          sx={{
                            color: theme.colors.WHITE,
                            mr: 1,
                            textDecoration: 'none',
                          }}
                        >
                          ,
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography
                  variant="textSmallImportant"
                  sx={{ color: theme.colors.WHITE, pl: 2 }}
                >
                  --
                </Typography>
              )}
            </Stack>
            <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
