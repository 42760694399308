import {
  Box,
  CircularProgress,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'

export interface IOutlineTabWithCountProps {
  name: string
  count?: number
  handleSelect: () => void
  selected: boolean
  showCount?: boolean
  isLoading?: boolean
  sx?: SxProps
}

export const OutlineTabWithCount = ({
  name,
  count,
  handleSelect,
  selected,
  showCount,
  isLoading,
  sx = [],
}: IOutlineTabWithCountProps) => {
  const theme = useTheme()
  return (
    <Box
      onClick={handleSelect}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            bgcolor: theme.colors.PRIMARY_40,
          },
          borderRadius: 0.5,
          cursor: 'pointer',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '1.25rem',
          pl: 2,
          pr: showCount ? 1 : 2,
          border: `1px solid ${
            selected ? theme.colors.PRIMARY : theme.colors.WHITE_20
          }`,
          borderRadius: '2px 0 0 2px',
        }}
      >
        <Typography
          variant="textSmallImportant"
          sx={{ color: selected ? theme.colors.PRIMARY : theme.colors.WHITE }}
        >
          {name}
        </Typography>
      </Box>
      {showCount && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: '0.375rem',
            height: '1.25rem',
            bgcolor: selected ? theme.colors.PRIMARY : theme.colors.WHITE_20,
            borderRadius: '0 2px 2px 0',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size="0.5rem"
              sx={{
                color: selected ? theme.colors.BLACK_90 : theme.colors.WHITE,
              }}
            />
          ) : (
            <Typography
              variant="textSmallImportant"
              sx={{
                color: selected ? theme.colors.BLACK_90 : theme.colors.WHITE,
              }}
            >
              {count}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
