import {
  Box,
  SvgIcon,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as SearchIcon } from 'assets/basicIcons/search.svg'
import { ReactComponent as AscSortedIcon } from 'assets/tableIcons/asc-sort.svg'
import { ReactComponent as DescSortedIcon } from 'assets/tableIcons/desc-sort.svg'
import { ReactComponent as UnSortedIcon } from 'assets/tableIcons/unsort.svg'
import { TextField } from 'components/TextField/TextField'
import { usePMReportDetailTableHeadCells } from 'hooks/usePMReportDetailTableHeadCells'
import { FC, SVGProps,useState } from 'react'

import type {
  TFilterTextMap,
  TOrder,
  TPMReportDetailFilterKeys,
  TPMReportDetailKeys,
  TPMReportDetailOrderKeys,
} from './PMReportDetailTable'

type TFilterActiveMap = Record<TPMReportDetailFilterKeys, boolean>

const initialFilterActiveMap: TFilterActiveMap = {
  id: false,
  vendor: false,
  product: false,
}

interface IPMReportDetailTableHeaderProps {
  order: TOrder
  orderBy: TPMReportDetailOrderKeys
  handleOrderChange: (newOrderBy: TPMReportDetailOrderKeys) => void
  filterTextMap: TFilterTextMap
  handleFilterChange: (
    newFilterBy: TPMReportDetailFilterKeys
  ) => (newFilterText: string) => void
}

export const PMReportDetailTableHeader = ({
  order,
  orderBy,
  handleOrderChange,
  filterTextMap,
  handleFilterChange,
}: IPMReportDetailTableHeaderProps) => {
  const theme = useTheme()
  const { headCells, widthRatioSummary } = usePMReportDetailTableHeadCells()

  // 判斷哪些欄位的 filter 有被啟用，決定是否出現 input element
  const [filterActiveMap, setFilterActiveMap] = useState<TFilterActiveMap>(
    initialFilterActiveMap
  )
  const handleFilterActiveChange = ({
    column,
    active,
  }: {
    column: TPMReportDetailKeys
    active: boolean
  }) => {
    const newFilterActiveMap = {
      ...filterActiveMap,
      [column]: active,
    }
    setFilterActiveMap(newFilterActiveMap)
  }

  return (
    <TableHead sx={{ bgcolor: theme.colors.BLACK_90 }}>
      <TableRow>
        {headCells.map((headCell, index) => {
          const cellWidthPercentage = headCell.widthRatio / widthRatioSummary

          const isSorting = orderBy === headCell.id

          let SortIcon: FC<SVGProps<SVGSVGElement>> = UnSortedIcon
          if (isSorting) {
            SortIcon = order === 'asc' ? AscSortedIcon : DescSortedIcon
          }

          const isFilterActive = Boolean(
            (headCell.id &&
              filterTextMap[headCell.id as TPMReportDetailFilterKeys]) ||
              filterActiveMap[headCell.id as TPMReportDetailFilterKeys]
          )

          const handleFilterInputChange = handleFilterChange(
            headCell.id as TPMReportDetailFilterKeys
          )
          const handleFilterInputOnBlur = () => {
            if (
              filterTextMap[headCell.id as TPMReportDetailFilterKeys] === ''
            ) {
              handleFilterActiveChange({ column: headCell.id, active: false })
            }
          }

          return (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={isSorting ? order : false}
              sx={{
                pl: 4,
                pr: 2,
                py: 2,
                // for first column head cell sticky
                left: index === 0 ? 0 : 'none',
                // TableCell default z-index is 2, first column head cell need on top
                zIndex: headCell.id === 'id' ? 3 : 2,
                bgcolor: theme.colors.BLACK_90,
                width: `${cellWidthPercentage * 100}%`,
                minWidth: headCell.minWidth,
                // last column no border-right style
                borderRight:
                  index + 1 === headCells.length
                    ? 'none'
                    : `1px solid ${theme.colors.WHITE_20}`,
                borderBottom: `2px solid ${theme.colors.WHITE}`,
                fontFamily: 'Noto Sans',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: theme.colors.WHITE,
                  width: '100%',
                }}
              >
                {isFilterActive ? (
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      label={headCell.label}
                      autoFocus
                      size="medium"
                      value={
                        filterTextMap[headCell.id as TPMReportDetailFilterKeys]
                      }
                      onChange={(event) =>
                        handleFilterInputChange(event.target.value)
                      }
                      onBlur={handleFilterInputOnBlur}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Typography variant="title">{headCell.label}</Typography>
                    {headCell.enableFilter && (
                      <SvgIcon
                        sx={{
                          width: '1.5rem',
                          height: '1.5rem',
                          cursor: 'pointer',
                        }}
                        component={SearchIcon}
                        inheritViewBox
                        onClick={() =>
                          handleFilterActiveChange({
                            column: headCell.id,
                            active: true,
                          })
                        }
                      />
                    )}
                  </Box>
                )}
                {headCell.enableOrder && (
                  <SvgIcon
                    sx={{ cursor: 'pointer' }}
                    component={SortIcon}
                    onClick={() =>
                      handleOrderChange(headCell.id as TPMReportDetailOrderKeys)
                    }
                  />
                )}
              </Box>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
