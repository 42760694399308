import { Box, Stack, Typography, useTheme } from '@mui/material'
import { NoData } from 'components/NoData/NoData'
import { PageAccordionSection } from 'components/PageSection/PageSection'
import { useAppSelector } from 'hooks/useReduxHooks'
import {
  selectSampleDetail,
  selectSampleDetailLoading,
} from 'store/slices/sampleDetail'

import { AccordionLoading } from '../RelationContent/SubContents/AccordionLoading'

export const SampleInfoContent = () => {
  const theme = useTheme()
  const sampleDetailLoading = useAppSelector(selectSampleDetailLoading)
  const sampleDetail = useAppSelector(selectSampleDetail)

  if (sampleDetailLoading) {
    return <AccordionLoading />
  }

  if (!sampleDetail) {
    return <NoData />
  }

  const { sampleInfo } = sampleDetail

  return (
    <Stack sx={{ mt: 2, gap: 1 }}>
      <PageAccordionSection defaultExpanded title="Hash">
        <Box
          sx={{
            px: 4,
            py: 2,
            display: 'flex',
            gap: 4,
            color: theme.colors.WHITE,
          }}
        >
          <Stack sx={{ gap: 2 }}>
            <Typography variant="bodySmall">MD5</Typography>
            <Typography variant="bodySmall">SHA-1</Typography>
            <Typography variant="bodySmall">SHA-256</Typography>
          </Stack>
          <Stack sx={{ gap: 2 }}>
            <Typography variant="bodySmall">
              {sampleInfo.md5 || '--'}
            </Typography>
            <Typography variant="bodySmall">
              {sampleInfo.sha1 || '--'}
            </Typography>
            <Typography variant="bodySmall">
              {sampleInfo.sha256 || '--'}
            </Typography>
          </Stack>
        </Box>
      </PageAccordionSection>
      <PageAccordionSection defaultExpanded title="Filename">
        <Box
          sx={{
            px: 4,
            py: 2,
            display: 'flex',
            gap: 4,
            color: theme.colors.WHITE,
          }}
        >
          <Stack sx={{ gap: 2 }}>
            <Typography variant="bodySmall">ORIGINAL_FILE_NAME</Typography>
            <Typography variant="bodySmall">PDB</Typography>
          </Stack>
          <Stack sx={{ gap: 2 }}>
            <Typography variant="bodySmall">
              {sampleInfo.filename.originalFilename || '--'}
            </Typography>
            <Typography variant="bodySmall">
              {sampleInfo.filename.pdbStrings.length > 0
                ? sampleInfo.filename.pdbStrings.join(', ')
                : '--'}
            </Typography>
          </Stack>
        </Box>
      </PageAccordionSection>
      {sampleInfo.peFile && (
        <PageAccordionSection defaultExpanded title="PE_FILE">
          <Box
            sx={{
              px: 4,
              py: 2,
              display: 'flex',
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            <Stack sx={{ gap: 2 }}>
              {Object.keys(sampleInfo.peFile).map((peKey) => (
                <Typography key={peKey} variant="bodySmall">
                  {peKey ? peKey.toUpperCase() : '--'}
                </Typography>
              ))}
            </Stack>
            <Stack sx={{ gap: 2 }}>
              {Object.values(sampleInfo.peFile).map((peValue) => (
                <Typography key={Math.random()} variant="bodySmall">
                  {peValue || '--'}
                </Typography>
              ))}
            </Stack>
          </Box>
        </PageAccordionSection>
      )}
      {/* {sampleInfo.document && (
        <PageAccordionSection defaultExpanded title="DOCUMENT">
          <Box
            sx={{
              px: 4,
              py: 2,
              display: 'flex',
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            <Stack sx={{ gap: 2 }}>
              {Object.keys(sampleInfo.document).map((documentKey) => (
                <Typography key={documentKey} variant="bodySmall">
                  {documentKey ? documentKey.toUpperCase() : '--'}
                </Typography>
              ))}
            </Stack>
            <Stack sx={{ gap: 2 }}>
              {Object.values(sampleInfo.document).map((documentValue) => (
                <Typography key={Math.random()} variant="bodySmall">
                  {JSON.stringify(documentValue) || '--'}
                </Typography>
              ))}
            </Stack>
          </Box>
        </PageAccordionSection>
      )} */}
      {sampleInfo.exiftool && (
        <PageAccordionSection defaultExpanded title="EXIFTOOL">
          <>
            <Stack sx={{ color: theme.colors.WHITE }}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                  pl: 1,
                  pb: 1,
                }}
              >
                <Typography variant="titleSmall">OUTPUT</Typography>
              </Box>
              <Box
                sx={{
                  px: 4,
                  py: 2,
                  display: 'flex',
                  gap: 4,
                }}
              >
                <Stack sx={{ gap: 2 }}>
                  {Object.keys(sampleInfo.exiftool.output).map(
                    (exiftoolOutputKey) => (
                      <Typography key={exiftoolOutputKey} variant="bodySmall">
                        {exiftoolOutputKey
                          ? exiftoolOutputKey.toUpperCase()
                          : '--'}
                      </Typography>
                    )
                  )}
                </Stack>
                <Stack sx={{ gap: 2 }}>
                  {Object.values(sampleInfo.exiftool.output).map(
                    (exiftoolOutputValue) => (
                      <Typography key={Math.random()} variant="bodySmall">
                        {exiftoolOutputValue || '--'}
                      </Typography>
                    )
                  )}
                </Stack>
              </Box>
            </Stack>
            {sampleInfo.exiftool.fileTimestamp && (
              <Stack sx={{ color: theme.colors.WHITE }}>
                <Box
                  sx={{
                    borderTop: `1px solid ${theme.colors.WHITE_20}`,
                    borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                    pl: 1,
                    py: 1,
                  }}
                >
                  <Typography variant="titleSmall">FILE_TIMESTAMP</Typography>
                </Box>
                <Box
                  sx={{
                    px: 4,
                    py: 2,
                    display: 'flex',
                    gap: 4,
                  }}
                >
                  <Typography variant="bodySmall">SECONDS</Typography>
                  <Typography variant="bodySmall">
                    {sampleInfo.exiftool.fileTimestamp.seconds || '--'}
                  </Typography>
                </Box>
              </Stack>
            )}
          </>
        </PageAccordionSection>
      )}
      {sampleInfo.fileHash && (
        <PageAccordionSection defaultExpanded title="FILE_HASH">
          <Box
            sx={{
              px: 4,
              py: 2,
              display: 'flex',
              gap: 4,
              color: theme.colors.WHITE,
            }}
          >
            <Stack sx={{ gap: 2 }}>
              {Object.keys(sampleInfo.fileHash).map((fileHashKey) => (
                <Typography key={fileHashKey} variant="bodySmall">
                  {fileHashKey ? fileHashKey.toUpperCase() : '--'}
                </Typography>
              ))}
            </Stack>
            <Stack sx={{ gap: 2 }}>
              {Object.values(sampleInfo.fileHash).map((fileHashValue) => (
                <Typography key={Math.random()} variant="bodySmall">
                  {fileHashValue || '--'}
                </Typography>
              ))}
            </Stack>
          </Box>
        </PageAccordionSection>
      )}
    </Stack>
  )
}
