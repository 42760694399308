import { Box, debounce, Typography, useTheme } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { TTargetType } from 'pages/ThreatLandscapePage/TargetBlock'
import { useEffect,useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchCountryRelatedAdversaries,
  fetchIndustryRelatedAdversaries,
  fetchRegionRelatedAdversaries,
  fetchRelatedAdversariesCancelled,
  resetRelatedAdversaries,
  selectRelatedAdversaries,
  selectRelatedAdversariesLoading,
} from 'store/slices/threatLandscape'
import { hideScrollBarStyles } from 'theme/scrollbar'

import { AdversaryBlock } from './AdversaryComponents/AdversaryBlock'
import { Loading } from './AdversaryComponents/Loading'
import { ScrollSwitcher } from './AdversaryComponents/ScrollSwitcher'
import {
  ADVERSARY_ITEMS_GAP_WIDTH,
  SCROLL_SWITCHER_DEBOUNCE_TIME,
} from './config'

interface IRelatedAdversariesProps {
  targetType: TTargetType
  name: string
}

const RELATED_ADVERSARIES_CONTAINER_ID = 'related-adversaries'
export const RELATED_ADVERSARIES_CONTAINER_TEST_ID =
  'related-adversaries-container'

export const RelatedAdversaries = ({
  targetType,
  name,
}: IRelatedAdversariesProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation(['threatLandscapes', 'component'])
  const relatedAdversariesLoading = useAppSelector(
    selectRelatedAdversariesLoading
  )
  const relatedAdversaries = useAppSelector(selectRelatedAdversaries)
  const scrollContainerRef = useRef()

  useEffect(() => {
    if (targetType === 'country') {
      dispatch(fetchCountryRelatedAdversaries(name))
    } else if (targetType === 'industry') {
      dispatch(fetchIndustryRelatedAdversaries(name))
    } else if (targetType === 'region') {
      dispatch(fetchRegionRelatedAdversaries(name))
    }
    return () => {
      dispatch(fetchRelatedAdversariesCancelled())
      dispatch(resetRelatedAdversaries())
    }
  }, [targetType, name])

  const [reachedLeftEdge, setReachedLeftEdge] = useState<boolean>(true)
  const [reachedRightEdge, setReachedRightEdge] = useState<boolean>(false)

  const handleEdgeDetection = debounce(() => {
    if (scrollContainerRef.current) {
      const { clientWidth, scrollLeft, scrollWidth } =
        scrollContainerRef.current
      setReachedLeftEdge(scrollLeft === 0)
      setReachedRightEdge(clientWidth + scrollLeft === scrollWidth)
    }
  }, SCROLL_SWITCHER_DEBOUNCE_TIME)

  return (
    <PageSection
      title={t('relatedAdversaries', { ns: 'threatLandscapes' })}
      titleAction={
        <ScrollSwitcher
          reachedLeftEdge={reachedLeftEdge}
          reachedRightEdge={reachedRightEdge}
          handleEdgeDetection={handleEdgeDetection}
          scrollContainerId={RELATED_ADVERSARIES_CONTAINER_ID}
        />
      }
    >
      <Box data-testid={RELATED_ADVERSARIES_CONTAINER_TEST_ID} />
      {relatedAdversariesLoading ? (
        <Loading />
      ) : relatedAdversaries.length > 0 ? (
        <Box sx={{ p: 2, height: '100%' }}>
          <Box
            id={RELATED_ADVERSARIES_CONTAINER_ID}
            ref={scrollContainerRef}
            sx={{
              gap: `${ADVERSARY_ITEMS_GAP_WIDTH}px`,
              display: 'flex',
              alignItems: 'center',
              overflowX: 'auto',
              overflowY: 'hidden',
              ...hideScrollBarStyles,
            }}
            onScroll={handleEdgeDetection}
          >
            {relatedAdversaries.map((adversary) => (
              <Box key={adversary.name} sx={{ flexShrink: 0 }}>
                <AdversaryBlock {...adversary} />
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Typography
          variant="titleSmall"
          sx={{ color: theme.colors.WHITE_60, p: 2 }}
        >
          {t('emptyState.noRelatedResult', { ns: 'component' })}
        </Typography>
      )}
    </PageSection>
  )
}
