import { Box, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { ReportPreview } from 'components/Report/ReportPreview'
import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react'
import { IReport } from 'store/types/entityTypes/report'

interface IReportBlockProps {
  report: IReport
  selectedReports: IReport[]
  setSelectedReports: Dispatch<SetStateAction<IReport[]>>
}

export const ReportBlock = ({
  report,
  selectedReports,
  setSelectedReports,
}: IReportBlockProps) => {
  const theme = useTheme()
  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedReports([report, ...selectedReports])
    } else {
      setSelectedReports(
        selectedReports.filter(
          (selectedReport) => selectedReport.title !== report.title
        )
      )
    }
  }

  const checked = useMemo(
    () =>
      selectedReports.some((selectedReport) => selectedReport.id === report.id),
    [selectedReports]
  )

  const noPermission = report.permission === false

  return (
    <Box
      sx={{
        display: 'flex',
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
      }}
    >
      <Box sx={{ pt: 2, px: 2 }}>
        <Checkbox
          disabled={noPermission}
          checked={checked}
          onChange={handleCheck}
        />
      </Box>
      <Box
        sx={{ flexGrow: 1, borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
      >
        <ReportPreview {...report} />
      </Box>
    </Box>
  )
}
