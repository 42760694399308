import { Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const WhoisLoading = () => (
  <Stack sx={{ gap: 2 }}>
    <Skeleton type="body" width="52.5rem" />
    <Skeleton type="body" width="29.1875rem" />
    <Skeleton type="body" width="40.6875rem" />
  </Stack>
)
