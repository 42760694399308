import { Box, SvgIcon, SxProps, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { HighlightLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import { TextLink } from 'components/Link/Link'
import {
  MALWARE_CONTENT_ROUTE,
  MALWARES_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'

const labelStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
}

export const MalwareAliasList = ({ aliases }: { aliases: string[] }) => {
  const { t } = useTranslation(['component'])

  return (
    <LabelList
      keyText={t('malwarePreview.labels.alias', { ns: 'component' })}
      labels={aliases}
      maxCount={5}
    />
  )
}

export const MalwareAttribution = ({
  attribution,
}: {
  attribution: string
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['search', 'component'])

  return (
    <Box sx={labelStyles}>
      <HighlightLabel
        text={t('malwarePreview.labels.attribution', { ns: 'component' })}
      />
      {attribution === 'shared' || attribution === 'unknown' ? (
        <Typography
          variant="textSmallImportant"
          sx={{ color: theme.colors.WHITE }}
        >
          {attribution}
        </Typography>
      ) : (
        <TextLink
          to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${attribution}`}
        >
          {attribution}
        </TextLink>
      )}
    </Box>
  )
}

export const MalwareDescription = ({ overview }: { overview: string }) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Box sx={labelStyles}>
      <Box sx={{ alignSelf: 'baseline', display: 'flex', flexShrink: 0 }}>
        <HighlightLabel
          text={t('malwarePreview.labels.description', { ns: 'component' })}
        />
      </Box>
      <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
        {overview}
      </Typography>
    </Box>
  )
}

export const MalwareThreatHuntingTools = ({
  id,
  hasTools,
}: {
  id: string
  hasTools: boolean
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Box sx={labelStyles}>
      <HighlightLabel
        text={t('malwarePreview.labels.threatHuntingTools', {
          ns: 'component',
        })}
      />
      {hasTools ? (
        <LinkButton
          to={`/${MALWARES_ROUTE}/${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_ID}=${id}&${SearchParamKey.MALWARE_TAB}=threatHuntingTools`}
          size="small"
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
        >
          {t('malwarePreview.checkForDownloadCta', {
            ns: 'component',
          })}
        </LinkButton>
      ) : (
        <Typography color={theme.colors.WHITE} variant="textSmallImportant">
          --
        </Typography>
      )}
    </Box>
  )
}
