import {
  Box,
  CircularProgress,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as AngleLeftIcon } from 'assets/basicIcons/angleLeft.svg'
import { ReactComponent as AngleRightIcon } from 'assets/basicIcons/angleRight.svg'
import { SearchParamKey } from 'constants/searchParamKeys'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TSearchResultKey } from 'store/types/slicesTypes/search'

import { ISearchResultCategoryTab } from '../config'

interface ICategoryTabsProps {
  categoryTab: ISearchResultCategoryTab
  searchResultCategoryTabs: ISearchResultCategoryTab[]
  defaultCategoryTab: ISearchResultCategoryTab
}

export const CategoryTabs = ({
  categoryTab,
  searchResultCategoryTabs,
  defaultCategoryTab,
}: ICategoryTabsProps) => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTab, setSelectedTab] = useState(defaultCategoryTab.key)

  const handleChange = (_event: SyntheticEvent, tabKey: TSearchResultKey) => {
    searchParams.set(SearchParamKey.SEARCH_RESULT_TAB, tabKey)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setSelectedTab(categoryTab.key)
  }, [categoryTab.key])

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      variant="scrollable"
      ScrollButtonComponent={(buttonProps) => {
        if (buttonProps.disabled) {
          return (
            <Box
              sx={{
                width: '1rem',
                height: '1rem',
                mr: 1,
              }}
            />
          )
        }
        if (buttonProps.direction === 'left') {
          return (
            <Box
              {...buttonProps}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '2px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: theme.colors.WHITE_20,
                },
                '&:active': {
                  bgcolor: theme.colors.WHITE_40,
                },
                mr: 1,
              }}
            >
              <SvgIcon
                sx={{
                  width: '1rem',
                  height: '1rem',
                }}
                component={AngleLeftIcon}
                inheritViewBox
              />
            </Box>
          )
        }
        if (buttonProps.direction === 'right') {
          return (
            <Box
              {...buttonProps}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '2px',
                '&:hover': {
                  bgcolor: theme.colors.WHITE_20,
                },
                '&:active': {
                  bgcolor: theme.colors.WHITE_40,
                },
                ml: 1,
              }}
            >
              <SvgIcon
                sx={{
                  width: '1rem',
                  height: '1rem',
                }}
                component={AngleRightIcon}
                inheritViewBox
              />
            </Box>
          )
        }
        return null
      }}
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      sx={{
        px: 1,
        maxHeight: '2rem',
        alignItems: 'center',
        '&.MuiTabs-root': {
          minHeight: '2rem',
        },
        '.MuiTabs-flexContainer': {
          gap: 2,
        },
        '.MuiTabs-indicator': {
          height: 0,
        },
      }}
    >
      {searchResultCategoryTabs.map((tab) => (
        <Tab
          key={tab.key}
          value={tab.key}
          label={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant="titleSmall">{tab.name}</Typography>
              <Box
                sx={{
                  px: '0.375rem',
                  height: '1.25rem',
                  bgcolor:
                    categoryTab.key === tab.key
                      ? theme.colors.PRIMARY
                      : theme.colors.WHITE_20,
                  borderRadius: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {tab.isLoading ? (
                  <CircularProgress
                    size="0.5rem"
                    sx={{
                      color:
                        categoryTab.key === tab.key
                          ? theme.colors.BLACK_90
                          : theme.colors.WHITE,
                    }}
                  />
                ) : (
                  <Typography
                    variant="textSmallImportant"
                    sx={{
                      color:
                        categoryTab.key === tab.key
                          ? theme.colors.BLACK_90
                          : theme.colors.WHITE,
                      lineHeight: '0.75rem',
                    }}
                  >
                    {tab.count}
                  </Typography>
                )}
              </Box>
            </Box>
          }
          disableRipple
          sx={{
            color: theme.colors.WHITE,
            px: '0.375rem',
            textTransform: 'none',
            borderRadius: 0.5,
            '.Mui-selected': {
              bgcolor: theme.colors.PRIMARY,
            },
            '&.MuiButtonBase-root.MuiTab-root': {
              p: 0,
              pl: '0.375rem',
              minHeight: '1.25rem',
              maxHeight: '1.25rem',
              minWidth: 0,
              fontFamily: 'Noto Sans',
              ...theme.typography.titleSmall,
              '&:hover': {
                bgcolor: theme.colors.PRIMARY_40,
              },
            },
          }}
          component={Box}
        />
      ))}
    </Tabs>
  )
}
