import { Stack } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion/Accordion'
import { Skeleton } from 'components/Skeleton/Skeleton'

const LoadingBlock = () => (
  <Accordion defaultExpanded>
    <AccordionSummary>
      <Skeleton type="title" width="7.5rem" />
    </AccordionSummary>
    <AccordionDetails>
      <Stack sx={{ gap: 2, px: 2, pt: 1 }}>
        <Skeleton type="body" width="13.5rem" />
        <Skeleton type="body" width="20rem" />
        <Skeleton type="body" width="16.5rem" />
      </Stack>
    </AccordionDetails>
  </Accordion>
)

export const AccordionLoading = () => (
  <Stack sx={{ pt: 2, gap: 1 }}>
    <LoadingBlock />
    <LoadingBlock />
  </Stack>
)
