import { Box, Typography, useTheme } from '@mui/material'

export const CodeBlock = ({ code }: { code: string }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        bgcolor: theme.colors.BLACK_85,
      }}
    >
      <Box sx={{ overflowX: 'auto', p: 4 }}>
        <Typography
          variant="reportCodeBlock"
          sx={{ color: theme.colors.WHITE, whiteSpace: 'pre' }}
        >
          {code}
        </Typography>
      </Box>
    </Box>
  )
}
