import { useTheme } from '@mui/material'
import { TToolIncluded } from 'store/types/entityTypes/tool'

export const useToolIncludedColor = (): Record<TToolIncluded, string> => {
  const theme = useTheme()
  return {
    yara: theme.colors.YARA,
    sigma: theme.colors.SIGMA,
    snort: theme.colors.SNORT,
    other: theme.colors.WHITE_40,
  }
}

export const useToolIncludedLabel = (): Record<TToolIncluded, string> => ({
  yara: 'YARA',
  sigma: 'Sigma',
  snort: 'Snort',
  other: 'Others',
})
