import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const UserCommentsLoading = () => (
  <Stack sx={{ py: 4, gap: 4 }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" type="circular" width="3rem" />
      <Stack sx={{ gap: 2 }}>
        <Skeleton type="body" width="13.5rem" />
        <Skeleton type="body" width="20rem" />
      </Stack>
    </Box>
    <Stack sx={{ gap: 2 }}>
      <Skeleton type="body" width="40.625rem" />
      <Skeleton type="body" width="40.625rem" />
    </Stack>
  </Stack>
)
