import { useTranslation } from 'react-i18next'
import { TAPTReportType } from 'store/types/entityTypes/report'

interface IReportListContent<T> {
  info: string
  cta: string
  type: T
}

export const useAptReportListContent = () => {
  const { t } = useTranslation(['report'])
  const aptReportListContent: IReportListContent<TAPTReportType>[] = [
    {
      info: t('howAbout.flashReports', { ns: 'report' }),
      cta: t('howAbout.flashReportsMoreCta', { ns: 'report' }),
      type: 'flashReport',
    },
    {
      info: t('howAbout.monthlyReports', { ns: 'report' }),
      cta: t('howAbout.monthlyReportsMoreCta', { ns: 'report' }),
      type: 'monthlyReport',
    },
    {
      info: t('howAbout.ctReports', { ns: 'report' }),
      cta: t('howAbout.ctReportsMoreCta', { ns: 'report' }),
      type: 'CTRReport',
    },
  ]
  return aptReportListContent
}
