import { Box, useTheme } from '@mui/material'
import { CenterCircularProgress } from 'components/Loading/CenterCircularProgress'
import { NormalLoading } from 'components/Loading/NormalLoading'
import { MalwarePreview } from 'components/Malware/MalwarePreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchMalwaresData,
  fetchSearchMalwaresDataCancelled,
  resetSearchMalwaresData,
  selectSearchMalwares,
} from 'store/slices/search'

import { NoResultContent } from './NoResultContent'

const SCROLLABLE_CONTAINER_ID = 'malware-scrollable-container'

export const MalwareContent = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const malwares = useAppSelector(selectSearchMalwares)
  const malwareCurrentCount = malwares.data.length
  const isMalwaresLoading = malwares.isDataLoading
  const hasMoreMalwares = malwares.hasMoreData
  const { ref, inView } = useInView()

  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  const fetchMalwares = (refetch?: boolean) => {
    if (searchText) {
      dispatch(
        fetchSearchMalwaresData({
          searchText,
          offset: refetch ? 0 : malwareCurrentCount,
        })
      )
    }
  }

  // fetch initial data
  useEffect(() => {
    fetchMalwares(true)
    return () => {
      dispatch(fetchSearchMalwaresDataCancelled())
      dispatch(resetSearchMalwaresData())
    }
  }, [searchText])

  useEffect(() => {
    if (inView && hasMoreMalwares && malwareCurrentCount > 0) {
      fetchMalwares()
    }
  }, [inView, hasMoreMalwares, malwareCurrentCount])

  if (isMalwaresLoading && malwareCurrentCount === 0) {
    return <NormalLoading />
  }

  if (malwareCurrentCount === 0 && !isMalwaresLoading) {
    return <NoResultContent />
  }

  return (
    <Box
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ overflowY: 'auto', height: '100%' }}
    >
      {malwares.data.map((malware) => (
        <Box
          key={malware.name}
          sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
        >
          <MalwarePreview
            showCheckDetailButton
            showTools
            {...malware}
            showHoverStyle
          />
        </Box>
      ))}
      {hasMoreMalwares && <CenterCircularProgress scrollRef={ref} />}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
