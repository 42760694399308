import * as Sentry from '@sentry/react'
import { downloadToolById } from 'constants/api'
import { StatusCode } from 'constants/statusCode'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { logout } from 'store/slices/auth'
import {
  downloadToolItem,
  downloadToolItemFulfilled,
  downloadToolItemRejected,
  selectToolDownloading,
} from 'store/slices/download'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { ITool } from 'store/types/entityTypes/tool'
import { downloadItem, downloadZipFile } from 'utils/download'

interface IDownloadToolParam extends ITool {
  isSelectedDownload?: boolean
}

export const useDownloadTool = () => {
  const { t } = useTranslation(['snackbar'])
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isDownloading = useAppSelector(selectToolDownloading)
  const handleDownloadTool = async ({
    id,
    name,
    isSelectedDownload,
  }: IDownloadToolParam) => {
    dispatch(downloadToolItem(id))
    try {
      const data = await downloadItem({
        itemUrl: downloadToolById(id),
        format: 'zip',
      })

      Sentry.captureMessage('Download Tool', 'log')

      if (data) {
        downloadZipFile({ value: data, filename: name })
        dispatch(downloadToolItemFulfilled(id))
        if (!isSelectedDownload) {
          dispatch(
            pushSuccessSnackbar({
              text: t('download.downloadSuccess', { ns: 'snackbar' }),
            })
          )
        }
      }
    } catch (error) {
      if ((error as Error).message === StatusCode.UNAUTHORIZED_TOKEN) {
        dispatch(
          logout({
            reason: 'expired',
            pathBeforeLogout: location.pathname + location.search,
          })
        )
      }
      dispatch(downloadToolItemRejected())
      dispatch(
        pushAlertSnackbar({
          text: t('download.downloadFail', { ns: 'snackbar' }),
        })
      )
    }
  }

  return { handleDownloadTool, isDownloading }
}
