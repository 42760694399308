import { Box } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { PageTab } from 'components/Tab/PageTab'
import { REPORTS_ROUTE } from 'constants/routeParams'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  selectIsCheckIsMsspLoading,
  selectIsMsspUser,
} from 'store/slices/account'
import {
  selectIsFetchReportReadableTypesLoading,
  selectReportReadableCategories,
} from 'store/slices/report'
import {
  reportRouteCategoryMap,
  TReportCategoryRoute,
} from 'store/types/entityTypes/report'

interface IReportCategoryTabsProps {
  reportCategoryParam: string
}

export const ReportCategoryTabs = ({
  reportCategoryParam,
}: IReportCategoryTabsProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['report'])
  const isMsspUser = useAppSelector(selectIsMsspUser)
  const reportReadableCategories = useAppSelector(
    selectReportReadableCategories
  )
  const isCheckIsMsspLoading = useAppSelector(selectIsCheckIsMsspLoading)
  const isFetchReportReadableTypesLoading = useAppSelector(
    selectIsFetchReportReadableTypesLoading
  )

  const handleReportCategoryTabClick = (
    categoryRoute: TReportCategoryRoute
  ) => {
    navigate(`/${REPORTS_ROUTE}/${categoryRoute}`)
  }

  type TReportCategoryLabel = {
    categoryRoute: TReportCategoryRoute
    label: string
  }

  const reportCategoryLabel: TReportCategoryLabel[] = [
    { categoryRoute: 'apt', label: t('tabs.apt', { ns: 'report' }) },
    {
      categoryRoute: 'cyber-affairs',
      label: t('tabs.cyberAffairs', { ns: 'report' }),
    },
    {
      categoryRoute: 'vulnerability',
      label: t('tabs.vulnerability', { ns: 'report' }),
    },
  ]

  // MSSP 顯示所有 tab, End-User 顯示有閱讀權限的 Category tab
  const filterReportCategoryLabels = isMsspUser
    ? reportCategoryLabel
    : reportCategoryLabel.filter(
        ({ categoryRoute }) =>
          reportReadableCategories[reportRouteCategoryMap[categoryRoute]]
      )

  if (isCheckIsMsspLoading || isFetchReportReadableTypesLoading) {
    return <Skeleton type="title" width="12.5rem" sx={{ mt: 1, mb: 2 }} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      {filterReportCategoryLabels &&
        filterReportCategoryLabels.map(({ label, categoryRoute }) => (
          <PageTab
            key={label}
            name={label}
            selected={categoryRoute === reportCategoryParam}
            handleTabClick={() => handleReportCategoryTabClick(categoryRoute)}
          />
        ))}
    </Box>
  )
}
