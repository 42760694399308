import { Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const ReportDetailTocLoading = () => (
  <Stack sx={{ px: 2, py: 4, gap: 2 }}>
    <Skeleton type="body" width="7.5rem" />
    <Skeleton type="body" width="12.5rem" />
    <Skeleton type="body" width="9rem" />
  </Stack>
)
