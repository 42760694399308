import { Typography, useTheme } from '@mui/material'

export const H2Title = ({ title }: { title: string }) => {
  const theme = useTheme()

  return (
    <Typography variant="reportH2" sx={{ color: theme.colors.WHITE }}>
      {title}
    </Typography>
  )
}
