import { Box, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

const LoadingBlock = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        height: '2rem',
        px: 1,
        py: 2,
        bgcolor: theme.colors.BLACK_90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Skeleton type="body" width="20rem" />
      <Skeleton type="body" width="5rem" />
    </Box>
  )
}

export const IocDownloadLoading = () => (
  <Stack sx={{ gap: 1, height: '13.75rem', p: 1 }}>
    <LoadingBlock />
    <LoadingBlock />
    <LoadingBlock />
  </Stack>
)
