import { useEffect } from 'react'
import {
  fetchCTRReportsCount,
  fetchCTRReportsCountCancelled,
  fetchCyberAffairsCount,
  fetchCyberAffairsCountCancelled,
  fetchFlashReportsCount,
  fetchFlashReportsCountCancelled,
  fetchMonthlyReportsCount,
  fetchMonthlyReportsCountCancelled,
  fetchPMReportsCount,
  fetchVIRReportsCount,
  fetchVIRReportsCountCancelled,
  resetIsCountReady,
  selectIsCountReady,
} from 'store/slices/report'

import { useAppDispatch, useAppSelector } from './useReduxHooks'

export const useFetchReportsCount = () => {
  const dispatch = useAppDispatch()
  const isCountReady = useAppSelector(selectIsCountReady)

  useEffect(() => {
    if (!Object.values(isCountReady).every((ready) => ready)) {
      dispatch(fetchFlashReportsCount())
      dispatch(fetchMonthlyReportsCount())
      dispatch(fetchCTRReportsCount())
      dispatch(fetchCyberAffairsCount())
      dispatch(fetchVIRReportsCount())
      dispatch(fetchPMReportsCount())
    }

    return () => {
      dispatch(fetchFlashReportsCountCancelled())
      dispatch(fetchMonthlyReportsCountCancelled())
      dispatch(fetchCTRReportsCountCancelled())
      dispatch(fetchCyberAffairsCountCancelled())
      dispatch(fetchVIRReportsCountCancelled())
      dispatch(resetIsCountReady())
    }
  }, [])
}
