import { getPageTitleThreatLandscapeCountryByName } from 'constants/pageTitle'
import { THREAT_LANDSCAPE_ROUTE } from 'constants/routeParams'
import { useTargetedCountries } from 'hooks/useTargetedCountries'
import Image from 'mui-image'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate,useParams } from 'react-router-dom'

import { LandscapePageContainer } from './LandscapePageContainer/LandscapePageContainer'

export const CountrySubPage = () => {
  const navigate = useNavigate()
  const { target } = useParams()
  const targetedCountries = useTargetedCountries()
  const targetCountry = targetedCountries.find(
    (country) => country.targetName === target
  )

  // redirect to landscape index page if country is not exists
  useEffect(() => {
    if (!targetCountry) {
      navigate(`/${THREAT_LANDSCAPE_ROUTE}`)
    }
  }, [targetCountry])

  return targetCountry && targetCountry.graphicImageSrc ? (
    <>
      <Helmet>
        <title>
          {getPageTitleThreatLandscapeCountryByName(targetCountry.displayName)}
        </title>
      </Helmet>
      <LandscapePageContainer
        target={targetCountry}
        targetType="country"
        TargetGraphic={<Image src={targetCountry.graphicImageSrc} />}
      />
    </>
  ) : null
}
