import './reportDetail.scss'

import { Box, Stack, useTheme } from '@mui/material'
import { Dialog } from 'components/Dialog/Dialog'
import { UserComments } from 'components/UserComments/UserComments'
import { getPageTitleReportDetailByNameAndType } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import DOMPurify from 'dompurify'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchReport,
  fetchReportCancelled,
  fetchReportHtmlContent,
  fetchReportHtmlContentCancelled,
  fetchReportTools,
  resetReportTools,
  selectIsFetchReportHtmlContentLoading,
  selectReport,
  selectReportHtmlContent,
} from 'store/slices/report'
import { reportTypePageTitleMap } from 'store/types/entityTypes/report'

import { VIR_REPORT_TYPE } from './constants/reportType'
import { ReportDetailInfo } from './ReportDetailComponents/ReportDetailInfo'
import { ReportDetailLoading } from './ReportDetailLoading'

export interface IH2h3ListItem {
  tagName: string
  text: string
  anchorId: string
}

const BACK_TO_TOP = 'backToTop'
const GO_TO_BOTTOM = 'goToBottom'

export const ReportDetailPage = () => {
  const { hash } = useLocation()
  const dispatch = useAppDispatch()
  const reportHtmlContent = useAppSelector(selectReportHtmlContent)
  const isFetchReportHtmlContentLoading = useAppSelector(
    selectIsFetchReportHtmlContentLoading
  )

  useGoBack()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation(['component', 'snackbar'])
  const theme = useTheme()
  const report = useAppSelector(selectReport)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [tocList, setTocList] = useState<IH2h3ListItem[]>([])

  const alias = searchParams.get(SearchParamKey.REPORT_ALIAS)

  const handleNoAccess = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    navigate('/')
  }

  useEffect(() => {
    if (alias) {
      dispatch(
        fetchReportHtmlContent({ alias, noAccessCallback: handleNoAccess })
      )
      dispatch(fetchReport({ alias, noAccessCallback: handleNoAccess }))
    } else {
      navigate(`/${NOT_FOUND_ROUTE}`)
    }

    return () => {
      dispatch(fetchReportHtmlContentCancelled())
      dispatch(fetchReportCancelled())
    }
  }, [alias])

  useEffect(() => {
    if (report) {
      dispatch(
        setupCommentableIdAndType({
          commentableId: report.id,
          commentableType: report.commentableType,
        })
      )
      dispatch(
        fetchComments({
          commentableId: report.id,
          commentableType: report.commentableType,
        })
      )
    }

    return () => {
      dispatch(resetComments())
    }
  }, [report])

  useEffect(() => {
    if (!isFetchReportHtmlContentLoading && !!reportHtmlContent) {
      setTimeout(() => {
        const h2h3List: IH2h3ListItem[] = []
        const h2h3Tags = document.querySelectorAll<HTMLElement>('h2,h3')
        h2h3Tags.forEach((tag) => {
          // 報告的規則，在 title 的第一個 children <a> tag 為 anchor，第二個 children <a> tag 為 title text
          const tocItem = {
            tagName: tag.tagName,
            text: tag.innerText || '',
            anchorId: tag.firstElementChild?.id || '',
          }
          // 確保有抓到字和 anchor element
          if (tocItem.text && tocItem.anchorId) {
            h2h3List.push(tocItem)
          }
        })

        if (h2h3List.length > 0) {
          setTocList(h2h3List)
        }
      }, 1000)
    }
  }, [isFetchReportHtmlContentLoading, reportHtmlContent])

  useEffect(() => {
    if (report?.type === VIR_REPORT_TYPE) {
      dispatch(fetchReportTools(report.id))
    }

    return () => {
      dispatch(resetReportTools())
    }
  }, [report])

  useEffect(() => {
    if (reportHtmlContent) {
      const scrollToHashTarget = () => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        } else {
          // if html is not finished yet, try again
          setTimeout(() => {
            scrollToHashTarget()
          }, 500)
        }
      }
      scrollToHashTarget()
    }
  }, [reportHtmlContent])

  if (!report) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>
          {getPageTitleReportDetailByNameAndType(
            report.title,
            reportTypePageTitleMap[report.type]
          )}
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '54.125rem 23.875rem',
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          backgroundColor: theme.colors.BLACK_95,
          [theme.breakpoints.up('desktop')]: {
            justifyContent: 'center',
          },
        }}
      >
        <Dialog
          open={dialogOpen}
          handleDialogClose={handleDialogClose}
          description={t('report.noAccessFail', { ns: 'snackbar' })}
          confirmButtonText={t('yesCta', { ns: 'component' })}
          handleConfirmButtonClick={() => navigate('/')}
        />
        <Stack sx={{ px: 28 }}>
          {isFetchReportHtmlContentLoading ? (
            <ReportDetailLoading />
          ) : (
            <>
              <Box id={BACK_TO_TOP}>
                {reportHtmlContent && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        `<style>body > .flash:before {
                    content: '';
                    display: block;
                    width: 30px;
                    box-sizing: content-box;
                    background: center center / 100% no-repeat;
                    background-origin: content-box;
                    padding: 0;
                    height: 30px;
                    background-image: url(/assets/karen/icon_circle_exclamation-9da164a2f218abaa4e093e3a67097897f45553055de2441924fff88bf218171f.svg);
                    margin-right: 10px;
                  }
                  
                  body > .flash a.dismiss {
                    display: block;
                    width: 20px;
                    box-sizing: content-box;
                    background: center center / 100% no-repeat;
                    background-origin: content-box;
                    padding: 10px;
                    height: 20px;
                    background-image: url(/assets/karen/icon_close-5e3e77425f109f529cbbe80fef8bcbb17ebd3aa2cbdb81cefce24e9a071a915d.svg);
                    position: absolute;
                    top: 10px;
                    right: 30px;
                  }</style><main>${DOMPurify.sanitize(
                    reportHtmlContent
                  )}</main>` || '',
                    }}
                  />
                )}
              </Box>
              <Box id={GO_TO_BOTTOM} />
              <Box sx={{ py: 10 }}>
                <UserComments />
              </Box>
            </>
          )}
        </Stack>
        <ReportDetailInfo tocList={tocList} />
      </Box>
    </>
  )
}
