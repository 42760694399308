import { TCommentType } from './comment'
import {
  IRelatedRecord,
  IRelatedRecordAPIData,
  mapAPIRelatedRecordToState,
  TRiskLevel,
} from './shared'

export interface IDomain {
  id: string
  domain: string
  commentableType: TCommentType
}

export interface IDomainAPIData {
  main_value: string
  fqdn: string
  commentable_type: TCommentType
}

export interface IDomainDetailAPIData {
  risk_level: TRiskLevel
  risk_score: number
  risk_types: string[]
  adversaries: string[]
  attributes: IRelatedRecordAPIData[]
  country: string
  region: string
  city: string
  last_update_at: string
  registrar: string
  summary: {
    whois: boolean
    related_adversaries: number
    related_reports: number
    related_samples: number
    dns_records: number
    osint: number
  }
}

export interface IDomainDetail {
  riskLevel: TRiskLevel
  riskScore: number
  riskTypes: string[]
  adversaries: string[]
  attributes: IRelatedRecord[]
  country: string
  region: string
  lastUpdateAt: string
  registrar: string
  city: string
  summary: {
    whois: boolean
    relatedAdversaries: number
    relatedReports: number
    relatedSamples: number
    dnsRecords: number
    osint: number
  }
}

export const mapAPIDomainsToState = (
  fromAPIDomains: IDomainAPIData[]
): IDomain[] =>
  fromAPIDomains.map((fromAPIDomain) => ({
    id: fromAPIDomain.main_value,
    domain: fromAPIDomain.fqdn,
    commentableType: fromAPIDomain.commentable_type,
  }))

export const mapAPIDomainDetailToState = (
  fromAPIDomainDetail: IDomainDetailAPIData
): IDomainDetail => ({
  riskLevel: fromAPIDomainDetail.risk_level,
  riskScore: fromAPIDomainDetail.risk_score,
  riskTypes: fromAPIDomainDetail.risk_types,
  adversaries: fromAPIDomainDetail.adversaries,
  attributes: mapAPIRelatedRecordToState(fromAPIDomainDetail.attributes),
  registrar: fromAPIDomainDetail.registrar,
  country: fromAPIDomainDetail.country,
  region: fromAPIDomainDetail.region,
  city: fromAPIDomainDetail.city,
  lastUpdateAt: fromAPIDomainDetail.last_update_at,
  summary: {
    whois: fromAPIDomainDetail.summary.whois,
    relatedAdversaries: fromAPIDomainDetail.summary.related_adversaries,
    relatedReports: fromAPIDomainDetail.summary.related_reports,
    relatedSamples: fromAPIDomainDetail.summary.related_samples,
    dnsRecords: fromAPIDomainDetail.summary.dns_records,
    osint: fromAPIDomainDetail.summary.osint,
  },
})
