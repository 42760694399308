import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { DateLabel } from 'components/Label/Label'
import { ExternalLink } from 'components/Link/Link'
import { useDateTime } from 'hooks/useDatetime'
import { useTranslation } from 'react-i18next'
import { IPMReportDetail } from 'store/types/entityTypes/report'

interface IPubliclyDisclosedDrawerProps {
  selectedCVE: string
  pmReportDetail: IPMReportDetail[]
  publiclyDisclosedDrawerOpen: boolean
  onClose: () => void
}
export const PubliclyDisclosedDrawer = ({
  selectedCVE,
  pmReportDetail,
  publiclyDisclosedDrawerOpen,
  onClose,
}: IPubliclyDisclosedDrawerProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const { t } = useTranslation(['report'])

  const selectedPMReportDetail = pmReportDetail.find(
    ({ id }) => id === selectedCVE
  )

  return (
    <Drawer
      open={publiclyDisclosedDrawerOpen}
      onClose={onClose}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: theme.colors.MASK,
        },
      }}
    >
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {selectedCVE}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      {selectedPMReportDetail &&
        selectedPMReportDetail.poc &&
        selectedPMReportDetail.poc.length !== 0 && (
          <>
            <Box sx={{ py: 2 }}>
              <Typography variant="title" sx={{ color: theme.colors.WHITE }}>
                {t('pmReportsDetail.poc', { ns: 'report' })}
              </Typography>
            </Box>
            <Stack sx={{ gap: 2 }}>
              {selectedPMReportDetail.poc.map(
                ({ url, updated, description }) => (
                  <Stack key={description} sx={{ gap: 1 }}>
                    <Box sx={{ width: 'fit-content' }}>
                      {updated && (
                        <DateLabel
                          text={getDateStringByTimestampsInSeconds(updated)}
                        />
                      )}
                    </Box>
                    <Box sx={{ width: 'fit-content' }}>
                      <ExternalLink href={url}>{description}</ExternalLink>
                    </Box>
                  </Stack>
                )
              )}
            </Stack>
          </>
        )}
    </Drawer>
  )
}
