import { Box, Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { ADVERSARY_INFO_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IAdversary } from 'store/types/entityTypes/adversary'

const DISPLAY_ADVERSARIES_COUNT = 3

export const AdversaryBlock = ({ image, name, id, aliases }: IAdversary) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  const displayAlias = aliases.slice(0, DISPLAY_ADVERSARIES_COUNT)

  const imageUrl = useImageUrl({ fetchImageUrl: image })

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        px: 4,
        py: 2,
        gap: 2,
        alignItems: 'center',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: theme.colors.BLACK_60,
        },
        '&:active': {
          bgcolor: theme.colors.BLACK_95,
        },
      }}
      to={`${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_ID}=${id}`}
      component={Link}
    >
      {imageUrl && <Image src={imageUrl} width={128} height={128} />}
      <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
        {name}
      </Typography>
      <Box
        sx={{ display: 'flex', gap: 2, alignSelf: 'baseline', width: '100%' }}
      >
        <HighlightLabel
          text={t('adversaryPreview.labels.alias', { ns: 'component' })}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            flexBasis: 0,
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {displayAlias.length > 0 ? (
            displayAlias.map((al, index) => {
              const isLastAlias = index === displayAlias.length - 1
              const showDots =
                isLastAlias && aliases.length > DISPLAY_ADVERSARIES_COUNT
              return (
                <Typography
                  key={al}
                  variant="textSmallImportant"
                  color={theme.colors.WHITE}
                >
                  {al}
                  {!isLastAlias && ','}
                  {showDots && '...'}
                </Typography>
              )
            })
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
              <Typography color={theme.colors.WHITE}>--</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  )
}
