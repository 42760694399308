import { Box, Stack } from '@mui/material'
import PrivacyPolicyImage from 'assets/documentImages/privacyPolicy.jpg'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H3Title } from 'components/Documents/H3Title'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_PRIVACY_POLICY } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'
import { openMail } from 'utils/mail'

export const PrivacyPolicyPage = () => {
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_PRIVACY_POLICY}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title title="Privacy Policy" />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.PRIVACY_POLICY_DOCUMENT_TIMESTAMP
              )}
            />
            <Image
              src={PrivacyPolicyImage}
              alt="privacyPolicy"
              height="25rem"
              fit="cover"
            />
            <Description>
              We, Team T5 Inc., a limited liability company, organized and duly
              existing under the laws of Taiwan (R.O.C.), having the principal
              office at 15F., No. 46, Ln. 11, Guangfu N. Rd., Songshan Dist.,
              105 Taipei City, Taiwan (&quot;TeamT5&quot;), develops this
              ThreatVision portal (the &quot;Portal&quot;) that allows users to
              access our service over the Internet.
            </Description>
            <Description>
              This Privacy Policy describes the types of personal data
              collected, how such data is being used as well as what are your
              rights and choices in relation to your personal data processed
              when you visit the Portal and interact with it.
            </Description>
            <Description>
              The Portal is not intended for children, and we do not knowingly
              collect data relating to children. This Privacy Policy does not
              apply to any third-party websites, services, or applications, even
              if they may be accessible through the Portal.
            </Description>
            <H3Title title="Collection of Personal Data" />
            <Description>
              We collect and process personal data, which refers to any
              information relating to an identified or identifiable natural
              person, you voluntarily submit to us, as well as information
              provided automatically by your browser or mobile device when you
              visit the Portal.
            </Description>
            <Description>
              Typically, we may collect, process, and use the following
              categories of data:
              <UnorderedList>
                <ListItem>your first and last name;</ListItem>
                <ListItem>
                  your contact information, such as telephone and e-mail
                  address;
                </ListItem>
                <ListItem>
                  your business information, such as company name and type;
                </ListItem>
                <ListItem>your IP address;</ListItem>
                <ListItem>operating system;</ListItem>
                <ListItem>browser type;</ListItem>
                <ListItem>browser version;</ListItem>
                <ListItem>browser configuration;</ListItem>
                <ListItem>
                  the URL and IP address of the website from which you access
                  the Portal or from which you are transferred to the Portal,
                  including date and time;
                </ListItem>
                <ListItem>
                  any pages of the Portal on which you click during your visit,
                  and any links on the Portal which you click, including date
                  and time;
                </ListItem>
                <ListItem>
                  the entire URL click stream regarding, through, and from the
                  Portal, including date and time;
                </ListItem>
                <ListItem>
                  information collected by cookies or similar technologies (as
                  explained below);
                </ListItem>
                <ListItem>
                  any other relevant information regarding your computer and
                  Internet connection in order to identify the type of your
                  device, to connect you to the Portal, to exchange data with
                  your terminal device, or to ensure the proper use of the
                  Portal.
                </ListItem>
              </UnorderedList>
            </Description>
            <Description>
              If you do not provide the necessary information, we may not be
              able to fulfill your request. We also use cookies to collect
              information about your use of the Portal, such as the IP address
              of the computer you are using, the browser you are using, the type
              of operating system, and other similar information.
            </Description>
            <H3Title title="Cookies" />
            <Description>
              The Internet pages of the Portal use cookies. Cookies are text
              files that are stored in a computer system via an Internet
              browser. This allows us to recognize our website users. The
              purpose of this recognition is to make it easier for users to
              utilize the Portal. Through the use of cookies, we can provide the
              users of the Portal with more user-friendly services.
            </Description>
            <Description>
              The user that uses cookies, does not have to enter access data
              each time the Portal is accessed, because this is taken over by
              the Portal, and the cookie is stored on the user&apos;s computer
              system. You may, at any time, prevent the setting of cookies
              through the Portal by means of a corresponding setting of the
              Internet browser used, and may thus permanently deny the setting
              of cookies. If you deactivate the setting of cookies in the
              Internet browser used, some functions of the Portal may be
              unusable.
            </Description>
            <H3Title title="Use of Personal Data" />
            <Description>
              We do not draw any conclusions about the visitors of the Portal.
              Rather, the information we collected is processed for the
              following purposes:
              <UnorderedList>
                <ListItem>
                  to deliver the content of the Portal correctly;
                </ListItem>
                <ListItem>to respond to your inquiry or request;</ListItem>
                <ListItem>to optimize the content of the Portal;</ListItem>
                <ListItem>
                  to ensure the long-term viability of our information
                  technology systems and website technology;
                </ListItem>
                <ListItem>
                  to help improve the performance of the Portal, as well as
                  develop new features and functionality;
                </ListItem>
                <ListItem>
                  as well as any other uses listed in the collection of personal
                  data.
                </ListItem>
              </UnorderedList>
            </Description>
            <H3Title title="Retention of Personal Data" />
            <Description>
              We will keep your personal data for a period of time not exceeding
              the legal permission and, in any case, not exceeding the period
              necessary to achieve the collection or processing of personal
              data.
            </Description>
            <H3Title title="Disclosure of Personal Data" />
            <Description>
              We may be related to the above purposes in our company or
              affiliated companies, and in accordance with the need to go to a
              special authorized personnel to share the personal data collected
              on the Portal.
            </Description>
            <Description>
              We reserve the right to disclose the personal data collected on
              the Portal to any other third party, whether it is required by law
              or in compliance with the law, including:
              <UnorderedList>
                <ListItem>
                  in compliance with legal procedures or at the request of the
                  government;
                </ListItem>
                <ListItem>
                  for prevention, investigating, or prosecuting criminal
                  offences or attacks on the integrity of the Portal;
                </ListItem>
                <ListItem>
                  protecting the rights, property or security of TeamT5, the
                  users of the Portal or the public.
                </ListItem>
              </UnorderedList>
            </Description>
            <Description>
              We may also use the assistance of third parties to improve the
              Portal and share the personal data collected on the Portal. These
              third parties may include:
              <UnorderedList>
                <ListItem>
                  Vendors or public institutions. To the extent that this is
                  necessary in order to make use of certain services requiring
                  special expertise (such as legal, accounting or auditing
                  services), we may share personal data with vendors of such
                  services or public institutions that offer them.
                </ListItem>
                <ListItem>
                  Organizational changes. We may disclose personal data to allow
                  a change of ownership of TeamT5 (including, but not limited
                  to, an acquisition by or a merger with another company) and
                  related transfer of all such information to the new owner, in
                  which case any information remains protected in accordance
                  with this Privacy Policy.
                </ListItem>
                <ListItem>
                  Other than in the cases mentioned above, we will only transfer
                  personal data to third parties upon your explicit consent, or
                  if we are obliged to do so by statutory law or by instruction
                  from a public authority or court.
                </ListItem>
              </UnorderedList>
            </Description>
            <H3Title title="Data security" />
            <Description>
              We are very committed to establishing internal procedures to
              ensure that your personal information is both accurate and
              protected from accidental loss, unauthorized access, use,
              alteration, or disclosure. However, internet-based data
              transmissions may in principle have security gaps, so absolute
              protection cannot be guaranteed.
            </Description>
            <H3Title title="Your Rights" />
            <Description>
              Relating to your personal data collected or processed by us, you
              have the right to:
              <UnorderedList>
                <ListItem>
                  request information on personal data processed by us about
                  you;
                </ListItem>
                <ListItem>
                  demand the correction of incorrect data or completion of
                  incomplete personal data stored by us;
                </ListItem>
                <ListItem>
                  to terminate processing of your personal data;
                </ListItem>
                <ListItem>
                  request erasure of your personal data stored by us, unless the
                  processing of the data is required for the exercise of the
                  right to freedom of expression and information, for the
                  fulfillment of a legal obligation, for reasons of public
                  interest or for the assertion, exercise or defense of legal
                  claims.
                </ListItem>
              </UnorderedList>
            </Description>
            <Description>
              If you would like to exercise these rights, please contact us
              using details indicated below under contact details. Please
              include any information that would help us identify you in our
              database, such as your account name and email address.
            </Description>
            <H3Title title="Third-Party Websites" />
            <Description>
              If the Portal includes hyperlinks to, and details of, any
              third-party websites, services or applications. We have no control
              over, and are not responsible for, the privacy policies and
              practices of the third parties.
            </Description>
            <H3Title title="Personal Data of Children" />
            <Description>
              ThreatVison targets at persons over the age of 18. If we have
              reasons to believe that we hold personal data of a person under
              that age in our databases, we will delete it.
            </Description>
            <H3Title title="International Transmission" />
            <Description>
              Since TeamT5 is a Taiwan-based company, any personal data you
              submit through the Portal is collected and processed directly by
              TeamT5 in Taiwan. You also agree to transfer your personal data
              outside Taiwan.
            </Description>
            <H3Title title="Changes to the Privacy Policy" />
            <Description>
              The privacy legislation is still evolving and governmental
              authorities issue guidelines and updates on a regular basis. We
              may need to change our Privacy Policy to ensure that we implement
              such recommendations. We may at any time, without notice to you,
              revise the Privacy Policy and any other information contained in
              this Portal.
            </Description>
            <H3Title title="Governing Law and Jurisdiction." />
            <Description>
              The terms of this Privacy Policy shall be governed and construed
              exclusively by the laws of Taiwan (R.O.C.), not including its
              conflicts of law provisions. If there is any dispute relating to
              this Privacy Policy which cannot be resolved amicably, then it
              will be resolved in the courts of Taiwan. You agree that if there
              is any legal action or proceeding relating to this Privacy Policy,
              it should be brought exclusively in the Taiwan Taipei District
              Court for the first instance, and you consent to the jurisdiction
              thereof.
            </Description>
            <H3Title title="Contacting Us" />
            <Description>
              With regard to exercising your rights under the Privacy Policy or
              for questions specifically about this Privacy Policy, or our use
              of your personal information, cookies or similar technologies,
              please contact us by email at{' '}
              <TextLink size="large" onClick={openMail} to="">
                support@teamt5.org
              </TextLink>
            </Description>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
