import { AdversaryTabContent } from 'components/Adversary/AdversaryTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { SampleTabContent } from 'components/Sample/SampleTabContent'
import { TechniqueTabContent } from 'components/Technique/TechniqueTabContent'
import { UserCommentTabContent } from 'components/UserComments/UserCommentTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { ThreatHuntingToolTabContent } from 'pages/MalwarePage/MalwareContentPage/ThreatHuntingTools/ThreatHuntingToolTabContent'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectCommentLoading, selectCommentsCount } from 'store/slices/comment'
import {
  selectMalwareTools,
  selectMalwareToolsLoading,
} from 'store/slices/malware'
import {
  selectTabAdversaryCount,
  selectTabAdversaryCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
  selectTabSampleCount,
  selectTabSampleCountLoading,
  selectTabTechniqueCount,
  selectTabTechniqueCountLoading,
} from 'store/slices/tabContent'

export interface IMalwareCategoryTab {
  key: string
  name: string
  component: FC<{ searchText: string }>
  count: number
  hiddenEmpty: boolean
  isLoading?: boolean
  appendPlusSign?: boolean
}

export const useMalwareCategoryTabs = (): IMalwareCategoryTab[] => {
  const { t } = useTranslation(['malware', 'component'])
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)

  const tabAdversaryCountLoading = useAppSelector(
    selectTabAdversaryCountLoading
  )
  const tabAdversaryCount = useAppSelector(selectTabAdversaryCount)

  const tabSampleCountLoading = useAppSelector(selectTabSampleCountLoading)
  const tabSampleCount = useAppSelector(selectTabSampleCount)

  const toolsLoading = useAppSelector(selectMalwareToolsLoading)
  const toolsCount = useAppSelector(selectMalwareTools).length

  const tabCommentCount = useAppSelector(selectCommentsCount)
  const isCommentLoading = useAppSelector(selectCommentLoading)

  const tabTechniqueCountLoading = useAppSelector(
    selectTabTechniqueCountLoading
  )
  const tabTechniqueCount = useAppSelector(selectTabTechniqueCount)

  const tabs: IMalwareCategoryTab[] = [
    {
      key: 'reports',
      name: t('categoryTabs.reports', { ns: 'component' }),
      component: ReportTabContent,
      count: tabReportCount,
      hiddenEmpty: true,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'adversaries',
      name: t('categoryTabs.adversaries', { ns: 'component' }),
      component: AdversaryTabContent,
      count: tabAdversaryCount,
      hiddenEmpty: true,
      isLoading: tabAdversaryCountLoading,
    },
    {
      key: 'samples',
      name: t('categoryTabs.samples', { ns: 'component' }),
      component: SampleTabContent,
      count: tabSampleCount,
      hiddenEmpty: true,
      isLoading: tabSampleCountLoading,
      appendPlusSign: tabSampleCount >= 1000,
    },
    {
      key: 'techniques',
      name: t('categoryTabs.capabilities', { ns: 'component' }),
      component: TechniqueTabContent,
      count: tabTechniqueCount,
      hiddenEmpty: false,
      isLoading: tabTechniqueCountLoading,
    },
    {
      key: 'threatHuntingTools',
      name: t('categoryTabs.threatHuntingTools', { ns: 'component' }),
      component: ThreatHuntingToolTabContent,
      count: toolsCount,
      hiddenEmpty: true,
      isLoading: toolsLoading,
    },
    {
      key: 'comments',
      name: t('categoryTabs.comments', { ns: 'component' }),
      component: UserCommentTabContent,
      count: tabCommentCount,
      hiddenEmpty: false,
      isLoading: isCommentLoading,
    },
  ]

  return tabs.filter(
    (tab) => !(tab.hiddenEmpty && !tab.isLoading && tab.count === 0)
  )
}
