import { Box, SvgIcon, SvgIconProps, Typography, useTheme } from '@mui/material'
import { ReactComponent as KeyboardArrowDownIcon } from 'assets/basicIcons/angleDown.svg'
import { ReactComponent as KeyboardArrowUpIcon } from 'assets/basicIcons/angleUp.svg'
import { ReactElement, ReactNode } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

interface INavbarOptionAccordionProps {
  icon: ReactElement<SvgIconProps>
  text: string
  isActive: boolean
  children: NonNullable<ReactNode>
  expand: boolean
  handleAccordionExpand: (expand: boolean) => void
}
export const NavbarOptionAccordion = ({
  icon,
  text,
  isActive,
  children,
  expand,
  handleAccordionExpand,
}: INavbarOptionAccordionProps) => {
  const theme = useTheme()

  const handleExpanded = () => {
    handleAccordionExpand(!expand)
  }

  return (
    <Box>
      <Box
        data-testid="navbar-accordion"
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.colors.BLACK_20,
          },
        }}
        onClick={handleExpanded}
      >
        <Box
          sx={{
            width: '2rem',
            height: theme.fixedConstants.FIXED_TOP_HEIGHT,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: isActive ? theme.colors.PRIMARY_60 : 'transparent',
              borderRadius: 1,
              border: isActive ? `1px solid ${theme.colors.WHITE_20}` : 'none',
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            pl: 1,
            pr: 2,
          }}
        >
          <Typography
            sx={{ color: theme.colors.WHITE }}
            variant="textSmallImportant"
          >
            {text}
          </Typography>
          {expand ? (
            <SvgIcon
              sx={{ width: '1.25rem', height: '1.25rem' }}
              component={KeyboardArrowUpIcon}
              inheritViewBox
            />
          ) : (
            <SvgIcon
              sx={{ width: '1.25rem', height: '1.25rem' }}
              component={KeyboardArrowDownIcon}
              inheritViewBox
            />
          )}
        </Box>
      </Box>
      {expand && children}
    </Box>
  )
}

const accordionContentIndentLevelMap: Record<number, string> = {
  1: '2rem',
  2: '3.5rem',
}

interface INavAccordionContentProps {
  text: string
  activeKey: string
  indentLevel?: number
  disabled?: boolean
  endTag?: NonNullable<ReactNode>
}

export const NavAccordionContent = ({
  text,
  activeKey,
  indentLevel = 1,
  disabled = false,
  endTag,
}: INavAccordionContentProps) => {
  const theme = useTheme()
  const { pathname } = useLocation()

  return (
    <Link to={disabled ? '#' : `${activeKey}`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: accordionContentIndentLevelMap[indentLevel],
          pr: 2,
          py: 1.5,
          cursor: disabled ? 'default' : 'pointer',
          '&:hover': {
            bgcolor: matchPath({ path: activeKey, end: false }, pathname)
              ? theme.colors.PRIMARY_40
              : theme.colors.BLACK_20,
          },
          bgcolor: matchPath({ path: activeKey, end: false }, pathname)
            ? theme.colors.PRIMARY_60
            : theme.colors.BLACK_95,
        }}
      >
        <Typography
          variant="body"
          sx={{ color: disabled ? theme.colors.DISABLE : theme.colors.WHITE }}
        >
          {text}
        </Typography>
        {endTag && (
          <Box component="span" sx={{ ml: 2 }}>
            {endTag}
          </Box>
        )}
      </Box>
    </Link>
  )
}
