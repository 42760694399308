import type { Action } from '@reduxjs/toolkit'
import {
  CREATE_COMMENT,
  FETCH_COMMENTS,
  getDeleteCommentsAPI,
  getUpdateCommentsAPI,
} from 'constants/api'
import i18n from 'i18next'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  createComment,
  createCommentFulfilled,
  createCommentRejected,
  deleteComment,
  deleteCommentFulfilled,
  deleteCommentRejected,
  fetchComments,
  fetchCommentsCancelled,
  fetchCommentsFulfilled,
  fetchCommentsRejected,
  updateComment,
  updateCommentFulfilled,
  updateCommentRejected,
} from 'store/slices/comment'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { mapAPICommentsToState } from 'store/types/entityTypes/comment'
import { IFetchCommentsResponse } from 'store/types/slicesTypes/comment'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

const fetchCommentsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchComments.match),
    switchMap((action) =>
      fetchData<IFetchCommentsResponse>({
        url: `${FETCH_COMMENTS}?commentable_type=${action.payload.commentableType}&commentable_id=${action.payload.commentableId}`,
      }).pipe(
        map((response: AjaxResponse<IFetchCommentsResponse>) =>
          fetchCommentsFulfilled(
            mapAPICommentsToState(response.response.comments)
          )
        ),
        takeUntil(action$.pipe(filter(fetchCommentsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchCommentsRejected(error))
          )
        )
      )
    )
  )

const createCommentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(createComment.match),
    switchMap((action) =>
      fetchData({
        method: 'POST',
        url: `${CREATE_COMMENT}?commentable_type=${action.payload.commentableType}&commentable_id=${action.payload.commentableId}`,
        body: action.payload.body,
      }).pipe(
        mergeMap(() =>
          merge(
            of(createCommentFulfilled()).pipe(
              tap(() => action.payload.successCallback())
            ),
            of(
              pushSuccessSnackbar({
                text: i18n.t('comment.submitSuccess', { ns: 'snackbar' }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(createCommentRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('comment.submitFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const updateCommentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(updateComment.match),
    switchMap((action) =>
      fetchData({
        method: 'PATCH',
        url: getUpdateCommentsAPI(action.payload.commentableId),
        body: action.payload.body,
      }).pipe(
        mergeMap(() =>
          merge(
            of(updateCommentFulfilled()).pipe(
              tap(() => action.payload.successCallback())
            ),
            of(
              pushSuccessSnackbar({
                text: i18n.t('comment.updateSuccess', { ns: 'snackbar' }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(updateCommentRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('comment.updateFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const deleteCommentEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(deleteComment.match),
    switchMap((action) =>
      fetchData({
        method: 'DELETE',
        url: getDeleteCommentsAPI(action.payload.commentableId),
      }).pipe(
        mergeMap(() =>
          merge(
            of(deleteCommentFulfilled()).pipe(
              tap(() => action.payload.successCallback())
            ),
            of(
              pushSuccessSnackbar({
                text: i18n.t('comment.deleteSuccess', { ns: 'snackbar' }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(deleteCommentRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('comment.deleteFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

export const commentEpic = combineEpics(
  fetchCommentsEpic,
  createCommentEpic,
  updateCommentEpic,
  deleteCommentEpic
)
