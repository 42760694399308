import { Box } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { Select, SelectItem } from 'components/Select/Select'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { updateAccountSettings, updateTimezone } from 'store/slices/account'
import {
  IAccountSettings,
  IAccountSettingsOptions,
} from 'store/types/entityTypes/account'

export const TimeZone = ({
  accountSettings,
  accountSettingsOptions,
}: {
  accountSettings: IAccountSettings
  accountSettingsOptions: IAccountSettingsOptions
}) => {
  const { t } = useTranslation(['account', 'snackbar'])
  const dispatch = useAppDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTimezone = event.target.value || null
    dispatch(
      updateAccountSettings({
        tip_user: {
          merge_preferences: {
            timezone: newTimezone,
          },
        },
        successMessage: t('timeZone.updateSuccess', { ns: 'snackbar' }),
        failMessage: t('timeZone.updateFail', { ns: 'snackbar' }),
      })
    )

    dispatch(updateTimezone(newTimezone))
  }

  return (
    <PageSection title={t('timeZone.title', { ns: 'account' })}>
      <Box sx={{ px: 4, py: 2 }}>
        <Select
          value={accountSettings.timezone || ''}
          onChange={handleChange}
          width="13.125rem"
          displayEmpty
          sx={{ textTransform: 'capitalize' }}
        >
          {accountSettingsOptions.timezones.map((timezone) => (
            <SelectItem
              key={timezone.value}
              value={timezone.value || ''}
              sx={{ textTransform: 'capitalize' }}
            >
              {timezone.displayValue}
            </SelectItem>
          ))}
        </Select>
      </Box>
    </PageSection>
  )
}
