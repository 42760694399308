import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { Flag } from 'components/Flag/Flag'
import { HighlightLabel } from 'components/Label/Label'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppSelector } from 'hooks/useReduxHooks'
import { threatLvMap, useThreatLvColor } from 'hooks/useThreatLevel'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { selectIPDetail } from 'store/slices/ip'

export const IPInfo = () => {
  const theme = useTheme()
  const { t } = useTranslation(['ipDomain'])
  const [searchParams] = useSearchParams()
  const threatLvColor = useThreatLvColor()
  const ipDetail = useAppSelector(selectIPDetail)

  const ip = searchParams.get(`${SearchParamKey.IP}`)

  return (
    <Box
      data-testid="ip-info"
      sx={{ display: 'flex', alignItems: 'center', gap: 4, p: 3 }}
    >
      {ipDetail && (
        <>
          <Stack sx={{ alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                width: '6.875rem',
                height: '6.875rem',
                borderRadius: '50%',
                bgcolor: theme.colors.PRIMARY_20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '6.25rem',
                  height: '6.25rem',
                  borderRadius: '50%',
                  bgcolor: theme.colors.BLACK_85,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '6rem',
                    height: '6rem',
                    borderRadius: '50%',
                    bgcolor: threatLvColor[ipDetail.riskLevel],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="ITMScore"
                    sx={{ color: theme.colors.WHITE, display: 'flex' }}
                  >
                    {ipDetail.riskScore}
                  </Typography>
                  <Typography
                    variant="textSmallImportant"
                    sx={{
                      color: theme.colors.WHITE,
                      mt: -2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      // for `Medium Threat` word break line
                      maxWidth: '5.75rem',
                      lineHeight:
                        ipDetail.riskLevel === 'medium' ? '110%' : '140%',
                    }}
                  >
                    {threatLvMap[ipDetail.riskLevel]}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="textSmallImportant">
                        {t('scoreTooltip', { ns: 'ipDomain' })}
                      </Typography>
                    }
                  >
                    <SvgIcon
                      component={InfoIcon}
                      inheritViewBox
                      sx={{ width: '1rem', height: '1rem' }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {ipDetail.riskTypes &&
              ipDetail.riskTypes.map((type) => (
                <Typography
                  key={type}
                  variant="textSmallImportant"
                  sx={{
                    px: 2,
                    bgcolor: theme.colors.PRIMARY_20,
                    color: theme.colors.PRIMARY,
                    borderRadius: 0.5,
                  }}
                >
                  {type}
                </Typography>
              ))}
          </Stack>
          <Stack sx={{ gap: 2, flexGrow: 1 }}>
            <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
              {ip}
            </Typography>
            <Divider sx={{ width: '100%', bgcolor: theme.colors.WHITE_20 }} />
            <Grid container>
              <Grid sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    pb: 2,
                  }}
                >
                  <HighlightLabel
                    text={t('ipLabels.attribution', { ns: 'ipDomain' })}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {ipDetail.attributes.length > 0 ? (
                      ipDetail.attributes.map((attribute, index) => (
                        <Box
                          key={attribute.name}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            variant="textSmallImportant"
                            component="p"
                            sx={{ color: theme.colors.WHITE }}
                          >
                            {attribute.name}
                          </Typography>
                          <Tooltip
                            title={
                              <Stack>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.firstSeen', { ns: 'ipDomain' })}:{' '}
                                  {attribute.firstSeen}
                                </Typography>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.lastSeen', { ns: 'ipDomain' })}:{' '}
                                  {attribute.lastSeen}
                                </Typography>
                              </Stack>
                            }
                          >
                            <SvgIcon
                              component={InfoIcon}
                              inheritViewBox
                              sx={{ width: '1rem', height: '1rem' }}
                            />
                          </Tooltip>

                          {index < ipDetail.attributes.length - 1 && (
                            <Typography
                              variant="textSmallImportant"
                              sx={{ color: theme.colors.WHITE }}
                            >
                              ,
                            </Typography>
                          )}
                        </Box>
                      ))
                    ) : (
                      <Typography
                        variant="textSmallImportant"
                        sx={{ color: theme.colors.WHITE }}
                      >
                        --
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    pb: 2,
                  }}
                >
                  <HighlightLabel
                    text={t('ipLabels.ipSharing', { ns: 'ipDomain' })}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {ipDetail.ipSharing.length > 0 ? (
                      ipDetail.ipSharing.map((sharing, index) => (
                        <Box
                          key={sharing.name}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            variant="textSmallImportant"
                            component="p"
                            sx={{ color: theme.colors.WHITE }}
                          >
                            {sharing.name}
                          </Typography>
                          <Tooltip
                            title={
                              <Stack>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.firstSeen', { ns: 'ipDomain' })}:{' '}
                                  {sharing.firstSeen}
                                </Typography>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.lastSeen', { ns: 'ipDomain' })}:{' '}
                                  {sharing.lastSeen}
                                </Typography>
                              </Stack>
                            }
                          >
                            <SvgIcon
                              component={InfoIcon}
                              inheritViewBox
                              sx={{ width: '1rem', height: '1rem' }}
                            />
                          </Tooltip>
                          {index < ipDetail.ipSharing.length - 1 && (
                            <Typography
                              variant="textSmallImportant"
                              sx={{ color: theme.colors.WHITE }}
                            >
                              ,
                            </Typography>
                          )}
                        </Box>
                      ))
                    ) : (
                      <Typography
                        variant="textSmallImportant"
                        sx={{ color: theme.colors.WHITE }}
                      >
                        --
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    pb: 2,
                  }}
                >
                  <HighlightLabel
                    text={t('ipLabels.service', { ns: 'ipDomain' })}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {ipDetail.services.length > 0 ? (
                      ipDetail.services.map((service, index) => (
                        <Box
                          key={service.name}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            variant="textSmallImportant"
                            component="p"
                            sx={{ color: theme.colors.WHITE }}
                          >
                            {service.name}
                          </Typography>
                          <Tooltip
                            title={
                              <Stack>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.firstSeen', { ns: 'ipDomain' })}:{' '}
                                  {service.firstSeen}
                                </Typography>
                                <Typography variant="textSmallImportant">
                                  {t('tooltip.lastSeen', { ns: 'ipDomain' })}:{' '}
                                  {service.lastSeen}
                                </Typography>
                              </Stack>
                            }
                          >
                            <SvgIcon
                              component={InfoIcon}
                              inheritViewBox
                              sx={{ width: '1rem', height: '1rem' }}
                            />
                          </Tooltip>
                          {index < ipDetail.services.length - 1 && (
                            <Typography
                              variant="textSmallImportant"
                              sx={{ color: theme.colors.WHITE }}
                            >
                              ,
                            </Typography>
                          )}
                        </Box>
                      ))
                    ) : (
                      <Typography
                        variant="textSmallImportant"
                        sx={{ color: theme.colors.WHITE }}
                      >
                        --
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Flag width={60} country={ipDetail.country} />
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        pb: 2,
                      }}
                    >
                      <HighlightLabel
                        text={t('ipLabels.latestUpdate', { ns: 'ipDomain' })}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          variant="textSmallImportant"
                          component="p"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          {ipDetail.lastUpdateAt || '--'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        pb: 2,
                      }}
                    >
                      <HighlightLabel
                        text={t('ipLabels.region', { ns: 'ipDomain' })}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          variant="textSmallImportant"
                          component="p"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          {ipDetail.region || '--'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        pb: 2,
                      }}
                    >
                      <HighlightLabel
                        text={t('ipLabels.city', { ns: 'ipDomain' })}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          variant="textSmallImportant"
                          component="p"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          {ipDetail.city || '--'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
    </Box>
  )
}
