import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  getFlattenSubComments,
  IComment,
} from 'store/types/entityTypes/comment'
import {
  ICommentsSlice,
  ICreateCommentPayload,
  IDeleteCommentPayload,
  IFetchCommentsPayload,
  IUpdateCommentPayload,
} from 'store/types/slicesTypes/comment'

const initialState: ICommentsSlice = {
  isLoading: false,
  creatingCommentTargetId: '',
  updatingCommentTargetId: '',
  comments: [],
  commentableType: null,
  commentableId: '',
}

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setupCommentableIdAndType(
      state,
      action: PayloadAction<IFetchCommentsPayload>
    ) {
      state.commentableId = action.payload.commentableId
      state.commentableType = action.payload.commentableType
    },
    fetchComments(state, _action: PayloadAction<IFetchCommentsPayload>) {
      state.isLoading = true
    },
    fetchCommentsFulfilled(state, action: PayloadAction<IComment[]>) {
      state.comments = getFlattenSubComments(action.payload)
      state.isLoading = false
    },
    fetchCommentsRejected(state, _action: PayloadAction<AjaxError>) {
      state.comments = initialState.comments
      state.isLoading = false
    },
    fetchCommentsCancelled(state) {
      state.isLoading = false
    },
    createComment(state, action: PayloadAction<ICreateCommentPayload>) {
      state.creatingCommentTargetId = action.payload.commentableId
    },
    createCommentFulfilled(state) {
      state.creatingCommentTargetId = ''
    },
    createCommentRejected(state, _action: PayloadAction<AjaxError>) {
      state.creatingCommentTargetId = ''
    },
    updateComment(state, action: PayloadAction<IUpdateCommentPayload>) {
      state.updatingCommentTargetId = action.payload.commentableId
    },
    updateCommentFulfilled(state) {
      state.updatingCommentTargetId = ''
    },
    updateCommentRejected(state, _action: PayloadAction<AjaxError>) {
      state.updatingCommentTargetId = ''
    },
    deleteComment(_state, _action: PayloadAction<IDeleteCommentPayload>) {},
    deleteCommentFulfilled(_state) {},
    deleteCommentRejected(_state, _action: PayloadAction<AjaxError>) {},
    resetComments(state) {
      state.isLoading = initialState.isLoading
      state.creatingCommentTargetId = initialState.creatingCommentTargetId
      state.updatingCommentTargetId = initialState.updatingCommentTargetId
      state.comments = initialState.comments
      state.commentableId = initialState.commentableId
      state.commentableType = initialState.commentableType
    },
  },
})

export const {
  setupCommentableIdAndType,
  fetchComments,
  fetchCommentsFulfilled,
  fetchCommentsRejected,
  fetchCommentsCancelled,
  createComment,
  createCommentFulfilled,
  createCommentRejected,
  updateComment,
  updateCommentFulfilled,
  updateCommentRejected,
  deleteComment,
  deleteCommentFulfilled,
  deleteCommentRejected,
  resetComments,
} = commentSlice.actions

export const selectCommentableId = (state: TRootState) =>
  state.comment.commentableId
export const selectCommentableType = (state: TRootState) =>
  state.comment.commentableType
export const selectCommentLoading = (state: TRootState) =>
  state.comment.isLoading
export const selectCommentCreating = (state: TRootState) =>
  state.comment.creatingCommentTargetId
export const selectCommentUpdating = (state: TRootState) =>
  state.comment.updatingCommentTargetId
export const selectComments = (state: TRootState) => state.comment.comments
export const selectCommentsCount = (state: TRootState) => {
  let count = 0
  state.comment.comments.forEach((comment) => {
    count += comment.comments.length + 1
  })
  return count
}

export default commentSlice.reducer
