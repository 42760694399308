import { SEARCH_RESULT_ROUTE, SEARCH_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const OriginSearchResultPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  let target = searchParams.get(SearchParamKey.ORIGIN_SEARCH_TEXT) || ''
  if (target.indexOf('"') === 0) {
    target = target.slice(1)
  }
  if (target.indexOf('"') === target.length - 1) {
    target = target.slice(0, -1)
  }
  const searchResultRoute = `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${target}`

  useEffect(() => {
    navigate(searchResultRoute, { replace: true })
  }, [])

  return null
}
