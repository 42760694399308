import { Tooltip as MuiTooltip, TooltipProps, useTheme } from '@mui/material'

export const Tooltip = ({ title, children, sx = [] }: TooltipProps) => {
  const theme = useTheme()

  return (
    <MuiTooltip
      placement="bottom"
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.colors.BLACK_60,
            color: theme.colors.WHITE,
            maxWidth: '15rem',
            py: 1,
            px: 2,
            borderRadius: 0.5,
            ...theme.typography.textSmallImportant,
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, -14] },
          },
        ],
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      {children}
    </MuiTooltip>
  )
}
