import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { PAGE_TITLE_REPORT_PMR } from 'constants/pageTitle'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useFetchReportsCount } from 'hooks/useFetchReportsCount'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useVulnerabilityReportListContent } from 'hooks/useVulnerabilityReportListContent'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { selectIsMsspUser } from 'store/slices/account'
import {
  fetchPMReports,
  fetchPMReportsCancelled,
  selectIsFetchPMReportsLoading,
  selectPMReports,
  selectReportReadableTypes,
  selectVulnerabilityReportTypeCount,
} from 'store/slices/report'

import { PM_REPORT_TYPE } from '../constants/reportType'
import { FilterBar } from '../ReportComponents/FilterBar'
import { ReportListInfo } from '../ReportComponents/ReportListInfo'
import { PMRListLoading } from '../ReportComponents/ReportLoading'
import { PMReportPreview } from './PMReportComponents/PMReportPreview'

const SCROLLABLE_CONTAINER_ID = 'pmr-report-list-container'

interface IPMReportListProps {
  hasReportReadableTypesNoReport: boolean
}

export const PMReportList = ({
  hasReportReadableTypesNoReport,
}: IPMReportListProps) => {
  const theme = useTheme()
  const rangeOptions = useDateRangeOptions()
  const { dateRangeOptionToTimes } = useDateTime()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['report', 'component'])
  const PMReports = useAppSelector(selectPMReports)
  const isFetchPMReportsLoading = useAppSelector(selectIsFetchPMReportsLoading)
  const vulnerabilityReportTypeCount = useAppSelector(
    selectVulnerabilityReportTypeCount
  )
  const reportReadableTypes = useAppSelector(selectReportReadableTypes)
  const isMsspUser = useAppSelector(selectIsMsspUser)

  useFetchReportsCount()
  const vulnerabilityReportListContent = useVulnerabilityReportListContent()

  const filterOtherReports = vulnerabilityReportTypeCount
    ? vulnerabilityReportListContent.filter(
        ({ type }) =>
          type !== PM_REPORT_TYPE &&
          vulnerabilityReportTypeCount[type] !== 0 &&
          reportReadableTypes[type]
      )
    : []

  const displayNoReportsEmptyState = () => {
    let emptyState
    // 沒有 reports 時，有閱讀權限顯示 emptyState.noReports
    if (hasReportReadableTypesNoReport) {
      emptyState = t('emptyState.noReports', {
        ns: 'report',
        reports: t('pmReports.title', { ns: 'report' }),
      })
    } else if (isMsspUser) {
      emptyState = t('emptyState.msspNoAccess', {
        ns: 'report',
        reports: t('pmReports.title', { ns: 'report' }),
      })
    } else {
      emptyState = t('emptyState.noMatchFilter', { ns: 'component' })
    }

    return emptyState
  }

  const dateRange = dateRangeOptionToTimes({ startDate, endDate })[
    rangeOption as TRangeOption
  ]

  useEffect(() => {
    dispatch(fetchPMReports())

    return () => {
      dispatch(fetchPMReportsCancelled())
    }
  }, [])

  const filteredPMReports = useMemo(
    () =>
      PMReports.filter((report) => {
        if (dateRange.dateFrom && report.releaseDate < dateRange.dateFrom) {
          return false
        }
        if (dateRange.dateTo && report.releaseDate > dateRange.dateTo) {
          return false
        }
        return true
      }),
    [PMReports, startDate, endDate, rangeOption]
  )

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_PMR}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          color: theme.colors.WHITE,
        }}
      >
        <ReportListInfo
          type={PM_REPORT_TYPE}
          otherReportsInfo={filterOtherReports}
        />
        <Stack
          sx={{
            flexGrow: 1,
            flexBasis: 0,
            width: '100%',
            maxWidth: '85rem',
            minWidth: '47.5rem',
            [theme.breakpoints.up('xl')]: {
              minWidth: '85rem',
            },
          }}
        >
          <FilterBar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            rangeOption={rangeOption}
            setRangeOption={setRangeOption}
          />
          <Stack
            id={SCROLLABLE_CONTAINER_ID}
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              overflowY: 'auto',
              bgcolor: theme.colors.BLACK_90,
              height: '100%',
              py: 2,
            }}
          >
            {isFetchPMReportsLoading && PMReports.length === 0 ? (
              <PMRListLoading />
            ) : filteredPMReports.length > 0 ? (
              filteredPMReports.map((report) => (
                <PMReportPreview key={report.name} {...report} />
              ))
            ) : (
              <Box sx={{ p: 4 }}>
                <Typography
                  variant="titleSmall"
                  sx={{ color: theme.colors.WHITE_60 }}
                >
                  {displayNoReportsEmptyState()}
                </Typography>
              </Box>
            )}
            <ScrollToTop
              scrollableContainerId={SCROLLABLE_CONTAINER_ID}
              sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
