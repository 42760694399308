import { Stack, Typography, useTheme } from '@mui/material'
import { TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { DDW_MONITOR_KEY } from 'constants/ddw'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'

import { DDWRiskMonitoringLoading } from './DDWRiskMonitoringLoading'

export const DDWRiskMonitoring = ({
  otherServicesSubscriberGroups,
}: IAccountSettings) => {
  const { t } = useTranslation(['account'])
  const theme = useTheme()
  const { formatDuration } = useDateTime()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const DDWRiskMonitor = otherServicesSubscriberGroups?.find(
    (otherServicesSubscriberGroup) =>
      otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
  )

  return (
    <PageSection title={t('ddw.ddwRiskMonitoring', { ns: 'account' })}>
      {accountSettingsLoading ? (
        <DDWRiskMonitoringLoading />
      ) : (
        <Stack sx={{ gap: 1, px: 4, py: 2 }}>
          {!DDWRiskMonitor ? (
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('ddw.emptyState', { ns: 'account' })}
            </Typography>
          ) : (
            <TwoToneLabel
              keyText={t('ddw.ddwAlert', { ns: 'account' })}
              valueText={formatDuration(
                DDWRiskMonitor.joinedAt,
                DDWRiskMonitor.expiresAt
              )}
              mainColor={theme.colors.WIPER}
              textColor={theme.colors.BLACK_90}
            />
          )}
        </Stack>
      )}
    </PageSection>
  )
}
