import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

export const COUNTS_BOARD_LOADING_TEST_ID = 'counts-board-loading'

const LoadingBlock = () => (
  <Stack sx={{ gap: 2 }}>
    <Skeleton type="body" width="3rem" />
    <Skeleton type="body" width="3rem" />
  </Stack>
)

export const CountsBoardLoading = () => (
  <Box
    data-testid={COUNTS_BOARD_LOADING_TEST_ID}
    sx={{
      width: '100%',
      height: '3.25rem',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    }}
  >
    {generateSkeletons(6, <LoadingBlock />)}
  </Box>
)
