import { Button, Stack, Typography, useTheme } from '@mui/material'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { openMail } from 'utils/mail'

export const NO_DATA_WITH_CONTACT_CTA_TEST_ID = 'no-data-with-contact-cta'

interface INoDataWithContactCtaProps {
  text?: string
}

export const NoDataWithContactCta = ({
  text = '',
}: INoDataWithContactCtaProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['landingPage', 'component'])

  return (
    <Stack
      data-testid={NO_DATA_WITH_CONTACT_CTA_TEST_ID}
      sx={{ gap: 2, alignItems: 'center' }}
    >
      <Typography variant="titleBig" sx={{ color: theme.colors.WHITE_60 }}>
        {text || t('emptyState.title', { ns: 'landingPage' })}
      </Typography>
      <Image
        src={SmileTVImage}
        alt={t('emptyState.noRelatedResult', { ns: 'component' })}
        width={328}
        height={130}
      />
      <Button
        size="large"
        color="primary"
        variant="contained"
        sx={{ width: '15rem', mt: 4 }}
        onClick={openMail}
      >
        {t('emptyState.contactCta', { ns: 'landingPage' })}
      </Button>
    </Stack>
  )
}
