import { CSSProperties } from 'react'
import { createTheme } from '@mui/material/styles'
import colors, { IColors } from './colors'
import fixedConstants, { IFixedConstants } from './fixedConstants'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body: CSSProperties
    bodySmall: CSSProperties
    assistiveText: CSSProperties
    textSmallImportant: CSSProperties
    textLinkS: CSSProperties
    textLink: CSSProperties
    textLinkL: CSSProperties
    titleBig: CSSProperties
    title: CSSProperties
    titleSmall: CSSProperties
    buttonSText: CSSProperties
    buttonMText: CSSProperties
    buttonLText: CSSProperties
    reportH1: CSSProperties
    reportH2: CSSProperties
    reportH3: CSSProperties
    reportH4: CSSProperties
    reportH5: CSSProperties
    reportH6: CSSProperties
    reportInlineText: CSSProperties
    reportInlineTextSmall: CSSProperties
    reportInlineTextBold: CSSProperties
    reportInlineTextLink: CSSProperties
    reportInlineTextOblique: CSSProperties
    reportCaption: CSSProperties
    reportCodeBlock: CSSProperties
    reportInlineCode: CSSProperties
    ITMScore: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: CSSProperties
    bodySmall?: CSSProperties
    assistiveText?: CSSProperties
    textSmallImportant?: CSSProperties
    textLinkS?: CSSProperties
    textLink?: CSSProperties
    textLinkL?: CSSProperties
    titleBig?: CSSProperties
    title?: CSSProperties
    titleSmall?: CSSProperties
    buttonSText?: CSSProperties
    buttonMText?: CSSProperties
    buttonLText?: CSSProperties
    contentSmall?: CSSProperties
    reportH1?: CSSProperties
    reportH2?: CSSProperties
    reportH3?: CSSProperties
    reportH4?: CSSProperties
    reportH5?: CSSProperties
    reportH6?: CSSProperties
    reportInlineText?: CSSProperties
    reportInlineTextSmall?: CSSProperties
    reportInlineTextBold?: CSSProperties
    reportInlineTextLink?: CSSProperties
    reportInlineTextOblique?: CSSProperties
    reportCaption?: CSSProperties
    reportCodeBlock?: CSSProperties
    reportInlineCode?: CSSProperties
    ITMScore?: CSSProperties
  }

  interface BreakpointOverrides {
    xs: true // removes the `xs` breakpoint
    sm: true
    md: true
    lg: true
    desktop: true
    xl: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true
    bodySmall: true
    assistiveText: true
    textSmallImportant: true
    textLinkS: true
    textLink: true
    textLinkL: true
    titleBig: true
    title: true
    titleSmall: true
    buttonSText: true
    buttonMText: true
    buttonLText: true
    contentSmall: true
    reportH1: true
    reportH2: true
    reportH3: true
    reportH4: true
    reportH5: true
    reportH6: true
    reportInlineText: true
    reportInlineTextSmall: true
    reportInlineTextBold: true
    reportInlineTextLink: true
    reportInlineTextOblique: true
    reportCaption: true
    reportCodeBlock: true
    reportInlineCode: true
    ITMScore: true
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    button: false
    caption: false
    overline: false
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: IColors
    fixedConstants: IFixedConstants
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: IColors
    fixedConstants?: IFixedConstants
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textColor: true
    footer: true
  }
}

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      desktop: 1440,
      xl: 1920,
    },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  colors,
  fixedConstants,
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
    error: {
      main: colors.ERROR,
    },
    secondary: {
      main: colors.WHITE_60,
    },
  },
  typography: {
    body: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    bodySmall: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    assistiveText: {
      fontSize: '0.625rem',
      fontWeight: 400,
    },
    textSmallImportant: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '140%',
    },
    textLinkS: {
      fontSize: '0.625rem',
      fontWeight: 700,
      textDecorationLine: 'underline',
    },
    textLink: {
      fontSize: '0.75rem',
      fontWeight: 700,
      textDecorationLine: 'underline',
      lineHeight: '140%',
    },
    textLinkL: {
      fontSize: '0.875rem',
      fontWeight: 700,
      textDecorationLine: 'underline',
      lineHeight: '140%',
    },
    titleBig: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '150%',
    },
    title: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '150%',
    },
    titleSmall: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '140%',
    },
    buttonSText: {
      fontSize: '0.625rem',
      fontWeight: 700,
    },
    buttonMText: {
      fontSize: '0.75rem',
      fontWeight: 700,
    },
    buttonLText: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    contentSmall: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    reportH1: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportH2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportH3: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportH4: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportH5: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportH6: {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: '180%',
    },
    reportInlineText: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    reportInlineTextSmall: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    reportInlineTextBold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '140%',
    },
    reportInlineTextLink: {
      fontSize: '1rem',
      fontWeight: 400,
      textDecorationLine: 'underline',
      lineHeight: '140%',
    },
    reportInlineTextOblique: {
      fontSize: '1rem',
      fontWeight: 400,
      fontStyle: 'italic',
      lineHeight: '140%',
    },
    reportCaption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      fontStyle: 'italic',
      lineHeight: '130%',
    },
    reportCodeBlock: {
      fontFamily: 'PT Mono',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '180%',
    },
    reportInlineCode: {
      fontFamily: 'PT Mono',
      fontSize: '1rem',
      fontWeight: 400,
    },
    ITMScore: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: '150%',
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '-0.25rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              color: colors.WHITE_60,
              '&:hover': {
                backgroundColor: colors.PRIMARY_20,
              },
              '&:focus': {
                border: `2px solid ${colors.WHITE}`,
              },
              '&:active': {
                color: colors.WHITE,
                backgroundColor: colors.PRIMARY_60,
                border: 'none',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              color: colors.WHITE,
              backgroundColor: colors.PRIMARY_40,
              '&:hover': {
                backgroundColor: colors.PRIMARY_40,
                border: `1px solid ${colors.TEXT_LINK_NORMAL}`,
              },
              '&:focus': {
                backgroundColor: colors.PRIMARY_40,
                border: `1px solid ${colors.TEXT_LINK_NORMAL}`,
              },
              '&:active': {
                backgroundColor: colors.PRIMARY_60,
                border: 'none',
              },
            }),
        }),
      },
      variants: [
        {
          props: { variant: 'textColor' },
          style: {
            color: '#FFE17D', // TEXT_LINK_NORMAL
            '&:hover': {
              backgroundColor: 'transparent',
              textDecorationLine: 'underline',
              color: '#ffffff99', // WHITE_60
              path: {
                fill: '#ffffff99', // WHITE_60
              },
            },
            '&:active': {
              backgroundColor: 'transparent',
              textDecorationLine: 'underline',
              color: '#F8BE34', // PRIMARY
              path: {
                fill: '#F8BE34', // PRIMARY
              },
            },
          },
        },
        {
          props: { variant: 'footer' },
          style: {
            height: '0.875rem',
            color: '#696969', // BLACK_20
            '&:hover': {
              backgroundColor: 'transparent',
              textDecorationLine: 'underline',
              color: '#ffffff99', // WHITE_60
            },
            '&:active': {
              backgroundColor: 'transparent',
              textDecorationLine: 'underline',
              color: '#454545', // BLACK_60
            },
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        disableGutters: {
          padding: '0.25rem',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          font-family: 'Noto Sans';
          overflow: hidden;
          scrollbar-color: ${colors.BLACK_50};
          scrollbar-width: thin;
        }

        a {
          text-decoration: none;
        }

        .SnackbarItem-wrappedRoot .SnackbarContent-root.SnackbarItem-contentRoot {
          flex-wrap: nowrap;
        }

        /* width */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          padding: 4px 2px;
          background: ${colors.WHITE_40};
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${colors.BLACK_50};
          border-radius: 1px;
          border: 2px solid transparent;
          background-clip: content-box;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        /* corner */
        ::-webkit-scrollbar-corner {
          background: ${colors.WHITE_40};
        }
      `,
    },
  },
})
