import { Box, Link,SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as TocLinkIcon } from 'assets/basicIcons/tocLink.svg'

export const TocLink = ({ text, hash }: { text: string; hash: string }) => {
  const theme = useTheme()

  return (
    <Link
      id={hash}
      href={`#${hash}`}
      sx={{
        textDecoration: 'none',
        color: 'transparent',
        width: 'fit-content',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="reportH3" sx={{ color: theme.colors.WHITE }}>
          {text}
        </Typography>
        <SvgIcon component={TocLinkIcon} inheritViewBox />
      </Box>
    </Link>
  )
}
