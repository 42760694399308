import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import { Button } from 'components/Button/Button'
import { PageSection } from 'components/PageSection/PageSection'
import { Select, SelectItem } from 'components/Select/Select'
import { PAGE_TITLE_DDW_ALERT_DEMO } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import { useGoBack } from 'hooks/useGoBack'
import Image from 'mui-image'
import { ChangeEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { IDdwReportMeta } from 'store/types/entityTypes/ddw'

import { ExportReportDialogDemo } from './ExportReportDialogDemo'
import { KeywordCardListDemo } from './KeywordCardListDemo'

export const DDWAlertDemoPage = () => {
  useGoBack()
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  const { formatDuration } = useDateTime()
  const reportList = [
    {
      reportId: '82e0d626-7e24-4a05-81b3-631e28e640d9',
      contractId: '2f86e695-5108-4d1d-8e81-d18543dd4608',
      reportStartAt: 1718812800000.0,
      reportEndAt: 1719417599000.0,
      reportCount: 13,
    },
  ]
  const reportMetaList = [
    {
      keyword: 't******',
      clCount: 0,
      cdsCount: 4,
    },
    {
      keyword: 't******.com',
      clCount: 6,
      cdsCount: 3,
    },
  ]

  const reportMetaEachRowList: IDdwReportMeta[][] = []

  for (let i = 0; i < reportMetaList.length; i += 5) {
    const chunk = reportMetaList.slice(i, i + 5)
    reportMetaEachRowList.push(chunk)
  }

  const [selectedReportId, setSelectedReportId] = useState<string>('')
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const [openExportDialog, setOpenExportDialog] = useState(false)

  const handleReportIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReportId(event.target.value)
  }

  const handleKeywordChange = (keyword: string) => {
    setSelectedKeyword(keyword)
  }

  const handleExportDialogOpen = () => {
    setOpenExportDialog(true)
  }

  const handleExportDialogClose = () => {
    setOpenExportDialog(false)
  }

  useEffect(() => {
    if (reportMetaEachRowList.length > 0) {
      const findSelectedKeywordRowIndex = reportMetaEachRowList
        .map((list) => list.find(({ keyword }) => keyword === selectedKeyword))
        .findIndex((row) => row)

      const scrollableContainer = document.getElementById(
        String(findSelectedKeywordRowIndex)
      )

      if (scrollableContainer) {
        scrollableContainer.scrollIntoView()
      }
    }
  }, [selectedKeyword])

  useEffect(() => {
    if (reportList.length > 0) {
      setSelectedReportId(reportList[0].reportId)
    }
  }, [reportList.length])

  useEffect(() => {
    // 切換 report 時不選 keyword
    setSelectedKeyword('')
  }, [selectedReportId])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DDW_ALERT_DEMO}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Stack sx={{ p: 1, gap: 1, height: '100%' }}>
          <PageSection
            title={t('ddwAlertDemo.title', { ns: 'ddw' })}
            titleAction={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  color: theme.colors.WHITE,
                }}
              >
                <Typography
                  variant="textSmallImportant"
                  sx={{ lineHeight: '0.75rem' }}
                >
                  {t('ddwAlert.contractPeriod', { ns: 'ddw' })}
                </Typography>
                <Typography variant="body">
                  {t('ddwAlertDemo.period', { ns: 'ddw' })}
                </Typography>
                <Button
                  endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                  onClick={handleExportDialogOpen}
                >
                  {t('ddwAlert.exportCsv', { ns: 'ddw' })}
                </Button>
              </Box>
            }
            childrenOverflowStyle="hidden"
          >
            <Stack sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bgcolor: theme.colors.BLACK_95,
                  p: 2,
                }}
              >
                <Typography
                  variant="textSmallImportant"
                  sx={{ color: theme.colors.WHITE, flexShrink: 0 }}
                >
                  {t('ddwAlert.monitoringInterval', { ns: 'ddw' })}
                </Typography>
                <Select
                  value={selectedReportId}
                  onChange={handleReportIdChange}
                  sx={{ width: '15rem' }}
                >
                  {reportList.map((reportDurationCount) => (
                    <SelectItem
                      key={reportDurationCount.reportId}
                      value={reportDurationCount.reportId}
                    >
                      {formatDuration(
                        reportDurationCount.reportStartAt / 1000,
                        reportDurationCount.reportEndAt / 1000
                      )}
                      ({reportDurationCount.reportCount})
                    </SelectItem>
                  ))}
                </Select>
              </Box>
              <Stack sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                {reportMetaEachRowList.length > 0 ? (
                  reportMetaEachRowList.map((metaList, index) => (
                    <KeywordCardListDemo
                      id={String(index)}
                      key={metaList[0]?.keyword}
                      metaList={metaList}
                      selectedReportId={selectedReportId}
                      selectedKeyword={selectedKeyword}
                      handleKeywordChange={handleKeywordChange}
                    />
                  ))
                ) : (
                  <Stack sx={{ py: 12, gap: 2, alignItems: 'center' }}>
                    <Typography
                      variant="titleBig"
                      sx={{
                        color: theme.colors.WHITE_60,
                        textAlign: 'center',
                      }}
                    >
                      <Trans t={t} i18nKey="ddwAlert.emptyState.noResult">
                        You have successfully subscribed to Deep and Dark Web
                        Alert !<br /> The monitoring results will soon be
                        delivered.
                      </Trans>
                    </Typography>
                    <Image
                      src={SmileTVImage}
                      alt="You have successfully subscribed to Deep and Dark Web Alert ! The monitoring results will soon be delivered."
                      width={328}
                      height={130}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </PageSection>
        </Stack>
        <ExportReportDialogDemo
          open={openExportDialog}
          handleClose={handleExportDialogClose}
          reportList={reportList}
        />
      </Box>
    </>
  )
}
