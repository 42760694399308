import { combineReducers } from '@reduxjs/toolkit'

import accountReducer from './account'
import adversaryReducer from './adversary'
import authReducer from './auth'
import capabilityReducer from './capability'
import commentReducer from './comment'
import ddwReducer from './ddw'
import domainDetailReducer from './domain'
import downloadReducer from './download'
import ipDetailReducer from './ip'
import landingReducer from './landing'
import malwareReducer from './malware'
import purchaseReducer from './purchase'
import rawIntelReducer from './rawIntel'
import reportReducer from './report'
import sampleReducer from './sample'
import sampleDetailReducer from './sampleDetail'
import searchReducer from './search'
import snackbarReducer from './snackbar'
import tabContentReducer from './tabContent'
import threatLandscapeReducer from './threatLandscape'

const reducer = combineReducers({
  auth: authReducer,
  landing: landingReducer,
  threatLandscape: threatLandscapeReducer,
  search: searchReducer,
  malware: malwareReducer,
  comment: commentReducer,
  sample: sampleReducer,
  sampleDetail: sampleDetailReducer,
  tabContent: tabContentReducer,
  account: accountReducer,
  snackbar: snackbarReducer,
  report: reportReducer,
  download: downloadReducer,
  adversary: adversaryReducer,
  ipDetail: ipDetailReducer,
  domainDetail: domainDetailReducer,
  purchase: purchaseReducer,
  capability: capabilityReducer,
  rawIntel: rawIntelReducer,
  ddw: ddwReducer,
})

export default reducer
