import { OutOfAAPDialog } from 'components/Errors/OutOfAAPDialog'
import { useState } from 'react'

interface IUseOutOfAAPDialogProps {
  needGoBack: boolean
}

// AAP 不足的情況下，後端會回 402 status code，可在 component 將此 Dialog 放入 TSX，handler 放入對應的 callback function
export const useOutOfAAPDialog = ({ needGoBack }: IUseOutOfAAPDialogProps) => {
  const [outOfAAPDialogOpen, setOutOfAAPDialogOpen] = useState<boolean>(false)

  const handleOutOfAAPDialogOpen = () => {
    setOutOfAAPDialogOpen(true)
  }

  const handleOutOfAAPDialogClose = () => {
    setOutOfAAPDialogOpen(false)
  }

  const renderOutAAPDialog = (
    <OutOfAAPDialog
      open={outOfAAPDialogOpen}
      handleClose={handleOutOfAAPDialogClose}
      needGoBack={needGoBack}
    />
  )

  return {
    renderOutAAPDialog,
    outOfAAPDialogOpen,
    handleOutOfAAPDialogOpen,
    handleOutOfAAPDialogClose,
  }
}
