import { Box, SxProps, useTheme } from '@mui/material'
import { ReactNode } from 'react'

export const UnorderedList = ({
  children,
  typography,
}: {
  children: NonNullable<ReactNode>
  typography?: SxProps
}) => {
  const theme = useTheme()
  const typographyStyles = typography || theme.typography.reportInlineText

  return (
    <Box
      component="ul"
      sx={{
        listStyle: 'none',
        margin: 0,
        paddingInlineStart: '1.625rem',
        ...typographyStyles,
      }}
    >
      {children}
    </Box>
  )
}

export const ListItem = ({
  children,
}: {
  children: NonNullable<ReactNode>
}) => {
  const theme = useTheme()

  return (
    <Box
      component="li"
      sx={{
        '&::before': {
          content: '"\\2022"',
          color: theme.colors.PRIMARY,
          display: 'inline-block',
          width: '1rem',
          marginLeft: '-1rem',
        },
        color: theme.colors.WHITE,
      }}
    >
      {children}
    </Box>
  )
}

export const OrderedList = ({
  children,
  typography,
}: {
  children: NonNullable<ReactNode>
  typography?: SxProps
}) => {
  const theme = useTheme()
  const typographyStyles = typography || theme.typography.reportInlineText

  return (
    <Box
      component="ol"
      sx={{
        listStyle: 'none',
        counterReset: 'index',
        margin: 0,
        paddingInlineStart: 0,
        color: theme.colors.PRIMARY,
        ...typographyStyles,
      }}
    >
      {children}
    </Box>
  )
}

export const NumberListItem = ({
  children,
  tensDigits = false,
}: {
  children: NonNullable<ReactNode>
  tensDigits?: boolean
}) => {
  const theme = useTheme()

  // normal: 1, tensDigits: 10
  return (
    <Box
      component="li"
      sx={{
        '&::before': {
          counterIncrement: 'index',
          content: 'counters(index, ".", decimal)',
          color: theme.colors.PRIMARY,
          display: 'inline-block',
          width: tensDigits ? '1.5rem' : '1rem',
          marginLeft: tensDigits ? '-1.5rem' : '-1rem',
        },
        paddingInlineStart: tensDigits ? '1.5rem' : '1rem',
        color: theme.colors.WHITE,
      }}
    >
      {children}
    </Box>
  )
}

const subNumberListNumberWidthMap: Record<number, string> = {
  1: '2rem', // 1.1
  2: '2.5rem', // 10.1
  3: '2.75rem', // 1.1.1
}

export const SubNumberListItem = ({
  children,
  level = 1,
}: {
  children: NonNullable<ReactNode>
  level?: number
}) => {
  const theme = useTheme()

  return (
    <Box
      component="li"
      sx={{
        '&::before': {
          counterIncrement: 'index',
          content: 'counters(index, ".", decimal)',
          color: theme.colors.PRIMARY,
          display: 'inline-block',
          width: subNumberListNumberWidthMap[level],
          marginLeft: `-${subNumberListNumberWidthMap[level]}`,
        },
        paddingInlineStart: subNumberListNumberWidthMap[level],
        color: theme.colors.WHITE,
      }}
    >
      {children}
    </Box>
  )
}
