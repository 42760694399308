import {
  Box,
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  SxProps,
  useTheme,
} from '@mui/material'
import { ElementType } from 'react'
import { Link } from 'react-router-dom'

const getCircularProgressSizeByButtonSize = (
  buttonSize: 'large' | 'medium' | 'small'
): string => {
  if (buttonSize === 'small') {
    return '0.75rem'
  }
  if (buttonSize === 'medium') {
    return '1rem'
  }
  return '1.25rem'
}

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  component?: ElementType
}

/**
 * @description 避免連點 button 觸發非同步事件，可在尚未完成時將 loading 設為 true，啟動讀取狀態，待非同步事件完成後再解除
 * @param loading 依據 boolean 來決定是否呈現讀取狀態
 * @param props 其他比照 Mui Button 的 Props
 */
export const Button = ({
  size = 'medium',
  loading = false,
  variant = 'outlined',
  children,
  sx = [],
  ...props
}: LoadingButtonProps) => {
  const theme = useTheme()

  return (
    <MuiButton
      disableRipple
      variant={variant}
      size={size}
      sx={[
        {
          textTransform: 'none',
          borderRadius: 0.5,
          px: 2,
          minWidth: 'auto',
          whiteSpace: 'nowrap',
          '&.MuiButton-outlined': {
            color: theme.colors.WHITE_60,
            borderColor: theme.colors.WHITE_60,
            '&:hover': {
              bgcolor: theme.colors.PRIMARY_20,
            },
            '&:focus': {
              bgcolor: theme.colors.PRIMARY_20,
              border: `2px solid ${theme.colors.WHITE}`,
            },
            '&:active': {
              color: theme.colors.WHITE,
              bgcolor: theme.colors.PRIMARY_60,
              borderColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.colors.DISABLE,
              borderColor: theme.colors.DISABLE,
            },
          },
          '&.MuiButton-contained': {
            color: theme.colors.WHITE,
            bgcolor: theme.colors.PRIMARY_40,
            border: '1px solid transparent',
            '&:hover': {
              borderColor: theme.colors.TEXT_LINK_NORMAL,
            },
            '&:focus': {
              border: `2px solid ${theme.colors.TEXT_LINK_NORMAL}`,
            },
            '&:active': {
              bgcolor: theme.colors.PRIMARY_60,
              borderColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.colors.DISABLE,
              bgcolor: 'transparent',
              borderColor: theme.colors.DISABLE,
            },
          },
          '&.MuiButton-text': {
            color: theme.colors.WHITE_60,
            border: '1px solid transparent',
            '&:hover': {
              bgcolor: theme.colors.WHITE_20,
            },
            '&:focus': {
              bgcolor: theme.colors.WHITE_20,
              border: `2px solid ${theme.colors.WHITE}`,
            },
            '&:active': {
              bgcolor: theme.colors.WHITE_40,
              borderColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.colors.DISABLE,
              bgcolor: 'transparent',
              borderColor: 'transparent',
            },
          },
          '&.MuiButton-textColor': {
            '&:focus': {
              border: 'none',
            },
          },
          '&.MuiButton-footer': {
            '&:focus': {
              border: 'none',
            },
          },
          '&.MuiButton-sizeSmall': {
            height: '1rem',
            lineHeight: '1rem',
            ...theme.typography.buttonSText,
            '& .MuiButton-endIcon .MuiSvgIcon-root': {
              height: '0.75rem',
              width: '0.75rem',
            },
          },
          '&.MuiButton-sizeMedium': {
            height: '1.5rem',
            lineHeight: '1.5rem',
            ...theme.typography.buttonMText,
            '& .MuiButton-endIcon .MuiSvgIcon-root': {
              height: '1rem',
              width: '1rem',
            },
          },
          '&.MuiButton-sizeLarge': {
            height: '2rem',
            lineHeight: '2rem',
            px: 4,
            ...theme.typography.buttonLText,
            '& .MuiButton-endIcon .MuiSvgIcon-root': {
              height: '1.5rem',
              width: '1.5rem',
            },
          },
          '& .MuiButton-endIcon': {
            ml: 0.125,
          },
          '&.Mui-disabled': {
            color: theme.colors.DISABLE,
            border: `1px solid ${theme.colors.DISABLE}`,
            path: {
              fill: theme.colors.DISABLE,
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {loading ? (
        <CircularProgress
          color="primary"
          size={getCircularProgressSizeByButtonSize(size)}
        />
      ) : (
        children
      )}
    </MuiButton>
  )
}

interface ILinkButtonProps extends ButtonProps {
  to: string
  loading?: boolean
  linkSx?: SxProps
}

export const LinkButton = ({
  to,
  size = 'medium',
  loading = false,
  variant = 'outlined',
  children,
  sx = [],
  linkSx = [],
  ...props
}: ILinkButtonProps) => (
  <Box sx={linkSx} to={to} component={props.disabled ? Box : Link}>
    <Button variant={variant} size={size} sx={sx} loading={loading} {...props}>
      {children}
    </Button>
  </Box>
)
