import { MalwareTabContent } from 'components/Malware/MalwareTabContent'
import { ReportTabContent } from 'components/Report/ReportTabContent'
import { SampleTabContent } from 'components/Sample/SampleTabContent'
import { TechniqueTabContent } from 'components/Technique/TechniqueTabContent'
import { UserCommentTabContent } from 'components/UserComments/UserCommentTabContent'
import { useAppSelector } from 'hooks/useReduxHooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectCommentLoading, selectCommentsCount } from 'store/slices/comment'
import {
  selectTabMalwareCount,
  selectTabMalwareCountLoading,
  selectTabReportCount,
  selectTabReportCountLoading,
  selectTabSampleCount,
  selectTabSampleCountLoading,
  selectTabTechniqueCount,
  selectTabTechniqueCountLoading,
} from 'store/slices/tabContent'

export interface IAdversaryCategoryTab {
  key: string
  name: string
  component: FC<{ searchText: string }>
  count: number
  hiddenEmpty: boolean
  isLoading?: boolean
  appendPlusSign?: boolean
}

export const useAdversaryCategoryTabs = (): IAdversaryCategoryTab[] => {
  const { t } = useTranslation(['adversary', 'component'])
  const tabReportCountLoading = useAppSelector(selectTabReportCountLoading)
  const tabReportCount = useAppSelector(selectTabReportCount)

  const tabMalwareCountLoading = useAppSelector(selectTabMalwareCountLoading)
  const tabMalwareCount = useAppSelector(selectTabMalwareCount)

  const tabSampleCountLoading = useAppSelector(selectTabSampleCountLoading)
  const tabSampleCount = useAppSelector(selectTabSampleCount)

  const tabTechniqueCountLoading = useAppSelector(
    selectTabTechniqueCountLoading
  )
  const tabTechniqueCount = useAppSelector(selectTabTechniqueCount)

  const tabCommentCount = useAppSelector(selectCommentsCount)
  const isCommentLoading = useAppSelector(selectCommentLoading)

  const tabs: IAdversaryCategoryTab[] = [
    {
      key: 'reports',
      name: t('categoryTabs.reports', { ns: 'component' }),
      component: ReportTabContent,
      count: tabReportCount,
      hiddenEmpty: true,
      isLoading: tabReportCountLoading,
    },
    {
      key: 'malware',
      name: t('categoryTabs.malware', { ns: 'component' }),
      component: MalwareTabContent,
      count: tabMalwareCount,
      hiddenEmpty: true,
      isLoading: tabMalwareCountLoading,
    },
    {
      key: 'samples',
      name: t('categoryTabs.samples', { ns: 'component' }),
      component: SampleTabContent,
      count: tabSampleCount,
      hiddenEmpty: true,
      isLoading: tabSampleCountLoading,
      appendPlusSign: tabSampleCount >= 1000,
    },
    {
      key: 'techniques',
      name: t('categoryTabs.capabilities', { ns: 'component' }),
      component: TechniqueTabContent,
      count: tabTechniqueCount,
      hiddenEmpty: false,
      isLoading: tabTechniqueCountLoading,
    },
    {
      key: 'comments',
      name: t('categoryTabs.comments', { ns: 'component' }),
      component: UserCommentTabContent,
      count: tabCommentCount,
      hiddenEmpty: false,
      isLoading: isCommentLoading,
    },
  ]
  return tabs.filter(
    (tab) => !(tab.hiddenEmpty && !tab.isLoading && tab.count === 0)
  )
}
