import { Box, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import {
  APT_ROUTE,
  CYBER_AFFAIRS_CATEGORY_ROUTE,
  NOT_FOUND_ROUTE,
  PREFACE_INTRODUCTION_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORT_INTRODUCTION_ROUTE,
  REPORT_REDIRECT_ALIAS_SUB_ROUTE,
  REPORTS_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  checkIsMssp,
  checkIsMsspCancelled,
  selectIsCheckIsMsspLoading,
  selectIsMsspUser,
} from 'store/slices/account'
import {
  fetchReportReadableTypes,
  fetchReportReadableTypesCancelled,
  selectIsFetchReportReadableTypesLoading,
  selectReportReadableCategories,
} from 'store/slices/report'
import {
  reportCategoryRouteMap,
  reportRouteCategoryMap,
  TReportCategory,
} from 'store/types/entityTypes/report'

import { APTReports } from './APTReports/APTReports'
import { CyberAffairs } from './CyberAffairs/CyberAffairs'
import { ReportCategoryTabs } from './ReportComponents/ReportCategoryTabs'
import { Vulnerability } from './Vulnerability/Vulnerability'

export const ReportsPage = () => {
  const theme = useTheme()
  const { reportCategory: reportCategoryParam } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isMsspUser = useAppSelector(selectIsMsspUser)
  const reportReadableCategories = useAppSelector(
    selectReportReadableCategories
  )
  const isCheckIsMsspLoading = useAppSelector(selectIsCheckIsMsspLoading)
  const isFetchReportReadableTypesLoading = useAppSelector(
    selectIsFetchReportReadableTypesLoading
  )

  useEffect(() => {
    // 有些 report 內容會有其他報告的連結，會是 'reports/{alias} 的樣式，後端表示都會有 _report，因此用其來判斷是否導頁至 report detail'
    if (
      reportCategoryParam &&
      reportCategoryParam.includes(REPORT_REDIRECT_ALIAS_SUB_ROUTE)
    ) {
      navigate(
        `/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${reportCategoryParam}`
      )
    } else if (!isCheckIsMsspLoading && !isFetchReportReadableTypesLoading) {
      // 有閱讀權限的報告或是他是 MSSP
      if (
        Object.values(reportReadableCategories).some((readable) => readable) ||
        isMsspUser
      ) {
        // End-User 在 /reports 頁會自動導到第一個有閱讀權限的 /reports/{reportCategory}
        // MSSP 在 /reports 頁會自動導到第一個 /reports/apt
        if (!reportCategoryParam) {
          const firstReadableCategory: TReportCategory | undefined = isMsspUser
            ? 'APT'
            : (
                Object.keys(reportReadableCategories) as Array<TReportCategory>
              ).find((type) => reportReadableCategories[type])

          if (firstReadableCategory) {
            navigate(`${reportCategoryRouteMap[firstReadableCategory]}`, {
              replace: true,
            })
          }
        }

        // End-User 網址輸入的 reportCategoryParam 沒有在閱讀權限裡
        if (
          reportCategoryParam &&
          !isMsspUser &&
          !reportReadableCategories[reportRouteCategoryMap[reportCategoryParam]]
        ) {
          navigate(`/${NOT_FOUND_ROUTE}`)
        }

        // MSSP 網址輸入的 reportCategoryParam 沒有在 reportRouteCategory 範圍裡
        if (
          reportCategoryParam &&
          isMsspUser &&
          !reportRouteCategoryMap[reportCategoryParam]
        ) {
          navigate(`/${NOT_FOUND_ROUTE}`)
        }
      } else {
        // 沒有閱讀權限的報告
        navigate(`/${NOT_FOUND_ROUTE}`)
      }
    }
  }, [
    reportCategoryParam,
    isMsspUser,
    isCheckIsMsspLoading,
    isFetchReportReadableTypesLoading,
    reportReadableCategories,
  ])

  useEffect(() => {
    dispatch(checkIsMssp())
    dispatch(fetchReportReadableTypes())

    return () => {
      dispatch(checkIsMsspCancelled())
      dispatch(fetchReportReadableTypesCancelled())
    }
  }, [])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
      }}
    >
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: `2px solid ${theme.colors.WHITE_20}`,
            }}
          >
            {reportCategoryParam && (
              <ReportCategoryTabs reportCategoryParam={reportCategoryParam} />
            )}
            <LinkButton
              to={`/${REPORT_INTRODUCTION_ROUTE}/${PREFACE_INTRODUCTION_ROUTE}`}
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
            >
              Report Introduction
            </LinkButton>
            {/* TODO: hide in May,2023 TV2.0 beta version */}
            {/* {reportCategoryParam === APT_ROUTE && (
          <LinkButton
            to={/${REPORTS_ROUTE}/${APT_ROUTE}/${REPORT_METRICS_ROUTE}`}
            size="small"
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            Check Report Metrics
          </LinkButton>
        )} */}
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, px: 1, pb: 1 }}>
          {reportCategoryParam === APT_ROUTE && <APTReports />}
          {reportCategoryParam === CYBER_AFFAIRS_CATEGORY_ROUTE && (
            <CyberAffairs />
          )}
          {reportCategoryParam === VULNERABILITY_ROUTE && <Vulnerability />}
        </Box>
      </Stack>
    </Box>
  )
}
