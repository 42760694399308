import { Divider, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { IPMReportDetail } from 'store/types/entityTypes/report'

interface IPatchDrawerProps {
  selectedCVE: string
  pmReportDetail: IPMReportDetail[]
  patchDrawerOpen: boolean
  onClose: () => void
}

export const PatchDrawer = ({
  selectedCVE,
  pmReportDetail,
  patchDrawerOpen,
  onClose,
}: IPatchDrawerProps) => {
  const theme = useTheme()
  const selectedPMReportDetail = pmReportDetail.find(
    ({ id }) => id === selectedCVE
  )

  return (
    <Drawer
      open={patchDrawerOpen}
      onClose={onClose}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: theme.colors.MASK,
        },
      }}
    >
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {selectedCVE}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Typography
        variant="reportInlineText"
        component="p"
        sx={{ color: theme.colors.WHITE, pt: 2 }}
      >
        {selectedPMReportDetail?.patch}
      </Typography>
    </Drawer>
  )
}
