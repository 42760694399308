import {
  FormControl,
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps,
  RadioProps,
  useTheme,
} from '@mui/material'

export const Radio = ({ sx = [], ...props }: RadioProps) => {
  const theme = useTheme()
  const { checked } = props

  return (
    <MuiRadio
      {...props}
      sx={[
        {
          width: '1.5rem',
          height: '1.5rem',
          color: theme.colors.WHITE_60,
          // 已選 radio 外層圓圈 WHITE_60，內層圓圈 PRIMARY_60
          '&.Mui-checked svg:first-of-type': {
            color: theme.colors.WHITE_60,
          },
          '&.Mui-checked svg:last-of-type': {
            color: theme.colors.PRIMARY_60,
          },
          '&.Mui-disabled svg:first-of-type': {
            color: theme.colors.DISABLE,
          },
          '&.Mui-disabled svg:last-of-type': {
            color: theme.colors.DISABLE,
          },
          '&:hover, &:focus': {
            bgcolor: checked ? theme.colors.PRIMARY_20 : theme.colors.WHITE_20,
            'svg:first-of-type': {
              color: theme.colors.WHITE,
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}

export interface IRadioOption {
  value: string | null
  label: string | null
  disabled?: boolean
}

interface IRadioGroupProps extends RadioGroupProps {
  options: IRadioOption[]
}

export const RadioGroup = ({
  sx = [],
  options,
  ...props
}: IRadioGroupProps) => {
  const theme = useTheme()

  return (
    <FormControl>
      <MuiRadioGroup sx={sx} {...props}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value || ''}
            control={
              <Radio
                checked={props.value === option.value}
                disabled={option.disabled}
              />
            }
            label={option.label}
            sx={{
              textTransform: 'capitalize',
              '.MuiTypography-root.MuiFormControlLabel-label': {
                color: theme.colors.WHITE,
                ...theme.typography.body,
              },
            }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}
