import { useTranslation } from 'react-i18next'
import { TVulnerabilityReportType } from 'store/types/entityTypes/report'

interface IReportListContent<T> {
  info: string
  cta: string
  type: T
}

export const useVulnerabilityReportListContent = () => {
  const { t } = useTranslation(['report'])

  const vulnerabilityReportListContent: IReportListContent<TVulnerabilityReportType>[] =
    [
      {
        info: t('howAbout.viReports', { ns: 'report' }),
        cta: t('howAbout.viReportsMoreCta', { ns: 'report' }),
        type: 'VIRReport',
      },
      {
        info: t('howAbout.pmReports', { ns: 'report' }),
        cta: t('howAbout.pmReportsMoreCta', { ns: 'report' }),
        type: 'PMReport',
      },
    ]

  return vulnerabilityReportListContent
}
