import { Box, Typography, useTheme } from '@mui/material'
import { DateRangePicker } from 'components/DatePicker/DatePicker'
import { Select, SelectItem } from 'components/Select/Select'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

interface IDateRangeSelectorPickerProps {
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  rangeOption: string
  setRangeOption: (option: string) => void
  width?: string
}

export const DateRangeSelectorPicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  rangeOption,
  setRangeOption,
  width = '13.125rem',
}: IDateRangeSelectorPickerProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()
  const rangeOptions = useDateRangeOptions()
  const { rangeToDate } = useDateTime()

  const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const range = event.target.value as TRangeOption
    setRangeOption(event.target.value)
    if (rangeToDate({ startDate, endDate })[range]) {
      setStartDate(rangeToDate({ startDate, endDate })[range].dateFrom)
      setEndDate(rangeToDate({ startDate, endDate })[range].dateTo)
    }
  }

  const showCustomDatePicker = rangeOption === 'custom'

  const handleCustomDateChange = ([newStartDate, newEndDate]: [
    newStartDate: Date | null,
    newEndDate: Date | null
  ]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography
        variant="textSmallImportant"
        sx={{ color: theme.colors.WHITE }}
      >
        {t('timeSelector.label', { ns: 'component' })}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Select value={rangeOption} onChange={handleRangeChange} width={width}>
          {rangeOptions.map((option, index) => (
            <SelectItem
              key={option.value}
              value={option.value}
              sx={{
                borderBottom:
                  index === 0 ? `1px solid ${theme.colors.WHITE_20}` : 'none',
              }}
            >
              {option.text}
            </SelectItem>
          ))}
        </Select>
        {showCustomDatePicker && (
          <DateRangePicker
            selected={startDate}
            onChange={handleCustomDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </Box>
    </Box>
  )
}
