import { Box, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { ReportLoading } from 'components/Loading/ReportLoading'
import { NoData } from 'components/NoData/NoData'
import { ReportPreview } from 'components/Report/ReportPreview'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import {
  fetchTabReports,
  fetchTabReportsCancelled,
  resetTabReportContent,
  selectHasMoreTabReports,
  selectTabReportDataLoading,
  selectTabReports,
} from 'store/slices/tabContent'

const SCROLLABLE_CONTAINER_ID = 'tab-reports-scrollable-container'

interface IReportTabContentProps {
  searchText: string
}

export const ReportTabContent = ({ searchText }: IReportTabContentProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const tabReports = useAppSelector(selectTabReports)
  const isTabReportLoading = useAppSelector(selectTabReportDataLoading)
  const hasMoreTabReports = useAppSelector(selectHasMoreTabReports)

  const [isScrollable, ref, node] = useIsScrollable([tabReports])

  const handleFetchTabReports = (refetch?: boolean) =>
    dispatch(
      fetchTabReports({ searchText, offset: refetch ? 0 : tabReports.length })
    )

  // fetch initial tab reports
  useEffect(() => {
    handleFetchTabReports(true)
    return () => {
      dispatch(fetchTabReportsCancelled())
      dispatch(resetTabReportContent())
    }
  }, [searchText])

  // fetch more data if container is un-scrollable
  useEffect(() => {
    if (node && !isScrollable && hasMoreTabReports) {
      handleFetchTabReports()
    }
  }, [isScrollable, hasMoreTabReports, node, tabReports.length])

  return (
    <Box
      ref={ref}
      id={SCROLLABLE_CONTAINER_ID}
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
    >
      {tabReports.length === 0 ? (
        isTabReportLoading ? (
          <ReportLoading />
        ) : (
          <NoData />
        )
      ) : (
        <InfiniteScrollWrapper
          dataLength={tabReports.length}
          next={handleFetchTabReports}
          hasMore={hasMoreTabReports}
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          scrollThreshold="0px"
        >
          {tabReports.map((report) => (
            <Box
              key={report.id}
              sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
            >
              <ReportPreview {...report} />
            </Box>
          ))}
        </InfiniteScrollWrapper>
      )}
      <ScrollToTop
        scrollableContainerId={SCROLLABLE_CONTAINER_ID}
        sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
      />
    </Box>
  )
}
