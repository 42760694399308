import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from '@mui/material'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { Table } from 'components/Table/Table'
import { TableBodyRow } from 'components/Table/TableBodyRow'
import { TableHeaderRow } from 'components/Table/TableHeaderRow'
import { getPageTitleTacticDetailByTitle } from 'constants/pageTitle'
import {
  CAPABILITY_ROUTE,
  NOT_FOUND_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchTactic,
  fetchTacticCancelled,
  fetchTactics,
  fetchTacticsCancelled,
  resetTactic,
  selectTactic,
  selectTacticLoading,
  selectTactics,
  selectTacticsLoading,
} from 'store/slices/capability'

import { MarkDownHtml } from './CapabilityComponents/MarkDownHtml'
import { TacticLoading } from './TacticLoading'

const SCROLLABLE_CONTAINER_ID = 'tactic-scrollable-container'

interface ITacticAttribute {
  title: string
  content: string
}

const TacticAttribute = ({ title, content }: ITacticAttribute) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <HighlightLabel text={title} />
    <Typography sx={{ lineHeight: '1rem' }} variant="textSmallImportant">
      {content}
    </Typography>
  </Box>
)

export const TacticPage = () => {
  const { t } = useTranslation(['capability'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const tactic = useAppSelector(selectTactic)
  const tacticLoading = useAppSelector(selectTacticLoading)
  const tactics = useAppSelector(selectTactics)
  const tacticsLoading = useAppSelector(selectTacticsLoading)
  const navigate = useNavigate()
  useGoBack()

  const tacticId = searchParams.get(SearchParamKey.CAPABILITY_TACTIC_ID)

  const headCells = [
    'ID',
    t('tactic.techniquesTable.headCell.name', {
      ns: 'capability',
    }),
    t('tactic.techniquesTable.headCell.description', {
      ns: 'capability',
    }),
  ]

  useEffect(() => {
    if (tactics.length === 0) {
      dispatch(fetchTactics())
    }
    if (tacticId) {
      dispatch(fetchTactic(tacticId))
    }

    return () => {
      dispatch(fetchTacticsCancelled())
      dispatch(fetchTacticCancelled())
      dispatch(resetTactic())
    }
  }, [tacticId])

  const flattenTechniques = tactic.techniques.reduce(
    (accumulator, current) => [
      ...accumulator,
      current,
      ...current.subTechniques.map((tech) => ({
        ...tech,
        parentTechnique: current,
      })),
    ],
    []
  )

  if (!tacticId) {
    navigate(`/${NOT_FOUND_ROUTE}`, { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitleTacticDetailByTitle(tactic.name)}</title>
      </Helmet>
      <Box
        sx={{
          p: 1,
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            borderRadius: 0.5,
            border: `1px solid ${theme.colors.WHITE_20}`,
          }}
        >
          <Box
            sx={{
              width: '17.5rem',
              flexShrink: 0,
              borderRight: `1px solid ${theme.colors.WHITE_20}`,
              height: '100%',
              overflow: 'auto',
              bgcolor: theme.colors.BLACK_90,
            }}
          >
            {!tacticsLoading && (
              <Stack
                sx={{
                  width: '100%',
                  color: theme.colors.WHITE,
                }}
              >
                <Typography sx={{ px: 4, py: 1 }} variant="title">
                  {t('tactic.title', { ns: 'capability' })}
                </Typography>
                {tactics.map((tacticOption, index) => (
                  <Typography
                    sx={{
                      color: theme.colors.WHITE,
                      cursor: 'pointer',
                      px: 4,
                      py: 1.5,
                      borderTop: `1px solid ${theme.colors.WHITE_20}`,
                      borderBottom:
                        index === tactics.length - 1
                          ? `1px solid ${theme.colors.WHITE_20}`
                          : 'none',
                      '&:hover': {
                        bgcolor:
                          tacticOption.id === tacticId
                            ? theme.colors.PRIMARY_40
                            : theme.colors.WHITE_20,
                      },
                      bgcolor:
                        tacticOption.id === tacticId
                          ? theme.colors.PRIMARY_60
                          : 'inherit',
                    }}
                    key={tacticOption.id}
                    variant="titleSmall"
                    to={`?${SearchParamKey.CAPABILITY_TACTIC_ID}=${tacticOption.id}`}
                    component={Link}
                  >
                    {tacticOption.name}
                  </Typography>
                ))}
              </Stack>
            )}
          </Box>
          <Box
            sx={{
              flexBasis: 0,
              flexGrow: 1,
              height: '100%',
              overflow: 'auto',
            }}
          >
            {tacticLoading ? (
              <Box sx={{ width: '100%', height: '100%' }}>
                <TacticLoading />
              </Box>
            ) : (
              <Stack sx={{ pl: 4, pt: 4, width: '100%', height: '100%' }}>
                <Box
                  sx={{
                    flexShrink: 0,
                    borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                    mr: 4,
                  }}
                >
                  <Typography color={theme.colors.WHITE} variant="reportH2">
                    {tactic.name}
                  </Typography>
                </Box>
                <Box
                  id={SCROLLABLE_CONTAINER_ID}
                  sx={{
                    pt: 2,
                    flexBasis: 0,
                    flexGrow: 1,
                    overflow: 'auto',
                    pr: 4,
                  }}
                >
                  <Stack sx={{ color: theme.colors.WHITE, mb: 4 }}>
                    <Stack sx={{ gap: 1 }}>
                      <TacticAttribute
                        title={t('tactic.label.id', { ns: 'capability' })}
                        content={tactic.id}
                      />
                      <TacticAttribute
                        title={t('tactic.label.created', { ns: 'capability' })}
                        content={
                          tactic.publishedAt
                            ? getDateStringByTimestampsInSeconds(
                                tactic.publishedAt
                              )
                            : '-'
                        }
                      />
                      <TacticAttribute
                        title={t('tactic.label.lastModified', {
                          ns: 'capability',
                        })}
                        content={
                          tactic.lastModified
                            ? getDateStringByTimestampsInSeconds(
                                tactic.lastModified
                              )
                            : '-'
                        }
                      />
                      <Stack>
                        <HighlightLabel
                          text={t('tactic.label.description', {
                            ns: 'capability',
                          })}
                        />
                        <Typography variant="body" sx={{ pl: 2, pt: 1 }}>
                          {tactic.description}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography color={theme.colors.WHITE} variant="reportH2">
                      {t('tactic.techniquesTable.title', {
                        ns: 'capability',
                      })}
                    </Typography>
                    <Box>
                      <TableContainer sx={{ overflow: 'initial' }}>
                        <Table>
                          <TableHead>
                            <TableHeaderRow>
                              {headCells.map((headCell) => (
                                <TableCell key={headCell}>{headCell}</TableCell>
                              ))}
                            </TableHeaderRow>
                          </TableHead>
                          <TableBody>
                            {flattenTechniques.map((technique, index) => (
                              <TableBodyRow key={technique.id} index={index}>
                                <TableCell sx={{ width: '4.375rem' }}>
                                  <TextLink
                                    to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${technique.id}`}
                                  >
                                    {technique.id}
                                  </TextLink>
                                </TableCell>
                                <TableCell>
                                  <TextLink
                                    to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${technique.id}`}
                                  >
                                    {technique.name}
                                  </TextLink>
                                </TableCell>
                                <TableCell>
                                  <MarkDownHtml
                                    markdown={technique.description}
                                    sx={{ ...theme.typography.bodySmall }}
                                  />
                                </TableCell>
                              </TableBodyRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Stack>
                  <ScrollToTop
                    scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                    sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                  />
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
