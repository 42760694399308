import { SearchParamKey } from 'constants/searchParamKeys'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSearchAdversariesCount,
  fetchSearchAdversariesCountCancelled,
  fetchSearchDomainsCount,
  fetchSearchDomainsCountCancelled,
  fetchSearchIPsCount,
  fetchSearchIPsCountCancelled,
  fetchSearchMalwaresCount,
  fetchSearchMalwaresCountCancelled,
  fetchSearchRawIntelsCount,
  fetchSearchRawIntelsCountCancelled,
  fetchSearchReportsCount,
  fetchSearchReportsCountCancelled,
  fetchSearchSamplesCount,
  fetchSearchSamplesCountCancelled,
  fetchSearchTechniquesCount,
  fetchSearchTechniquesCountCancelled,
  resetAllSearchCount,
  resetSelectedSearchReportTime,
  resetSelectedSearchReportTypes,
  selectSearchAdversaries,
  selectSearchDomains,
  selectSearchIPs,
  selectSearchMalwares,
  selectSearchRawIntels,
  selectSearchReports,
  selectSearchSamples,
  selectSearchTechniques,
  selectSelectedSearchReportTime,
  selectSelectedSearchReportTypes,
} from 'store/slices/search'
import { TSearchResultKey } from 'store/types/slicesTypes/search'

import { AdversaryContent } from './SearchResultContent/AdversaryContent'
import { CapabilityContent } from './SearchResultContent/CapabilityContent'
import { DomainContent } from './SearchResultContent/DomainContent'
import { IPContent } from './SearchResultContent/IPContent'
import { MalwareContent } from './SearchResultContent/MalwareContent'
import { RawIntelContent } from './SearchResultContent/RawIntelContent'
import { ReportContent } from './SearchResultContent/ReportContent/ReportContent'
import { SampleContent } from './SearchResultContent/SampleContent'

export interface ISearchResultCategoryTab {
  key: TSearchResultKey
  name: string
  component: FC
  count: number
  isLoading: boolean
}

export const useSearchResultCategoryTabs = (): ISearchResultCategoryTab[] => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['search', 'component'])
  const { dateRangeOptionToTimes } = useDateTime()
  const reports = useAppSelector(selectSearchReports)
  const adversaries = useAppSelector(selectSearchAdversaries)
  const malwares = useAppSelector(selectSearchMalwares)
  const samples = useAppSelector(selectSearchSamples)
  const ips = useAppSelector(selectSearchIPs)
  const domains = useAppSelector(selectSearchDomains)
  const techniques = useAppSelector(selectSearchTechniques)
  const rawIntels = useAppSelector(selectSearchRawIntels)
  const selectedSearchReportTypes = useAppSelector(
    selectSelectedSearchReportTypes
  )
  const { startDate, endDate, rangeOption } = useAppSelector(
    selectSelectedSearchReportTime
  )

  const [searchParams] = useSearchParams()
  const searchText = searchParams.get(SearchParamKey.SEARCH_TEXT)

  useEffect(() => {
    // 搜尋關鍵字改變時，需要清除上一次的搜尋結果
    dispatch(resetAllSearchCount())
    if (searchText) {
      dispatch(fetchSearchAdversariesCount({ searchText }))
      dispatch(fetchSearchMalwaresCount({ searchText }))
      dispatch(fetchSearchSamplesCount({ searchText }))
      dispatch(fetchSearchIPsCount({ searchText }))
      dispatch(fetchSearchDomainsCount({ searchText }))
      dispatch(fetchSearchTechniquesCount({ searchText }))
      dispatch(fetchSearchRawIntelsCount({ searchText }))
    }
    return () => {
      dispatch(fetchSearchReportsCountCancelled())
      dispatch(fetchSearchAdversariesCountCancelled())
      dispatch(fetchSearchMalwaresCountCancelled())
      dispatch(fetchSearchSamplesCountCancelled())
      dispatch(fetchSearchIPsCountCancelled())
      dispatch(fetchSearchDomainsCountCancelled())
      dispatch(fetchSearchTechniquesCountCancelled())
      dispatch(fetchSearchRawIntelsCountCancelled())
      dispatch(resetSelectedSearchReportTypes())
      dispatch(resetSelectedSearchReportTime())
    }
  }, [searchText])

  useEffect(() => {
    if (searchText) {
      dispatch(
        fetchSearchReportsCount({
          searchText,
          searchReportTypeOptions: selectedSearchReportTypes,
          ...dateRangeOptionToTimes({ startDate, endDate })[
            rangeOption as TRangeOption
          ],
        })
      )
    }
  }, [searchText, selectedSearchReportTypes, startDate, endDate, rangeOption])

  // 如果 searchReportType 為空 array，API 會拿到所有 report 的 count，但設計希望顯示 0
  const displayReportCount =
    selectedSearchReportTypes.length === 0 ? 0 : reports.count

  return [
    {
      key: 'reports',
      name: t('categoryTabs.reports', { ns: 'component' }),
      component: ReportContent,
      count: displayReportCount,
      isLoading: reports.isCountLoading,
    },
    {
      key: 'adversaries',
      name: t('categoryTabs.adversaries', { ns: 'component' }),
      component: AdversaryContent,
      count: adversaries.count,
      isLoading: adversaries.isCountLoading,
    },
    {
      key: 'malware',
      name: t('categoryTabs.malware', { ns: 'component' }),
      component: MalwareContent,
      count: malwares.count,
      isLoading: malwares.isCountLoading,
    },
    {
      key: 'samples',
      name: t('categoryTabs.samples', { ns: 'component' }),
      component: SampleContent,
      count: samples.count,
      isLoading: samples.isCountLoading,
    },
    {
      key: 'ips',
      name: t('categoryTabs.ips', { ns: 'component' }),
      component: IPContent,
      count: ips.count,
      isLoading: ips.isCountLoading,
    },
    {
      key: 'domains',
      name: t('categoryTabs.domains', { ns: 'component' }),
      component: DomainContent,
      count: domains.count,
      isLoading: domains.isCountLoading,
    },
    {
      key: 'techniques',
      name: t('categoryTabs.capabilities', { ns: 'component' }),
      component: CapabilityContent,
      count: techniques.count,
      isLoading: techniques.isCountLoading,
    },
    {
      key: 'rawIntels',
      name: t('categoryTabs.rawIntel', { ns: 'component' }),
      component: RawIntelContent,
      count: rawIntels.count,
      isLoading: false,
    },
  ]
}
