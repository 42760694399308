import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Drawer } from 'components/Drawer/Drawer'
import { TwoToneLabel } from 'components/Label/Label'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchAccountGroup, selectAccountGroup } from 'store/slices/account'

interface IGroupDrawerProps {
  drawerOpen: boolean
  toggleDrawer: () => void
  groupId?: number
  groupName?: string
}

export const GroupDrawer = ({
  drawerOpen,
  toggleDrawer,
  groupId,
  groupName,
}: IGroupDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['account'])
  const dispatch = useAppDispatch()
  const accountGroup = useAppSelector(selectAccountGroup)

  useEffect(() => {
    if (groupId) dispatch(fetchAccountGroup(groupId))
  }, [groupId])

  return (
    <Drawer open={drawerOpen} onClose={toggleDrawer}>
      <Typography variant="reportH1" sx={{ color: theme.colors.WHITE }}>
        {t('group.groupDrawer.title', { group: groupName, ns: 'account' })}
      </Typography>
      <Divider sx={{ bgcolor: theme.colors.WHITE_20 }} />
      <Box sx={{ py: 2 }}>
        <Typography variant="titleSmall" sx={{ color: theme.colors.WHITE }}>
          {t('group.groupDrawer.memberList', { ns: 'account' })}
        </Typography>
      </Box>
      <Stack
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          px: 4,
          py: 2,
          gap: 2,
          overflowY: 'auto',
        }}
      >
        {accountGroup &&
          accountGroup.map((group) => (
            <TwoToneLabel
              key={group}
              keyText={t('group.groupDrawer.user', { ns: 'account' })}
              valueText={group}
              mainColor={theme.colors.WHITE_60}
              textColor={theme.colors.BLACK_90}
            />
          ))}
      </Stack>
    </Drawer>
  )
}
