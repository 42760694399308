import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import {
  FirstSeenLabel,
  HighlightLabel,
  NetworkActivityLabel,
  RiskLevelLabel,
} from 'components/Label/Label'
import { useDateTime } from 'hooks/useDatetime'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { ISample } from 'store/types/entityTypes/sample'

import {
  MoreSampleDetailButton,
  RelatedAdversaries,
  RelatedMalwares,
  SampleFilename,
  SampleTitle,
} from './SampleElements'

export const SamplePreview = ({
  id,
  filename,
  md5,
  sha256,
  firstSeen,
  adversaries,
  malwares,
  riskLevel,
  iconUrl,
  hasNetworkActivity,
}: ISample) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const imageUrl = useImageUrl({ fetchImageUrl: iconUrl })
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <Box
      sx={{
        display: 'flex',
        pl: 6,
        pr: 4,
        py: 4,
        gap: 8,
        borderBottom: `1px solid ${theme.colors.WHITE_20}`,
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      {imageUrl && (
        <Box sx={{ mt: 2 }}>
          <Image width={48} height={48} src={imageUrl} />
        </Box>
      )}

      <Stack sx={{ flexGrow: 1, gap: 2 }}>
        <SampleTitle title={sha256} />
        <Box
          sx={{
            height: '1rem',
            display: 'flex',
            gap: 2,
            color: theme.colors.WHITE,
          }}
        >
          <HighlightLabel
            text={t('samplePreview.labels.md5', { ns: 'component' })}
          />
          <Typography variant="textSmallImportant">{md5}</Typography>
        </Box>
        <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Stack sx={{ gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FirstSeenLabel
                date={
                  firstSeen
                    ? getDateStringByTimestampsInSeconds(firstSeen)
                    : '--'
                }
              />
              <RiskLevelLabel level={riskLevel} />
              <NetworkActivityLabel isActive={hasNetworkActivity} />
            </Box>
            <RelatedAdversaries adversaries={adversaries} />
            <RelatedMalwares malwares={malwares} />
            <SampleFilename filename={filename} />
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'end', flexShrink: 0 }}>
            <MoreSampleDetailButton sampleId={id} />
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
