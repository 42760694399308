import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { ReactComponent as WarningIcon } from 'assets/basicIcons/warning.svg'
import { NoData } from 'components/NoData/NoData'
import { PageTabsContent } from 'components/PageSection/PageTabsContent'
import { SampleDetails } from 'components/Sample/SampleDetails'
import { SampleDetailsLoading } from 'components/Sample/SampleDetailsLoading'
import { getPageTitleSampleDetailByHash } from 'constants/pageTitle'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useOutOfAAPDialog } from 'hooks/useOutOfAAPDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  fetchSampleDetail,
  fetchSampleDetailCancelled,
  selectSampleDetail,
  selectSampleDetailLoading,
} from 'store/slices/sampleDetail'
import {
  fetchTabAdversaryCount,
  fetchTabAdversaryCountCancelled,
  fetchTabMalwareCount,
  fetchTabMalwareCountCancelled,
  fetchTabReportCount,
  fetchTabReportCountCancelled,
  fetchTabTechniqueCount,
  fetchTabTechniqueCountCancelled,
  resetTabAdversaryContent,
  resetTabCounts,
  resetTabMalwareContent,
  resetTabReportContent,
  selectTabMalwareCount,
} from 'store/slices/tabContent'

import { useSampleCategoryTabs } from './config'
import { SampleCategoryTabs } from './SampleCategoryTabs'

const ANTIVIRUS_THRESHOLD = 3

interface IAntivirusReminderProps {
  showReminder: boolean
  hideReminder: () => void
}

const AntivirusReminder = ({
  showReminder,
  hideReminder,
}: IAntivirusReminderProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['sample'])

  if (!showReminder) {
    return null
  }

  return (
    <Box sx={{ px: 6, py: 4, width: '58.5rem' }}>
      <Box
        sx={{
          display: 'flex',
          p: '0.5rem',
          gap: 2,
          borderLeft: `2px solid ${theme.colors.WHITE}`,
          bgcolor: theme.colors.WHITE_20,
        }}
      >
        <Box sx={{ flexShrink: 0 }}>
          <SvgIcon component={WarningIcon} />
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: 1, color: theme.colors.WHITE }}>
          <Typography component="div" variant="titleSmall">
            {t('antivirusReminder.title', { ns: 'sample' })}
          </Typography>
          <Typography component="div" variant="body">
            <Trans t={t} i18nKey="antivirusReminder.description">
              The sample is related to multiple malware families and
              adversaries, which is not a reasonable situation. It might be a
              antivirus pattern file. However, we suggest that it&apos;s prudent
              to refer to other indicators for further analysis and actions,
              please click
              <Typography variant="titleSmall">Need Help</Typography>
              button at the bottom right of the web page for further analysis
              assistance.
            </Trans>
          </Typography>
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <SvgIcon
            onClick={hideReminder}
            sx={{ cursor: 'pointer' }}
            component={CloseIcon}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SampleDetailPage = () => {
  useGoBack()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const sampleId = searchParams.get(SearchParamKey.SAMPLE_ID)
  const sampleDetailLoading = useAppSelector(selectSampleDetailLoading)
  const sampleDetail = useAppSelector(selectSampleDetail)
  const tabMalwareCount = useAppSelector(selectTabMalwareCount)
  const { renderOutAAPDialog, outOfAAPDialogOpen, handleOutOfAAPDialogOpen } =
    useOutOfAAPDialog({
      needGoBack: true,
    })

  const sampleCategoryTabs = useSampleCategoryTabs()
  const defaultCategoryTab = sampleCategoryTabs[0]

  const categoryTabParam = searchParams.get(SearchParamKey.SAMPLE_TAB)
  const categoryTab =
    (categoryTabParam &&
      sampleCategoryTabs.find((tab) => tab.key === categoryTabParam)) ||
    defaultCategoryTab

  const [showReminder, setShowReminder] = useState<boolean>(true)

  const hideReminder = () => {
    setShowReminder(false)
  }

  useEffect(() => {
    if (sampleId) {
      if (sampleDetail) {
        dispatch(fetchTabReportCount(sampleId))
        dispatch(fetchTabAdversaryCount(sampleId))
        dispatch(fetchTabMalwareCount(sampleId))
        dispatch(fetchTabTechniqueCount(sampleId))
      }
    }
    return () => {
      dispatch(fetchTabReportCountCancelled())
      dispatch(fetchTabAdversaryCountCancelled())
      dispatch(fetchTabMalwareCountCancelled())
      dispatch(fetchTabTechniqueCountCancelled())
    }
  }, [sampleId, sampleDetail])

  useEffect(() => {
    if (sampleId) {
      dispatch(
        fetchSampleDetail({
          sampleId,
          outOfAAPCallback: handleOutOfAAPDialogOpen,
        })
      )
    }
    return () => {
      dispatch(resetTabReportContent())
      dispatch(resetTabAdversaryContent())
      dispatch(resetTabMalwareContent())
      dispatch(resetTabCounts())
      dispatch(fetchSampleDetailCancelled())
    }
  }, [sampleId])

  const BottomContent = categoryTab.component

  const shouldShowAntivirusReminder =
    (categoryTab.key === 'adversaries' || categoryTab.key === 'malware') &&
    tabMalwareCount > ANTIVIRUS_THRESHOLD

  if (!sampleDetail && !sampleDetailLoading && !outOfAAPDialogOpen) {
    return <NoData />
  }

  return (
    <>
      <>
        <Helmet>
          <title>
            {sampleDetail &&
              getPageTitleSampleDetailByHash(sampleDetail.sha256)}
          </title>
        </Helmet>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            [theme.breakpoints.up('desktop')]: {
              justifyContent: 'center',
            },
          }}
        >
          <Stack
            sx={{
              p: 2,
              color: theme.colors.WHITE,
              height: '100%',
              width: '78rem',
            }}
          >
            {sampleDetailLoading ? (
              <SampleDetailsLoading />
            ) : (
              sampleDetail && <SampleDetails {...sampleDetail} />
            )}
            <PageTabsContent
              tabs={
                <SampleCategoryTabs
                  sampleCategoryTabs={sampleCategoryTabs}
                  categoryTab={categoryTab}
                />
              }
              extraReminder={
                shouldShowAntivirusReminder ? (
                  <AntivirusReminder
                    showReminder={showReminder}
                    hideReminder={hideReminder}
                  />
                ) : null
              }
              content={
                <BottomContent searchText={sampleDetail?.sha256 || ''} />
              }
            />
          </Stack>
        </Box>
      </>
      {renderOutAAPDialog}
    </>
  )
}
