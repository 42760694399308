export enum LocalStorageKey {
  /* auth page start */
  AUTH_TOKEN = 'auth-token',
  AUTH_TOKEN_TYPE = 'auth-token-type',
  AUTH_EXPIRE_TIME = 'auth-expire-time',
  PATH_BEFORE_LOGOUT = 'path-before-logout',
  /* auth page end */

  /* search page start */
  SEARCH_HISTORY = 'search-history',
  /* search page end */
}
