import { useTheme } from '@mui/material'

export const threatLvMap = {
  high: 'High Threat',
  medium: 'Medium Threat',
  low: 'Low Threat',
}

export const useThreatLvColor = () => {
  const theme = useTheme()
  return {
    high: theme.colors.TLPRed,
    medium: theme.colors.TLPAmber,
    low: theme.colors.TLPGreen,
  }
}
