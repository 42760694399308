import { Box, Divider, Stack, useTheme } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

const LoadingBlock = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      px: 4,
      py: 3,
      gap: 2,
      width: '100%',
    }}
  >
    <Skeleton type="title" width="26.5rem" />
    <Skeleton type="title" width="7.5rem" />
  </Box>
)

export const OneLineLoading = () => {
  const theme = useTheme()

  return (
    <Stack>
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
      <LoadingBlock />
      <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
    </Stack>
  )
}
