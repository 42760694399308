import { Box, Stack, useTheme } from '@mui/material'
import { useState } from 'react'

import { ISampleTabContentProps } from '../type'
import { PreviewContentTabs } from './PreviewContentTabs'
import { PreviewHex } from './PreviewHex'
import { PreviewStrings } from './PreviewStrings'

export type TPreviewFormat = 'Strings' | 'HEX'

export type TStringType = 'ascii' | 'utf' | 'stack'

export const PreviewContent = ({ searchText }: ISampleTabContentProps) => {
  const theme = useTheme()
  const [stringType, setStringType] = useState<TStringType>('ascii')
  const handleStringTypeChange = (newType: TStringType) => {
    setStringType(newType)
  }

  const [format, setFormat] = useState<TPreviewFormat>('Strings')
  const handleFormatChange = (newFormat: TPreviewFormat) => {
    setFormat(newFormat)
  }

  return (
    <Box sx={{ height: '100%', pt: 1 }}>
      <Stack
        sx={{
          height: '100%',
          overflowY: 'hidden',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
        }}
      >
        <PreviewContentTabs
          selectedFormat={format}
          handleFormatChange={handleFormatChange}
          stringType={stringType}
          handleStringTypeChange={handleStringTypeChange}
        />
        <Box
          sx={{
            bgcolor: theme.colors.BLACK_90,
            flexGrow: 1,
            flexBasis: 1,
            overflowY: 'hidden',
          }}
        >
          {format === 'Strings' && (
            <PreviewStrings searchText={searchText} stringType={stringType} />
          )}
          {format === 'HEX' && <PreviewHex searchText={searchText} />}
        </Box>
      </Stack>
    </Box>
  )
}
