import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_ROUTE,
  API_DOC_V2_ROUTE,
  API_DOC_V2_SAMPLE_ROUTE,
  API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE,
} from 'constants/routeParams'
import {
  API_DOC_V2_IP_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/network/ips/167.179.85.233/samples" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"167.179.85.233",
  "analysis_status":true,
  "samples":[
    {
      "main_value":"3e05e13fd8885e697bc711dacdcbda2eac9f6d2af3c878c7f844ab3b5a4ddbf2",
      "sha256":"3e05e13fd8885e697bc711dacdcbda2eac9f6d2af3c878c7f844ab3b5a4ddbf2",
      "md5":"c803b64f6896f545a8ab2e16976d47b2",
      "size":543496,
      "first_seen":1702952479,
      "adversaries":[],
      "malwares":[],
      "filename":null,
      "risk_level":"high",
      "has_network_activity":true,
      "url":"https://api.threatvision.org/api/v2/samples/3e05e13fd8885e697bc711dacdcbda2eac9f6d2af3c878c7f844ab3b5a4ddbf2"
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Samples'

export const APIDocumentVersionTwoIPSamples = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_IP_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_IP_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          With this endpoint, you can retrieve samples that are related to this
          IP.
          <br />
          To dig into the details about those samples, you are welcome to make
          use of the endpoint for{' '}
          <TextLink
            sx={{ wordBreak: 'break-word' }}
            to={`/${API_DOC_ROUTE}/${API_DOC_V2_ROUTE}/${API_DOC_V2_SAMPLE_ROUTE}/${API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE}`}
            size="large"
          >
            Search Samples
          </TextLink>
          .
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/network/ips/:address/samples" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            analysis_status
            <UnorderedList>
              <ListItem>The IP had been analyzed</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The IP address</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            samples
            <br />
            Array of matching uploaded samples, each sample contains following
            keys:
            <UnorderedList>
              <ListItem>sha256</ListItem>
              <ListItem>filename</ListItem>
              <ListItem>uploaded_date</ListItem>
              <ListItem>
                adversaries
                <UnorderedList>
                  <ListItem>Related adversaries</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                malwares
                <UnorderedList>
                  <ListItem>Related malwares</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                url
                <UnorderedList>
                  <ListItem>
                    Link to sample detail API endpoint in ThreatVision
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
