export interface ITacticAPIData {
  main_value: string
  name: string
  description: string
  last_modified: number
  published_at: number
  version: string
  techniques: ITechniqueAPIData[]
}

export interface ITactic {
  id: string
  name: string
  description: string
  lastModified: number | null
  publishedAt: number | null
  version: string
  techniques: ITechnique[]
}

export interface ITechniqueAPIData {
  main_value: string
  name: string
  description: string
  last_modified: number
  published_at: number
  version: string
  parent?: {
    serial: string | null
    name: string | null
  }
  tactics: {
    tactic_name: string
    tactic_serial: string
  }[]
  sub_techniques: ITechniqueAPIData[]
}

export interface ITechnique {
  id: string
  name: string
  description: string
  lastModified: number | null
  publishedAt: number | null
  version: string
  parent: {
    id: string | null
    name: string | null
  }
  tactics: {
    name: string
    id: string
  }[]
  subTechniques: ITechnique[]
  parentTechnique?: ITechnique
}

export const mapAPITechniqueToState = (
  fromAPITechnique: ITechniqueAPIData
): ITechnique => ({
  id: fromAPITechnique.main_value,
  name: fromAPITechnique.name,
  description: fromAPITechnique.description,
  lastModified: fromAPITechnique.last_modified,
  publishedAt: fromAPITechnique.published_at,
  version: fromAPITechnique.version,
  parent: {
    id: fromAPITechnique?.parent?.serial || null,
    name: fromAPITechnique?.parent?.name || null,
  },
  tactics: fromAPITechnique.tactics.map((tactic) => ({
    name: tactic.tactic_name,
    id: tactic.tactic_serial,
  })),
  subTechniques: fromAPITechnique.sub_techniques.map(mapAPITechniqueToState),
})

export const mapAPITechniquesToState = (
  fromAPITechniques: ITechniqueAPIData[]
): ITechnique[] => fromAPITechniques.map(mapAPITechniqueToState)

export const mapAPITacticToState = (
  fromAPITactic: ITacticAPIData
): ITactic => ({
  id: fromAPITactic.main_value,
  name: fromAPITactic.name,
  description: fromAPITactic.description,
  lastModified: fromAPITactic.last_modified,
  publishedAt: fromAPITactic.published_at,
  version: fromAPITactic.version,
  techniques: fromAPITactic.techniques.map(mapAPITechniqueToState),
})

export const mapAPITacticsToState = (
  fromAPITactics: ITacticAPIData[]
): ITactic[] => fromAPITactics.map(mapAPITacticToState)
