import { IPMReportDetail, TThreatLevel } from 'store/types/entityTypes/report'
import { checkTextIncludingIgnoreCase } from 'utils/checkString'
import { numberComparator, stringComparator } from 'utils/comparator'

import type {
  TFilterTextMap,
  TOrder,
  TPMReportDetailOrderKeys,
} from './PMReportDetailTable'

/* Header config */

// PM 指定的 threatLv 排序階層
const PMReportDetailThreatLvMap: Record<TThreatLevel, number> = {
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
}

type TExploitedOrderMapKey = 'true' | 'false' | 'null'

// PM 指定的 exploited 排序階層
const PMReportDetailExploitedOrderMap: Record<TExploitedOrderMapKey, number> = {
  true: 2,
  false: 1,
  null: 1,
}

type TPubliclyDisclosedOrderMapKey = 'true' | 'false' | 'null'

// PM 指定的 PubliclyDisclosed 排序階層
const PMReportDetailPubliclyDisclosedOrderMap: Record<
  TPubliclyDisclosedOrderMapKey,
  number
> = {
  true: 2,
  false: 1,
  null: 1,
}

type TPocOrderMapKey = 'true' | 'false'

// PM 指定的 poc 排序階層
const PMReportDetailPocOrderMap: Record<TPocOrderMapKey, number> = {
  true: 2,
  false: 1,
}

/**
 * @param order asc or desc
 * @returns 不同 PMReportDetail table 欄位專屬的排序方式
 */

export const getPMReportDetailComparatorMap = (
  order: TOrder
): Record<
  TPMReportDetailOrderKeys,
  (a: IPMReportDetail, b: IPMReportDetail) => number
> => {
  const isDesc = order === 'desc'

  return {
    id: (a: IPMReportDetail, b: IPMReportDetail) =>
      stringComparator(a.id, b.id, order),
    vendor: (a: IPMReportDetail, b: IPMReportDetail) =>
      stringComparator(a.vendor, b.vendor, order),
    // products: (a: IPMReportDetail, b: IPMReportDetail) => {
    //   const aProduct = a.products.length > 1 ? 'Multiple Device' : a.products[0]
    //   const bProduct = b.products.length > 1 ? 'Multiple Device' : b.products[0]
    //   return stringComparator(aProduct, bProduct, order)
    // },
    product: (a: IPMReportDetail, b: IPMReportDetail) =>
      stringComparator(a.product || '', b.product || '', order),
    cvss: (a: IPMReportDetail, b: IPMReportDetail) =>
      numberComparator(a.cvss || 0, b.cvss || 0, order),
    // 1st order: Threat Lv. -> 2nd order: Exploited: True 較前 -> 3nd order: PoC > 0 較前
    threatLevel: (a: IPMReportDetail, b: IPMReportDetail) => {
      const aLevel = PMReportDetailThreatLvMap[a.threatLevel]
      const bLevel = PMReportDetailThreatLvMap[b.threatLevel]

      if (aLevel < bLevel) {
        return isDesc ? 1 : -1
      }

      if (aLevel > bLevel) {
        return isDesc ? -1 : 1
      }

      if (aLevel === bLevel) {
        const aExploited =
          PMReportDetailExploitedOrderMap[
            String(a.exploited) as TExploitedOrderMapKey
          ]
        const bExploited =
          PMReportDetailExploitedOrderMap[
            String(b.exploited) as TExploitedOrderMapKey
          ]

        if (aExploited < bExploited) {
          return isDesc ? 1 : -1
        }

        if (aExploited > bExploited) {
          return isDesc ? -1 : 1
        }

        if (aExploited === bExploited) {
          const aPoc =
            PMReportDetailPocOrderMap[
              a.poc ? (String(a.poc.length > 0) as TPocOrderMapKey) : 'false'
            ]
          const bPoc =
            PMReportDetailPocOrderMap[
              b.poc ? (String(b.poc.length > 0) as TPocOrderMapKey) : 'false'
            ]
          if (aPoc < bPoc) {
            return isDesc ? 1 : -1
          }

          if (aPoc > bPoc) {
            return isDesc ? -1 : 1
          }

          return 0
        }

        return 0
      }

      return 0
    },
    exploited: (a: IPMReportDetail, b: IPMReportDetail) => {
      const aExploited =
        PMReportDetailExploitedOrderMap[
          String(a.exploited) as TExploitedOrderMapKey
        ]
      const bExploited =
        PMReportDetailExploitedOrderMap[
          String(b.exploited) as TExploitedOrderMapKey
        ]

      if (aExploited < bExploited) {
        return isDesc ? 1 : -1
      }

      if (aExploited > bExploited) {
        return isDesc ? -1 : 1
      }

      return 0
    },
    publiclyDisclosed: (a: IPMReportDetail, b: IPMReportDetail) => {
      const aPubliclyDisclosed =
        PMReportDetailPubliclyDisclosedOrderMap[
          String(a.publiclyDisclosed) as TPubliclyDisclosedOrderMapKey
        ]
      const bPubliclyDisclosed =
        PMReportDetailPubliclyDisclosedOrderMap[
          String(b.publiclyDisclosed) as TPubliclyDisclosedOrderMapKey
        ]

      if (aPubliclyDisclosed < bPubliclyDisclosed) {
        return isDesc ? 1 : -1
      }

      if (aPubliclyDisclosed > bPubliclyDisclosed) {
        return isDesc ? -1 : 1
      }

      return 0
    },
    updatedAt: (a: IPMReportDetail, b: IPMReportDetail) =>
      numberComparator(a.updatedAt, b.updatedAt, order),
  }
}

/**
 * @param pmReportDetail PMReportDetail 列表資料
 * @param filterTextMap 各欄位的 filterText
 * @returns 取 filterTextMap 各欄位包含文字的交集
 */
export const filterPMReportDetail = ({
  pmReportDetail,
  filterTextMap,
}: {
  pmReportDetail: IPMReportDetail[]
  filterTextMap: TFilterTextMap
}): IPMReportDetail[] => {
  const validIndex: number[] = []

  const newPMReportDetails = pmReportDetail.map((reportDetail, index) => {
    // 預設為 undefined，依序被有 filterText 的欄位檢查
    let valid

    if (valid !== false && filterTextMap.id) {
      valid = checkTextIncludingIgnoreCase(reportDetail.id, filterTextMap.id)
    }

    if (valid !== false && filterTextMap.vendor) {
      valid = checkTextIncludingIgnoreCase(
        reportDetail.vendor,
        filterTextMap.vendor
      )
    }
    // if (
    //   valid !== false &&
    //   filterTextMap.products &&
    //   reportDetail.products.length > 0
    // ) {
    //   const matchProductIndex = reportDetail.products.findIndex(
    //     (alias) =>
    //       filterTextMap.products &&
    //       checkTextIgnoreCase(alias, filterTextMap.products)
    //   )
    //   if (matchProductIndex > -1) {
    //     // 只顯示第一個 alias，但過濾時會檢查所有 alias，若有符合則挪至第一個來顯示
    //     reportDetail.products[0] = reportDetail.products[matchProductIndex]
    //   } else {
    //     valid = false
    //   }
    // }
    if (valid !== false && filterTextMap.product) {
      valid = checkTextIncludingIgnoreCase(
        reportDetail.product || '',
        filterTextMap.product
      )
    }

    // 如果 valid 依然是初始的 undefined，代表都不用 filter，只有 false 會被過濾掉
    if (valid !== false) {
      validIndex.push(index)
    }

    return reportDetail
  })

  return newPMReportDetails.filter((_reportDetail, index) =>
    validIndex.includes(index)
  )
}
