import { Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'

export const SubscriptionReportLoading = () => (
  <Stack sx={{ gap: 2, px: 4, py: 2 }}>
    <Skeleton type="body" width="13.5rem" />
    <Skeleton type="body" width="20rem" />
    <Skeleton type="body" width="16.5rem" />
  </Stack>
)
