import { Dialog } from 'components/Dialog/Dialog'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { openMail } from 'utils/mail'

interface IOutOfAAPDialogProps {
  open: boolean
  handleClose: () => void
  needGoBack?: boolean
}

export const OutOfAAPDialog = ({
  open,
  handleClose,
  needGoBack,
}: IOutOfAAPDialogProps) => {
  const { t } = useTranslation(['component'])
  const navigate = useNavigate()
  const handleConfirm = () => {
    openMail()
    if (needGoBack) {
      navigate(-1)
    }
    handleClose()
  }

  const handleDialogClose = () => {
    if (needGoBack) {
      navigate(-1)
    }
    handleClose()
  }

  return (
    <Dialog
      open={open}
      handleDialogClose={handleDialogClose}
      title={t('aapNotEnoughDialog.title', { ns: 'component' })}
      description={t('aapNotEnoughDialog.description', { ns: 'component' })}
      confirmButtonText={t('sendEmailCta', { ns: 'component' })}
      handleConfirmButtonClick={handleConfirm}
    />
  )
}
