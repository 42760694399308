import { useDateTime } from 'hooks/useDatetime'
import { IAccountOnDemandReports } from 'store/types/entityTypes/account'
import { checkTextIncludingIgnoreCase } from 'utils/checkString'
import { numberComparator, stringComparator } from 'utils/comparator'

import type { TFilterTextMap, TOrder,TRFIListOrderKeys } from './RFIListTable'

/**
 * @param order asc or desc
 * @returns 不同 RFIList table 欄位專屬的排序方式
 */

export const getRFIListComparatorMap = (
  order: TOrder
): Record<
  TRFIListOrderKeys,
  (a: IAccountOnDemandReports, b: IAccountOnDemandReports) => number
> => ({
  date: (a: IAccountOnDemandReports, b: IAccountOnDemandReports) =>
    numberComparator(a.date, b.date, order),
  title: (a: IAccountOnDemandReports, b: IAccountOnDemandReports) =>
    stringComparator(a.title, b.title, order),
  requestForAssistanceHour: (
    a: IAccountOnDemandReports,
    b: IAccountOnDemandReports
  ) =>
    numberComparator(
      a.requestForAssistanceHour,
      b.requestForAssistanceHour,
      order
    ),
})

/**
 * @param RFIList RFI 列表資料
 * @param filterTextMap 各欄位的 filterText
 * @returns 取 filterTextMap 各欄位包含文字的交集
 */
export const filterRFIList = ({
  RFIList,
  filterTextMap,
}: {
  RFIList: IAccountOnDemandReports[]
  filterTextMap: TFilterTextMap
}): IAccountOnDemandReports[] => {
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const validIndex: number[] = []

  const newRFIList = RFIList.map((RFI, index) => {
    // 預設為 undefined，依序被有 filterText 的欄位檢查
    let valid
    if (valid !== false && filterTextMap.date) {
      valid = checkTextIncludingIgnoreCase(
        getDateStringByTimestampsInSeconds(RFI.date),
        filterTextMap.date
      )
    }
    if (valid !== false && filterTextMap.title) {
      valid = checkTextIncludingIgnoreCase(RFI.title, filterTextMap.title)
    }

    // 如果 valid 依然是初始的 undefined，代表都不用 filter，只有 false 會被過濾掉
    if (valid !== false) {
      validIndex.push(index)
    }

    return RFI
  })

  return newRFIList.filter((_RFIList, index) => validIndex.includes(index))
}
