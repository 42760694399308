import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  IAuthAPIResponse,
  IAuthSlice,
  ILoginPostPayload,
  ILogoutPayload,
} from 'store/types/slicesTypes/auth'
import { getAuthToken, getExpireTime } from 'utils/auth'

const initialState: IAuthSlice = {
  ...getAuthToken(),
  isLoginLoading: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, _action: PayloadAction<ILoginPostPayload>) {
      state.isLoginLoading = true
    },
    loginFulfilled(state, action: PayloadAction<IAuthAPIResponse>) {
      state.accessToken = action.payload.access_token
      state.tokenType = action.payload.token_type
      state.expireTime = getExpireTime(action.payload.expires_in)
      state.isLoginLoading = false
    },
    loginRejected(state, _action: PayloadAction<AjaxError>) {
      state.isLoginLoading = false
    },
    loginCancelled(state) {
      state.isLoginLoading = false
    },
    logout(state, _action: PayloadAction<ILogoutPayload>) {
      state.accessToken = ''
      state.tokenType = ''
      state.expireTime = null
    },
  },
})

export const { login, loginFulfilled, loginRejected, loginCancelled, logout } =
  authSlice.actions

export const selectAccessToken = (state: TRootState) => state.auth.accessToken
export const selectExpireTime = (state: TRootState) => state.auth.expireTime
export const selectLoginLoading = (state: TRootState) =>
  state.auth.isLoginLoading

export default authSlice.reducer
