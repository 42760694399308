export interface ICounts {
  reportCount: number | null
  indicatorCount: number | null
  sampleCount: number | null
  malwareCount: number | null
  adversaryCount: number | null
}

interface ICountsAPIData {
  report_count: number
  indicator_count: number
  sample_count: number
  malware_count: number
  adversary_count: number
}

export interface IFetchCountsResponse {
  success: boolean
  data: ICountsAPIData
}

export const mapAPICountsToState = (
  fromAPICounts: ICountsAPIData
): ICounts => ({
  reportCount: fromAPICounts.report_count,
  indicatorCount: fromAPICounts.indicator_count,
  sampleCount: fromAPICounts.sample_count,
  malwareCount: fromAPICounts.malware_count,
  adversaryCount: fromAPICounts.adversary_count,
})
