import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  AdversaryAliasList,
  AdversaryIntroduction,
  AdversaryOrigin,
  AdversaryTargetList,
} from 'components/Adversary/AdversaryLabels'
import { ADVERSARIES_ROUTE, ADVERSARY_INFO_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useImageUrl } from 'hooks/useImageSrc'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchMatchedAdversary,
  fetchMatchedAdversaryCancelled,
  selectMatchedAdversary,
  selectMatchedCardLoading,
} from 'store/slices/search'

import { MatchedCardLayout } from './MatchedCardLayout'

interface IAdversaryImageProps {
  name: string
  image: string
}

const AdversaryImage = ({ name, image }: IAdversaryImageProps) => {
  const theme = useTheme()
  const imageUrl = useImageUrl({ fetchImageUrl: image })
  return imageUrl ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2.5,
        background: `linear-gradient(0deg, ${theme.colors.ADVERSARY_BG_COLOR}, ${theme.colors.ADVERSARY_BG_COLOR}), url("${imageUrl}");`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '8.75rem',
        p: 5,
      }}
    >
      <Image width={96} height={96} src={imageUrl} />
      <Typography color={theme.colors.WHITE} variant="titleBig">
        {name}
      </Typography>
    </Box>
  ) : null
}

interface IAdversaryMatchedCardProps {
  adversaryId: string
}

export const AdversaryMatchedCard = ({
  adversaryId,
}: IAdversaryMatchedCardProps) => {
  const dispatch = useAppDispatch()
  const adversary = useAppSelector(selectMatchedAdversary)
  const matchedCardLoading = useAppSelector(selectMatchedCardLoading)
  const { t } = useTranslation(['search', 'component'])

  useEffect(() => {
    if (adversaryId) {
      dispatch(fetchMatchedAdversary(adversaryId))
    }
    return () => {
      dispatch(fetchMatchedAdversaryCancelled())
    }
  }, [adversaryId])

  if (!adversary || matchedCardLoading) {
    return null
  }

  const {
    id,
    aliases,
    overview,
    originCountries,
    targetedCountries,
    targetedIndustries,
    name,
    image,
  } = adversary

  const country = originCountries[0] || ''

  return (
    <MatchedCardLayout
      title={t('matchedCard.adversary', { ns: 'search' })}
      linkText={t('readMoreCta', { ns: 'component' })}
      linkTo={`/${ADVERSARIES_ROUTE}/${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_ID}=${id}`}
    >
      <Stack sx={{ gap: 2 }}>
        <AdversaryImage name={name} image={image} />
        <Stack sx={{ gap: 2, px: 4 }}>
          <AdversaryAliasList aliases={aliases} />
          {country && <AdversaryOrigin country={country} />}
          <AdversaryTargetList
            keyText={t('adversaryPreview.labels.targetCountry', {
              ns: 'component',
            })}
            labels={targetedCountries}
          />
          <AdversaryTargetList
            keyText={t('adversaryPreview.labels.targetIndustry', {
              ns: 'component',
            })}
            labels={targetedIndustries}
          />
          <AdversaryIntroduction overview={overview} />
        </Stack>
      </Stack>
    </MatchedCardLayout>
  )
}
