import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Button/Button'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import { ListItem, UnorderedList } from 'components/List/List'
import {
  RawIntelAdversaryLabel,
  RawIntelExploitLabel,
  RawIntelMalwareLabel,
  VictimInformation,
} from 'components/RawIntel/RawIntelLabels'
import { UserComments } from 'components/UserComments/UserComments'
import { getPageTitleRawIntelDetailByTitle } from 'constants/pageTitle'
import {
  CAPABILITY_ROUTE,
  NOT_FOUND_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useGoBack } from 'hooks/useGoBack'
import { useOutOfAAPDialog } from 'hooks/useOutOfAAPDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  fetchComments,
  resetComments,
  setupCommentableIdAndType,
} from 'store/slices/comment'
import {
  fetchRawIntelDetail,
  fetchRawIntelDetailCancelled,
  selectRawIntelDetail,
  selectRawIntelDetailLoading,
} from 'store/slices/rawIntel'
import { TCommentType } from 'store/types/entityTypes/comment'
import { openMail } from 'utils/mail'

import { Loading } from './Loading'

const commentableType: TCommentType = 'Karen::RawIntel'

export const RawIntelPage = () => {
  useGoBack()
  const { t } = useTranslation(['rawIntel'])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [searchParam] = useSearchParams()
  const isRawIntelLoading = useAppSelector(selectRawIntelDetailLoading)
  const rawIntelDetail = useAppSelector(selectRawIntelDetail)
  const { renderOutAAPDialog, handleOutOfAAPDialogOpen } = useOutOfAAPDialog({
    needGoBack: true,
  })

  const rawIntelId = searchParam.get(SearchParamKey.RAW_INTEL_ID)

  const handleContact = () => openMail()

  useEffect(() => {
    if (rawIntelId) {
      dispatch(
        fetchRawIntelDetail({
          id: rawIntelId,
          outOfAAPCallback: handleOutOfAAPDialogOpen,
        })
      )
    } else {
      navigate(`/${NOT_FOUND_ROUTE}`)
    }

    return () => {
      dispatch(fetchRawIntelDetailCancelled())
    }
  }, [])

  useEffect(() => {
    if (rawIntelId && rawIntelDetail) {
      dispatch(
        setupCommentableIdAndType({
          commentableType,
          commentableId: rawIntelId,
        })
      )
      dispatch(
        fetchComments({
          commentableType,
          commentableId: rawIntelId,
        })
      )
    }

    return () => {
      dispatch(resetComments())
    }
  }, [rawIntelDetail, rawIntelId])

  return (
    <>
      <Helmet>
        <title>
          {rawIntelDetail &&
            getPageTitleRawIntelDetailByTitle(rawIntelDetail.title)}
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          [theme.breakpoints.up('xl')]: {
            width: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`, // 1920px - navbar width
          },
        }}
      >
        <Stack
          sx={{
            py: 4,
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            minWidth: '47.5rem',
            maxWidth: '85.5rem',
            bgcolor: theme.colors.BLACK_95,
            color: theme.colors.WHITE,
            overflowY: 'auto',
          }}
        >
          {isRawIntelLoading ? (
            <Loading />
          ) : (
            rawIntelDetail && (
              <Stack sx={{ gap: 12 }}>
                <Stack sx={{ gap: 2, width: '40.625rem' }}>
                  <Typography color={theme.colors.WHITE} variant="reportH1">
                    {rawIntelDetail.title}
                  </Typography>
                  <VictimInformation
                    countries={rawIntelDetail.targetCountries}
                    industries={rawIntelDetail.targetIndustries}
                  />
                  <RawIntelMalwareLabel malwares={rawIntelDetail.malwares} />
                  <RawIntelAdversaryLabel
                    adversaries={rawIntelDetail.adversaries}
                  />
                  <RawIntelExploitLabel exploits={rawIntelDetail.exploits} />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <HighlightLabel
                        text={t('label.maliciousSample', { ns: 'rawIntel' })}
                      />
                      {rawIntelDetail.maliciousSamples.length === 0 && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          --
                        </Typography>
                      )}
                    </Box>
                    <UnorderedList>
                      {rawIntelDetail.maliciousSamples.map((sample) => (
                        <ListItem key={sample}>
                          <TextLink
                            to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${sample}`}
                            target="_blank"
                          >
                            {sample}
                          </TextLink>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <HighlightLabel
                        text={t('label.suspiciousIp', { ns: 'rawIntel' })}
                      />
                      {rawIntelDetail.ips.length === 0 && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          --
                        </Typography>
                      )}
                    </Box>
                    <UnorderedList>
                      {rawIntelDetail.ips.map((ip) => (
                        <ListItem key={ip}>
                          <TextLink
                            to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${ip}`}
                            target="_blank"
                          >
                            {ip}
                          </TextLink>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <HighlightLabel
                        text={t('label.suspiciousDomain', { ns: 'rawIntel' })}
                      />
                      {rawIntelDetail.domains.length === 0 && (
                        <Typography
                          variant="textSmallImportant"
                          sx={{ color: theme.colors.WHITE }}
                        >
                          --
                        </Typography>
                      )}
                    </Box>
                    <UnorderedList>
                      {rawIntelDetail.domains.map((domain) => (
                        <ListItem key={domain}>
                          <TextLink
                            to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${domain}`}
                            target="_blank"
                          >
                            {domain}
                          </TextLink>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <HighlightLabel
                      text={t('label.capability', { ns: 'rawIntel' })}
                    />
                    {rawIntelDetail.capabilities.length > 0 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {rawIntelDetail.capabilities.map((technique, index) => (
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            key={technique}
                          >
                            <TextLink
                              to={`/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${technique}`}
                            >
                              {technique}
                            </TextLink>
                            {index < rawIntelDetail.capabilities.length - 1 && (
                              <Typography
                                variant="textLink"
                                sx={{
                                  color: theme.colors.WHITE,
                                  mr: 1,
                                  textDecoration: 'none',
                                }}
                              >
                                ,
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography
                        variant="textSmallImportant"
                        sx={{ color: theme.colors.WHITE }}
                      >
                        --
                      </Typography>
                    )}
                  </Box>
                </Stack>
                <Box>
                  <UserComments />
                </Box>
              </Stack>
            )
          )}
        </Stack>
        <Box
          sx={{
            height: '100%',
            flexShrink: 0,
            width: '20.5rem',
            [theme.breakpoints.up('desktop')]: {
              width: '26.5rem',
            },
            [theme.breakpoints.up('xl')]: {
              width: '32.5rem',
            },
            p: 6,
            bgcolor: theme.colors.BLACK_90,
          }}
        >
          <Stack
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: theme.colors.BLACK_85,
              height: '100%',
              p: 6,
            }}
          >
            <Stack sx={{ alignItems: 'center', gap: 4 }}>
              <Typography color={theme.colors.WHITE} variant="titleSmall">
                {t('alert.title', { ns: 'rawIntel' })}
              </Typography>
              <Divider
                sx={{ borderColor: theme.colors.WHITE_20, width: '100%' }}
              />
              <Typography color={theme.colors.WHITE} variant="bodySmall">
                <Trans t={t} i18nKey="alert.description">
                  “TeamT5 Raw Intel” is TeamT5’s preliminary findings about the
                  malicious campaign. “TeamT5 Raw Intel” can help the user to
                  have a bigger picture of the malicious campaign based on the
                  searched Indicators of Compromise (IOCs). However, the
                  information of “TeamT5 Raw Intel” requires further
                  investigation. We recommend that our user who needs further
                  analysis and investigation can contact us.
                </Trans>
              </Typography>
            </Stack>
            <Button variant="contained" size="large" onClick={handleContact}>
              {t('contactCta', { ns: 'rawIntel' })}
            </Button>
          </Stack>
        </Box>
        {renderOutAAPDialog}
      </Box>
    </>
  )
}
