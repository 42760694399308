import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  TARGET_COUNTRY_ROUTE,
  TARGET_INDUSTRY_ROUTE,
  TARGET_REGION_ROUTE,
  THREAT_LANDSCAPE_ROUTE,
} from 'constants/routeParams'
import { useTargetedCountries } from 'hooks/useTargetedCountries'
import { useTargetedIndustries } from 'hooks/useTargetedIndustries'
import { useTargetedRegions } from 'hooks/useTargetedRegions'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { MatchedCardLayout } from './MatchedCardLayout'

interface ITargetMatchedCardLayoutProps {
  logo?: ReactElement
  desc: string
  displayName: string
  title: string
  backgroundImageSrc: string
  linkTo: string
}

export const TargetMatchedCardLayout = ({
  logo,
  desc,
  displayName,
  backgroundImageSrc,
  title,
  linkTo,
}: ITargetMatchedCardLayoutProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  return (
    <MatchedCardLayout
      title={title}
      linkText={t('readMoreCta', { ns: 'component' })}
      linkTo={linkTo}
    >
      <Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 4,
            height: '6rem',
            background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${backgroundImageSrc});`,
          }}
        >
          {logo}
          <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
            {displayName}
          </Typography>
        </Box>
        {/* Target Region 目前沒有 desc，所以加了 desc 判斷式，未來可移除 */}
        {desc && (
          <Box sx={{ p: 4 }}>
            <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
              {desc}
            </Typography>
          </Box>
        )}
      </Stack>
    </MatchedCardLayout>
  )
}

interface ITargetMatchedCardProps {
  name: string
}

export const TargetRegionMatchedCard = ({ name }: ITargetMatchedCardProps) => {
  const targetRegions = useTargetedRegions()
  const targetRegion = targetRegions.find((region) => region.name === name)

  if (!targetRegion) {
    return null
  }

  const { desc, backgroundImageSrc, displayName, targetName } = targetRegion

  return (
    <TargetMatchedCardLayout
      linkTo={`/${THREAT_LANDSCAPE_ROUTE}/${TARGET_REGION_ROUTE}/${targetName}`}
      title="Target Region"
      displayName={displayName}
      desc={desc}
      backgroundImageSrc={backgroundImageSrc}
    />
  )
}

export const TargetCountryMatchedCard = ({ name }: ITargetMatchedCardProps) => {
  const { t } = useTranslation(['search'])
  const targetCountries = useTargetedCountries()
  const targetCountry = targetCountries.find((country) =>
    country.name.includes(name)
  )

  if (!targetCountry) {
    return null
  }

  const { logo, desc, backgroundImageSrc, displayName, targetName } =
    targetCountry

  return (
    <TargetMatchedCardLayout
      linkTo={`/${THREAT_LANDSCAPE_ROUTE}/${TARGET_COUNTRY_ROUTE}/${targetName}`}
      title={t('matchedCard.targetCountry', { ns: 'search' })}
      displayName={displayName}
      logo={logo}
      desc={desc}
      backgroundImageSrc={backgroundImageSrc}
    />
  )
}

export const TargetIndustryMatchedCard = ({
  name,
}: ITargetMatchedCardProps) => {
  const { t } = useTranslation(['search'])
  const targetIndustries = useTargetedIndustries()
  const targetIndustry = targetIndustries.find((industry) =>
    industry.name.includes(name)
  )

  if (!targetIndustry) {
    return null
  }

  const { logo, desc, backgroundImageSrc, displayName, targetName } =
    targetIndustry

  return (
    <TargetMatchedCardLayout
      linkTo={`/${THREAT_LANDSCAPE_ROUTE}/${TARGET_INDUSTRY_ROUTE}/${targetName}`}
      title={t('matchedCard.targetIndustry', { ns: 'search' })}
      displayName={displayName}
      logo={logo}
      desc={desc}
      backgroundImageSrc={backgroundImageSrc}
    />
  )
}
