/**
 * @description 隱藏 scrollbar 但保有捲動的功能
 * 用法：可直接把 hideScrollBarStyles 以解構的方式寫入 sx props
 */
export const hideScrollBarStyles = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
}
